import React, { useState,useEffect, Fragment } from "react";
import DropdownField from "./DropdownField";
import TextType from "../TextType";
import CirclePattleIcon from "../../Icons/CirclePattleIcon";
import SelectField from "./SelectField";
import MultiSelectDropDown from "./HeaderSidebar/MultiSelectDropDown";
import ListBox from "./HeaderSidebar/ListBox";

const ExtraFieldAdd = ({setExtraField,ExtraFieldAddHeader,EditFieldData}) => {
  const options = [
    { label: `Dropdown`, value: "dropdown", svg: <CirclePattleIcon /> },
    { label: "List Box", value: "Listbox" },
    { label: "Multi-Select", value: "multiple" },
    { label: "Text", value: "text" },
    { label: "Date", value: "date" },
    { label: "Person", value: "person" },


  ];
  const [extraFieldData,setExtraFieldData] = useState({id:'',field_name:'',field_lable:'',field_options:[],field_type:'',field_description:'',deleteoption: true});

  const [inputValue, setInputValue] = useState('');
  const [shadowColor, setShadowColor] = useState('');
  const [inputVisible, setInputVisible] = useState(true);

  const [error, setError] = useState('');


  const handleAddOption = () => {
    if (inputValue.trim() !== '') {

      const normalizedInputValue = inputValue.toLowerCase();

      // Check if the normalized input value already exists in the field_options
      const isDuplicate = extraFieldData?.field_options?.some(option =>
        option.value.toLowerCase() === normalizedInputValue
      );

      if (isDuplicate) {
        setError('already exists.');
      } else {
        setExtraFieldData(prevState => ({
          ...prevState,
          field_options: [...prevState.field_options, { value: inputValue, color_code: shadowColor }]
        }));
        setInputValue('');
        setError('');
        setShadowColor('#87CEEB');
        setInputVisible(false);
      }
    

      // setExtraFieldData(prevState => ({
      //   ...prevState,
      //   field_options: [...prevState.field_options, { value: inputValue, color_code: shadowColor,delete: true }]
      // }));
      // setInputValue('');
      // setShadowColor('#87CEEB');
      // setInputVisible(false);
    }
  };



 
  

  
  useEffect(() => {
    setExtraFieldData(prevState => ({
      ...prevState,
      id:  EditFieldData?.id || '',
      field_name: EditFieldData?.field_name || '',
      field_lable: EditFieldData?.field_label || '',
      field_options: EditFieldData?.field_options || [],
      field_type: EditFieldData?.field_type || options[0].value,
      field_description: EditFieldData?.field_description || '',
      deleteoption: EditFieldData?.field_type && false || true,


    }));
  }, [EditFieldData]);

  const handleFieldChange = (field,e) => {
    const newValue = e.target.value;
    setExtraFieldData(prevState => ({
      ...prevState,
      [field] :  newValue,
      ...(field === 'field_lable' && { field_name: newValue })
    }));
  };


  const ExtraFieldSaveHeader = async () => {
    
     let updatedFieldData;
    if(inputValue.trim() !== ''){

      updatedFieldData = {
        ...extraFieldData,
        field_options: [
          ...extraFieldData.field_options,
          { value: inputValue, color_code: shadowColor }
        ]
      };

    }

    const updatedData = updatedFieldData ? updatedFieldData : extraFieldData;
   
    const extraFieldPost = {id:updatedData?.id,field_type : updatedData?.field_type, field_name:updatedData?.field_name,field_lable : updatedData?.field_lable,field_description : updatedData?.field_description, field_options : updatedData?.field_options }
 
    ExtraFieldAddHeader(extraFieldPost);
    setExtraField(false)
 
  }

  return (
    <div className="bg-white rounded-lg border shadow-lg" id="newField">
{/* 
                <div className="flex justify-end mx-3 ">
                      <button className="mt-1" onClick={() => { setExtraField(false); } }>
                          <i className="bi bi-x-circle"></i>
                      </button>
                </div> */}


                <SelectField
                  setExtraFieldData={setExtraFieldData}
                  extraFieldData={extraFieldData}
                  options={options}
                  setExtraField={setExtraField}  
                />

      <div className="pb-3 px-7">
        <div className="font-500 text-12">
          <label className="text-535252 font-poppins font-12 text-base">
            Name
          </label>
          <input
            className="px-4 h-8 mb-3 focus:outline-none focus:ring-1 border border-CADBEB rounded-md w-full capitalize"
            placeholder="Field Name"
            defaultValue={extraFieldData?.field_lable}
            disabled={extraFieldData?.id}
            onChange={(e) => {handleFieldChange('field_lable',e)} }
          />
          <label className="text-535252 font-poppins font-12 text-base">
            Description
          </label>
          <textarea
            className="resize w-full border border-CADBEB rounded-md px-4 py-1"
            defaultValue={extraFieldData?.field_description}
            // className="px-4 w-full h-8 mb-1 focus:outline-none focus:ring-1 border border-CADBEB rounded-md"
            onChange={(e) => {handleFieldChange('field_description',e)} }

          />
        </div>
      </div>

      <div>
        {extraFieldData?.field_type === "dropdown" && (
          <Fragment>
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}
          <DropdownField
            setExtraFieldData={setExtraFieldData}
            extraFieldData={extraFieldData}
            setInputValue={setInputValue}
            inputValue={inputValue}
            setShadowColor={setShadowColor}
            shadowColor={shadowColor}
            handleAddOption={handleAddOption}
            setInputVisible={setInputVisible}
            InputVisible={inputVisible}
            
          />

</Fragment>

        )}
        {extraFieldData?.field_type === "Listbox" && (
          <Fragment>
                {error && <p className="text-red-500 text-sm text-center">{error}</p>}

                <ListBox 
                  setExtraFieldData={setExtraFieldData}
                  extraFieldData={extraFieldData} 
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  handleAddOption={handleAddOption}
                  setInputVisible={setInputVisible}
                  InputVisible={inputVisible}
                />
          </Fragment>
        )}
        {extraFieldData?.field_type === "multiple" && (
            <Fragment>
                {error && <p className="text-red-500 text-sm text-center">{error}</p>}
                    <MultiSelectDropDown
                        setExtraFieldData={setExtraFieldData}
                        extraFieldData={extraFieldData} 
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        handleAddOption={handleAddOption}
                        setInputVisible={setInputVisible}
                        InputVisible={inputVisible}
                    />
            </Fragment>
        )}
        {/* {extraFieldData?.field_type === "text-type" && (
          <TextType setExtraFieldData={setExtraFieldData}
          extraFieldData={extraFieldData}  />
        )} */}
      </div>

      <div className="py-4 px-6 h-full">
        <button
          type="submit"
          className={`rounded-md w-full h-10 font-poppins font-500 text-16 ${
            extraFieldData?.field_name?.trim() === ""
              ? "bg-D4D4D4 text-black cursor-not-allowed"
              : "bg-2999BC text-white shadow-md"
          }`}
          disabled={extraFieldData?.field_name?.trim() === ""}
          onClick={(e) => { ExtraFieldSaveHeader() }}
        >
          SAVE
        </button>
      </div>
    </div>
  );
};

export default ExtraFieldAdd;
