const CustomerAddressStaticHeader = [
    {
        "address_type": "Address Type",
        "address_type_status": 1
    },

    {
        "point_of_contact": "Point Of Contact",
        "point_of_contact_stutus": 1
    },
    {
      "address": "Address",
      "address_status": 1
    },
    {
      "city": "City",
      "city_status": 1
    },
    {
      "state": "State",
      "state_status": 1
    },
    {
      "zip_code": "Zip Code",
      "zip_code_status": 1
    },
    {
      "country_id": "Country",
      "country_id_status": 1
    },
    
    {
        "phone1": "Phone1",
        "phone1_status": 1
    },
    {
        "phone2": "Phone2",
        "phone2_status": 1
    },
    {
    "primary_email": "Primary Email",
    "primary_email_status": 1
    },
    {
      "secondry_email": "Secondry Email",
      "secondry_email_status": 1
    },
       
]


export default CustomerAddressStaticHeader