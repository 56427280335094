import React, { useState, Fragment } from "react";
import SubmitIcon from "../Icons/SubmitIcon";

      const TaskActivityExpanded = (closePopup) => {
            /* const [isPopupOpen, setIsPopupOpen] = useState(false);
            const closePopup = () => {
                  setIsPopupOpen(false);
                }; */
      return (
            <div className="fixed inset-0 flex items-center justify-center z-[9999]">
                  <div className="relative mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md w-[750px] overflow-hidden no-scrollbar">
                        <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
                              <div className="flex justify-between">
                                    <p className='text-md text-2C495D'>Activity</p>
                                    <button
                                          type="button"
                                          onClick={closePopup}
                                    ><h3 className="text-545F65"><i className="bi bi-x"></i></h3>
                                    </button>
                              </div>
                        </div>
                  </div>
            </div>
      );
};

export default TaskActivityExpanded;