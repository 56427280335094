import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit';
import { saveData , getCommonDataBasedOnId , deleteData ,updateData} from '../../components/store/customer/CustomerSocialAddUpdateFunction';
import { CUSTOMER_SERVICE_ADD_UPDATE , CUSTOMER_SERVICE_DATA_GET, CUSTOMER_SERVICE_DATA_DELETE,CUSTOMER_SERVICE_STATUS } from '../constants';

export const saveServicesDataAsync = createAsyncThunk(
  'socialMembers/saveServicesDataAsync',
  async ({ socialData, token }, thunkAPI) => {
    try {
     
      const requestData =[socialData];
      const endpoint = (socialData.id === undefined || socialData.id === '') ? 
      CUSTOMER_SERVICE_ADD_UPDATE : CUSTOMER_SERVICE_STATUS;
     
      const response = await ((socialData.id === undefined || socialData.id === '') ? 
          saveData : updateData)(
          endpoint,
          requestData,
          token
        );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const fetchServicesData = createAsyncThunk(
  'customerServices/fetchServicesData',
  async ({ customerId, token },thunkAPI) => {
    try {
      const url = `${CUSTOMER_SERVICE_DATA_GET}${customerId}`;
      const getData = await getCommonDataBasedOnId(url, token);
      const contactResponse=getData?.data?.data
      if (Array.isArray(contactResponse) && contactResponse.length > 0) {
        const processedData = contactResponse.map(member => ({
          ...member,
          socialChecked: member.status === "1" ? true : false,
        }));
        
        thunkAPI.dispatch(setCustomerServices(processedData));
        return processedData;
       
      } else {
        
        return thunkAPI.rejectWithValue('Data is not an array or is empty');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteServicesDataAsync = createAsyncThunk(
  'customerServices/deleteServicesDataAsync',
  async ({ id, token }, { rejectWithValue,thunkAPI }) => {
    try {
      const response = await deleteData(`${CUSTOMER_SERVICE_DATA_DELETE}${id}`,token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const servicesSlice = createSlice({
  name: 'customerServices',
  initialState: {
    pointerStyle: 0,
    message: null,
    showMessage: false,
    socialMembers: [],
    status: 'idle',
    error: null,
  },
  reducers: {
   
    save: (state, action) => {
        state.pointerStyle= 1;
    },
    addServiceCheck: (state, action) => {
      const { indexno, socialChecked } = action.payload;
      state.socialMembers[indexno].socialChecked = socialChecked;
    },
    setTextAreaIndex: (state, action) => {
      state.textareaIndex = action.payload;
    },
    setTextAreaHeight: (state, action) => {
      state.textareaHeight = action.payload;
    },
    setCustomerServices: (state, action) => {
     
     
      state.socialMembers = action.payload;
      state.pointerStyle = 1;
    },
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    removeServicesMemberAtIndex: (state, action) => {
      const indexToRemove = action.payload;
      state.socialMembers = state.socialMembers.filter((_, index) => index !== indexToRemove);
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveServicesDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveServicesDataAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message = action.payload.message;
        state.showMessage = true;
        state.pointerStyle =1;
      })
      .addCase(saveServicesDataAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      })
      .addCase(fetchServicesData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchServicesData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.teamMembers = action.payload;
        state.pointerStyle =1;
      })
      .addCase(fetchServicesData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteServicesDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteServicesDataAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message = action.payload.message;
        state.showMessage = false;
      })
      .addCase(deleteServicesDataAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      });
  },
});

export const { removeServicesMemberAtIndex,hideMessage , addService, inputChange, save, addServiceCheck, setTextAreaIndex, setTextAreaHeight , setCustomerServices} = servicesSlice.actions;

export default servicesSlice.reducer;
