import React, { useRef, Fragment, useEffect, useState } from "react";
import SubmitIcon from "../Icons/SubmitIcon";
import { saveActivity } from "../../utils/customer/customerTaskEventSlice";
import { useDispatch, useSelector } from "react-redux";
import useRequireAuth from "../../utils/useRequireAuth";
import { CUSTOMER_ACTIVITY_GET_EVENT } from "../../utils/constants";
import TaskActivityExpanded from "./TaskActivityExpanded";
 
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import SearchBar from "../GlobalFeature/Table/HeadTop/SearchBar";
import SortingFilter from "../GlobalFeature/Table/TableThead/SortingFilter";
import useSortableContent from "../../hooks/Content/useSortableContent";
const formatchangeDateformate = (dateString) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const parts = dateString.split("-");
  const day = parseInt(parts[0], 10);
  const monthIndex = parseInt(parts[1], 10) - 1;
  const year = parts[2].split(" ");
  const formattedDate = `${day} ${monthNames[monthIndex]} ${year[0].slice(
    -2
  )}  ${year[1]}`;
  return formattedDate;
};
 
const CustomerEditTaskActivity = ({
  onFocus,
  taskid,
  taskCategory,
  activity,
  onBlur,
  cross,
  showNotification,
  handleNotification
}) => {
  console.log("crosss>>>>>>>>>>>>", cross);
  

  const [editTaskSearchInput, setEditTaskSearchInput] = useState("");
  const scrollRef = useRef(null);

  const [description, setDescription] = useState("");
  const handleInputChange = (event) => {
    const { value } = event.target;
    setDescription(value);
  };
  const [taskEvent, setTaskEvent] = useState([]);
  const activitys = useSelector((state) => state.activity);
  const dispatch = useDispatch();
  const { token } = useRequireAuth();
  const url = `${CUSTOMER_ACTIVITY_GET_EVENT}?task_id=${taskid}`;
  const fetchData = async () => {
    try {
      if (
        activitys?.activity?.length === 0 ||
        activitys?.activity?.data === ""
      ) {
        const data = await getCommonDataBasedOnId(url, token);
        setTaskEvent(data?.data);
      } else {
        setTaskEvent(activitys?.activity?.data);
      }
    } catch (error) {
      console.error("Error in fetchData:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [url, token, taskid, activitys?.activity?.data]);
 
 
  const [isActivityModalOpen, setActivityModalOpen] = useState(true);
  const closeActivityModal = () => {
    setActivityModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
 
  const openModal = () => {
    setIsModalOpen(true);
  };
 
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { sortedData, sortData, sortKey, sortOrder, handleSearch } = useSortableContent(taskEvent, "asc");
  console.warn(sortedData,"sortedData")

 const handleTaskSearch = (term) => {
    setEditTaskSearchInput(term);
    handleSearch(term);
  };
 
  const handleSubmitButtonClick = (e) => {
   const taskEvent = {
    description: description,
    task_id: taskid,
  };
  dispatch(saveActivity({ taskEvent, token }));
    fetchData();
    setDescription('');
  };
  useEffect(() => {
    // Scroll to the bottom of the container when data updates
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [sortedData]);
  return (
    <>
      {isActivityModalOpen && (
        <div className={`bg-white  ${cross ? 'py-0 w-[350px] min-h-full flex flex-col justify-between  ' : 'py-3 w-full '}`}>
            <div className="flex justify-between py-2 px-4 border border-DADCDE rounded ">
                      <p className="flex items-center text-base text-434852 ">
                        Activities <i className="bi bi-circle-fill text-D93916 relative bottom-[6px] ms-1 due-info">
                                    <span onClick={openModal} className="text-white text-xs absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                                      {taskEvent.length}
                                    </span>
                                  </i>
                      </p>

                      <div className="flex">
                        <div className="flex">
                              <SearchBar
                                data={editTaskSearchInput}
                                event={handleTaskSearch}
                                SearchOpen={false}
                                InputClass={"h-[25px] w-[140px]"}
                              />
                        </div>
                        <button type="button" className="text-B4C0C8 mx-3">
                            <i className="bi bi-bell"></i>
                        </button>
                        <button type="button" className="text-B4C0C8"  onClick={() => sortData("created_at")}>
                            <SortingFilter
                                sortKey={sortKey}
                                sortOrder={sortOrder}
                                header={{ key: "created_at" }}
                            />
                        </button>
                        {
                        cross && <button onClick={handleNotification}>
                          <div><button type="button" class="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="assets-modal" fdprocessedid="5uls5o"><svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path></svg><span class="sr-only">Close modal</span></button></div>
                        </button>
                        }
                      </div>
                      {/* <button type="button" onClick={openModal}>
                        <h5 className="text-545F65">
                          <i className="bi bi-arrows-fullscreen"></i>
                        </h5>
                      </button> */}
            </div>
            {/* ================POPUP STARTS================ */}
            {/* {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-[99999999] bg-black bg-opacity-50">
                <div className="">
                  <div
                    className="relative p-3 mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md overflow-hidden no-scrollbar"
                    style={{ width: "600px" }}
                  >
                    <div className="flex items-center justify-between rounded-t">
                      <p className="text-md text-2C495D">Activity</p>
                      <button type="button" onClick={closeModal}>
                        <h3 className="text-545F65">
                          <i className="bi bi-x"></i>
                        </h3>
                      </button>
                    </div>
                    <div className="flex flex-col ">
                      <p className="mb-3">{taskCategory}</p>
                      <div className="h-[350px] addTask overflow-x-auto">
                      {!taskEvent
                        ? null
                        : taskEvent.map((CategoryData) => (
                            <div className="px-2 py-2 bg-F1F6F9 rounded-5px mb-3">
                              <div className="px-2 flex items-center gap-x-2">
                                <div>
                                  <img
                                    className="w-8 h-8 me-2 rounded-full"
                                    src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                                    alt="user photo"
                                  />
                                </div>
                                <div className="flex flex-col">
                                  <p className="text-2999BC font-medium text-xs">
                                    {CategoryData.created_by}
                                  </p>
                                  <p className="text-F16136 font-medium text-xxs">
                                    {formatchangeDateformate(
                                      CategoryData.created_at
                                    )}
                                  </p>
                                </div>
                              </div>
                              <p className="text-xs text-justify text-535252 m-3">
                                {CategoryData.description}
                              </p>
                            </div>
                          ))}
                      </div>    
                      <form>
                        <div className="relative flex flex-wrap">
                          <input
                            name="description"
                            type="text"
                            className="h-full w-full border-2 border-lightBlue rounded-5px shadow-10 focus:outline-hidden focus:ring-0 focus:border-1 focus:border-lightBlue focus:shadow-11"
                            placeholder="Type Something..."
                            value={description}
                            onChange={handleInputChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                          />
                          <button
                            className="bg-white absolute right-1 top-1 z-10 py-2 px-4"
                            type="button"
                            data-ripple-light="true"
                            onClick={handleSubmitButtonClick}
                          >
                            <SubmitIcon />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          {/* </div> */}

          {/* ================POPUP ENDS================ */}
          <div className="border border-DADCDE ">
                <div ref={scrollRef} className="flex flex-col max-h-[320px] overflow-y-scroll scrollbar-hidden addTask my-2 mb-1">
                  <p className="mb-3">{taskCategory}</p>
                  {!sortedData
                    ? null
                    : sortedData.map((CategoryData) => (
                        <div className="border border-DEE0E2 mx-2 py-2 rounded-5px mb-2">
                          <div className="px-2 flex items-center gap-x-2">
                            <div>
                              <img
                                className="w-8 h-8 me-2 rounded-full"
                                src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                                alt="user photo"
                              />
                            </div>
                            <div className="flex flex-col">
                              <p className="text-2999BC font-medium text-sm">
                                {CategoryData.created_by}
                              </p>
                              <p className="text-F16136 font-normal text-xxs">
                                {formatchangeDateformate(
                                  CategoryData.created_at
                                )}
                              </p>
                            </div>
                          </div>
                          <p className="text-sm text-justify text-383838 m-1">
                            {CategoryData.description}
                          </p>
                        </div>
                      ))}
                </div>
                <form>
                  <div className="relative flex flex-wrap">
                    <textarea
                      name="description"
                      type="text"
                      className="h-full w-full border-2 border-lightBlue rounded-5px shadow-10 focus:outline-hidden focus:ring-0 focus:border-1 focus:border-lightBlue focus:shadow-11 scrollbar-hidden"
                      placeholder="Type Something..."
                      value={description}
                      onChange={handleInputChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                    <button
                      className="absolute right-1 bottom-1 py-2 px-4"
                      type="button"
                      data-ripple-light="true"
                      onClick={handleSubmitButtonClick}
                    >
                      <SubmitIcon />
                    </button>
                  </div>
                </form>
          </div>
        </div>
      )}
    </>
  );
};
export default CustomerEditTaskActivity;