import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteData, getDataIdBase, saveNotesData ,getCommonDataBasedOnId} from '../../components/store/customer/CustomerSocialAddUpdateFunction';
import { CUSTMOER_NOTES_DATA_GET, CUSTOMER_NOTES_ADD_UPDATE,CUSTMOER_NOTES_DATA_DELETE } from '../constants';
export const saveNotes = createAsyncThunk('notes/saveNotes', async ({notesData ,token}, thunkAPI) => {
  try {
    ///console.log(notesData);return false;
     const response=await saveNotesData(CUSTOMER_NOTES_ADD_UPDATE,[notesData],token);
   
     if(response.status===true){
      const url = `${CUSTMOER_NOTES_DATA_GET}${notesData.customer_id}`;
        const getData = await getCommonDataBasedOnId(url, token);
        const contactResponses = getData?.data;
        if (Array.isArray(contactResponses) && contactResponses.length > 0) {
          thunkAPI.dispatch(setCustomerNotes(contactResponses));
          return contactResponses;
        }

     }
    
  } catch (error) {
    return thunkAPI.rejectWithValue('Data is not an array or is empty');
  }
});
export const fetchNotes=createAsyncThunk('notes/fetchNotes',async({customerid,token},thunkAPI)=>{
  try {
    const response=await getDataIdBase(`${CUSTMOER_NOTES_DATA_GET}${customerid}`,token);
    if(response?.status===true){
      return response;
    }
  }catch(error){
    return thunkAPI.rejectWithValue('Data is not an array or is empty');
  }
});

export const deleteNotes=createAsyncThunk("notes/deleteNotes",async({id,token},thunkAPI)=>{
  try {
    
     const response=await deleteData(`${CUSTMOER_NOTES_DATA_DELETE}${id}`,token);
    
     if(response?.status===true){
      return response;
     }
  } catch (error) {
    return thunkAPI.rejectWithValue('Data is not an array or is empty');
  }
}) 

const customerNotesSlice = createSlice({
  name: 'notes',
  initialState: {
    loading: false,
    notes: [],
    error: null,
    showMessage: false,
    message: null,
  },
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    setCustomerNotes:(state, action) => {
     
      state.notes = action.payload;
     
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = action.payload;
        state.error = '';
        state.showMessage = true;
        state.message = action.payload;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(saveNotes.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(saveNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.notes=action.payload;
        state.error = '';
        state.showMessage = true;
        state.message = action.payload;
       
      })
      .addCase(saveNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(deleteNotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
        state.message = action.payload;
      })
      .addCase(deleteNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

// Export actions and reducer
export const { showMessage, hideMessage ,setCustomerNotes} = customerNotesSlice.actions;
export default customerNotesSlice.reducer;
