
// import { useState, useRef, useEffect } from 'react';

// const useDropdownPosition = (isOpen, tableidclass) => {
//   const [dropdownPosition, setDropdownPosition] = useState('bottom');
//   const dropdownRef = useRef(null);

//   const adjustDropdownPosition = () => {
//     if (dropdownRef.current) {
//       const dropdownRect = dropdownRef.current.getBoundingClientRect();
//       const tableElement = document.getElementById(tableidclass);

//       if (tableElement) {
//         const tableRect = tableElement.getBoundingClientRect();
//         const viewportHeight = window.innerHeight;
//         const spaceBelow = viewportHeight - (dropdownRect.bottom - tableRect.top);
//         const spaceAbove = dropdownRect.top - tableRect.top;
//         const dropdownHeight = dropdownRect.height;

//         // Adjust dynamically based on dropdown height and available space
//         const buffer = 20; // Adjust as needed for any additional buffer space

//         if (spaceBelow < dropdownHeight + buffer && spaceAbove > dropdownHeight + buffer) {
//           setDropdownPosition('top');
//         } else {
//           setDropdownPosition('bottom');
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     if (isOpen) {
//       adjustDropdownPosition();
//       window.addEventListener('resize', adjustDropdownPosition);
//       window.addEventListener('scroll', adjustDropdownPosition);
//     } else {
//       window.removeEventListener('resize', adjustDropdownPosition);
//       window.removeEventListener('scroll', adjustDropdownPosition);
//     }

//     return () => {
//       window.removeEventListener('resize', adjustDropdownPosition);
//       window.removeEventListener('scroll', adjustDropdownPosition);
//     };
//   }, [isOpen]);

//   return { dropdownPosition, dropdownRef };
// };

// export default useDropdownPosition;









import { useState, useRef, useEffect } from 'react';

const useDropdownPosition = (isOpen, tableidclass) => {
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const dropdownRef = useRef(null);

  const adjustDropdownPosition = () => {
    if (dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const tableElement = document.getElementById(tableidclass);

      if (tableElement) {
        const tableRect = tableElement.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - dropdownRect.bottom;
        const spaceAbove = dropdownRect.top;
        const dropdownHeight = dropdownRect.height;

        // Adjust dynamically based on dropdown height and available space
        const buffer = 20; // Adjust as needed for any additional buffer space

        if (spaceBelow < dropdownHeight + buffer && spaceAbove > dropdownHeight + buffer) {
          setDropdownPosition('top');
        } else {
          setDropdownPosition('bottom');
        }
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      adjustDropdownPosition();
      window.addEventListener('resize', adjustDropdownPosition);
      window.addEventListener('scroll', adjustDropdownPosition);
    } else {
      window.removeEventListener('resize', adjustDropdownPosition);
      window.removeEventListener('scroll', adjustDropdownPosition);
    }

    return () => {
      window.removeEventListener('resize', adjustDropdownPosition);
      window.removeEventListener('scroll', adjustDropdownPosition);
    };
  }, [isOpen]);

  return { dropdownPosition, dropdownRef };
};

export default useDropdownPosition;
