
import React, { useState, useEffect, Fragment } from 'react';
import useDropdownPosition from '../../../hooks/Dropdown/useDropDownPosition';
import SaveEditFirst from '../SaveEdit/SaveEditFirst';
import useDebounce from '../useDebounce';

const OwnerDropDown = ({ tableidclass,fetchPersonInTable, setDataInputRow, dataInputRow, SpecificElement, DropData, UpdateTableField, FieldValue, Item,handleInsert ,owner,fieldname,handlePersonData}) => {
  const parsedDropData = Array.isArray(DropData) ? DropData : [];
  const [selected, setSelected] = useState({email: ''});
  const [filter, setFilter] = useState(FieldValue?.value || '');
  const [show, setShow] = useState(true);
  

  useEffect(()=>{
    setSelected({
      email: owner
    })
  },[owner])

  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
  const [hoveredItemPosition, setHoveredItemPosition] = useState({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const debouncedFilter = useDebounce(filter, 300);
  const { dropdownPosition, dropdownRef } = useDropdownPosition(show, tableidclass);
  const [isOpen, setIsOpen] = useState(false);

 

  useEffect(() => {
    if (show) {
      setIsLoading(true);
      setError(null);
      fetchPersonInTable(debouncedFilter);
      setIsLoading(false);

    } 
  }, [debouncedFilter,show]);

  const close = () => {
    setShow(false);
    setFocusedOptionIndex(null);
  };

  const open = () => {
    setShow(true);
    setFilter('');
  };

  const searchDropToggle = () => {
    setIsOpen(!isOpen);
  };



  const filteredOptions = () => {
    return parsedDropData && parsedDropData.filter(option => {
      return (
        option.name?.toLowerCase().includes(filter?.toLowerCase()) ||
        option.email?.toLowerCase().includes(filter?.toLowerCase())
      );
    });
  };

  const onOptionClick = (index) => {
    setFocusedOptionIndex(index);
    selectOption(index);
  };

  const selectOption = (index) => {
    if (!isOpen) {
      return;
    }
    const focusedOption = filteredOptions()[index];
    if (focusedOption) {
      handleInsert(focusedOption.email);
      setSelected(focusedOption);
      if(handlePersonData!==undefined)
      {
        handlePersonData(focusedOption);
      }
     
      setFilter(`${focusedOption.value} ${focusedOption.email}`);
    }
    close();
  };

  const focusPrevOption = () => {
    if (!isOpen) {
      return;
    }
    const optionsNum = filteredOptions().length - 1;
    if (focusedOptionIndex > 0 && focusedOptionIndex <= optionsNum) {
      setFocusedOptionIndex(prevIndex => prevIndex - 1);
    } else if (focusedOptionIndex === 0) {
      setFocusedOptionIndex(optionsNum);
    }
  };

  const focusNextOption = () => {
    const optionsNum = filteredOptions().length - 1;
    if (!isOpen) {
        setIsOpen();
    }
    if (focusedOptionIndex === null || focusedOptionIndex === optionsNum) {
      setFocusedOptionIndex(0);
    } else if (focusedOptionIndex >= 0 && focusedOptionIndex < optionsNum) {
      setFocusedOptionIndex(prevIndex => prevIndex + 1);
    }
  };

  const handleKeyDown = (e) => {
    setIsOpen(true)
    switch (e.key) {
      case 'Enter':
        selectOption(focusedOptionIndex);
        break;
      case 'ArrowUp':
        focusPrevOption();
        break;
      case 'ArrowDown':
        focusNextOption();
        break;
      default:
        break;
    }
  };

 
  
  return (
    <Fragment>
      <div className="" ref={dropdownRef}>    
          <div className=" relative">
            <div onClick={() => setShow(false)} className="bg-white flex rounded">
              <input
                name={fieldname ? fieldname : ''}
                value={(selected)? selected?.email: filter}
                placeholder="Email"
                onChange={(e) => {
                  setFilter(e.target.value);
                  setSelected(null);
                }}
                // className="flex-1 p-1 px-2 appearance-none outline-none text-gray-800"
                className="bg-white p-1 px-2 h-7.5 rounded-5px border border-DFE2E5 focus:outline-hidden focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full"
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(true);
                }}
                onKeyUp={handleKeyDown}
              />
             
            </div>
            {isOpen && (
              <div className={`absolute shadow bg-white z-40 rounded max-h-[225px] overflow-y-auto w-full ${dropdownPosition === 'bottom' ? 'top-full mt-2' : 'bottom-full mb-2'}`}>
                <div className="flex flex-col w-full">
                  {filteredOptions()?.map((option, index) => (
                    <div
                      key={index}
                      onClick={() => onOptionClick(index)}
                      onMouseEnter={(e) => { setFocusedOptionIndex(index); setHoveredItemPosition({ x: e.clientX, y: e.clientY }); }}
                      onMouseLeave={() => setFocusedOptionIndex(null)}
                      className={`flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100 ${
                        focusedOptionIndex === index ? 'bg-gray-100' : ''
                      }`}
                      aria-selected={focusedOptionIndex === index}
                    >
                      <div className="w-full items-center flex">
                        <div className="mx-2 -mt-1">
                          <span className='text-2999BC'>{option?.name}</span>
                          <div className="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500">{option?.email}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>       
      </div>      
    </Fragment>
  );
};

export default OwnerDropDown;
