import React, { useState, useEffect, Fragment } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import CustomerListTable from "../customer/CustomerListTable";
import CompanyDelegationHeader from "../User/CompanyDelegationHeader";
import { useFetchCompanyDelegationQuery } from "../../utils/customer/customerTaskTable";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import LayoutHOC from "../LayoutHOC";
import AddUpdateCompanyDelegation from "./AddUpdateCustomerDelegation";
import { useLocation } from "react-router-dom";
import { getUserTokeData } from "../../utils/userSlice";
import { useDispatch } from "react-redux";
import { DELTE_COMPANY_DELEGATION } from "../../utils/constants";
import { deleteData } from "../store/customer/CustomerSocialAddUpdateFunction";
const CompanyDelegation = ({}) => {
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const [customer, setCustomer] = useState([]);
  const [initialFilter, setInitialFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useRequireAuth();
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [editData, setEditData] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchCompanyDelegationQuery({
      token: token,
      pagination: `${customerTablePagination}`,
    });
  useEffect(() => {
    if (customerTableALL) {
      const { data, ...restData } = customerTableALL?.data;
      setCustomer(data);
      setInitialFilter(restData);
      setLoading(false);
    }
  }, [
    customerTableALL,
    customerTablePagination,
    refetchCustomerData,
    location.key,
  ]);
  const handleNavigate = (taskid) => {
    setEditData(taskid);
  };
  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };
  const onClose = () => {
    setIsPopupOpen(false);
    setEditData("");
  };

  const handelRegenerat = () => {
    refetchCustomerData();
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  useEffect(() => {
    if (location.key) {
      refetchCustomerData();
      dispatch(getUserTokeData());
    }
  }, [location.key]);

  const handleDelete=async(rowKey, item)=>{
    const id=item?.customer_id?.value;
    const url=`${DELTE_COMPANY_DELEGATION}${id}`
    const response=deleteData(url,token);
    if(response){
      refetchCustomerData();
     setAlert({
       visible: true,
       message: "data deleted successfully",
       type: "success",
     });
    }
   
   }

  return (
    <Fragment>
      <div className="p-4 pt-0 mt-7" id="innercontent">
        <div id="default-styled-tab-content" className="tablemaindiv">
          <div
            className="profile"
            id="styled-profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className={` ${isFullscreen ? "bg-white h-full" : ""}`}>
              <div className="flex items-center gap-2">
                <div className="tablemaindiv customerTaskTable py-1">
                  {isPopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-[102]">
                      <AddUpdateCompanyDelegation
                        isOpen={isPopupOpen}
                        onClose={onClose}
                        handleSubmitData={handelRegenerat}
                      />
                    </div>
                  )}
                  {editData && (
                    <div className="fixed inset-0 flex items-center justify-center z-[102]">
                      <AddUpdateCompanyDelegation
                        isOpen={isPopupOpen}
                        onClose={onClose}
                        handleSubmitData={handelRegenerat}
                        editData={editData}
                      />
                    </div>
                  )}
                </div>
              </div>

              <FirstMessage
                type={alert.type}
                message={alert.message}
                visible={alert.visible}
                onHide={hideAlert}
              />

              <CustomerListTable
                key={location.key}
                tableDetail={{
                  tableidclass: "fullscreen-table",
                  tableparentidclass: "tableparent",
                  tablename:"Manage Access",
                  tablenameclass: "font-medium text-16 text-203C50 capitalize",
                  tableSearch: true,
                  tableFilter: true,
                  tableResizer: true,
                  tableZoom: true,
                  tableheader: false,
                  forcefullyfreeze:true,
                  dataactivity: true,
                  datetedata:true,
                  headeraddfield: false,
                  rowDisable: true,
                  addbutton: (
                    <button
                      onClick={openPopup}
                      type="button"
                      className="text-2541558A hover:text-254155"
                    >
                      <i className="bi bi-plus-lg"></i>
                    </button>
                  ),
                }}
                thead={CompanyDelegationHeader}
                tbody={customer}
                tablePaginationData={setCustomerTablePagination}
                initialFilter={initialFilter}
                handleNavigate={handleNavigate}
                navigateNameCondition={["user_name"]}
                handleDelete={handleDelete}
              />
              
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LayoutHOC(CompanyDelegation);
