import React, { Fragment } from "react";
import EditCompanyProfile from "./EditCompanyProfile";
import useCompanyEditData from "../../utils/company/useCompanyEditData";
import EdiCompanyBilling from "./EditCompanyBilling";
import EditAddress from "./EditAddress";
import EditContact from "./EditContact";
import useRequireAuth from "../../utils/useRequireAuth";
import LayoutHOC from "../LayoutHOC";
import { useFetchCompanyCommonDataQuery } from "../../utils/company/companySelector";
import EditCompanyAddress from "./EditCompanyAddress";
const EditCompany = () => {
  const { token } = useRequireAuth();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  let userToken = token || JSON.parse(localStorage.getItem("myUserData")).token;
  const companyData = useCompanyEditData(parsedCompanyData.company_id);
  const {
    data: companyCommnData,
    isLoading,
    error
  } = useFetchCompanyCommonDataQuery(userToken);

  
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <Fragment>
        <div className="mt-4 md:mx-4 font-poppins">
          <div className="flex flex-col py-4">
            <div className="flex justify-between items-center">
              <h5 className=" text-203C50 font-Vietnam text-24 font-medium ">
                {companyData?.data?.company_name}
              </h5>
              <div className="relative">
                <div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      defaultValue=""
                      className="sr-only peer"
                      defaultChecked=""
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-600 dark:peer-focus:ring-green-600 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <EditCompanyProfile
            companyData={companyData}
            businessCategoryData={companyCommnData?.data?.businessCategory}
            countryData={companyCommnData?.data?.country}
            currencyData={companyCommnData?.data?.currency}
            initialCompanyGroup={companyCommnData?.data?.company_group}
          />
          <EdiCompanyBilling
            company_id={companyData?.data?.id}
            taxFormData={companyCommnData?.data?.taxForm}
            accounting={companyCommnData?.data?.accountingMethod}

          />
          <EditCompanyAddress/>
          {/* <EditAddress company_id={companyData?.data?.id}   countryData={companyCommnData?.data?.country} /> */}
          <EditContact />

          <div id="default-styled-tab-content">
            <div
              className="hidden p-4 rounded-lg bg-gray-50"
              id="styled-profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            ></div>
            <div
              className="hidden p-4 rounded-lg bg-gray-50"
              id="styled-dashboard"
              role="tabpanel"
              aria-labelledby="dashboard-tab"
            ></div>
            <div
              className="hidden p-4 rounded-lg bg-gray-50"
              id="styled-settings"
              role="tabpanel"
              aria-labelledby="settings-tab"
            ></div>
            <div
              className="hidden p-4 rounded-lg bg-gray-50"
              id="styled-contacts"
              role="tabpanel"
              aria-labelledby="contacts-tab"
            ></div>
          </div>
      </div>
    </Fragment>
  );
};
export default LayoutHOC(EditCompany);
