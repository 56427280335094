import React, { useState , useEffect, Fragment} from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import { useDispatch } from "react-redux";
import OwnerDropDown from "../GlobalFeature/Fields/OwnerDropDown";
import { getCommonDataBasedOnId, saveData } from "../store/customer/CustomerSocialAddUpdateFunction";
import { GET_CUSTOMER_DELEGATION } from "../../utils/constants";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";
import { ADD_UPDATE_CUSTOMER_DELEGATION } from "../../utils/constants";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import CreatePortalHOC from "../GlobalFeature/Fields/CreatePortalHOC";
import useClickOutside from "../../hooks/Screen/useClickOutside";


const AddUpdateCustomerDelegation = ({ isOpen, closePopup, customerId,multiData,companyId,refetchCustomerData,show,setShow,alert,setAlert}) => {
  const { token } = useRequireAuth();

  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  
  const [errors, setErrors] = useState({});
  


  const [formData, setFormData] = useState({
    email: "",
    owner: 0,
  });

 const fetchTaskEditData = async (customerId) => {
    const url = `${GET_CUSTOMER_DELEGATION}${customerId}`;
    const getData = await getCommonDataBasedOnId(url, token);
   
    if(getData?.data?.length>0){
      setFormData({...getData?.data[0],email: ""});
    }
  };
  


  useEffect(() => {
    fetchTaskEditData(customerId);
  }, [customerId]);


  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validate = () => {
    let tempErrors = {};

    if (!formData?.email) {
      tempErrors.email = 'Email is required';
    }
    if (!formData?.owner) {
      tempErrors.owner = 'access is required';
    } 

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

 const handleSave=async(e)=>{
  e.preventDefault();
  if(!validate()){
    return false;
  }
  const customerData={
    ...formData,
    customer_id:customerId,
    company_id:companyId,
    multiData:multiData?.length?multiData:[customerId]
  }

  const response=await saveData(ADD_UPDATE_CUSTOMER_DELEGATION,[customerData],token);
  console.log(response,"response aakash")
  refetchCustomerData()
  if(response?.success===true){

    setAlert({
      visible: true,
      message: "data deleted successfully",
      type: "success",
    });
   
    closePopup()
  }else{
    setShow(response?.message);
  }
 }


const handleInsert = (item) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    email: item,
  }));
};
 
const [fetchPersonInTable, { data: personTableData }] =
useLazyFetchPersonInTableQuery();

const fetchPersonData = (data) => {
fetchPersonInTable({ token:token, data: data });
};
const tableDetail = {
  tableidclass: "usermodelmember",
};

const OutsideTaskClose = useClickOutside (() => {
  closePopup(true);
})

  return (isOpen && 
    <Fragment>
      <CreatePortalHOC dialog isOpen={isOpen}>
        
        <div className="fixed right-10 inset-y-0 z-[999] h-full w-full items-center justify-center flex bg-gray-900 bg-opacity-50 ">
          <div className="relative  mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md w-500px overflow-hidden no-scrollbar" ref={OutsideTaskClose}>
            <div className=" flex items-center justify-between  mr-2 pt-1.5  pb-1.5 rounded-t">
              <div className="px-2"></div>
              <br />
              <h3 className="text-16 font-500 text-2C495D">Customer Delegation</h3>
             
              <button
                onClick={closePopup}
                type="button"
                className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg  text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                data-modal-hide="assets-modal"
              >
                <svg
                  className="w-4 h-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            
            <form
              onSubmit={handleSave}
              className="w-full lg:max-h-[70vh]  overflow-x-hidden  no-scrollbar"
            >
           
              <div className="basis-5/5 mb-3  px-4">
                <div className="border-t border-[#BECBD3]"></div>
                <label htmlFor="first_name" className="block text-lg pt-1">
                  <span className="text-13 font-400 text-434852">
                  Email
                  </span>
                  <span className=" text-red-500"> *</span>
                  {errors.email && (
                          <span className="text-red-700">{errors.email}</span>
                  )}
                </label>
                <OwnerDropDown
                    tableidclass={tableDetail.tableidclass}
                    fetchPersonInTable={fetchPersonData}
                    DropData={personTableData}
                    handleInsert={handleInsert}
                    owner={formData?.email}
                    fieldname={"owner"}
                  />
                  {show && (<span className="text-red-700">{show}</span>)}
              </div>
              <div className="basis-5/5  px-4 ">
                <label
                  htmlFor="Designation"
                  className="block mb-1 text-13 font-400 text-434852"
                >
                  Access <span className=" text-red-500"> *</span>
                  {errors.owner && (
                          <span className="text-red-700">{errors.owner}</span>
                  )}
                </label>
                <select
                  id={`owner`}
                  name="owner"
                  onChange={handleFieldChange}
                  className={`form-select text-12 text-383838 text-md  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-0.5`}
                >
                  <option value="0" selected={formData?.owner === 0}>
                    Contributor
                  </option>
                  <option value="1" selected={formData?.owner === 1}>
                    Owner
                  </option>
                
                </select>
              </div>
              <div className="flex justify-end py-4 text-14 w-full">
                <div className="px-4">
                  <button
                    onClick={closePopup}
                    type="button"
                    className="w-[91px] text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-500 h-[30px] text-center rounded"
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="w-[91px] text-white bg-1D4469 hover:bg-20486F save-btn font-500 border border-gray-200 h-[30px]  ms-3 rounded"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CreatePortalHOC>
    </Fragment>
  );
};

export default AddUpdateCustomerDelegation;
