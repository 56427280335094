import React, { useState, Fragment } from "react";
import DownCircleArrow from "../Icons/DownCircleArrow";

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = date < 10 ? `0${date}` : date;

  return `${formattedMonth}/${formattedDate}/${year}`;
}

const AddOverDue = () => {
  const [currentDate, setCurrentDate] = useState(getDate());

  return (
    <div className="bg-white border border-1d446933 px-4 mb-4">
      <div className="flex justify-between py-4">
        <Fragment>
          <h3 className="text-2C495D font-semibold text-xl">Over Due</h3>
          <div className="flex justify-between w-[20%]">
            <button className="p-1 px-3 border border-E1E1E1 rounded-md text-2999BC text-sm font-500">
              View Over Due
            </button>
            {/* <DownCircleArrow /> */}
          </div>
        </Fragment>
      </div>

      <div className={`py-4 h-48 border-t-2 border-1d446933`}>
        <div className={`grid grid-cols-3 mb-2`}>
          <div className="rounded-md border border-1D446933 p-2">
            <i className="bi bi-circle-fill text-D9D9D9"></i>
            <span className="m-6">No Status</span>
          </div>

          <span className={`flex items-center justify-end  order-last`}>
            {currentDate}
          </span>
          <div className={`ms-8 flex items-center`}>
            <button
              type="button"
              className={`bg-transparent text-2999BC text-sm`}
            >
              Design
            </button>
          </div>
        </div>

        <p className="pt-3 text-2C495D text-sm">
          Hello World - Description test
        </p>
      </div>
    </div>
  );
};

export default AddOverDue;
