import React, { useState, useEffect, Fragment } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import CustomerListTable from "../customer/CustomerListTable";
import SideBar from "../Dashboard/SideBar";

import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import { useDeleteExternalUserMutation, useFetchUserDataTableDataQuery, useUpdateUserActiveMutation } from "../../utils/customer/customerTaskTable";
import UserHeader from "./UserHeader";
import AddUserModal from "./AddUserModal";
import HeaderDashboard from '../HeaderDashboard';
import { parseJSONValue } from "../../utils/json/parseJSONValue";
import UserPageDetails from "../GlobalFeature/Others/UserPageDetails";
import Tooltip from "@mui/material/Tooltip";
import UserProfileView from "./UserProfileView";
import { Drawer } from '@mui/material';
import useClickOutside from "../../hooks/Screen/useClickOutside";
import {UserActiveFilters,UserTypeFilters} from "./UserFilters";
import DeleteIcon from "../../assets/images/icons/deleteIcon";
import CreatePortalHOC from "../GlobalFeature/Fields/CreatePortalHOC";
import UserHoverDetails from "./UserHoverDetails";

const UserPage = () => {
  const [quiFilter, setQuiFilter] = useState({external:0,status:1});
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const [customer, setCustomer] = useState([]);
  const [initialFilter, setInitialFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useRequireAuth();
  const [editData, setEditData] = useState();
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [isProfileView, setIsProfileView] = useState(false);
  const [newDialog,setNewDialog]=useState(false)
  const [showHoverId,setShowHoverId]=useState(null)
  const [openShowDetails,setOpenShowDetails]=useState(false)
  // const [activeUserFlag,setActiveUserFlag]=useState(true)
  // const [externalUserFlag,setExternalUserFlag]=useState(false)

  const handleToggle = () => {
    setIsProfileView(!isProfileView);
};

  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchUserDataTableDataQuery({
      pagination: `${customerTablePagination}`,
      quiFilter: quiFilter,
      token: token,
    });
    // external=yes&status=1
  const [updateUserActive]=useUpdateUserActiveMutation()
  const [deleteExternalUser]=useDeleteExternalUserMutation()

  useEffect(()=>{
    handelsave()
  },[quiFilter])

  useEffect(() => {
    if (customerTableALL) {
      const { data, ...restData } = customerTableALL?.data;
      setCustomer(data);
      setInitialFilter(restData);
      setLoading(false);
    }
  }, [customerTableALL, customerTablePagination, refetchCustomerData]);

  const handleNavigate = (taskid) => {
    setIsEditPopupOpen(true);
    setEditData(taskid);
  };

  const closePopup = () => {
    setEditData();
    setIsEditPopupOpen(false);
    setIsPopupOpen(false);
    setNewDialog(false)
  };
  const handelsave = () => {
    refetchCustomerData();
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  const handleOpenNewDialog=()=>{
    setNewDialog(true)
  }

  const ProfileOutSideClick = useClickOutside(() => {
    setIsEditPopupOpen(false);
  });

  const handleToggleUserActive=(data)=>{
    const payload={
      "id":data?.customer_id?.value,
      "status":data?.enabled_status?.value==1?0:1
  }
    updateUserActive({token,data:payload})
  }

  const handleActiveChange=(value)=>{
    setQuiFilter({...quiFilter,status:value})
  }

  const handleTypeChange=(value)=>{
    setQuiFilter({...quiFilter,external:value})
  }

  const handleDeleteExternalUser=(data)=>{
   
    const payload={
      "id":data?.customer_id?.value,
    }
  deleteExternalUser({token,data:payload})
  }


  const handleShowHover=(data,header)=>{
    setShowHoverId({data,header})
    // console.log(data,"data aakash")
  }

  const handleShowDetails=(value,headerKey)=>{
    setOpenShowDetails(value && {customerData:value,headerKey})
    
  }

  const isHoverDetailsOpen = (item, header) => {
    const customerIdMatches =
      showHoverId?.data?.customer_id?.value === item?.customer_id?.value;
    const headerKeyMatches = showHoverId?.header?.key === header?.key;
  
    return customerIdMatches && headerKeyMatches;
  };

  return (
    <Fragment>
      <FirstMessage
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onHide={hideAlert}
      />
      <HeaderDashboard />
      <nav className="">
        <div className="container ">
          <div class="flex justify-between ">
            <div class="flex justify-between ">
              <div class="tablemaindiv customerTaskTable">
                {isPopupOpen && (
                  <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                  <AddUserModal
                    closePopup={closePopup}
                    handelsave={handelsave}
                    title='Add User'                    
                  />
                </div>
                )}

                {isEditPopupOpen && (
                    <Drawer
                    anchor="right"
                    open={isEditPopupOpen}
                    onClose={closePopup}
                  >
                    <div>
                      <UserProfileView 
                        closePopup={closePopup}
                        handelsave={handelsave}
                        title='Add User'  
                        editData={editData}               
                      />
                    </div>
                  </Drawer>
                )}

                {/* {editData && (
                  <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                    <AddUserModal
                      closePopup={closePopup}
                      handelsave={handelsave}
                      editData={editData} 
                      title='Edit User'                     
                    />
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <div className="md:hidden">
            <button className="text-white focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>        
      </nav>
      <div class="flex gap-10 px-5 mt-14">
        <div className="w-1/5">
        
          <SideBar />
        </div>
        <div className="w-9/12 mt-2">           
          <CustomerListTable
            tableDetail={{
              tableidclass: "fullscreen-table",
              tableparentidclass: "tableparent",
              tablenameclass: "font-medium text-16 text-203C50 capitalize",
              tablename: "All Users",
              tableSearch: true,
              tableFilter: true,
              forcefullyfreeze: true,
              tableResizer: false,
              tableZoom: true,
              tableheader: false,
              dataactivity: true,
              headeraddfield: false,
              rowDisable: true,
              addbutton: (
                <button
                  onClick={openPopup}
                  type="button"
                  className="text-2541558A hover:text-254155"
                >
                  <i className="bi bi-plus-lg"></i>
                </button>
              ),
            }}
            thead={UserHeader}
            tbody={customer}
            tablePaginationData={setCustomerTablePagination}
            initialFilter={initialFilter}
            handleNavigate={handleNavigate}
            navigateNameCondition={["user_name","reporting","enabled"]}
            HoverOnCustomerName
            openShowDetails={openShowDetails}
            handleShowDetails={handleShowDetails}
            // ExtraFieldComponent={{
            //   user_name: (item, header, tableDetail) => {
            //     const parsedData = parseJSONValue(item);
            //     return (
            //          <UserPageDetails
            //             ParentId={tableDetail}
            //             Data={parsedData}
            //             HoverActiveClass={"tablehoverelement"}
            //           />
            //     );
            //   },
            // }}
            TableActiveFilter={UserActiveFilters}
            UserActiveFlag={quiFilter.status}
            TableTypeFilter={UserTypeFilters}
            UserTypeFlag={quiFilter.external}
            handleActiveChange={handleActiveChange}
            handleTypeChange={handleTypeChange}
            ExtraFieldComponent={{
              user_name: (item, header) => (
                <div className="absolute w-full h-full" onMouseOver={()=>handleShowHover(item,header)} onMouseOut={()=>handleShowHover(null)}>
                   <div >
                      <UserHoverDetails  
                       open={isHoverDetailsOpen(item, header)}
                      onClose={()=>handleShowHover(null)}
                      item={item}
                      header={header}
                      handleShowDetails={handleShowDetails}
                      handleShowHover={handleShowHover}

                      />
                  </div>
                 
                </div>
              ),
              reporting: (item, header) => (
                <div className="absolute w-full h-full" onMouseOver={()=>handleShowHover(item,header)} onMouseOut={()=>handleShowHover(null)}>
                   <div >
                      <UserHoverDetails  
                       open={isHoverDetailsOpen(item, header)}
                      onClose={()=>handleShowHover(null)}
                      item={item}
                      header={header}
                      handleShowDetails={handleShowDetails}
                      handleShowHover={handleShowHover}

                      />
                  </div>
                 
                </div>
              ),
              enabled: (item, header, tableDetail) => {
                const parsedData = parseJSONValue(item);
                return (
                  <div className={`absolute -top-[10px]`} key={item?.id}>
                  <label className={`inline-flex cursor-pointer`}>
                    <input key={item?.id} type={`checkbox`} checked={item?.enabled_status.value} value={item?.enabled_status} className={`sr-only peer cursor-pointer`} onClick={()=>handleToggleUserActive(item)}/>
                    <div
                      className={`mt-3 ml-4`}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="15" viewBox="0 0 26 15" fill="none">
                      <path d="M18.3182 0.777832H7.68182C3.43909 0.777832 0 3.91168 0 7.77783C0 11.644 3.43909 14.7778 7.68182 14.7778H18.3182C22.5609 14.7778 26 11.644 26 7.77783C26 3.91168 22.5609 0.777832 18.3182 0.777832Z" fill="#0A63E9"/>
                      <path d="M18.3182 13.7009C21.908 13.7009 24.8182 11.0491 24.8182 7.77783C24.8182 4.50661 21.908 1.85476 18.3182 1.85476C14.7283 1.85476 11.8182 4.50661 11.8182 7.77783C11.8182 11.0491 14.7283 13.7009 18.3182 13.7009Z" fill="#E8F5E9"/>
                      </svg>
                      </div>
                    {Boolean(quiFilter.external) && 
                    <button onClick={()=>handleDeleteExternalUser(item)} className="ml-[10px] pt-2">
                      <DeleteIcon color={"red"} size={22}/>
                    </button>}
                  </label>
                </div>
                );
              },
              
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default UserPage;
