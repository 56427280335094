import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  BASE_URL,
  GET_CUST_COMMN,
  UPDATE_SAVE_OTHERDETAIL_DATA,
  CUSTOMER_OTHER_DETAILS,
  CUSTMOER_NOTES_DATA_GET,
  CUSTOMER_REPORTING_DATA_GET,
  CUSTOMER_ADDRESS_DATA_GET,
  CUSTMOER_ARTIFACT_DATA_GET,
  CUSTOMER_SERVICE_DATA_GET,
  CUSTOMER_CONTACT_DATA_GET,
  CUSTOMER_SOCIAL_DATA_GET,
  CUSTOMER_TASK_CATEGORY,
  CUSTOMER_TASK_DATA_GET,
  CUSTOMER_NOTES_COLOR_CODE,
  CUSTOMER_MANAGER_LIST,
  CUSTOMER_ARTIFACT_DATATYPE,
  CUSTOMER_DETAILS_DATA,
  CUSTOMER_DESINATION_LIST,
  CUSTOMER_GET_USER_DATA,
  CUSTOMER_TASK_POD_DATA,
  TASK_VIEW_DATA,
  CUSTOMER_SERVICE_CATEGORY,
  CUSTOMER_SOCIAL_CATEGORY,
  GET_COMPANY_DEFAULT_ADDRESS,
  GET_VENDOR_DEFAULT_ADDRESS,
  CUSTOMER_DEPARTMENT_LIST,
} from "../constants";

export const customerServiceCategoryApi = createApi({
  reducerPath: "customerServiceCategoryApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchServiceCategory: builder.query({
      query: ({ token }) => ({
        url: CUSTOMER_SERVICE_CATEGORY,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useFetchServiceCategoryQuery,
  useLazyFetchServiceCategoryQuery,
} = customerServiceCategoryApi;

export const customerSocialCategoryApi = createApi({
  reducerPath: "customerSocialCategoryApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchSocialCategory: builder.query({
      query: ({ token }) => ({
        url: CUSTOMER_SOCIAL_CATEGORY,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const { useFetchSocialCategoryQuery, useLazyFetchSocialCategoryQuery } =
  customerSocialCategoryApi;

export const customerDesignationApi = createApi({
  reducerPath: "customerDesignationApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerDesignation: builder.query({
      query: ({ token }) => ({
        url: CUSTOMER_DESINATION_LIST,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useFetchCustomerDesignationQuery,
  useLazyFetchCustomerDesignationQuery,
} = customerDesignationApi;

export const customerSelectApi = createApi({
  reducerPath: "customerSelectApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerCommn: builder.query({
      query: (token) => ({
        url: GET_CUST_COMMN,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    updateSaveOtherDetail: builder.mutation({
      query: ({ token, data }) => ({
        url: UPDATE_SAVE_OTHERDETAIL_DATA, // Replace with your endpoint
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchCustomerCommnQuery,
  useLazyFetchCustomerCommnQuery,
  useUpdateSaveOtherDetailMutation,
} = customerSelectApi;

export const customerEditDataApi = createApi({
  reducerPath: "customerEditDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["CustomerOtherDetailData"],
  endpoints: (builder) => ({
    fetchCustomerEdit: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTOMER_OTHER_DETAILS}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          providesTags: ["CustomerOtherDetailData"],
        };
      },
    }),
  }),
});

export const { useFetchCustomerEditQuery, useLazyFetchCustomerEditQuery } =
  customerEditDataApi;

export const customerNotesDataApi = createApi({
  reducerPath: "customerNotesDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerNotesData: builder.query({
      query: ({ token, trimmedCustomerId }) => {
        return {
          url: `${CUSTMOER_NOTES_DATA_GET}${trimmedCustomerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerNotesDataQuery,
  useLazyFetchCustomerNotesDataQuery,
} = customerNotesDataApi;

export const customerTeamsDataApi = createApi({
  reducerPath: "customerTeamsDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerTeamsData: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTOMER_REPORTING_DATA_GET}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerTeamsDataQuery,
  useLazyFetchCustomerTeamsDataQuery,
} = customerTeamsDataApi;

export const customerAddressDataApi = createApi({
  reducerPath: "customerAddressDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerAddressData: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTOMER_ADDRESS_DATA_GET}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerAddressDataQuery,
  useLazyFetchCustomerAddressDataQuery,
} = customerAddressDataApi;

export const customerArtiFactDataApi = createApi({
  reducerPath: "customerArtiFactDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerArtiFactData: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTMOER_ARTIFACT_DATA_GET}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerArtiFactDataQuery,
  useLazyFetchCustomerArtiFactDataQuery,
} = customerArtiFactDataApi;

export const customerServicesDataApi = createApi({
  reducerPath: "customerServicesDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerServicesData: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTOMER_SERVICE_DATA_GET}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerServicesDataQuery,
  useLazyFetchCustomerServicesDataQuery,
} = customerServicesDataApi;

export const customerContactPersonDataApi = createApi({
  reducerPath: "customerContactPersonDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerContactPersonData: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTOMER_CONTACT_DATA_GET}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerContactPersonDataQuery,
  useLazyFetchCustomerContactPersonDataQuery,
} = customerContactPersonDataApi;

export const customerSocialLinkDataApi = createApi({
  reducerPath: "customerSocialLinkDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerSocialLinkData: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTOMER_SOCIAL_DATA_GET}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerSocialLinkDataQuery,
  useLazyFetchCustomerSocialLinkDataQuery,
} = customerSocialLinkDataApi;

export const customerReportingTeamDataApi = createApi({
  reducerPath: "customerReportingTeamDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerReportingTeamsData: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTOMER_REPORTING_DATA_GET}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerReportingTeamsDataQuery,
  useLazyFetchCustomerReportingTeamsDataQuery,
} = customerReportingTeamDataApi;

export const customerTaskDataApi = createApi({
  reducerPath: "customerTaskDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchTaskData: builder.query({
      query: ({ token, jsonData }) => {
        const queryParams = new URLSearchParams(jsonData).toString();
        return {
          url: `${CUSTOMER_TASK_CATEGORY}?${queryParams}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const { useFetchTaskDataQuery, useLazyFetchTaskDataQuery } =
  customerTaskDataApi;

export const customerTasksTableDataApi = createApi({
  reducerPath: "customerTasksTableDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchTasksTableData: builder.query({
      query: ({ token, jsonData }) => {
        const queryParams = new URLSearchParams(jsonData).toString();
        return {
          url: `${CUSTOMER_TASK_DATA_GET}?${queryParams}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const { useFetchTasksTableDataQuery, useLazyFetchTasksTableDataQuery } =
  customerTasksTableDataApi;

export const customerGetOtherDetailDataApi = createApi({
  reducerPath: "customerGetOtherDetailDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerGetOtherDetailData: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTOMER_OTHER_DETAILS}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerGetOtherDetailDataQuery,
  useLazyFetchCustomerGetOtherDetailDataQuery,
} = customerGetOtherDetailDataApi;

export const customerNotesColorDataApi = createApi({
  reducerPath: "customerNotesColorDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerNotesColorData: builder.query({
      query: ({ token }) => {
        return {
          url: `${CUSTOMER_NOTES_COLOR_CODE}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerNotesColorDataQuery,
  useLazyFetchCustomerNotesColorDataQuery,
} = customerNotesColorDataApi;

export const customerManagerListDataApi = createApi({
  reducerPath: "customerManagerListDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerManagerListData: builder.query({
      query: ({ token }) => {
        return {
          url: `${CUSTOMER_MANAGER_LIST}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerManagerListDataQuery,
  useLazyFetchCustomerManagerListDataQuery,
} = customerManagerListDataApi;

export const customerArtiFactApi = createApi({
  reducerPath: "customerArtiFactApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerArtiFactDataList: builder.query({
      query: ({ token }) => {
        return {
          url: `${CUSTOMER_ARTIFACT_DATATYPE}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerArtiFactDataListQuery,
  useLazyFetchCustomerArtiFactDataListQuery,
} = customerArtiFactApi;

export const customerAddressDataBaseOnIdApi = createApi({
  reducerPath: "customerAddressDataBaseOnIdApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerAddressDataBasedOnId: builder.query({
      query: ({ token, customerId }) => {
        return {
          url: `${CUSTOMER_DETAILS_DATA}${customerId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerAddressDataBasedOnIdQuery,
  useLazyFetchCustomerAddressDataBasedOnIdQuery,
} = customerAddressDataBaseOnIdApi;

export const customerGroupApi = createApi({
  reducerPath: "customerGroupApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerGroupList: builder.query({
      query: ({ userToken }) => {
        return {
          url: `${CUSTOMER_DETAILS_DATA}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerGroupListQuery,
  useLazyFetchCustomerGroupListQuery,
} = customerGroupApi;

export const customeTaskUserList = createApi({
  reducerPath: "customeTaskUserList",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerUseListData: builder.query({
      query: ({ token }) => {
        return {
          url: `${CUSTOMER_GET_USER_DATA}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerUseListDataQuery,
  useLazyFetchCustomerUseListDataQuery,
} = customeTaskUserList;

export const customeTaskPOdList = createApi({
  reducerPath: "customeTaskPOdList",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerTaskPodData: builder.query({
      query: ({ tokens }) => {
        return {
          url: `${CUSTOMER_TASK_POD_DATA}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokens}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerTaskPodDataQuery,
  useLazyFetchCustomerTaskPodDataQuery,
} = customeTaskPOdList;

export const customerEditTaskApi = createApi({
  reducerPath: "customerEditTaskApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchEditTask: builder.query({
      query: ({ token, taskid }) => {
        return {
          url: `${TASK_VIEW_DATA}${taskid}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const { useFetchEditTaskQuery, useLazyFetchEditTaskQuery } =
  customerEditTaskApi;

export const customeAllTaskPOdList = createApi({
  reducerPath: "customeAllTaskPOdList",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerAllTaskPodData: builder.query({
      query: ({ token }) => {
        return {
          url: `${CUSTOMER_TASK_POD_DATA}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useFetchCustomerAllTaskPodDataQuery,
  useLazyFetchCustomerAllTaskPodDataQuery,
} = customeAllTaskPOdList;

export const companyAddressDataApi = createApi({
  reducerPath: "companyAddressDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCompanyAddressData: builder.query({
      query: ({ token, companyId }) => {
        return {
          url: `${GET_COMPANY_DEFAULT_ADDRESS}${companyId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const { useFetchCompanyAddressDataQuery } = companyAddressDataApi;

export const getVendorDefaultAddress = createApi({
  reducerPath: "getVendorDefaultAddress",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchVendorDefaultAddress: builder.query({
      query: ({ token, vendorId }) => {
        return {
          url: `${GET_VENDOR_DEFAULT_ADDRESS}${vendorId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const { useFetchVendorDefaultAddressQuery } = getVendorDefaultAddress;

export const customerDepartment = createApi({
  reducerPath: "customerDepartment",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerDepartment: builder.query({
      query: ({ token }) => ({
        url: CUSTOMER_DEPARTMENT_LIST,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const { useFetchCustomerDepartmentQuery } = customerDepartment;
