
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
     BASE_URL, 
     GET_MANAGE_GROUPS, 
     GET_MANAGE_PERMISSION,
     PERMISSION_ADDUPDATE,
     PERMISSION_MODULES,
     SELECT_PERMISSION_LEVEL,

    } from '../constants';

export const managePermissions = createApi({
  reducerPath: 'managePermissions',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchPermissionTableData: builder.query({
      query: ({ token,pagination }) => {
        
        const params = new URLSearchParams({
          ...pagination,
        });
    
        const url = `${GET_MANAGE_PERMISSION}?${params.toString()}`;
        return {
          url,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
      },
    }),
  
  }),
});



export const {
    useFetchPermissionTableDataQuery
  } = managePermissions;


  export const PermissionsAddUpdate = createApi({
    reducerPath: 'PermissionsAddUpdate',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
     
      PermissionsAddUpdate: builder.mutation({
        query: ({token,data}) => ({
          url : `${PERMISSION_ADDUPDATE}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      }),
    }),
  });
  
  
  
  export const {
      usePermissionsAddUpdateMutation,
  } = PermissionsAddUpdate;
  

  
export const selectPermission = createApi({
    reducerPath: 'selectPermission',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
      fetchSelectPermission: builder.query({
        query: (token) => {
          
      
          const url = `${SELECT_PERMISSION_LEVEL}`;
          return {
            url,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          };
        },
      }),
    
    }),
  });
  
  
  
  export const {
      useFetchSelectPermissionQuery
    } = selectPermission;
    



     
export const PermissionModule = createApi({
  reducerPath: 'PermissionModule',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchPermissionModule: builder.query({
      query: ({token,id}) => {
        
    
        const url = id ? `${PERMISSION_MODULES}?id=${id}` : PERMISSION_MODULES;
        return {
          url,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
      },
    }),
  
  }),
});



export const {
    useFetchPermissionModuleQuery,
    useLazyFetchPermissionModuleQuery
  } = PermissionModule;


  