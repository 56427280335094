import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAddresses,
  deleteAddresssAsync,
  removeAddressAtIndex,
} from "../../utils/customer/addressSlice";
import { useParams } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import { useFetchCustomerAddressDataQuery } from "../../utils/customer/customerSelect";
import { useFetchCompanyCommonDataQuery } from "../../utils/company/companySelector";
import FullScreenMode from "../GlobalFeature/Table/HeadTop/FullScreenMode";

import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import CustomerListTable from "./CustomerListTable";
import CustomerAddressStaticHeader from "./CustomerAddressStaticHeader";
import CustomerAddressModel from "./CustomerAddressModel";
import { useFetchCustomerAddressTableDataQuery } from "../../utils/customer/customerTaskTable";

import { useFetchCustomerAllAddressTableDataQuery } from "../../utils/customer/customerTaskTable";
import { useUpdateCustomerAllAddressTableDataMutation } from "../../utils/customer/customerTaskTable";
import { useCustomerTableMutation } from "../../utils/customer/customerTable";


const CustomerAddress = ({leftTab}) => {
  const { isFullscreen, toggleFullscreen } = useFullscreen();

  const { id:customerId } = useParams();
  const { token } = useRequireAuth();
  const addresses = useSelector((state) => state.customerAddresses.addresses);
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [refreshKey, setRefreshKey] = useState(0);
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const [customer,setCustomer]=useState([]);
  const [initialFilter,setInitialFilter]=useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const showMessage = useSelector(
    (state) => state.customerAddresses.showMessage
  );

  const [addressData,setAddressData]=useState();
  const [isDefaultOptionHidden, setIsDefaultOptionHidden] = useState(false);

  const message = useSelector((state) => state.customerAddresses.message);
  const dispatch = useDispatch();

  const { data: customerTableALL, refetch: refetchCustomerData } =
  useFetchCustomerAddressTableDataQuery({
      customerId: customerId,
      pagination: `${customerTablePagination}`,
      token: token,
    });
  const {
    data: customerAddressData,
    refetch
  } = useFetchCustomerAddressDataQuery({ token, customerId },{
    skip:leftTab!==0
  });
  


  const deleteAddressHandler = async (index, id) => {
    dispatch(deleteAddresssAsync({ id, token }));
    dispatch(removeAddressAtIndex(index));
  };
  const {
    data: companyCommnData,
    error,
    isLoading,
  } = useFetchCompanyCommonDataQuery(token);

 
  const openPopup = () => {
    setAddressData(true);
  };
  const closePopup = () => {
    setAddressData(false);
    refetch()
   
  };

  useEffect(()=>{
    if(customerTableALL && customerTableALL[0]?.id){
      setIsSubmitting(false);
    }
  },[customerTableALL,refetchCustomerData]);


  const { data: customerAddress, refetch: refetchCustomerAddress } =
  useFetchCustomerAllAddressTableDataQuery({
      token: token,
      customerId: customerId,
      pagination: `${customerTablePagination}`,

  });

  useEffect(() => {
    if (customerAddress && leftTab===0) {
      setCustomer(customerAddress.data.data??'');
      const { data: omitData, ...restData } = customerAddress.data;
      setInitialFilter(restData??'');
    }
  }, [customerAddress, customerTablePagination,leftTab]);

  const [updateCustomerAllAddressTableData, { CustomerDropLoad, CustomerDroperror, }] = useUpdateCustomerAllAddressTableDataMutation();

  const customerTableFieldUpdate = async (field_name,value,id) => {
    const updateData = {
      field_name: value?.field_name,
      value: field_name?.value,
      id: value?.id
    }
    try {
      const result = await updateCustomerAllAddressTableData({ token: token, data: updateData }).unwrap();
      if(result){
        refetchCustomerAddress()
        {result?.data?.original ?  setAlert({ visible: true, message: result?.data?.original?.error, type: 'danger' }) : setAlert({ visible: true, message: result?.message, type: 'success' })} 
      }
    } catch (error) {
      setAlert({ visible: true, message: 'Error updating data!', type: 'danger' });
    }
  }

const handelSaveData=()=>{
  refetch();
  refetchCustomerAddress();
}

const [
  CustomerTableDrop,
  { data: customerCommnData, refetch: refetchCustomerCommnData },
] = useCustomerTableMutation();

const fetchCustomerTable = (data) => {
  const completedata = {
    "model_name": "customer_teams",
    "field_name": data,
  };
  CustomerTableDrop({token:token,data:completedata});
}

const handleNavigate = (taskid) => {
  if (taskid) {
    setAddressData(taskid);
  }
};

return (
    <>
      <div className="tablemaindiv" key={refreshKey}>
        <div className={` h-full ${isFullscreen ? 'mt-[-16px]' : 'pt-2'} `} >
                {addressData && (
                  <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                    <CustomerAddressModel isOpen={''} closePopup={closePopup}  addressid={addressData} handelSaveData={handelSaveData}
                     AddressData={customerAddressData}
                     refetchAddress={refetchCustomerAddress}
                     />
                  </div>
                )}
            {isFullscreen ? (
              <Fragment>
                <div className="flex justify-end ">
                  <FullScreenMode event={(e) => toggleFullscreen(e)}>
                    {isFullscreen ? (
                      <i className="bi bi-fullscreen-exit"></i>
                    ) : (
                      <i className="bi bi-fullscreen text-xl text-284356"></i>
                    )}
                  </FullScreenMode>
                </div>
                <CustomerListTable
                    tableDetail={{
                      tableidclass: "fullscreen-table",
                      tableparentidclass: "tableparent",
                      tablename: "Address",
                      tablenameclass:
                        "font-medium text-16 text-203C50 capitalize",
                      tableSearch: true,
                      tableFilter: true,
                      tableResizer: true,
                      tableheader: false,
                      dataactivity:true,
                      datetedata:true,
                      forcefullyfreeze:true,
                      addbutton: (
                        <button 
                          onClick={openPopup}
                          type="button"
                          className="text-2541558A hover:text-254155"
                        >
                            <i className="bi bi-plus-lg"></i>
                        </button>
                    )
                    }}
                    thead={CustomerAddressStaticHeader}
                    tbody={customer}
                    fetchtableBodyData={fetchCustomerTable}
                    customerCommnData={customerCommnData}
                    tablePaginationData={setCustomerTablePagination}
                    TableFieldUpdate={customerTableFieldUpdate}
                    initialFilter={initialFilter}
                    navigateNameCondition={["address_type"]}
                    handleNavigate={handleNavigate}

                  />
              </Fragment>
            ) : (
              <Fragment>
                <div className="flex justify-between">
                  <h2 className="font-Vietnam text-20 font-600 mb-2 text-203C50">
                    Address
                  </h2>
                  <div className="flex gap-8">
                   
                    <div className="flex items-center gap-2">
                      <button
                        type="button"
                        className="text-2541558A hover:text-254155"
                        onClick={openPopup}
                      >
                        <i className="bi bi-plus-lg"></i>
                      </button>                      
                     
                          
                      <FullScreenMode event={(e) => toggleFullscreen(e)}>
                        {isFullscreen ? (
                          <i className="bi bi-fullscreen-exit"></i>
                        ) : (
                          <i className="bi bi-fullscreen text-xl text-284356"></i>
                        )}
                      </FullScreenMode>
                     
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
         

          {/* <div className="bg-white p-2">
            {customerAddressData?.length > 0 ? (
              customerAddressData.map((address, index) => (
                        <div className={`grid grid-cols-4 gap-x-3 p-5 `}>
                            <div className="mb-3">
                              <p class="text-sm font-light text-848687">Address Type</p>
                              <p class="text-sm font-normal text-2C495D">{address.address_type}</p>                      
                            </div>                   
                            <div className="mb-3">
                              <p className="text-sm font-light text-848687">Address</p>
                              <p className="text-sm font-normal text-2C495D">{address.address}</p>                      
                            </div>
                            <div className="mb-3">
                              <p className="text-sm font-light text-848687">City</p>
                              <p className="text-sm font-normal text-2C495D">{address.city}</p>                      
                            </div>
                            <div className="mb-3">
                              <p className="text-sm font-light text-848687">State</p>
                              <p className="text-sm font-normal text-2C495D">{address.state}</p>
                            </div>
                            <div className="mb-3">
                            <p class="text-sm font-light text-848687">Country</p>
                              <p class="text-sm font-normal text-2C495D">
                             {address.country_id}
                              </p>                      
                            </div>
                            <div className="mb-3">
                              <p class="text-sm font-light text-848687">Zip Code</p>
                              <p class="text-sm font-normal text-2C495D">{address.zip_code}</p>
                            </div>
                            <div className="mb-3">
                              <p class="text-sm font-light text-848687">Point of Contact</p>
                              <p class="text-sm font-normal text-2C495D">{address.point_of_contact}</p>                      
                            </div> 
                            <div className="mb-3">
                              <p class="text-sm font-light text-848687">Email</p>
                              <p class="text-sm font-normal text-2C495D">{address.primary_email}</p>
                            </div>
                            <div className="mb-3">
                              <p class="text-sm font-light text-848687">Phone 1 </p>
                              <p class="text-sm font-normal text-2C495D">{address.phone1}</p>                      
                            </div>
                            <div className="mb-3">
                              <p class="text-sm font-light text-848687">Phone 2</p>
                              <p class="text-sm font-normal text-2C495D">{address.phone2}</p>                      
                            </div>
                           
                            <div className="mb-3">
                              <p class="text-sm font-light text-848687">Secondary Email</p>
                              <p class="text-sm font-normal text-2C495D">{address.secondry_email}</p>
                            </div>
                        </div>
             ))
            ) :
              
               <Fragment>
               <div className={`grid grid-cols-3 gap-x-3  `}>
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">Address Type</p>
                 <p className="text-sm font-normal text-2C495D"></p>                      
               </div>
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">Point of Contact</p>
                 <p className="text-sm font-normal text-2C495D"></p>                      
               </div>                    
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">Address</p>
                 <p className="text-sm font-normal text-2C495D"></p>                      
               </div>
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">City</p>
                 <p className="text-sm font-normal text-2C495D"></p>                      
               </div>
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">State</p>
                 <p className="text-sm font-normal text-2C495D"></p>
               </div>
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">Zip Code</p>
                 <p className="text-sm font-normal text-2C495D"></p>
               </div>
               <div className="mb-3">
               <p className="text-sm font-light text-848687">Country</p>
                 <p className="text-sm font-normal text-2C495D">
                
                 </p>                      
               </div>
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">Phone 1</p>
                 <p className="text-sm font-normal text-2C495D"></p>                      
               </div>
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">Phone 2</p>
                 <p className="text-sm font-normal text-2C495D"></p>                      
               </div>
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">Primary Email</p>
                 <p className="text-sm font-normal text-2C495D"></p>
               </div>
               <div className="mb-3">
                 <p className="text-sm font-light text-848687">Secondary Email</p>
                 <p className="text-sm font-normal text-2C495D"></p>
               </div>
             </div>
             </Fragment>
            }
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CustomerAddress;
