import CryptoJS from 'crypto-js';
import React, { useState, useEffect } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import {
  useFetchTaskDataQuery,
  useFetchCustomerUseListDataQuery,
  useFetchCustomerTaskPodDataQuery,
  useFetchEditTaskQuery,
} from "../../utils/customer/customerSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveTasks, hideMessage } from "../../utils/customer/customerTaskSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomerEditTaskActivity from "./CustomerEditTaskActivity";
import SearchableInput from "./SearchableInput";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";
import { useFetchEnumFieldGetApiQuery } from '../../utils/enumApi';

import { RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@mui/material';

const validationRules = {
  daily_interval: { required: true, number: true, errorMessage: 'Enter a valid number for daily interval.' },
  weekly_interval: { required: true, number: true, errorMessage: 'Enter a valid number for weekly interval.' },
  monthly_day: { required: true, number: true, errorMessage: 'Enter a valid day for monthly interval.' },
  monthly_interval: { required: true, number: true, errorMessage: 'Enter a valid interval for monthly recurrence.' },
  frequency: { required: true, errorMessage: 'Please select a recurrence pattern.' },
  recurr_pattern_times: { required: true, number: true, errorMessage: 'Enter a valid number for pattern times.' },
  recurr_pattern_days: { required: true, errorMessage: 'Please select days of the week.' },
};

const RecurrenceModal = ({
  ActivateRecur,
  closePopup,
  taskid,
  handelSubmitData,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  StartTime,
  EndTime,
  formData,
  setFormData
}) => {
  
  

  
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);



  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    
  };


  // const [formData, setFormData] = useState({
  //   frequency: taskid?.frequency || "daily",
  //   intervals:  taskid?.intervals || "",
  //   every_weeks: "",
  //   daily_recur: "",
  //   monthly_recur: "",
  //   yearly_recur: "",
  //   occurrences: "",
  //   daily_option:  taskid?.daily_option || 'every_n_days',
  //   weekly_days: initialWeeklyDays,
  //   endBy: 'end_by',
  //   monthly_option: taskid?.monthly_option || 'specific_day',
  //   monthly_week:  taskid?.monthly_week	 || 'first',
  //   day_of_week: taskid?.day_of_week,
  //   day_of_month:  taskid?.day_of_month,
  //   yearly_option:  taskid?.yearly_option || 'specific_year',
  // });

  
  const calculateEndDate = (startDate, intervals) => {
    const newEndDate = new Date(startDate);
    newEndDate.setDate(newEndDate.getDate() + parseInt(intervals, 10));
    return newEndDate;
  };

  useEffect(() => {
    if (formData?.daily_option === 'every_weekday' && formData?.frequency === 'daily') {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate() + 7);
      setEndDate(newEndDate);
      setFormData({ ...formData, "intervals": 1 });
    }
     else if (formData.intervals) {
      const newEndDate = calculateEndDate(startDate, formData.intervals);
      setEndDate(newEndDate);
    }
  }, [formData?.intervals, formData?.daily_option, startDate]);
  

  const { values, errors, touched, handleChange, handleBlur, isFormValid } =
    useCustomFormValidation(formData, validationRules);

    

  const handleFieldChange = (e) => {
    handleChange(e);
    const { name, value } = e.target;
  
    // setFormData({ ...formData, [name]: value });
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
    
  };

  // const {
  //   data: companyCommnData,
  //   error,
  //   isLoading,
  // } = useFetchEnumFieldGetApiQuery({token,table_name: "recurrence_patterns" });


  // useEffect(() => {
  //   if(token){
  //     fetchOptions('your_table_name', 'your_column_name');
  //   }
  // }, [token]);



  // const handleRecurrenceChange = (event) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     radioselected: {
  //       ...prevFormData.radioselected,
  //       daily_option: event.target.value
  //     }
  //   }));
  // };

  const handleEndByChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      endBy: event.target.value
    }));

    
  };

  
  const toggleDaySelection = (day) => {
    setFormData((prevFormData) => {
      const { weekly_days } = prevFormData;
      const isSelected = weekly_days.includes(day);

      return {
        ...prevFormData,
        weekly_days: isSelected
          ? weekly_days.filter((d) => d !== day)
          : [...weekly_days, day],
      };
    });
  };

  const handleRecurTask = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // setFormData(prevFormData => ({
    //   ...prevFormData,
    //   selection: "entire"
    // }));
    // const validationErrors = validateForm();

    // if (isFormValid()) {
    // ActivateRecur(formData);

    // }

    // if(formData?.recurr_pattern === "daily"){
    //   if(formData?.radioselected?.recurrence === "every"){
    //       if(formData?.every_day === ''){
    //              return;
    //       }
        
    //   }
    // }
      
    // ActivateRecur(formData);
    setFormData(prevFormData => {
      const updatedFormData = {
        ...prevFormData,
        selection: "entire",
        is_recurrence: 1,
      };
      
      ActivateRecur(updatedFormData); 
  
      return updatedFormData;
    });
  };

  // const handleSubmitTask = async (event) => {
  //   event.preventDefault();

  //   if (
  //     !validateForm() ||
  //     !selectedItem ||
  //     !selectedItem.id ||
  //     !event.target.task_pod.value ||
  //     !event.target.subject.value ||
  //     !event.target.category_id.value ||
  //     !startDate ||
  //     !endDate
  //   ) {
  //     if (!selectedItem || !selectedItem.id) setOwnerError("Owner is required");
  //     console.log("Form has errors");
  //     return false;
  //   }

  //   const taskData = {
  //     subject: event.target.subject.value,
  //     category_id: event.target.category_id.value,
  //     priority: event.target.priority.value,
  //     task_pod: event.target.task_pod.value,
  //     start_date: startDate,
  //     end_date: endDate,
  //     customer_id: customerId,
  //     company_id: parsedCompanyData.company_id,
  //     description: event.target.description.value,
  //     owner: selectedItem,
  //     contact_name: event.target.contact_name.value,
  //     status: event.target.status.value,
  //   };
  //   try {
  //     await dispatch(saveTasks({ taskData, token }));
  //     await handelSubmitData(customerId);
  //     closePopup();
  //     event.target.reset();
  //   } catch (error) {}
  // };



  useEffect(() => {
    if (formData?.occurrences != null) {
      const daysAfter = parseInt(formData.occurrences, 10);
      if (!isNaN(daysAfter)) {
        // const futureDate = new Date();
        const futureDate = new Date(startDate);
        futureDate.setDate(futureDate.getDate() + daysAfter);
        setEndDate(futureDate);
      } 
      // else {
      //   console.error('Invalid daysAfter value:', formData.occurrences);
      // }
    }
  }, [formData?.occurrences]);


  const daysInMonth = (month) => {
    const days = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return days[month - 1];
  };

  const handleDayValidation = (e) => {
    const value = e.target.value;
    const maxDays = daysInMonth(formData?.intervals);

    if (Number(value) < 1 || Number(value) > maxDays) {
      e.target.value = value.slice(0, -1);
    }
  };
  
  const renderRecurrenceSection = () => {
    switch (formData?.frequency) {
      case "daily":
        return (
          <div>
            <div className="flex gap-5 py-2 items-center">
              <div>
                <input
                  type="radio"
                  name="daily_option"
                  value={"every_n_days"}
                  className="radio  checked:border-indigo-500 checked:bg-indigo-900"
                  checked={formData?.daily_option === 'every_n_days'}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="text-xs">Every</div>
           
              <div className="appearance-none pb-5 px-3 w-20 h-9 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block">
                <input
                  type="number"
                  id="intervals"
                  name="intervals"
                  value={formData?.intervals}
                  onChange={handleFieldChange}
                  className={ `w-16 p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white disabled:opacity-50 disabled:bg-[#EBEBE4]`}
                  placeholder=""
                  required
                  disabled={formData?.daily_option === 'every_weekday'}
                />
              </div>
              <div className="text-xs">Day &#40; s &#41;</div>
            </div>
            <div className="flex items-center gap-5 ">
              <div>
                <input
                  type="radio"
                  name="daily_option"
                  value={"every_weekday"}
                  className="radio  checked:border-indigo-500 checked:bg-indigo-900"
                  onChange={handleFieldChange}
                  checked={formData?.daily_option === 'every_weekday'}
                />
              </div>
              <div className="text-xs">Every Weekday</div>
            </div>
          </div>
        );
      case "weekly":
        return (
          <div>
            <div className="flex items-center gap-5">
               <div className="text-xs">Re<u>c</u>ur every</div>
            
              <div className="appearance-none pb-5 px-3 w-20 h-9 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block">
                <input
                  type="number"
                  name="intervals"
                  value={formData?.intervals}
                  onChange={handleFieldChange}
                  className="w-16 p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                 
                />
                
              </div>
              <div className="items-center text-xs">week(s) on:</div>
            </div>


            {/*<div className="gap-2 flex flex-grow">
              <button
                type="button"
                id="toggleButton"
                className=" text-xs font-normal hover:bg-sky-950 hover:text-white focus:bg-sky-950 focus:text-white items-center p-2 px-4 border rounded"
              >
                Sun
              </button>
              <button
                type="button"
                id="toggleButton"
                className=" text-xs font-normal hover:bg-sky-950 hover:text-white focus:bg-sky-950 focus:text-white items-center p-2 px-4 border rounded"
              >
                Mon
              </button>
              <button
                type="button"
                id="toggleButton"
                className=" text-xs font-normal hover:bg-sky-950 hover:text-white focus:bg-sky-950 focus:text-white items-center p-2 px-4 border rounded"
              >
                Tue
              </button>
              <button
                type="button"
                id="toggleButton"
                className=" text-xs font-normal hover:bg-sky-950 hover:text-white focus:bg-sky-950 focus:text-white items-center p-2 px-4 border rounded"
              >
                Wed
              </button>
              <button
                type="button"
                id="toggleButton"
                className=" text-xs font-normal hover:bg-sky-950 hover:text-white focus:bg-sky-950 focus:text-white items-center p-2 px-4 border rounded"
              >
                Thu
              </button>
              <button
                type="button"
                id="toggleButton"
                className=" text-xs font-normal hover:bg-sky-950 hover:text-white focus:bg-sky-950 focus:text-white items-center p-2 px-4 border rounded"
              >
                Fri
              </button>
              <button
                type="button"
                id="toggleButton"
                className=" text-xs font-normal hover:bg-sky-950 hover:text-white focus:bg-sky-950 focus:text-white items-center p-2 px-4 border rounded"
              >
                Sat
              </button>
            </div>
            */}


<div className="gap-2 flex flex-grow mt-4">
      {daysOfWeek.map((day, index) => (
        <button
          key={index}
          type="button"
          className={`text-xs font-normal items-center p-2 px-4 border rounded ${
            formData?.weekly_days?.includes(day)
              ? 'bg-sky-950 text-white'
              : 'hover:bg-sky-950 hover:text-white focus:bg-sky-950 focus:text-white'
          }`}
          onClick={() => toggleDaySelection(day)}
        >
          {day?.slice(0,3)}
        </button>
      ))}
    </div>


          </div>
        );
      case "monthly":
        return (
          <div>
            <div className="flex gap-3 py-2 items-center">
              <div>
                <input
                   type="radio"
                   name="monthly_option"
                   value={"specific_day"}
                   className="radio  checked:border-indigo-500 checked:bg-indigo-900"
                   checked={formData?.monthly_option === 'specific_day'}
                   onChange={handleFieldChange}
                  
                />
              </div>
              <div className="text-xs">Day</div>

              <div className="appearance-none pb-5 px-3 w-20 h-9 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block">
                <input
                  type="number"
                  id="day_of_month"
                  name='day_of_month'
                  value={formData?.day_of_month}
                  onChange={handleFieldChange}
                  className="w-16 p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                  placeholder=""
                  onKeyUp={handleDayValidation}
                  required
                />
              </div>

              <div className="text-xs">Of Every</div>

              <div className="appearance-none pb-5 px-3 w-20 h-9 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block">
                <input
                  type="number"
                  id="intervals"
                  name="intervals"
                  value={formData?.intervals}
                  onChange={handleFieldChange}
                  className=" p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                  placeholder=""
                  // min={1}
                  // max={12}
                  // onKeyUp={() => { }}
                    onKeyUp={(e) => {
                      const value = e.target.value;
                  
                      if (Number(value) < 1 || Number(value) > 12) {
                        e.target.value = value.slice(0, -1);
                      }
                    }}
                  required
                />
              </div>

              <div className="text-xs">Month &#40; s &#41;</div>
            </div>

            <div className="flex gap-2 py-2 items-center">
              <div>
                 <input
                   type="radio"
                   name="monthly_option"
                   value={"weekday_of_month"}
                   className="radio  checked:border-indigo-500 checked:bg-indigo-900"
                   checked={formData?.monthly_option === 'weekday_of_month'}
                   onChange={handleFieldChange}
                  
                />
              </div>
              <div className="text-xs">The</div>

              <div className="flex items-center justify-end">
                <select
                  id={`monthly_week`}
                  name="monthly_week"
                  onChange={handleFieldChange}
                  value={formData?.monthly_week} 
                  className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-9 appearance-none pr-10`}
                > 
                  <option value="first">First</option>
                  <option value="second">Second</option>
                  <option value="third">Third</option>
                  <option value="fourth">Fourth</option>

                </select>
                <div className="absolute flex px-2 pointer-events-none">
                  <h4>
                    <i className="bi bi-chevron-down"></i>
                  </h4>
                </div>
              </div>
              {/* {formErrors?.recurr_pattern && (
                <span className="text-red-500 text-xs">
                  Cannot be left blank.
                </span>
              )} */}

              <div className="flex items-center justify-end">
                <select
                  id={`day_of_week`}
                  name="day_of_week"
                  onChange={handleFieldChange}
                  value={formData?.day_of_week} 
                  className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-9 appearance-none pr-10`}
                >
                  {daysOfWeek.map((day, index) => (
                    <option key={index} value={day.toLowerCase()}>
                      {day}
                    </option>
                  ))}
                </select>
                <div className="absolute flex px-2 pointer-events-none">
                  <h4>
                    <i className="bi bi-chevron-down"></i>
                  </h4>
                </div>
              </div>
              {/* {formErrors.recurr_pattern && (
                <span className="text-red-500 text-xs">
                  Cannot be left blank.
                </span>
              )} */}

              <div className="text-xs">Of Every</div>

              <div className="appearance-none pb-5 px-3 w-20 h-9 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block">
                <input
                  type="number"
                  id="intervals"
                  name="intervals"
                  value={formData?.intervals}
                  onChange={handleFieldChange}
                  className="w-16 p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                  placeholder=""
                  required
                />
              </div>

              <div className="text-xs">Month &#40; s &#41;</div>
            </div>
          </div>
        );
      case "yearly":
        return (
          <div>
            <div className="flex gap-2 py-2 items-center">
               <div className="text-xs">Re<u>c</u>ur every</div>

              <div className="appearance-none pb-5 px-3 w-20 h-9 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block">
                

                <input
                  type="number"
                  id="intervals"
                  name="intervals"
                  value={formData?.intervals}
                  onChange={handleFieldChange}
                  className="w-16 p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                  placeholder=""
                  required
                />


              </div>
              <div className="text-xs">year(s)</div>
            </div>
            <div className="flex gap-2 py-2 items-center">
              <div>
                <input
                  type="radio"
                  name="yearly_option"
                  value={"specific_year"}
                  className="radio  checked:border-indigo-500 checked:bg-indigo-900 "
                  checked={formData?.yearly_option === 'specific_year'}
                  onChange={handleFieldChange}
                />


              </div>
              <div className="text-xs">On </div>

              <select
                id={`recurr_pattern_days`}
                name="recurr_pattern_days"
                // onChange={handleRecurChange}
                className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block  h-9 appearance-none pr-10`}
              >
                {monthOfYear.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>

              <div className="appearance-none pb-5 px-3 w-20 h-9 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block">
                <input
                  type="number"
                  id="visitors"
                  className="w-16  p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                  placeholder=""
                  required
                  defaultValue={1}
                />
              </div>
            </div>

            <div className="flex gap-2 py-2 items-center">
              <div>

                <input
                  type="radio"
                  name="yearly_option"
                  value={"weekday_of_year"}
                  className="radio  checked:border-indigo-500 checked:bg-indigo-900 "
                  checked={formData?.yearly_option === 'weekday_of_year'}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="text-xs">On The</div>

              <div className="flex items-center justify-end">
                <select
                  id={`recurr_pattern_times`}
                  name="recurr_pattern_times"
                  // onChange={handleRecurChange}
                  className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-9 appearance-none pr-10`}
                >
                  <option value="First">First</option>
                  <option value="Second">Second</option>
                  <option value="Third">Third</option>
                </select>
                <div className="absolute flex px-2 pointer-events-none">
                  <h4>
                    <i className="bi bi-chevron-down"></i>
                  </h4>
                </div>
              </div>
              {/* {formErrors.recurr_pattern && (
                <span className="text-red-500 text-xs">
                  Cannot be left blank.
                </span>
              )} */}

              <div className="flex items-center justify-end">
                <select
                  id={`recurr_pattern_days`}
                  name="recurr_pattern_days"
                  // onChange={handleRecurChange}
                  className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-9 appearance-none pr-10`}
                >
                  {daysOfWeek.map((day, index) => (
                    <option key={index} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <div className="absolute flex px-2 pointer-events-none">
                  <h4>
                    <i className="bi bi-chevron-down"></i>
                  </h4>
                </div>
              </div>
              {/* {formErrors.recurr_pattern && (
                <span className="text-red-500 text-xs">
                  Cannot be left blank.
                </span>
              )} */}

              <div className="text-xs">Of Every</div>

              <select
                id={`recurr_pattern_days`}
                name="recurr_pattern_days"
                // onChange={handleRecurChange}
                className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block  h-9 appearance-none pr-10`}
              >
                {monthOfYear.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-gray-900 bg-opacity-50">
      <div className="w-[75%] relative mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md  overflow-hidden no-scrollbar">
        <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
          <h3 className="text-2xl text-434852 ps-3">Appointment Recurrence</h3>
          <button
            onClick={closePopup}
            type="button"
            className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <form
          className=" lg:max-h-[85vh]  overflow-hidden  no-scrollbar pt-3"
        >
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6"> */}


               {StartTime}

               {EndTime} 


          <div calssName="px-6">
            <div className=" mb-3 px-6">
              <label htmlFor="categories" className="block mb-2  text-434852">
                <span className="text-gray-700 text-sm font-medium">
                  Recurrence Pattern
                </span>
                <span className=" text-red-500"> *</span>
              </label>
              <div className="flex">
                {/* <select
                  id={`frequency`}
                  name="frequency"
                  onChange={handleFieldChange}
                  className={`h-9 custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-9 appearance-none pr-10`}
                >
                  <option value="daily" selected={formData?.frequency === "daily"}>Daily</option>
                  <option value="weekly" selected={formData?.frequency === "weekly"}>Weekly</option>
                  <option value="monthly" selected={formData?.frequency === "monthly"}>Monthly</option>
                  <option value="yearly" selected={formData?.frequency === "yearly"}>Yearly</option>
                </select> */}

                    <FormControl component="fieldset" sx={{borderRight: 1, borderColor: "#BECBD3",mr: 2}}>
                      {/* <FormLabel component="legend">Frequency</FormLabel> */}
                      <RadioGroup
                        aria-label="frequency"
                        name="frequency"
                        value={formData?.frequency}
                        onChange={handleFieldChange}
                      >
                        <FormControlLabel
                          value="daily"
                          control={<Radio />}
                          label="Daily"
                        />
                        <FormControlLabel
                          value="weekly"
                          control={<Radio />}
                          label="Weekly"
                        />
                        <FormControlLabel
                          value="monthly"
                          control={<Radio />}
                          label="Monthly"
                        />
                        {/*
                        
                        <FormControlLabel
                          value="yearly"
                          control={<Radio />}
                          label="Yearly"
                        />  
                        */}
                      </RadioGroup>
                    </FormControl>


                {renderRecurrenceSection()}


                {/* <div className="absolute flex px-2 pointer-events-none pr-5">
                  <h4>
                    <i className="bi bi-chevron-down"></i>
                  </h4>
                </div> */}
              </div>
              {errors?.frequency && (
                <span className="text-red-500 text-xs">
                  {errors?.frequency}
                </span>
              )}
            </div>

            {/* <div className="mb-3 px-6">
              <label htmlFor="categories" className="block mb-2  text-434852">
                <span className="text-gray-700 text-sm font-medium">
                  Recur Every
                </span>
                <span className=" text-red-500"> *</span>
                {renderRecurrenceSection()}
              </label>
            </div> */}
            {/* </div> */}

            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6"> */}
            <div className=" mb-3 px-6 flex">

              <div className='flex'>

                      <label htmlFor="duedate" className=" text-434852">
                        <span className="text-gray-700 text-sm font-medium">
                          Start :
                        </span>
                        {/* <span className=" text-red-500"> *</span> */}
                      </label>

                      <div className="relative  flex ms-3">

                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDateChange}
                          // dateFormat={displayFormat}
                          startDate={startDate}
                          // minDate={new Date()}
                          placeholderText="Select a start date"
                          className="h-10 w-full bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block"
                          calendarClassName="absolute right-0 flex justify-end"
                          // popperPlacement=" top-0"
                          dateFormat="dd-MM-yyyy"
                        />



                                      {/* <div className="relative inset-y-0 flex items-center pr-3 pointer-events-none bg-zinc-200 px-3 rounded-e text-lg  pb-2">
                                            <i className="bi bi-clock w-5 h-5"></i>{" "}
                                      </div> */}
                      </div>

                      

              </div>
              


              <div className="ms-5">
                {/* <label
                  htmlFor="duedate"
                  className="basis-5/5 block mb-2  text-434852"
                >
                  <span className="text-gray-700 text-sm font-medium">
                    Recurrence End By :
                  </span>
                </label> */}

                <div className="flex gap-5 items-center">
                  <div>
                    <input
                      type="radio"
                      name="end_by"
                      value={"end_by"}
                      className="radio  checked:border-indigo-500 checked:bg-indigo-900 "
                      onChange={handleEndByChange}
                      checked={formData?.endBy === "end_by"}
                    />
                  </div>
                  <div className="text-xs"> End By </div>
                  <div className="relative flex ms-6">
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      endDate={endDate}
                      minDate={startDate}
                      // dateFormat="dd-MM-yyyy"
                      placeholderText="Select an end date"
                      className="h-10 w-full bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block"
                      disabled={formData?.endBy === "after"}
                      
                      dateFormat="dd-MM-yyyy"
                    />
                              
                    {/* <div className="relative inset-y-0 flex items-center pr-3 pointer-events-none bg-zinc-200 px-3 rounded-e text-lg  pb-2">
                      <i className="bi bi-clock w-5 h-5 "></i>{" "}
                    </div> */}
                  </div>
                </div>

                <div className="flex gap-6 py-2 items-center">
                  <div>
                    <input
                      type="radio"
                      name="after"
                      value={"after"}
                      className="radio  checked:border-indigo-500 checked:bg-indigo-900"
                      onChange={handleEndByChange}
                      checked={formData?.endBy === "after"}
                    />
                  </div>
                  <div className="text-xs">End After</div>
             
                  <div className="appearance-none pb-5 px-3 w-20 h-9 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block">
                    <input
                      type="number"
                      id="occurrences"
                      name="occurrences"
                      onChange={handleFieldChange}
                      className="w-16 p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                      placeholder=""
                      required
                      disabled={formData?.endBy === "end_by"}
                    />
                  </div>

                  <div className="text-xs">Occurrences</div>
                </div>


                <div className="flex gap-5 py-2 items-center ">
                  <div>
                    <input
                      type="radio"
                      name="no_end_date"
                      value={"no_end_date"}
                      className="radio  checked:border-indigo-500 checked:bg-indigo-900"
                      onChange={handleEndByChange}
                      checked={formData?.endBy === "no_end_date"}
                    />
                  </div>
                  <div className="text-xs">No End Date</div>
                </div>
                {/* <i className="bi bi-calendar2-event absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none cursor:pointer"></i> */}
              </div>
            </div>

            <div className="sticky bottom-0 bg-gray-200 p-4 flex justify-end py-4 modal-footer text-lg w-full rounded-none">
              <div className="px-6">
                <button
                  onClick={closePopup}
                  type="button"
                  className="h-9 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-2 text-center rounded-sm"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={handleRecurTask}
                  className="btn-submit h-9 w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold text-gray-900 border border-gray-200 py-2 px-5 ms-3 rounded-sm"
                >
                  SET
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default RecurrenceModal;
