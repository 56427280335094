import React from 'react'

const GentChart = () => {
  return (

<div>
<div className="flex  drop-shadow-xl text-xs mb-2 w-full">
  <div className="bg-white px-2 flex-1">
    <div className="text-center pt-2">Jan</div>
    <div className="pt-5 text-center">1...........15..........30</div>
  </div>

  <div className="inline-block min-h-[0.3em] w-0.5 self-stretch bg-neutral-100"></div>

  <div className="bg-white px-2 flex-1">
    <div className="text-center pt-2">Feb</div>
    <div className="pt-5 text-center">1...........15..........30</div>
  </div>

  <div className="inline-block min-h-[0.3em] w-0.5 self-stretch bg-neutral-100"></div>

  <div className="bg-white px-2 flex-1">
    <div className="text-center pt-2">March</div>
    <div className="pt-5 text-center">1...........15..........30</div>
  </div>

  <div className="inline-block min-h-[0.3em] w-0.5 self-stretch bg-neutral-100"></div>

  <div className="bg-white flex flex-col items-center">
    <div className="p-1">
      <button className="w-6 h-4 p-2 py-3 bg-slate-100 rounded justify-center items-center gap-2 inline-flex">
        <i className="bi bi-plus-lg"></i>
      </button>
    </div>
    <div className="p-1">
      <button className="w-6 h-4 p-2 py-3 bg-slate-100 rounded justify-center items-center gap-2 inline-flex">
        <i className="bi bi-dash-lg"></i>
      </button>
    </div>
  </div>

</div>


    <div className="bg-white w-full">

    <hr className="w-48 h-1 mx-2 my-0  bg-orange-700 border-0 rounded md:my-0 dark:bg-gray-700"/>
    <hr className="h-px my-5 bg-gray-200 border-0 dark:bg-gray-700"/>
    <hr className="w-48 h-1 mx-auto my-0 bg-orange-700 border-0 rounded md:my-0 dark:bg-gray-700"/>
    <hr className="h-px my-5 bg-gray-200 border-0 dark:bg-gray-700"/>
    <hr className="w-48 h-1 justify-items-end ml-auto my-0 bg-orange-700 border-0 rounded md:my-0 dark:bg-gray-700"/>
    <hr className="h-px my-5 bg-gray-200 border-0 dark:bg-gray-700"/>
    <hr className="w-64 h-1 justify-items-end ml-auto my-0 bg-orange-700 border-0 rounded md:my-0 dark:bg-gray-700"/>
    <hr className="h-px my-5 bg-gray-200 border-0 dark:bg-gray-700"/>
    <hr className="w-48 h-1 justify-items-end ml-10 mr-auto my-0 bg-orange-700 border-0 rounded md:my-0 dark:bg-gray-700"/>
    <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"/>



    </div>
      




</div>
  )
}

export default GentChart