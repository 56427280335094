import React from 'react'


function FooterFixed() {
    const getFinancialYear = () => {
        const now = new Date();
        const year = now.getFullYear();
        return year;
    };
    return (
        <footer className="bg-ECF5FE text-white p-3 w-full fixed bottom-0 left-0 text-center">
            <p className='text-1D4469 text-12'>© Copyright <span className='font-600'>{getFinancialYear()}</span> <ppan className="mx-2">||</ppan><span>All right reserved.  Powered by </span><a href='https://www.anytimeaccountant.com' target='_blank'><span className='text-red-600 hover:cursor-pointer hover:text-emerald-600'>Zinancial</span></a></p>
        </footer>
    );
  }
  
  export default FooterFixed