import React, { useState, useRef ,useEffect} from 'react';
const ColorDropdown = ({ colors ,handleInsertColur,colourCodes}) => {
 
  const [selectedColor, setSelectedColor] = useState(() => {
    const defaultColor = colors?.data.find(color => color.colour_code === colourCodes);
    if (defaultColor) {
      handleInsertColur(defaultColor.colour_code);
      return defaultColor; 
    }
    return "";
  });
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleOptionChange = (color) => {
    setSelectedColor(color);
    handleInsertColur(color.colour_code);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  
  return (
    <div className="flex item-center   mr-2 h-[41px] w-[85px]" ref={dropdownRef}>


    <button onClick={handleDropdownToggle} type='button' className="bg-white border rounded-5px cursor-pointer  px-4 pointer pt-[1px]  w-[80px] text-left form-select h-[35px] border-D2DAE1 focus:border-778FA5 mr-4">
    <span
      className="inline-block  w-[20px] h-[20px] rounded-full mt-1 pr-4"
      style={{ backgroundColor: selectedColor ? selectedColor.colour_code : '#008937 ' }}
    ></span>
    </button>
      {isOpen && (
        <div className="dropdown-menu-color    ml-[1px] pb-2  pl-[6px] ">
          {colors?.data.map((color) => (
            <label key={color.colour_code} className="flex cursor-pointer pl-3 pr-3 pt-1">
              <input
                type="checkbox"
                name="colour_code"
                id="colour_code"
                value={color.colour_code}
                checked={selectedColor && selectedColor.colour_name === color.colour_name}
                onChange={() => handleOptionChange(color)}
                style={{ backgroundColor: color.colour_code, borderColor: '' }}
                className='cursor-pointer mx-1 rounded-full color-circle '
              />
             
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorDropdown;
