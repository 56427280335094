import React, { useState } from "react";

const UserProfile = () => {
    const[isOpen, setIsOpen]=useState(false);
    
    const profileDropdown = () => {
        setIsOpen(!isOpen);
      };

  return (
    <div className="">
      <div className="bg-white p-7 text-18px text-2C495D font-semibold text font-poppins h-full pb-28 border-y-2">
        <div className="flex ">
          <div className="w-5 ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="86"
              height="86"
              viewBox="0 0 86 86"
              fill="none"
            >
              <g clip-path="url(#clip0_14991_57720)">
                <rect width="86" height="86" rx="43" fill="#2C495D" />
                <path
                  d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                  fill="#E7ECED"
                />
              </g>
              <defs>
                <clipPath id="clip0_14991_57720">
                  <rect width="86" height="86" rx="43" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            <div className="ml-20">
              <div className="flex ">
                <h3 className="text-18 text-2C495D font-600">Khushdeev Kaur</h3>
                <button class="bg-FFDCD1 ml-2 text-10 flex-start text-F65627  px-2  border-[1px] border-F65627 rounded-full font-400">
                  Standard
                </button>
                <svg
                  className="ml-2 mt-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                >
                  <path
                    d="M7.41065 1.83848L0.808079 7.89763C0.774862 7.9282 0.750876 7.9668 0.739455 8.00829L0.00762279 10.7037C-0.0142684 10.7849 0.0117155 10.8712 0.0762469 10.9304C0.125074 10.9752 0.191604 11 0.259657 11C0.280501 11 0.301821 10.9976 0.32257 10.9928L3.25999 10.3212C3.30577 10.3107 3.34736 10.2888 3.38058 10.2583L9.98373 4.1996L7.41065 1.83848Z"
                    fill="#1D4469"
                  />
                  <path
                    d="M11.6195 1.01234L10.8845 0.337904C10.3933 -0.112853 9.53715 -0.112416 9.0465 0.337904L8.1462 1.16404L10.7192 3.52508L11.6195 2.69894C11.8648 2.47387 12 2.1743 12 1.85568C12 1.53707 11.8648 1.2375 11.6195 1.01234Z"
                    fill="#1D4469"
                  />
                </svg>
              </div>
              <p className="text-2C495D text-14 font-400">
                Manager At Global FPO
              </p>
              <div className="text-14 font-500 text-2C495D">
                <div>
                  <i className="bi bi-envelope mr-3"></i>
                  khushdeev.kaur@globalfpo.com
                </div>
                <div>
                  <i className="bi bi-person mr-3"></i>manager@globalfpo.com
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 p-2 border">
          <button
            type="button"
            className="form-select inline-flex  w-full  text-848687 text-12 font-400 px-4 "
           onClick={profileDropdown}
          >
            Show Details
          </button>
        </div>
        {isOpen && (
          <div className="flex w-full pb-8 border">
            <div className="w-3/5 flex flex-col ">
              <h3 className="pl-4  font-600 text-20px font-Poppins mt-5">
                User Information
              </h3>
              <div className="grid grid-cols-2 gap-40 font-400  leading-normal">
                <div className="bg-white p-4 grid grid-cols-2 h-10 ">
                  <div className="bg-white p-1 ">
                    <label className=" text-5E5E5E text-14">First Name</label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">Khushdeep</p>
                  </div>
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">Last Name</label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">Kaur</p>
                  </div>
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">Email</label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">khush.kaur@gmail.com</p>
                  </div>
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">Role</label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">Manager</p>
                  </div>
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">Profile</label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">Standard</p>
                  </div>
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">Created By</label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">Vaibhav J.</p>
                  </div>
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">Alias</label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">--</p>
                  </div>
                </div>
                <div className="bg-white p-4 grid grid-cols-2">
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">Phone</label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">Khushdeep</p>
                  </div>
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">Mobile</label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">Kaur</p>
                  </div>
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">
                      Date of Birth
                    </label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">khush.kaur@gmail.com</p>
                  </div>
                  <div className="bg-white p-1">
                    <label className=" text-5E5E5E text-14">
                      Personal Email
                    </label>
                  </div>
                  <div className="bg-white p-1">
                    <p className="text-2C495D text-14">Manager</p>
                  </div>
                  <div className="bg-white p-1">
                    {/* <label className=' text-5E5E5E text-14'>Profile</label> */}
                  </div>
                  <div className="bg-white p-1">
                    {/* <p className='text-2C495D text-14'>Standard</p> */}
                  </div>
                  <div className="bg-white p-1">
                    {/* <label className=' text-5E5E5E text-14'>Created By</label> */}
                  </div>
                  <div className="bg-white p-1">
                    {/* <p className='text-2C495D text-14'>Vaibhav J.</p> */}
                  </div>
                  <div className="bg-white p-1">
                    {/* <label className=' text-5E5E5E text-14'>Alias</label> */}
                  </div>
                  <div className="bg-white p-1">
                    {/* <p className='text-2C495D text-14'></p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-2/5 flex flex-col ml-28">
              <h3 className=" text-2C495D font-600 text-20px font-Poppins ml-3 mt-5">
                Address Information
              </h3>
              <div className="bg-white  grid grid-cols-2 p-2 font-400 text-14 w-[55%]">
                <div className="bg-white p-1">
                  <label className=" text-5E5E5E text-14">Street</label>
                </div>
                <div className="bg-white p-1">
                  <p className="text-2C495D text-14">Khushdeep</p>
                </div>
                <div className="bg-white p-1">
                  <label className=" text-5E5E5E text-14">City</label>
                </div>
                <div className="bg-white p-1">
                  <p className="text-2C495D text-14">Kaur</p>
                </div>
                <div className="bg-white p-1">
                  <label className=" text-5E5E5E text-14">State</label>
                </div>
                <div className="bg-white p-1">
                  <p className="text-2C495D text-14">khush.kaur@gmail.com</p>
                </div>
                <div className="bg-white p-1">
                  <label className=" text-5E5E5E text-14">Zip Code</label>
                </div>
                <div className="bg-white p-1">
                  <p className="text-2C495D text-14">Manager</p>
                </div>
                <div className="bg-white p-1">
                  <label className=" text-5E5E5E text-14">Company</label>
                </div>
                <div className="bg-white p-1">
                  <p className="text-2C495D text-14">Standard</p>
                </div>
                <div className="bg-white p-1">
                  <label className=" text-5E5E5E text-14">Created By</label>
                </div>
                <div className="bg-white p-1">
                  <p className="text-2C495D text-14">Vaibhav J.</p>
                </div>
                <div className="bg-white p-1">
                  <label className=" text-5E5E5E text-14">Alias</label>
                </div>
                <div className="bg-white p-1">
                  <p className="text-2C495D text-14">--</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <div className="flex">
            <h3 className=" text-2C495D font-600 text-20px font-Poppins ml-3 mt-5 ">
              Local Information
            </h3>
            <svg
              className="ml-4 mt-6"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
            >
              <path
                d="M9.43174 2.33988L1.02846 10.0515C0.986187 10.0904 0.955661 10.1396 0.941124 10.1924L0.00970173 13.6229C-0.0181598 13.7262 0.0149106 13.836 0.0970415 13.9114C0.159185 13.9684 0.24386 14 0.330473 14C0.357002 14 0.384136 13.997 0.410544 13.9909L4.14908 13.1361C4.20734 13.1227 4.26028 13.0948 4.30256 13.056L12.7066 5.34495L9.43174 2.33988Z"
                fill="#1D4469"
              />
              <path
                d="M14.7884 1.28843L13.853 0.43006C13.2278 -0.143631 12.1382 -0.143075 11.5137 0.43006L10.3679 1.48151L13.6426 4.48647L14.7884 3.43502C15.1007 3.14856 15.2727 2.76729 15.2727 2.36178C15.2727 1.95627 15.1007 1.575 14.7884 1.28843Z"
                fill="#1D4469"
              />
            </svg>
          </div>
          <div className="bg-white p-4 ms-10">
            <div className="flex flex-col w-[50%]">
              <div className="grid grid-cols-2">
                <div className="">
                  <label className="text-14 text-5E5E5E font-400 ">
                    Language
                  </label>
                </div>
                <div className="">
                  <p className="text-14 text-2C495D font-400">
                    English (United States)
                  </p>
                </div>
                <div className="">
                  <label className="text-14 text-5E5E5E font-400">
                    Country Local
                  </label>
                </div>
                <div className="">
                  <p className="text-14 text-2C495D font-400">United State</p>
                </div>
                <div className="">
                  <label className="text-14 text-5E5E5E font-400">
                    Date Format
                  </label>
                </div>
                <div className="">
                  <p className="text-14 text-2C495D font-400">DD/MM/YYYY</p>
                </div>
                <div className="">
                  <label className="text-14 text-5E5E5E font-400">
                    Time Zone
                  </label>
                </div>
                <div className="">
                  <p className="text-14 text-2C495D font-400">
                    GMT 5:30 India Standard Time Asia/Kolkata
                  </p>
                </div>
                <div className="">
                  <label className="text-14 text-5E5E5E font-400">
                    Number Format
                  </label>
                </div>
                <div className="">
                  <p className="text-14 text-2C495D font-400">123,456.789</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className=" text-2C495D font-600 text-20px font-Poppins ml-3 mt-5">
            Role
          </h3>
          <div className="bg-white p-4 ms-10">
            <div className="flex flex-col w-[30%]">
              <div className="grid grid-cols-2">
                <div className="">
                  <label className="text-14 text-5E5E5E font-400">
                    Member In
                  </label>
                </div>
                <div className="">
                  <p className="text-14 text-2C495D font-400">-----</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className=" text-2C495D font-600 text-20px font-Poppins ml-3 mt-5">
            Display Name Format & Preferences
          </h3>
          <p className="text-14 text-5E5E5E font-400 ml-3.5">
            It applies to full name in Column of list view, lookup fields and
            user name
          </p>
        </div>

        <div className="ml-20 mt-5 w-50">
          <div className="space-x-5 ">
            <label className="mr-16 text-5E5E5E text-14 font-400">
              Name Format
            </label>
            <select className=" form-select rounded w-[418px] h-[40px] text-434852 text-12 font-400">
              <option>Salutation, first name, last name</option>
              <option>A</option>
              <option>A</option>
              <option>A</option>
              <option>A</option>
            </select>
          </div>
          <div className="space-x-5 mt-3 ">
            <label className="mr-[3px]  text-5E5E5E text-14 font-400">
              Sort Order Preferences
            </label>
            <select className="form-select rounded w-[418px] h-[40px] text-434852 text-12 font-400">
              <option>first name, last name</option>
              <option>A</option>
              <option>A</option>
              <option>A</option>
              <option>A</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
