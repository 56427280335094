// import { useState } from 'react';

// const useDragAndDrop = () => {
//   const [draggedHeader, setDraggedHeader] = useState({ key: null, value: null });
//   const [groupedHeaders, setGroupedHeaders] = useState([]);
//   const [isDraggingOver, setIsDraggingOver] = useState(false);

//   const handleDragStart = (key, value) => {
//     setIsDraggingOver(true);
//     setDraggedHeader({ key, value });
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setIsDraggingOver(false);
//     if (draggedHeader.key !== null && draggedHeader.value !== null) {
//       setGroupedHeaders((prev) => [...prev, { key: draggedHeader.key, value: draggedHeader.value }]);
//       setDraggedHeader({ key: null, value: null });
//     }
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     setIsDraggingOver(true);
//   };


//   const handleRemoveHeader = (index) => {
//     setGroupedHeaders((prev) => prev.filter((_, i) => i !== index));
    
//   };

//   return {
//     draggedHeader,
//     groupedHeaders,
//     handleDragStart,
//     handleDrop,
//     handleDragOver,
//     handleRemoveHeader,
//     isDraggingOver,
//   };
// };

// export default useDragAndDrop;





import { useState, useEffect } from 'react';

const useDragAndDrop = () => {
  const [draggedHeader, setDraggedHeader] = useState({ key: null, value: null });
  const [groupedHeaders, setGroupedHeaders] = useState([]);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isDragAccurateLoc,setIsDragAccurateLoc] = useState(false)

  const handleDragStart = (key, value) => {
    setIsDraggingOver(true);
    setDraggedHeader({ key, value });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    setIsDragAccurateLoc(false);
   
    if (draggedHeader.key !== null && draggedHeader.value !== null) {
      setGroupedHeaders((prev) => [...prev, { key: draggedHeader.key, value: draggedHeader.value }]);
      setDraggedHeader({ key: null, value: null });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    // setIsDragAccurateLoc(true);
  };

  
  const handleDragLeave = () => {
    setIsDragAccurateLoc(false); // Reset dragging over when leaving
  };

  const handleRemoveHeader = (index) => {
    setGroupedHeaders((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const handleDocumentDragEnd = () => {
      setIsDraggingOver(false);
      setDraggedHeader({ key: null, value: null });
    };

    document.addEventListener('dragend', handleDocumentDragEnd);

    return () => {
      document.removeEventListener('dragend', handleDocumentDragEnd);
    };
  }, []);

  return {
    draggedHeader,
    groupedHeaders,
    handleDragStart,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    handleRemoveHeader,
    isDragAccurateLoc,
    isDraggingOver,
  };
};

export default useDragAndDrop;
