import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import useRequireAuth from "../utils/useRequireAuth";
import { addParticularCompany } from "../utils/userSlice";
import { BASE_URL } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { useFetchCompanyListQuery } from "../utils/company/companySelector";
import { useSidebar } from "./SidebarContext";
import { saveTaskPod, hideMessage } from "../utils/customer/taskPodSlice";
import { useFetchCustomerTaskPodDataQuery } from "../utils/customer/customerSelect";
import HamburgerIcon from "./Icons/HamburgerIcon";
import { useFetchCustomerTableDataQuery } from "../utils/customer/customerTable";
import useDebouncedEffect from "./customer/useDebouncedEffect";
import useClickOutside from "../hooks/Screen/useClickOutside";
import useSorting from "../hooks/Table/useSortableColumn";
import SortingFilter from "./GlobalFeature/Table/TableThead/SortingFilter";
import { parseJSONValue } from "../utils/json/parseJSONValue";
import { getUserTokeData } from "../utils/userSlice";





function formatValue(item) {
  if (item) {
    const words = item.split(" ");
    const firstWord = words[0];
    const lastWord = words[words?.length - 1];
    const firstChar = firstWord.charAt(0).toUpperCase();
    const lastChar = lastWord.charAt(0).toUpperCase();
    return `${firstChar}${lastChar}`;
  }
  return null;
}

function Leftmenu(props) {
  const threedotmenu = useRef(null);
  const customerList = useRef(null);
  const location = useLocation();

  const dispatch = useDispatch();
  const { token } = useRequireAuth();

  const navigate = useNavigate();
  const storedData = localStorage.getItem("company_data");
  const myUserData = localStorage.getItem("myUserData");
  const usetoken = myUserData ? JSON.parse(myUserData) : null;
  const tokens = token ? token : usetoken.token;
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [showCustomerHover, setshowCustomerHover] = useState(false);
  const [showTaskpodHover, setshowTaskpodHover] = useState(false);
  const [showVendorHover, setshowVendorHover] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isRotated, setIsRotated] = useState(false);

  const [searchUserText, setSearchUserText] = useState("");

  const companyId = storedData ? JSON.parse(storedData) : null;
  const {
    data: companyCommnData,
    error,
    isLoading,
  } = useFetchCompanyListQuery(tokens);
  const user = useSelector((store) => store.user);

  const [asideWidth, setAsideWidth] = useState(null);
  const { sideBarColl, setSideBarColl } = useSidebar(false);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [isTaskPodOpen, setIsTaskPodOpen] = useState(false);

  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const [customer, setCustomer] = useState([]);
  const [initialFilter, setInitialFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0);

  const [activeTaskpod, setActiveTaskpod] = useState("");
  const { sortedData, sortData, sortKey, sortOrder, handleSearch } = useSorting(
    customer,
    "asc"
  );
  const [formErrors, setFormErrors] = useState({ name: "", description: "" });
  const [formData, setFormData] = useState({ name: "" });
  const [mobileTaskPod,setMobileTaskPod]=useState(false)
  const taskFormSubmit = useSelector((state) => state.tasks);

  const showMessage = useSelector((state) => state.taskPod.showMessage);
  const message = useSelector((state) => state.taskPod.message);
  useEffect((
  )=>{
    if(sideBarColl){
      setIsTaskPodOpen(false)
      setActiveTaskpod("")
    }
  },[sideBarColl])

  const toggleTaskPodDropdown = () => {
    setIsTaskPodOpen(!isTaskPodOpen );
    setActiveTaskpod((activeTaskpod === "taskpod") ? "" : "taskpod");
  };
  const handleTaskPodItemClick = (item) => {
    const path = `taskPod/${item}`;
    navigate(path);
  };

  const handleToggleMenu = (menuIndex) => {
    dispatch(
      addParticularCompany({
        ...companyId,
        menuName: menuIndex,
        menuId: menuIndex,
      })
    );
    setOpenMenuId((prevMenuId) =>
      prevMenuId === menuIndex ? null : menuIndex
    );
  };

  const menuDropDown = (e) => {
    e.preventDefault();
    threedotmenu.current?.classList.toggle("hidden");
  };

  const customerModalList = (e) => {
    e.preventDefault();
    customerList.current?.classList.remove("hidden");
    handleMenuItemClick("customers");
  };

  const closeCustomerList = () => {
    customerList.current?.classList.add("hidden");
    handleMenuItemClick("");
  };

  const closeoutsideclick = useClickOutside(closeCustomerList);
  const threedotpanelcloseoutsideclick = useClickOutside(() => {
    threedotmenu.current?.classList.add("hidden");
  });

  const setSideMenuRef = useCallback((node) => {
    if (node) {
      const resizeObserver = new ResizeObserver(([entry]) => {
        setAsideWidth(entry.contentRect.width);
      });
      resizeObserver.observe(node);
      return () => resizeObserver.disconnect();
    }
  }, []);

  useEffect(() => {
    const mainContentElement = document.getElementById("maincontent");
    const tableMainDiv = document.getElementsByClassName("tablescreenfull");
    if (asideWidth && mainContentElement) {
      mainContentElement.style.marginLeft = `${asideWidth}px`;
      for (let i = 0; i < tableMainDiv.length; i++) {
        tableMainDiv[i].style.marginLeft = `${asideWidth}px`;
      }
    }
    return () => {
      if (mainContentElement) {
        mainContentElement.style.marginLeft = "";
        for (let i = 0; i < tableMainDiv.length; i++) {
          tableMainDiv[i].style.marginLeft = "";
        }
      }
    };
  }, [asideWidth]);

  const Expandside = () => {
    setSideBarColl(!sideBarColl);
    setIsRotated(!isRotated);
  };

  const handleMenuItemClick = (menuItem) => setActiveMenuItem(menuItem);

  const handleItemClick = (companyId, company_name, company_logo) => {
    dispatch(
      addParticularCompany({
        company_id: companyId,
        company_name,
        company_logo,
      })
    );
    navigate(`/edit_company/${companyId}`);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = (term) => {
    setSearchText(term);
    handleSearch(term);
  };

  const validateForm = () => {
    const requiredFields = ["name"];
    const errors = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = "Cannot be left blank.";
      }
    });
    setFormErrors(errors);
    return !Object.keys(errors).length;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const rotateSvg = () => {
    setIsRotated(!isRotated); // Toggle rotation state
  };

  const handleSubmitTaskPod = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      console.log("Form has errors");
      return;
    }
    const taskData = {
      pod_name: event.target.name.value,
      description: event.target.description.value,
    };
    dispatch(saveTaskPod({ taskData, tokens }));
    setRefreshKey((prevKey) => prevKey + 1);
    setTimeout(() => {
      dispatch(hideMessage());
    }, 3000);
    event.target.reset();
  };

  const { data: customerTaskPodList } = useFetchCustomerTaskPodDataQuery({
    tokens,
  });

  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchCustomerTableDataQuery({
      companyId: companyId.company_id,
      pagination: `${customerTablePagination}`,
      token: tokens,
    });

  useEffect(() => {
    if (customerTableALL) {
      setCustomer(customerTableALL?.data?.data);
      const { data: omitData, ...restData } = customerTableALL?.data;
      setInitialFilter(restData);
    }
  }, [customerTableALL, customerTablePagination]);

  useEffect(()=>{
    const url = `?search=${searchText} &sort_direction=${sortOrder}&sort_by=${sortKey}`;
    setCustomerTablePagination(url);
   
  },[sortKey,sortOrder,searchText])

  const handleInputChangeUserList = (e) => setSearchUserText(e.target.value);
  const clearSearchUserList = () => setSearchUserText("");
  const clearSearchValue = () => setSearchUserText("");
  const handleClickNavigetCustomerDetail = (id) =>
    navigate(`/add_customer1/${id}`);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const SmallTaskPOdDropDown = ({isTaskPodOpen, useSubmenuChildClass}) => {
    return (
      <div>
          <ul
            className={`${useSubmenuChildClass ? "submenuchildtext1" : "mobtask"} ${isTaskPodOpen ? "" : "hidden"}`}
            ///  key={refreshKey}
          >
            {!customerTaskPodList?.data
              ? null
              : customerTaskPodList?.data.map((CategoryData) => (
                  <li key={CategoryData.id}>
                    <Link
                      key={CategoryData.id}
                      to={`/taskPod/${CategoryData.pod_name}`}
                      className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-4 gap-2 group hover:bg-2999BC text-14 "
                      onClick={() =>
                        handleTaskPodItemClick(CategoryData.pod_name)
                      }
                    >
                      <i className="bi bi-boxes text-white"></i>
                      <span className="text-white">{CategoryData.pod_name}</span>
                    </Link>
                  </li>
                ))}
          </ul>
      </div>
    )
  }

  const handleMobileTaskPod=()=>{
    setMobileTaskPod(!mobileTaskPod)
  }
  return (
    <Fragment>
      
      {/* <button
        type="button"
        className={`fixed left-[${asideWidth + 20}px] top-[5px] z-[9999]`}
        onClick={Expandside}
      >
        <HamburgerIcon />
      </button> */}
      <div
        ref={threedotmenu}
        className={`rounded shadow-6 bg-F8FAFB fixed left-[${asideWidth}px] top-[40px] z-[99999999]  hidden`}
      >
        <div ref={threedotpanelcloseoutsideclick}>
          <div className={`bg-white border-b-4 mb-2 px-7 py-3`}>
            <i className="bi bi-gear me-3 text-1D4469"></i>
            <Link to="/dashboardMain" className="text-1D4469 text-16 pe-auto">
              Manage Account
            </Link>
          </div>
          <ul className="ms-5 relative overflow-y-scroll  w-[217px] h-[392px] ">
            {!companyCommnData?.data
              ? null
              : companyCommnData?.data?.map((list) => (
                  <li
                    className=" px-3 manageaccountli cursor-pointer"
                    key={list.id}
                    onClick={() => {
                      handleItemClick(
                        list.id,
                        list.company_name,
                        list.company_logo
                      );
                      window.location.reload(true);
                    }}
                  >
                    <div className="flex items-center py-1 mb-1">
                      {list.company_logo ? (
                        <div className="h-10 w-10 sm:h-12 sm:w-12 p-3 bg-gray-200 flex items-center justify-center rounded-full">
                          <img
                            className="h-6 sm:h-7 mx-auto rounded"
                            src={`${BASE_URL}/${list.company_logo}`}
                            alt="Logo"
                            style={{ fontSize: "1.5em" }}
                          />
                        </div>
                      ) : (
                        <div className="h-10  sm:h-12 sm:w-12 bg-gray-200 flex items-center justify-center rounded-full ">
                          <span className="text-xs text-gray-500">
                            <strong style={{ fontSize: "1.5em" }}>
                              {formatValue(list.company_name)}
                            </strong>
                          </span>
                        </div>
                      )}
                      <button className="text-6B8AA8 text-16 pe-auto ms-3 company-name">
                        {list.company_name}
                      </button>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      </div>

      <button
        data-drawer-toggle="sidebar-multi-level-sidebar"
        aria-controls="sidebar-multi-level-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
      <aside
        ref={setSideMenuRef}
        id="sidebar-multi-level-sidebar"
        className={`${sideBarColl ? "w-16 sidebaraside" : "w-2/12 min-w-[220px] mobtaskview"} fixed top-0 left-0 z-[5000] h-screen transition-transform -translate-x-full sm:translate-x-0 bg-1D4469`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 overflow-y-auto sidebarscroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
          <div className="flex bg-[#29547c] mb2-2  px-2 rounded justify-between items-center py-2">
          <div className=" ">
          <a
            onClick={menuDropDown}
            href="#"
            className={` items-center ${
              sideBarColl ? "justify-center" : "justify-between"
            }  my-5`}
          >
            {!sideBarColl && <div className="flex items-center">
              {companyId.company_logo ? (
                <>
                  <img
                    className="h-10 w-10 me-3 sm:h-12 sm:w-12 rounded-full"
                     src={`${BASE_URL}/${companyId.company_logo}`}
                    alt="Logo"
                  />
                  <div
                    className={`${sideBarColl ? "hidden" : "block"} text-white`}
                  >
                    {companyId.company_name}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`h-10 w-10 ${
                      sideBarColl ? "" : "me-3"
                    }  sm:h-12 sm:w-12 bg-gray-200 flex items-center justify-center rounded-full`}
                    onClick={sideBarColl ? menuDropDown : null}
                  >
                    <span className="text-xs text-gray-500">
                      <strong style={{ fontSize: "1.9em" }}>
                        {formatValue(companyId.company_name)}
                      </strong>
                    </span>
                  </div>
                  <span
                    className={`${sideBarColl ? "hidden" : "block"} text-white `}
                  >
                    {companyId.company_name}
                  </span>
                </>
              )}
            </div>}
            {sideBarColl && <div className="flex items-center">
              {companyId.company_logo ? (
                <>
                  <div
                    className="h-10 w-10 me-3 sm:h-12 sm:w-12 rounded-full"
                    //  src={`${BASE_URL}/${companyId.company_logo}`}
                    // alt="Logo"
                  />
                  <div
                    className={`${sideBarColl ? "hidden" : "block"} text-white`}
                  >
                    {companyId.company_name}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`h-10 w-10 ${
                      sideBarColl ? "" : "me-3"
                    }  sm:h-12 sm:w-12 bg-gray-200 flex items-center justify-center rounded-full`}
                    onClick={sideBarColl ? menuDropDown : null}
                  >
                    <span className="text-xs text-gray-500">
                      <strong style={{ fontSize: "1.9em" }}>
                        {formatValue(companyId.company_name)}
                      </strong>
                    </span>
                  </div>
                  <span
                    className={`${sideBarColl ? "hidden" : "block"} text-white `}
                  >
                    {companyId.company_name}
                  </span>
                </>
              )}
            </div>}

          </a>
          </div>
          {!sideBarColl && 
            (<div className="ms-2 bg-[#29547c] py-2 mr-1">
                  <button onClick={Expandside}>
                  <svg className={`w-2.5 h-2.5 transform ${
                          isRotated ? "-rotate-0" : "rotate-180"
                        } transition-transform duration-300`}

                    xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    <path d="M8.50303 17.466C8.34318 17.4669 8.18472 17.4363 8.03674 17.3758C7.88876 17.3154 7.75417 17.2263 7.64068 17.1138L0.353191 9.82628C0.126974 9.59871 0 9.29087 0 8.97C0 8.64912 0.126974 8.34128 0.353191 8.11372L7.64068 0.826234C7.87303 0.627254 8.1719 0.523278 8.47759 0.535085C8.78327 0.546893 9.07323 0.673612 9.28954 0.889922C9.50585 1.10623 9.63258 1.3962 9.64438 1.70188C9.65619 2.00756 9.55221 2.30644 9.35323 2.53879L2.9281 8.96392L9.35323 15.3891C9.52379 15.5582 9.64035 15.7741 9.68819 16.0095C9.73602 16.2449 9.71298 16.4892 9.62198 16.7116C9.53098 16.9339 9.3761 17.1242 9.17693 17.2585C8.97776 17.3928 8.74324 17.465 8.50303 17.466Z" fill="white"/>
                    <path d="M15.7905 17.466C15.6307 17.4669 15.4722 17.4363 15.3242 17.3758C15.1762 17.3154 15.0416 17.2263 14.9282 17.1138L7.64067 9.82628C7.41446 9.59871 7.28748 9.29087 7.28748 8.97C7.28748 8.64912 7.41446 8.34128 7.64067 8.11372L14.9282 0.826234C15.1605 0.627254 15.4594 0.523278 15.7651 0.535085C16.0707 0.546893 16.3607 0.673612 16.577 0.889922C16.7933 1.10623 16.9201 1.3962 16.9319 1.70188C16.9437 2.00756 16.8397 2.30644 16.6407 2.53879L10.2156 8.96392L16.6407 15.3891C16.8113 15.5582 16.9278 15.7741 16.9757 16.0095C17.0235 16.2449 17.0005 16.4892 16.9095 16.7116C16.8185 16.9339 16.6636 17.1242 16.4644 17.2585C16.2652 17.3928 16.0307 17.465 15.7905 17.466Z" fill="white"/>
                  </svg>
                  </button>
            </div>)
          }
          </div>
          {sideBarColl && (
            <div className="absolute left-[22px] top-[18px] ">
                  <button className="p-[5px] bg-[#1D4469] rounded-full" onClick={Expandside}>
                  <svg className={`w-2.5 h-2.5 transform ${
                          isRotated ? "-rotate-0" : "rotate-180"
                        } transition-transform duration-300`}
 
                    xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    <path d="M8.50303 17.466C8.34318 17.4669 8.18472 17.4363 8.03674 17.3758C7.88876 17.3154 7.75417 17.2263 7.64068 17.1138L0.353191 9.82628C0.126974 9.59871 0 9.29087 0 8.97C0 8.64912 0.126974 8.34128 0.353191 8.11372L7.64068 0.826234C7.87303 0.627254 8.1719 0.523278 8.47759 0.535085C8.78327 0.546893 9.07323 0.673612 9.28954 0.889922C9.50585 1.10623 9.63258 1.3962 9.64438 1.70188C9.65619 2.00756 9.55221 2.30644 9.35323 2.53879L2.9281 8.96392L9.35323 15.3891C9.52379 15.5582 9.64035 15.7741 9.68819 16.0095C9.73602 16.2449 9.71298 16.4892 9.62198 16.7116C9.53098 16.9339 9.3761 17.1242 9.17693 17.2585C8.97776 17.3928 8.74324 17.465 8.50303 17.466Z" fill="white"/>
                    <path d="M15.7905 17.466C15.6307 17.4669 15.4722 17.4363 15.3242 17.3758C15.1762 17.3154 15.0416 17.2263 14.9282 17.1138L7.64067 9.82628C7.41446 9.59871 7.28748 9.29087 7.28748 8.97C7.28748 8.64912 7.41446 8.34128 7.64067 8.11372L14.9282 0.826234C15.1605 0.627254 15.4594 0.523278 15.7651 0.535085C16.0707 0.546893 16.3607 0.673612 16.577 0.889922C16.7933 1.10623 16.9201 1.3962 16.9319 1.70188C16.9437 2.00756 16.8397 2.30644 16.6407 2.53879L10.2156 8.96392L16.6407 15.3891C16.8113 15.5582 16.9278 15.7741 16.9757 16.0095C17.0235 16.2449 17.0005 16.4892 16.9095 16.7116C16.8185 16.9339 16.6636 17.1242 16.4644 17.2585C16.2652 17.3928 16.0307 17.465 15.7905 17.466Z" fill="white"/>
                  </svg>
                  </button>
            </div>
          )}

          <ul
            className={`space-y-0 font-extralight ${
              activeMenuItem === "customers" ? "active" : ""
            }`}
          >
            <li className="parentmenu hidden">
              <a
                href="#"
                className="sidebarmenutab flex items-center px-2 py-1 text-White rounded-lg hover:bg-2999BC group"
                onClick={() => handleToggleMenu(0)}
              >
                <i className="bi bi-speedometer2 text-18"></i>
                <span className={`ms-3 parenttext text-14`}>Dashboard</span>
              </a>
            </li>
            <li className=" parentmenu">
              {!sideBarColl && (
                <Link
                  onClick={() => handleMenuItemClick("customers")}
                  to="/customers"
                  className={`flex items-center justify-between w-full px-2 py-1 text-White transition duration-75  gap-4 group hover:bg-2999BC text-14 ${
                    activeMenuItem === "customers" ? "active bg-122E49" : ""
                  }`}
                >
                  {
                    <div className="">
                      <span className="flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 25 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.81574 17.478C6.31314 14.165 9.12921 11.6264 12.5287 11.6264C15.93 11.6264 18.7461 14.1651 19.2425 17.478C19.2534 17.5544 19.231 17.6317 19.1814 17.69C19.1316 17.7483 19.0595 17.7818 18.9835 17.7818C17.194 17.7818 7.86407 17.7818 6.07405 17.781C5.99831 17.781 5.92629 17.7476 5.87672 17.6895C5.82716 17.6313 5.80491 17.5542 5.81574 17.478ZM12.5287 11.0948C10.6379 11.0948 9.09665 9.52738 9.09665 7.60684C9.09665 5.68624 10.6379 4.11893 12.5287 4.11893C14.4213 4.11893 15.9626 5.68624 15.9626 7.60684C15.9626 9.52738 14.4213 11.0948 12.5287 11.0948ZM23.8242 7.95693L23.8677 7.86746C24.0134 7.56734 24.3716 7.44389 24.667 7.5919C24.9625 7.73991 25.0841 8.10372 24.9384 8.40384L24.2267 9.8695C24.1558 10.0155 24.0301 10.1264 23.8779 10.1773C23.7256 10.2281 23.5597 10.2147 23.4173 10.14L21.988 9.38963C21.6954 9.23598 21.5805 8.86989 21.7318 8.57264C21.8831 8.27534 22.2434 8.15875 22.5361 8.3124L22.7152 8.40639C21.1783 4.20734 17.1951 1.21259 12.5287 1.21259C7.38221 1.21259 3.06662 4.85527 1.94741 9.73877C1.87272 10.0647 1.55155 10.2677 1.2307 10.1918C0.909845 10.116 0.710006 9.78975 0.784697 9.46385C2.02697 4.04354 6.81651 0 12.5287 0C17.6976 0 22.1111 3.31092 23.8242 7.95693ZM1.17291 15.8651L1.12811 15.9538C0.977837 16.2516 0.617833 16.3694 0.32467 16.2168C0.0315059 16.0641 -0.0845347 15.6984 0.0657895 15.4007L0.799667 13.9464C0.872788 13.8015 1.00013 13.6926 1.15313 13.6441C1.30612 13.5956 1.47189 13.6117 1.61305 13.6887L3.03077 14.4613C3.32105 14.6196 3.43029 14.9874 3.27452 15.2823C3.11875 15.5771 2.7566 15.6881 2.46632 15.5299L2.28867 15.433C3.76145 19.6557 7.69862 22.7128 12.3644 22.7861C17.5103 22.8669 21.8808 19.2925 23.0743 14.4272C23.154 14.1025 23.4782 13.9045 23.7978 13.9855C24.1175 14.0663 24.3123 14.3956 24.2327 14.7204C22.908 20.1205 18.0574 24.0883 12.346 23.9985C7.17767 23.9173 2.81512 20.5374 1.17291 15.8651Z" fill="white"/>
                      </svg>
                        <span className="ms-2 text-white ">Customers</span>
                      </span>
                    </div>
                  }
                  <div className="flex justify-end">
                    <button
                      className="bg-1C3E60 p-1"
                      onClick={(event) => event.preventDefault()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 9 14"
                        fill="none"
                        onMouseEnter={customerModalList}
                      >
                        <path
                          d="M2.07905 0.238604L8.47909 5.83705C8.91908 6.22192 8.91908 6.87179 8.47909 7.25665L2.07905 12.8551C1.59815 13.2758 0.809082 12.9589 0.809082 12.3452L0.809081 0.748625C0.809081 0.134829 1.59815 -0.182014 2.07905 0.238604Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </Link>
              )}
              {sideBarColl && (
                <Link
                  onMouseEnter={() => setshowCustomerHover(true)}
                  onMouseLeave={() => setshowCustomerHover(false)}
                  onClick={() => handleMenuItemClick("customers")}
                  to="/customers"
                  className={`flex items-center justify-between w-full px-2 py-1 text-White transition duration-75  gap-4 group hover:bg-2999BC text-14 ${
                    activeMenuItem === "customers" ? "active bg-122E49" : ""
                  }`}
                >
                  <div>
                    {
                      <div
                        className="p-2 "
                        onClick={(event) => event.preventDefault()}
                      >
                        
                        <Link  onClick={() => handleMenuItemClick("customers")}
                        to="/customers">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.81574 17.478C6.31314 14.165 9.12921 11.6264 12.5287 11.6264C15.93 11.6264 18.7461 14.1651 19.2425 17.478C19.2534 17.5544 19.231 17.6317 19.1814 17.69C19.1316 17.7483 19.0595 17.7818 18.9835 17.7818C17.194 17.7818 7.86407 17.7818 6.07405 17.781C5.99831 17.781 5.92629 17.7476 5.87672 17.6895C5.82716 17.6313 5.80491 17.5542 5.81574 17.478ZM12.5287 11.0948C10.6379 11.0948 9.09665 9.52738 9.09665 7.60684C9.09665 5.68624 10.6379 4.11893 12.5287 4.11893C14.4213 4.11893 15.9626 5.68624 15.9626 7.60684C15.9626 9.52738 14.4213 11.0948 12.5287 11.0948ZM23.8242 7.95693L23.8677 7.86746C24.0134 7.56734 24.3716 7.44389 24.667 7.5919C24.9625 7.73991 25.0841 8.10372 24.9384 8.40384L24.2267 9.8695C24.1558 10.0155 24.0301 10.1264 23.8779 10.1773C23.7256 10.2281 23.5597 10.2147 23.4173 10.14L21.988 9.38963C21.6954 9.23598 21.5805 8.86989 21.7318 8.57264C21.8831 8.27534 22.2434 8.15875 22.5361 8.3124L22.7152 8.40639C21.1783 4.20734 17.1951 1.21259 12.5287 1.21259C7.38221 1.21259 3.06662 4.85527 1.94741 9.73877C1.87272 10.0647 1.55155 10.2677 1.2307 10.1918C0.909845 10.116 0.710006 9.78975 0.784697 9.46385C2.02697 4.04354 6.81651 0 12.5287 0C17.6976 0 22.1111 3.31092 23.8242 7.95693ZM1.17291 15.8651L1.12811 15.9538C0.977837 16.2516 0.617833 16.3694 0.32467 16.2168C0.0315059 16.0641 -0.0845347 15.6984 0.0657895 15.4007L0.799667 13.9464C0.872788 13.8015 1.00013 13.6926 1.15313 13.6441C1.30612 13.5956 1.47189 13.6117 1.61305 13.6887L3.03077 14.4613C3.32105 14.6196 3.43029 14.9874 3.27452 15.2823C3.11875 15.5771 2.7566 15.6881 2.46632 15.5299L2.28867 15.433C3.76145 19.6557 7.69862 22.7128 12.3644 22.7861C17.5103 22.8669 21.8808 19.2925 23.0743 14.4272C23.154 14.1025 23.4782 13.9045 23.7978 13.9855C24.1175 14.0663 24.3123 14.3956 24.2327 14.7204C22.908 20.1205 18.0574 24.0883 12.346 23.9985C7.17767 23.9173 2.81512 20.5374 1.17291 15.8651Z" fill="white"/>
                          </svg>
                       </Link>
                      
                        {showCustomerHover && (
                          <div
                            className="absolute  left-16 top-[64px]  bg-122E49  p-[14px] "
                            onMouseEnter={() => setshowCustomerHover(true)}
                          >
                            <div className="space-x-24 px-[7px] flex justify-center items-center  ">
                              <label className="text-12 font-500 text-white">
                                Customer
                              </label>
                              <div className="">
                                <button
                                  className="bg-1C3E60 p-1 "
                                  onClick={(event) => event.preventDefault()}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    viewBox="0 0 9 14"
                                    fill="none"
                                    onMouseEnter={customerModalList}
                                  >
                                    <path
                                      d="M2.07905 0.238604L8.47909 5.83705C8.91908 6.22192 8.91908 6.87179 8.47909 7.25665L2.07905 12.8551C1.59815 13.2758 0.809082 12.9589 0.809082 12.3452L0.809081 0.748625C0.809081 0.134829 1.59815 -0.182014 2.07905 0.238604Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>

                            <div>
                            {sideBarColl && 
                            <div className="cusomerList fixed left-[64px]"
                              ref={closeoutsideclick}
                            >
                              <div
                                ref={customerList}
                                className="hidden  shadow-md mt-2 w-[220px] rounded "
                              >
                                <div className="flex justify-between bg-122E49 text-white  px-3 py-2">
                                  <div
                                    className="flex items-center group cursor-pointer"
                                    onClick={() => sortData("customer_name")}
                                  >
                                    <h2 className="text-16 font-500 group-hover:hvrcls">
                                      Customer List
                                    </h2>
                                    <div className="ms-4">
                                      <SortingFilter
                                        sortKey={sortKey}
                                        sortOrder={sortOrder}
                                        header={{ key: "customer_name" }}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      onClick={(event) => {
                                        closeCustomerList(event);
                                      }}
                                      className="mb-2"
                                    >
                                      <i className="bi bi-x-circle-fill"></i>
                                    </button>
                                  </div>
                                </div>

                                <div className="text-2999BC bg-white p-3">
                                  <div className="relative ">
                                    <input
                                      className="px-3 w-full h-[30px] rounded bg-FAFAFA text-[11px] border border-CED4DA text-black pr-7"
                                      type="text"
                                      placeholder="Type search"
                                      value={searchText}
                                      onChange={(e) => {
                                        handleInputChange(e?.target?.value);
                                      }}
                                    />
                                    {searchText && (
                                      <button
                                        className="absolute inset-y-0 right-2 flex items-center ml-4"
                                        onClick={() => {
                                          handleInputChange("");
                                        }}
                                      >
                                        <i className="bi bi-x text-black"></i>
                                      </button>
                                    )}
                                  </div>
                                    <ul className="py-2 px-3 text-13 font-500 mt-2 thinscrollbar mobviewlist overflow-y-auto max-h-[454px]">
                                      {sortedData &&
                                        sortedData.map((cust) => {
                                          const parsedData = parseJSONValue(cust?.customer_name);
                                          return (
                                            <li
                                              key={cust.customer_name.id}
                                              className="cursor-pointer hover:bg-2999BC hover:rounded hover:text-white px-2 py-1"
                                              onClick={() =>
                                                handleClickNavigetCustomerDetail(cust.customer_name.id)
                                              }
                                            >
                                              {parsedData?.value}
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    }
                  </div>

                  {!sideBarColl && (
                    <div className="">
                      <button
                        className="bg-1C3E60 p-1"
                        onClick={(event) => event.preventDefault()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 9 14"
                          fill="none"
                          onMouseEnter={customerModalList}
                        >
                          <path
                            d="M2.07905 0.238604L8.47909 5.83705C8.91908 6.22192 8.91908 6.87179 8.47909 7.25665L2.07905 12.8551C1.59815 13.2758 0.809082 12.9589 0.809082 12.3452L0.809081 0.748625C0.809081 0.134829 1.59815 -0.182014 2.07905 0.238604Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </Link>
              )}
            </li>
            <li className=" parentmenu pb-1">
            {!sideBarColl && <button
                type="button"
                className={`sidebarmenutab flex items-center w-full px-2 py-1 text-White transition duration-75  group hover:bg-2999BC text-14 ${
                  activeTaskpod === "taskpod" ? "bg-122E49" : ""
                }`}
                onClick={() => toggleTaskPodDropdown("taskpod")}
              >
                
                  <>
                    <i class="bi bi-clipboard-check text-18 text-white"></i>
                    <span className="flex-1 ms-2 text-left rtl:text-right whitespace-nowrap text-white">
                      Task Pod
                    </span>
                  </>                
                  <div className="flex">
                    <div className="px-3">
                      <button
                        className="p-1 bg-1C3E60"
                        onClick={(event) => {
                          event.preventDefault();
                          toggleTaskPodDropdown("taskpod");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 16 15"
                          fill="none"
                          onClick={openModal}
                        >
                          <path
                            d="M14.0877 6.22164H9.13086V1.2648C9.13086 0.717568 8.68673 0.273438 8.1395 0.273438H7.47863C6.93139 0.273438 6.48726 0.717568 6.48726 1.2648V6.22164H1.53043C0.983193 6.22164 0.539062 6.66577 0.539062 7.213V7.87387C0.539062 8.42111 0.983193 8.86524 1.53043 8.86524H6.48726V13.8221C6.48726 14.3693 6.93139 14.8134 7.47863 14.8134H8.1395C8.68673 14.8134 9.13086 14.3693 9.13086 13.8221V8.86524H14.0877C14.6349 8.86524 15.0791 8.42111 15.0791 7.87387V7.213C15.0791 6.66577 14.6349 6.22164 14.0877 6.22164Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="items-center justify-center flex">
                      <svg
                        className={`w-3 h-3 sidedownarrow text-white ${
                          isTaskPodOpen ? "rotate-180" : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </div>

                    <svg
                      className={`w-3 h-3 siderightarrow ${
                        isTaskPodOpen ? "rotate-[270deg]" : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </div>
                
              </button>}

              {sideBarColl && 
              <button
                type="button"
                className={`sidebarmenutab flex items-center w-full px-2 py-1 text-White transition duration-75  group hover:bg-2999BC text-14 ${
                  activeTaskpod === "taskpod" ? "bg-122E49" : ""
                }`}
                onClick={() => !sideBarColl && toggleTaskPodDropdown("taskpod")}
                onMouseEnter={() => setshowTaskpodHover(true)}
                onMouseLeave={() => setshowTaskpodHover(false)}
              >
                {!sideBarColl && (
                  <>
                    <i class="bi bi-clipboard-check text-18"></i>
                    <span className="flex-1 ms-2 text-left rtl:text-right whitespace-nowrap">
                      Task Pod
                    </span>
                  </>
                )}

                {sideBarColl && (
                  <div className="mr-2 p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="26"
                      viewBox="0 0 19 26"
                      fill="none"
                    >
                      <path
                        d="M16.593 2.0007H14.2499V1.01562C14.2499 0.454651 13.8188 0 13.287 0H5.71258C5.18078 0 4.74977 0.454651 4.74977 1.01562V2.0007H2.40704C1.07978 2.0007 0 3.13971 0 4.53976V23.4609C0 24.861 1.07978 26 2.40704 26H16.593C17.9202 26 19 24.861 19 23.4609V4.53976C19 3.13971 17.92 2.0007 16.593 2.0007ZM12.3242 2.03125V4.0016H6.67521C6.67521 3.32657 6.67521 2.76897 6.67521 2.03125H12.3242ZM17.0744 23.4609C17.0744 23.741 16.8583 23.9688 16.593 23.9688H2.40704C2.14151 23.9688 1.92563 23.741 1.92563 23.4609V4.53976C1.92563 4.25987 2.14151 4.03195 2.40704 4.03195H4.74958V5.01723C4.74958 5.5782 5.18078 6.03285 5.71239 6.03285H13.287C13.8187 6.03285 14.2499 5.5782 14.2499 5.01723V4.03195H16.593C16.8583 4.03195 17.0744 4.25987 17.0744 4.53976V23.4609ZM13.9676 11.8584C14.3437 12.2551 14.3437 12.8982 13.9676 13.2948L8.90792 18.632C8.53201 19.0287 7.92236 19.0287 7.54626 18.632L5.03184 15.9796C4.65593 15.5831 4.65593 14.94 5.03184 14.5433C5.40794 14.1467 6.01741 14.1467 6.39351 14.5433L8.22718 16.4775L12.6059 11.8586C12.982 11.4619 13.5915 11.4619 13.9676 11.8584Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}

                {showTaskpodHover && (
                  <div className="absolute  left-16 top-[112px]  bg-122E49  p-[14.5px] " onMouseEnter={() => setshowTaskpodHover(true)}>
                      <div className="space-x-24 px-[7px] flex justify-center items-center">
                              <label className="text-12 font-500 text-white">
                                Taskpod
                              </label>
                              <div className="flex">
                            <div className="px-3">
                              <button
                                className="p-1 bg-1C3E60"
                                onClick={(event) => event.preventDefault()}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 16 15"
                                  fill="none"
                                  onClick={openModal}
                                >
                                  <path
                                    d="M14.0877 6.22164H9.13086V1.2648C9.13086 0.717568 8.68673 0.273438 8.1395 0.273438H7.47863C6.93139 0.273438 6.48726 0.717568 6.48726 1.2648V6.22164H1.53043C0.983193 6.22164 0.539062 6.66577 0.539062 7.213V7.87387C0.539062 8.42111 0.983193 8.86524 1.53043 8.86524H6.48726V13.8221C6.48726 14.3693 6.93139 14.8134 7.47863 14.8134H8.1395C8.68673 14.8134 9.13086 14.3693 9.13086 13.8221V8.86524H14.0877C14.6349 8.86524 15.0791 8.42111 15.0791 7.87387V7.213C15.0791 6.66577 14.6349 6.22164 14.0877 6.22164Z"
                                    fill="white"
                                  />
                                </svg>
                              </button>
                            </div>

                            <div className="items-center justify-center flex">
                              <svg
                                className={`w-3 h-3 sidedownarrow ${
                                  isTaskPodOpen ? "rotate-180" : ""
                                }`}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                  d="m1 1 4 4 4-4"
                                />
                              </svg>
                            </div>

                            <svg
                              className={`w-3 h-3 siderightarrow ${
                                mobileTaskPod ? "rotate-[180deg]" : ""
                              }`}
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                              onClick={handleMobileTaskPod}
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                            {mobileTaskPod &&
                             <SmallTaskPOdDropDown 
                                isTaskPodOpen={mobileTaskPod} 
                                useSubmenuChildClass={false}
                              />
                            }
                          </div>
                      </div>
                  </div>
                )}

                {!sideBarColl && (
                  <div className="flex">
                    <div className="px-3">
                      <button
                        className="p-1 bg-1C3E60"
                        onClick={(event) => event.preventDefault()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 16 15"
                          fill="none"
                          onClick={openModal}
                        >
                          <path
                            d="M14.0877 6.22164H9.13086V1.2648C9.13086 0.717568 8.68673 0.273438 8.1395 0.273438H7.47863C6.93139 0.273438 6.48726 0.717568 6.48726 1.2648V6.22164H1.53043C0.983193 6.22164 0.539062 6.66577 0.539062 7.213V7.87387C0.539062 8.42111 0.983193 8.86524 1.53043 8.86524H6.48726V13.8221C6.48726 14.3693 6.93139 14.8134 7.47863 14.8134H8.1395C8.68673 14.8134 9.13086 14.3693 9.13086 13.8221V8.86524H14.0877C14.6349 8.86524 15.0791 8.42111 15.0791 7.87387V7.213C15.0791 6.66577 14.6349 6.22164 14.0877 6.22164Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="items-center justify-center flex">
                      <svg
                        className={`w-3 h-3 sidedownarrow ${
                          isTaskPodOpen ? "rotate-180" : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </div>

                    <svg
                      className={`w-3 h-3 siderightarrow ${
                        isTaskPodOpen ? "rotate-[270deg]" : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </div>
                )}
              </button>}

              <SmallTaskPOdDropDown 
                isTaskPodOpen={isTaskPodOpen}
                useSubmenuChildClass={true} 
              />

            </li>
            <li className=" parentmenu hidden">
              <button
                type="button"
                className="sidebarmenutab flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg group hover:bg-sky-600"
                aria-controls="sales-menu"
                data-collapse-toggle="sales-menu"
                onClick={() => handleToggleMenu(1)}
              >
                <i className="bi bi-cash-coin text-18 text-White"></i>
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap parenttext text-14">
                  Sales & Payments
                </span>
                <svg
                  className="w-3 h-3 sidedownarrow"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokelineap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="sales-menu"
                className={`py-1  space-y-1 submenuchildtext ${
                  openMenuId === 1 ? "" : "hidden"
                }`}
              >
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14"
                  >
                    <i className="bi bi-building-fill-gear text-14"></i>
                    Estimates
                  </a>
                </li>
                <li>
                  <Link
                    onClick={() => handleMenuItemClick("invoice")}
                    to="/invoice"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14"
                  >
                    <i className="bi bi-receipt text-14"></i>
                    Invoices
                  </Link>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14"
                  >
                    <i className="bi bi-wallet2 text-14"></i>
                    Payments Setup
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14"
                  >
                    <i className="bi bi-receipt-cutoff text-14"></i>
                    Recurring Invoices
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14"
                  >
                    <i className="bi bi-list-check text-14"></i>
                    Checkouts
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14"
                  >
                    <i className="bi bi-body-text text-14"></i>
                    Customer Statements
                  </a>
                </li>
                <li>
                  <Link
                    onClick={() => handleMenuItemClick("customers")}
                    to="/customers"
                    className={`flex items-center w-full py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-2999BC text-14 ${
                      activeMenuItem === "customers" ? "active bg-122E49" : ""
                    }`}
                  >
                    <i className="bi bi-person-circle"></i>
                    <spna>Customers</spna>
                    <div className="pl-6  space-x-2">
                      <button
                        onClick={() => navigate("/add_customer")}
                        className=" bg-1C3E60 p-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 16 15"
                          fill="none"
                        >
                          <path
                            d="M14.0877 6.22164H9.13086V1.2648C9.13086 0.717568 8.68673 0.273438 8.1395 0.273438H7.47863C6.93139 0.273438 6.48726 0.717568 6.48726 1.2648V6.22164H1.53043C0.983193 6.22164 0.539062 6.66577 0.539062 7.213V7.87387C0.539062 8.42111 0.983193 8.86524 1.53043 8.86524H6.48726V13.8221C6.48726 14.3693 6.93139 14.8134 7.47863 14.8134H8.1395C8.68673 14.8134 9.13086 14.3693 9.13086 13.8221V8.86524H14.0877C14.6349 8.86524 15.0791 8.42111 15.0791 7.87387V7.213C15.0791 6.66577 14.6349 6.22164 14.0877 6.22164Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                      <button
                        className="bg-1C3E60 p-1"
                        onClick={(event) => event.preventDefault()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 9 14"
                          fill="none"
                          onMouseEnter={customerModalList}
                        >
                          <path
                            d="M2.07905 0.238604L8.47909 5.83705C8.91908 6.22192 8.91908 6.87179 8.47909 7.25665L2.07905 12.8551C1.59815 13.2758 0.809082 12.9589 0.809082 12.3452L0.809081 0.748625C0.809081 0.134829 1.59815 -0.182014 2.07905 0.238604Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </Link>
                </li>

                <li className=" parentmenu pb-1">
                  <button
                    type="button"
                    className={`sidebarmenutab flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg group pl-6 hover:bg-2999BC text-14 ${
                      activeTaskpod === "taskpod" ? "bg-122E49" : ""
                    }`}
                    onClick={() => toggleTaskPodDropdown("taskpod")}
                  >
                    <i className="bi bi-list-task"></i>
                    <span className="flex-1 ms-2 text-left rtl:text-right whitespace-nowrap">
                      Task Pod
                    </span>

                    <div className="px-3">
                      <button
                        className="p-1 bg-1C3E60"
                        onClick={(event) => event.preventDefault()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 16 15"
                          fill="none"
                          onClick={openModal}
                        >
                          <path
                            d="M14.0877 6.22164H9.13086V1.2648C9.13086 0.717568 8.68673 0.273438 8.1395 0.273438H7.47863C6.93139 0.273438 6.48726 0.717568 6.48726 1.2648V6.22164H1.53043C0.983193 6.22164 0.539062 6.66577 0.539062 7.213V7.87387C0.539062 8.42111 0.983193 8.86524 1.53043 8.86524H6.48726V13.8221C6.48726 14.3693 6.93139 14.8134 7.47863 14.8134H8.1395C8.68673 14.8134 9.13086 14.3693 9.13086 13.8221V8.86524H14.0877C14.6349 8.86524 15.0791 8.42111 15.0791 7.87387V7.213C15.0791 6.66577 14.6349 6.22164 14.0877 6.22164Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>

                    <div>
                      <svg
                        className={`w-3 h-3 sidedownarrow ${
                          isTaskPodOpen ? "rotate-180" : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                      <svg
                        className={`w-3 h-3 siderightarrow ${
                          isTaskPodOpen ? "rotate-[270deg]" : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </div>
                  </button>

                 <SmallTaskPOdDropDown isTaskPodOpen={isTaskPodOpen} yes/>
                </li>

                <li>
                  <a
                    href="#"
                    className="flex items-center w-full p-2 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14"
                  >
                    <i className="bi bi-ui-checks-grid text-14"></i>
                    Products & Services
                  </a>
                </li>
              </ul>
            </li>

            <li className=" parentmenu pb-1 hidden">
              <a
                href="#"
                className="sidebarmenutab flex items-center px-2 py-1 text-White rounded-lg hover:bg-sky-600 group text-14"
              >
                <i className="bi bi-cart4 text-18"></i>
                <span className="flex-1 ms-3 whitespace-nowrap parenttext">
                  Purchases
                </span>
              </a>
            </li>

            <li className="parentmenu pb-1 hidden">
              <button
                type="button"
                className="sidebarmenutab flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg group hover:bg-sky-600 text-14"
                aria-controls="account-menu"
                data-collapse-toggle="account-menu"
                onClick={() => handleToggleMenu(2)}
              >
                <i className="bi bi-piggy-bank text-18"></i>
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap parenttext text-14">
                  Accounting
                </span>
                <svg
                  className="w-3 h-3 sidedownarrow"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="account-menu"
                className={`py-2 submenuchildtext ${
                  openMenuId === 2 ? "" : "hidden"
                }`}
              >
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14"
                  >
                    Transactions
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14"
                  >
                    Reconciliation
                  </a>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      handleMenuItemClick("import_chart_of_account")
                    }
                    to="/import_chart_of_account"
                    className={`flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-122E49 text-14 
                      ${
                        activeMenuItem === "import_chart_of_account"
                          ? "active bg-122E49"
                          : ""
                      } `}
                  >
                    Chart of Accounts
                  </Link>
                </li>
              </ul>
            </li>

            <li className=" parentmenu pb-1 hidden">
              <button
                type="button"
                className="sidebarmenutab flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg group hover:bg-sky-600"
                aria-controls="banking-menu"
                data-collapse-toggle="banking-menu"
                onClick={() => handleToggleMenu(3)}
              >
                <i className="bi bi-bank2 text-18"></i>
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap parenttext text-14">
                  Banking
                </span>
                <svg
                  className="w-3 h-3 sidedownarrow"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="banking-menu"
                className={`py-1 space-y-1 submenuchildtext ${
                  openMenuId === 3 ? "" : "hidden"
                }`}
              >
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-sky-600 text-14"
                  >
                    Connected Accounts
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-6 gap-4 group hover:bg-sky-600 text-14"
                  >
                    Payouts
                  </a>
                </li>
              </ul>
            </li>

            <li className=" parentmenu pb-2 hidden">
              <button
                type="button"
                className="sidebarmenutab flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg group hover:bg-sky-600 text-14"
                aria-controls="payroll-menu"
                data-collapse-toggle="payroll-menu"
                onClick={() => handleToggleMenu(4)}
              >
                <i className="bi bi-currency-bitcoin text-18"></i>
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap parenttext text-14">
                  Payroll
                </span>
                <svg
                  className="w-3 h-3 sidedownarrow"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              <ul
                id="banking-menu"
                className={`py-1 space-y-1 submenuchildtext ${
                  openMenuId === 4 ? "" : "hidden"
                }`}
              >
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-11 group hover:bg-sky-600 text-14"
                  >
                    Run Payroll
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-11 group hover:bg-sky-600 text-14"
                  >
                    Employees
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-11 group hover:bg-sky-600 text-14"
                  >
                    Timesheets
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-11 group hover:bg-sky-600 text-14"
                  >
                    Payroll Transactions
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-11 group hover:bg-sky-600 text-14"
                  >
                    Taxes
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-11 group hover:bg-sky-600 text-14"
                  >
                    Tax Forms
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center w-full px-2 py-1 text-White transition duration-75 rounded-lg pl-11 group hover:bg-sky-600 text-14"
                  >
                    COVID-19
                  </a>
                </li>
              </ul>
            </li>

            <li className="parentmenu pb-2">
              <Link
                onClick={() => handleMenuItemClick("vendor_list")}
                to="/vendor_list"
                className={`flex items-center justify-between w-full py-1 text-White transition duration-75  px-2 gap-4 group hover:bg-2999BC text-14 ${
                  activeMenuItem === "vendor_list" ? "active bg-122E49" : ""
                }`}
              >
                {!sideBarColl && (
                  <span className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 23 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.91667 0.5C1.57094 0.5 1.26798 0.731395 1.17701 1.06495L0.0270135 5.28162C0.00908504 5.34736 0 5.41518 0 5.48333C0 6.50511 0.444188 7.42315 1.15 8.05485V19.6667C1.15 21.7837 2.86624 23.5 4.98333 23.5H11.5C11.9234 23.5 12.2667 23.1568 12.2667 22.7333C12.2667 22.3099 11.9234 21.9667 11.5 21.9667H4.98333C3.71308 21.9667 2.68333 20.9369 2.68333 19.6667V8.84785C2.9299 8.90378 3.1865 8.93333 3.45 8.93333C4.5338 8.93333 5.50083 8.43358 6.13333 7.65197C6.76583 8.43358 7.73287 8.93333 8.81667 8.93333C9.90047 8.93333 10.8675 8.43358 11.5 7.65197C12.1325 8.43358 13.0995 8.93333 14.1833 8.93333C15.2671 8.93333 16.2342 8.43358 16.8667 7.65197C17.4992 8.43358 18.4662 8.93333 19.55 8.93333C19.8135 8.93333 20.0701 8.90378 20.3167 8.84785V12.575C20.3167 12.9984 20.6599 13.3417 21.0833 13.3417C21.5068 13.3417 21.85 12.9984 21.85 12.575V8.05485C22.5558 7.42315 23 6.50511 23 5.48333C23 5.41518 22.9909 5.34736 22.973 5.28162L21.823 1.06495C21.732 0.731395 21.4291 0.5 21.0833 0.5H1.91667ZM8.81667 7.4C7.75813 7.4 6.9 6.54187 6.9 5.48333C6.9 5.0599 6.55676 4.71667 6.13333 4.71667C5.7099 4.71667 5.36667 5.0599 5.36667 5.48333C5.36667 6.54187 4.50854 7.4 3.45 7.4C2.42308 7.4 1.58478 6.59239 1.53561 5.57763L2.50224 2.03333H20.4978L21.4644 5.57763C21.4152 6.59239 20.5769 7.4 19.55 7.4C18.4915 7.4 17.6333 6.54187 17.6333 5.48333C17.6333 5.0599 17.2901 4.71667 16.8667 4.71667C16.4432 4.71667 16.1 5.0599 16.1 5.48333C16.1 6.54187 15.2419 7.4 14.1833 7.4C13.1248 7.4 12.2667 6.54187 12.2667 5.48333C12.2667 5.0599 11.9234 4.71667 11.5 4.71667C11.0766 4.71667 10.7333 5.0599 10.7333 5.48333C10.7333 6.54187 9.8752 7.4 8.81667 7.4Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0833 16.2167C21.0833 17.0163 20.7773 17.7444 20.2759 18.2904C21.8927 19.1141 23 20.7944 23 22.7333C23 23.1568 22.6568 23.5 22.2333 23.5C21.8099 23.5 21.4667 23.1568 21.4667 22.7333C21.4667 20.8279 19.9221 19.2833 18.0167 19.2833C16.1113 19.2833 14.5667 20.8279 14.5667 22.7333C14.5667 23.1568 14.2234 23.5 13.8 23.5C13.3766 23.5 13.0333 23.1568 13.0333 22.7333C13.0333 20.7944 14.1407 19.1141 15.7574 18.2904C15.2561 17.7444 14.95 17.0163 14.95 16.2167C14.95 14.523 16.323 13.15 18.0167 13.15C19.7103 13.15 21.0833 14.523 21.0833 16.2167ZM16.4833 16.2167C16.4833 15.3698 17.1698 14.6833 18.0167 14.6833C18.8635 14.6833 19.55 15.3698 19.55 16.2167C19.55 17.0635 18.8635 17.75 18.0167 17.75C17.1698 17.75 16.4833 17.0635 16.4833 16.2167Z" fill="white"/>
                    </svg>
                    <spna className="ms-2 text-white">Vendor</spna>
                  </span>
                )}

                {sideBarColl && (
                  <div className=" p-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.91667 0.5C1.57094 0.5 1.26798 0.731395 1.17701 1.06495L0.0270135 5.28162C0.00908504 5.34736 0 5.41518 0 5.48333C0 6.50511 0.444188 7.42315 1.15 8.05485V19.6667C1.15 21.7837 2.86624 23.5 4.98333 23.5H11.5C11.9234 23.5 12.2667 23.1568 12.2667 22.7333C12.2667 22.3099 11.9234 21.9667 11.5 21.9667H4.98333C3.71308 21.9667 2.68333 20.9369 2.68333 19.6667V8.84785C2.9299 8.90378 3.1865 8.93333 3.45 8.93333C4.5338 8.93333 5.50083 8.43358 6.13333 7.65197C6.76583 8.43358 7.73287 8.93333 8.81667 8.93333C9.90047 8.93333 10.8675 8.43358 11.5 7.65197C12.1325 8.43358 13.0995 8.93333 14.1833 8.93333C15.2671 8.93333 16.2342 8.43358 16.8667 7.65197C17.4992 8.43358 18.4662 8.93333 19.55 8.93333C19.8135 8.93333 20.0701 8.90378 20.3167 8.84785V12.575C20.3167 12.9984 20.6599 13.3417 21.0833 13.3417C21.5068 13.3417 21.85 12.9984 21.85 12.575V8.05485C22.5558 7.42315 23 6.50511 23 5.48333C23 5.41518 22.9909 5.34736 22.973 5.28162L21.823 1.06495C21.732 0.731395 21.4291 0.5 21.0833 0.5H1.91667ZM8.81667 7.4C7.75813 7.4 6.9 6.54187 6.9 5.48333C6.9 5.0599 6.55676 4.71667 6.13333 4.71667C5.7099 4.71667 5.36667 5.0599 5.36667 5.48333C5.36667 6.54187 4.50854 7.4 3.45 7.4C2.42308 7.4 1.58478 6.59239 1.53561 5.57763L2.50224 2.03333H20.4978L21.4644 5.57763C21.4152 6.59239 20.5769 7.4 19.55 7.4C18.4915 7.4 17.6333 6.54187 17.6333 5.48333C17.6333 5.0599 17.2901 4.71667 16.8667 4.71667C16.4432 4.71667 16.1 5.0599 16.1 5.48333C16.1 6.54187 15.2419 7.4 14.1833 7.4C13.1248 7.4 12.2667 6.54187 12.2667 5.48333C12.2667 5.0599 11.9234 4.71667 11.5 4.71667C11.0766 4.71667 10.7333 5.0599 10.7333 5.48333C10.7333 6.54187 9.8752 7.4 8.81667 7.4Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0833 16.2167C21.0833 17.0163 20.7773 17.7444 20.2759 18.2904C21.8927 19.1141 23 20.7944 23 22.7333C23 23.1568 22.6568 23.5 22.2333 23.5C21.8099 23.5 21.4667 23.1568 21.4667 22.7333C21.4667 20.8279 19.9221 19.2833 18.0167 19.2833C16.1113 19.2833 14.5667 20.8279 14.5667 22.7333C14.5667 23.1568 14.2234 23.5 13.8 23.5C13.3766 23.5 13.0333 23.1568 13.0333 22.7333C13.0333 20.7944 14.1407 19.1141 15.7574 18.2904C15.2561 17.7444 14.95 17.0163 14.95 16.2167C14.95 14.523 16.323 13.15 18.0167 13.15C19.7103 13.15 21.0833 14.523 21.0833 16.2167ZM16.4833 16.2167C16.4833 15.3698 17.1698 14.6833 18.0167 14.6833C18.8635 14.6833 19.55 15.3698 19.55 16.2167C19.55 17.0635 18.8635 17.75 18.0167 17.75C17.1698 17.75 16.4833 17.0635 16.4833 16.2167Z" fill="white"/>
                    </svg>
                  </div>
                )}

                {!sideBarColl && (
                  <div className="pl-6  space-x-2">
                    <button
                      className="bg-1C3E60 p-1"
                      onClick={(event) => event.preventDefault()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 9 14"
                        fill="none"
                        onMouseEnter={customerModalList}
                      >
                        <path
                          d="M2.07905 0.238604L8.47909 5.83705C8.91908 6.22192 8.91908 6.87179 8.47909 7.25665L2.07905 12.8551C1.59815 13.2758 0.809082 12.9589 0.809082 12.3452L0.809081 0.748625C0.809081 0.134829 1.59815 -0.182014 2.07905 0.238604Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </Link>
            </li>
            
          
            <li className=" parentmenu pb-2 hidden">
              <a
                href="#"
                className="sidebarmenutab flex items-center px-2 py-1 text-White rounded-lg hover:bg-sky-600 group"
              >
                <i className="bi-clipboard-data text-18"></i>
                <span className="flex-1 ms-3 whitespace-nowrap parenttext text-14">
                  Reports
                </span>
              </a>
            </li>

            <li className="parentmenu pb-2 hidden">
              <a
                href="#"
                className="sidebarmenutab flex items-center px-2 py-1 text-White rounded-lg hover:bg-sky-600 group"
              >
                <div className="relative">
                  <i className="bi bi-pc-display-horizontal text-8"></i>
                  <i className="bi bi-rocket text-8 absolute bottom-2 left-0"></i>
                </div>

                <span className="flex-1 ms-3 whitespace-nowrap parenttext text-14">
                  App
                </span>
              </a>
            </li>

            <li className="menubottomborder parentmenu pb-1 hidden">
              <a
                href="#"
                className="sidebarmenutab flex items-center px-2 py-1 text-White rounded-lg hover:bg-sky-600 group"
              >
                <i className="bi bi-bar-chart-steps text-18"></i>
                <span className="flex-1 ms-3 whitespace-nowrap parenttext text-14">
                  Others
                </span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
          <div className="bg-black bg-opacity-50 absolute inset-0"></div>
          <div className="relative mt-18 w-[510px] bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md overflow-hidden no-scrollbar">
            <div className="flex items-center justify-between  pt-2 pb-1  px-6 rounded-t">
              <h3 className="text-16 font-500  text-2C495D">Add Taskpod</h3>
              <button
                onClick={closeModal}
                type="button"
                className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto pl-4 inline-flex justify-center items-center"
                data-modal-hide="assets-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <form
              className="w-full lg:max-h-[85vh] overflow-hidden  no-scrollbar"
              onSubmit={handleSubmitTaskPod}
            >
              {/**overflow-y-auto */}
              {showMessage && (
                <p className="text-green-500 text-right">{message}</p>
              )}
              <div className="grid grid-cols-1 ">
                <div className="basis-5/5 mb-3 px-6 ">
                  <label
                    htmlFor="subject"
                    className="block mb-1.5 pt-2 text-14 font-400 text-434852 border-t border-BECBD3"
                  >
                    Name <span className=" text-red-500"> *</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    className="bg-white rounded border border-DFE2E5 text-gray-900 text-sm block w-full p-2.5 h-9"
                  />
                  {formErrors.name && (
                    <span className="text-red-500 text-xs">
                      Cannot be left blank.
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1">
                <div className="basis-5/5 mb-3 px-6">
                  <label
                    htmlFor="subject"
                    className="block mb-1.5 text-14 font-400 text-434852"
                  >
                    Description
                  </label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    onChange={handleChange}
                    className="bg-white rounded border border-DFE2E5 text-gray-900 text-sm block w-full p-2.5 h-20"
                  />
                </div>
              </div>
              <div className="sticky bottom-0 rounded flex justify-end pb-3  text-md w-full">
                <div className="px-6">
                  <button
                    onClick={closeModal}
                    type="button"
                    className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-1 text-center rounded"
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold  border border-gray-200 py-1 px-5 ms-3 rounded"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {sideBarColl && <div
        className="cusomerList fixed left-16 ml-[3px] top-[20%] mt-[2px] z-[1000000]"
        ref={closeoutsideclick}
      >
        <div
          ref={customerList}
          className="hidden  shadow-md mt-2 w-[220px] rounded"
        >
          <div className="flex justify-between bg-122E49 text-white  px-3 py-2">
            <div
              className="flex items-center group cursor-pointer"
              onClick={() => sortData("customer_name")}
            >
              <h2 className="text-16 font-500 group-hover:hvrcls">
                Customer List
              </h2>
              <div className="ms-4">
                <SortingFilter
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  header={{ key: "customer_name" }}
                />
              </div>
            </div>
            <div>
              <button
                onClick={(event) => {
                  closeCustomerList(event);
                }}
                className="mb-2"
              >
                <i className="bi bi-x-circle-fill"></i>
              </button>
            </div>
          </div>

          <div className="text-2999BC bg-white p-3">
            <div className="relative ">
              <input
                className="px-3 w-full h-[30px] rounded bg-FAFAFA text-[11px] border border-CED4DA text-black pr-7"
                type="text"
                placeholder="Type search"
                value={searchText}
                onChange={(e) => {
                  handleInputChange(e?.target?.value);
                }}
              />
              {searchText && (
                <button
                  className="absolute inset-y-0 right-2 flex items-center ml-4"
                  onClick={() => {
                    handleInputChange("");
                  }}
                >
                  <i className="bi bi-x text-black"></i>
                </button>
              )}
            </div>

            <ul className="py-2 px-3 text-13 font-500 mt-2 thinscrollbar overflow-y-auto max-h-[454px]">
              {sortedData &&
                sortedData.map((cust) => {
                  const parsedData = parseJSONValue(cust?.customer_name);
                  return (
                    <li
                      key={cust.customer_name.id}
                      className="cursor-pointer hover:bg-2999BC hover:rounded hover:text-white px-2 py-1"
                      onClick={() =>
                        handleClickNavigetCustomerDetail(cust.customer_name.id)
                      }
                    >
                      {parsedData?.value}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>}
      {!sideBarColl && <div
        className="cusomerList fixed left-[17%] top-[14%]   z-[1000000]"
        ref={closeoutsideclick}
      >
        <div
          ref={customerList}
          className="hidden  shadow-md mt-2 w-[220px] rounded"
        >
          <div className="flex justify-between bg-122E49 text-white  px-3 py-2">
            <div
              className="flex items-center group cursor-pointer"
              onClick={() => sortData("customer_name")}
            >
              <h2 className="text-16 font-500 group-hover:hvrcls">
                Customer List
              </h2>
              <div className="ms-4">
                <SortingFilter
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  header={{ key: "customer_name" }}
                />
              </div>
            </div>
            <div>
              <button
                onClick={(event) => {
                  closeCustomerList(event);
                }}
                className="mb-2"
              >
                <i className="bi bi-x-circle-fill"></i>
              </button>
            </div>
          </div>

          <div className="text-2999BC bg-white p-3">
            <div className="relative ">
              <input
                className="px-3 w-full h-[30px] rounded bg-FAFAFA text-[11px] border border-CED4DA text-black pr-7"
                type="text"
                placeholder="Type search"
                value={searchText}
                onChange={(e) => {
                  handleInputChange(e?.target?.value);
                }}
              />
              {searchText && (
                <button
                  className="absolute inset-y-0 right-2 flex items-center ml-4"
                  onClick={() => {
                    handleInputChange("");
                  }}
                >
                  <i className="bi bi-x text-black"></i>
                </button>
              )}
            </div>

            <ul className="py-2 px-3 text-13 font-500 mt-2 thinscrollbar overflow-y-auto max-h-[454px]">
              {sortedData &&
                sortedData.map((cust) => {
                  const parsedData = parseJSONValue(cust?.customer_name);
                  return (
                    <li
                      key={cust.customer_name.id}
                      className="cursor-pointer hover:bg-2999BC hover:rounded hover:text-white px-2 py-1"
                      onClick={() =>
                        handleClickNavigetCustomerDetail(cust.customer_name.id)
                      }
                    >
                      {parsedData?.value}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>}
      {/* <div className="relative">
        <input
          className="px-3 w-full h-[30px] rounded bg-FAFAFA text-[11px] border border-CED4DA text-black pr-7"
          type="text"
          placeholder="Type search"
          value={searchUserText}
          onChange={handleInputChangeUserList}
        />
        {searchUserText && (
          <button
            className="absolute inset-y-0 right-2 flex items-center ml-4"
            onClick={clearSearchUserList}
            onKeyUp={clearSearchValue}
          >
            <i className="bi bi-x text-black"></i>
          </button>
        )}
      </div> */}

      <ul className="absolute py-2 space-y-2 px-3 text-White text-14 font-200 mt-2 thinscrollbar overflow-y-auto max-h-[454px] "></ul>
    </Fragment>
  );
}

export default Leftmenu;
