import React, { useEffect, useState } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import {
 
  saveServicesDataAsync,
  hideMessage,
} from "../../utils/customer/servicesSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFetchServiceCategoryQuery } from "../../utils/customer/customerSelect";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";

const validationRules = {
  service_id : {
    required: true,
    errorMessage: 'Service Type is required.',
  }
};
const ServicesModal = ({ isOpen, onClose ,refetchCustomerData}) => {
  const { token } = useRequireAuth();
  const { id } = useParams();
  const customerId = parseInt(id);
  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const message = useSelector(state => state.customerServices.message);
  const showMessage = useSelector(state => state.customerServices.showMessage);
  const [disable,setDisable]=useState();


 
  const [formErrors, setFormErrors] = useState({
    service_id:'',
});
const [formData, setFormData] = useState({
  service_id:"",
  
});

const { values, errors, touched, handleChange, handleBlur, validateField, isFormValid } = useCustomFormValidation(
  formData,
  validationRules
);

const handleFieldChange = (e) => {
  handleChange(e);

  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};

const validateForm = () => {
  const requiredFields = ['service_id'];
  const errors = {};
  requiredFields.forEach(field => {
      if (!formData[field]) {
          errors[field] = 'Cannot be left blank.';
      }
  });
 
  setFormErrors(errors);
  return Object.keys(errors).length === 0;
};
  const handleSave = async(event) => {
    event.preventDefault();
    const formValid = isFormValid();
    setDisable(true);
      if(!formValid){
        return;
      }
      const socialData = {
        service_id: JSON.stringify({value:event.target.service_id.value}),
        descriptions: event.target.description.value,
        customer_id: customerId,
        company_id: parsedCompanyData.company_id
      
      };
      

      try {
        await dispatch(saveServicesDataAsync({ socialData, token }));
        onClose();
        await refetchCustomerData();
      } catch (error) {
        
      }
     
    
  };

  const { data: customerServiceCategory,refetch } = useFetchServiceCategoryQuery({token});

  useEffect(()=>{
    setDisable(false);
  },[message])


  return (
    <div className="fixed inset-y-0 z-[999] grid h-screen place-items-center justify-start">
      <div className="relative  py-3 mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md w-500px overflow-hidden no-scrollbar">
        <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
          <div className="px-2">
         
          </div>
          <br />
          <h3 className="text-2xl text-2C495D">Add Service</h3>

          <button
            onClick={onClose}
            type="button"
            className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <form
          onSubmit={handleSave}
          className="w-full lg:max-h-[70vh]  overflow-x-hidden  no-scrollbar"
        >
          {/**overflow-y-auto */}

          <div className="basis-5/5 mb-3 px-6 pt-2 ">
            <label
              htmlFor="categories"
              className="block mb-2 text-sm text-434852"
            >
              Services <span className=" text-red-500"> *</span>
              {errors && <span className="text-red-700"> {errors.service_id}</span>}
            </label>

            <select
              id={`service_id`}
              name="service_id"
              onChange={handleFieldChange}
              className={`form-select w-6 border border-BFBFBF text-383838 text-md  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1`}
            >
              <option> --</option>
             
              {!customerServiceCategory
                    ? null
                    : customerServiceCategory.map((CategoryData) => (
                      <option value={CategoryData.name}>{CategoryData.name}</option>
                    ))}
            </select>
            {formErrors.service_id && (
                <span className="text-red-500 text-xs">
                  Cannot be left blank.
                </span>
            )}
          </div>

          <div className="basis-5/5 mb-3 px-6">
            <label
              htmlFor="account_number"
              className="block mb-2 text-sm text-434852"
            >
              Description
            </label>
            <textarea
              type="message"
              id="description"
              name="description"
              onChange={handleFieldChange}
              className="bg-white border  border-DFE2E5 text-gray-900 text-sm block w-full p-2.5 h-20"
        
            />
           
           
          </div>

          

          <div className="flex justify-end py-4  modal-footer text-md w-full">
            <div className="px-6">
              <button
                onClick={onClose}
                type="button"
                className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-2 text-center rounded-sm"
              >
                CANCEL
              </button>
              <button
                type="submit"
                disable={disable}
                className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold text-gray-900 border border-gray-200 py-2 px-5 ms-3 rounded-sm"
              >
                SAVE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ServicesModal;
