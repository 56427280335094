import React,{useEffect} from 'react';
import { Link,useNavigate,useLocation  } from "react-router-dom";

const TopTitle = ({ name,groupname,type,pt,pb,icon,url,nameletter  }) => {    

  return (
    <span className={`fixed top-0 flex items-center ${icon ? '-ml-2' : 'ml-0'}  pt-[${pt}] z-[9999]`}>
        {icon && (
            <span className='cuthome-icon hover:bg-1D4469 transition duration-600 ease-in-out'>
                {url ? (
                <Link to={url} className="flex items-center">
                    {icon}
                </Link>
                ) : (
                icon
                )}
            </span>
        )}
        {nameletter && (
            <span className='nml-circle'>
                {nameletter}
            </span>
        )}    
        {name && groupname ? (
            <span className='flex items-start'>                
                <span className='me-2'>
                    <p className='font-medium text-18 text-203C50 capitalize mb-0'>{groupname}</p>
                    <p className="text-xs text-red-400">{type}</p>
                </span>
                <span className='flex items-start'>
                    <span className='me-2'>-</span>
                    <span>{name}</span>
                </span>
            </span>    
        ) : (
            <span>{name}</span>
        )}   
    </span>
  );
};

export default TopTitle;
