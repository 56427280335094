import React from "react";

const TaskIcon = () => {
  return (
    <>
      <i className="bi bi-file-earmark-plus"></i>
    </>
  );
};

export default TaskIcon;
