import React,{useState,useEffect, Fragment} from 'react'
import AddPermission from './AddPermission'
import { useFetchPermissionTableDataQuery } from '../../utils/users/permissions';
import useRequireAuth from '../../utils/useRequireAuth';
import CustomerListTable from '../customer/CustomerListTable';
import PermissionHeader from './PermissionHeader';

const PermissionTable = ({handleNavigate,TableNav}) => {

    const [permissionTablePagination, setPermissionTablePagination] = useState("");
    const [permission, setPermission] = useState([]);    
    const [initialFilter, setInitialFilter] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(null);

    const {token}=useRequireAuth();
    const { data: permissionTableALL, refetch: refetchPermissionData } =
     useFetchPermissionTableDataQuery({       
       pagination: `${permissionTablePagination}`,
       token: token,
     });

     useEffect(() => {
        if (permissionTableALL) {
          const { data, ...restData } = permissionTableALL?.data;
          setPermission(data);
          setInitialFilter(restData);
        }
      }, [permissionTableALL, permissionTablePagination, refetchPermissionData]);

      const handleActionEdit = (data) => {
        setIsPopupOpen(data) 
      }
  return (
    <Fragment>     
      <CustomerListTable
          tableDetail={{
              tableidclass: "fullscreen-table",
              tableparentidclass: "tableparent",
              tablename: TableNav(),
              tablenameclass: "font-medium text-16 text-203C50 capitalize",
              tablename: "Manage Permission",
              tableSearch: true,
              tableFilter: true,
              forcefullyfreeze: true,
              tableResizer: false,
              tableZoom: true,
              tableheader: false,
              dataactivity: true,
              headeraddfield: false,
              addbutton: (
                <Fragment>
                  <button
                    onClick={() => { setIsPopupOpen(true) } }
                    type="button"
                    className="text-2541558A hover:text-254155"
                  >
                    <i className="bi bi-plus-lg"></i>
                  </button>
                  <AddPermission refetchData={refetchPermissionData} setIsPopupOpen={setIsPopupOpen} isPopupOpen={isPopupOpen}/>
                </Fragment>
              ),
              actionfield: (data) => (
                <Fragment>
                  <td>
                  <div className="text-left" style={{ paddingLeft: '20px' }}>
                      <button onClick={() => handleActionEdit(data)} className='text-F84545 text-15'> <i class="bi bi-pencil "></i> </button>
                    </div>
                  </td>
                </Fragment>                
              ),
          }}
          thead={PermissionHeader}
          tbody={permission}
          tablePaginationData={setPermissionTablePagination}
          initialFilter={initialFilter}
          navigateNameCondition={["group_name","permission"]}
          handleNavigate={handleNavigate}          
      />

    </Fragment>

  )
}

export default PermissionTable