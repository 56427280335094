import React from 'react';

const InvoiceEditCompanyModal = () => {
  return (
    <div className="border-t-2 border-[#203C501A] px-2">
      <h3 className='mt-5 mb-5 text-2C495D text-14 font-600'>Changes made here will update your company information everywhere.</h3>
      <div className='space-y-3'>
        <div>
          <label className='text-434852 text-14 font-500'>Company Name (Required)</label>
          <input className='w-full border-DFE2E5 rounded mt-1 px-2 outline-none' type='text' />
        </div>
        <div>
          <label className='text-434852 text-14 font-500'>Email</label>
          <input className='w-full border-DFE2E5 rounded mt-1 px-2 outline-none' type='email' />
        </div>
        <div>
          <label className='text-434852 text-14 font-500'>Phone Number</label>
          <input className='w-full border-DFE2E5 rounded mt-1 px-2 outline-none' type='number' />
        </div>
        <div>
          <label className='text-434852 text-14 font-500'>Website</label>
          <input className='w-full border-DFE2E5 rounded mt-1 px-2 outline-none' type='text' />
        </div>
      </div>
      <div className='space-y-3 mt-6'>
        <h3 className='text-2C495D text-14 font-600'>Company Address</h3>
        <div>
          <label className='text-434852 text-14 font-500'>Street</label>
          <input className='w-full border-DFE2E5 rounded mt-1 px-2 outline-none' type='text' />
        </div>
        <div>
          <label className='text-434852 text-14 font-500'>City</label>
          <input className='w-full border-DFE2E5 rounded mt-1 px-2 outline-none' type='text' />
        </div>
        <div className='flex space-x-2'>
          <div className='w-1/2'>
            <label className='text-434852 text-14 font-500'>State</label>
            <input className='w-full border-DFE2E5 rounded mt-1 px-2 outline-none' type='text' />
          </div>
          <div className='w-1/2'>
            <label className='text-434852 text-14 font-500'>Zip Code</label>
            <input className='w-full border-DFE2E5 rounded mt-1 px-2 outline-none' type='number' />
          </div>
        </div>
      </div>
      <div className='mt-5 ms-2'>
        <h3 className='text-14 text-2C495D font-600'>Customer-facing Address</h3>
        <div className="flex items-center mt-3">
          <input
            type="checkbox"
            value=""
            className="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:outline-none focus:ring-0 checked:bg-537596 input:border-none"
          />
          <label
            htmlFor="link-checkbox"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 "
          >
            Same as company address
          </label>
        </div>
      </div>
      <div className='flex justify-end items-end mt-[10%]'>
        <button className='border py-2 px-6 text-16 font-500 border-123251 rounded bg-1D4469 text-white '>SAVE</button>
      </div>
    </div>
  );
}

export default InvoiceEditCompanyModal;
