import React from 'react'

const ClearAllFilter = ({setTableSearchInput,setSearchTerms,clearAllFilters}) => {

    const clearAllFilter = () => {
        setTableSearchInput('')
        setSearchTerms({})
        clearAllFilters();
        
    }
  return (
    <button className='p-1 text-xs text-DF4321 border hover:border-DF4321' onClick={clearAllFilter}>Clear All</button>
  )
}

export default ClearAllFilter