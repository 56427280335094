import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchCustomerAddressDataBasedOnIdQuery } from '../../utils/customer/customerSelect';
import useRequireAuth from '../../utils/useRequireAuth';
import { parseJSONFields } from '../../utils/json/parseJSONFields';
import { skipToken } from '@reduxjs/toolkit/query/react';
 
const ClientInfoCard = ({ isOpen, onToggle, handleRouteSection }) => {
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const [formData, setFormData] = useState({});
 
  const { data: customerAddress } = useFetchCustomerAddressDataBasedOnIdQuery(
    isOpen ? { token, customerId } : skipToken
  );
 
  useEffect(() => {
    if (customerAddress) {
      const parsedResponse = parseJSONFields(customerAddress?.data[0]);
      setFormData(Array.isArray(parsedResponse) ? parsedResponse : parsedResponse);
    }
  }, [customerAddress]);
 
  return (
    <div className="bg-1d44691a border border-1D446933 mb-3 rounded-5px">
      <div className="flex justify-between px-3 py-2">
        <h4 className="text-2C495D font-normal cursor-pointer" onClick={handleRouteSection}>General Info</h4>
        <button onClick={onToggle}>
          <i className={`bi bi-${isOpen ? "chevron-up" : "chevron-down"} `}></i>
        </button>
      </div>
      {isOpen && (
        <Fragment>
          <div className="p-3 bg-white rounded-b-lg">
            <div className="my-3">
              <div className="text-12 font-400 text-5E5E5E">Name</div>
              <div className="text-12 font-400 text-2C495D mb-2">
              {formData?.customer_name} 
              </div>
            </div>
            <div className="my-3">
              <div className="text-12 font-400 text-5E5E5E">Email</div>
              <div className="text-12 font-400 text-2C495D mb-2">
                {formData?.email}
              </div>
            </div>
            {/* <div className="my-3">
              <div className="text-12 font-400 text-5E5E5E">Type</div>
              <div className="text-12 font-400 text-2C495D mb-2">
                {formData?.type?.value}
              </div>
            </div> */}
            <div className="my-3">
              <div className="text-12 font-400 text-5E5E5E">
                Business Category
              </div>
              <div className="text-12 font-400 text-2C495D mb-2">
              {formData?.business_category}
              </div>
            </div>
            {/* <div className="my-3">
              <div className="text-xs font-normal text-5E5E5E">Tax Number</div>
              <div className="text-xs font-normal text-2C495D mb-2">
                {formData?.tax_no?.value}
              </div>
            </div> */}
          </div>
        </Fragment>
      )}
    </div>
  );
};
 
export default ClientInfoCard;
       