import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import userImage from "../assets/images/user.webp";
import checkWeb from "../assets/images/check.webp";
import logo from "../assets/images/logo.webp";
import FooterFixed from "./FooterFixed";
import { CHECK_EMAIL_DUPLICATE } from "../utils/constants";
import useCustomFormValidation from "../hooks/ErrorHandling/useCustomFormValidation";
const validationRules = {
  name: {
    required: true,
    errorMessage: "Name is required.",
  },
  email: [
    {
      required: true,
      errorMessage: 'Email is required.',
   },
   {
     email: true,
     errorMessage: 'Provide valid email',
   },
  ],
  password: {
    required: true,
    errorMessage: "Password is required.",
  },
};

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error,setErrors]=useState(false);
  const [matchPassword,setMatchPassword]=useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateField,
    isFormValid,
  } = useCustomFormValidation(formData, validationRules);

  const handleInputChange = (event) => {
    handleChange(event);
    const { name, value } = event.target;
    if(name==='email'){
      checkEmailExists(value);
    }else if(name==='confirmPassword'){
      confirmPasswordMatch(value)
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const confirmPasswordMatch = (value) => {
    if(formData.password !== value){
      setMatchPassword(formData.password !== value);
    }else{
      setMatchPassword('');
      setIsButtonDisabled(false);
    }    
  };



  const checkEmailExists = async (email) => {
    try {
     // console.log(`Checking email: ${email}`); // Log the email being checked
      const response = await fetch(`${CHECK_EMAIL_DUPLICATE}?email=${email}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("API Response:", data); // Log the response from the API

      if (data.exists) {
        setErrors(true);
      } else {
        setIsButtonDisabled(true);
        setErrors(false);
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };

  useEffect(()=>{
    if(formData && formData?.length>0){
      setIsButtonDisabled(true);
    }

  },[formData])

 

  const handleButtomClick = async (event) => {
    event.preventDefault();
    const formValid = isFormValid();
      if(!formValid && !formData){
        return;
      }
    navigate("/addorganization", { state: { signupData: formData } });
  };

  const handleClick = () => {
    navigate("/"); // Navigate to the "add_company" page
  };



  return (
    <div className="bg-white px-5">
      <a className="absolute top-10" onClick={handleClick}>
        <button className="flex gap-x-4 bg-F4F4F4 border border-EAE2E2 rounded-lg px-6 py-2">
          <h4 className="text-7B7B7B">
            <i class="bi bi-arrow-left"></i>
          </h4>Back</button>
      </a>
      <div class="flex items-center justify-center min-h-screen">
        <div className="px-2 py-8 border border-EDEDED rounded shadow-md mx-28 w-1/2 max-w-md">           
          <div className="text-1D5D9B text-24 font-700 pb-3 px-5 text-center">Sign Up</div>
          <div className="space-y-5 px-5">
          <form onSubmit={(e) => e.preventDefault()} id="loginForm">
              <div className="grid grid-row space-y-3 gap-x-5">
                <div className="mt-3">
                  <label
                    htmlFor="name"
                    className="text-545B61 text-14 font-500 mb-2"
                  >
                    Full Name {" "}
                    {(errors.name && formData.name==='') && <span className="text-red-500 font-normal text-12">{errors.name}</span>}
                  </label>
                  <input
                    onBlur={handleBlur}
                    className="border-1 border-E4EDF3 rounded-md focus:outline outline-1D5D9B focus:border-sky-700 w-full px-1"
                    type="text"
                    id="name"
                    placeholder=""
                    name="name"
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="text-545B61 text-14 font-500 mb-2"
                  >
                    Email
                    {" "}
                    {(errors.email && formData.email==='') ?(<span className="text-red-500 font-normal text-12">{errors.email}</span>):error===true?(
                     <span className="text-red-500 font-normal text-12">User Already Exist</span>
                    ):''}
                  </label>
                  <input
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    className="border-1 border-E4EDF3 rounded-md focus:outline outline-1D5D9B focus:border-sky-700 w-full px-1"
                    type="email"
                    id="email"
                    placeholder=""
                    name="email"
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="text-545B61 text-14 font-500 mb-2"
                  >
                    Create Password
                    {" "}
                    {(errors.password && formData.password==='') && <span className="text-red-500 font-normal text-12">{errors.password}</span>}
                  </label>
                  <input
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    className="border-1 border-E4EDF3 rounded-md focus:outline outline-1D5D9B focus:border-sky-700 w-full px-1"
                    id="password"
                    name="password"
                    type="password"
                    placeholder=""
                  />
                </div>

                <div>
                  <label
                    htmlFor="confirmPassword"
                    className="text-545B61 text-14 font-500 mb-2"
                  >
                    Confirm Password
                    {" "}
                    {(errors.confirmPassword && formData.confirmPassword==='') ?( <span className="text-red-500 font-normal text-12">{errors.confirmPassword}</span>):matchPassword?(
                       <span className="text-red-500 font-normal text-12">Password is not match.</span>
                    ):null}
                  </label>
                  <input
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    className="border-1 border-E4EDF3 rounded-md focus:outline outline-1D5D9B focus:border-sky-700 w-full px-1"
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    placeholder=""
                  />
                </div>
              </div>

              <button
                onClick={handleButtomClick}
                type="submit"
                className={`mt-5 bg-sky-700 w-full text-white font-bold py-1 rounded-md ${
                  isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isButtonDisabled}
              >
                Next
              </button>
            </form>  
            <div className="flex items-center"><hr className="flex-grow border-t-2 border-gray-200"/></div>            
            <div className="text-center text-14">
              Already have an account?{" "}
              <a className="text-red-500 hover:cursor-pointer font-700" onClick={handleClick}>Sign In</a>
            </div>
            <div className="flex justify-center text-center">
              <p className="w-550px text-14">
              By continuing, you are indicating that you have read and agree to the
              <a className="text-sky-700 font-bold"> Terms of Use</a> and <a className="text-sky-700 font-bold">Privacy
                  Policy.</a></p>              
            </div>
          </div>
          <div><FooterFixed /></div>
        </div>
      </div>  
    </div>
  );
}

export default SignUp;
