import React from 'react'

const AddIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M14.5095 6.75572H9.24758V1.49379C9.24758 0.912873 8.77612 0.441406 8.1952 0.441406H7.49365C6.91273 0.441406 6.44127 0.912873 6.44127 1.49379V6.75572H1.17934C0.59842 6.75572 0.126953 7.22719 0.126953 7.8081V8.50965C0.126953 9.09057 0.59842 9.56204 1.17934 9.56204H6.44127V14.824C6.44127 15.4049 6.91273 15.8764 7.49365 15.8764H8.1952C8.77612 15.8764 9.24758 15.4049 9.24758 14.824V9.56204H14.5095C15.0904 9.56204 15.5619 9.09057 15.5619 8.50965V7.8081C15.5619 7.22719 15.0904 6.75572 14.5095 6.75572Z" fill="#2C495D"/>
    </svg>
  )
}

export default AddIcon