import { useState } from 'react';

function useTabs(initialTab) {
  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return [activeTab, handleTabClick];
}

export default useTabs;
