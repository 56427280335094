import React, { useState, useEffect, useRef } from 'react';

const QuickFilter = ({ options, placeholder, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-[128px]" ref={dropdownRef}>
      <div
        className="bg-white border border-gray-300 rounded-md shadow-sm p-2 cursor-pointer  font-poppins text-12 text-[#383838]}"
        onClick={handleToggleDropdown}
      >
        {selectedOption ? selectedOption : placeholder}
        <i className={`bi ${isOpen ? 'bi-chevron-up' : 'bi-chevron-down'} float-right `}></i>
      </div>
      {isOpen && (
        <div className="absolute z-[999] bg-white border border-gray-300 rounded-md shadow-lg mt-1 w-full">
          {options.map((option, index) => (
            <div
              key={index}
              className="p-2 cursor-pointer hover:bg-gray-200 text-12 text-383838"
              onClick={() => handleOptionSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default QuickFilter;
