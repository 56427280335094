import React, { useState, useEffect } from "react";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import CustomerListTable from "../customer/CustomerListTable";

import { useCustomerTableMutation } from "../../utils/customer/customerTable";
import useRequireAuth from "../../utils/useRequireAuth";
import { useUpdateCustomerSocialDataMutation } from "../../utils/customer/customerTaskTable";
import AddVendorSocialLink from "./AddVendorSocialLink";
import SocailHeader from "./SocailHeader";
import { useFetchVendorSocailQuery } from "../../utils/customer/customerTaskTable";
import { useParams } from "react-router-dom";
import { deleteVendorSocail } from "../../utils/vendor/vendorSocialSlice";
import { useDispatch } from "react-redux";
const VendorSocialLink = () => {
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const [customer, setCustomer] = useState([]);
  const [customerTablePagination, setCustomerTablePagination] = useState(null);
  const [initialFilter, setInitialFilter] = useState([]);
  const { token } = useRequireAuth();
  const { id: vendorId } = useParams();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState();

  const [
    CustomerTableDrop,
    { data: customerCommnData, refetch: refetchCustomerCommnData },
  ] = useCustomerTableMutation();
  const fetchCustomerTable = (data) => {
    const completedata = {
      model_name: "vendor_social",
      field_name: "social_link_id",
    };
    CustomerTableDrop({ token: token, data: completedata });
  };

  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchVendorSocailQuery({
      vendorId: vendorId,
      pagination: customerTablePagination,
      token: token,
    });

  useEffect(() => {
    if (customerTableALL) {
      setCustomer(customerTableALL?.data?.data);
      const { data: omitData, ...restData } =
        customerTableALL && customerTableALL?.data;
      setInitialFilter(restData);
    }
  }, [customerTableALL, customerTablePagination]);

  const [updateCustomerSocialData, { CustomerDropLoad, CustomerDroperror }] =
    useUpdateCustomerSocialDataMutation();

  const customerTableFieldUpdate = async (field_name, value, id) => {
    const updateData = {
      field_name: value.field_name,
      value: field_name.value,
      id: value.id,
    };

    try {
      const result = await updateCustomerSocialData({
        token: token,
        data: updateData,
      }).unwrap();
      if (result) {
        refetchCustomerData();
        {
          result?.data?.original
            ? setAlert({
                visible: true,
                message: result?.data?.original?.error,
                type: "danger",
              })
            : setAlert({
                visible: true,
                message: result?.message,
                type: "success",
              });
        }
      }
    } catch (error) {
      setAlert({
        visible: true,
        message: "Error updating data!",
        type: "danger",
      });
    }
  };

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const handleDelete = (rowKey, item) => {
    const id = item?.customer_id?.value;
    dispatch(deleteVendorSocail({ id, token }));
    refetchCustomerData();
  };
  const closePopup = () => {
    setIsPopupOpen(false);
    setEditData();
  };
  const handleNavigate = (vendorId) => {
    setEditData(vendorId);
  };
  return (
    <div className={`tablemaindiv`}>
      <div className={` ${isFullscreen && "bg-white h-full px-5 pt-2"}`}>
        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />
        <CustomerListTable
          tableDetail={{
            tableidclass: "fullscreen-table",
            tableparentidclass: "tableparent",
            tablename: (
              <div className="py-2 text-203C50 text-20 font-600">
                Social Link
              </div>
            ),
            tablenameclass: "font-medium text-537596 capitalize",
            tableSearch: true,
            tableFilter: true,
            tableResizer: true,
            tableZoom: true,
            tableheader: false,
            statusUpdate: false,
            forcefullyfreeze: true,
            datetedata: true,
            addbutton: (
              <button
                onClick={openPopup}
                type="button"
                className="text-2541558A hover:text-254155"
              >
                <i className="bi bi-plus-lg"></i>
              </button>
            ),
          }}
          thead={SocailHeader}
          tbody={customer}
          tablePaginationData={setCustomerTablePagination}
          fetchtableBodyData={fetchCustomerTable}
          customerCommnData={customerCommnData}
          initialFilter={initialFilter}
          TableFieldUpdate={customerTableFieldUpdate}
          navigateNameCondition={["social_type"]}
          handleDelete={handleDelete}
          handleNavigate={handleNavigate}
        />
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-[102]">
            <AddVendorSocialLink
              onClose={closePopup}
              refetchSocial={refetchCustomerData}
            />
          </div>
        )}
        {editData && (
          <div className="fixed inset-0 flex items-center justify-center z-[102]">
            <AddVendorSocialLink
              onClose={closePopup}
              refetchSocial={refetchCustomerData}
              editData={editData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorSocialLink;
