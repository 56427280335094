import { useEffect, useState , useRef } from "react";
import useRequireAuth from "../useRequireAuth";
import { COMPANY_BILLING_DETAIL} from "../constants";


const useEdiCompanyBilling = (company_profile_id) => {
    const user = useRequireAuth();
    const [companyData, setCompanyData] = useState(null);
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const Authtoken = user.token;
            const url = `${COMPANY_BILLING_DETAIL}?profile_id=${encodeURIComponent(company_profile_id)}`;
    
            const response = await fetch(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${Authtoken}`,
                'Content-Type': 'application/json'
              }
            });
    
           
            if (!response.ok) {
              if (response.status === 429) {
                  // Too Many Requests, retry after some time
                  setTimeout(fetchData, 5000); // Retry after 5 seconds
                  return;
              }
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
            const data = await response.json();
            setCompanyData(data);
            setLoading(false);
            return data;
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        }; // Debounce with a delay of 2000ms
    
        fetchData(); // Trigger the debounced function
    
        // Cleanup function
       
      }, [user.token, company_profile_id]);
      
    if (loading) {
        return <div>Loading...</div>;
    }

    if (!companyData) {
        return <div>Error: Unable to fetch company data</div>;
    }

    return companyData;
};

export default useEdiCompanyBilling;
