import React from 'react';

const getPageNumber=(url)=>{
  const urlObj = new URL(url);
  const pageNumber = urlObj.searchParams.get('page');
  return pageNumber
}
const Pagination = ({ initialFilter, onPageChange }) => {
  const totalPages = initialFilter.last_page;
  const currentPage = initialFilter.current_page;

  // Helper function to create an array of page numbers with ellipses
  const getPageNumbers = () => {
    const pages = [];
    
    if (totalPages <= 6) {
      // If there are 6 or fewer pages, show all of them
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Always show first page
      pages.push(1);

      // Show ellipsis if there are pages between first page and current page
      if (currentPage > 4) {
        pages.push('...');
      }

      // Show pages around current page
      for (let i = Math.max(currentPage - 2, 2); i <= Math.min(currentPage + 2, totalPages - 1); i++) {
        pages.push(i);
      }

      // Show ellipsis if there are pages between current page and last page
      if (currentPage < totalPages - 3) {
        pages.push('...');
      }

      // Always show last page
      pages.push(totalPages);
    }

    return pages;
  };

  const handlePageClick = (page) => {
    if (page === '...') return; // Skip ellipsis clicks
    onPageChange(page);
  };

  return (
    <ul className="globalpagination1 ">
      {/* Jump to First Page */}
      <li className='ml-[10px]'>
        <button
          onClick={() => onPageChange(1)}
          className={`${currentPage === 1 ? 'paginationinactive' : ''} `}
          disabled={currentPage === 1}
        >
          <i className="bi bi-chevron-double-left text-[10px] mt-[2.5px] ml-[5px] font-semibold"></i>
        </button>
      </li>
      
      {/* Previous Page */}
      <li>
        <button
          onClick={() => onPageChange(getPageNumber(initialFilter.prev_page_url))}
          className={`${currentPage === 1 ? 'paginationinactive' : ''}`}
          disabled={currentPage === 1}
        >
          <i className="bi bi-chevron-left text-[10px] mt-[2.5px] ml-[5px] font-medium"></i>
        </button>
      </li>

      {/* Page Numbers */}
      {getPageNumbers().map((page, index) => ( 
        <li key={index} >
          <button
            className={page === currentPage ? 'paginationactive' : 'paginationinactive'}
            onClick={() => handlePageClick(page)}
            disabled={page === '...'}
          >
            {page }
          </button>
        </li>
      ))}
      
      {/* Next Page */}
      <li>
        <button
          onClick={() => onPageChange(getPageNumber(initialFilter.next_page_url))}
          className={`${currentPage === totalPages ? 'paginationinactive' : ''}`}
          disabled={currentPage === totalPages}
        >
          <i className="bi bi-chevron-right text-[10px] mt-[2.5px] ml-[5px] font-medium"></i>
        </button>
      </li>
      
      {/* Jump to Last Page */}
      <li className='mr-[10px]'>
        <button
          onClick={() => onPageChange(totalPages)}
          className={`${currentPage === totalPages ? 'paginationinactive' : ''}`}
          disabled={currentPage === totalPages}
        >
          <i className="bi bi-chevron-double-right text-[10px] mt-[2.5px] ml-[5px] font-medium"></i>
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
