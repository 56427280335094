import React, { Fragment, useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveEditFirst from '../SaveEdit/SaveEditFirst';

const CombinedDate = ({ FieldValue, FieldChange, setDataInputRow, UpdateTableField, Item ,ItemsValue}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

 

  useEffect(() => {
    if (FieldValue) {
      const [startStr, endStr] = FieldValue.split(' - ');
      const startDateObj = startStr ? parseDateString(startStr) : null;
      const endDateObj = endStr ? parseDateString(endStr) : null;
      setStartDate(startDateObj);
      setEndDate(endDateObj);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  }, [FieldValue]);

  const parseDateString = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return new Date(`${year}-${month}-${day}`);
  };

  const formatDateForDisplay = (date) => {
    if (!date) return '';
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const formatDateForSave = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const onChange = dates => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

      const formattedStartDate = start ? formatDateForDisplay(start) : '';
      const formattedEndDate = end ? formatDateForDisplay(end) : '';
      FieldChange({ target: { value: `${formattedStartDate} - ${formattedEndDate}` } });
    }else{
      setStartDate(dates);
      setEndDate(null);
      const formattedStartDate = dates ? formatDateForSave(dates) : '';
      FieldChange({ target: { value: `${formattedStartDate}`}});
    }
  }

  const placeholder = "Select Date Range";
  const displayFormat = "dd-MM-yyyy"; // Format for displaying dates

  const handleUpdateTableField = (e) => {
    const formattedStartDate = startDate ? formatDateForSave(startDate) : '';
    const formattedEndDate = endDate ? formatDateForSave(endDate) : '';
    UpdateTableField(e, { value: `${formattedStartDate} - ${formattedEndDate}` }, Item);
  };

  return (
    <Fragment>
      {ItemsValue.category_id.value ==='Task' ? (
        <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        placeholderText={placeholder}
        dateFormat={displayFormat} // Set the display format for the date picker
      />
      ):(
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        placeholderText={placeholder}
        dateFormat={displayFormat} // Set the display format for the date picker
      />
      )}
      

      <SaveEditFirst
        UpdateTableField={handleUpdateTableField}
        handleCloseClick={(e) => {
          e.stopPropagation();
          setDataInputRow(null);
        }}
      />
    </Fragment>
  );
}

export default CombinedDate;
