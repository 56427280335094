import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  hideMessage,
  saveSocialLinkDataAsync,
  deleteSocialLinkDataAsync,
} from "../../utils/customer/socialLinkSlice";
import useRequireAuth from "../../utils/useRequireAuth";
import { useParams } from "react-router-dom";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import CustomerSocialModel from "./CustomerSocialModel";
import CustomerListTable from "./CustomerListTable";
import contacsocailtHeader from "./customersocialheader";
import { useFetchCustomerSocialDataQuery } from "../../utils/customer/customerTaskTable";
import { useUpdateCustomerSocialDataMutation } from "../../utils/customer/customerTaskTable";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import { useCustomerTableMutation } from "../../utils/customer/customerTable";
const CustomerSocialLink = () => {
  const { isFullscreen, toggleFullscreen } = useFullscreen();

  const { token } = useRequireAuth();
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const { id: customerId } = useParams();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.customerSocialLink.message);
  const showMessage = useSelector(
    (state) => state.customerSocialLink.showMessage
  );
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [initialFilter, setInitialFilter] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [customerTablePagination, setCustomerTablePagination] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleDelete = async (rowKey, item, id) => {
    const itemId = item?.customer_id?.value;
    try {
      await dispatch(deleteSocialLinkDataAsync({ itemId, token }));
      refetchCustomerData();
      setAlert({
        visible: true,
        message: "Record Deleted Successfully",
        type: "success",
      });
    } catch (error) {
      console.error("Error updating status:", error);
      setAlert({
        visible: true,
        message: error,
        type: "danger",
      });
    }
  };

  const handleStatusUpdate = async (rowKey, item, itemId, status) => {
    const socialData = {
      id: itemId,
      status: status,
    };
    try {
      await dispatch(saveSocialLinkDataAsync({ socialData, token }));
      refetchCustomerData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSubmitData = (customerId) => {
    refetchCustomerData();
  };

  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchCustomerSocialDataQuery({
      customerId: customerId,
      pagination: customerTablePagination,
      token: token,
    });

  const [
    CustomerTableDrop,
    { data: customerCommnData, refetch: refetchCustomerCommnData },
  ] = useCustomerTableMutation();

  const fetchCustomerTable = (data) => {
    const completedata = {
      model_name: "customer_social",
      field_name: "social_link_id",
    };
    CustomerTableDrop({ token: token, data: completedata });
  };

  const [updateCustomerSocialData, { CustomerDropLoad, CustomerDroperror }] =
    useUpdateCustomerSocialDataMutation();
  const customerTableFieldUpdate = async (field_name, value, id) => {
    const updateData = {
      field_name: value.field_name,
      value: field_name.value,
      id: value.id,
    };

    try {
      const result = await updateCustomerSocialData({
        token: token,
        data: updateData,
      }).unwrap();
      if (result) {
        refetchCustomerData();
        {
          result?.data?.original
            ? setAlert({
                visible: true,
                message: result?.data?.original?.error,
                type: "danger",
              })
            : setAlert({
                visible: true,
                message: result?.message,
                type: "success",
              });
        }
      }
    } catch (error) {
      setAlert({
        visible: true,
        message: "Error updating data!",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    if (customerTableALL) {
      setCustomer(customerTableALL?.data?.data);
      const { data: omitData, ...restData } =
        customerTableALL && customerTableALL?.data;

      setInitialFilter(restData);
      setLoading(false);
    }
  }, [customerTableALL, customerTablePagination]);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  setTimeout(() => {
    dispatch(hideMessage());
  }, 10000);

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  useEffect(() => {
    if (showMessage) {
      setAlert({
        visible: true,
        message: message,
        type: "success",
      });
    }
  }, [showMessage, message]);

  return (
    <div className={`tablemaindiv`}>
      <div className={` ${isFullscreen && "bg-white h-full px-5 "}`}>
        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />
        <CustomerListTable
          tableDetail={{
            tableidclass: "fullscreen-table",
            tableparentidclass: "tableparent",
            tablename: (
              <div className=" text-203C50 text-20 font-600 mt-2">Link</div>
            ),
            tablenameclass: "font-medium text-537596 capitalize",
            tableSearch: true,
            tableFilter: true,
            tableResizer: true,
            tableZoom: true,
            tableheader: false,
            statusUpdate: false,
            datetedata: true,
            addbutton: (
              <button
                onClick={openPopup}
                type="button"
                className="text-2541558A hover:text-254155"
              >
                <i className="bi bi-plus-lg"></i>
              </button>
            ),
          }}
          thead={contacsocailtHeader}
          tbody={customer}
          tablePaginationData={setCustomerTablePagination}
          fetchtableBodyData={fetchCustomerTable}
          customerCommnData={customerCommnData}
          initialFilter={initialFilter}
          TableFieldUpdate={customerTableFieldUpdate}
          navigateNameCondition={["subject"]}
          handleDelete={handleDelete}
          handleStatusUpdate={handleStatusUpdate}
        />
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-[102]">
            <CustomerSocialModel
              isOpen={openPopup}
              onClose={closePopup}
              handleSubmitData={handleSubmitData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerSocialLink;
