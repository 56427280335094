import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import MonthCalendar from "../Calendar/MonthCalendar";
import WeekCalendar from "../Calendar/WeekCalendar";
import DownCircleArrow from "../Icons/DownCircleArrow";
import FullScreenMode from "../GlobalFeature/Table/HeadTop/FullScreenMode";

const AddUpcomingEvent = () => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [activeCalendarTab, setActiveCalendarTab] = useState(1);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const changeTab = (tabIndex) => {
    setActiveCalendarTab(tabIndex);
  };

  const goToPreMonth = () => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCalendarDate(newDate);
  };

  const goToNextMonth = () => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setCalendarDate(newDate);
  };
  const goToPreDate = () => {
    const newDate = new Date(calendarDate);
    newDate.setDate(newDate.getDate() - 1);
    setCalendarDate(newDate);
  };

  const goToNextDate = () => {
    const newDate = new Date(calendarDate);
    newDate.setDate(newDate.getDate() + 1);
    setCalendarDate(newDate);
  };

  const calendarClick = (e) => {
    e.preventDefault();
    setCalendarOpen(!calendarOpen);
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );

    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const calendarDateChange = (e) => {
    // setCalendarOpen(!calendarOpen);
    setCalendarDate(e);
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  return (
    <div className="bg-white border border-1d446933 px-4 mb-4">
      <div className="flex justify-between py-4">
        <Fragment>
          <h3 className={`text-2C495D text-xl font-semibold`}>
            Upcoming Event
          </h3>
          {/* <DownCircleArrow /> */}
        </Fragment>
      </div>

      <div className={`py-2`}>
        <div className="p-2 px-4">
          <div className="flex">
            <div className="flex-1 flex justify-center">
              <div className="border border-E8E9EA rounded-3xl w-[290px] flex justify-between items-center ">
                <button
                  className="border-r border-E8E9EA p-2 px-4"
                  onClick={goToPreDate}
                >
                  <i className="bi bi-chevron-double-left text-8997A0"></i>
                </button>
                <span className="text-base font-medium  text-cyan-600">
                  {calendarDate.toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
                <button
                  className="border-l border-E8E9EA rounded-tr-3xl rounded-br-3xl p-2 px-4"
                  onClick={goToNextDate}
                >
                  <i className="bi bi-chevron-double-right text-8997A0"></i>
                </button>
              </div>

              <div className="flex items-center h-full ms-3 relative align-center">
                <button
                  type="button"
                  className="text-2999BC hover:text-F16136 focus:outline-none ms-3"
                  onClick={calendarClick}
                >
                  <span className="relative">
                    <i className="bi bi-calendar-event-fill text-2xl"></i>
                    {/* <div className="absolute right-0 top-0 left-0 text-[10px] ">{formatDate(calendarDate).slice(3,6)}</div> */}
                  </span>
                </button>

                {calendarOpen && (
                  <Fragment>
                    <DatePicker
                      selected={calendarDate}
                      onChange={calendarDateChange}
                      holidays={[
                        { date: "2024-01-26", holidayName: "Republic Day" },
                        { date: "2024-08-15", holidayName: "Independence Day" },
                        { date: "2024-10-02", holidayName: "Mahatma Gandhi" },
                        { date: "2024-10-31", holidayName: "Diwali" },
                        { date: "2024-12-25", holidayName: "Christmas Day" },
                        { date: "2024-12-31", holidayName: "New Year Eve" },
                      ]}
                      inline
                      filterDate={isWeekday}
                      dayClassName={(date) => {
                        const isWeekend =
                          date.getDay() === 0 || date.getDay() === 6; // Saturday or Sunday
                        return isWeekend ? "satsunweek" : "montofriweek";
                      }}
                      closeOnScroll={true}
                      onClickOutside={() => {
                        setCalendarOpen(false);
                      }}
                      calendarClassName="customercalendar left-12"
                      calendarStartDay={1}
                    >
                      <div className="inline-flex gap-2 ">
                      <div>
                        <button
                          className="bg-1D4469 text-white font-500 text-18 py-2 px-4 "
                          onClick={() => {
                            setCalendarDate(new Date());
                          }}
                        >
                          Today
                        </button>
                        </div>
                        <div>
                        <button
                          className="bg-F4F4F4 text-535252 border border-EAE2E2 font-500 text-18 py-2 px-4 "
                          onClick={() => setCalendarOpen(false)}
                        >
                          Close
                        </button>
                        </div>
                      </div>
                    </DatePicker>
                  </Fragment>
                )}
              </div>
            </div>

            <div>
              <button
                className={`${
                  activeCalendarTab === 1
                    ? "shadow-5 text-E8E9EA bg-2C495D border-0"
                    : "border-F8F8F8 text-848687"
                } border  px-4 py-1 mx-1 text-sm font-normal focus:outline-none`}
                onClick={() => changeTab(1)}
              >
                Weekly
              </button>
              <button
                className={`${
                  activeCalendarTab === 2
                    ? "shadow-5 text-E8E9EA bg-2C495D border-0"
                    : "border-F8F8F8 text-848687"
                } border  px-4 py-1 mx-1 text-sm font-normal focus:outline-none`}
                onClick={() => changeTab(2)}
              >
                Monthly
              </button>
            </div>
          </div>
        </div>

        {/* const CustomerListTable = ({ tableidclass,thead,handlePostThead, tbody,fetchBodyData,fetchtableBodyData,customerCommnData,initialFilter,handleNavigate,navigateNameCondition }) => { */}

        {/* <div id="fullscreen-container" className={`w-100 ${isFullscreen ? 'h-[80vh]' : 'h-100'} overflow-auto customscrollbar`}>
        <table id={`${tableidclass}`} className={`${tableidclass} bg-white min-w-full border border-neutral-200 text-center text-sm text-surface rounded-lg text-2C495D font-poppins ${isFullscreen ? 'h-full' : ''} ${densityMode}`}>
          <thead>
            <tr>
            {groupedHeaders.length > 0 && (
                <th className="font-normal px-6 py-3 text-left border whitespace-nowrap border-x-0">Groups</th>
              )} 
              {renderTableHeaders() && renderTableHeaders().map((header, index) => (
                <th key={index} scope="col" className="font-normal px-6 py-3 text-left border whitespace-nowrap border-x-0">
                  <div className={`flex justify-between items-center cursor-pointer`} onClick={() => sortData(header.key)}>

                    <div>
                      <button className="btn me-2 cursor-grab" draggable onDragStart={() => handleDragStart(header.key, header.label)}> 
                        <i className="bi bi-grip-vertical opacity-55 text-sm"></i> 
                      </button>
                      
                      {header.label}
                    </div>

                    <SortingFilter sortKey={sortKey} sortOrder={sortOrder} header={header}/>
                    
                      
                  </div>
                  <div>
                    {tableThSearch && (
                      <div className="relative">
                        <input className="px-3 mt-2 w-full h-[30px] rounded-lg bg-335678 text-sm placeholder:text-[10px]" type="text" placeholder={`Filter By ${header.label.split(' ')[0]}`} value={searchTerms[header.key] || ''}
                          onChange={(e) => handleInputChange(header.key, e.target.value)} />
                        {searchTerms[header.key] && (
                          <button className="absolute inset-y-0 right-2" onClick={() => handleInputChange(header.key, '')}>
                            <i className="bi bi-x"></i>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </th>
              ))} 
            </tr>
          </thead>
          <tbody className="border">
            {!groupedData ? null : renderTableRows(groupedData)}
          </tbody>
        </table>
      </div>
                 */}

        {activeCalendarTab === 2 ? (
          <MonthCalendar value={calendarDate} />
        ) : (
          <WeekCalendar value={calendarDate} />
        )}
      </div>
    </div>
  );
};

export default AddUpcomingEvent;