import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import LayoutHOC from "../LayoutHOC";
import useClickOutside from "../../hooks/Screen/useClickOutside";
import CustomerListTable from "../customer/CustomerListTable";
import QuickFilter from "../GlobalFeature/Table/HeadTop/QuickFilter";
// import AddUserModal from './AddUserModal';
import HeaderDashboard from "../HeaderDashboard";
import PermissionHeader from "./PermissionHeader";
import { useFetchUserDataTableDataQuery } from "../../utils/customer/customerTaskTable";
import useRequireAuth from "../../utils/useRequireAuth";
import SideBar from "../Dashboard/SideBar";
import AddPermission from "./AddPermission";
import { useFetchPermissionTableDataQuery } from "../../utils/users/permissions";
import PermissionTable from "./PermissionTable";
import GroupsTable from "../Groups/GroupsTable";
import PermissionLevel from "./PermissionLevel";

const ManagePermission = () => {
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [permission, setPermission] = useState(null);

  const handleNavigate = (data, items, header, taskid) => {   
    if (items[header?.key]?.field_name === "group_name") {
      setGroup(items);
      setPermission(null);
    
    } else if (items[header?.key]?.field_name === "permission") {
      setPermission(items);
      setGroup(null);
      
    }
  };

  const TableNav = () => {
    return (
      <div>
        {/* <span className='ms-1 text-8A8F92 me-4'>Create Permission</span>
                <span className='text-8A8F92 me-4'>Create Group</span>
                <span className='text-8A8F92 me-4'>Grand Permission</span>
                <span className='text-8A8F92 me-4'>Add Permission Level</span> */}
      </div>
    );
  };

  console.log("00000000000",group);

  return (
    <div className="">
        <HeaderDashboard />
            <div class="flex gap-10 px-5 mt-14">
                <div className="w-1/5">
                    <SideBar />
                </div>
                <div className="w-9/12 mt-2">
                    {group?.group_name ? (
                        <Fragment>
                          <div className="flex absolute">
                            <button
                                className="text-1D4469 text-18 font-600"
                                onClick={() => {
                                setGroup(null);
                                }}
                            >
                                <i className="bi bi-arrow-left"></i><span className="ms-2">{group?.group_name?.value}</span>
                            </button>
                          </div>  
                          <GroupsTable
                              handleNavigates={handleNavigate}
                              TableNav={TableNav}
                              groupid={group}
                          />
                        </Fragment>
                    ) : permission?.permission ? (
                        <Fragment>
                        <PermissionLevel
                            handleNavigate={handleNavigate}
                            TableNav={TableNav}
                            ButtonEvent={() => {
                            setPermission(null);
                            }}
                            PermissionLevelId={permission}
                        />
                        </Fragment>
                    ) : (
                        <PermissionTable
                        handleNavigate={handleNavigate}
                        TableNav={TableNav}
                        />
                    )}
                </div>
            </div>
    </div>
  );
};

export default ManagePermission;
