import React, { useState, useEffect, useRef, Fragment } from "react";
import LayoutHOC from "../LayoutHOC";
import { Link, useNavigate } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import { useFetchCustomerHeaderQuery } from "../../utils/customer/customerListHeader";
import { usePostCustomerHeaderMutation } from "../../utils/customer/customerListHeader";
import {
  useFetchCustomerTableDataQuery,
  useUpdateCustomerTableDataMutation,
  useCustomerTableMutation,
  useLazyFetchCustFieldHdrQuery,
  useCustTableAddFieldHeaderMutation,
  useLazyFetchPersonInTableQuery,
  useCustTableUpdateFieldHeaderMutation,
} from "../../utils/customer/customerTable";
import handlePostApi from "../../utils/api/postApiSlice";
import CustomerListTable from "./CustomerListTable";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import TopTitle from "../GlobalFeature/TopTitle";
import SkeletonTable from "../GlobalFeature/Others/SkeletonTable";
import CustomerName from "../GlobalFeature/Table/ExtraComponent/CustomerName";
import EmailHoverElement from "../GlobalFeature/Others/EmailHoverElement";
import { parseJSONValue } from "../../utils/json/parseJSONValue";
import TextHoverElement from "../GlobalFeature/Others/TextHoverElement";
import { useLocation } from "react-router-dom";
import { getUserTokeData } from "../../utils/userSlice";
import { useDispatch } from "react-redux";
import MuiLoader from "../GlobalFeature/Loader/MuiLoader";

const stickyHeaders={customer_name:{name:"customer_name",width:"30px"},customer_display_name:{name:"customer_display_name",width:"200px"}}
const Customer = ({ setSideBarColl }) => {
  
  const navigate = useNavigate();
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const { token } = useRequireAuth();
  const [customer, setCustomer] = useState([]);
  const [initialFilter, setInitialFilter] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");
  const [customerTablePagination, setCustomerTablePagination] = useState(`?page=1`);
  const storedData = localStorage.getItem("company_data");
  const companyId = storedData ? JSON.parse(storedData) : null;
  const [multiData,setMultiData]=useState({})
  const [
    CustomerTableDrop,
    { data: customerCommnData, refetch: refetchCustomerCommnData },
  ] = useCustomerTableMutation();

  const fetchCustomerTable = (data) => {
    const completedata = {
      model_name: "customers",
      field_name: data,
    };
    CustomerTableDrop({ token: token, data: completedata });
  };

  const [fetchPersonInTable, { data: personTableData }] =
    useLazyFetchPersonInTableQuery();

  const fetchPersonData = (data) => {
    fetchPersonInTable({ token: token, data: data });
  };

  const {
    data: customerListHeader,
    refetch,
    isLoading,
  } = useFetchCustomerHeaderQuery({
    token: token,
    model: "customer",
  });

  const [postCustomerHeader, { isLoad, isError }] =
    usePostCustomerHeaderMutation();

  const handleNavigate = (customer_id = null) => {
    const path = `/add_customer1/${customer_id}`;
    navigate(path);
  };

  // const handleNavigate = (customer_id = null) => {
  //   const path = `/add_customer1/${customer_id}?RightTab=1`;
  //   navigate(path);
  // };



  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchCustomerTableDataQuery({
      companyId: parsedCompanyData.company_id,
      owner:  parsedCompanyData.owner,
      pagination: `${customerTablePagination}`,
      token: token,
  });
  useEffect(() => {
    if (customerTableALL) {
      setCustomer(customerTableALL?.data?.data);
      const { data: omitData, ...restData } = customerTableALL?.data;
      setInitialFilter(restData);
    }
  }, [customerTableALL, customerTablePagination]);

  useEffect(() => {
    if (location.key) {
      dispatch(getUserTokeData());
    }
  }, [location.key]);

  const [updateCustomerTableData, { CustomerDropLoad, CustomerDroperror }] =
    useUpdateCustomerTableDataMutation();
    const customerTableFieldUpdate = async (item, field_name,multiData) => {
      let value, data;
      if (Array.isArray(item) && typeof item !== 'string') {
        value = item.map((it) => it.value).join("; ");
        // data = {
        //   value: value,
        // };
   
        if (value.trim() !== '') {
          data = {
            value: value,
          };
        } else {
          data = '';
        }
      } else if (Object?.keys(item?.value).length >= 2) {
        value = item?.value || "";
        if (value) {
          data = {
            ...item,
          };
        } else {
          data = {
            value: item?.email || "",
            name: item?.name || "",
          };
        }
      }
      else{
   
        data = item?.value;
      }
   
      const updateData = {
        field_name: field_name?.field_name,
        value: data,
        id: field_name?.id,
        multidata: Object.keys(multiData)
      };
   
      try {
        setLoading(true);
        const result = await updateCustomerTableData({
          token: token,
          data: updateData,
        }).unwrap();
        if (result?.status) {
          refetchCustomerData();
          {
            result?.data?.original
              ? setAlert({
                  visible: true,
                  message: result?.data?.original?.error,
                  type: "danger",
                })
              : setAlert({
                  visible: true,
                  message: result?.message,
                  type: "success",
                });
          }
          setLoading(false);
        }
      } catch (error) {
        console.error("Error updating data:", error);
        setAlert({
          visible: true,
          message: "Error updating data!",
          type: "danger",
        });
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
  const handlePostData = async (label) => {
    try {
      let data = {
        company_id: companyId.company_id,
        model: "customer",
        data: label,
      };
      const response = await postCustomerHeader({
        token: token,
        data: data,
      });
      if (response.data.status) {
        setAlert({
          visible: true,
          message: response?.data?.message,
          type: "success",
        });
        refetch();
        refetchCustomerData();
      }
    } catch (error) {
      console.error("Post error:", error);
    }
  };

  const [fetchCustomerGetAddField, { data: CustomerGetAddField }] =
    useLazyFetchCustFieldHdrQuery();
  const EditHeaderField = (headerField) => {
    fetchCustomerGetAddField({ token: token, data: headerField });
  };

  const [
    addFieldHeader,
    { headerExtraFieldAddLoad, headerExtraFieldAddError },
  ] = useCustTableAddFieldHeaderMutation();
  const [
    updateFieldHeader
  ] = useCustTableUpdateFieldHeaderMutation();
  const handleExtraFieldAddHeader = async (fielddata) => {
    
    // const Data = {
    //   company_id: companyId?.company_id,
    //   ...fielddata,
    // };
    

    const modifiedFieldOptions = fielddata?.field_options?.map(option => {
      if (option?.color_code === null || option?.color_code === "") {
        const { color_code, ...rest } = option;
        return rest;
      }
      return option;
    }) || [];
  
    const Data = {
      company_id: companyId.company_id,
      ...fielddata,
      field_options: modifiedFieldOptions,
    };


 
    if(Data?.id && String(Data.id).trim() !== ""){
      try {
        const response = await updateFieldHeader({
          token: token,
          data: Data,
        }).unwrap();

        
        refetch()
        refetchCustomerData()
        setAlert({ visible: true, message: response?.message, type: 'success' });
      } catch (error) {
        console.error("Update Failed", error);
      }
    }
    else{
      await handlePostApi({
        AddField: addFieldHeader,
        Data: {
          token: token,
          data: Data,
        },
        LiveLoad: [refetch,refetchCustomerData],
        AlertMessage: setAlert,
        Loader: setLoading,
        Error: setError,
      });
    }
 
 
 
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };






  return (
    <Fragment>
      <div className="p-4 pt-0 mt-4" id="innercontent">
        <div id="default-styled-tab-content" className="tablemaindiv">
          <div
            className={` ${activeTab === "profile" ? "" : "hidden"} `}
            id="styled-profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div>
              <FirstMessage
                type={alert.type}
                message={alert.message}
                visible={alert.visible}
                onHide={hideAlert}
              />
              <TopTitle
                name="Customer List"
                type=""
                pt="10px"
              />  
              {/* {customer.length > 0 ? ( */}
              {true ? (
                <Fragment >
                    {loading && 
                    <MuiLoader isOpen={loading}/>
                    }
                    <CustomerListTable
                      key={location.key}
                      tableDetail={{
                        tableidclass: "fullscreen-table z-customer-45",
                        tableparentidclass: "tableparent",
                        tablename: "",
                        tableHeading:"Customer Table",
                        tablenameclass:"font-medium text-xl text-203C50 capitalize",
                        tableSearch: true,
                        tableFilter: true,
                        tableResizer: true,
                        tableZoom: true,
                        tableheader: true,
                        addbutton: (
                          <Link
                            to="/add_customer"
                            className={`tooltip hover:text-254155 
                            }`}
                          >
                            <i className="bi bi-plus-lg text-2541558A  hover:text-254155 ">
                            <span className={`tooltiptext text-[10px] w-[95px] bottom-full left-1/2 ml-[-30px] not-italic`}>Add Customer</span>  
                            </i>
                           
                          </Link>
                        ),
                      }}
                      multiSelect
                      multiData={multiData}
                      setMultiData={setMultiData}
                      thead={customerListHeader}
                      handlePostThead={handlePostData}
                      tbody={customer}
                      tablePaginationData={setCustomerTablePagination}
                      fetchtableBodyData={fetchCustomerTable}
                      customerCommnData={customerCommnData || personTableData}
                      fetchPersonInTable={fetchPersonData}
                      TableFieldUpdate={customerTableFieldUpdate}
                      ExtraFieldAddHeader={handleExtraFieldAddHeader}
                      GetAdditionalField={EditHeaderField}
                      GetAddFieldData={CustomerGetAddField}
                      initialFilter={initialFilter}
                      stickyHeaders={stickyHeaders}
                      handleNavigate={handleNavigate}
                      navigateNameCondition={[
                        "customer_display_name",
                        "customer_name",
                      ]}
                      setSideBarColl={setSideBarColl}
                      refetchCustomerData={refetchCustomerData}
                      ExtraFieldComponent={{
                        customer_name: (item, header) => (
                          <CustomerName Item={item} multiData={Object.keys(multiData)} refetchCustomerData={refetchCustomerData}/>
                        ),
                        person: (item, header, tableDetail) => {
                          const parsedData = parseJSONValue(item[header.key]);
                          return (
                            <EmailHoverElement
                              ParentId={tableDetail}
                              Data={parsedData}
                              HoverActiveClass={"tablehoverelement"}
                            />
                          );
                        },
                        // text: (item, header, tableDetail) => {
                        //   const parsedData = parseJSONValue(item[header.key]);
                          
                        //   return (
                        //     <Fragment>
                        //       {header?.key !== "customer_name" && (
                        //         <TextHoverElement
                        //           ParentId={tableDetail}
                        //           Data={parsedData}
                        //           HoverActiveClass={"tablehoverelement"}
                        //           ClassName={`max-w-[500px]`}
                        //         />
                        //       )}
                        //     </Fragment>
                        //   );
                        // },
                      }}
                    />
                </Fragment>
              ) : (
                <div className="flex items-center justify-end">
                  <Link
                    to="/add_customer"
                    className="bg-1D4469 text-white rounded p-2 px-5 text-[14px]"
                    type="button"
                  >
                    + Add Customer
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div
            className={` p-4 rounded-lg  ${
              activeTab === "dashboard" ? "" : "hidden"
            }`}
            id="styled-dashboard"
            role="tabpanel"
            aria-labelledby="dashboard-tab"
          >
            {/* Content for dashboard tab */}
          </div>
          <div
            className="hidden p-4 rounded-lg"
            id="styled-settings"
            role="tabpanel"
            aria-labelledby="settings-tab"
          >
            {/* Content for settings tab */}
          </div>
          <div
            className="hidden p-4 rounded-lg"
            id="styled-contacts"
            role="tabpanel"
            aria-labelledby="contacts-tab"
          >
            {/* Content for contacts tab */}
          </div>
        </div>
      </div>    
    </Fragment>
  );
};

export default LayoutHOC(Customer);
