import React, { Fragment, useState, useEffect, useRef } from "react";
import useSorting from "../../hooks/Table/useSortableColumn";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import useTableDensity from "../../hooks/Table/useToggleDensity";
import useDragAndDrop from "../../hooks/Table/useDragAndDrop";
import useGrouping from "../../hooks/Table/useGroupingTable";
import useDataInputHandler from "../../hooks/Table/useTableTbodyFire";
import { Link } from "react-router-dom";
import Pagination from "../../hooks/Table/usePagination";
import SearchBar from "../GlobalFeature/Table/HeadTop/SearchBar";
import FilterSearchTh from "../GlobalFeature/Table/HeadTop/FilterSearchTh";
import ToggleDensityMode from "../GlobalFeature/Table/HeadTop/ToggleDensityMode";
import FullScreenMode from "../GlobalFeature/Table/HeadTop/FullScreenMode";
import SettingHeader from "../GlobalFeature/Table/HeadTop/SettingHeader";
import SortingFilter from "../GlobalFeature/Table/TableThead/SortingFilter";
import ClearAllFilter from "../GlobalFeature/Table/HeadTop/ClearAllFilter";
import RenderElements from "../GlobalFeature/Others/RenderElements";
import useResizableColumn from "../../hooks/Table/useResizableColumn";
import TaskActivityList from "./TaskActivityList";
import CheckBox1 from "../GlobalFeature/CheckBox/CheckBox1";
import { parseJSONValue } from "../../utils/json/parseJSONValue";
import useRequireAuth from "../../utils/useRequireAuth";
import ActivityCounts from "./ActivityCounts";
import { Popover } from "@mui/material";
import UserPopUpDetails from "../../utils/customer/UserPopupDetails";
import CustomerShowDetails from "./CustomerShowDetails";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Select, MenuItem, FormControl } from '@mui/material';
import TaskRecurrenceCalendar from "../TaskRecurrence/TaskRecurrenceCalendar";



const ToolTipEnableHeader=["User Name","Reporting"]
const CustomerListTable = ({  
  tableDetail,
  thead,
  handlePostThead,
  tbody,
  tablePaginationData,

  fetchtableBodyData,
  customerCommnData,
  fetchPersonInTable,

  TableFieldUpdate,
  TableFieldInsideOptionAdd,
  ExtraFieldAddHeader,
  GetAdditionalField,
  GetAddFieldData,
  initialFilter,
  handleNavigate,
  navigateNameCondition,
  ExtraFieldComponent,
  handleDownload,
  handleDelete,
  handleEdite,
  handleStatusUpdate,
  downloadvalue,
  TextFieldElement,
  activityClosed,
  TableActiveFilter,
  TableTypeFilter,
  UserTypeFlag,
  UserActiveFlag,
  handleTypeChange,
  handleActiveChange,
  multiData,
  setMultiData,
  multiSelect,
  HoverOnCustomerName,
  openShowDetails,
  handleShowDetails,
  stickyHeaders,
  refetchCustomerData

}) => {
  const { token } = useRequireAuth();
  const [tableThSearch, setTableThSearch] = useState(false);
  const [tableSearchInput, setTableSearchInput] = useState("");
  const [searchTerms, setSearchTerms] = useState({});
  const [dataInputRow, setDataInputRow] = useState(null);
  const [tableSearch, setTableSearch] = useState(false);
  const [tablecellInput, setTablecellInput] = useState("");
  const [storedThead, setStoredThead] = useState(thead);

  const [hoveredCell, setHoveredCell] = useState(null);
  const [hoveredHeader, setHoveredHeader] = useState(null);
  const [showIconAct, setShowIconAct] = useState(false);
  const popupRefA = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupItemId, setPopupItemId] = useState(null);
  const [clientCoord,setClientCoord]=useState({})
  
 
  const [lastCheckedIndex, setLastCheckedIndex] = useState(null);
  const timerRef = useRef(null);
 

  

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(UserTypeFlag);
  const [openDropdown, setOpenDropdown] = useState(null); 



  const handleOpen = (value) => setOpenDropdown(value);
  const handleClose = () => setOpenDropdown(null);


  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    handleTypeChange(value);
    handleClose();
  };

  
  const handleMouseEnterDebounced = (itemId) => {
    setPopupItemId(itemId);
    setIsPopupOpen(true);
  };

  const handleClosed = () => {
    setPopupItemId(null);
    setIsPopupOpen(false);
    activityClosed();
  };

  const {
    draggedHeader,
    groupedHeaders,
    handleDragStart,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    isDragAccurateLoc,
    isDraggingOver,
    handleRemoveHeader,
  } = useDragAndDrop();

  const [collapsedRows, setCollapsedRows] = useState({});

  const {
    sortedData,
    sortData,
    sortKey,
    sortOrder,
    handleSearch,
    handleColumnSearch,
    clearAllFilters,
    headers,
  } = useSorting(tbody, storedThead,"initial");


  const { densityMode, densityIcons, toggleDensityMode } = useTableDensity();
  const { isFullscreen, toggleFullscreen, isTableMainDivPresent } =
    useFullscreen();

  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });

  const [columnDropdown, setColumnDropdown] = useState(false);

  const { columnWidths, handleMouseDown, tableRef, handleDoubleClick } =
    useResizableColumn();

  const { handleDataInput } = useDataInputHandler(
    fetchtableBodyData,
    setDataInputRow,
    setClientCoord
  );

  const groupedData = useGrouping(sortedData, groupedHeaders);
  useEffect(() => {
    setStoredThead(thead);
  }, [thead]);

  const toggleColumnDropdown = () => {
    setColumnDropdown(!columnDropdown);
  };

  const renderTableHeaders = () => {
    if (!Array.isArray(storedThead)) return null;

    return storedThead
      .filter((headerItem) => {
        const headerValue = headerItem[Object.keys(headerItem)[0]];
        return (
          Number(headerItem[Object.keys(headerItem)[1]]) === 1 &&
          !groupedHeaders?.some(
            (groupedHeader) => groupedHeader?.value === headerValue
          )
        );
      })
      .map((headerItem, index) => ({
        key: Object.keys(headerItem)[0],
        label: headerItem[Object.keys(headerItem)[0]],
        statuskey: Object.keys(headerItem)[1],
        statuslabel: headerItem[Object.keys(headerItem)[1]],
      }));
  };

  const handleGeneralSearch = (term) => {
    setTableSearchInput(term);
    handleSearch(term);
  };
 
  const handleInputChange = (headerKey, term) => {
    const newColumnSearchInputs = { ...searchTerms };
    newColumnSearchInputs[headerKey] = term;
    setSearchTerms(newColumnSearchInputs);
    handleColumnSearch(term, headerKey);
  };

  
  const [perPage, setPerPage] = useState(initialFilter?.per_page || 20);
  const [currentPage, setCurrentPage] = useState(initialFilter?.current_page || 1);

  // Effect to handle URL changes and fetch data
  // useEffect(() => {
  //   const url = `?per_page=${perPage}&page=${currentPage}`;
  //   handleChangePage(url);
  // }, [perPage, currentPage]);

  useEffect(() => {
    const url = `?per_page=${perPage}&page=${currentPage}`;
    handleChangePage(url);
  }, [perPage, currentPage]);
  
  const child1Ref = useRef(null);
  const scrollbarWrapperRef = useRef(null);
  const tabletRef = useRef(null);
  useEffect(() => {
    // Calculate the total width of the table
    if (tabletRef.current) {
      const tableWidth = tabletRef.current.scrollWidth;
      const containerWidth = child1Ref.current.clientWidth;
      // console.log("line 239>>>>>>>>>>>>tableWidth<<<<<<<<<<<<",tableWidth);
      // console.log("line 240>>>>>>>>>>>>containerWidth<<<<<<<<<<<",containerWidth);
      

    if (tableWidth > containerWidth+20) {
      setScrollbarWidth(`${tableWidth}px`);
    } else {
      setScrollbarWidth("0px");
    }
      
      // setScrollbarWidth(`${tableWidth}px`);
    }
  }, [groupedData, stickyHeaders, renderTableHeaders]);
  

  const [scrollbarWidth, setScrollbarWidth] = useState("100%");

  useEffect(() => {
      const child1 = child1Ref.current;
      const scrollbarWrapper = scrollbarWrapperRef.current;
      if (child1 && scrollbarWrapper) {
          const handleScroll = () => {
              if (child1.scrollLeft !== scrollbarWrapper.scrollLeft) {
                  child1.scrollLeft = scrollbarWrapper.scrollLeft;
              }
          };

          const handleChild1Scroll = () => {
              if (scrollbarWrapper.scrollLeft !== child1.scrollLeft) {
                  scrollbarWrapper.scrollLeft = child1.scrollLeft;
              }
          };

          scrollbarWrapper.addEventListener('scroll', handleScroll);
          child1.addEventListener('scroll', handleChild1Scroll);
          // Cleanup event listeners on component unmount
          // return () => {
          //     scrollbarWrapper.removeEventListener('scroll', handleScroll);
          //     child1.removeEventListener('scroll', handleChild1Scroll);
          // };
      }
  }, []); 

 


  

  useEffect(()=>{
    const url = `?per_page=${perPage}&page=${currentPage}&search=${tableSearchInput} &sort_direction=${sortOrder}&sort_by=${sortKey}`;
    handleChangePage(url);
  },[sortKey,sortOrder,tableSearchInput,perPage, currentPage])

  const handleChangePage = (url) => {
    // Fetch data based on the URL
    tablePaginationData(url);
  };

  const handlePaginate = (page) => {
    setCurrentPage(page);
  };

  const DataElementShow = (event) => {
    setPerPage(event.target.value || 20);
    setCurrentPage(1); // Reset to page 1 when per_page changes
  };

  const tableCellChange = (e) => {
    setTablecellInput(e.target.value);
  };

  const TableSearchData = (e) => {
    setTableSearch(!tableSearch);
  };

  const handleOtherDetailChange = (header, item, columnKey,event) => {
    if (!dataInputRow?.rowIndex) {
      handleDataInput(header, item, columnKey,event);
    }
  };

  const highlightText = (text, term,item) => {
    const parsedValue = parseJSONValue(text)?.value
      ? parseJSONValue(text)
      : text;

    const dataArray =
      typeof parsedValue?.value === "string"
        ? parsedValue?.value?.split(";").map((item) => item?.trim())
        : [];

    const firstTwoItems = dataArray.slice(0, 2);
    const remainingItems = dataArray.slice(2);
    const remainingCount = dataArray.length - firstTwoItems.length;

    const DisplayedOptions = () => (
      <>
        {firstTwoItems.join("; ")}
        {remainingCount > 0 && (
          <Fragment>
            <span>;</span>
            <span className="border-2 rounded px-1 multiple-tooltip">
              +{remainingCount}
              <span className="multiple-tooltip-content !z-[9999999999999]">
                {remainingItems.join("; ")}
              </span>
            </span>
          </Fragment>
        )}
      </>
    );

    if (!term)
      return remainingCount > 0 ? (
        <DisplayedOptions />
      ) : (
        parsedValue?.value ?? ""
      );

    const regex = new RegExp(`(${term})`, "gi");

    const highlight = (str) => {
      if (typeof str !== "string") return str;

      return str.split(regex).map((part, index) =>
        regex.test(part?.toLowerCase()) ? (
          <span
            key={index}
            className="whitespace-pre-wrap"
            style={{ backgroundColor: "rgb(255, 203, 127)" }}
          >
            {part}
          </span>
        ) : (
          <span className="whitespace-pre-wrap">{part}</span>
        )
      );
    };

    if (typeof parsedValue === "string") {
      return highlight(parsedValue?.value);
    }

    if (
      parsedValue !== null &&
      typeof parsedValue === "object" &&
      "value" in parsedValue
    ) {
      return highlight(parsedValue?.value);
    }

    return parsedValue;
  };

  const toggleCollapse = (rowKey) => {
    setCollapsedRows((prevState) => ({
      ...prevState,
      [rowKey]: !prevState[rowKey],
    }));
  };

  const updateTableField = (e, item, completedata) => {
    e.stopPropagation();
    TableFieldUpdate(item, completedata,multiData);
    setDataInputRow(null);
  };

  const handleMouseEnter = (e) => {
    const cellRect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: cellRect.bottom,
      left: cellRect.left,
    });
  };
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
          backgroundColor: theme.palette.common.white,
          
        }
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      // border: '1px solid #dadde9',
      padding:"0px",
      boxShadow:"0px 0px 14.091px rgba(44, 73, 93, 0.30)"
    },
  }));
  
  const getPersonAvatar=(personList)=>{
   
    return(
        
      <div className="w-full">
        <div className="pt-1 space-x-1 flex items-end">
      {personList
        ?.filter((assigned, index) => index < 3)
        .map((eachInput, index) => {
          const initials = eachInput.name
            .split(" ")
            .filter((word) => word.length > 0)
            .map((word) => word.charAt(0).toUpperCase())
            .slice(0, 2)
            .join("");

          return (
            <div key={index} className="relative">
              <div className="bg-gray-200 text-gray-500 text-12 font-600 border-gray-300  p-[10px] w-5 h-5 flex items-center justify-center rounded-full border-[1px]">
                {initials}
              </div>
              {/* <button
                className="absolute top-[-4px] right-[-4px] bg-blue-500 text-white rounded-full w-[10px] h-[10px] flex items-center justify-center text-xs cursor-pointer"
              // onClick={(e) => handleSelectPerson(e, eachInput)} */}
              {/* >
                &times;
              </button> */}
            </div>
          );
        })}
      {personList?.length > 3 && (
        <div className="">
          <div className="bg-gray-200 text-gray-500 text-12 font-600 border-gray-300   w-7 h-5 flex items-center justify-center rounded-full border-[1px]">
            + {personList?.length - 3}
          </div>
         
        </div>
      )}
    </div>
      </div>)
    
  }
  const renderCellContent = (item, header, searchTerms, tableSearchInput) => {
    
    const DataText = highlightText(item[header.key],searchTerms[header.key] || tableSearchInput);
    const TextElement = TextFieldElement && TextFieldElement[item[header.key]?.type] && (
      <div className="min-h-[20px] w-full">
        {TextFieldElement[item[header.key]?.type](DataText, tableDetail)}
      </div>
    );
    
    if(item[header.key]?.type === "multi_person"){
      return getPersonAvatar(JSON.parse(item[header.key]?.value))
    }
    // const TextElement = TextFieldElement &&
    //   TextFieldElement[item[header.key]?.type] && (
    //     <Fragment>
    //       {TextFieldElement[item[header.key]?.type](DataText, tableDetail)}
    //     </Fragment>
    //   );
    return <div className="min-h-[20px] w-full" >{TextElement ? TextElement : DataText}</div>
 
  };
//
  const onFocus = () => {};
  // const [openDialog,setOpenDialog]=useState(false)
  // const handleOpenDialog=()=>{
  //   setOpenDialog(true)
  

  const [popoverData,setPopoverData]=useState({})
  const handleFocusCustomer = (item,header) => {
    setPopoverData(item)
  };

  const renderTableRows = (data, level = 0) => {
    
    return data.map((item, index) => {

      const firstKey = Object.keys(item)[0];
      const parsedValue = parseJSONValue(item);
      const rowKey = item[firstKey]?.value;
      const isParent = Boolean(parsedValue?.value);
      const latestGroupedHeader =groupedHeaders.length > 0 ? groupedHeaders[groupedHeaders.length - 1].key: "";
      const isCollapsed = collapsedRows[item.groupKey];
      const parsedlatestGrp = parseJSONValue(item[latestGroupedHeader]);
      const handleChangeCheckox=(event)=>{
        const value=event.target.value
        let newCheckedItems = structuredClone(multiData);
        if (event?.nativeEvent.shiftKey ) {
          const start = Math.min(lastCheckedIndex, value);
          const end = Math.max(lastCheckedIndex, value);
    
          for (let i = start; i <= end; i++) {
            newCheckedItems[i] = true;
          }
          setMultiData(newCheckedItems);
        }
        else{
          setMultiData((prevMultiData)=>{
            const newSelectedValues = { ...prevMultiData };
            if (newSelectedValues[value]) {
              delete newSelectedValues[value];
            } else {
              newSelectedValues[value] = true;
            }
            return newSelectedValues;
          })
        }
        setLastCheckedIndex(value);

       

          
      }
      return (
        <Fragment key={rowKey}>
          <tr className={`border  border-neutral-200 tablerow`}>
            
            {groupedHeaders?.length > 0 && (
              <td
                className={` font-normal px-6 py-3 text-left border whitespace-nowrap border-x-0`}
                style={{ paddingLeft: `${level !== 0 && level * 30}px` }}
              >
                {isParent && (
                  <Fragment>
                    {parsedValue?.value} ({item.items.length}){" "}
                    {isCollapsed ? (
                      <button onClick={() => toggleCollapse(item.groupKey)}>
                        ▶
                      </button>
                    ) : (
                      <button onClick={() => toggleCollapse(item.groupKey)}>
                        ▼
                      </button>
                    )}
                  </Fragment>
                )}
                {!isParent && (
                  <Link
                    className="text-2999BC"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleNavigate(rowKey, item);
                    }}
                  >
                    {" "}
                    {parsedlatestGrp?.value}{" "}
                  </Link>
                )}
              </td>
            )}

            {!isParent && (
              <Fragment>
                {multiSelect && <td className="bg-white z-[1] sticky left-0">
                <input key={index} type="checkbox" className=" cursor-pointer switch border-gray-300  hover:border-[#005B88] checked:bg-[#005B88]" value={item?.id?.id} checked={multiData[item?.id?.id]} onChange={handleChangeCheckox}/>
                </td>}
                
                {renderTableHeaders() &&
                  renderTableHeaders().map((header, headerIndex) => {
                    const isDataInputRow  = dataInputRow && dataInputRow?.rowIndex === rowKey && dataInputRow?.columnKey === header.key;
                    const isDifferentCell = dataInputRow && (dataInputRow.rowIndex !== rowKey ||dataInputRow.columnKey !== header.key);
                    const parsedValue = parseJSONValue(item[header.key]);

                    const statusvalue =
                      parseInt(item?.status?.value) === 0 &&
                      header.key !== "action";
                    // Table Body Data begins here
                    return (
                      Number(header.statuslabel) === 1 && (
                        <td
                          key={headerIndex}
                          className={`${ stickyHeaders && stickyHeaders[header.key]?.name===header.key?`bg-white z-[1] sticky left-[${stickyHeaders && stickyHeaders[header.key]?.width}]`:""} whitespace-nowrap border-b border-e border-neutral-200 text-2C495D font-normal text-left text-[12px] ${
                            item?.access_type?.value === "Owner" &&
                            header.key === "action"
                              ? ""
                              : "cursor-pointer"
                          } border-r-0 
                              ${isDataInputRow ? "p-0" : "py-0.5"} 
                              ${
                                isDifferentCell ||statusvalue || (tableDetail?.forcefullyfreeze && (!navigateNameCondition ||!navigateNameCondition.includes(
                                      header.key
                                    ))) ?'pointer-events-none freezetd':''
                                  
                              }
                            `}
                          onClick={(event) => {
                            if (
                              !statusvalue &&
                              header.key !== "action" &&
                              header.key !== "activity" &&
                              (!navigateNameCondition ||
                                !navigateNameCondition.includes(header.key))
                            ) {
                              handleOtherDetailChange(rowKey, item, header.key,event);
                            }
                          }}
                          onMouseEnter={() => {
                            if (
                              !navigateNameCondition ||
                              !navigateNameCondition.includes(header.key) ||
                              HoverOnCustomerName

                            ) {
                              setHoveredCell({
                                rowIndex: rowKey,
                                columnKey: header.key,
                              });
                            }
                            // else{
                            //   console.log(header,"header.key")
                            // }
                          }}
                          onMouseLeave={() => setHoveredCell(null)}
                        >
                          <div
                            className={`clickopennow flex justify-between relative ms-5  ` }
                            
                          >
                            {dataInputRow &&
                            dataInputRow.rowIndex === rowKey &&
                            dataInputRow.columnKey === header.key ? (
                              <Fragment>
                                <div className="relative w-full">
                                  <RenderElements
                                    tableDetail={tableDetail}
                                    fetchPersonInTable={fetchPersonInTable}
                                    Item={item}
                                    Header={header}
                                    RowKey={rowKey}
                                    CommonData={customerCommnData}
                                    CellChange={tableCellChange}
                                    CellInput={tablecellInput}
                                    setDataRow={setDataInputRow}
                                    DataRow={dataInputRow}
                                    UpdateField={updateTableField}
                                    clientCoord={clientCoord}
                                    refetchCustomerData={refetchCustomerData}
                                    multiData={multiData}
                                  />
                                </div>

                                
                              </Fragment>
                            ) : 
                            (
                              <Fragment>
                                {header.key === "action" ||
                                header.key === "activity" ? (
                                  <div className="flex items-center">
                                    {header.key === "activity" &&
                                    tableDetail?.dataactivity === true ? (
                                      <div className="relative">
                                        <i
                                          className="bi-hdd-stack icon-colorG"
                                          onClick={() =>
                                            handleMouseEnterDebounced(
                                              item?.customer_id?.value
                                            )
                                          }
                                        >
                                          {item?.activityCount?.value > 0 && (
                                            <i className="bi bi-circle-fill text-D93916 relative bottom-[6px] ms-1 due-info">
                                              <span className="text-white text-xs absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                                                {item?.activityCount?.value}
                                              </span>
                                            </i>
                                          )}
                                        </i>
                                        {popupItemId ===
                                          item?.customer_id?.value &&
                                          isPopupOpen && (
                                            <TaskActivityList
                                              onFocus={onFocus}
                                              taskid={popupItemId}
                                              onChange={handleClosed}
                                              disble={false}
                                            />
                                          )}
                                      </div>
                                    ) : null}
                                    {tableDetail?.downloaddata === true && (
                                      <i
                                        className="bi bi-download text-18 text-1D4469 font-500"
                                        onClick={() =>
                                          handleDownload(
                                            rowKey,
                                            item,
                                            item.file_name
                                          )
                                        }
                                      />
                                    )}
                                    {tableDetail?.statusUpdate === true && (
                                      <CheckBox1
                                        Item={item}
                                        UpdateCheckBox={(e) => {
                                          e.stopPropagation();
                                          handleStatusUpdate(
                                            rowKey,
                                            item,
                                            item.id,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    )}
                                    {tableDetail?.datetedata === true &&
                                    item?.access_type?.value !== "Owner" ? (
                                      <button
                                        type="button"
                                        className="ms-3"
                                        onClick={() =>
                                          handleDelete(rowKey, item, item.id)
                                        }
                                      >
                                        <i className="bi bi-trash3 text-18 text-F84545 font-600" />
                                      </button>
                                    ) : null}
                                  </div>
                                ) : (
                                  <Fragment>
                                    <div
                                      onMouseEnter={handleMouseEnter}
                                      className={`flex items-center  ${
                                        item[header.key]?.type === "person" &&
                                        "persontype"
                                      } ${
                                        navigateNameCondition &&
                                        navigateNameCondition.includes(
                                          header.key
                                        )
                                          ? "text-2999BC"
                                          : "text-black"
                                      }
                                        ${
                                          item[header.key]?.others
                                            ? "owner-info"
                                            : ""
                                        } ${
                                        parsedValue?.color_code &&
                                        `p-0.5 rounded-[5px] text-white px-2 border bg-[${parsedValue?.color_code?.trim()}]`
                                      }`}
                                      onClick={
                                        navigateNameCondition &&
                                        navigateNameCondition.includes(
                                          header.key
                                        )
                                          ? (e) => {
                                              e.stopPropagation();
                                              handleNavigate(
                                                rowKey,
                                                item,
                                                header
                                              );
                                            }
                                          : null
                                      }
                                    >
                                      
                                      <p
                                        className={`${
                                          item[header.key]?.type === "text" &&
                                          `accounting-ellipsis w-[${
                                            ExtraFieldComponent &&
                                            ExtraFieldComponent[header.key]
                                              ? "120px"
                                              : "120px"
                                          }]`
                                        }`}
                                        
                                      >
                                        {renderCellContent(
                                          item,
                                          header,
                                          searchTerms,
                                          tableSearchInput
                                        )}
                                      </p>
                                    </div>
                                    {ExtraFieldComponent &&
                                      ExtraFieldComponent[header.key] && (
                                        <Fragment>
                                          {ExtraFieldComponent[header.key](
                                            item,
                                            header
                                          )}
                                        </Fragment>
                                      )}

                                    {ExtraFieldComponent &&
                                      ExtraFieldComponent[
                                        item[header.key]?.type
                                      ] && (
                                        <Fragment>
                                          {ExtraFieldComponent[
                                            item[header.key]?.type
                                          ](item, header, tableDetail)}
                                        </Fragment>
                                      )}

                                    {!navigateNameCondition?.includes(
                                      header.key
                                    ) && (
                                      <button
                                        className={`ms-3 ${
                                          hoveredCell &&
                                          hoveredCell.rowIndex === rowKey &&
                                          hoveredCell.columnKey === header.key
                                            ? "opacity-1"
                                            : "opacity-0"
                                        }`}
                                      >
                                        <i className="bi bi-pencil"></i>
                                      </button>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </td>
                      )
                    );
                  })}

                {tableDetail?.actionfield && tableDetail.actionfield(item)}
              </Fragment>
            )}
          </tr>
          {isParent &&
            !isCollapsed &&
            item.items &&
            renderTableRows(item.items, level + 1)}
        </Fragment>
      );
    });
  };
  const handleAllChecked=()=>{
    if(Object.keys(multiData)?.length){
      setMultiData({})
    }
    else{
      const newData=groupedData.reduce((acc,item)=>{
        
        acc[`${item?.id?.id}`]=true
        return acc
    },{})
    setMultiData(newData)
    }
    
  }

  return (
    <div className="mt-[44px]">
    <Fragment>
    {/* <div className="flex  justify-between pb-2 ">
     <div className="flex space-x-4 "> */}
        <div
          className={`flex ${
            isFullscreen && "bg-F1F6F9"
          } justify-between droppingarea ${
            isDraggingOver ? "highlighted" : ""
          } ${isDragAccurateLoc ? "locationhighlighted" : ""}`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <div className="flex items-center pt-1 ">
            {groupedHeaders?.length > 0 ? (
              groupedHeaders?.map((header, index) => (
                <span key={index} className="grouped-header">
                  {index === 0 ? (
                    "Group By "
                  ) : (
                    <span className="mx-1">
                      {" "}
                      <i className="bi bi-chevron-double-right"></i>{" "}
                    </span>
                  )}
                  <span className="bg-D7DFE3 text-1D4469 text-sm rounded capitalize">
                    {header?.value}{" "}
                    <button
                      className="btn ms-2"
                      onClick={() => handleRemoveHeader(index)}
                    >
                      {" "}
                      <i className="bi bi-x-lg"></i>{" "}
                    </button>{" "}
                  </span>
                </span>
              ))
            ) : (
              <h4 className={tableDetail?.tablenameclass}>
                {" "}
                {tableDetail?.tablename}{" "}
              </h4>
            )}
          </div>
            
          {draggedHeader?.value && (
          <div className="dragged-value">
            Drop To Group By {draggedHeader?.value}
          </div>
        )}

        
          {/* {TableTypeFilter && <div className="mb-2 min-h-[30px]">
            {TableTypeFilter && TableTypeFilter?.map((eachFilter)=>(
                <button disabled={eachFilter.value===UserTypeFlag} onClick={()=>handleTypeChange(eachFilter.value)} className={`w-fit min-w-[100px] px-2 py-1 rounded-full   ${eachFilter.value===UserTypeFlag?"bg-cyan-900 text-white" :"bg-cyan-50 text-black"} hover:border-[1px] hover:border-cyan-900 text-[10px] mr-2 box-border`}>{eachFilter.label}
                    
        )} */}
        <div className="flex items-center gap-3 me-1">
          
          
            {/* {isGridActive && ( */}
              {/* <div className="border-[1px] space-x-4 flex justify-center items-center rounded-l-lg rounded-r-lg border-[rgba(29, 68, 105, 0.20)] bg-white">
                <button 
                  className={`text-12 text-848687 font-400 px-4 py-2 ${view === "Day" ? "selected" : ""}`}
                  onClick={() => changeview("Day")}>
                    Daily
                </button>
              ))}
          </div>} */}


          </div>
      <div className="flex items-center gap-2 me-1 pb-1 ">
      <div className="mb-2 h-[20px] space-x-2 ">
      {TableTypeFilter && TableTypeFilter.length > 0 && (
              <FormControl
                key={TableTypeFilter[0].value}
                className="mr-2 w-[134px] bg-white"
                
                sx={{
                  height: '30px', 
                  fontSize: '12px',
                  borderColor: '#3F6F8F',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#3F6F8F', 
                },
              }, 
                }}
              >
                <Select
                  value={selectedValue}
                  open={openDropdown === TableTypeFilter[0].value} 
                  onClose={handleClose}
                  onOpen={() => handleOpen(TableTypeFilter[0].value)}
                  onChange={handleChange}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        mt: 1, // Add margin-top to create space between FormControl and dropdown
                      },
                    },
                  }}
                  sx={{
                    fontSize: '12px', 
                    padding: '5px 0px', 
                    minHeight: '30px', 
                    color: '#1D4469',
                    '& .MuiSelect-icon': {
                  color: '#1D4469', // Change the icon color
                },
                  
                  }}
                >
                  {TableTypeFilter.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ fontSize: '12px' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/* Render the second Select dropdown from TableActiveFilter */}
            {TableActiveFilter && (
              <FormControl
                className="w-[134px] bg-white"
                sx={{
                  height: '30px',
                  fontSize: '12px',
                  borderColor: '#3F6F8F', 
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#3F6F8F',
                },
              },
                  
                }}
              >
                <Select
                  value={UserActiveFlag}
                  onChange={(e) => handleActiveChange(e.target.value)}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        mt: 1, // Add margin-top to create space between FormControl and dropdown
                      },
                    },
                  }}
                  sx={{
                    fontSize: '12px', 
                    padding: '5px 0px', 
                    height: '30px',
                    color: '#1D4469',
                      '& .MuiSelect-icon': {
                  color: '#1D4469', // Change the icon color
                },
                  
                  }}
                >
                  {TableActiveFilter.map((option) => (
                    <MenuItem 
                      key={option.value}
                      value={option.value}
                      // disabled={option.value === UserActiveFlag}
                      sx={{ fontSize: '12px'}}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          <div className="flex items-center  ml-2 space-x-4"> 
                {tableDetail?.tablequickfilter && (
                  <div>{tableDetail?.tablequickfilter}</div>
                )}

                {tableDetail?.tableSearch && (
                  <SearchBar
                    data={tableSearchInput}
                    event={handleGeneralSearch}
                    SearchOpen={false}
                    className="ml-4" 
                  />
                )}

                <div className="ml-4">{tableDetail?.addbutton}</div> 
                {tableDetail?.tableFilter && (
                  <FilterSearchTh
                    tableThSearch={tableThSearch}
                    setTableThSearch={setTableThSearch}
                    tableSearchInput={tableSearchInput}
                    searchTerms={searchTerms}
                    className="ml-4"  
                  />
                )}        
          
                {tableDetail?.tableResizer && (
                  <ToggleDensityMode event={toggleDensityMode} className="ml-4"> 
                    <button
                      className="hover:text-254155  text-2541558A"
                      type="button"
                    >
                      <i
                        className={`${densityIcons[densityMode]} tableheaderfiltericon`}
                      ></i>
                    </button>
                  </ToggleDensityMode>
                )}

          

                {isTableMainDivPresent && tableDetail?.tableZoom && (
                  <FullScreenMode event={(e) => toggleFullscreen(e)} className="ml-4"> 
                    {isFullscreen ? (
                      <i className="bi bi-fullscreen-exit tableheaderfiltericon text-254155 border border-253E50 px-1 rounded"></i>
                    ) : (
                      <i className="bi bi-fullscreen tableheaderfiltericon hover:text-254155  text-2541558A"></i>
                    )}
                  </FullScreenMode>
                )}
              </div>

          {tableDetail?.tableheader && (
            <SettingHeader
              thead={storedThead}
              setStoredThead={setStoredThead}
              handlePostThead={handlePostThead}
              ExtraFieldAddHeader={ExtraFieldAddHeader}
              GetAdditionalField={GetAdditionalField}
              GetAddFieldData={GetAddFieldData}
              tableDetail={tableDetail}
            />
          )}

          {(Object.keys(searchTerms).length > 0 || tableSearchInput) && (
            <ClearAllFilter
              setTableSearchInput={setTableSearchInput}
              setSearchTerms={setSearchTerms}
              clearAllFilters={clearAllFilters}
            />
          )}
        </div>
    </div>     
      <div id="fullscreen-container">
        <div
          id={`${tableDetail.tableparentidclass || "tableparent"}`}
          className={`${
            tableDetail.tableparentidclass || "tableparent"
          } w-100  bg-white 
          overflow-x-auto
          overflow-y-auto
          hide-scrollbar-x

          scrollbar-hide
          ${tableDetail?.tablename ? 'h-min-[25vh] h-max-[75vh]' : 'h-[75vh]'}
          customscrollbar`}
          ref={child1Ref}
         
        >
          <table
            ref={tabletRef}
            id={`${tableDetail.tableidclass}`}
            className={` ${
              tableDetail.tableidclass
            }  min-w-full border border-neutral-200 text-center text-[13px] text-surface rounded-lg ${
              groupedData?.length < 10 ? "" : "shadow-lg"
            }  text-2C495D font-poppins ${
              isFullscreen ? "h-full" : ""
            } ${densityMode}`}
          >
            <thead className="capitalize sticky ">
              <tr>
                {groupedHeaders?.length > 0 && (
                  <Fragment>
                    <th
                      scope="col"
                      className=" px-6 py-3 text-left border whitespace-nowrap border-x-0"
                      // style={{ width: columnWidths[0] || "auto" }}
                    >
                      <div
                        className={`flex justify-between items-center cursor-pointer`}
                        onClick={() => {
                          sortData(groupedHeaders[0]?.key);
                        }}
                      >
                        <div
                          onMouseEnter={() =>
                            setHoveredHeader(groupedHeaders[0].key)
                          }
                          onMouseLeave={() => setHoveredHeader(null)}
                        >
                          Groups
                        </div>
                        <div className="ms-3 w-[19px] h-[18px]">
                          {(hoveredHeader === groupedHeaders[0].key ||
                            tableThSearch) && (
                            <SortingFilter
                              sortKey={sortKey}
                              sortOrder={sortOrder}
                              header={groupedHeaders[0]}
                            />
                          )}
                        </div>
                      </div>

                      {/* <div
                      className="resizer"
                      onMouseDown={(e) => handleMouseDown(0, e)}
                      onDoubleClick={() => handleDoubleClick(0)}
                    /> */}
                    </th>
                  </Fragment>
                )}
               {multiSelect && <th className="sticky left-0 bg-white z-[99]">
                  <input type="checkbox" className=" cursor-pointer border-gray-300 hover:border-[#005B88] checked:bg-[#005B88]" checked={Boolean(Object.keys(multiData).length>1)} onChange={handleAllChecked}/>
                </th>}
                 {/* Table Header begins here */}
                {renderTableHeaders() &&
                  renderTableHeaders().map((header, index) => (
                    <th
                      key={index}
                      scope="col"
                      className={`${stickyHeaders && stickyHeaders[header.key]?.name===header.key?`bg-white z-[99] sticky left-[${stickyHeaders && stickyHeaders[header.key]?.width}]`:""} pr-6 text-left  border whitespace-nowrap border-x-0 `}
                      // style={{ width: columnWidths[index + 1] || "auto" }}
                    >
                     
                      <div
                        className={`flex justify-between items-center cursor-pointer`}
                        onClick={() => sortData(header.key)}
                      >
                        <div
                          onMouseEnter={() => setHoveredHeader(header.key)}
                          // onMouseLeave={() => setHoveredHeader(null)}
                        >
                          <button
                            className="btn me-2 cursor-grab"
                            draggable
                            onDragStart={() =>
                              handleDragStart(header.key, header.label)
                            }
                          >
                            <i className="bi bi-grip-vertical opacity-55 text-sm"></i>
                          </button>

                          {header.label}
                        </div>

                        <div className="ms-3 w-[19px] h-[18px]">
                          {(hoveredHeader === header.key || tableThSearch) && (
                            <SortingFilter
                              sortKey={sortKey}
                              sortOrder={sortOrder}
                              header={header}
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        {tableThSearch && (
                          <div className="relative mt-2">
                            <input
                              className="px-3 w-full h-[30px] rounded-lg bg-FAFAFA text-[11px] border border-CED4DA"
                              type="text"
                              placeholder={`Filter By ${
                                header.label.split(" ")[0]
                              }`}
                              value={searchTerms[header.key] || ""}
                              onChange={(e) =>
                                handleInputChange(header.key, e.target.value)
                              }
                            />
                            {searchTerms[header.key] && (
                              <button
                                className="absolute inset-y-0 right-2"
                                onClick={() =>
                                  handleInputChange(header.key, "")
                                }
                              >
                                <i className="bi bi-x text-B4C0C8"></i>
                              </button>
                            )}
                          </div>
                        )}
                      </div>

                      {/* <div
                      className="resizer"
                      onMouseDown={(e) => handleMouseDown(index + 1, e)}
                      onDoubleClick={() => handleDoubleClick(index + 1)}
                      /> */}
                    </th>
                  ))}
                {/* <th></th> */}

                {tableDetail?.actionfield && <th className=" pr-6 py-3 text-left border whitespace-nowrap border-x-0 ">Action</th>}
              </tr>
            </thead>
            <tbody className="border">
              {Boolean(groupedData?.length) ? renderTableRows(groupedData): <td colSpan={"10%"}  className="text-black bg-gray-500">No Data to Show</td> }
            </tbody>
          </table>
        </div>       
      </div>


      { (
        <div className="mt-2 flex justify-between align-centre ">
          <div className="flex min-w-fit w-auto">
            <div className="flex flex-grow justify-between items-center " >
              
             {(groupedData?.length >= 20 || initialFilter?.total >= 20) && initialFilter &&
              <div className="mr-1">
                <select
                  className="tblpadinationSelect form-select"
                  onChange={(e) => DataElementShow(e)}
                  defaultValue={initialFilter.per_page}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                  <option value={60}>60</option>
                  <option value={70}>70</option>
                  <option value={80}>80</option>
                  <option value={90}>90</option>
                  <option value={100}>100</option>
                </select>
              </div>}

              {
                (groupedData?.length >= 20 || initialFilter?.total >= 20) && initialFilter &&  (
                  <div className="bg-white     customerpadination ">
                    <Pagination 
                    initialFilter={initialFilter}
                    onPageChange={handlePaginate}
                  />
                  </div>
              )
             }
             
            </div>
          </div> 
          <div className={`flex justify-center items-center ${tableDetail?.tableHeading==="Customer Table" && (groupedData?.length >= 20 || initialFilter?.total >= 20) && initialFilter?"w-1/2 ml-4":"w-full"}  `}>
              {tableDetail?.tableHeading==="Customer Table" && <div className="mt-[4px] text-[20px]"><i className="bi bi-three-dots-vertical"></i></div>}
                        
                <button className={`mr-[-20px] bg-[#5d5f60] bg-opacity-100 text-white px-2 h-[15px] text-sm rounded-l-full flex items-center justify-center ${
    scrollbarWidth === "0px" ? "hidden" : ""
  }`}>
                     <span className="" style={{fontSize: '7.5px',}}>&#9664;</span> 
                    </button>

                    <div className="flex-grow overflow-x-auto overflow-y-hidden scrollbar-thin customscrollbar1" ref={scrollbarWrapperRef}>
                      <div style={{ width: scrollbarWidth, height: "1px" }} ></div>
                    </div>

                    <button className={`ml-[-20px]  bg-[#5d5f60] bg-opacity-100 text-white px-2  h-[15px] text-sm rounded-r-full flex items-center justify-center ${
                      scrollbarWidth==="0px"?"hidden":""
                    }`} >
                    <span className="" style={{fontSize: '7.5px',}}>&#9654;</span> 
                    </button>
                    

                </div>
                         

        </div>
      )}
      {/* {calview &&(
        <TaskRecurrenceCalendar defaultview={view}/>
      )} */}
     
    </Fragment>

  {openShowDetails && <CustomerShowDetails open={openShowDetails} onClose={()=>handleShowDetails(false)} Data={openShowDetails} />}
  </div>
    
  );
};
export default CustomerListTable;
