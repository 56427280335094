const GroupsHeader = [
    {
        "name": "Name",
        "name_status": 1
    },

    
    {
      "manager_name": "Reporting Manager",
      "manager_name_status": 1
    },
    {
      "designation": "Job Title",
      "designation_status": 1
    },
    {
        "department": "Department",
        "department_status": 1
    },
    {
      "access_type": "Access",
      "owner_status": 1
  },
  {
    "action": "Action",
    "action_status": 1
  },
    
]


export default GroupsHeader