import React from 'react'

const CirclePattleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13 13" fill="none">
  <g clip-path="url(#clip0_11157_3525)">
    <path d="M0 6.5C0 2.9101 2.9101 0 6.5 0C10.0899 0 13 2.9101 13 6.5C13 10.0899 10.0899 13 6.5 13C2.9101 13 0 10.0899 0 6.5ZM6.5 10.3869C8.6467 10.3869 10.3869 8.6467 10.3869 6.5C10.3869 4.3533 8.6467 2.61305 6.5 2.61305C4.3533 2.61305 2.61305 4.3533 2.61305 6.5C2.61305 8.6467 4.3533 10.3869 6.5 10.3869Z" fill="#83D9FF"/>
    <path d="M6.5 13.0004V10.3873C6.99621 10.3873 7.4704 10.2945 7.9067 10.125L8.80073 12.5813C8.08533 12.8521 7.31012 13.0004 6.5 13.0004Z" fill="#3FEFEF"/>
    <path d="M7.90723 10.1239C8.35632 9.94946 8.76515 9.69387 9.11566 9.375L10.7963 11.3776C10.2143 11.8903 9.53996 12.3007 8.80125 12.5803L7.90723 10.1239Z" fill="#1DD1D9"/>
    <path d="M9.11523 9.38093C9.46207 9.06523 9.75169 8.68774 9.96661 8.26562L12.2315 9.57344C11.8636 10.2598 11.3755 10.8726 10.7958 11.3835L9.11523 9.38093Z" fill="#17B5BA"/>
    <path d="M10.2812 5.59498L12.8577 5.14062C12.9516 5.5798 13.0008 6.03525 13.0008 6.5024C13.0008 6.80579 12.9799 7.10423 12.9396 7.39672L10.3632 6.94267C10.3793 6.79826 10.3878 6.65127 10.3878 6.5024C10.3878 6.18977 10.3509 5.88618 10.2812 5.59498Z" fill="#9BE058"/>
    <path d="M9.75781 4.38594L12.0229 3.07812C12.4124 3.70436 12.6986 4.40141 12.8571 5.14577L10.2806 5.60013C10.1758 5.16174 9.99684 4.75242 9.75781 4.38594Z" fill="#C0FF66"/>
    <path d="M8.87793 3.42476L10.5586 1.42188C11.135 1.88327 11.6312 2.44077 12.0228 3.07008L9.75768 4.3779C9.51934 4.01301 9.2214 3.69077 8.87793 3.42476Z" fill="#FFDF46"/>
    <path d="M4.35449 0.362808C5.02645 0.127647 5.7484 0 6.5004 0V2.61305C6.06221 2.61305 5.64118 2.68555 5.24822 2.81915L4.35449 0.362808Z" fill="#FC3E81"/>
    <path d="M2.44141 1.41894C3.00883 0.964882 3.65401 0.603958 4.35374 0.359375H4.35403L5.24776 2.81572C4.83735 2.95517 4.45788 3.16157 4.12205 3.42182L2.44141 1.41894Z" fill="#F01054"/>
    <path d="M0.977539 3.07008C1.36911 2.44077 1.86532 1.88337 2.44176 1.42188L4.12241 3.42476C3.77894 3.69077 3.481 4.01301 3.24276 4.3779L0.977539 3.07008Z" fill="#7884EA"/>
    <path d="M0.0615234 7.39937L2.63798 6.94531C2.69114 7.41583 2.82811 7.86106 3.03381 8.26543L0.768791 9.57324C0.412529 8.90912 0.168243 8.17616 0.0615234 7.39937Z" fill="#1685FB"/>
    <path d="M0.768555 9.57344L3.03348 8.26562C3.2485 8.68774 3.53812 9.06523 3.88496 9.38093L2.20432 11.3835C1.62469 10.8726 1.13662 10.2598 0.768555 9.57344Z" fill="#43A3F9"/>
    <path d="M2.2041 11.3776L3.88474 9.375C4.23515 9.69387 4.64398 9.94946 5.09318 10.1239L4.19905 12.5803C3.46044 12.3007 2.7861 11.8903 2.2041 11.3776Z" fill="#64BDFF"/>
    <path d="M0.143555 5.14577C0.302048 4.40141 0.588188 3.70436 0.977676 3.07812L3.2428 4.38594C3.00377 4.75242 2.82484 5.16174 2.72001 5.60013L0.143555 5.14577Z" fill="#4A5BE5"/>
    <path d="M9.9668 8.26543C10.1724 7.86096 10.3094 7.41583 10.3625 6.94531L12.939 7.39937C12.8323 8.17616 12.588 8.90912 12.2317 9.57324L9.9668 8.26543Z" fill="#1CC939"/>
    <path d="M0 6.5024C0 6.03525 0.0491943 5.5798 0.14312 5.14062L2.71957 5.59498C2.64995 5.88618 2.61305 6.18977 2.61305 6.5024C2.61305 6.65127 2.62148 6.79826 2.63765 6.94267L0.0611954 7.39672C0.0209274 7.10423 0 6.80579 0 6.5024Z" fill="#364EC6"/>
    <path d="M7.75195 2.81572L8.64598 0.359375C9.34571 0.603958 9.99099 0.964882 10.5583 1.41904L8.87777 3.42182C8.54184 3.16157 8.16227 2.95517 7.75195 2.81572Z" fill="#F9B200"/>
    <path d="M6.5 2.61305V0C7.252 0 7.97424 0.127647 8.6462 0.362808L7.75208 2.81915C7.35921 2.68555 6.93819 2.61305 6.5 2.61305Z" fill="#FD6096"/>
  </g>
  <defs>
    <clipPath id="clip0_11157_3525">
      <rect width="13" height="13" fill="white"/>
    </clipPath>
  </defs>
</svg>
  )
}

export default CirclePattleIcon