import React, { useState,useEffect } from "react";
import useChartsOfAccounts from "../../hooks/useChartsOfAccounts";
import useRequireAuth from "../../utils/useRequireAuth";
import { COMMON_URL,ADD_UPDATE_CHART_OF_ACCOUNT_DETAILS } from "../../utils/constants";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

const AddAccount = ({ isOpen, onClose }) => {
  
  const chartOfAccounts = useChartsOfAccounts();
  const user = useRequireAuth();
  const [currencyData, setcurrencyData] = useState(null);
  const[fieldError,setFieldError]=useState('');
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    chart_of_accounts_id: "",
    account_name: "",
    account_number: "",
    descriptions:"",
    balance_as_on_date:"",
    amount:"",
    currency:""
  });

  const [errors, setErrors] = useState({
    chart_of_accounts_id: "",
    account_name: "",
    account_number: "",
    balance_as_on_date:"",
    amount:"",
    currency:""
  });

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });    
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.chart_of_accounts_id) {
      newErrors.chart_of_accounts_id = "Select an option";
    }
    if (!formData.account_name) {
      newErrors.account_name = "Enter Account Name";
    }
    if (!formData.account_number) {
      newErrors.account_number = "Enter Account Number";
    }
    if (!formData.currency) {
      newErrors.currency = "Enter Currency ";
    }
    if (!formData.balance_as_on_date) {
      newErrors.balance_as_on_date = "Enter Balance Date";
    }
    if (!formData.amount) {
      newErrors.amount = "Enter Amount";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
      e.preventDefault();

      // Validate the form
      const isFormValid = validateForm();

      if (isFormValid) {
          setFormData({
              chart_of_accounts_id: "",
              account_name: "",
              account_number: "",
              balance_as_on_date: "",
              amount: "",
              currency: "",
          });

          const formDataWithFile = new FormData();
          formDataWithFile.append("chart_of_accounts_id", formData.chart_of_accounts_id);
          formDataWithFile.append("account_name", formData.account_name);
          formDataWithFile.append("account_number", formData.account_number);
          formDataWithFile.append("balance_as_on_date", formData.balance_as_on_date);
          formDataWithFile.append("amount", formData.amount);
          formDataWithFile.append("currency", formData.currency);
          formDataWithFile.append("descriptions", formData.descriptions);
          
          try {
              const response = await fetch(ADD_UPDATE_CHART_OF_ACCOUNT_DETAILS, {
                  method: "POST",
                  headers: {
                      Authorization: `Bearer ${user.token}`,
                  },
                  body: formDataWithFile,
              });

              if (!response.ok) {
                  throw new Error("Failed to save data");
              }

              const data = await response.json();
              if (data.status === true) {
                setSuccessMessage("Records saved successfully");
                 
              } else {
                  throw new Error("Failed to save data");
              }
          } catch (error) {
              console.error("Error submitting form:", error);
              setFieldError("An error occurred while submitting the form.");
          }
      } else {
        setFieldError("Please Fill All Detail.");
         // console.log("Form has errors, please correct them");
      }
  };

  useEffect(() => {
    const fetchData = async () => {
     
        const Authtoken = user.token;
        try {
            const response = await fetch(COMMON_URL, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${Authtoken}`,
                "Content-Type": "application/json",
              },
            });
            const data = await response.json();
            if (data.status===false) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            console.log(data);
          setcurrencyData(data?.data?.currency);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
   fetchData();
   
  }, []);

 

setTimeout(() => {
  setFieldError("");
  setSuccessMessage("");
  setErrors("");
}, 3000);



  return (
    <div className="fixed inset-y-0 inset-x-150 z-[999] grid h-screen w-5/6 place-items-center bg-black bg-opacity-0 transition-opacity duration-300">
      <div className="relative p-4 mt-10 bg-white border border-E5ECF0 font-poppins leading-relaxed antialiased shadow-1">
        <div className="p-4">
          <div className="flex items-center justify-between p-4 md:p-3 border-b border-BECBD3 rounded-t">
          <div>
            {fieldError && (
                <p className="text-red-700">{fieldError}</p>
            )}
            {successMessage && (
                <p className="text-green-700">{successMessage}</p>
            )}
            <br className="mb-2" /> {/* This creates a line break with margin-bottom */}
           
        </div><br/>
            <h3 className="text-2xl text-2C495D">New Account (L3)</h3>
             
            <button
              onClick={onClose}
              type="button"
              className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              data-modal-hide="assets-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <form onSubmit={(e) => e.preventDefault()} className="w-full">
            <div className="p-3 md:px-0">
              <label htmlFor="chart_of_accounts_id" className="block mb-2 text-sm text-434852">
                Save Account Under *
              </label>

              <select
                id="chart_of_accounts_id"
                name="chart_of_accounts_id"
                value={formData.chart_of_accounts_id}
                onChange={handleChange}
                className="form-select bg-white border border-DFE2E5 text-gray-900 text-sm block w-full px-2.5"
              >
                {chartOfAccounts &&
                  chartOfAccounts.map((item) => (
                    <optgroup key={item.id} label={item.name} className="">
                      {item.children &&
                        item.children.map((child) => (
                          <option key={child.id} value={child.id}>
                            &nbsp;&nbsp; {child.identification_no+' - '+child.name}
                          </option>
                        ))}
                    </optgroup>
                  ))}
              </select>
              
              <div className="text-sm text-red-400">{errors.chart_of_accounts_id}</div>
            </div>
            <div className="flex flex-row px-0">
              <div className="basis-3/5 mb-3 pe-3">
                <label
                  htmlFor="account_name"
                  className="block mb-2 text-sm text-434852"
                >
                  Account Name *
                </label>
                <input
                  type="text"
                  id="account_name"
                  name="account_name"
                  value={formData.account_name}
                  onChange={handleChange}
                  className="bg-white border border-DFE2E5 text-gray-900 text-sm block w-full p-2.5"
                />
              
                <div className="text-sm text-red-400">{errors.account_name}</div>
              </div>
              <div className="basis-2/5 mb-3">
                <label
                  htmlFor="account_number"
                  className="block mb-2 text-sm text-434852"
                >
                  Account Number *
                </label>
                <input
                  type="text"
                  id="account_number"
                  name="account_number"
                  value={formData.account_number}
                  onChange={handleChange}
                  className="bg-white border border-DFE2E5 text-gray-900 text-sm block w-full p-2.5"
                />
                
                <div className="text-sm text-red-400">
                  {errors.account_number}
                </div>
              </div>
            </div>
            <div className="grid px-0 pb-3">
              <label
                htmlFor="description"
                className="block mb-2 text-sm text-434852"
              >
                Description
              </label>
              <textarea
                id="descriptions"
                name="descriptions"
                rows="4"
                value={formData.descriptions}
                onChange={handleChange}
                className="bg-white border border-DFE2E5 text-gray-900 text-sm block w-full p-2.5"
              ></textarea>
            </div>
            <div className="flex flex-row w-full px-0">
              <div className="mb-3 pe-3 mr-2 w-full">
                <label
                  htmlFor="currency"
                  className="block text-sm text-434852 mb-1 mt-2 ml-1"
                >
                  Currency
                </label>
                <select
                  id="currency"
                  name="currency"
                  value={formData.currency}
                  onChange={handleChange}
                  className="form-select bg-white border border-DFE2E5 text-gray-900 text-sm block px-2.5 w-full"
                >
                  <option >Please select currency</option>
                  {!currencyData ? null : (
                    currencyData.map((CurrencyData) => (
                      <option key={CurrencyData.id} value={CurrencyData.id}>
                        {CurrencyData.currency_name_symbol}
                      </option> 
                    ))
                  )}
                </select>
                <div className="text-sm text-red-400">{errors.currency}</div>
              </div>
              <div className="mb-3 pe-3 mr-2">
                <label
                  htmlFor="balanceDate"
                  className="block text-sm text-434852 mb-1 mt-2 ml-1"
                >
                  Balance as on Date
                </label>
                <input
                  type="date"
                  id="balance_as_on_date"
                  name="balance_as_on_date"
                  value={formData.balance_as_on_date}
                  onChange={handleChange}
                  className="bg-white border border-DFE2E5 text-gray-900 text-sm block p-2.5"
                />
               <div className="text-sm text-red-400">{errors.balance_as_on_date}</div>
              </div>
              <div className="mb-3 pe-3">
                <label
                  htmlFor="amount"
                  className="block text-sm text-434852 mb-1 mt-2 ml-1"
                >
                  Amount
                </label>
                <input
                  type="amount"
                  id="amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  className="bg-white border border-DFE2E5 text-gray-900 text-sm block p-2.5"
                />
               <div className="text-sm text-red-400">{errors.amount}</div>
              </div>
            </div>
            <div className="flex justify-end py-4 modal-footer text-md">
              <button
                onClick={onClose}
                type="button"
                className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-medium px-5 py-2.5 text-center"
              >
                CANCEL
              </button>
              <button
                onClick={handleSubmit}
                type="button"
                className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-medium text-gray-900 border border-gray-200 py-2.5 px-5 ms-3"
              >
                SAVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAccount;
