import { Avatar, Dialog } from "@mui/material";
import React,{useState,useEffect} from "react";
import { GET_USER_DETAILS_MORE_DETAILS } from "../../utils/constants";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import useRequireAuth from "../../utils/useRequireAuth";
import { getInitials, getName } from "../../utils/utilityFunctions/stringUtility";
import SouthIcon from '@mui/icons-material/South';
import { deepOrange, deepPurple } from '@mui/material/colors';

const CustomerShowDetails=({open,onClose,Data})=>{
    
    const { token } = useRequireAuth();
    const [hierarchyData,setHierarchyData]=useState([])
    const fetchDataCustomerAccess=async(customerId,token)=>{
        const url=`${GET_USER_DETAILS_MORE_DETAILS}${customerId}`;
        const response=await getCommonDataBasedOnId(url,token);
        setHierarchyData(  response && response)
      }

    useEffect(()=>{
      const  {customerData,headerKey}=Data
      let payload;
     if(headerKey==="reporting"){
       payload=customerData?.manager_id?.value
     }
     else{
       payload=customerData?.customer_id?.value
     }
        fetchDataCustomerAccess(payload,token)
    },[Data])
      const {user,manager,team}=hierarchyData

      

      const Initials=({value})=>(
        <div className="h-8 w-8 sm:h-8 sm:w-8 bg-gray-200 flex items-center justify-center rounded-full me-1">
          <span className="text-xs text-gray-500">{getInitials(value)}</span>
        </div>
      )
    return(
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div
                // ref={popupRef}
                className="absolute bg-white p-5 w-[673px] border border-b-2 rounded-lg shadow-lg"
              >
                <div className="flex justify-end" onClick={onClose}>
                  <div className="rounded-[5px] bg-F1F5F8 w-[30px] h-[30px] flex justify-center items-center cursor-pointer" >
                    <i className="bi bi-x-lg"></i>
                  </div>
                </div>
                <div className="flex border-b-2 pb-2 mb-2">
                  <Avatar sx={{ bgcolor: deepPurple[500],width:86,height:86,fontSize:"40px" }}>{getInitials(user?.name)}</Avatar>
                  <div className="mx-4 space-y-2 text-2C495D">
                    <h3 className="font-medium">{getName(user?.name)}</h3>
                    <div className="flex space-x-2">
                      <i className="bi bi-envelope"></i>
                      <label className="font-medium">{getName(user?.email)}</label>
                    </div>
                    <div className="flex space-x-2">
                      <i className="bi bi-telephone"></i>
                      <label className="font-medium">{getName(user?.mobile)}</label>
                    </div>
                  </div>
                </div>
                <div className="border-b-2 pb-2 mb-2">
                  <h3 className="font-600 text-16 pb-4">Contact Information</h3>
                  <div className="flex justify-between">
                    <div>
                      <div className="flex gap-3">
                        <i className="bi bi-envelope text-B6B6B6"></i>
                        <p className="font-400 text-14 text-5E5E5E">
                          Designation
                        </p>
                      </div>
                      <label className="font-400 text-14 text-2C495D ml-7">
                      {getName(user?.designation)}
                      </label>
                    </div>
                    <div>
                      <div className="flex gap-3">
                        <i className="bi bi-diagram-3 text-B6B6B6"></i>
                        <p className="font-400 text-14 text-5E5E5E">Department</p>
                      </div>
                      <label className="font-400 text-14 text-2C495D ml-7">
                      {getName(user?.department)}
                      </label>
                    </div>
                    <div>
                      <div className="flex gap-3">
                        <i className="bi bi-diagram-3 text-B6B6B6"></i>
                        <p className="font-400 text-14 text-5E5E5E">Reporting To</p>
                      </div>
                      <label className="font-400 text-14 text-2C495D ml-7">
                      {getName(manager?.name?manager?.name:"Self")}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="font-600 text-16 mb-6">Team</h3>
                  <div class={`flex flex-col justify-center gap-4`}>
                {manager && <div onClick={()=>fetchDataCustomerAccess(manager?.id,token)} className="border border-2C495D custom-customer-card justify-between col-span-3 w-1/3 rounded-lg flex ml-52 cursor-pointer">
                  <div className="flex">
                    <div className=" p-2  rounded font-medium text-16 place-self-center">
                      {/* <img
                        className="h-5 w-5 mr-2 ml-2 rounded-full"
                        src={Initials(getName(manager?.name))}
                        alt="User"
                      /> */}
                      {<Initials value={getName(manager?.name)}/>}
                    </div>
                    <div className=" place-self-center">
                      <label className="text-2C495D text-14 font-500">
                      {getName(manager?.name)}
                      </label>
                      <div>
                        <label className="text-2C495D text-12 font-400">
                        {getName(manager?.designation)}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="place-self-center me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="16"
                      viewBox="0 0 10 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.00662 14.8456C1.44253 15.2442 2.14937 15.2442 2.58528 14.8456L9.28301 8.72198C9.71892 8.32343 9.71892 7.67719 9.28301 7.27864L2.58528 1.15503C2.14937 0.756483 1.44253 0.756483 1.00662 1.15503C0.57071 1.55357 0.57071 2.19982 1.00662 2.59836L6.91503 8.00031L1.00662 13.4023C0.57071 13.8008 0.57071 14.447 1.00662 14.8456Z"
                        fill="#545F65"
                      />
                    </svg>
                  </div>
                </div>}
                {user && <div className="border border-2C495D bg-f2f2f299 justify-between col-span-3 w-1/3 rounded-lg flex ml-52 custom-customer-card noHoverCustom">
                  <div className="flex">
                    <div className=" p-2  rounded font-medium text-16 place-self-center">
                      {/* <img
                        className="h-5 w-5 mr-2 ml-2 rounded-full"
                        src={Initials(getName(manager?.name))}
                        alt="User"
                      /> */}
                      {<Initials value={getName(user?.name)}/>}
                    </div>
                    <div className=" place-self-center">
                      <label className="text-2C495D text-14 font-500">
                      {getName(user?.name)}
                      </label>
                      <div>
                        <label className="text-2C495D text-12 font-400">
                        {getName(user?.designation)}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="place-self-center me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="16"
                      viewBox="0 0 10 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.00662 14.8456C1.44253 15.2442 2.14937 15.2442 2.58528 14.8456L9.28301 8.72198C9.71892 8.32343 9.71892 7.67719 9.28301 7.27864L2.58528 1.15503C2.14937 0.756483 1.44253 0.756483 1.00662 1.15503C0.57071 1.55357 0.57071 2.19982 1.00662 2.59836L6.91503 8.00031L1.00662 13.4023C0.57071 13.8008 0.57071 14.447 1.00662 14.8456Z"
                        fill="#545F65"
                      />
                    </svg>
                  </div> */}
                </div>}
                <div className="mt-10 flex flex-wrap gap-5 justify-center">

                
                {Boolean(team?.length)? team.map((eachMember)=>(
                    <div onClick={()=>fetchDataCustomerAccess(eachMember?.id,token)} className="border border-D9D9D9 flex bg-f2f2f299 justify-between rounded-lg custom-customer-card cursor-pointer mt-4">
                    <div className="flex pr-5">
                    <div className=" p-2  rounded font-medium text-16 place-self-center">
                        {/* <img
                        className="h-5 w-5 mr-2 ml-2 rounded-full"
                        src={Initials(eachMember?.name))}
                        alt="User"
                        /> */}
                        {<Initials value={getName(eachMember?.name)}/>}
                       
                    </div>
                    <div className="place-self-center">
                        <div className="text-2C495D text-[12px] w-[120px] font-500 whitespace-nowrap overflow-hidden text-ellipsis ">
                        {getName(eachMember?.name)}
                        </div>
                        <div>
                        <label className="text-2C495D text-[10px] font-400 text-ellipsis">
                        {getName(eachMember?.designation)}
                        </label>
                        </div>
                    </div>
                    </div>
                    </div>
                )):<p className="text-center"></p>
               }
               </div>
                
              </div>
                     {/* <div className="text-14 font-400 mt-3 text-535252 inline-block border-b cursor-pointer  border-535252 py-0 "> Less More</div> */}
                </div>
              </div>
            </div>
            </Dialog>
     
  
    )
}

export default CustomerShowDetails