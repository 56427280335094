import React, { Fragment, useState , useRef } from 'react';
import WeekDays from './WeekDays';
import ClickAwayListener from 'react-click-away-listener';
import { useNavigate} from "react-router-dom";
import AddTask from "../customer/AddTask";




const WeekCalendar = ({ value }) => {
  const [popupStates, setPopupStates] = useState(Array(7).fill(false));

  const getStartOfWeek = (date) => {
    const dayOfWeek = date.getDay();
    const diff = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    const startDate = new Date(date);
    startDate.setDate(startDate.getDate() - diff);
    return startDate;
  };

  const getWeekDates = (date) => {
    const startDate = getStartOfWeek(date);
    const dates = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      dates.push(currentDate);
    }

    return dates;
  };

  const togglePopup = (index) => {
    setPopupStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const closePopup = (index) => {
    setPopupStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  const weekDates = getWeekDates(value);
  const navigate = useNavigate();
  const eventClick = () => navigate('/AddTask');



  
  return (
    <Fragment>
      <WeekDays />
      <div className="grid grid-cols-7 mt-5">
        {weekDates.map((date, index) => (
          <div
            key={index}
            className={`border border-E8E9EA p-4 hover:border-b-2 hover:border-b-red-600 relative ${date.getDay() === 0 ? 'bg-EDEDED border border-E8E9EA' : ''}`}
          >
            <i className={`border-y-[25px] border-transparent border-r-[25px] border-r-2999BC w-0 h-0 rotate-45 absolute top-[-16px] left-[-4px] text-2999BC ${date.getDate() !== value.getDate() && 'hidden'}`}></i>
            
            <div className="flex justify-between mb-3">
              <span>{date.getDate()}</span>
              <span>
                {/* <button onClick={() => togglePopup(index)}> */}
                <button >
                  <i className="bi bi-plus-circle text-606061"></i>
                </button>
              </span>
            </div>
            {/* {popupStates[index] && (
              <ClickAwayListener onClickAway={() => closePopup(index)}>
                <div className="popup">
                  <div className="absolute w-48 top-0 right-0 mt-8 bg-white border border-gray-300 shadow-md">
                    <div className="flex items-center hover:bg-gray-100 p-2 cursor-pointer">
                      <svg xmlns="=" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit mr-2 text-indigo-600"></svg>
                      Meeting
                    </div>
                    <div className="flex items-center hover:bg-gray-100 p-2 cursor-pointer">
                      <svg xmlns="=" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit mr-2 text-indigo-600"></svg>
                      Follow Up
                    </div>
                    <div className="flex items-center hover:bg-gray-100 p-2 cursor-pointer">
                      <svg xmlns="=" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit mr-2 text-indigo-600"></svg>
                      Appointment
                    </div>
                    <div className="flex items-center hover:bg-gray-100 p-2 cursor-pointer">
                      <svg xmlns="=" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit mr-2 text-indigo-600"></svg>
                      Call
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            )} */}
            <div className={`text-2C495D content-end bg-F5F0E9 border border-2c495d33 text-xs w-full p-2 h-20 ${date.getDay() === 0 && 'hidden'}`} onClick={eventClick}>
              <div className="text-cyan-600 text-xs font-normal">Kuldeep</div>
              <div className="flex gap-1 mt-5">
                <div>Meeting</div> | <div>High</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-between py-2">
       <button> 
        <div className="gap-2 flex text-center text-slate-700 text-base font-normal font-['Poppins'] uppercase">
         <div><i className="bi bi-chevron-left  text-gray-400 font-bold"></i></div>
          Previous 1 week 
        </div>
        </button>
      <button>
        <div className="gap-2 flex text-center text-slate-700 text-base font-normal font-['Poppins'] uppercase">
          Next 1 week 
        <div><i className="bi bi-chevron-right text-gray-400 "></i></div>
        </div>
      </button>
       
</div>
    </Fragment>
  );
};

export default WeekCalendar;
