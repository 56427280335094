import React, { useState } from 'react';

const CheckBox1 = ({ Item, UpdateCheckBox }) => {
  const [checked, setChecked] = useState(parseInt(Item.status.value) ? true : false);

  const handleChange = (e) => {
    setChecked(e.target.checked);
    UpdateCheckBox(e);
  };

  return (
    <div className="checkbox-wrapper-20">
      <div className="switch">
        <input 
          id={`${Item.id.value}`} 
          className="input" 
          type="checkbox" 
          value={0} 
          checked={checked} 
          onChange={handleChange} 
        />
        <label htmlFor={`${Item.id.value}`} className="slider"></label>
      </div>
    </div>
  );
};

export default CheckBox1;
