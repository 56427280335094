import React, { useState, useEffect, Fragment } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFetchCompanyCommonDataQuery } from "../../utils/company/companySelector";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import { EDITE_DYNAMIC_VALUE } from "../../utils/constants";
import { useAddUpdateDynamicListMutation } from "../../utils/customer/customerTaskTable";

function camelToSentenceCase(camelCaseString) {
    const words = camelCaseString.split(/(?=[A-Z])/);
    const sentenceCaseString = words.join(' ').charAt(0).toUpperCase() + words.join(' ').slice(1);
    return sentenceCaseString;
  }
const validationRules = {
  currency_name: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  symbol: {
    required: true,
    errorMessage: "Cannot be left blank",
  },

  country_name: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
};

const validationCountryRules={
    iso: {
        required: true,
        errorMessage: "Cannot be left blank",
      },
    name: {
    required: true,
    errorMessage: "Cannot be left blank",
    },
}

const validationDepartmentRules={
    department: {
        required: true,
        errorMessage: "Cannot be left blank",
      },
}

const validationDesignationRules={
    designation: {
        required: true,
        errorMessage: "Cannot be left blank",
      },
}

const validationBusinessCategoriesRules={
  name: {
      required: true,
      errorMessage: "Cannot be left blank",
    },
}
const validationcompanygroupsRules={
  company_group: {
      required: true,
      errorMessage: "Cannot be left blank",
    },
}

const AddCurrency = ({
  closePopup,
  dataName,
  currencyId,
  tableId,
  handelsave,
}) => {
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [selectedItem, setSelectedItem] = useState(null);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const initialFormData = {
    currency_name: "",
    country_name:"",
    symbol: ""
  }
  const initialCountry = {
        iso: "",
        name: "",
  }
 const initialDepartments = {
    department: "",
 }
 const initialDesignation = {
    designation: "",
 }

 const initialBusinessCategories = {
  name: "",
}

const initialcompanygroups = {
  company_group: "",
}

  const dataForm = {
    currency: initialFormData,
    country: initialCountry,
    departments: initialDepartments,
    business_categories:initialBusinessCategories,
    company_groups:initialcompanygroups
  }[dataName] || initialDesignation;

  console.log("dataName",dataName)
 
  const [formData, setFormData] = useState(dataForm);
  
 
  
  const fetchData = async (currencyId, tableId) => {
    const url = `${EDITE_DYNAMIC_VALUE}${currencyId}?getId=${tableId}`;
    const getData = await getCommonDataBasedOnId(url, token);
     setFormData(getData[0]);
  };
  
  useEffect(() => {
    if(currencyId){
        fetchData(currencyId, tableId);
    }
   
  }, [currencyId, tableId]);
 
  const validation = {
    currency: validationRules,
    country: validationCountryRules,
    departments: validationDepartmentRules,
    business_categories:validationBusinessCategoriesRules,
    company_groups:validationcompanygroupsRules
  }[dataName] || validationDesignationRules;
 
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateField,
    isFormValid,
  } = useCustomFormValidation(formData, validation);

  const handleFieldChange = (e) => {
    handleChange(e);
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
};
 


  const { data: companyCommnData } = useFetchCompanyCommonDataQuery(token);

  const [AddUpdateDynamicList, { isLoading, isError, isSuccess, error }] =
    useAddUpdateDynamicListMutation();

  const handleSubmitCurrency = async (e) => {
    e.preventDefault();
    
    const formValid = isFormValid();
    if (!formValid) {
        return;
      }
   
    try {
   
      const data = {
        ...formData,
        id: currencyId,
        tableId: tableId,
      };

      const response = await AddUpdateDynamicList({ token, data });

      if (response?.data?.status === true) {
        setAlert({
          visible: true,
          message: response?.data?.message,
          type: "success",
        });
        handelsave();
        closePopup();
      } else if (response?.error) {
        setAlert({
          visible: true,
          message: response?.error?.data?.message,
          type: "danger",
        });
      }
    } catch (err) {
      console.error("Error while adding/updating user:", err);
    }
  };

  const removeUnderscores = (str) => {
    console.log(str, 300);
    if(str !=''){
      return str.replace(/_/g, ' ');
    }
  };

  const getPopWidth = (dataName) => {
    let width = null;  // Use let instead of const for width to allow reassignment  
    if (dataName) {
      if (dataName === "currency") {
        width = '300px';
      } else if (dataName === "country") {
        width = '400px';
      } else if (dataName === "departments") {
        width = '500px';
      } else if (dataName === "business_categories") {
        width = '600px';
      } else if (dataName === "company_groups") {
        width = '700px';
      }
    }
  
    return width;
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-gray-900 bg-opacity-50 ">
      <div className="relative mt-18 bg-white font-popns drop-shadow-lg rounded-md overflow-hidden no-scrollbar">
        <div className=" flex items-center justify-between  px-2 py-1 w-[510px]  rounded-t">
          <h3 className="text-16 text-2C495D px-3 font-500 capitalize">{currencyId ? "Update" : "Add"} {dataName && camelToSentenceCase(removeUnderscores(dataName))}
          </h3>
          <button
            onClick={closePopup}
            type="button"
            className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only" onClick={closePopup}>
              Close modal
            </span>
          </button>
        </div>

        <form
          onSubmit={handleSubmitCurrency}
          className={`w-full lg:max-h-[85vh] overflow-auto `}
        >
          {dataName && dataName === "currency" ? (
            <Fragment>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-6">
              <div className="basis-5/5 mb-3">
                  <label htmlFor="contact_name" className="block mb-2">
                    <span className="font-medium	text-sm text-434852">
                      Country
                    </span>
                    {" "}
                  </label>
                  <select
                    id="country_name"
                    name="country_name"
                    className="border border-gray-300 text-gray-900 text-xs block w-full px-2.5 py-0.5"
                    onChange={handleFieldChange}
                  >
                    {companyCommnData?.data?.country &&
                      companyCommnData?.data?.country.map((CountryData) => (
                        <option
                          key={CountryData.id}
                          value={CountryData.country_name}
                          selected={
                            formData.country_name.toLowerCase() ===
                            CountryData.country_name.toLowerCase()
                          }
                        >
                          {CountryData.country_name}
                        </option>
                      ))}
                  </select>
                  {errors.country_name && (
                    <span className="text-red-500 text-xxs">
                      {errors.country_name}
                    </span>
                  )}
                </div>
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="currency_name"
                    className="block mb-2"
                  >
                    <span className="text-sm font-medium text-434852">
                      Currency {" "}                      
                    </span>
                  </label>
                  <input
                    type="text"
                    id="currency_name"
                    name="currency_name"
                    placeholder="Enter Currency"
                    onChange={handleFieldChange}
                    value={formData.currency_name}
                    className="bg-white p-1 px-2 rounded border border-BFBFBF focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full"
                  />
                  {errors.currency_name && (
                    <span className="text-red-500 text-xxs">
                      {errors.currency_name}
                    </span>
                  )}
                </div>
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="symbol"
                    className="block mb-2"
                  >
                    <span className="text-sm font-medium text-434852">
                      Symbol
                    </span>
                    {" "}
                  </label>
                  <input
                    type="text"
                    id="symbol"
                    name="symbol"
                    value={formData.symbol}
                    placeholder="Enter symbol"
                    onChange={handleFieldChange}
                    className="bg-white p-1 px-2 rounded border border-BFBFBF focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full"
                  />
                  {errors.symbol && (
                    <span className="text-red-500 text-xxs">{errors.symbol}</span>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
                
              </div>
            </Fragment>
          ) : dataName && dataName === "country" ? (
            <Fragment>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="iso"
                    className="block mb-2 text-lg text-434852"
                  >
                    <span className="text-sm font-medium text-434852">
                      ISO {" "}
                    </span>
                  </label>
                  <input
                    type="text"
                    id="iso"
                    name="iso"
                    placeholder="Enter iso"
                    value={formData.iso}
                    onChange={handleFieldChange}
                    className="bg-white p-1 px-2 rounded border border-BFBFBF focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full"
                  />
                  {errors.iso && (
                    <span className="text-red-500 text-xxs">{errors.iso}</span>
                  )}
                </div>
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-lg text-434852"
                  >
                    <span className="text-sm font-medium text-434852">
                      Country
                    </span>
                    {" "}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    placeholder="Enter country name"
                    onChange={handleFieldChange}
                    className="bg-white p-1 px-2 rounded border border-BFBFBF focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-xxs">{errors.name}</span>
                  )}
                </div>
              </div>
            </Fragment>
          ) : dataName && dataName === "departments" ? (
            <Fragment>
              <div className="grid grid-cols-1 md:grid-cols-1 px-4">
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="iso"
                    className="block mb-2 text-lg text-434852"
                  >
                    <span className="text-sm font-medium text-434852">
                      Department {" "}
                    </span>
                  </label>
                  <input
                    type="text"
                    id="department"
                    name="department"
                    value={formData.department}
                    placeholder="Enter department"
                    onChange={handleFieldChange}
                    className="bg-white p-1 px-2 rounded border border-BFBFBF focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full"
                  />
                  {errors.department && (
                    <span className="text-red-500 text-xxs">
                      {errors.department}
                    </span>
                  )}
                </div>
              </div>
            </Fragment>
          ) :dataName && dataName === "business_categories" ? (
            <Fragment>
              <div className="grid grid-cols-1 md:grid-cols-1 px-4">
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="iso"
                    className="block mb-2 text-lg text-434852"
                  >
                    <span className="text-sm font-medium text-434852">
                      Name {" "}
                    </span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    placeholder="Enter name"
                    onChange={handleFieldChange}
                    className="bg-white p-1 px-2 rounded border border-BFBFBF focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-xxs">
                      {errors.name}
                    </span>
                  )}
                </div>
              </div>
            </Fragment>
          ): dataName && dataName === "company_groups" ? (
            <Fragment>
              <div className="grid grid-cols-1 md:grid-cols-1 px-4">
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="iso"
                    className="block mb-2 text-lg text-434852"
                  >
                    <span className="text-sm font-medium text-434852">
                      Gruop Name {" "}
                    </span>
                  </label>
                  <input
                    type="text"
                    id="company_group"
                    name="company_group"
                    value={formData.company_group}
                    placeholder="Enter group name"
                    onChange={handleFieldChange}
                    className="bg-white p-1 px-2 rounded border border-BFBFBF focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full"
                  />
                  {errors.company_group && (
                    <span className="text-red-500 text-xxs">
                      {errors.company_group}
                    </span>
                  )}
                </div>
              </div>
            </Fragment>
          ):(
            <Fragment>
              <div className="grid grid-cols-1 md:grid-cols-1 px-5">
                <div className="basis-5/5 mb-3 border-t border-BECBD3">
                  <label
                    htmlFor="designation"
                    className="block mb-1 text-lg text-434852 pt-1"
                  >
                    <span className="text-sm font-medium text-434852">
                      Designation {" "}
                    </span>
                  </label>
                  <input
                    type="text"
                    id="designation"
                    name="designation"
                    value={formData.designation}
                    placeholder="Enter designation"
                    onChange={handleFieldChange}
                    className="bg-white p-1 px-2 rounded border border-BFBFBF focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full"
                  />
                  {errors.designation && (
                    <span className="text-red-500 text-xxs">
                      {errors.designation}
                    </span>
                  )}
                </div>
              </div>
            </Fragment>
          )}
          <div className="sticky bottom-0 pr-3 flex justify-end pb-3 text-md w-full rounded-none z-[9998]">
            <div className="px-2">
              <button
                onClick={closePopup}
                type="button"
                className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-normal px-5 py-1 text-center rounded-5px"
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-normal border border-gray-200 py-1 px-5 ms-3 rounded-5px"
              >
                SAVE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddCurrency;
