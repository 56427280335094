import React ,{useState,useEffect} from 'react'
import FirstMessage from '../GlobalFeature/MessageAlert/FirstMessage';
import CustomerListTable from '../customer/CustomerListTable';
import useRequireAuth from '../../utils/useRequireAuth';
import AddUpdateVendorContact from './AddUpdateVendorContact';
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import { useDispatch } from 'react-redux';
import { deleteVendorContact } from '../../utils/vendor/vendorContactSlice';
import VendorContactHeader from './VendorContactHeader';
import { useFetchVendorContactQuery } from '../../utils/customer/customerTaskTable';
import { useParams } from 'react-router-dom';
import { useUpdateVendorContactMutation } from '../../utils/customer/customerTaskTable';


const VendorContactPerson = () => {
  const {token}=useRequireAuth();
  const [initialFilter,setInitialFilter]=useState([]);
  const [isPopupOpen,setIsPopupOpen]=useState(false);
  const [customer,setCustomer]=useState([]);
  const [customerTablePagination,setCustomerTablePagination]=useState(null);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const dispatch=useDispatch();
  const {id:vendorId}=useParams();
  
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  
  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };
  const openPopup=()=>{
    setIsPopupOpen(true);
  }

  const closePopup=()=>{
    setIsPopupOpen(false)
  }

  const handleDelete=(rowKey,item)=>{
    const id=item?.customer_id?.value;
    dispatch(deleteVendorContact({id,token}));
    refetchCustomerData();
  }

  const { data: customerTableALL, refetch: refetchCustomerData } =
  useFetchVendorContactQuery({
    vendorId: vendorId,
    pagination: customerTablePagination,
    token: token,
  });

  useEffect(() => {
    if (customerTableALL) {
      setCustomer(customerTableALL?.data?.data);
      const { data: omitData, ...restData } = customerTableALL && customerTableALL?.data;
      setInitialFilter(restData);
    }
  }, [customerTableALL, customerTablePagination]);


  const [updateVendorContact, { CustomerDropLoad, CustomerDroperror, }] = useUpdateVendorContactMutation();

  const customerTableFieldUpdate = async (field_name,value,id) => {
    const updateData = {
      field_name: value?.field_name,
      value: field_name?.value,
      id: value?.id
    }

    try {
      const result = await updateVendorContact({ token: token, data: updateData }).unwrap();
      if(result){
        refetchCustomerData();
        {result?.data?.original ?  setAlert({ visible: true, message: result?.data?.original?.error, type: 'danger' }) : setAlert({ visible: true, message: result?.message, type: 'success' })} 
      }
    } catch (error) {
      setAlert({ visible: true, message: 'Error updating data!', type: 'danger' });
    }
  }
  return (
    <>
    <div className="tablemaindiv">
      <div className={`${isFullscreen ? "bg-white h-full px-5 pt-2" : ""}`}>
    
        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />
          <CustomerListTable
            tableDetail={{
              tableidclass: "fullscreen-table",
              tableparentidclass: "tableparent",
              tablename: <div className="text-203C50 text-20 font-600 py-3">Contact Person</div>,
              tablenameclass:
                "font-medium text-16 text-203C50 capitalize",
              tableSearch: true,
              tableFilter: true,
              tableResizer: true,
              tableZoom: true,
              tableheader: false,
              dataactivity:true,
              datetedata:true,
              addbutton: (
                <button 
                   onClick={openPopup}
                   type="button"
                   className="text-2541558A hover:text-254155"
                >
                     <i className="bi bi-plus-lg"></i>
                </button>
            )
            }}
            thead={VendorContactHeader}
            tbody={customer}
            tablePaginationData={setCustomerTablePagination}
            TableFieldUpdate={customerTableFieldUpdate}
            initialFilter={initialFilter}
            handleDelete={handleDelete}
          />
          {isPopupOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-[102]">
              <AddUpdateVendorContact
                onClose={closePopup}
                refetchContact={refetchCustomerData}
              />
            </div>
          )}
      </div>
    </div>
  </>
  )
}

export default VendorContactPerson;
