// CustomerTable.js
import React, { useState, useEffect } from 'react';
import { tableData } from './data';

const CustomerTable = ({ activeTab }) => {
  const [data, setData] = useState(tableData);

  // Fetch data from the backend and update the state (not needed in this case)
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('/api/table-data');
  //       const data = await response.json();
  //       setData(data);
  //     } catch (error) {
  //       console.error('Error fetching table data:', error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  return (
    <div id="default-styled-tab-content">
      {activeTab === 'overview' && (
        <div className="" id="styled-overview" role="tabpanel" aria-labelledby="overview-tab">
          <table className="bg-white min-w-full border border-neutral-200 text-center text-sm text-surface text-2C495D font-poppins">
            <thead className="border-stone-300 border bg-slate-200">
              <tr>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Customer / Contact--overview
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Email / Phone
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Banking
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Payroll Alerts
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Prep For Taxes
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Tax Return
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="border">
              {data.map((row, index) => (
                <tr key={index} className="border border-neutral-200 ">
                  <td className="whitespace-nowrap border-b border-e border-s border-neutral-200 text-2C495D px-6 py-2 font-normal text-left">{row.customer}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.contact}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.banking}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.payrollAlerts}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.prepTaxes}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.taxReturn}</td>
                  {/* Other table cells */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {activeTab === 'bookkeeping' && (
        <div className="" id="styled-overview" role="tabpanel" aria-labelledby="overview-tab">
          <table className="bg-white min-w-full border border-neutral-200 text-center text-sm text-surface text-2C495D font-poppins">
            <thead className="border-stone-300 border bg-slate-200">
              <tr>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Customer / Contact
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Email / Phone
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Banking
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Payroll Alerts
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Prep For Taxes
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Tax Return
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="border">
              {data.map((row, index) => (
                <tr key={index} className="border border-neutral-200 ">
                  <td className="whitespace-nowrap border-b border-e border-s border-neutral-200 text-2C495D px-6 py-2 font-normal text-left">{row.customer}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.contact}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.banking}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.payrollAlerts}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.prepTaxes}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.taxReturn}</td>
                  {/* Other table cells */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {activeTab === 'payroll' && (
        <div className="" id="styled-overview" role="tabpanel" aria-labelledby="overview-tab">
          <table className="bg-white min-w-full border border-neutral-200 text-center text-sm text-surface text-2C495D font-poppins">
            <thead className="border-stone-300 border bg-slate-200">
              <tr>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Customer / Contact--payroll
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Email / Phone
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Banking
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Payroll Alerts
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Prep For Taxes
                  </div>
                </th>
                <th scope="col" className="font-normal px-6 py-3 text-left border-stone-300 ">
                  <div className="flex justify-between text-slate-700 text-base font-medium ">
                    Tax Return
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="border">
              {data.map((row, index) => (
                <tr key={index} className="border border-neutral-200 ">
                  <td className="whitespace-nowrap border-b border-e border-s border-neutral-200 text-2C495D px-6 py-2 font-normal text-left">{row.customer}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.contact}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.banking}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.payrollAlerts}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.prepTaxes}</td>
                  <td className="whitespace-nowrap border-b border-e border-neutral-200 px-6 py-2 text-2C495D font-normal text-left">{row.taxReturn}</td>
                  {/* Other table cells */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CustomerTable;