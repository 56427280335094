import { Fragment, React, useState, useEffect } from "react";

import PermissionTable from "../Permission/PermissionTable";
import LayoutHOC from "../LayoutHOC";
import GroupsTable from "../Groups/GroupsTable";
import PermissionLevel from "../Permission/PermissionLevel";
import AddPermission from "../Permission/AddPermission";
import { useFetchPermissionTableDataQuery } from '../../utils/users/permissions';
import useRequireAuth from '../../utils/useRequireAuth';
import CustomerListTable from '../customer/CustomerListTable';
import PermissionHeader from "../Permission/PermissionHeader";

const CustomerManagePermission = () => {
  const [group, setGroup] = useState(null);
  const [permission, setPermission] = useState(null);
  const [permissionTablePagination, setPermissionTablePagination] = useState("");  
  const [initialFilter, setInitialFilter] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(null);

  const {token} = useRequireAuth();
  const { data: permissionTableALL, refetch: refetchPermissionData } =
    useFetchPermissionTableDataQuery({       
      pagination: `${permissionTablePagination}`,
      token: token,
    });

  useEffect(() => {
    if (permissionTableALL && permissionTableALL.data) {
      const { data, ...restData } = permissionTableALL.data;
      setPermission(data);
      setInitialFilter(restData);
    }
  }, [permissionTableALL, permissionTablePagination, refetchPermissionData]);

  const handleActionEdit = (data) => {
    setIsPopupOpen(data);
  };

  const handleNavigate = (data, items, header, taskid) => {
    if (items[header?.key]?.field_name === "group_name") {
      setGroup(items);
      setPermission(null);
    } else if (items[header?.key]?.field_name === "permission") {
      setPermission(items);
      setGroup(null);
    }
  };

  return (
    <div className="m-5">
      {/* <div className="">
        {group?.group_name ? (
          <Fragment>
            <div className="flex absolute">
              <button
                className="text-1D4469 text-18 font-600"
                onClick={() => {
                  setGroup(null);
                }}
              >
                <i className="bi bi-arrow-left"></i>
                <span className="ms-2">{group?.group_name?.value}</span>
              </button>
            </div>
            <GroupsTable
              handleNavigates={handleNavigate}
              TableNav={TableNav}
              groupid={group}
            />
          </Fragment>
        ) : permission?.permission ? (
          <Fragment>
            <PermissionLevel
              handleNavigate={handleNavigate}
              TableNav={TableNav}
              ButtonEvent={() => {
                setPermission(null);
              }}
              PermissionLevelId={permission}
            />
          </Fragment>
        ) : (
          <CustomerListTable
            tableDetail={{
              tableidclass: "fullscreen-table",
              tableparentidclass: "tableparent",
              tablename: TableNav(),
              tablenameclass: "font-medium text-16 text-203C50 capitalize",
              tablename: "Manage Permission",
              tableSearch: true,
              tableFilter: true,
              forcefullyfreeze: true,
              tableResizer: false,
              tableZoom: true,
              tableheader: false,
              dataactivity: true,
              headeraddfield: false,
              addbutton: (
                <Fragment>
                  <button
                    onClick={() => setIsPopupOpen(true)}
                    type="button"
                    className="text-2541558A hover:text-254155"
                  >
                    <i className="bi bi-plus-lg"></i>
                  </button>
                  <AddPermission
                    refetchData={refetchPermissionData}
                    setIsPopupOpen={setIsPopupOpen}
                    isPopupOpen={isPopupOpen}
                  />
                </Fragment>
              ),
              actionfield: (data) => (
                <Fragment>
                  <td>
                    <div
                      className="text-left"
                      style={{ paddingLeft: "20px" }}
                    >
                      <button
                        onClick={() => handleActionEdit(data)}
                        className="text-F84545 text-15"
                      >
                        <i className="bi bi-pencil"></i>
                      </button>
                    </div>
                  </td>
                </Fragment>
              ),
            }}
            thead={PermissionHeader}
            tbody={permission}
            tablePaginationData={setPermissionTablePagination}
            initialFilter={initialFilter}
            navigateNameCondition={["group_name", "permission"]}
            handleNavigate={handleNavigate}
          />
        )}
      </div> */}
    </div>
  );
};

export default LayoutHOC(CustomerManagePermission);
