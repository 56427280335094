import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  saveData,
  getCommonDataBasedOnId
} from "../../components/store/customer/CustomerSocialAddUpdateFunction";
import {
  CUSTOMER_TASK_ADD_UPDATE,
  DELETE_SOCAIL_Vendor_LINK,
  ADD_UPDATE_VENDOR_ADDRESS

} from "../constants";

export const saveVendorAddress = createAsyncThunk(
  "vendorAddress/saveVendorAddress",
  async ({ vendorAddress, token }, thunkAPI) => {
    
    try {
      const response = await saveData(
        ADD_UPDATE_VENDOR_ADDRESS,
        vendorAddress,
        token
      );
      if (response.status === true) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);




export const deleteVendorSocail = createAsyncThunk(
  "vendorAddress/deleteVendorSocail",
  async ({ id, token }, thunkAPI) => {
    try {
      const response = await deleteData(
        `${DELETE_SOCAIL_Vendor_LINK}${id}`,
        token
      );

      if (response?.status === true) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);

const vendorSocialSlice = createSlice({
  name: "vendorAddress",
  initialState: {
    loading: false,
    vendorAddress: [],
    error: null,
    showMessage: false,
    message: null,
  },
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    setVendor: (state, action) => {
      state.vendors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveVendorAddress.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(saveVendorAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.vendors = action.payload;
        state.error = "";
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(saveVendorAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteVendorSocail.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteVendorSocail.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(deleteVendorSocail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { showMessage, hideMessage, setVendor } = vendorSocialSlice.actions;
export default vendorSocialSlice.reducer;
