import React from "react"
import LayoutHOC from "../LayoutHOC";
import SubNav from "./SubNav";


const page = ()=>{
return(
    <>
    {/* 
    <Leftmenu/>
    <SubNav/>
    <Footer/> */}
    
                    <div className={`p-4 mt-14`}>
                    <div className={`flex flex-col bg-white p-2 mb-5 py-3 shadow-b-lg`}>
                            <div className={`flex gap-5 text-base items-center px-6`}>
                                <h5 className={`text-203C50 font-Vietnam text-[24px] font-medium`}>GFPO USA</h5>
                                <p className="text-base font-medium">Shashi's Customer</p>
                                {/* <div className={`flex items-center`}>
                                </div> */}
                            </div>
                        </div> 

                          <SubNav/>
                    </div>

    </>
)
}
export default LayoutHOC(page)