import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

const FirstMessage = ({ type, message, visible, onHide }) => {
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        onHide();
      }, 2000); // Auto-hide after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [visible, onHide]);

  const typeClasses = {
    success: 'bg-green-500 text-white',
    danger: 'bg-red-500 text-white',
    info: 'bg-blue-500 text-white',
    warning: 'bg-yellow-500 text-white',
  };

  return (
    <CSSTransition
      in={visible}
      timeout={{ enter: 1000, exit: 1000 }} /* 2 seconds to enter, 1 second to exit */
      classNames="snackbar"
      unmountOnExit
    >
      <div className="fixed top-4 right-4 z-[9999999999999]">
        <div
          className={`inline-flex items-center bg-green-500 leading-none rounded-[5px]   p-2  shadow text-sm ${typeClasses[type]}`}
        >
          <span
            className={`inline-flex  h-6 pl-[10px] pr-0  justify-center items-center ${typeClasses[type]}`}
          >
            {type?.charAt(0)?.toUpperCase() + type?.slice(1)}
           
          </span>
          {/* <span className="inline-flex px-1 text-white">{message}</span> */}
        </div>
      </div>
    </CSSTransition>
  );
};

export default FirstMessage;
