import { useEffect, useState } from "react";
import useRequireAuth from "../useRequireAuth";
import { COMPANY_PROFILE_DETAIL } from "../constants";
import axios from "axios";
// const useCompanyEditData = (company_profile_id) => {
//     const user = useRequireAuth();
//     const [companyData, setCompanyData] = useState(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const Authtoken = user.token;
//                 const url = `${COMPANY_PROFILE_DETAIL}?profile_id=${encodeURIComponent(company_profile_id)}`;
        
//                 const response = await axios.get(url, {
//                     headers: {
//                         Authorization: `Bearer ${Authtoken}`,
//                         'Content-Type': 'application/json'
//                     }
//                 });
        
//                 const data = response.data;
//                 setCompanyData(data);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };
        
//         fetchData();

//     }, [user.token, company_profile_id]);

//     return companyData;
// };

const useCompanyEditData = (company_profile_id) => {
    const user = useRequireAuth();
    const [companyData, setCompanyData] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const { token } = user;
          const url = `${COMPANY_PROFILE_DETAIL}?profile_id=${encodeURIComponent(company_profile_id)}`;
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          });
          setCompanyData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [user.token, company_profile_id]);
  
    return companyData;
  };

export default useCompanyEditData;
