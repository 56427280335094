import React, { Fragment, useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  UPDATE_SAVE_OTHERDETAIL_DATA,
  CUSTOMER_OTHERDETAIL_DYNAMIC_ADD_UPDATE,
} from "../../utils/constants";
import useRequireAuth from "../../utils/useRequireAuth";
import SaveEdit from "./SaveEdit";
import {
  useFetchCustomerEditQuery,
  useUpdateSaveOtherDetailMutation,
} from "../../utils/customer/customerSelect";
import {useCustTableAddFieldHeaderMutation, useCustTableUpdateFieldHeaderMutation, useCustomerTableMutation, useLazyFetchCustFieldHdrQuery, useLazyFetchCustomerTableQuery, useLazyFetchCustomerTableSecondDataQuery, useLazyFetchPersonInTableQuery, useUpdateCustomerTableDataMutation} from '../../utils/customer/customerTable'
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import FullScreenMode from "../GlobalFeature/Table/HeadTop/FullScreenMode";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import ExtraFieldAdd from "../GlobalFeature/Table/ExtraFieldAdd";
import useClickOutside from "../../hooks/Screen/useClickOutside";
import handlePostApi from "../../utils/api/postApiSlice";
import { useFetchCustomerHeaderQuery } from "../../utils/customer/customerListHeader";
import RenderElements from "../GlobalFeature/Others/RenderElements";
import { parseJSONValue } from "../../utils/json/parseJSONValue";
import useDataInputHandler from "../../hooks/Table/useTableTbodyFire";
import Skeleton from "react-loading-skeleton";
import KeyValueSkeleton from "../GlobalFeature/Others/KeyValueSkeleton ";
import EmailHoverElement from "../GlobalFeature/Others/EmailHoverElement";
import TextHoverElement from "../GlobalFeature/Others/TextHoverElement";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const getValueFromFunc=(value)=>{
  if(typeof value === "object" && value !== null){
    return value.value
  }
  return <div className="min-h-[20px] w-full">{value}</div>

}
const CustomerOtherDetail = () => {
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const storedData = localStorage.getItem("company_data");
  const companyId = storedData ? JSON.parse(storedData) : null;
  const { isFullscreen, toggleFullscreen,isTableMainDivPresent } = useFullscreen();
  const [dataInputRow, setDataInputRow] = useState(null);
  const [clientCoord,setClientCoord]=useState({})
 
  const [extraField, setExtraField] = useState(false);
  const [editFieldData, setEditFieldData] = useState(null);
  
  const modalRef = useClickOutside(() => setExtraField(false));
 
  const handleMenuToggle = () => {
    setExtraField(!extraField);
    setEditFieldData(null);
  };
  const { id } = useParams();
  const customerId = parseInt(id);
  const { token } = useRequireAuth();

  const [
    CustomerTableDrop,
    { data: customerCommnData },
  ] = useCustomerTableMutation();

  
  const fetchCustomerTable = (data) => {
    const completedata = {
      "model_name": "customers",
      "field_name": data,
    };    
    CustomerTableDrop({token:token,data:completedata});
  }


  const [
    fetchPersonInTable,
    { data: personTableData },
  ] = useLazyFetchPersonInTableQuery();

  const fetchPersonData = (data) => {
    fetchPersonInTable({token: token,data:data})
  }

  const [fetchCustomerGetAddField, { data: CustomerGetAddField }] =
    useLazyFetchCustFieldHdrQuery();
  useEffect(() => {
    setEditFieldData(CustomerGetAddField);
  }, [CustomerGetAddField]);
  const EditExtraField = (e,headeritem) => {
    fetchCustomerGetAddField({ token: token,data:headeritem})
    setExtraField(true);
  };
  const [editModelStatus, setEditModelStatus] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [tablecellInput, setTablecellInput] = useState("");
  const { handleDataInput } = useDataInputHandler(
    fetchCustomerTable,
    setDataInputRow,
    setClientCoord
  );
  const handleOtherDetailChange = (header, item, columnKey,event) => {
    if (!dataInputRow?.rowIndex) {
      handleDataInput(header, item, columnKey,event);
    }
  };
 
  const {
    data: customerEditData,
    refetch: customerEditDataRefetch,
    isLoading,
  } = useFetchCustomerEditQuery({ token, customerId });

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    }
  }, [isLoading]);
  

  useEffect(() => {
    if (customerId && token) {
      customerEditDataRefetch();
    }
  }, [customerId, token]);


  const dataitem = customerEditData?.data[0];

  const { data: customerListHeader, refetch } = useFetchCustomerHeaderQuery({
    token: token,
    id: companyId.company_id,
    model: "customer",
  });

  const mappedHeaders =
    customerListHeader?.map((headerItem) => ({
      key: Object.keys(headerItem)[0],
      label: headerItem[Object.keys(headerItem)[0]],
      statuskey: Object.keys(headerItem)[1],
      statuslabel: headerItem[Object.keys(headerItem)[1]],
      ...headerItem,
    })) || [];

  const [
    addFieldHeader,
    { headerExtraFieldAddLoad, headerExtraFieldAddError },
  ] = useCustTableAddFieldHeaderMutation();
  const [
    updateFieldHeader
  ] = useCustTableUpdateFieldHeaderMutation();
 
  const handleExtraFieldAddHeader = async (fielddata) => {
    // const Data = {
    //   company_id: companyId.company_id,
    //   ...fielddata,
    // };
 
    const modifiedFieldOptions = fielddata?.field_options?.map(option => {
      if (option?.color_code === null || option?.color_code === "") {
        const { color_code, ...rest } = option;
        return rest;
      }
      return option;
    }) || [];
  
    const Data = {
      company_id: companyId.company_id,
      ...fielddata,
      field_options: modifiedFieldOptions,
    };
 
 
  if(Data?.id && String(Data.id).trim() !== ""){
      try {
        const response = await updateFieldHeader({
          token: token,
          data: Data,
        }).unwrap();
        refetch()
        customerEditDataRefetch()
        setAlert({ visible: true, message: response?.message, type: 'success' });
      } catch (error) {
        console.error("Update Failed", error);
      }
    }
    else{
      await handlePostApi({
        AddField: addFieldHeader,
        Data: {
          token: token,
          data: Data,
        },
        LiveLoad: [refetch, customerEditDataRefetch],
        AlertMessage: setAlert,
        Loader: setLoading,
        Error: setError,
      });
    }
 
  };

  const EditFieldData = () => {};
  const tableDetail = {
    tableidclass: "customerotherdetail",
  };

  const tableCellChange = (e) => {
    setTablecellInput(e.target.value);
  };

  const [updateCustomerTableData] = useUpdateCustomerTableDataMutation();


//************** Please Consult Aakash before changing this section in Conflict cases***********************//

  const customerTableFieldUpdate = async (item,field_name) => {
    let value, data, person;
    if (Array.isArray(item) && typeof item !== 'string') {
      value = item.map((it) => it.value).join("; ");
      if (value.trim() !== '') {
        data = {
          value: value,
        };
      } else {
        data = '';
      }
    } 
    else if (Object?.keys(item).length >= 2) {
      value = item?.value || "";
      if (value) {
        data = {
          ...item,
        };
      } else {
        data = {
          value: item?.email || "",
          name: item?.name || "",
        };
      }
    }
    else if (typeof(item)==="object" && !Array.isArray(item)) {
      value = item?.value || "";
      if (value) {
        data = {
          ...item,
        };
      }
    }
    else{
          data = item?.value;
    }
  
    const updateData = {
      field_name: field_name?.field_name,
      value: data,
      id: field_name?.id,
    };
 
    try {
      const result = await updateCustomerTableData({
        token: token,
        data: updateData,
      }).unwrap();
      if (result?.status) {
        customerEditDataRefetch();
 
        setAlert({ visible: true, message: result?.message, type: "success" });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      setAlert({
        visible: true,
        message: "Error updating data!",
        type: "danger",
      });
    }
  };

//************** Please Consult Aakash before changing this section in Conflict cases***********************//

const updateTableField = (e, item, completedata) => {
    e.stopPropagation();

    customerTableFieldUpdate(item, completedata);
    setDataInputRow(null);
  };
 
  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 320,
      // minHeight:100,
      maxHeight:100,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
  return (
    <div  className="tablemaindiv bg-white pt-4 ">
      <FirstMessage
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onHide={hideAlert}
      />
      {successMessage && (
        <p className="text-green-500 text-right ">{successMessage}</p>
      )}
      <div className="flex justify-between pr-4 ">
        {/* <div className="focus:pb-2 px-3 block leading-normal text-20 font-600 font-Vietnam text-203C50 capitalize self-end ">
          <h3>Tracking</h3>
        </div> */}
        <div className="flex justify-between"><h4 className=" text-537596 text-20 capitalize pl-4 font-600 pb-[-5px]"> Tracking </h4></div>
        <div>
            <button
              onClick={handleMenuToggle}
              disabled={editModelStatus}
              className="justify-end px-4  transition"
            >
              <i className="bi bi-plus-lg"></i>
            </button>
            {isTableMainDivPresent &&
                    <FullScreenMode event={(e) => toggleFullscreen(e)}>
                      {isFullscreen ? (
                        <i className="bi bi-fullscreen-exit text-16"></i>
                      ) : (
                        <i className="bi bi-fullscreen text-xl text-16 text-284356"></i>
                      )}
                    </FullScreenMode>

            }

        </div>
      </div>
      {extraField && (
        <div className="modal">
          <div className="modal-content" ref={modalRef}>
            <ExtraFieldAdd
              setExtraField={setExtraField}
              ExtraFieldAddHeader={handleExtraFieldAddHeader}
              EditFieldData={editFieldData}
            />
          </div>
        </div>
      )}


      {extraField &&
 
      <div className="modal">
            <div className="modal-content"  ref={modalRef}> 
                <ExtraFieldAdd setExtraField={setExtraField} ExtraFieldAddHeader={handleExtraFieldAddHeader} EditFieldData={editFieldData}/>
            </div>
      </div> 
      }
 
 
        <form className="bg-white p-5 h-auto overflow-y-auto">
        <div className={`flex flex-wrap md:justify-between gap-[6px] ${tableDetail.tableidclass}`}  id={`${tableDetail.tableidclass}`}>     
        {loading ? (
            Array(8).fill().map((_, index) => <KeyValueSkeleton key={index} />)
          ) : (
            mappedHeaders && mappedHeaders?.map((header,index) => {
                    const key = header?.key;
           
                    if (dataitem && dataitem.hasOwnProperty(key)) {
                      const matchingData = dataitem[key];
                      const parsedvalue =  matchingData && parseJSONValue(matchingData)
                   
                      return(
                        <div className="w-[47%] h-[40px] mb-6 md:mb-0 relative flex items-center disp grid grid-cols-12"  key={index}>
                              <label
                                className="col-span-5 tracking-wide text-535252 text-[13px] labelhoverpencilshow capitalize"
                                htmlFor="status"
                              >
                                {matchingData?.field_lable}
                     
                                <button
                                  type="button"
                                  className="pencilicon ms-5"
                                  onClick={(e) => {
                                    EditExtraField(e,header);
                                  }}>
                                    <i className="bi bi-pencil"></i>
                                </button>
                              </label>
                               
                              <div className={`col-span-7 relative`}>                             
                                {dataInputRow && dataInputRow?.rowIndex === matchingData.field_lable ?
                                                          <RenderElements
                                                                                    tableDetail={tableDetail}
                                                                                    fetchPersonInTable={fetchPersonData}
                                                                                    Item={dataitem}
                                                                                    Header={header}
                                                                                    RowKey={matchingData?.field_lable}
                                                                                    CommonData={customerCommnData || personTableData}
                                                                                    CellChange={tableCellChange}  
                                                                                    CellInput={tablecellInput}
                                                                                    setDataRow={setDataInputRow}
                                                                                    DataRow={dataInputRow}
                                                                                    UpdateField={updateTableField}
                                                                                    clientCoord={clientCoord}
                                                                                    
                                                            />
                                                            :
                                                            <div className="clickopennow hovershowborder cursor-pointer accounting-ellipsis flex items-center"  

                                                                  onClick={(event) => {
                                                                      handleOtherDetailChange(matchingData.field_lable,dataitem,header?.key,event)
                                                                  }}>
 
                                                                   
                                                                  {(matchingData?.type === 'dropdown'  || matchingData?.type === 'Listbox' || matchingData?.type === 'person') ?
 
 
                                                                           (
                                                                            <div
                                                                            style={{
                                                                              backgroundColor: parsedvalue?.color_code,
                                                                              borderColor: parsedvalue?.color_code,
                                                                              padding:"0px 5px"
                                                                             
                                                                            }}
                                                                            className={`inline-block text-black  ${parsedvalue?.color_code && "border rounded-[5px]  accounting-ellipsis"}
                                                                         
                                                                            text-[12px] min-h-[20px] `}
                                                                         
                                                                         
                                                                           >
                                                                            {parsedvalue?.value || ""}
                                                                            </div>
                                                                           )
                                                                           :parsedvalue?.value?
                                                                           (
                                                                            <HtmlTooltip
                                                                              arrow
                                                                              disableHoverListener={!Boolean(getValueFromFunc(parsedvalue?.value || ""))}
                                                                              title={
                                                                                <React.Fragment>
                                                                                  <div className="max-w-[320px] overflow-auto  ">
                                                                                  {(parsedvalue?.value || "")}
                                                                                  </div>
                                                                                 
                                                                            
                                                                                </React.Fragment>
                                                                              }
                                                                            >
                                                                              <div
                                                                            className={`inline-block max-w-full w-full  overflow-hidden  accounting-ellipsis  text-black  ${parsedvalue?.color_code && "border rounded-[68px] "}
                                                                         
                                                                            text-[12px] `}
                                                                          
                                                                         
                                                                          >
                                                                            
                                                                            
                                                                            {getValueFromFunc(parsedvalue?.value || "")}
                                                                          </div>
                                                                            </HtmlTooltip>):(<div
                                                                            className={`inline-block max-w-full w-full  overflow-hidden  accounting-ellipsis  text-black  ${parsedvalue?.color_code && "border rounded-[68px] "}
                                                                         
                                                                            text-[12px] `}
                                                                          >
                                                                            {getValueFromFunc(parsedvalue?.value || "")}
                                                                          </div>)
                                                                            
                                                                 
                                                                  }
                                                             
 
                                                                {matchingData && matchingData?.type === 'person' &&
 
                                                                        (
                                                                          <EmailHoverElement
                                                                          ParentId={tableDetail}        
                                                                          Data={parsedvalue}      
                                                                          HoverActiveClass={"tablehoverelement"}        
                                                                          />  
                                                                        )
                                                               
                                                                }
 

                                                                           
                                                                            {/* {open && matchingData && matchingData?.type === 'text' && <Popover
                                                                              id={idNew}
                                                                              open={open}
                                                                              anchorEl={anchorEl}
                                                                              onClose={handleClose}
                                                                              anchorOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                              }}
                                                                            >
                                                                              <Typography sx={{ p: 2 }}>{getValueFromFunc(parsedvalue?.value)}</Typography>
                                                                            </Popover>} */}

                                                                
                                                                   
                                                               
                                                            </div>
 
                                                               
                                      } 
 
                              </div>
                        </div> 
                      )                
                   
                   
                   
                    }
                 
                    return null;
            })
 
        )} 


          
        </div>
      </form>
    </div>
  );
};

export default CustomerOtherDetail;