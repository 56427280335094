import React, { useState, useEffect } from 'react';
import useRequireAuth from '../../utils/useRequireAuth';
import { SAVE_BILLING,COMMON_URL} from '../../utils/constants';
import { useSelector } from 'react-redux';

export default function CompanyBilling({accounting , taxFormData}) {
    const user = useRequireAuth();
    const profile = useSelector(store => store.profile);    
    const company_profile_id =  profile.addProfile ? profile.addProfile.id : null; 

    const [billSubmitted, setBillSubmitted] = useState(false);
    const [successBillMessage, setSuccessBillMessage] = useState('');  
    const [errorMessage, setErrorMessage] = useState('');



    const [billingFormData, setBillingFormData] = useState({
        company_profile_id: company_profile_id,
        incom_tax_no: '',
        gst_no: '',  
        accounting_method_id: '1',  
        tax_form_id: '1',  
    });

    useEffect(() => {
        setBillingFormData(prevFormData => ({
            ...prevFormData,
            company_profile_id: company_profile_id
        }));
    }, [company_profile_id]);


    const handleInputBillingChange = (event) => {
        const { name, value } = event.target;
        setBillingFormData({
            ...billingFormData,
            [name]: value
        });
    };

    const handleBillSave = async () => {        

        try {
            const Authtoken = user.token;

            const formData = new FormData();
            formData.append('company_profile_id', billingFormData.company_profile_id);
            formData.append('incom_tax_no', billingFormData.incom_tax_no);
            formData.append('gst_no', billingFormData.gst_no); 
            formData.append('accounting_method_id', billingFormData.accounting_method_id); 
            formData.append('tax_form_id', billingFormData.tax_form_id);  
           
            const response = await fetch(SAVE_BILLING, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Authtoken}`,
                },
                body: formData,
            });

            const data = await response.json(); 
           
            setSuccessBillMessage(data.message);
            setBillSubmitted(true);
            setTimeout(() => {
              //  billSubmitted(false);
                setSuccessBillMessage('');
            }, 10000);
            // if(data.success == true){
            //     setBillSubmitted(true);
            // }
            
        } catch (error) {
            setBillSubmitted(false);
            setSuccessBillMessage('');
          //  console.log(error.response.data.message);
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred while submitting the form.');
            }
        }
    };

   
   
    return (
            <>
                <form id="companyBillingForm" encType="multipart/form-data" className='bg-white py-4'>
                    <div className="font-poppins grid grid-cols-3 px-8 py-3 pt-5 gap-16">
                        <div>
                            <label htmlFor="Income Tax No." className="block mb-1 text-535252 text-14 font-500">
                                Income Tax No.
                            </label>
                            <input
                                className="border border-gray-300 text-xs block w-full p-2.5 rounded-5px"
                                type="text"
                                name="incom_tax_no"
                                value={billingFormData.incom_tax_no}
                                onChange={handleInputBillingChange}
                                disabled={billSubmitted}
                            />
                        </div>
                        <div>
                            <label htmlFor="GST No." className="block mb-1 text-535252 text-14 font-500">
                                GST No.
                            </label>
                            <input
                                type="text"
                                name="gst_no"
                                value={billingFormData.gst_no}
                                onChange={handleInputBillingChange}
                                className="border border-gray-300 text-xs block w-full p-2.5 rounded-5px"
                                disabled={billSubmitted}
                            />
                        </div>
                        <div>
                            <label htmlFor="Accounting Method" className="block mb-1 text-535252 text-14 font-500">
                                Accounting Method
                            </label>
                            <select
                                name='accounting_method_id'
                                value={billingFormData.accounting_method_id}
                                onChange={handleInputBillingChange}
                                className="form-select text-xs block w-full px-2.5 text-gray-400"
                                disabled={billSubmitted}
                            >
                                <option >Select  Accounting Method</option>
                                {!accounting ? null : (
                                accounting.map((Accounting) => (
                                    <option key={Accounting.id} value={Accounting.id}>
                                    {Accounting.accounting_method_name}
                                    </option> 
                                ))
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="font-poppins grid grid-cols-3 px-8 py-3 pt-5 gap-16">
                        <div>
                            <label htmlFor="Tax Form" className="block mb-1 text-14 font-500 text-535252">
                                Tax Form
                            </label>
                            <select
                                name='tax_form_id'
                                value={billingFormData.tax_form_id}
                                onChange={handleInputBillingChange}
                                className="form-select text-xs block w-full px-2.5 text-gray-400"
                                disabled={billSubmitted}
                            >
                                <option >Select Tax Form</option>
                                {!taxFormData ? null : (
                                taxFormData.map((TaxFormData) => (
                                    <option key={TaxFormData.id} value={TaxFormData.id}>
                                    {TaxFormData.tax_form}
                                    </option> 
                                ))
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="flex font-poppins p-3 justify-end">
                        <div className="flex gap-4 pe-6">
                            {/* <button className="px-6 py-2 text-base rounded font-normal bg-F4F4F4 focus:outline-none" disabled={true}>
                                CANCEL
                            </button> */}
                            <button onClick={handleBillSave} type='button' className="px-6 py-1 text-base rounded font-normal bg-1D4469 text-white focus:outline-none pointer-events-none opacity-50" disabled={true}>
                                SAVE
                            </button>
                        </div>
                    </div>
                </form> 

                {billSubmitted && (
                    <div className='p-10 text-green-400'>{successBillMessage}</div>
                )}
 

                {errorMessage && (
                <div className='p-10 text-red-400'>{errorMessage}</div> )} 

            </>
    );
}
