import React, { useState, useEffect, Fragment } from "react";
import CustomerListTable from "../customer/CustomerListTable";
import AddUserModal from "./AddUserModal";
import { useFetchManageDataTableDataQuery } from "../../utils/customer/customerTaskTable";
import useRequireAuth from "../../utils/useRequireAuth";
import SideBar from "../Dashboard/SideBar";
import ManageListHeader from "./ManageListHeader";
import { useNavigate } from "react-router-dom";
import HeaderDashboard from "../HeaderDashboard";

const ManageList = () => {
  const [quiFilter, setQuiFilter] = useState("All");
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const [customer, setCustomer] = useState([]);
  const [initialFilter, setInitialFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useRequireAuth();
  const navigate = useNavigate();

  const TaskNavbarQuickFilter = (option) => {
    setQuiFilter(option);
  };

  const { data: customerTableALL, refetch: refetchCustomerData } =
  useFetchManageDataTableDataQuery({
      pagination: `${customerTablePagination}`,
      quiFilter: quiFilter,
      token: token,
    });

  useEffect(() => {
    if (customerTableALL) {
      const { data, ...restData } = customerTableALL?.data;
      setCustomer(data);
      setInitialFilter(restData);
      setLoading(false);
    }
  }, [customerTableALL, customerTablePagination, refetchCustomerData]);

  const handleNavigate = (taskid) => {
    navigate(`/dynamic/${taskid}`)
  };

  return (
    <Fragment>
      <HeaderDashboard />
      <div class="flex gap-10 px-5 mt-14">
        <div className="w-1/5">
          <SideBar />
        </div>
        <div className="w-9/12 mt-2"> 
          <CustomerListTable
            tableDetail={{
              tableidclass: "fullscreen-table",
              tableparentidclass: "tableparent",
              tablenameclass: "font-medium text-16 text-203C50 capitalize",
              tablename: "Manage List",
              tableSearch: true,
              tableFilter: true,
              forcefullyfreeze: true,
              tableResizer: false,
              tableZoom: true,
              tableheader: false,
              dataactivity: true,
              headeraddfield: false,
              rowDisable: true,
            }}
            thead={ManageListHeader}
            tbody={customer}
            tablePaginationData={setCustomerTablePagination}
            initialFilter={initialFilter}
            handleNavigate={handleNavigate}
            navigateNameCondition={["list_name"]}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ManageList;
