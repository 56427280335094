import CryptoJS from 'crypto-js'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL,ENUM_VALUE_GET } from './constants';

const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
};

export const EnumFieldGetApi = createApi({
  reducerPath: 'EnumFieldGetApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchEnumFieldGetApi: builder.query({
      query: ({ token, table_name }) => {
        const encryptedTableName = encryptData(table_name, token);

        return {
          url: `${ENUM_VALUE_GET}/${encryptedTableName}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
      },
    }),
  }),
});



export const {
    useFetchEnumFieldGetApiQuery,
  } = EnumFieldGetApi;