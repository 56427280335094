import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

function useDebouncedEffect(callback, delay, dependencies) {
  const callbackRef = useRef();
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const debouncedFunction = debounce(() => {
      if (callbackRef.current) {
        callbackRef.current();
      }
    }, delay);

    debouncedFunction();
    return () => {
      debouncedFunction.cancel();
    };
  }, [...dependencies, delay]);
}

export default useDebouncedEffect;
