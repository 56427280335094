  import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
  import { BASE_URL, GET_CUSTOMERLIST_HEADERS, CUSTOMERLIST_UPDATE_HEADERS } from "../constants";

  export const customerHeaderApi = createApi({
    reducerPath: 'customerHeaderApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['customerTableHeaderData'],
    endpoints: (builder) => ({
      fetchCustomerHeader: builder.query({
        query: (data) => {          
          const queryParams = new URLSearchParams({
            model: data.model,
          }).toString();

          return ({
            url: `${GET_CUSTOMERLIST_HEADERS}?${queryParams}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${data.token}`,
              'Content-Type': 'application/json',
            },
            providesTags: ['customerTableHeaderData'],
            
          });          
        },
        // providesTags: ['customerTableHeaderData'],
      }),
      postCustomerHeader: builder.mutation({
        query: ({ token, data }) => ({
          url: CUSTOMERLIST_UPDATE_HEADERS,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: data,
          invalidatesTags: ['customerTableHeaderData'],
        }),
      }),
    }),
  });

  export const { useFetchCustomerHeaderQuery, usePostCustomerHeaderMutation } = customerHeaderApi;
