import { useState } from 'react';

const useCustomerFormValidation = () => {
  const [errorMessage, setErrorMessage] = useState({});

  const validateField = (fieldName, value) => {
    let validationError = '';
    switch (fieldName) {
      case 'address_default':
        if (!value) {
          validationError = "Default Address is required.";
        }
        break;
      case 'primary_email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          validationError = "Email is required.";
        } else if (!emailRegex.test(value)) {
          validationError = "Enter a valid email address.";
        }
        break;
        case 'secondry_email':
            const emailRegexs = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value) {
              validationError = "Email is required.";
            } else if (!emailRegexs.test(value)) {
              validationError = "Enter a valid email address.";
            }
            break;
      case 'point_of_contact':
        if (!value) {
          validationError = "point of contact is required.";
        }
        break;
      case 'country_id':
        if (!value) {
          validationError = "Country is required.";
        }
        break;
      case 'phone1':
        if (!value) {
          validationError = "Phone number is required.";
        }
        break;
      default:
        break;
    }

    setErrorMessage(prevState => ({
      ...prevState,
      [fieldName]: validationError
    }));

    return validationError === '';
  };

  const validateForm = (formData) => {
    const formErrors = {};

    for (const [field, value] of Object.entries(formData)) {
      
      const isValid = validateField(field, value);
      if (!isValid) {
        formErrors[field] = errorMessage[field];
      }
    }

    return {
      isValid: Object.keys(formErrors).length === 0,
      errors: formErrors,
    };
  };

  return {
    errorMessage,
    validateField,
    validateForm,
    setErrorMessage, // Adding setErrorMessage to be used outside the hook if necessary
  };
};

export default useCustomerFormValidation;
