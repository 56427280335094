

import React, { Fragment, useEffect, useState,useRef } from "react";
import SaveEditFirst from "../SaveEdit/SaveEditFirst";
import TextareaSubmit from "./TextareaSubmit";
import useClickOutside from "../../../hooks/Screen/useClickOutside";
import useKeyboardShortcuts from "../../../hooks/KeysHandle/useKeyboardShortcuts";
import CreatePortalHOC from "./CreatePortalHOC";

const InputField = ({
  tableidclass,
  FieldType,
  FieldValue,
  FieldChange,
  setDataInputRow,
  UpdateTableField,
  Item,
  Header,
  clientCoord
}) => {
  const [inputvalue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [overlayStyle, setOverlayStyle] = useState({});
  const[samplestate,setsamplestate]=useState(false)
  const inputcloseoutside = useClickOutside(() => {
    setDataInputRow(null);
  });

  useEffect(() => {
    if (FieldType === 'date' && FieldValue) {
      setInputValue(formatDateForDisplay(FieldValue));
    } else {
      setInputValue(FieldValue);
    }
  }, [FieldValue, FieldType]);

  const formatDateForDisplay = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;
  };

  const formatDateForInput = (date) => {
    if (!date) return '';
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (FieldType === 'date') {
      newValue = formatDateForInput(newValue);
      setInputValue(formatDateForDisplay(newValue)); // Display format
    } else {
      setInputValue(newValue.slice(0,200));
    }
    FieldChange({ target: { value: newValue } }); 
  };


  // useEffect(() => {
  //   if (inputRef.current) {
  //     const inputRect = inputRef.current.getBoundingClientRect();
  //     const centerLeft = inputRect.left + inputRect.width / 2 + window.scrollX;
  //     setOverlayStyle({
  //       left: `${centerLeft}px`,
  //       transform: 'translateX(-50%)', 
  //     });
  //   }
  // }, [inputvalue]);

  

  // useEffect(() => {
  //   if (inputRef.current) {
  //     const inputRect = inputRef.current.getBoundingClientRect();
  //     const centerLeft = inputRect.left + inputRect.width / 2 + window.scrollX;
  //     const topPosition = inputRect.bottom + window.scrollY; // Adjust top position here

  //     setOverlayStyle({
  //       left: `${centerLeft}px`,
  //       top: `${topPosition}px`, // Set top position below the input
  //       transform: 'translateX(-50%)', 
  //     });
  //   }
  // }, [inputvalue]);


  useKeyboardShortcuts({
    Enter: (event) => {
      event.preventDefault();
      UpdateTableField(event, {value : inputvalue} , Item)
    },
    Escape: (event) => {
      event.preventDefault();
      setDataInputRow(null);
    }
  });


  return (
    <Fragment>
      {FieldType === 'text' ? (
        <Fragment>
          <input
            ref={inputRef}
            className="p-3 w-full h-[33px] bg-white"
            disabled
            readOnly
          />

          {/* <div className="absolute left-1/2 transform -translate-x-1/2 z-10 mt-3 w-[300px]"> */}
          {/* <div className={`w-full absolute top-0 h-full ${HoverActiveClass}`} ref={dropdownRef} onMouseOver={handleOpen} onMouseLeave={handleClose}>
            </div> */}
               
                <CreatePortalHOC isOpen={true} onClose={()=>{}} position={clientCoord} >
                {/* <div className="table-overlay inset-0 flex items-center justify-end"> */}
                  <div className={` !p-0 w-[${clientCoord?.element?.width-20}px]`} ref={inputcloseoutside}>
                        
                        <div className="">
                              {/* <div className="flex justify-between mb-2">
                                    <h4 className="font-500 text-18 text-434852 capitalize">{Header?.label}</h4>
                                    <button  
                                    onClick={(e) => {
                                          e.stopPropagation();
                                          setDataInputRow(null);
                                    }}>
                                        <i className="bi bi-x-circle text-14"></i>
                                    </button>
                              </div> */}
                              
                              <TextareaSubmit 
                                Value={inputvalue}
                                onChange={handleChange}
                                handleSaveEvent={(e) => UpdateTableField(e, {value : inputvalue}, Item)}
                                
                              />

                        </div>
                      
                  </div>
                {/* </div> */}
              </CreatePortalHOC>
               
               
        </Fragment>
      ) : (
        <Fragment>
              <input
                type={FieldType === "number" ? 'text' : FieldType} 
                value={inputvalue}
                onChange={handleChange}
                className="p-3 w-full h-[33px]"
              />


              <SaveEditFirst
              UpdateTableField={(e) => UpdateTableField(e, {value: inputvalue}, Item)}
              handleCloseClick={(e) => {
                e.stopPropagation();
                setDataInputRow(null);
              }}
              />

        </Fragment>

      )}

     
    </Fragment>
  );
};
export default InputField;