import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import taskHeader from "./CustomerTaskTableHead";
import AddTaskModal from "./AddTaskModal";
import CustomerListTable from "./CustomerListTable";
import { useParams } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import {useFetchTaskGetOrgAccordingQuery} from "../../utils/customer/customerTaskTable"

import { hideMessage } from "../../utils/customer/customerTaskSlice";
import {
  useFetchCustomerTaskTableDataQuery,
  useUpdateCustomerTaskTableDataMutation,
} from "../../utils/customer/customerTaskTable";
import { useDispatch, useSelector } from "react-redux";

import {
  useLazyFetchPersonInTableQuery,
} from "../../utils/customer/customerTable";
import EditTaskModel from "./EditTaskModel";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import useClickOutside from "../../hooks/Screen/useClickOutside";
import { useCustomerTableMutation } from "../../utils/customer/customerTable";
import QuickFilter from "../GlobalFeature/Table/HeadTop/QuickFilter";
import { useFetchCustomerHeaderTaskQuery } from "../../utils/customer/customerTaskTable";
import TaskRecurrenceCalendar from "../TaskRecurrence/TaskRecurrenceCalendar";


import Tooltip from '@mui/material/Tooltip';

const stringData = (dateString) => {
  if (!dateString || dateString.trim() === "") return "";
  const data = dateString.split("-");
  return `${data[2]}-${data[1]}-${data[0]}`;
};


const TaskNavbar = ({taskOpenTab,setView,view}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("#All");
  const [initialFilter, setInitialFilter] = useState([]);
  const [quiFilter, setQuiFilter] = useState("");
  const [customer, setCustomer] = useState([]);
  const [dummyFilter, setDummyFilter] = useState([]);
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const showMessage = useSelector((state) => state.tasks.showMessage);
  const message = useSelector((state) => state.tasks.message);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const taskFormSubmit = useSelector((state) => state.tasks);

  const [editData, setEditData] = useState();
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(null);

  const [refreshKey, setRefreshKey] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
    
  const [isGridActive, setIsGridActive] = useState(false);
  const [listview, setListView]=useState(true);
  const [calview, setCalView]=useState(false);

  const taskpopupcloseoutside = useClickOutside(() => {
    setIsEditPopupOpen(false);
  });

  const handelSubmitData = async (customerId) => {
    refetchCustomerData();
  };
  useEffect(() => {
    if (taskOpenTab) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
 
    // Clean up the effect on component unmount
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [taskOpenTab]);
  // const {
  //   data: customerListHeader,
  //   refetch,
  //   isLoading,
  // } = useFetchCustomerHeaderTaskQuery({
  //   token: token,
  // });
  // const { data: customerTableALL, refetch: refetchCustomerData } =
  //   useFetchCustomerTaskTableDataQuery({
  //     customerId: customerId,
  //     owner:  parsedCompanyData?.owner,
  //     company_id: parsedCompanyData?.company_id,
  //     pagination: `${customerTablePagination}`,
  //     status: activeTab.replace("#", ""),
  //     quiFilter:  quiFilter,
  //     token: token,
  //   },{
  //     skip: taskOpenTab !== 1,
  //   });
  const { data: customerTableALL, refetch:refetchCustomerData } = useFetchTaskGetOrgAccordingQuery(
    { customerId: customerId,
           owner:  parsedCompanyData?.owner,
           company_id: parsedCompanyData?.company_id,
           pagination: `${customerTablePagination}`,
          status: activeTab.replace("#", ""),
          quiFilter:  quiFilter,
      
      token },{
        skip:taskOpenTab !==1,
      });

    const getTransformedData=(val)=>{
      console.log("initial raw custometTableall data",val);
     const transformedData= val.map((obj)=>{
      let status=obj?.statuses[0]?.status
      let end_date=obj?.end_date.slice(0,10)
      if(status && status.length<3){
        status="--"
      }
      
        let transformedObj = {};


   Object.keys(obj).forEach((key, index) => {
      // If the key is "status", use the `status` variable for value
      if (key === "status") {
        transformedObj[key] = {
          "value": status??"--",
          "type": null,
          "field_options": null,
          "table_name": null,
          "field_name": key,
          "id": index
        };
      } else if(key==="due_date"){
        transformedObj[key] = {
          "value":end_date,
          "type": null,
          "field_options": null,
          "table_name": null,
          "field_name": key,
          "id": index

      }}else {
        transformedObj[key] = {
          "value": obj[key],
          "type": null,
          "field_options": null,
          "table_name": null,
          "field_name": key,
          "id": index
        };
      }
    });
  // Object.keys(obj).forEach((key,index) => {
  //   transformedObj[key] = {
  //     "value": obj[key],        
  //     "type": null,             
  //     "field_options": null,      
  //     "table_name": null,       
  //     "field_name": key,        
  //     "id": index                 
  //   };
  // });
  
  return transformedObj
  
  
 


      })
     // console.log("transformed data",transformedData);
      return transformedData
    }

  useEffect(() => {
    if (customerTableALL && taskOpenTab===1) {
      //console.log("customerTableAll",customerTableALL)
      //const { data, ...restData } = customerTableALL?.data;
      const {data,...restData} = customerTableALL
      console.log("desctructured data",data);
      console.log("restData",restData);
      const getFilteredData=(data)=>{
        console.log("activetab",activeTab);
        
        switch (activeTab) {
          case "#OverDue":
            return data.filter((item)=>new Date(item.end_date)>new Date())
            break;
          case "#Close":
            return data.filter((item)=>item.statuses[0].status!=="Not started" && item.statuses[0].status!=="Work in Progress")
            
            break;
        
          default:
            return data
            break;
        }
      }
      let filteredData= getFilteredData(data)

      
      
      
      const transformedData=getTransformedData(filteredData)  
      // const transformedData = {
        
      //     "data": transformedData1
        
      // };
       console.log("transformed data",transformedData);
        
        setCustomer(transformedData);
      setInitialFilter(restData);
     
    }
  }, [customerTableALL, customerTablePagination, refetchCustomerData,taskOpenTab,activeTab]);

  useEffect(() => {
    if (taskFormSubmit?.tasks?.data?.data && taskOpenTab===1) {
      refetchCustomerData();
    }
  }, [taskFormSubmit?.tasks?.data?.data]);

  const [
    CustomerTableDrop,
    { data: customerCommnData, refetch: refetchCustomerCommnData },
  ] = useCustomerTableMutation();

  const fetchCustomerTable = (data) => {
    const completedata = {
      model_name: "task",
      field_name: data,
    };
    CustomerTableDrop({ token: token, data: completedata });
  };

  const fetchPersonData = (data) => fetchPersonInTable({ token, data });
  const [fetchPersonInTable, { data: personTableData }] =
    useLazyFetchPersonInTableQuery();

  const handleNavigate = (taskid) => {
    if (taskid) {
      setIsEditPopupOpen(true);
      setEditData(taskid);
    }
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };



  const openPopup = (rowKey, item) => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setEditData();
    setIsEditPopupOpen(false);
    setIsPopupOpen(false);
  };  

  setTimeout(() => {
    dispatch(hideMessage());
  }, 3000);

  const [updateCustomerTaskTableData, { CustomerDropLoad, CustomerDroperror }] =
    useUpdateCustomerTaskTableDataMutation();



  

  const customerTableFieldUpdate = async (field_name, value) => {
    

    let datevalue;
    if (
      value?.field_name === "start_date" ||
      value?.field_name === "end_date"
    ) {
      datevalue = stringData(field_name.value);
    } else {
      datevalue =
        value?.field_name === "owner" ? field_name.name : field_name.value;
    }
    const updateData = {
      field_name: value?.field_name,
      value: datevalue,
      id: value?.id,
    };

    try {
      const result = await updateCustomerTaskTableData({
        token: token,
        data: updateData,
      }).unwrap();
      setQuiFilter();
      refetchCustomerData();
      setAlert({
        visible: true,
        message: result?.data?.original?.error || result?.message,
        type: result?.data?.original?.error ? "danger" : "success",
      });
    } catch (error) {
      setAlert({
        visible: true,
        message: "Error updating data!",
        type: "danger",
      });
    }
  };

  const isOpen = () => {};

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  const TaskNavbarQuickFilter = (option) => {
    setQuiFilter(option);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (refreshKey) {
      refetchCustomerData();
    }
  }, [refreshKey]);

  const activityClosed=(val)=>{
    refetchCustomerData();
    
  }



  const changeToListView = () => {
    setListView(true);
    setCalView(false);
  }

  const changeToCaltView = () => {
    setListView(false);
    setCalView(true);      
  }

  const handleGridClick = () => {
    setIsGridActive(!isGridActive);
    changeToCaltView();
  }

  const ViewData =() => {
    return  (
      <div className={`flex items-center justify-end ${calview?'pb-1':''}`}>
          <div
            className={`border-[1px] flex justify-center items-center rounded-l-[5px] rounded-r-[5px] border-[rgba(29, 68, 105, 0.20)] bg-white me-1`}
            role="group"
          >
            <button
              className="flex items-center h-[28px] px-3 py-[5px] text-sm font-medium text-gray-900 bg-white rounded-l-lg border-[rgba(29, 68, 105, 0.20)] hover:bg-1D4469 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-200 focus:text-gray-200"
              onClick={changeToListView}
            >
              <i className="bi bi-list-ul text-848687 hover:text-white"></i>
            </button>

            <button
              className={`flex items-center h-[28px] px-3 py-[5px] text-sm font-medium text-gray-900 bg-white ${calview?'':'rounded-r-lg'} border-[rgba(29, 68, 105, 0.20)] hover:bg-1D4469 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-200 focus:text-gray-200 border-l border-[rgba(29, 68, 105, 0.20)]`}
              onClick={handleGridClick}
            >
              <i className="bi bi-grid text-848687 hover:text-white"></i>
            </button>

            {calview && (
              <Fragment>
                <button
                  onClick={() => setView("Day")}
                  className={`h-[30px] text-12 text-848687 font-400 px-3 py-[5px] border-l border-[rgba(29, 68, 105, 0.20)] hover:bg-1D4469 hover:text-white ${view === "Day" ? "bg-1D4469 text-white" : ""}`}
                >
                  Day
                </button>
                <button
                  onClick={() => setView("Week")}
                  className={`h-[30px] text-12 text-848687 font-400 px-3 py-[5px] border-l border-[rgba(29, 68, 105, 0.20)] hover:bg-1D4469 hover:text-white ${view === "Week" ? "bg-1D4469 text-white" : ""}`}
                >
                  Week
                </button>
                <button
                  onClick={() => setView("Month")}
                  className={`h-[30px] text-12 text-848687 font-400 px-3 py-[5px] border-l border-[rgba(29, 68, 105, 0.20)] hover:bg-1D4469 hover:text-white ${view === "Month" ? "bg-1D4469 text-white rounded-r-[5px]" : ""}`}
                >
                  Month
                </button>
              </Fragment>
            )}
          </div>
          {calview   && 
              (
                  <button                   
                    type="button"   
                    className="bg-1D4469 text-12 text-white rounded-5px h-[30px] w-[84px] border-[rgb(29 68 105 100%))] hover:bg-123251"    
                  >   
                    Today   
                  </button>   

              )

          }
      </div>
    );
  }


  return (
    <Fragment>
      <nav className="">
        <div className="container ">
          <div className="flex justify-between ">
            <div className="flex justify-between ">
              <div className="tablemaindiv customerTaskTable">
                {(isPopupOpen || editData) && (
                  <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                    <AddTaskModal
                      isOpen={isOpen}
                      closePopup={closePopup}
                      taskid={editData}
                      rowData={editData}
                      completeData={customerTableALL.data}
                      handelSubmitData={handelSubmitData}
                    />
                  </div>
                )}
                {/* {editData && (
                  <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                    <EditTaskModel
                      isOpen={isOpen}
                      closePopup={closePopup}
                      taskid={editData}
                      handelSubmitData={handelSubmitData}
                      Referance={taskpopupcloseoutside}
                    />
                  </div>
                )} */}
              </div>
            </div>
          </div>

          <div className="md:hidden">
            <button className="text-white focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />
      </nav>

      <div>

        {
          listview  &&        
            <CustomerListTable
              key={refreshKey}
              tableDetail={{
                tableidclass: "fullscreen-table",
                tableparentidclass: "tableparent",
                forcefullyfreeze: true,
                tablename: (
                  <div className="flex items-center">
                    <ul className="hidden md:flex space-x-6">
                      {initialFilter?.count?.All > 0 && (
                        <li>
                          <a
                            href="#All"
                            onClick={() => handleTabClick("#All")}
                            className={`border-b-2 focus:block leading-normal font-medium text-537596 capitalize ${
                              activeTab === "#All" ? "border-2C495D" : "border-transparent"
                            }`}
                          >
                            All Task
                          </a>
                        </li>
                      )}
                      {initialFilter?.count?.OverDue > 0 && (
                        <li>
                          <a
                            href="#OverDue"
                            onClick={() => handleTabClick("#OverDue")}
                            className={`border-b-2 focus:block leading-normal font-medium text-537596 capitalize ${
                              activeTab === "#OverDue"
                                ? "border-b-2 border-2C495D"
                                : "border-transparent"
                            }`}
                          >
                            Overdue
                          </a>
                        </li>
                      )}
                      {initialFilter?.count?.Close > 0 && (
                        <li>
                          <a
                            href="#Close"
                            onClick={() => handleTabClick("#Close")}
                            className={`border-b-2 focus:block leading-normal font-medium text-537596 capitalize ${
                              activeTab === "#Close"
                                ? "border-b-2 border-2C495D"
                                : "border-transparent"
                            }`}
                          >
                            Closed Task
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                ),
              
                tablenameclass: "font-medium text-16 text-203C50 capitalize",
                tableSearch: true,
                tableFilter: true,
                tablequickfilter: (
                  <QuickFilter
                    options={[
                      "All",
                      "Due Today",
                      "Due This Week",
                      "Due Next Week",
                      "Created by me",
                      "Assigned To me",
                    ]}
                    placeholder={<span className="text-848687"> {quiFilter ? quiFilter : 'Quick Filter'}</span>}
                    onSelect={TaskNavbarQuickFilter}
                  />
                ),
                tableResizer: true,
                tableZoom: true,
                tableheader: true,
                dataactivity: true,
                addViewSec:true,
                addbutton: (
                  <button
                    onClick={openPopup}
                    type="button"
                    className="text-2541558A hover:text-254155"
                  >
                    <i className="bi bi-plus-lg"></i>
                  </button>
                ),
                addbuttonToday: (
                  <Fragment>
                      {ViewData()}
                  </Fragment>
                ),
              }}
              thead={taskHeader}
              tbody={dummyFilter && dummyFilter.length > 0 ? dummyFilter : customer}
              tablePaginationData={setCustomerTablePagination}
              fetchtableBodyData={fetchCustomerTable}
              customerCommnData={customerCommnData || personTableData}
              fetchPersonInTable={fetchPersonData}
              TableFieldUpdate={customerTableFieldUpdate}
              initialFilter={initialFilter}
              handleNavigate={handleNavigate}
              navigateNameCondition={["subject"]}
              activityClosed={activityClosed}
              ExtraFieldComponent={{
                subject: (item, header) => (
                  // <button className="bg-[#F1F1F1] rounded p-1">
    
                  //       <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  //         <path d="M8.75 5.83333H7.08333C6.39583 5.83333 5.83333 6.39583 5.83333 7.08333V7.5H2.91667C2.6875 7.5 2.5 7.3125 2.5 7.08333V4.16667H4.16667V1.25C4.16667 0.5625 3.60417 0 2.91667 0H1.25C0.5625 0 0 0.5625 0 1.25V4.16667H1.66667V7.08333C1.66667 7.77083 2.22917 8.33333 2.91667 8.33333H5.83333V10H10V7.08333C10 6.39583 9.4375 5.83333 8.75 5.83333ZM0.833333 1.25C0.833333 1.02083 1.02083 0.833333 1.25 0.833333H2.91667C3.14583 0.833333 3.33333 1.02083 3.33333 1.25V3.33333H0.833333V1.25ZM9.16667 9.16667H6.66667V7.08333C6.66667 6.85417 6.85417 6.66667 7.08333 6.66667H8.75C8.97917 6.66667 9.16667 6.85417 9.16667 7.08333V9.16667Z" fill="#567A9D"/>
                  //       </svg>
                  // </button>
    
                  <Tooltip title="Sub Task" placement="right" arrow  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'common.white',
                        filter: 'drop-shadow(0px 0px 10px rgba(44, 73, 93, 0.30))',
                        color: '#383838',
                        '& .MuiTooltip-arrow': {
                          color: 'common.white',
                          filter: 'drop-shadow(0px 0px 10px rgba(44, 73, 93, 0.30))',
                        },
                      },
                    },
                  }}>
                        <button className="bg-[#F1F1F1] rounded p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <path
                              d="M8.75 5.83333H7.08333C6.39583 5.83333 5.83333 6.39583 5.83333 7.08333V7.5H2.91667C2.6875 7.5 2.5 7.3125 2.5 7.08333V4.16667H4.16667V1.25C4.16667 0.5625 3.60417 0 2.91667 0H1.25C0.5625 0 0 0.5625 0 1.25V4.16667H1.66667V7.08333C1.66667 7.77083 2.22917 8.33333 2.91667 8.33333H5.83333V10H10V7.08333C10 6.39583 9.4375 5.83333 8.75 5.83333ZM0.833333 1.25C0.833333 1.02083 1.02083 0.833333 1.25 0.833333H2.91667C3.14583 0.833333 3.33333 1.02083 3.33333 1.25V3.33333H0.833333V1.25ZM9.16667 9.16667H6.66667V7.08333C6.66667 6.85417 6.85417 6.66667 7.08333 6.66667H8.75C8.97917 6.66667 9.16667 6.85417 9.16667 7.08333V9.16667Z"
                              fill="#567A9D"
                            />
                          </svg>
                        </button>
                  </Tooltip>
                )
              }}    
              /// setSideBarColl={setSideBarColl}
            />
                  
        }
        {
          calview && (
            <Fragment>
              {ViewData()}
              <TaskRecurrenceCalendar setView={setView} view={view}/>
            </Fragment>
          )
        }
      </div>
    </Fragment>
  );
};

export default TaskNavbar;
