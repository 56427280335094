import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useMemo,
} from "react";

import DatePicker from "react-datepicker";
import LayoutHOC from "../LayoutHOC";
import { Link,useNavigate,useLocation  } from "react-router-dom";
import CustomerHome from "./CustomerHome";
import CustomerOtherDetail from "./CustomerOtherDetail";
import CustomerReportingTeam from "./CustomerReportingTeam";
import CustomerArtifact from "./CustomerArtifact";
import CustomerServices from "./CustomerServices";
import AddIcon from "../Icons/AddIcon";
import AddTask from "./AddTask";
import AddNotes from "./AddNotes";
import AddUpcomingEvent from "./AddUpcomingEvent";
import LeftCard from "./LeftCard";
import AddArtifact from "./AddArtifact";
import useRequireAuth from "../../utils/useRequireAuth";
import { useParams } from "react-router-dom";
import {
  CUSTMOER_NOTES_DATA_GET,
  CUSTOMER_DETAILS_DATA,
  GET_CUSTOMER_ACCESS_AVAILABLE
} from "../../utils/constants";
import CustomerTaskHoverDescription from "./CustomerTaskHoverDescription";
import CustomerTaskHoverActivity from "./CustomerTaskHoverActivity";
import {
  useFetchCustomerTaskTableDataQuery,
  useFetchCustomerTaskDueTableDataQuery
} from "../../utils/customer/customerTaskTable";
import AddTaskModal from "./AddTaskModal";
import { useSelector, useDispatch } from "react-redux";
import HoverElement from "../GlobalFeature/Others/HoverElement";
import ViewNotesData from "./ViewNotesData";
import NotesEditor from "../TextEditor/NotesEditor";
import { saveNotes } from "../../utils/customer/customerNotesSlice";
import {
  useFetchCustomerAddressDataBasedOnIdQuery,
  useFetchCustomerNotesColorDataQuery,
  useFetchTaskDataQuery,
} from "../../utils/customer/customerSelect";
import AddCutomerOtherDetail from "./AddCutomerOtherDetail";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import TextHoverElement from "../GlobalFeature/Others/TextHoverElement";
import ManageAccess from "./ManageAccess";
import TopTitle from "../GlobalFeature/TopTitle"; 

const stringData = (dateString) => {
  //const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
 
  if (dateString && dateString.trim() !== '') {
     const datenew= dateString.split('-');
     return `${datenew[0]} ${monthNames[datenew[1] - 1]} ${datenew[2]}`
  }
  return '';
};

function getDate() {
  const today = new Date();
  return `${(`0${today.getMonth() + 1}`).slice(-2)}/${(`0${today.getDate()}`).slice(-2)}/${today.getFullYear()}`;
}

function formatValue(item) {
  if (item) {
    const words = item.split(' ');
    const firstWord = words[0];
    const lastWord = words[words.length - 1];
    const firstChar = firstWord.charAt(0).toUpperCase();
    const lastChar = lastWord.charAt(0).toUpperCase();
    return  `${firstChar}${lastChar}`;
  }
  return null;
} 

function formatFirstValue(item) {
  if (item) {
    const words = item.split(' ');
    const firstWord = words[0];
    const lastWord = words[words.length - 1];
    const firstChar = firstWord.charAt(0).toUpperCase();
    const lastChar = lastWord.charAt(0).toUpperCase();
    return  `${firstChar}`;
  }
  return null;
} 

const AddCustomer1 = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lefttabParam =  parseInt(queryParams.get('LeftTab')) || 0;
  const righttabParam =  parseInt(queryParams.get('RightTab')) || 0;
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);

  const [customerTask, setCustomerTask] = useState([]);
  const [hoveredTaskId, setHoveredTaskId] = useState(null);
  const taskFormSubmit = useSelector((state) => state.tasks);
  const notesFormSubmit = useSelector((state) => state.notes);
  const [isOpen, setIsVisible] = useState(true);

  const handleMouseEnterDescription = (id) => {
    setHoveredTaskId(id);
    setShowIconDesc(id);
  };
  const handleMouseLeaveDescription = (id) => {
   
      setHoveredTaskId(false);
      setShowIconDesc(false);
    
  };

  const [showIconDesc, setShowIconDesc] = useState(false);
  const [onClickActivity, setOnClickActivity] = useState(null);
  const popupRefA = useRef(null);

  const [openTab, setOpenTab] = React.useState(lefttabParam);
  const [taskOpenTab, setTaskOpenTab] = React.useState(righttabParam);

  const [isFixed, setIsFixed] = useState(false);
  const [currentDate, setCurrentDate] = useState(getDate());

  const [calendarDate, setCalendarDate] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const [customer, setCustomer] = useState(false);
  const [customerGroupName, setCustomerGroup] = useState();
  const [customerType, setCustomerType] = useState();  
  const [cusnameletter, setnameletter] = useState();  
  const [taskExpand, setTaskExpand] = useState(lefttabParam ? false : true);
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const [initialFilter, setInitialFilter] = useState([]);
 
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [notesSetting, setNotesSetting] = useState(false);
  const [category, setCategory] = useState(1);
  const textareaRef = useRef(null);
  const [viewNotes, setViewNotes] = useState({ allnotes: false, addnotes: false });
  const [initialContent, setInitialContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [color, setColor] = useState(null);
  const [formData, setFormData] = useState({ notes_title: "", notes_category_id: "", colour_code: "" });
  const notesDatasFormSubmit = useSelector((state) => state.notes);
  const [access,setAccess]=useState();

  const { data: customerTaskCategory } = useFetchTaskDataQuery({
    token,
    jsonData: { customer_id: customerId, company_id: parsedCompanyData?.company_id },
  });

  const { data: customerColor } = useFetchCustomerNotesColorDataQuery({ token });
  const dispatch = useDispatch();

  const handleClick = (index) => {
    setOpenTab(index);
    if (index !== 0) {
      const contentElement = document.getElementById(`link${index + 1}`);
      contentElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };
  
  const handleTaskClick = (index) => {  
    setTaskOpenTab(index);
    if (index !== 0) {
      const contentElement = document.getElementById(`tasklink${index + 1}`);
      contentElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };
  
  const handleScroll = () => setIsFixed(window.scrollY > 120);
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const fetchDataCustomerAccess=async(customerId)=>{
    const url=`${GET_CUSTOMER_ACCESS_AVAILABLE}${customerId}?company_id=${parsedCompanyData?.company_id}`;
    const response=await getCommonDataBasedOnId(url,token);
    if(response?.success===true){
      setAccess(response?.data);
    }
  }
  const myTabs = [
    { label: "General Information", content: <CustomerHome leftTab={openTab}/> },
    { label: "Services", content: <CustomerServices leftTab={openTab}/> },
    { label: "Billing", content: "Billing Content" },
    { label: "Document", content: <CustomerArtifact leftTab={openTab}/> },
    { label: "Reporting Team", content: <CustomerReportingTeam leftTab={openTab}/> },
    ...(access === 1 ? [{ label: "Manage Access", content: <ManageAccess leftTab={openTab}/> }] : [])
  ];
  const taskTabs = [
    { label: "Tracking", content: <CustomerOtherDetail /> },
    { label: "Task", content: <AddTask taskOpenTab={taskOpenTab}/> },
    { label: "Notes", content: <AddNotes taskOpenTab={taskOpenTab}/> },
    { label: "Document", content: <AddArtifact  taskOpenTab={taskOpenTab}/> },
    { label: "Events", content: <AddUpcomingEvent /> },
    ...(true ? [{ label: "Manage Access", content: <ManageAccess rightTab={5}/> }] : [])
  ];

  function TaskExpand() {
    setTaskExpand(!taskExpand);
    setCalendarDate(new Date());
  }
  
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  
  const goToPreviousDate = () => setCalendarDate(new Date(calendarDate.setDate(calendarDate.getDate() - 1)));
  const goToNextDate = () => setCalendarDate(new Date(calendarDate.setDate(calendarDate.getDate() + 1)));
  const isWeekday = (date) => date.getDay() !== 0;
  const calendarDateChange = (e) => setCalendarDate(e);

  const calendarClick = (e) => {
    e.preventDefault();
    setCalendarOpen(!calendarOpen);
  };
  const [latestNotes, setLatestNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const url = `${CUSTMOER_NOTES_DATA_GET}${customerId}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCommonDataBasedOnId(url, token);
        const sortedNotes = response?.data
        setLatestNotes(sortedNotes.slice(0, 4));
      } catch (err) {
        setError(err);
      }
    };
    fetchData();
  }, [url, token, customerId,notesDatasFormSubmit.notes]);

  const fetchCustomerData = async () => {
    try {
      const response = await getCommonDataBasedOnId(`${CUSTOMER_DETAILS_DATA}${customerId}`, token);
      const customerName = response?.data[0]?.customer_name;
      setCustomer(customerName);
      const cutomergroup = response?.data[0]?.customer_group;
      setCustomerGroup(cutomergroup);
      const ctype = response?.data[0]?.type;
      setCustomerType(ctype);
      const words = customerName.split(' '); // Split the string into an array of words
      const firstLetter = words[0] ? words[0][0] : ''; // Get the first letter of the first word
      const secondLetter = words[1] ? words[1][0] : ''; // Get the first letter of the second word
      const nameletter =  firstLetter + secondLetter;
      setnameletter(nameletter);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerData();
    fetchDataCustomerAccess(customerId);
  }, [customerId]);  

  const openPopup = () => {
    setIsPopupOpen(true)
  };
  
  const closePopup = () => setIsPopupOpen(false);
  const { data: customerTableALL, refetch: refetchCustomerData } = useFetchCustomerTaskTableDataQuery({
    customerId,
    pagination: customerTablePagination,
    token,
  });
  
  useEffect(() => {    
    if (customerTableALL) {      
      const filteredData = customerTableALL?.data?.data?.filter(
        (item) => item?.status?.value !== 'Close' && item?.status?.value !== 'Cancelled'
      );
      const taskData = filteredData[9];
      const filteredTaskData = taskData.filter(item => Number(item.customer_id.value) ===   Number(customerId));
      setCustomerTask(filteredTaskData.slice(0,4));
      setInitialFilter({ ...customerTableALL.data });
    }
  }, [customerTableALL]);

  useEffect(()=>{
    if(taskFormSubmit?.tasks?.data?.data){
      refetchCustomerData(); 
    }

  },[taskFormSubmit?.tasks?.data?.data])
  
  const handelSubmitData = async () => {
    try {
      await refetchCustomerData();
    } catch (error) {
      console.error("Error refetching customer data:", error);
    }
  };
  

  const handleSave = async () => {};
  
  let expandbtn = (
    <div className={`flex ${taskExpand?'justify-start':'justify-end'} mb-3`}>
      <button
        className="bg-white border border-E1E1E1 py-2 px-3"
        onClick={TaskExpand}
      >
        <i className="bi bi-arrows-fullscreen"></i>
      </button>
    </div>
  );

  function truncateHTML(html, maxLength) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    let truncatedHTML = "";
    let totalChars = 0;
    
    for (const childNode of tempDiv.childNodes) {
      const nodeText = childNode.textContent || "";
      if (totalChars + nodeText.length > maxLength) {
        const remainingChars = maxLength - totalChars;
        truncatedHTML += nodeText.slice(0, remainingChars) + "...";
        break;
      }
      truncatedHTML += nodeText;
      totalChars += nodeText.length;
    }
    
    return truncatedHTML;
  }

  const handleButtonClick = (taskId) => setOnClickActivity((prevId) => prevId === taskId ? null : taskId);
  const onToggle = () =>setIsVisible(!isOpen);

  //const isOpen = () => {};

  const parseData = (data) => {
    try {
      const parsedData = JSON.parse(data);
      return parsedData?.value ?? "";
    } catch (e) {
      console.error("Invalid JSON:", e);
      return null;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleNotesClick = () => setViewNotes({ allnotes: true, addnotes: true });
  
  const handleCloseNotes = () => setViewNotes({ allnotes: false, addnotes: false });
  const handleSetColour = (color) => setColor(color);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const notesData = {
      notes_category_id: formData.notes_category_id || category,
      colour_code:color ?? "#D9D9D9",
      notes_title: formData.notes_title,
      company_id: parsedCompanyData.company_id,
      customer_id: customerId,
      notes_body: textareaRef.current.innerHTML,
      rag_status: "wip",
      attachment: selectedFiles,
    };
    if (textareaRef.current.innerHTML !== "") {
      dispatch(saveNotes({ notesData, token }));
      resetFile();
      setViewNotes({ allnotes: false, addnotes: false });
      setFormData({ notes_title: "", notes_category_id: "", colour_code: "" });
      textareaRef.current.innerHTML = "";
    }
  };
  
  const handleFileUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.click();
    input.addEventListener("change", (event) => {
      const files = event.target.files;
      const fileList = Array.from(files);
      setSelectedFiles(fileList);
    });
  };

  const closeActivityModal=()=>{
    setOnClickActivity(false);
  }

  const resetFile = (newSelectedFiles = []) => setSelectedFiles(newSelectedFiles);


  const  tableDetail={
    tableidclass: "fullscreen-table",
  }

  const handleClickDeleteFile = (index) => {
    if (index >= 0 && index < selectedFiles?.length) {
      resetFile(selectedFiles.filter((file, idx) => idx !== index));
    }
  };

 
  return (
    <div className={`p-2 pb-0 pt-5`}>
      <TopTitle
        name={customer}
        groupname={customerGroupName}        
        type={customerType}
        pt="0px"
        icon={<svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
          <path d="M11.0467 0.000446666C10.8498 0.00716809 10.6603 0.0766571 10.5058 0.19876L1.75958 7.08926C0.649512 7.96409 0 9.30089 0 10.7142V22.4616C0 23.3007 0.699314 24 1.53843 24H7.69216C8.53128 24 9.2306 23.3007 9.2306 22.4616V16.3078C9.2306 16.1263 9.35672 16.0001 9.53828 16.0001H12.6152C12.7967 16.0001 12.9228 16.1263 12.9228 16.3078V22.4616C12.9228 23.3007 13.6222 24 14.4613 24H20.615C21.4541 24 22.1534 23.3007 22.1534 22.4616V10.7142C22.1534 9.30089 21.5039 7.96409 20.3939 7.08926L11.6476 0.19876C11.4769 0.0639168 11.2641 -0.00631706 11.0467 0.000446666ZM11.0767 2.09897L19.252 8.53995C19.9192 9.06571 20.3073 9.86538 20.3073 10.7142V22.1539H14.769V16.3078C14.769 15.1294 13.7936 14.154 12.6152 14.154H9.53828C8.35987 14.154 7.38448 15.1294 7.38448 16.3078V22.1539H1.84612V10.7142C1.84612 9.86538 2.23426 9.06571 2.90139 8.53995L11.0767 2.09897Z" fill="white"/>
          </svg>}
        url="/customers"
        nameletter={cusnameletter}
      />
      <div className={`flex flex-col p-2`}>
        <div className={`flex justify-end items-center px-2`}>        
          {/* <div className="flex py-1">
            <Link to={"/customers"} className="me-2 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M15.0002 20.6251H6.99766C6.09267 20.6587 5.21098 20.3338 4.54416 19.7211C3.87733 19.1083 3.47925 18.2572 3.43641 17.3526V13.0626C3.43641 12.8802 3.50884 12.7054 3.63777 12.5764C3.7667 12.4475 3.94157 12.3751 4.12391 12.3751C4.30624 12.3751 4.48111 12.4475 4.61004 12.5764C4.73897 12.7054 4.81141 12.8802 4.81141 13.0626V17.3526C4.85366 17.8926 5.10664 18.3943 5.51571 18.7493C5.92477 19.1044 6.45708 19.2842 6.99766 19.2501H15.0002C15.5407 19.2842 16.073 19.1044 16.4821 18.7493C16.8912 18.3943 17.1442 17.8926 17.1864 17.3526V13.0626C17.1864 12.8802 17.2588 12.7054 17.3878 12.5764C17.5167 12.4475 17.6916 12.3751 17.8739 12.3751C18.0562 12.3751 18.2311 12.4475 18.36 12.5764C18.489 12.7054 18.5614 12.8802 18.5614 13.0626V17.3526C18.5186 18.2572 18.1205 19.1083 17.4537 19.7211C16.7868 20.3338 15.9051 20.6587 15.0002 20.6251ZM20.4245 11.4882C20.489 11.4243 20.5401 11.3483 20.575 11.2645C20.6099 11.1807 20.6279 11.0908 20.6279 11.0001C20.6279 10.9093 20.6099 10.8195 20.575 10.7357C20.5401 10.6519 20.489 10.5759 20.4245 10.512L11.487 1.57446C11.4231 1.51002 11.3471 1.45887 11.2633 1.42397C11.1795 1.38906 11.0897 1.37109 10.9989 1.37109C10.9081 1.37109 10.8183 1.38906 10.7345 1.42397C10.6507 1.45887 10.5747 1.51002 10.5108 1.57446L1.57328 10.512C1.44382 10.6414 1.37109 10.817 1.37109 11.0001C1.37109 11.1832 1.44382 11.3587 1.57328 11.4882C1.70274 11.6177 1.87832 11.6904 2.06141 11.6904C2.24449 11.6904 2.42007 11.6177 2.54953 11.4882L10.9989 3.03196L19.4483 11.4882C19.5122 11.5526 19.5882 11.6038 19.672 11.6387C19.7558 11.6736 19.8456 11.6916 19.9364 11.6916C20.0272 11.6916 20.117 11.6736 20.2008 11.6387C20.2846 11.6038 20.3606 11.5526 20.4245 11.4882Z" fill="#2999BC"/>
              </svg>
            </Link>
            <h5 className={`text-203C50 font-Vietnam text-[24px] font-medium capitalize`}>
              {customer}
            </h5>
          </div> */}

          {/* <div className={`flex items-center`}>
            <label className={`inline-flex items-center cursor-pointer`}>
              <input type={`checkbox`} value={``} className={`sr-only peer`} />
              <div
                className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
              ></div>
            </label>
          </div> */}
        </div>
      </div>

      {!taskExpand && (
        <ul
          className={`flex mb-0 list-none flex-wrap px-3 items-center flex-row mt-3 bg-white border border-1d446933 ${
            isFixed ? "shadow fixed top-[43px] z-50 w-11/12" : "w-full"
          }`}
          role="tablist"
        >
          {myTabs.map((item, index) => (
            <li key={index} className=" mr-2 last:mr-0 text-center text-848687 text-16 font-400">
              <Link
                className={
                  "py-2 px-3 block leading-normal" +
                  (openTab === index
                    ? "text-16 text-2C495D border-b-2 border-2C495D font-500"
                    : "text-848687")
                }
                to={`?LeftTab=${index}`}
                onClick={() => handleClick(index)}
                role="tab"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )}

      <div className={`flex flex-wrap mt-3`}>
       
        <div className={` ${taskExpand ? "hidden" : "w-[70%]"} mt-3`}>
          
          {!taskExpand && myTabs.map((item, index) => (
            <div
              key={index}
              className={`relative flex flex-col mb-2 min-w-0 break-words ${
                openTab === 0 ? "" : " "
              }  ${openTab === index ? "block" : "hidden"}`}
              id={`link${index + 1}`}
            >
              <div className="flex-auto">
                <div className="tab-content tab-space">{item.content}</div>
              </div>
            </div>
          ))}
        </div>

        <div className={` ${taskExpand ? "w-[20%] bg-white p-3" : "hidden"}`}>
          {taskExpand && expandbtn}
          <div className="no-scrollbar h-[90vh] overflow-y-auto">
            <LeftCard handleTaskExpand={TaskExpand} handleClickTab={handleClick}/>
          </div>
        </div>

        <div className={` ${taskExpand ?  "w-[80%]" : "hidden"} ps-4`}>
          <div className="flex justify-between">
            {taskExpand && (
              <ul
                className={`flex w-full list-none flex-wrap px-3 items-center flex-row bg-white border border-1d446933 ${
                  isFixed ? "shadow fixed top-[40px] z-50 w-full" : ""
                }  `}
                role="tablist"
              >
                {taskTabs.map((item, index) => (
                  <li key={index} className="-mb-px mr-2 last:mr-0 text-center">
                    <Link
                      className={
                        "px-3 py-2 block leading-normal " +
                        (taskOpenTab === index
                          ? "font-medium text-2C495D border-b-2 border-2C495D"
                          : "text-848687")
                      }
                      to={`?RightTab=${index}`}

                      onClick={() => handleTaskClick(index)}
                      role="tab"
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            {/* <div>
              <button className="rounded-full bg-2999BC text-white border border-1d446933 py-2 px-6">
                Timeline
              </button>
            </div> */}
          </div>
                  
          <div className="no-scrollbar overflow-y-auto">
            {taskTabs.map((item, index) => (
              <div
                key={index}
                className={`relative flex flex-col min-w-0 break-words   rounded ${
                  taskOpenTab === index ? "block" : "hidden"
                }`}
                id={`tasklink${index + 1}`}
              >
                <div className="pt-4 flex-auto">
                  <div className="tab-content tab-space">{item.content}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
              
        <div className={`${taskExpand ? "hidden" : "w-[30%]"} mt-3 ps-4`}>
          <div className="bg-white p-3">
            {!taskExpand && expandbtn}
            {/* ----------TRACKING---------- */}
            <div
              className={`bg-1d44691a border border-1D446933 mb-3 rounded-5px`}
            >
              <div className="flex justify-between px-3 py-2">
                <button
                    type="button"
                    onClick={() => {
                      setTaskExpand(true);
                      setTaskOpenTab(0);
                    }}
                    className="relative flex"
                  >
                  <h4 className="font-medium text-537596 capitalize cursor-pointer">Tracking</h4>
                </button>  
                <button onClick={onToggle}>
                  <i className={`bi bi-${isOpen ? "chevron-up" : "chevron-down"} `}></i>
                </button>
              </div>
              {isOpen && (
                <AddCutomerOtherDetail />
              )}  
            </div>
                  
            {/* ----------TASK---------- */}
            <div
              id={`${tableDetail?.tableidclass}`}
              className={`bg-1d44691a border border-1D446933 mb-3 rounded-5px ${tableDetail.tableidclass}`}
            >
              <div className="flex justify-between px-3 py-2 mujeeb">
                <Fragment>
                  <button
                    type="button"
                    onClick={() => {
                      setTaskExpand(true);
                      setTaskOpenTab(1);
                    }}
                    className="relative flex"
                  >
                    <h4 className=" font-medium text-537596 capitalize">Task</h4>

                    <span className="bg-white p-2 justify-center shadow-12 rounded-5px overdue-div">
                    {/* {customerDueTableALL?.data?.data && customerDueTableALL?.data?.data.length} */}
                      <p className="text-383838 text-sm">Over Due</p>
                    </span>
                    {/* <span className="overdue-triangle">
                      <i className="bi bi-caret-down-fill text-white text-md"></i>
                    </span> */}
                  </button>
                  <button
                    onClick={openPopup}
                    className="px-1 hover:bg-F1F5F8 hover:rounded-md"
                  >
                    <AddIcon />
                  </button>
                  {isPopupOpen && (
                    <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                      <AddTaskModal closePopup={closePopup} handelSubmitData={handelSubmitData}/>
                    </div>
                    
                  )}
                </Fragment>
              </div>
            
              <div className={`p-3 bg-white rounded-b-lg`}>
                {customerTask?.length>0 && customerTask.map((item) => (
                      <div className="relative" key={item?.customer_id?.value}>
                        <div
                          className={`flex justify-between items-center greybg-hover mb-2 py-1`}
                        >
                          <div
                            className="flex justify-center items-center"
                            style={{ columnGap: "5px" }}
                          >
                            <h4 className="font-lg">
                              <div
                                className="prior-info"
                              >{formatFirstValue(item?.priority?.value)}
                              </div>
                              <span className="bg-white px-2 py-1.5 justify-center shadow-12 rounded-5px prior-div">
                                <p className="text-center text-383838 text-sm">
                                  {item?.priority?.value}
                                </p>
                              </span>
                              <span className="prior-triangle">
                                <i className="bi bi-caret-right-fill text-white text-md"></i>
                              </span>
                            </h4>
                            <p className="text-xs text-2C495D">
                              {item?.start_date?.value && stringData(item?.start_date?.value)
                                }
                            </p>
                          </div>
                        

                          <p className="allhoverelementopennow text-xs text-383838 text-left accounting-ellipsis w-[70px]">
                            
                            {item?.subject?.value}

                            <TextHoverElement
                              ParentId={tableDetail}
                              Data={item?.subject}
                              HoverActiveClass={"allhoverelement"}
                              ClassName={`max-w-[200px]`}
                            />

                          </p>
                          

                          <div className="flex justify-center items-center">
                            <div
                              className="flex justify-center items-center mx-1 bg-3E3E3E text-white p-1 rounded-full text-xs owner-info"
                              style={{ height: "22px", width: "22.5px" }}
                            >
                              {item?.updated_by &&
                            formatValue(item?.updated_by?.value)}
                            </div>

                            <HoverElement
                              data={item}
                              Values={{
                                value1: "created_by",
                                value2: "created_by_email",
                                value3: "created_by_mobile",
                              }}
                              HoverActiveClass={"owner-div"}
                            />
                            <div
                              className={`flex justify-center items-center mx-1 bg-F16136 border border-B63C17 text-white text-xs rounded status-info`}
                              style={{ height: "22px", width: "30px" }}
                            >
                              {item?.status &&
                                formatValue(item?.status?.value)
                              }
                            </div>
                            <span className="bg-white px-2 py-1.5 justify-center shadow-12 rounded-5px status-div">
                              <p className="text-center text-383838 text-sm">
                                {item?.status?.value}
                              </p>
                            </span>
                            <span className="status-triangle">
                              <i className="bi bi-caret-down-fill text-white text-md"></i>
                            </span>
                            <button
                              className="mx-1"
                          
                              onMouseEnter={() =>
                                handleMouseEnterDescription(item?.customer_id?.value)
                              }
                              onMouseLeave={() =>
                                handleMouseLeaveDescription(item?.customer_id?.value)
                              }
                            
                            >
                              <h4>
                                {showIconDesc === item?.customer_id?.value ? (
                                  <i className="relative top-0.5 bi bi-chat-left-dots icon-colorH"></i>
                                ) : (
                                  <i className="relative top-0.5 bi bi-chat-left-dots icon-colorG"></i>
                                )}
                              </h4>
                            </button>

                            <button
                              className="mx-1"
                              onClick={() => {
                                handleButtonClick(item?.customer_id?.value);
                              }}
                            >
                              <h4>
                                {onClickActivity === item?.customer_id?.value ? (
                                  <i className="relative bi-hdd-stack icon-colorH"></i>
                                ) : (
                                  <i className="relative bi-hdd-stack icon-colorG"></i>
                                )}
                              </h4>
                            </button>
                          </div>
                        </div>
                        {hoveredTaskId === item?.customer_id?.value && (
                          <div
                        
                            className="absolute z-50 top-6 w-full"
                            onMouseEnter={() =>
                              handleMouseEnterDescription(item?.customer_id?.value)
                            }
                            onMouseLeave={() =>
                              handleMouseLeaveDescription(item?.customer_id?.value)
                            }
                          >
                            <CustomerTaskHoverDescription
                              description={item.description.value}
                            />
                          </div>
                        )}
                        {onClickActivity === item?.customer_id?.value && (
                          <div
                            ref={popupRefA}
                            className="absolute z-50 top-6 w-full"
                          >
                            <CustomerTaskHoverActivity
                              handleSave={handleSave}
                              taskid={item?.customer_id?.value}
                              closeActivityModal={closeActivityModal}
                            />
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            </div>

            {/* ----------NOTES---------- */}
            <div className="bg-1d44691a border border-1D446933 mb-3 rounded-5px max-h-[540px] overflow-auto">
              <div className="flex justify-between p-1 px-2">
                <Fragment>
                  <button
                    type="button"
                    onClick={() => {
                      setTaskExpand(true);
                      setTaskOpenTab(2);
                    }}
                  >
                    <h4 className="font-medium text-537596 capitalize">Notes</h4>
                  </button>
                  <button
                    onClick={handleNotesClick}
                    className="bg-transparent hover:bg-F1F5F8 rounded-lg px-1"
                  >
                    <AddIcon />
                  </button>

                  {viewNotes?.allnotes && viewNotes?.addnotes && (
                    
                    <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                      <div>kdfjdfkjfdk</div>
                      <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-gray-900 bg-opacity-50">
                        
                        <div className="relative mt-18 bg-white  font-popns drop-shadow-lg  rounded-md max-w-[750px] overflow-hidden no-scrollbar">
                          <div
                            className={`notes m-4 ${
                              Object.values(viewNotes).some((value) => value)
                                ? "show"
                                : ""
                            }`}
                          >
                            <ViewNotesData
                              setNotesSetting={setNotesSetting}
                              notesSetting={notesSetting}
                              handleNotesClick={handleNotesClick}
                              textareaRef={textareaRef}
                              visible={viewNotes.addnotes}
                              selectedFile={selectedFiles}
                              resetFile={resetFile}
                              customerTaskCategory={customerTaskCategory}
                              customerColor={customerColor}
                              handleSubmit={handleSubmit}
                              handleInputChange={handleInputChange}
                              formData={formData}
                              handleSetColour={handleSetColour}

                            >
                              <div
                                className={`grid  ${
                                  viewNotes.addnotes ? "show" : "hidden"
                                }`}
                              >
                                <NotesEditor
                                  textareaRef={textareaRef}
                                  initialContent={initialContent}
                                  setInitialContent={setInitialContent}
                                  notesSetting={notesSetting}
                                  handleFileUpload={handleFileUpload}
                                  selectedFile={selectedFiles}
                                  hndleCloseNotes={handleCloseNotes}
                                  handleClickDeleteFile={handleClickDeleteFile}
                                />
                              </div>
                            </ViewNotesData>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              </div>
              <div
                className={`p-3 bg-white rounded-b-lg`}
                onClick={() => {
                  setTaskExpand(true);
                  setTaskOpenTab(2);
                }}
              >
                
                {latestNotes && latestNotes.map((note, index) => (
                      <div
                        key={note.id}
                        className={`px-3 py-2 greybg-hover ${
                          index !== latestNotes.length - 1
                            ? "border-b-2 border-1d446933"
                            : ""
                        } `}
                      >
                        <div className="flex justify-between">
                          <div className="flex flex-row mb-3 gap-x-1.5">
                            <div className="flex items-center">
                              <h2 className="text-red-500">
                                <i
                                  className="bi bi-person-circle"
                                  style={{ color: "rgb(41, 87, 165)" }}
                                ></i>
                              </h2>
                            </div>
                            <div className="">
                              <p className="mt-1 text-xs text-535252 font-semibold mb-1 capitalize">
                                {note.notes_title}
                              </p>
                              <div className="flex flex-row gap-x-1.5">
                                <p className="text-xs text-F16136 capitalize">
                                  {note.updated_by}
                                </p>
                                <span className="border-2 border-r border-ACC0CC"></span>
                                <p className="text-xs text-F16136">
                                  {note.created_at}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex justify-between items-center border border-B6B6B6 rounded-5px p-1 gap-x-2">
                              <div>
                                <p className={`text-sm text-${note.colour_code}`}>
                                  {note?.category_name || "Action"}
                                </p>
                              </div>

                              <div>
                                <h5 className="font-lg">
                                  <i
                                    className="bi bi-circle-fill"
                                    style={{ color: note.colour_code }}
                                  ></i>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p
                          className="text-xs text-535252"
                          dangerouslySetInnerHTML={{
                            __html: truncateHTML(note.notes_body, 90),
                          }}
                        ></p>
                      </div>
                    ))}
              </div>
            </div>
            <div className="bg-1d44691a border border-1D446933 mb-3 rounded-5px">
              <div className="flex justify-between p-1 px-2">
                <Fragment>
                  <button
                    type="button"
                    onClick={() => {
                      setTaskExpand(true);
                      setTaskOpenTab(4);
                    }}
                  >
                    <h4 className={`font-medium text-537596 capitalize`}>Upcoming Event</h4>
                  </button>
                  <button
                    className="bg-transparent hover:bg-F1F5F8 rounded-lg p-2"
                  >
                     <AddIcon />
                  </button>
                 
                </Fragment>
              </div>

              <div className={`p-3 bg-white rounded-b-lg`}>
                <div className="border border-1D446933 p-2 px-4">
                  <div className="flex flex-wrap justify-center">
                    <div className="">
                      <button
                        className="bg-F2F2F2 rounded-sm p-1"
                        onClick={goToPreviousDate}
                      >
                        <i className="bi bi-chevron-left"></i>
                      </button>
                      <span className="text-sm text-848687 px-12">
                        {formatDate(calendarDate)}
                      </span>
                      <button
                        className="bg-F2F2F2 rounded-sm p-1"
                        onClick={goToNextDate}
                      >
                        <i className="bi bi-chevron-right"></i>
                      </button>
                    </div>

                    <div className="h-full border-s border-1D446933 ms-3 relative">
                      <button
                        type="button"
                        className="text-2999BC hover:text-F16136 focus:outline-none ms-3"
                        onClick={calendarClick}
                      >
                        <span className="relative">
                          <i className="bi bi-calendar-event-fill text-2xl"></i>
                        </span>
                      </button>
                      {calendarOpen && (
                        <Fragment>
                          <DatePicker
                            selected={calendarDate}
                            onChange={calendarDateChange}
                            holidays={[
                              {
                                date: "2024-01-26",
                                holidayName: "Republic Day",
                              },
                              {
                                date: "2024-08-15",
                                holidayName: "Independence Day",
                              },
                              {
                                date: "2024-10-02",
                                holidayName: "Mahatma Gandhi",
                              },
                              { date: "2024-10-31", holidayName: "Diwali" },
                              {
                                date: "2024-12-25",
                                holidayName: "Christmas Day",
                              },
                              {
                                date: "2024-12-31",
                                holidayName: "New Year Eve",
                              },
                            ]}
                            inline
                            filterDate={isWeekday}
                            dayClassName={(date) => {
                              const isWeekend =
                                date.getDay() === 0 || date.getDay() === 6; // Saturday or Sunday
                              return isWeekend ? "satsunweek" : "montofriweek";
                            }}
                            closeOnScroll={true}
                            onClickOutside={() => {
                              setCalendarOpen(false);
                            }}
                            calendarClassName="customercalendar top-full right-0"
                            calendarStartDay={1}
                            // calendarContainer={({ children }) => (

                            //                             )}
                          >
                            <div className=" inline-flex gap-2 ">
                              <div className="">
                                <button
                                  className="bg-1D4469 text-white font-500 text-18 py-2 px-4 "
                                  onClick={() => {
                                    setCalendarDate(new Date());
                                  }}
                                >
                                  Today
                                </button>
                              </div>
                              <div className="">
                                <button
                                  className="bg-F4F4F4 text-535252 border border-EAE2E2 font-500 text-18 py-2 px-4 "
                                  onClick={() => setCalendarOpen(false)}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </DatePicker>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>

                <div className="border border-E8E9EA my-5 p-4 relative">
                  <i className="border-y-[25px] border-transparent border-r-[25px] border-r-2999BC w-0 h-0 rotate-45 absolute top-[-16px] left-[-4px] text-2999BC"></i>

                  <div className="flex justify-between mb-3">
                    <span>{formatDate(calendarDate).slice(0, 2)}</span>
                    <span>
                      <i className="bi bi-plus-circle text-606061"></i>
                    </span>
                  </div>

                  <textarea
                    rows={6}
                    className="text-2C495D bg-F5F0E9 border border-2c495d33 text-sm w-full p-3"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutHOC(AddCustomer1);