import React, { Fragment } from 'react'
import { useState } from 'react';

const Notification = ({closePoup}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [activeSection, setActiveSection] = useState("signals");
    const closePopup = () => {
      setIsVisible(false);
    };
    const handleButtonClick = (section) => {
      setActiveSection(section);
    };
  
    if (!isVisible) {
      return null;
    }
  
    return (
      <Fragment>
      <div className="shadow-2xl rounded-xl px-1 w-[510px] h-[650px] mt-10 bg-white ">
        <div className="pr-2 ">
          <div>
          <button
              onClick={closePoup}
              type="button"
              className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto flex  items-center justify-end pt-4"
              data-modal-hide="assets-modal"
            >
              <i className="bi bi-x  text-[rgba(32, 60, 80, 0.60)] text-2xl"></i>
            </button>
          </div>
          <div className="flex border-b-2 mx-2">
            <h3 className=" mb-2 ml-0 text-203C50 text-18 font-500">
              Notification
            </h3>
          </div>
          <div className="mt-1 ms-6 space-x-2 py-2">
            <button
              className={`text-1D4469 text-12 font-500 p-1 text-center ml-0 hover:rounded-lg hover:bg-D7DFE3 ${
                activeSection === "signals" ? "font-bold" : ""
              }`}
              onClick={() => handleButtonClick("signals")}
            >
              Signals (61)
            </button>
            <button
              className={`  text-12 font-500 text-1D4469 p-1 hover:rounded-lg hover:bg-D7DFE3  ${
                activeSection === "feeds" ? "font-bold" : ""
              }`}
              onClick={() => handleButtonClick("feeds")}
            >
              Feeds (87)
            </button>
          </div>
  
          <div className="overflow-hidden relative">
            <div className="scrollbar-hidden h-[525px] overflow-auto px-2 shadow-inner">
            <div>
            {activeSection === "signals" && (
              <div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">John K.</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400 mt-1">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
  
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">williams</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">Rahul</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">Mayank</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4  mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">Sahil</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">Jais</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">Jordan</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">Tanmay</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
              </div>
            )}
            {activeSection === "feeds" && (
              <div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">Sahil</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">williams</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">Rahul</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">Mayank</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">John K.</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">John K.</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">John K.</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                <div className="w pt-4 flex justify-between border-b-2 ">
                  <svg
                    className="pb-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="86"
                    viewBox="0 0 86 86"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_15543_65026)">
                      <rect width="86" height="86" rx="43" fill="#2C495D" />
                      <path
                        d="M30.2019 67.3397L17.3716 74.3381C16.6187 74.7487 15.9421 75.2533 15.3125 75.8034C22.7929 82.1106 32.4492 85.9157 42.9996 85.9157C53.4722 85.9157 63.0668 82.1674 70.5261 75.9462C69.8381 75.3653 69.0917 74.8428 68.2642 74.4306L54.5253 67.562C52.7501 66.6744 51.6288 64.8603 51.6288 62.8758V57.4854C52.015 57.0456 52.4564 56.481 52.9286 55.8141C54.8011 53.1692 56.2177 50.2598 57.1994 47.2076C58.9616 46.664 60.2597 45.0365 60.2597 43.1023V37.3484C60.2597 36.0827 59.6966 34.9518 58.822 34.1599V25.8422C58.822 25.8422 60.5306 12.8984 43.0013 12.8984C25.4719 12.8984 27.1805 25.8422 27.1805 25.8422V34.1599C26.3043 34.9518 25.7428 36.0827 25.7428 37.3484V43.1023C25.7428 44.6178 26.5396 45.9516 27.7322 46.7224C29.1699 52.9809 32.9344 57.4854 32.9344 57.4854V62.7427C32.9328 64.6575 31.8845 66.4213 30.2019 67.3397Z"
                        fill="#E7ECED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_15543_65026">
                        <rect width="86" height="86" rx="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="ml-4 mb-1">
                    <div className="flex justify-between">
                      <h2 className=" text-1D4469 text-14 font-600">John K.</h2>
                      <span className="text-10 font-500 text-778FA5">Jan 24</span>
                    </div>
                    <p className="text-1D4469 text-12 font-500">
                      Clean Cut Contractors
                    </p>
                    <p className="text-778FA5 text-10 font-400">
                      In publishing and graphic design, Lorem ipsum is a
                      placeholder text commonly used to demonstrate the visual
                      form
                    </p>
                  </div>
                </div>
                
              </div>
            )}
          </div>
            </div>
          </div>
          
        </div>
      </div>
      </Fragment>
    );
  };
  
export default Notification;
  