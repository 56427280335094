import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteData, getData, saveData ,getDataIdBase} from '../../components/store/customer/CustomerSocialAddUpdateFunction';
import { TASKPOD_ADD_UPDATE ,CUSTOMER_TASK_POD_DATA,CUSTMOER_NOTES_DATA_DELETE} from '../constants';
export const saveTaskPod = createAsyncThunk('taskPod/saveTaskPod', async ({taskData ,tokens}, thunkAPI) => {
  try {
     
     const response=await saveData(TASKPOD_ADD_UPDATE,[taskData],tokens);
     return response;
  } catch (error) {
    return thunkAPI.rejectWithValue('Data is not an array or is empty');
  }
});


export const fetchTaskPod=createAsyncThunk('taskPod/fetchTaskPod',async({customerid,tokens},thunkAPI)=>{
  try {
    const response=await getDataIdBase(`${CUSTOMER_TASK_POD_DATA}${customerid}`,tokens);
    if(response?.status===true){
      return response;
    }
  }catch(error){
    return thunkAPI.rejectWithValue('Data is not an array or is empty');
  }
});



export const deleteTaskPod=createAsyncThunk("taskPod/deleteTaskPod",async({id,token},thunkAPI)=>{
  try {
    
     const response=await deleteData(`${CUSTMOER_NOTES_DATA_DELETE}${id}`,token);
    
     if(response?.status===true){
      return response;
     }
  } catch (error) {
    return thunkAPI.rejectWithValue('Data is not an array or is empty');
  }
}) 

const customerTasksPodSlice = createSlice({
  name: 'taskPod',
  initialState: {
    loading: false,
    taskPod: [],
    error: null,
    showMessage: false,
    message: null,
  },
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskPod.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(fetchTaskPod.fulfilled, (state, action) => {
        state.loading = false;
        state.taskPod = action.payload;
        state.error = '';
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(fetchTaskPod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveTaskPod.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(saveTaskPod.fulfilled, (state, action) => {
        state.loading = false;
        state.taskPod=action.payload;
        state.error = '';
        state.showMessage = true;
        state.message = action.payload.message;
       
      })
      .addCase(saveTaskPod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteTaskPod.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTaskPod.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(deleteTaskPod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { showMessage, hideMessage } = customerTasksPodSlice.actions;
export default customerTasksPodSlice.reducer;
