import React, { useState, useEffect } from "react";
import LayoutHOC from "../LayoutHOC";
import UserMyTeam from "./UserMyTeam";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UserProfile from "./UserProfile";

const UserProfileDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lefttabParam = parseInt(queryParams.get("LeftTab")) || 0;
  const [openTab, setOpenTab] = React.useState(lefttabParam);
  const [isFixed, setIsFixed] = useState(false);
  
  
  const UserNav = [
    { label: "Personal Setting", content: <UserProfile /> },
    { label: "My Team", content: <UserMyTeam /> },
  ];

  const handleScroll = () => setIsFixed(window.scrollY > 120);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (index) => {
    setOpenTab(index);
    if (index !== 0) {
      const contentElement = document.getElementById(`link${index + 1}`);
      
      return false;
      contentElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  useEffect(()=>{
    setOpenTab(0);
  },[]);

  return (
    <div className="p-5">
      <div className={`flex flex-col pt-2`}>
        <div className={`flex justify-between items-center px-2`}>
          <h5
            className={`text-203C50 font-Vietnam text-[24px] font-medium mt-5`}
          >
            Jagjeet Singh
          </h5>
          <div className={`flex items-center`}>
            <label className={`inline-flex items-center cursor-pointer`}>
              <input type={`checkbox`} className={`sr-only peer`} />
              <div
                className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
              ></div>
            </label>
          </div>
        </div>
      </div>

      <div>
        <ul
          className={`flex mb-0 list-none flex-wrap px-3 items-center flex-row bg-white border border-1d446933 ${
            isFixed ? "shadow fixed top-[43px] z-50 w-11/12" : "w-full"
          }`}
          role="tablist"
        >
          {UserNav.map((item, index) => (
            <li
              key={index}
              className=" mr-2 last:mr-0 text-center text-848687 text-16 font-400"
            >
              <Link
                className={
                  "py-2 px-3 block leading-normal" +
                  (openTab === index
                    ? "text-16 text-2C495D border-b-2 border-2C495D font-500"
                    : "text-848687")
                }
                to={`?LeftTab=${index}`}
                onClick={() => handleClick(index)}
                role="tab"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={` mt-3`}>
        {UserNav.map((item, index) => (
          <div
            key={index}
            className={`relative flex flex-col mb-2 min-w-0 break-words ${
              openTab === 0 ? "" : " "
            }  ${openTab === index ? "block" : "hidden"}`}
            id={`link${index + 1}`}
          >
            <div className="flex-auto">
              <div className="tab-content tab-space">{item.content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LayoutHOC(UserProfileDetails);
