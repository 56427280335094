import React,{useState,useEffect} from "react"
import ReactDOm from "react-dom"
import { useNavigate } from 'react-router-dom';
import useRequireAuth from "../../utils/useRequireAuth";
import { addParticularCompany } from '../../utils/userSlice';
import { useDispatch } from 'react-redux'; 
import { useFetchCompanyListQuery } from "../../utils/company/companySelector";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import useDebouncedEffect from "../customer/useDebouncedEffect";
import Leftmenu from "../Leftmenu";
import usePermissions from "../../hooks/User/usePermissions";

const   Options = () => {
  const { permissions, hasPermission } = usePermissions();

    const Navigate = useNavigate();
    const {token} = useRequireAuth();
    const dispatch = useDispatch();  
    const [showDeleteButton, setShowDeleteButton] = useState(null);
    let userToken = token || JSON.parse(localStorage.getItem("myUserData")).token;
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [queryParams, setQueryParams] = useState({
      pagination: `page=${currentPage}`,
      token: userToken
    });
    const [menuKey, setMenuKey] = useState(0);

    const[company,setCompany]=useState([]);

    const {
      data: companyCommnData,
      error,
      isLoading,
    } = useFetchCompanyListQuery(userToken);

    useEffect(() => {
        if (companyCommnData) {
          setCompany(companyCommnData?.data);
        
        }
      }, [companyCommnData, userToken]);


    useDebouncedEffect(
        () => {
          setQueryParams({
            pagination: `page=${currentPage}`,
            token: userToken,
          });
        },
        500,
        [currentPage, userToken]
      );
    
      useEffect(() => {
        setLoading(true);
      }, [queryParams]);



    const handleClick = () => {
        Navigate("/add_company");
    };
    const handleLoginClick = (companyId,company_name,company_logo,owner) => {

      dispatch(addParticularCompany({company_id: companyId, company_name: company_name,company_logo:company_logo,menuName:"",owner:owner}));
      Navigate('/customers');
      setMenuKey(prevKey => prevKey + 1);
    };
    const handleMouseEnter = (id) => {
      setShowDeleteButton(id);
    };
    
    const handleMouseLeave = () => {
      setShowDeleteButton(null);
    };
    if (isLoading) return <div>Loading...</div>;
   
    return (
        <>
            <div className="w-9/12 mt-11 customscrollbar overflow-y-auto h-[500px]">
                <div className="w-11/12">
                    <button className="w-full p-5 bg-white rounded-[5px] hover:shadow h-[79px] font-400 text-center text-545B61 createCmp text-20 hover:text-F16136 mb-4" onClick={handleClick}>+ Create Company</button>
                </div>
                {Array.isArray(company) &&
                company.map((ProfileData) => (
                  <div key={ProfileData.id} className="flex mt-3" onMouseEnter={() => handleMouseEnter(ProfileData.id)} onMouseLeave={handleMouseLeave}>
                      <div className="flex gap-3 w-11/12">    
                          <div className=" visble bg-white text-14 text-545B61 font-400 hover:bg-1D4469 hover:text-white rounded-[5px] border h-[65px] border-2c495d33 border-opacity-20 text-left px-5 hover:text-left w-full  cbtn-transition-bg">
                              <div className="flex justify-between items-center px-3 py-1 w-full">
                                      <div className=" relative">
                                        <text className="w-3/5 text-xl font-normal ">{ProfileData.company_name} {showDeleteButton === ProfileData.id && "|| " + ProfileData.created_at}</text>
                                      </div>  
                                      <div className="visble ">
                                        <button className=" my-2 py-1 px-5 border border-DFDFDF rounded-sm justify-center option-log items-center gap-2.5 hover:rounded-full hover:bg-white hover:text-1D5D9B "  onClick={() => handleLoginClick(ProfileData.id,ProfileData.company_name,ProfileData.company_logo,ProfileData.owner)}>
                                          <div className=" text-20 font-400 text-434343 font-['Poppins'] hover:text-1D5D9B ">Login</div>
                                        </button>                   
                                      </div>
                                  </div>
                              </div>
                              <div className="mt-16 hid ">
                                  <button><i className=" text-orange-400 text-xl shadow-md p-3 bi bi-trash3 bg-white rounded-md"></i></button>
                              </div>
                          </div> 
                          {/* <div className={`flex items-center ${showDeleteButton === ProfileData.id ? "visible m-0" : "invisible m-0"}`}>
                              <button className="rounded border-1 border-BFBFBF bg-white p-2 ms-5">
                                  <i className="bi bi-trash3 text-D93916"></i>
                              </button>
                          </div> */}
                    </div>
                  ))
                } 
              </div>
        </>
    )
}


export default Options