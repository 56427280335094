import React, { useState,useEffect } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import OwnerDropDown from "../GlobalFeature/Fields/OwnerDropDown";
import { saveData } from "../store/customer/CustomerSocialAddUpdateFunction";
import { ADD_UPDATE_DELEGATION ,GET_DELEGATION_DATA_EDITE} from "../../utils/constants";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
const AddUpdateCompanyDelegation = ({ isOpen, onClose, handleSubmitData,editData }) => {
  const { token } = useRequireAuth();
  const { id } = useParams();
  const customerId = parseInt(id);
  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [message,setMessage]=useState(null);
  const [errors, setErrors] = useState({});
  const [show,setShow]=useState('');

  const [formData, setFormData] = useState({
    email: "",
    owner: 0,
  });

  const fetchTaskEditData = async (editData) => {
    const url = `${GET_DELEGATION_DATA_EDITE}${editData}`;
    const getData = await getCommonDataBasedOnId(url, token);
    if(getData?.data && getData?.data?.length>0){
      setFormData(getData?.data[0]);
    }
  
   
  };

  useEffect(() => {
    fetchTaskEditData(editData);
  }, [editData]);

  const handlePersonData = (value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      email: value?.email,
    }));
  };
  


  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validate = () => {
    let tempErrors = {};

    if (!formData?.email) {
      tempErrors.email = 'Email is required';
    }
    if (!formData?.owner) {
      tempErrors.owner = 'access is required';
    } 

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (!validate()) {
        return false;
      }
   
    const socialData = {
      company_id: parsedCompanyData.company_id,
      owner:formData?.owner,
      email:formData?.email,
      id:formData.id
    };
   
    try {
    
     const response=await saveData(ADD_UPDATE_DELEGATION,[socialData],token);
     if(response?.success===true){
        onClose();
        handleSubmitData();
        setMessage(response?.message);
     }else{
        setShow(response?.message);
        setMessage(response?.message);
     }
     
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const [fetchPersonInTable, { data: personTableData }] =
    useLazyFetchPersonInTableQuery();

  const fetchPersonData = (data) => {
    fetchPersonInTable({ token: token, data: data });
  };
  const tableDetail = {
    tableidclass: "taskmodelowner",
  };

  const handleInsert = () => {};

  setTimeout(() => {
    setMessage(null);
  }, 5000);
  setTimeout(()=>{
    setShow('');
  },4000)
  

  return (
    <div className="fixed inset-y-0 z-[999] grid h-screen items-center justify-start">
      <div className="relative  py-3 mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md w-500px overflow-hidden no-scrollbar">
        <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
          <div className="px-2"></div>
          <br />
          <h3 className="text-2xl text-2C495D">Add Update Manage Access</h3>

          <button
            onClick={onClose}
            type="button"
            className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <form
          onSubmit={handleSave}
          className="w-full lg:max-h-[70vh]  overflow-x-hidden  no-scrollbar"
        >
          <div className="p-3 md:px-6">
            <label
              htmlFor="chart_of_accounts_id"
              className="block mb-2 text-sm text-434852"
            >
              Email <span className=" text-red-500"> *</span>
              {errors && formData?.email==='' &&  (
                <span className="text-red-700"> {errors?.email}</span>
              )}
            </label>

            <OwnerDropDown
              tableidclass={tableDetail.tableidclass}
              fetchPersonInTable={fetchPersonData}
              DropData={personTableData}
              handleInsert={handleInsert}
              handlePersonData={handlePersonData}
              owner={formData?.email}
            />
             {show && (<span className="text-red-700">{show}</span>)}
          </div>

          <div className="basis-5/5 mb-3 px-6 pt-2 ">
            <label
              htmlFor="Designation"
              className="block mb-2 text-sm text-434852"
            >
              Access <span className=" text-red-500"> *</span>
              {errors && (
                <span className="text-red-700"> {errors?.owner}</span>
              )}
            </label>
            <select
              id={`owner`}
              name="owner"
              onChange={handleFieldChange}
              className={`form-select border border-BFBFBF text-383838 text-md  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1`}
              
            >
               <option value="0" selected={parseInt(formData?.owner)===0}>Contributor</option>
              <option value="1" selected={parseInt(formData?.owner)===1}>Owner</option>
             
            </select>
          </div>
          <div className="flex justify-end py-4  modal-footer text-md w-full">
          {message && <p className="text-green-500 text-right	">{message}</p>}
            <div className="px-2">           
              <button
                onClick={onClose}
                type="button"
                className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-1 text-center rounded-5px"
              >
                CANCEL
              </button>
              {editData ?(
                 <button
                 type="submit"
                 className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold border border-gray-200 py-1 px-5 ms-3 rounded-5px"
               >
                 UPDATE
               </button>
              ):(
                  <button
                  type="submit"
                  className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold border border-gray-200 py-1 px-5 ms-3 rounded-5px"
                >
                  SAVE
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUpdateCompanyDelegation;
