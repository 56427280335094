import React from "react";
import Header from "./Header";
import CreateDashboard from "./CreateDashboard";
import HeaderDashboard from '../HeaderDashboard';

const dashboardMain = ()=>{
    return(
        <>
            <div className="flex justify-around ">
                <HeaderDashboard />
                <div className="ps-5 pe-32 fixed w-full mt-14">
                    <CreateDashboard/>
                </div>
            </div>
        </>
    )
}
export default dashboardMain
