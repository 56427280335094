import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  saveData,
 
} from "../../components/store/customer/CustomerSocialAddUpdateFunction";
import {
 
    DELETE_VENDOR_CONTACT_DATA,
  ADD_VENDOR_CONATACT_PERSON

} from "../constants";

export const saveVendorContact = createAsyncThunk(
  "vendorContact/saveVendorContact",
  async ({ vendorContactData, token }, thunkAPI) => {
    try {
      const response = await saveData(
        ADD_VENDOR_CONATACT_PERSON,
        [vendorContactData],
        token
      );
      if (response.status === true) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);



export const deleteVendorContact = createAsyncThunk(
  "vendorContact/deleteVendorContact",
  async ({ id, token }, thunkAPI) => {
    try {
      const response = await deleteData(
        `${DELETE_VENDOR_CONTACT_DATA}${id}`,
        token
      );

      if (response?.status === true) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);

const vendorContactSlice = createSlice({
  name: "vendorContact",
  initialState: {
    loading: false,
    vendorContact: [],
    error: null,
    showMessage: false,
    message: null,
  },
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    setVendor: (state, action) => {
      state.vendors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
     
      .addCase(saveVendorContact.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(saveVendorContact.fulfilled, (state, action) => {
        state.loading = false;
        state.vendors = action.payload;
        state.error = "";
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(saveVendorContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteVendorContact.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteVendorContact.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(deleteVendorContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { showMessage, hideMessage, setVendor } = vendorContactSlice.actions;
export default vendorContactSlice.reducer;
