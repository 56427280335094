import React, { useState, useRef, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import CustomerTaskHoverDescription from "./CustomerTaskHoverDescription";

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = date < 10 ? `0${date}` : date;
  return `${formattedMonth}/${formattedDate}/${year}`;
}

const formatchangeDateformate = (dateString) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const parts = dateString.split("-");
  const day = parseInt(parts[0], 10);
  const monthIndex = parseInt(parts[1], 10) - 1;
  const year = parts[2].slice(-2);
  const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;
  return formattedDate;
};

const CustomerListTaskPopup = ({ isPopupOpen, setIsPopupOpen }) => {
  const navigate = useNavigate();
  const handleClickToTask = (id) => {
    // navigate(`/add_customer1/${id}`);
    navigate(`/customers`);
  };
  const [hoveredTaskId, setHoveredTaskId] = useState(null);
  const handleMouseEnterDescription = (id) => {
    setHoveredTaskId(id);
    setShowIconDesc(id);
  };

  const handleMouseLeaveDescription = (id) => {
    if (hoveredTaskId === id) {
      setHoveredTaskId(null);
      setShowIconDesc(false);
    }
  };

  const [showIconDesc, setShowIconDesc] = useState(false);
  const [customerTask, setCustomerTask] = useState([]);
  const [openTask, setOpenTask] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  const itemsPerSegment = 4;
  const totalItems = customerTask.length;
  const startIndex = Math.max(0, totalItems - itemsPerSegment);
  const endIndex = totalItems;
  const currentData = customerTask.slice(startIndex, endIndex);

  return (
    <>
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
          <div className="bg-black bg-opacity-50 absolute inset-0">
            <div
              ref={popupRef}
              className="rounded-lg mx-auto w-fit drop-shadow-lg overflow-hidden no-scrollbar"
              style={{ marginTop: "18%" }}
            >
              <table className="bg-grey-200">
                <thead className="customerTask text-13 font-400!">
                  <tr className="bg-F1F6F9 px-4 py-1.5 w-550px flex justify-around items-center">
                    <th scope="col">Date</th>
                    <th scope="col">Type</th>
                    <th scope="col">Priority</th>
                    <th scope="col">
                      <>
                        <button type="button" onClick={handleClickToTask}>
                          <h3 className="">
                            <i className="bi bi-box-arrow-up-right text-383838"></i>
                          </h3>
                        </button>
                        {/* {openTask && <AddCustomer1 />} */}
                      </>
                    </th>
                  </tr>
                </thead>
                <tbody className="customerTask text-12">
                  {currentData.map((task) => (
                    <Fragment key={task?.id}>
                      <tr className="py-1 bg-white flex px-4 w-550px justify-around items-center">
                        <td>
                          {formatchangeDateformate(task?.end_date?.value)}
                        </td>
                        <td>{task?.subject?.value}</td>
                        <td>
                          <span
                            className="inline-block rounded-full w-20 text-white py-0.5"
                            style={{
                              color: task?.task_priority_name?.colour_code,
                            }}
                          >
                            {task?.task_priority_name?.value}
                          </span>
                        </td>
                        <td className="flex justify-center items-center gap-x-3.5 w-fit">
                          <h2>
                            <button
                              className="mx-1"
                              onMouseEnter={() =>
                                handleMouseEnterDescription(customerTask.id)
                              }
                              onMouseLeave={() =>
                                handleMouseLeaveDescription(customerTask.id)
                              }
                            >
                              <h4>
                                {showIconDesc === customerTask.id ? (
                                  <i className="relative top-0.5 bi bi-chat-left-dots icon-colorH"></i>
                                ) : (
                                  <i className="relative top-0.5 bi bi-chat-left-dots icon-colorG"></i>
                                )}
                              </h4>
                            </button>
                          </h2>
                          <h2>
                            <i className="bi bi-hdd-stack text-383838"></i>
                          </h2>
                        </td>
                        {hoveredTaskId === customerTask.id && (
                          <div
                            className="absolute z-50 top-6 w-full"
                            onMouseEnter={() =>
                              handleMouseEnterDescription(customerTask.id)
                            }
                            onMouseLeave={() =>
                              handleMouseLeaveDescription(customerTask.id)
                            }
                          >
                            <CustomerTaskHoverDescription
                              description={customerTask.description.value}
                            />
                          </div>
                        )}
                      </tr>
                      <hr className="border border-E5E7EB"></hr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerListTaskPopup;
