// data.js
export const tableData = [
    {
      customer: 'John Doe',
      contact: '345',
      banking: 'USD',
      payrollAlerts: 'Active',
      prepTaxes: 'Technology',
      taxReturn: '20'
    },
    {
      customer: ' Doe',
      contact: '123',
      banking: 'USD',
      payrollAlerts: 'Active',
      prepTaxes: 'Technology',
      taxReturn: '50'
    },
    // Add more sample data as needed
  ];