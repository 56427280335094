import React, { useState, useEffect, useRef } from "react";
import { BASE_URL, EDIT_PROFILE,COMMON_URL } from "../../utils/constants";
import useRequireAuth from "../../utils/useRequireAuth";
import SuccessComponent from "./SuccessComponent ";
import Shimmer from "../Shimmer";
import { saveData } from "../store/CompayProfileFuction/CompanyProfileAllFuction";
import { getUserTokeData } from "../../utils/userSlice";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";

import SaveEdit from "../customer/SaveEdit";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addParticularCompany } from "../../utils/userSlice";

function EditCompanyProfile({ companyData ,businessCategoryData ,countryData, currencyData,initialCompanyGroup }) {
  const {token} = useRequireAuth();
  const [selectedImagenew, setSelectedImagenew] = useState(null); 
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isFormFrozen, setIsFormFrozen] = useState(true);
  const [companyGroup, setCompanyGroup] = useState(initialCompanyGroup);
  const [website, setWebsite] = useState(
    companyData?.data?.company_website
  );
  const [legalName, setlegalName] = useState(
    companyData?.data?.legal_name || ""
  );
  const [phoneNo, setPhoneNo] = useState(
    companyData?.data?.company_phone_no || ""
  );
  const [email, setEmail] = useState(
    companyData?.data?.company_email || ""
  );
  const [uploadedImg, setUploadedImg] = useState(
    companyData?.data?.company_logo || ""
  );
  const location=useLocation();
  const dispatch=useDispatch();
  useEffect(() => {
    if(location.key){
   
     dispatch(getUserTokeData());
    }
 }, [location.key]);

  const [updatedCompanyData, setUpdatedCompanyData] = useState(null);  
  const [inputValue, setInputValue] = useState("");
  const [responseData, setResponseData] = useState(null);

  const [submitted, setSubmitted] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    company_name: "",
    display_name: "",
    country_id: "",
    company_logo: null,
  });

  const [imageSrc, setImageSrc] = useState('');

  const fileInputRef = useRef(null);
 
  useEffect(() => {
    setWebsite(companyData?.data?.company_website || "");
    setlegalName(companyData?.data?.legal_name || "");
    setPhoneNo(companyData?.data?.company_phone_no || "");
    setEmail(companyData?.data?.company_email || "");
    setUpdatedCompanyData(companyData);
  }, [companyData]);

  useEffect(() => {
    setImageSrc(uploadedImg);
  }, []);
  
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };
  
  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => setImageSrc(reader.result);
    reader.readAsDataURL(file);
  };
  
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = (event) => {
    const { name, value, type, files } = event.target;
    setInputValue(value);
    if (type === 'file') {
      const file = files[0];
     
      setSelectedImagenew(URL.createObjectURL(file));
      handleFileUpload(file);
      setFormData({ ...formData, company_logo: file });
      setSelectedImage(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, [name]: value });
      if (name === "website") setWebsite(value);
      if (name === "legal_name") setlegalName(value);
      if (name === "company_phone_no") setPhoneNo(value);
      if (name === "company_email") setEmail(value);
    }
  };
  const handleProfileSave = async (event) => {
    event.preventDefault();
    try {
      const Authtoken = token;
      const formDataWithFile = new FormData();
      const file = fileInputRef.current.files[0];
      formDataWithFile.append("profile_id", companyData?.data?.id);
      formDataWithFile.append("company_group",event.target.company_group.value);
      formDataWithFile.append("legal_name",event.target.legal_name.value);
      formDataWithFile.append("company_logo",file??companyData?.data?.company_logo);
      formDataWithFile.append("company_type",event.target.type.value);
      formDataWithFile.append("company_website",event.target.website.value);
      formDataWithFile.append("currency_id",event.target.currency_id.value);
      formDataWithFile.append("business_category",event.target.business_category.value);
      const response = await saveData(EDIT_PROFILE, formDataWithFile, Authtoken);
      setSelectedImage(null);
      setUploadedImg(response.data.company_logo);
      setIsDivVisible(!isDivVisible);
      setAlert({
        visible: true,
        message: "Record Update Successfully",
        type: "success",
      });
      
      setSubmitted(true);
    } catch (error) {
      setAlert({
        visible: true,
        message: "An error occurred while submitting the form.",
        type: "success",
      });
      //setError("An error occurred while submitting the form.");
    }
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    setPhoneNo(numericValue);
  };
  
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    setIsValidEmail(validateEmail(inputValue));
  }

  
  const handleButtonClick = () => {
    setIsDivVisible(!isDivVisible);
    setIsFormFrozen(!isFormFrozen);
  };
  const handleButtonClickShowEdit = () => {
    setIsDivVisible(!isDivVisible);
    setIsFormFrozen(!isFormFrozen);
  };

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  
  return (
    <div className="bg-white mb-3">
      {companyData ? (
        <form
          onSubmit={handleProfileSave}
          id="companyProfileForm"
          encType="multipart/form-data"
        >
          <FirstMessage
            type={alert.type}
            message={alert.message}
            visible={alert.visible}
            onHide={hideAlert}
          />
          <div class="flex flex-col md:flex-row px-8 mb-3 pt-4">
              <div class="w-full md:w-1/5 lg:w-1/5">
                <div
                  htmlFor="dropzone-file"
                  className="w-44 p-1 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100"
                >
                  <div
                    onDrop={handleDrop}
                    onClick={handleClick}
                    className="flex items-center h-40 w-40 text-center text-gray-500"
                  >
                  {
                  (selectedImagenew || companyData.data.company_logo)?(
                    <div className="relative w-full h-full">
                      <img
                          src={
                            selectedImagenew
                              ? `${selectedImagenew}`
                              : `${BASE_URL}${companyData.data.company_logo}`
                          }
                        alt="Uploaded"
                        className="w-full h-full"
                      />
                      <button className="upload-button absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-lg font-semibold opacity-0 transition-opacity duration-600 hover:opacity-75">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                        </svg>
                      </button>
                    </div>
                  ):(
                    <div className="relative w-full h-full empsec">
                      <button className="upload-button absolute inset-0  opacity-100 flex items-center justify-center text-black text-lg font-semibold transition-opacity duration-600 hover:opacity-75">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                          </svg>
                      </button>
                    </div>  
                  )
                  }
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div class="w-full md:w-4/5 lg:w-4/5 mt-4 md:mt-0 md:ml-4">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
                  <div className="mb-2">                    
                    <label 
                      htmlFor="company_name" 
                      className="mb-2 text-535252 text-14 font-500 flex justify-between"><span>Name <span className=" text-red-600">*</span></span>
                    </label>
                    <input
                      className={`border border-gray-300 text-xs block w-full p-2.5 rounded-5px ${isFormFrozen ? 'text-gray-400' : ''}`}
                      type="text"
                      name="company_name"
                      value={companyData?.data?.company_name}
                      onChange={handleInputChange}
                      disabled={submitted}
                      required
                    />
                    {responseData?.duplicate ? (
                      <div>
                        <span className="text-red-500 text-xxs">
                          {responseData?.message}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span className="text-green-500 text-xxs">
                          {responseData?.message}
                        </span>
                      </div>
                    )}
                  </div>
               
                  <div className="mb-2">
                    <label
                      htmlFor="company_group"
                      className="block mb-2 text-535252 text-14 font-500 rounded-5px"
                    >
                      Company Group <span className=" text-red-600">*</span>
                    </label>
                    <select
                      id="company_group"
                      name="company_group"
                      className={`border border-gray-300 text-gray-900 text-xs block w-full px-2.5 py-0.5 ${isFormFrozen ? 'text-gray-400' : ''}`}                      
                      onChange={handleInputChange} 
                      disabled={isFormFrozen}              
                    >
                      {companyGroup.map((companyGR) => (
                        <option key={companyGR.id} value={companyGR.company_group} selected={companyGR.company_group===companyData?.data?.company_group}>
                          {companyGR.company_group}
                        </option>
                      ))}
                      
                    </select>
                  </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
                  <div className="mb-2">
                  <label
                    htmlFor="Display Name"
                    className="block mb-2 text-535252 text-14  font-500 "
                  >
                    Cust. Display Name*
                  </label>
                  <input
                    type="text"
                    name="display_name"
                    value={companyData?.data?.display_name}
                    onChange={handleInputChange}
                    className={`border border-gray-300 text-xs  block w-full p-2.5 rounded-5px ${isFormFrozen ? 'text-gray-400' : ''}`}
                    disabled={submitted}
                    required
                  />
                  </div>
                  <div className="mb-2">
                    <div className="mb-2">
                      <label htmlFor="types" className="block mb-2 text-535252 text-14 font-500">
                        Type
                      </label>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <label>
                        <input
                          type="radio"
                          name="type"
                          value="business"
                          checked={
                            updatedCompanyData?.data?.company_type === "business"
                          }
                          onChange={handleInputChange}
                          className="hidden"
                        />

                        <button
                          type="button"
                          disabled={isFormFrozen}
                          className={`w-full px-2 py-1 text-535252 text-14 rounded-5px border cbtn-transition-bg hover:bg-gray-300 focus:outline-none option  ${
                            updatedCompanyData?.data?.company_type === "business"
                              ? "bg-sky-900 text-white border-2C495D font-500 active"
                              : "border"
                          }`}
                          onClick={() => {
                            setFormData({ ...formData, type: "business" });
                            setUpdatedCompanyData({
                              ...companyData,
                              data: {
                                ...companyData.data,
                                company_type: "business",
                              },
                            });
                          }}
                        >
                          Business
                        </button>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="type"
                          value="individual"
                          checked={
                            updatedCompanyData?.data?.company_type ===
                            "individual"
                          }
                          onChange={handleInputChange}
                          className="hidden"
                        />
                        <button
                          type="button"
                          disabled={isFormFrozen}
                          className={`w-full px-2 py-1 text-535252 text-14 rounded-5px border cbtn-transition-bg hover:bg-gray-300 focus:outline-none option ${
                            updatedCompanyData?.data?.company_type ===
                            "individual"
                              ? "bg-sky-900 text-white border-2C495D font-500 active"
                              : "border"
                          }`}
                          onClick={() => {
                            setFormData({ ...formData, type: "individual" });
                            setUpdatedCompanyData({
                              ...companyData,
                              data: {
                                ...companyData.data,
                                company_type: "individual",
                              },
                            });
                          }}
                        >
                          Individual
                        </button>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="type"
                          value="prospect"
                          checked={
                            updatedCompanyData?.data?.company_type === "prospect"
                          }
                          onChange={handleInputChange}
                          className="hidden"
                        />
                        <button
                          type="button"
                          disabled={isFormFrozen}
                          className={`w-full px-2 py-1 text-535252 text-14 rounded-5px border cbtn-transition-bg hover:bg-gray-300 focus:outline-none option ${
                            updatedCompanyData?.data?.company_type === "prospect"
                              ? "bg-sky-900 text-white border-2C495D font-500 active"
                              : "border"
                          }`}
                          onClick={() => {
                            setFormData({ ...formData, type: "prospect" });
                            setUpdatedCompanyData({
                              ...companyData,
                              data: {
                                ...companyData.data,
                                company_type: "prospect",
                              },
                            });
                          }}
                        >
                          Prospect
                        </button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            <div class="grid grid-cols-12 gap-4 px-8 mb-5">
              <div class="col-span-4">
                <label
                  htmlFor="Website"
                  className="block mb-2  text-535252 text-14  font-500"
                >
                  Website
                </label>

                <input
                  value={website}
                  className={`border border-gray-300 text-xs block w-full p-2.5 rounded-5px ${isFormFrozen ? 'text-gray-400' : ''}`}
                  type="text"
                  name="website"
                  onChange={handleInputChange}
                  readOnly={isFormFrozen}
                />
              </div>
              <div class="col-span-4">
                <label
                  htmlFor="Legal Name"
                  className="block mb-1  text-535252 text-14  font-400  "
                >
                  Legal Name
                </label>

                <input
                  className={`border border-gray-300 text-xs block w-full p-2.5 rounded-5px ${isFormFrozen ? 'text-gray-400' : ''}`}
                  type="text"
                  name="legal_name"
                  value={legalName}
                  onChange={handleInputChange}
                  readOnly={isFormFrozen}
                />
              </div>
              <div class="col-span-4">
                <label
                  htmlFor="Business Category"
                  className="block mb-1 text-535252 text-14  font-400  "
                >
                  Business Category
                </label>
                <select
                  id="business_category"
                  name="business_category"
                  className={`border border-gray-300 text-gray-900 text-xs block w-full px-2.5 py-0.5 ${isFormFrozen ? 'text-gray-400' : ''}`}
                  disabled={isFormFrozen}
                  onChange={handleInputChange}
                
                >
                  {!businessCategoryData
                    ? null
                    : businessCategoryData.map((CategoryData) => (
                        <option
                          key={CategoryData.id}
                          value={CategoryData.id}
                          selected={
                            CategoryData.id ===
                            companyData?.data?.business_category
                          }
                        >
                          {CategoryData.business_category}
                        </option>
                      ))}
                </select>
              </div>
              <div class="col-span-4">
                <label
                  htmlFor="currency_id"
                  className="block mb-1 text-535252 text-14  font-400  "
                >
                  Currency
                </label>
                <select
                  id="currency_id"
                  name="currency_id"
                  className={`border border-gray-300 text-gray-900 text-xs block w-full px-2.5 py-0.5 ${isFormFrozen ? 'text-gray-400' : ''}`}
                  disabled={isFormFrozen}
                  onChange={handleInputChange}
                >
                  {!currencyData
                    ? null
                    : currencyData.map((CurrencyData) => (
                        <option
                          key={CurrencyData.id}
                          value={CurrencyData.id}
                          selected={
                            CurrencyData.id === companyData?.data?.currency_id
                          }
                        >
                          {CurrencyData.currency_name_symbol}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            <div className="flex font-poppins px-8 py-3 justify-end">
              <div>
                {/* <span className="p p-1 text-green-400">
                {" "}
                <SuccessComponent />{" "}
              </span> */}
                {submitted && (
                  <span className="p p-1 text-green-400">{successMessage}</span>
                )}
              </div>
              <div className="flex gap-4">
              {isDivVisible && (
                <span>
                  <button 
                    id="btncan_compp" 
                    className="px-6 py-1 text-base rounded font-normal bg-F4F4F4 focus:outline-none me-2"
                    onClick={handleButtonClickShowEdit}
                  >
                    CANCEL
                  </button>
                  <button id="btnup_compp" type="submit" className="px-6 py-1 text-base rounded font-normal bg-1D4469 text-white focus:outline-none"
                  >
                    UPDATE
                  </button>
                </span>
                )}
                {!isDivVisible && (
                  <button
                    id="editcan_compp"
                    type="button"
                    className="px-6 py-1 text-base rounded font-normal bg-1D4469 text-white focus:outline-none"
                    onClick={handleButtonClick}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
        </form>
      ) : (
        <Shimmer width="100%" height="100px" />
      )}
    </div>
  );
}
export default EditCompanyProfile;