const serviceHeader = [
    {
        "service_name": "Service",
        "service_type_status": 1
    },
    {
        "descriptions": "Description",
        "description_status": 1
    },
    {
        "action": "Action",
        "action_status": 1
    },
]


export default serviceHeader