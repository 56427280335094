import React, { Fragment, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import LayoutHOC from "../LayoutHOC";
import {
  useUpdateCustomerTaskTableDataMutation,
  useFetchVendorListQuery,
} from "../../utils/customer/customerTaskTable";

import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import CustomerListTable from "../customer/CustomerListTable";
import VendorHeader from "./VendorHeader";
import { useNavigate } from "react-router-dom";

const VendorList = () => {
  const [initialFilter, setInitialFilter] = useState([]);
  const [customer, setCustomer] = useState([]);
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const { token } = useRequireAuth();
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const navigate = useNavigate();

  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchVendorListQuery({
      companyId: parsedCompanyData.company_id,
      token: token,
    });

  useEffect(() => {
    if (customerTableALL) {
      const { data, ...restData } = customerTableALL?.data;
      setCustomer(data);
      setInitialFilter(restData);
    }
  }, [customerTableALL, customerTablePagination, refetchCustomerData]);

  const fetchPersonData = (data) => fetchPersonInTable({ token, data });
  const [fetchPersonInTable, { data: personTableData }] =
    useLazyFetchPersonInTableQuery();

  const [updateCustomerTaskTableData, { CustomerDropLoad, CustomerDroperror }] =
    useUpdateCustomerTaskTableDataMutation();

  //   const customerTableFieldUpdate = async (field_name, value) => {

  //     let datevalue;
  //     if (
  //       value?.field_name === "start_date" ||
  //       value?.field_name === "end_date"
  //     ) {
  //       datevalue = stringData(field_name.value);
  //     } else {
  //       datevalue =
  //         value?.field_name === "owner" ? field_name.name : field_name.value;
  //     }
  //     const updateData = {
  //       field_name: value?.field_name,
  //       value: datevalue,
  //       id: value?.id,
  //     };

  //     try {
  //       const result = await updateCustomerTaskTableData({
  //         token: token,
  //         data: updateData,
  //       }).unwrap();

  //       refetchCustomerData();

  //       setAlert({
  //         visible: true,
  //         message: result?.data?.original?.error || result?.message,
  //         type: result?.data?.original?.error ? "danger" : "success",
  //       });
  //     } catch (error) {
  //       setAlert({
  //         visible: true,
  //         message: "Error updating data!",
  //         type: "danger",
  //       });
  //     }
  //   };

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  const activityClosed = (val) => {
    refetchCustomerData();
  };

  const openPopup = () => {};

  const handleNavigate = (customer_id) => {
  
    navigate(`/vendor_dashboard/${customer_id}`);
  };

  return (
    <Fragment>
      <nav className="">
        <div className="container ">
          <div className="flex justify-between ">
            <div className="flex justify-between ">
              <div className="tablemaindiv customerTaskTable"></div>
            </div>
          </div>

          <div className="md:hidden">
            <button className="text-white focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />
      </nav>
      <div class="p-4 pt-0 mt-7" id="innercontent">
        <div className="mt-10">
          <CustomerListTable
            tableDetail={{
              tableidclass: "fullscreen-table",
              tableparentidclass: "tableparent",
              tablename: "Vendor List",
              tablenameclass: "font-medium text-16 text-203C50 capitalize",
              tableSearch: true,
              tableFilter: true,
              tableResizer: true,
              tableZoom: true,
              tableheader: true,
              forcefullyfreeze:true,
              dataactivity: true,
              addbutton: (
                <button
                  onClick={openPopup}
                  type="button"
                  className="text-2541558A hover:text-254155"
                >
                  <Link to="/add_vendor">
                    <i className="bi bi-plus-lg"></i>
                  </Link>
                </button>
              ),
            }}
            thead={VendorHeader}
            tbody={customer}
            tablePaginationData={setCustomerTablePagination}
            fetchPersonInTable={fetchPersonData}
            initialFilter={initialFilter}
            handleNavigate={handleNavigate}
            navigateNameCondition={["vendor_name"]}
            activityClosed={activityClosed}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LayoutHOC(VendorList);
