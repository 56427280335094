
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
     BASE_URL, 
     GET_MANAGE_GROUPS, 

    } from '../constants';
    

export const manageGroups = createApi({
    reducerPath: 'manageGroups',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
      fetchGroupsTableData: builder.query({
        query: ({ token,pagination,orggrp_id }) => {
          
          const params = new URLSearchParams({
            ...pagination,
          });
      
          const url = `${GET_MANAGE_GROUPS}?${params.toString()}&orggrp_id=${orggrp_id}`;
          return {
            url,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          };
        },
      }),
    
    }),
  });
  
  
  
export const {
      useFetchGroupsTableDataQuery
} = manageGroups;
    
  
  
    