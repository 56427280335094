import React, { useState } from "react";
import iPay from "../../assets/images/iPay.webp";
import VISA from "../../assets/images/VISA.webp";
import PayPal from "../../assets/images/PayPal.webp";

const InvoiceView = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isPaymentDropdownOpen, setIsPaymentDropdownOpen] = useState(false);
  const [isSchedulingDropdownOpen, setIsSchedulingDropdownOpen] =useState(false);
  const [isRotated, setIsRotated] = useState(true);
  const [isPaymentRotated, setIsPaymentRotated] = useState(false);
  const [isSchedulingRotated, setIsSchedulingRotated] = useState(false);
   const invoiceDropdown = () => {
    setIsOpen(!isOpen);
    setIsRotated(!isRotated);
  };
  const PaymentDropdown = () => {
    setIsPaymentDropdownOpen(!isPaymentDropdownOpen);
    setIsPaymentRotated(!isPaymentRotated);
  };

  const SchedulingDropdown = () => {
    setIsSchedulingDropdownOpen(!isSchedulingDropdownOpen);
    setIsSchedulingRotated(!isSchedulingRotated);
  };
  return (
    <div>
      <div className="border-t-2 border-[#203C501A] px-2 thinscrollbar overflow-y-auto h-[851px]  lg:h-[851px]   py-2 shadow-inner">
        <div className="mt-5 p-2 border bg-E4E6E8 flex items-center">
          <button
            type="button"
            className=" inline-flex  w-full text-14 text-2C495D font-400 px-2 "
            onClick={invoiceDropdown}
                  
          >
            Customization
          </button>

              <div className="me-2" style={{ transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 15 9" fill="none" 
                  className=" ">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9901 8.60275C14.3887 8.16684 14.3887 7.46001 13.9901 7.0241L7.86651 0.326363C7.46797 -0.109548 6.82172 -0.109548 6.42318 0.326363L0.29956 7.0241C-0.0989857 7.46001 -0.0989857 8.16684 0.29956 8.60275C0.698105 9.03867 1.34435 9.03867 1.7429 8.60275L7.14484 2.69435L12.5468 8.60275C12.9454 9.03867 13.5916 9.03867 13.9901 8.60275Z" fill="#545F65"/>
                  </svg>
               </div>
        </div>
        {isOpen && (
          <div className="border py-2 space-y-1">
            <div className="space-y-3">
              <div className={`flex justify-between items-center px-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                >
                  Ship to
                </h5>
                <div className={`flex items-center`}>
                <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer` } />
                    <div
                      className={`relative  w-11 h-6 bg-gray-200 peer-focus:outline-0 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50 `}
                    ></div>
                  </label>
                </div>
              </div>
              <div className={`flex justify-between items-center px-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                >
                  Invoice Number
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className={`flex justify-between items-center px-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                >
                  Invoice Date
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className={`flex justify-between items-center px-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                >
                  Due Date
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className={`flex justify-between items-center px-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                >
                  terms
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className={`flex justify-between items-center px-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                >
                  Service Date
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className={`flex justify-between items-center px-4 pb-2`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                >
                  SKU
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
            </div>

            <div className="border mx-4 "></div>
            <div className={`flex justify-between items-center px-4 pb-2 `}>
              <h5
                className={`text-2C495D font-Poppins text-[12px] font-500 pt-2 pb-2`}
              >
                Custom Fields
              </h5>
              <div className={`flex items-center`}>
                <button className=" text-2999BC text-10 font-400">
                  Manage
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="mt-5 p-2 border bg-E4E6E8 flex items-center">
          <button
            type="button"
            className=" inline-flex  w-full text-14 text-2C495D font-400 px-2 "
            onClick={PaymentDropdown}
                  
          >
            Payment Method
          </button>

              <div className="me-2" style={{ transform: isPaymentRotated ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 15 9" fill="none" 
                  className=" ">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9901 8.60275C14.3887 8.16684 14.3887 7.46001 13.9901 7.0241L7.86651 0.326363C7.46797 -0.109548 6.82172 -0.109548 6.42318 0.326363L0.29956 7.0241C-0.0989857 7.46001 -0.0989857 8.16684 0.29956 8.60275C0.698105 9.03867 1.34435 9.03867 1.7429 8.60275L7.14484 2.69435L12.5468 8.60275C12.9454 9.03867 13.5916 9.03867 13.9901 8.60275Z" fill="#545F65"/>
                  </svg>
               </div>
        </div>
        {isPaymentDropdownOpen && (
          <div className="border py-2 space-y-1">
            <div>
              <div>
                <div className="py-2 pl-4 ">
                  <h3 className="text-14 font-600 text-2C495D">
                    Accepted Payment Option
                  </h3>
                </div>
                <div className="flex space-x-4 mt-2 pl-4 ">
                  <button>
                    <img src={iPay} alt="iPay"></img>
                  </button>
                  <button>
                    <img src={VISA} alt="VISA"></img>
                  </button>
                  <button>
                    <img src={PayPal} alt="PayPal"></img>
                  </button>
                </div>
              </div>

              <div className={`flex justify-between items-center px-4 `}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-6 pb-2`}
                >
                  Payment Details
                </h5>
                <div className={`flex items-center`}>
                  <button className=" text-2999BC text-10 font-400">
                    Manage
                  </button>
                </div>
              </div>
            </div>
            <div className="border mx-4 "></div>
            <div className={`flex justify-between items-center px-4`}>
              <h5
                className={`text-2C495D font-Poppins text-[12px] font-600 pt-3 pb-4`}
              >
                More Options
              </h5>
            </div>
            <div className="space-y-3">
              <div className={`flex justify-between items-center px-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                >
                  Invoice Total
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className={`flex justify-between items-center px-4`}>
                <div className="flex ">
                  <h5
                    className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                  >
                    Deposit
                  </h5>
                  <span className="border-710084 bg-9E00B8 rounded text-white  text-10 font-400 w-8 h-4 pl-1 ml-2 mt-2">
                    New
                  </span>
                </div>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className={`flex justify-between items-center px-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2`}
                >
                  Discount
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className={`flex justify-between items-center px-4 pb-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-2 `}
                >
                  Shipping Fee
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mt-5 p-2 border bg-E4E6E8 flex items-center">
          <button
            type="button"
            className=" inline-flex  w-full text-14 text-2C495D font-400 px-2 "
            onClick={SchedulingDropdown}
                  
          >
            Schedulling
          </button>

              <div className="me-2" style={{ transform: isSchedulingRotated ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 15 9" fill="none" 
                  className=" ">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9901 8.60275C14.3887 8.16684 14.3887 7.46001 13.9901 7.0241L7.86651 0.326363C7.46797 -0.109548 6.82172 -0.109548 6.42318 0.326363L0.29956 7.0241C-0.0989857 7.46001 -0.0989857 8.16684 0.29956 8.60275C0.698105 9.03867 1.34435 9.03867 1.7429 8.60275L7.14484 2.69435L12.5468 8.60275C12.9454 9.03867 13.5916 9.03867 13.9901 8.60275Z" fill="#545F65"/>
                  </svg>
               </div>
        </div>
        {isSchedulingDropdownOpen && (
          <div className="border py-2 ">
            <div className="mt-4 space-y-1">
              <button className="text-2999BC text-12 font-400 ml-4">
                Recurring Invoice
              </button>

              <div className="mt-2 mx-4"></div>
              <div className="border-b-2 mx-4 pb-4">
                <label className=" text-2C495D text-12 font-500  ">
                  Set up a recurring invoice
                </label>
              </div>
            </div>

            <div className="space-y-3 mx-4 border-b-2">
              <h2 className="text-12 font-600 text-2C495D pt-2">
                More Options
              </h2>
              <div className={`flex justify-between items-center`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 `}
                >
                  Discount
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>

              <div className={`flex justify-between items-center  pb-4`}>
                <div className="">
                  <h5
                    className={`text-2C495D font-Poppins text-[12px] font-500 `}
                  >
                    Shipping Fee
                  </h5>
                </div>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
            </div>
            <div className="space-y-3">
              <div className={`flex justify-between items-center px-4 `}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 pt-6 `}
                >
                  Automatic invoice remainders
                </h5>
                <button className=" text-2999BC text-10 font-400">
                    Manage
                </button>

              </div>
              <div className={`flex justify-between items-center px-4`}>
                <h5
                  className={`text-2C495D font-Poppins text-[12px] font-500 `}
                >
                  Invoice Remainders
                </h5>
                <div className={`flex items-center`}>
                  <label className={`inline-flex items-center cursor-pointer`}>
                    <input type={`checkbox`} className={`sr-only peer`} />
                    <div
                      className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-4CAF50`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className="text-12 font-400 text-2C495D pl-4 pb-6 w-[55%]">
                <p>
                  Changes made here will apply to all outstanding and future
                  invoices.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceView;
