import React, { useRef, Fragment, useEffect, useState } from "react";
import SubmitIcon from "../Icons/SubmitIcon";
import { saveActivity } from "../../utils/customer/customerTaskEventSlice";
import { useDispatch, useSelector } from "react-redux";
import useRequireAuth from "../../utils/useRequireAuth";
import { useFetchCustomerActivityDataTableDataQuery } from "../../utils/customer/customerTaskTable";

const TaskActivityList = ({
  onFocus,
  taskid,
  taskCategory,
  onChange,
  disble,
  taskPod,
}) => {
  const [description, setDescription] = useState("");
  const activitys = useSelector((state) => state.activity);
  const dispatch = useDispatch();
  const { token } = useRequireAuth();
  const [page, setPage] = useState(10);
  const containerRef = useRef(null);
  const [taskEvent, setTaskEvent] = useState([]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setDescription(value);
  };

  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchCustomerActivityDataTableDataQuery({
      token: token,
      taskid: taskid,
      page: `${page}`,
    });
  const [isActivityModalOpen, setActivityModalOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const closeModal = () => {
    setIsModalOpen(false);
    onChange();
  };

  useEffect(() => {
    refetchCustomerData({
      token: token,
      taskid: taskid,
      page: `${page}`,
    });
  }, [page, token]);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        if (page >= 10 && page <= customerTableALL?.data?.total) {
          setPage((prev) => prev + 1);
        }
      }
    };
    const container = containerRef.current;
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [page, customerTableALL?.data?.total, containerRef]);

  const handleSubmitButtonClick = async (e) => {
    const taskEvent = {
      description: description,
      task_id: taskid,
    };
    await dispatch(saveActivity({ taskEvent, token }));
    await refetchCustomerData();
    await setDescription("");
  };
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [customerTableALL?.data?.activity]);
  return (
    <>
      {isActivityModalOpen && disble === false && (
        <div className="bg-white w-full px-7 py-3">
          <div className="flex justify-between">
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-black bg-opacity-50">
                <div className="">
                  <div
                    className="relative p-3 mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md overflow-hidden no-scrollbar"
                    style={{ width: "600px" }}
                  >
                    <div className="flex items-center justify-between rounded-t">
                      <p className="text-md text-2C495D">Activity</p>
                      <button type="button">
                        <h3 className="text-545F65">
                          <i className="bi bi-x" onClick={closeModal}></i>
                        </h3>
                      </button>
                    </div>
                    <div className="flex flex-col">
                      <p className="mb-3">{taskCategory}</p>
                      <div
                        className="h-[350px] addTask overflow-y-scroll scrollbar-hidden"
                        ref={containerRef}
                      >
                        {customerTableALL?.data?.activity?.length > 0 &&
                          customerTableALL?.data?.activity.map(
                            (CategoryData) => (
                              <div className="px-2 py-2 bg-F1F6F9 rounded-5px mb-3">
                                <div className="px-2 flex items-center gap-x-2">
                                  <div>
                                    <img
                                      className="w-8 h-8 me-2 rounded-full"
                                      src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                                      alt="user photo"
                                    />
                                  </div>
                                  <div className="flex flex-col">
                                    <p className="text-2999BC font-medium text-xs">
                                      {CategoryData.created_by}
                                    </p>
                                    <p className="text-F16136 font-medium text-xxs">
                                      {CategoryData.created_at}
                                    </p>
                                  </div>
                                </div>
                                <p className="text-xs text-justify text-535252 m-3">
                                  {CategoryData.description}
                                </p>
                              </div>
                            )
                          )}
                      </div>
                      <form>
                        <div className="relative flex flex-wrap">
                          <textarea
                            name="description"
                            type="text"
                            className="h-full w-full border-2 border-lightBlue rounded-5px shadow-10 focus:outline-hidden focus:ring-0 focus:border-1 focus:border-lightBlue focus:shadow-11 scrollbar-hidden"
                            placeholder="Type Something..."
                            value={description}
                            onChange={handleInputChange}
                            onFocus={onFocus}
                          />
                          <button
                            className="absolute right-1 bottom-1 z-10 py-2 px-4"
                            type="button"
                            data-ripple-light="true"
                            onClick={handleSubmitButtonClick}
                          >
                            <SubmitIcon />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default TaskActivityList;
