import React, { useState } from 'react';
import CustomerTable from './CustomerTable';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('overview');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="mb-4 border-gray-200">
          <ul className="flex flex-wrap text-md font-poppins text-base font-normal text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classes="text-2C495D hover:text-2C495D border-b" data-tabs-inactive-classes="border-transparent text-2C495D hover:text-gray-600 hover:border-gray-300" role="tablist">
            <li className={`me-2 ${activeTab === 'overview' ? 'active' : ''}`} role="presentation">
              <button className={`inline-block p-4 rounded-t-lg hover:text-gray-600  ${activeTab === 'overview' ? 'border-b-2 border-gray-300' : ''}`} type="button" role="tab" aria-controls="overview" aria-selected={activeTab === 'overview'} onClick={() => handleTabClick('overview')}>
                Overview
              </button>
            </li>
            <li className={`me-2 ${activeTab === 'bookkeeping' ? 'active' : ''}`} role="presentation">
              <button className={`inline-block p-4 rounded-t-lg hover:text-gray-600  ${activeTab === 'bookkeeping' ? 'border-b-2 border-gray-300' : ''}`} type="button" role="tab" aria-controls="bookkeeping" aria-selected={activeTab === 'bookkeeping'} onClick={() => handleTabClick('bookkeeping')}>
                Bookkeeping
              </button>
            </li>
            <li className={`me-2 ${activeTab === 'payroll' ? 'active' : ''}`} role="presentation">
              <button className={`inline-block p-4 rounded-t-lg hover:text-gray-600  ${activeTab === 'payroll' ? 'border-b-2 border-gray-300' : ''}`} type="button" role="tab" aria-controls="payroll" aria-selected={activeTab === 'payroll'} onClick={() => handleTabClick('payroll')}>
                Payroll
              </button>
            </li>
          </ul>
        </div>
      <div>
      {/* <form className="max-w-sm mx-auto">
  
  <select id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
    <option selected><svg height="100" width="100" xmlns="http://www.w3.org/2000/svg">
  <circle r="45" cx="50" cy="50" fill="orange-400" /> 
</svg> </option>
    <option value="US"><svg height="100" width="100" xmlns="http://www.w3.org/2000/svg">
  <circle r="45" cx="50" cy="50" fill="red" /> 
</svg> </option>
    <option value="CA"><svg height="100" width="100" xmlns="http://www.w3.org/2000/svg">
  <circle r="45" cx="50" cy="50" fill="red" /> 
</svg> </option>
    <option value="FR"><svg height="100" width="100" xmlns="http://www.w3.org/2000/svg">
  <circle r="45" cx="50" cy="50" fill="red" /> 
</svg> </option>
    <option value="DE"><svg height="100" width="100" xmlns="http://www.w3.org/2000/svg">
  <circle r="45" cx="50" cy="50" fill="red" /> 
</svg> </option>
  </select>
</form> */}
    
    
{/* <select id="select_graphtype" className="fa">
    <option className='text-orange-400'> &#xf111;</option>
    <option className='text-green'> &#xf111;</option>
    <option className='text-red'> &#xf111;</option>
</select>
     */}
    
      </div>
        <div className="flex gap-3">
          <div className='max-w-md mx-auto '>
            <div className="relative flex border-2 items-center w-full h-12 focus-within:shadow-lg bg-white overflow-hidden">
              <button className="grid place-items-center h-full px-5 text-gray-300 border-0 pointer-events-none ">
                <i className="bi bi-search"></i>
              </button>

              <input
                className="peer h-full w-full outline-none text-stone-300 text-base font-normal pr-2 border-0"
                type="text"
                id="search"
                placeholder="write here your search"
              />
            </div>
          </div>
          <div>
            <button className="bg-white text-slate-700 h-12 px-2 text-xl">
              <i className="bi bi-gear"></i>
            </button>
          </div>
        </div>
      </div>
      <CustomerTable activeTab={activeTab} />
    </>
  );
};

export default Tabs;
