import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  getDataIdBase,
  saveDataAll,
  getCommonDataBasedOnId
} from "../../components/store/customer/CustomerSocialAddUpdateFunction";
import {
  CUSTOMER_TASK_ADD_UPDATE,
  CUSTMOER_NOTES_DATA_DELETE,
  ADD_UPDATE_VENDOR,
  EDIT_VENDOR_DATA
} from "../constants";

export const saveVendor = createAsyncThunk(
  "vendors/saveVendor",
  async ({ formDataWithFile, token }, thunkAPI) => {
    try {
      const response = await saveDataAll(
        ADD_UPDATE_VENDOR,
        formDataWithFile,
        token
      );
      if (response.status === true) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);

export const fetchVendor = createAsyncThunk(
  "vendors/fetchVendor",
  async ({ customerid, token }, thunkAPI) => {
    try {
      const response = await getCommonDataBasedOnId(
        `${CUSTOMER_TASK_ADD_UPDATE}${customerid}`,
        token
      );
      if (response?.status === true) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);

export const fetchVendorEditData = createAsyncThunk(
  "vendors/fetchVendorEditData", 
  async ({ vendorId, token }, thunkAPI) => { 
    try {
      const response = await getCommonDataBasedOnId(
        `${EDIT_VENDOR_DATA}${vendorId}`,
        token
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);

export const deleteVendor = createAsyncThunk(
  "vendors/deleteVendor",
  async ({ id, token }, thunkAPI) => {
    try {
      const response = await deleteData(
        `${CUSTMOER_NOTES_DATA_DELETE}${id}`,
        token
      );

      if (response?.status === true) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);

const vendorTeamSlice = createSlice({
  name: "vendors",
  initialState: {
    loading: false,
    vendors: [],
    error: null,
    showMessage: false,
    message: null,
  },
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    setVendor: (state, action) => {
      state.vendors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendor.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(fetchVendor.fulfilled, (state, action) => {
        state.loading = false;
        state.vendors = action.payload;
        state.error = "";
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(fetchVendor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveVendor.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(saveVendor.fulfilled, (state, action) => {
        state.loading = false;
        state.vendors = action.payload;
        state.error = "";
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(saveVendor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteVendor.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteVendor.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(deleteVendor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchVendorEditData.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(fetchVendorEditData.fulfilled, (state, action) => {
        state.loading = false;
        state.vendors = action.payload;
        state.error = null;
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(fetchVendorEditData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { showMessage, hideMessage, setVendor } = vendorTeamSlice.actions;
export default vendorTeamSlice.reducer;
