const taskHeader = [
      {
          "subject": "Subject",
          "subject_status": 1
      },
      {
          "due_date": "Due Date",
          "due_date_status": 1
      },
      {
        "priority": "Priority",
        "priority_status": 1
      },
      {
        "status": " Task Status",
        "status_status": 1
      },
      {
        "activity": "Activity",
        "activity_status": 1,
      },
      {
        "category_id": "Category",
        "category_id_status": 1
      },
      
      {
          "owner": "Task Owner",
          "owner_status": 1
      },
        {
            "task_pod": "Taskpod ",
            "task_pod_status": 1
        },
        {
          "contact_name": "Contact Name",
          "contact_name_status": 1     
          },
          
        
  ]


  export default taskHeader