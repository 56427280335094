import React, { useState,useRef ,useEffect} from 'react';

const SearchableInput = ({ data, onInsert, value, onChange, name,passvalue }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const handleInputChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = data.filter(item =>
      item.email.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredData(filtered);
    setIsOpen(true);
  };

  const handleItemClick = (item) => {
    setSelectedItemId(item.id);
    onInsert(item.name);
    setSearchTerm(item.name);
    setFilteredData([]);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div 
      ref={inputRef}
    >
      <input
      type="text"
      placeholder="Search..."
      value={searchTerm??passvalue}
      onChange={handleInputChange}
      style={{ width: '100%' }}
      className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10"
    />
      <input type="hidden" value={selectedItemId || ''} />
      {isOpen && (
      <ul
        className="absolute bg-white"
      >
        {filteredData.map((item) => (
          <li
            key={item.id}
            onClick={() => handleItemClick(item)}            
            className="p-2 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10"
          >
            {item.email}
          </li>
        ))}
      </ul>
      )}
      {/* Render hidden input for the selected item ID */}
      <input type="hidden" name={name} value={selectedItemId} onChange={onChange} />
    </div>
  );
};

export default SearchableInput;
