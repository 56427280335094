const teamkHeader = [
      {
          "designation": "Department",
          "designation_status": 1
      },
      {
        "reporting_person_name": "Name",
        "reporting_person_name_status": 1
    },
      {
          "reporting_person_email": "Email",
          "reporting_person_email_status": 1
      },
      
      {
          "action": "Action",
          "action_status": 1
      },
  ]


  export default teamkHeader