import React, { Fragment, useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../utils/constants";
import {
  
  hideMessage,
  
  deleteTeamMemberAsync,
  saveTeamMemberDataAsync
} from "../../utils/customer/artifactSlice";
import { useParams } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";

import { useLazyFetchCustomerTaskTableQuery } from "../../utils/customer/customerTaskTable";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";

import CustomerListTable from "./CustomerListTable";
import documentHeader from "./DocumentTableHead";
import { useNavigate } from "react-router-dom";
import DocumentModal from "./DocumentModal";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import { CUSTMOER_ARTIFACT_DATA_GET } from "../../utils/constants";
import { useUpdateCustomerTaskTableDataMutation } from "../../utils/customer/customerTaskTable";
import { useFetchCustomerDocumentDataQuery } from "../../utils/customer/customerTaskTable";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";

const AddArtifact = ({taskOpenTab}) => {
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const { token } = useRequireAuth();
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const { id: customerId } = useParams();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const message = useSelector((state) => state.customerArtifact.message);
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const showMessage = useSelector((state) => state.customerArtifact.showMessage);
  const [customer,setCustomer]=useState([]);
  const [initialFilter,setInitialFilter]=useState([]);
  const navigate = useNavigate();
 
  const [loading,setLoading]=useState(false);
  const [customerTablePagination,setCustomerTablePagination] = useState('');
  const [isPopupOpen,setIsPopupOpen]=useState(false);
  const handleDelete = async(rowKey, item) => {
    const id=item?.id?.value;
    try {
      await dispatch(deleteTeamMemberAsync({id, token }));
      setCustomer([]);
      await fetchData(customerId);
      setAlert({
        visible: true,
        message: "Record Deleted Successfully",
        type: "success",
      });
    } catch (error) {
      console.error("Error updating status:", error);
      setAlert({
        visible: true,
        message: `Error updating status: ${error}`,
        type: "danger",
      });
    }
  };
  
  const handleStatusUpdate=async(rowKey, item,itemId,status)=>{    
    const socialData={
      id:itemId.id,
      status:status?1:0,
    }
   
    try {
      await dispatch(saveTeamMemberDataAsync({ socialData, token }));
      setCustomer([]);
      await fetchData(customerId);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  }

  const handleSubmitData=async(customerId)=>{
    await fetchData(customerId);
  }

  const fetchData = async(customerId) => {    
    const url = `${CUSTMOER_ARTIFACT_DATA_GET}${customerId}`;
    const getData = await getCommonDataBasedOnId(url, token);
    console.log(getData, 3333);
    return false
    setCustomer(getData?.data?.data);
    
    const { data: omitData, ...restData } = getData?.data;
    setInitialFilter(restData);
  };

  const { data: customerTableALL, refetch: refetchCustomerData } =
  useFetchCustomerDocumentDataQuery({
      customerId: customerId,
      pagination: `${customerTablePagination}`,
      token: token,
    });

    useEffect(()=>{
      if(showMessage===true){
        setAlert({
          visible: true,
          message: message || "Operation successful",
          type: "success",
        });
        refetchCustomerData();
      }       
    });

  useEffect(() => {
    if (customerTableALL && taskOpenTab===3) {
      setCustomer(customerTableALL?.data?.data);
      const { data: omitData, ...restData } = customerTableALL?.data;

      setInitialFilter(restData);
      setLoading(false);
    }
  }, [customerTableALL, customerTablePagination,taskOpenTab]);

  const [
    fetchCustomerCategoryTable,
    { data: customerTaskCategorCommnData, refetch: refetchCustomerCommnData },
  ] = useLazyFetchCustomerTaskTableQuery({token: token});

  const [updateCustomerTableData, { CustomerDropLoad, CustomerDroperror, }] = useUpdateCustomerTaskTableDataMutation();
  const customerTableFieldUpdate = async (field_name,value,id) => {
    const updateData = {
      field_name: field_name,
      value: value,
      id: id
    }
    try {
      const result = await updateCustomerTableData({ token: token, data: updateData }).unwrap();
      if(result?.data){
        refetchCustomerData()
        {result?.data?.original ?  setAlert({ visible: true, message: result?.data?.original?.error, type: 'danger' }) : setAlert({ visible: true, message: result?.message, type: 'success' })} 
      }
    } catch (error) {
      setAlert({ visible: true, message: 'Error updating data!', type: 'danger' });
    }
  }

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  }; 
 


 

  const handleDownload = async (rowKey, item) => {
    try {
      
      const url = BASE_URL + item.path.value;
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const urlObject = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlObject;
      link.download = item.file_name.value; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(urlObject); 
    } catch (error) {
      console.error("Error occurred while downloading the file:", error);
    }
  };
  
  

  

  setTimeout(() => {
    dispatch(hideMessage());
  }, 3000);

 
  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };
  return (
    <Fragment>
      <div className="tablemaindiv">
        <div className={` ${isFullscreen ? "bg-white h-full px-5 pt-2" : ""}`}>
          <FirstMessage
            type={alert.type}
            message={alert.message}
            visible={alert.visible}
            onHide={hideAlert}
          />
          <CustomerListTable
             tableDetail={
              {
                  tableidclass:"fullscreen-table",
                  tableparentidclass: 'tableparent',
                  tablename:'Document',
                  tablenameclass: 'font-medium text-537596 capitalize',
                  tableSearch:true,
                  tableFilter:true,
                  tableResizer:true,
                  tableZoom:true,
                  statusUpdate: false,
                  datetedata:true,
                  downloaddata:true,
                  addbutton: (
                      <button 
                         onClick={openPopup}
                         type="button"
                         className="text-2541558A hover:text-254155"
                      >
                           <i className="bi bi-plus-lg"></i>
                      </button>
                  )
              }
            }
              thead={documentHeader}
              tbody={customer}
              tablePaginationData={setCustomerTablePagination}
              fetchtableBodyData={fetchCustomerCategoryTable}
              customerCommnData={customerTaskCategorCommnData}
              initialFilter={initialFilter}
              handleNavigate={''}
              TableFieldUpdate={customerTableFieldUpdate}
              handleDownload={handleDownload}
              handleDelete={handleDelete}
              handleStatusUpdate={handleStatusUpdate}
             
              
            /> 
              {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-[102]">
                  <DocumentModal
                    onClose={closePopup}
                    handleSubmitData={handleSubmitData}
                  />
                </div>
              )}
        </div>
      </div>
    </Fragment>
  );
};

export default AddArtifact;