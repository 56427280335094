import React from 'react'
import SubmitIcon from '../../Icons/SubmitIcon'

const InputWithSubmit = ({handleSaveEvent}) => {


    
  return (
    <form onSubmit={handleSaveEvent}>
        <div className="relative flex flex-wrap">
            <textarea
                name="description"
                type="text"
                className="h-full w-full border-2 border-lightBlue rounded-5px shadow-10 focus:outline-hidden focus:ring-0 focus:border-1 focus:border-lightBlue focus:shadow-11"
                placeholder="Type Something..."
            />
            <button
                className="bg-white absolute right-1 bottom-1 z-10 py-2 px-4"
                type="submit"
                data-ripple-light="true"
            >
                <SubmitIcon />
            </button>
        </div>
    </form>
  )
}

export default InputWithSubmit