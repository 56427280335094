import React, { useState, useEffect, useRef } from "react";
import SaveIcon from "../Icons/SaveIcon";
import ColorDropdown from "../Dropdown/ColorDropdown.js";

const ViewNotesData = ({
  handleNotesClick,
  children,
  notesTitle,
  notetborder,
  notesCategory,
  colourCodes,
  customerTaskCategory,
  customerColor,
  handleSubmit,
  handleInputChange,
  handleSetColour,
  editNoteId,
  formData
}) => {
  const formRef = useRef(null);
  const handleInsertColur=(color)=>{
    handleSetColour(color)
  }



  
  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <div className="border border-D2DAE1 rounded-5px">
        <div className="grid">
          <div className={`flex flex-row relative  mb-2`}>
          <input
  type="text"
  placeholder="Note Title . . ."
  className={`px-2 w-full border rounded-5px focus:ring-0 h-[35px] mt-6 ml-6 border-D2DAE1 focus:border-778FA5 `}
  onClick={() => handleNotesClick("addnotes")}
  onChange={handleInputChange}
  name="notes_title"
  value={formData?.notes_title !== '' ? formData.notes_title : ''}
/>


              <select
                className="form-select w-[119px] rounded-5px h-[35px] mt-6 pt-1 ml-2  mr-2 focus:ring-0 border-D2DAE1 focus:border-778FA5 "
                onChange={handleInputChange}
                name="notes_category_id"
              >
                {customerTaskCategory?.data?.notes.map((cat) => (
                  <option value={cat.id} key={cat.id} selected={cat.value===notesCategory}
                  defaultValue={cat.value === 1 ? true : false}
                  >
                    {cat.value}
                  </option>
                ))}
              </select>
              <div className="mt-6 ">
                <ColorDropdown colors={customerColor} handleInsertColur={handleInsertColur}  onChange={handleInputChange} colourCodes={colourCodes}/>
              </div>
          </div>
          {children}
        </div>
      </div>
    </form>
  );
};

export default ViewNotesData;
