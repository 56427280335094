import React from "react";
import LayoutHOC from "./LayoutHOC";

const Dashboard = () => {
    return <main id="maincontent" className="ml-[256px]">
            <div className="p-4 mt-14">
              <h1>Welcome to Dashboard</h1>
            </div>
            </main>;
};

export default LayoutHOC(Dashboard);
