import React, { useEffect, useState, useRef, Fragment , useImperativeHandle ,forwardRef} from "react";
import EmojiesDrop from "./EmojiesDrop";
import { BASE_URL } from "../../utils/constants";
const truncateFileName = (fileName, maxLength = 10) => {
  if (fileName?.length <= maxLength) {
    return fileName;
  }
  const extension = fileName?.split('.').pop();
  const baseName = fileName?.substring(0, fileName?.length - extension?.length - 1);
  return `${baseName.substring(0, maxLength - extension?.length - 4)}...${extension}`;
};

const NotesEditor = forwardRef(({ initialContent, setInitialContent, notesSetting ,notesBody,textareaRef,handleFileUpload , selectedFile,hndleCloseNotes,handleClickDeleteFile,downloadFiles,handleDeleteFileServer,editNoteId,getFileLastName}, ref) => {
 
  const [activeFormatting, setActiveFormatting] = useState({
    bold: false,
    underline: false,
    italic: false,
    copy: false,
    // foreColor: "#1D4469",
    fontSize: 3,
    fontName: "Poppins",
    formatblock: "p",
    wordcount: 0,
    charactercount: 0,
  });
  const [content, setContent] = useState(initialContent);
  const [paraHeadOpen, setParaHeadOpen] = useState(false);
  const rgbToHex = (rgb) => {
    const [r, g, b] = rgb.match(/\d+/g);
    const hexR = (+r).toString(16).padStart(2, "0");
    const hexG = (+g).toString(16).padStart(2, "0");
    const hexB = (+b).toString(16).padStart(2, "0");
    return `#${hexR}${hexG}${hexB}`;
  };



  const handleTextData = (event) => {
    const isBold = document.queryCommandState("bold");
    const isItalic = document.queryCommandState("italic");
    const isUnderline = document.queryCommandState("underline");
    const fontSize = document.queryCommandValue("fontSize");
    const fontName = document.queryCommandValue("fontName");
    const foreColor = document.queryCommandValue("foreColor");
    const hexColor = rgbToHex(foreColor);
    let formatblock = document.queryCommandValue("formatblock");

    // if (formatblock === "div" || formatblock === "") {
    //   document.execCommand("formatblock", false, "p");
    //   formatblock = "p";
    // }

    const content = event.target.textContent;
    const words = content
      .trim()
      .split(/\s+/)
      .filter((word) => word?.length > 0);
    setActiveFormatting((prevState) => ({
      ...prevState,
      bold: isBold,
      italic: isItalic,
      underline: isUnderline,
      fontSize: fontSize,
      fontName: fontName,
      foreColor: hexColor,
      formatblock: formatblock,
      wordcount: words?.length,
      charactercount: content?.length,
    }));
  };

  

 

  const handleButtonClick = (event) => {
    // textareaRef.current.focus();
    const elementvalue = event.target.value;

    const button = event.currentTarget.getAttribute("data-styletag");
    document.execCommand(button, false, elementvalue);
    setActiveFormatting((prevState) => ({
      ...prevState,
      [button]: elementvalue ? elementvalue : !prevState[button],
    }));

    if (button === "copy") {
      setTimeout(() => {
        setActiveFormatting((prevState) => ({
          ...prevState,
          copy: false,
        }));
      }, 1000);
    }
  };
  
  

  const handleParaHead = (value) => {
    textareaRef.current.focus();

    document.execCommand("formatblock", false, value);
    setActiveFormatting((prevState) => ({
      ...prevState,
      formatblock: value,
    }));
    setParaHeadOpen(false);
  };
  useImperativeHandle(ref, () => ({
    clearContent: () => {
      setContent('');
      setInitialContent('');
    },
  }));

  const getFileLastNames = (filePath) => {
    if (typeof filePath === 'string' && filePath.includes('/')) {
        return true;
    } else {
        return false;
    }
  };
  
  const downloadFile = (file) => {
    if (!file instanceof Blob) {
        console.error('File object is invalid or empty.');
        return;
    }
    try {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); 
    } catch (error) {
        console.error('Error creating object URL:', error);
    }
};



  return (
    <Fragment>
      <div className="relative mx-6 ">
        <div
          role="textbox  "
          contentEditable={true}
          ref={textareaRef}
          // value={notesBody}
            dangerouslySetInnerHTML={{ __html: notesBody }}
            className={`text-12 font-500 opacity-100  w-full  p-2 min-h-24 overflow-auto  rounded-5px  notesckeditor resize-y h-[75px] focus:ring-0  border  focus:outline-none border-D2DAE1 focus:border-778FA5`}
            placeholder="Type Something . . ."
            // onBlur={() => alert(1)}
            onKeyUp={handleTextData}
        ></div>

      </div>
      <div className="flex flex-row justify-start ms-4 mb-4 gap-15px">
        {selectedFile?.length>0 && selectedFile.map((file, index) => (
            
          <div key={index} className="relative flex justify-between">           
             <div className="border border-D2DAE1 py-1 px-3 rounded-5px">
              {file?.attachment ?(
                getFileLastName(file?.attachment)
              ):(
                truncateFileName(file?.name)
              )}
            
            </div>
            {file?.attachment ? (
              <Fragment>
              <button type="button" onClick={() => handleDeleteFileServer(file.id,editNoteId)} className="m-1">
                <i className="bi bi-x-circle text-red-500"></i>
              </button> 
              {getFileLastNames(file?.attachment)?(
                   <button type="button" onClick={() => downloadFiles(file?.attachment)} className="mx-1">
                   <i className="bi bi-download text-2999BC"></i>
                  </button> 
              ):(
                <button type="button" onClick={() => downloadFile(file?.file)} className="mx-1">
                <i className="bi bi-download text-2999BC"></i>
               </button> 
              )}
                          
            </Fragment>
            ):( 
            <Fragment>
              <button type="button" onClick={() => handleClickDeleteFile(index)} className="m-1">
              <i className="bi bi-x-circle text-red-500"></i>
            </button>
            <button type="button" onClick={() => downloadFile(file)} className="mx-1">
            <i className="bi bi-download text-2999BC"></i>
            </button>
             
            </Fragment>
        )}
           
          
          </div>
          
           
           
          ))}
      </div>
    
      <div className="flex flex-row">
          <div className="basis-5/6 border-ynm border-D2DAE1 bg-F1F6F9 flex align-center ps-2">
            <div className="flex items-center">
              <div className="pe-2 border-r border-B3B3B3 inline-block">
                <button type="button" onClick={(e)=>{ e.preventDefault();handleFileUpload();}}>
                  <i className="bi bi-paperclip text-2xl text-8997A0"></i>
                </button>
              </div>
              <div className="text-xs px-4 flex">
  <span className="mx-1">
    Words: <span>{activeFormatting.wordcount}</span>
  </span>
  <span className="mx-1">
    Characters: <span>{activeFormatting.charactercount}</span>
  </span>
</div>


              <EmojiesDrop textareaRef={textareaRef} />

              <div className="inline-block text-left relative">
                <div>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 capitalize"
                    onClick={() => setParaHeadOpen(!paraHeadOpen)}
                    aria-expanded={paraHeadOpen}
                    aria-haspopup="true"
                  >
                    {activeFormatting.formatblock}

                    <i
                      className={`bi bi-${
                        paraHeadOpen ? "caret-up" : "caret-down"
                      } text-gray-400`}
                    ></i>
                  </button>
                </div>

                {paraHeadOpen && (
                  <div
                    className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg  ring-white ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    tabIndex="-1"
                  >
                    <div className="py-1" role="none">
                      <button
                      type="button"
                        className={`text-gray-700 block w-full px-4 py-2 text-left text-sm ${
                          activeFormatting.formatblock === "p"
                            ? "bg-1D4469  text-white"
                            : ""
                        }`}
                        onClick={() => handleParaHead("p")}
                        tabIndex="-1"
                      >
                        Paragraph
                      </button>
                      <button
                      type="button"
                        className={`text-gray-700 block w-full px-4 py-2 text-left text-sm ${
                          activeFormatting.formatblock === "h1"
                            ? "bg-1D4469  text-white"
                            : ""
                        }`}
                        onClick={() => handleParaHead("h1")}
                        tabIndex="-1"
                      >
                        Heading 1
                      </button>
                      <button
                      type="button"
                        className={`text-gray-700 block w-full px-4 py-2 text-left text-sm ${
                          activeFormatting.formatblock === "h2"
                            ? "bg-1D4469  text-white"
                            : ""
                        }`}
                        onClick={() => handleParaHead("h2")}
                        tabIndex="-1"
                      >
                        Heading 2
                      </button>
                      <button
                      type="button"
                        className={`text-gray-700 block w-full px-4 py-2 text-left text-sm ${
                          activeFormatting.formatblock === "h3"
                            ? "bg-1D4469  text-white"
                            : ""
                        }`}
                        onClick={() => handleParaHead("h3")}
                        tabIndex="-1"
                      >
                        Heading 3
                      </button>
                      <button
                      type="button"
                        className={`text-gray-700 block w-full px-4 py-2 text-left text-sm ${
                          activeFormatting.formatblock === "h4"
                            ? "bg-1D4469  text-white"
                            : ""
                        }`}
                        onClick={() => handleParaHead("h4")}
                        tabIndex="-1"
                      >
                        Heading 4
                      </button>
                      <button
                      type="button"
                        className={`text-gray-700 block w-full px-4 py-2 text-left text-sm ${
                          activeFormatting.formatblock === "h5"
                            ? "bg-1D4469  text-white"
                            : ""
                        }`}
                        onClick={() => handleParaHead("h5")}
                        tabIndex="-1"
                      >
                        Heading 5
                      </button>
                      <button
                      type="button"
                        className={`text-gray-700 block w-full px-4 py-2 text-left text-sm ${
                          activeFormatting.formatblock === "h6"
                            ? "bg-1D4469  text-white"
                            : ""
                        }`}
                        onClick={() => handleParaHead("h6")}
                        tabIndex="-1"
                      >
                        Heading 6
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="mx-2 ">
                <select
                  className="form-select w-[60px] h-9 px-2 rounded-5px "
                  data-styletag="fontSize"
                  onChange={handleButtonClick}
                  value={activeFormatting.fontSize}
                >
                  <option value={"1"}>1</option>
                  <option value={"2"}>2</option>
                  <option value={"3"}>3</option>
                  <option value={"4"}>4</option>
                  <option value={"5"}>5</option>
                  <option value={"6"}>6</option>
                  <option value={"7"}>7</option>
                </select>
              </div>

              <div className="mx-2 relative">
                <input
                  type="color"
                  id="favcolor"
                  className="color-input"
                  name="favcolor"
                  data-styletag="foreColor"
                  value={activeFormatting.foreColor}
                  onChange={handleButtonClick}
                />
                <div
                  id="custom-colorbox-1"
                  className={`customcolorbox bg-[${activeFormatting.foreColor}]`}
                >
                
                  <i className="bi bi-palette-fill text-1D4469"></i>
                </div>
              </div>

              <button
              type="button"
                className={`mx-2 ${
                  activeFormatting.bold && "text-2999BC bg-EAEAEA rounded"
                }`}
                data-styletag="bold"
                onClick={handleButtonClick}
              >
                <i className="bi bi-type-bold text-xl text-858f96"></i>
              </button>
              <button
              type="button"
                className={`mx-2  ${
                  activeFormatting.underline && "text-2999BC bg-EAEAEA rounded"
                }`}
                data-styletag="underline"
                onClick={handleButtonClick}
              >
                <i className="bi bi-type-underline text-xl text-858f96"></i>
              </button>
              <button
              type="button"
                className={`mx-2  ${
                  activeFormatting.italic && "text-2999BC bg-EAEAEA rounded"
                }`}
                data-styletag="italic"
                onClick={handleButtonClick}
              >
                <i className="bi bi-type-italic text-xl text-858f96"></i>
              </button>

              <button
              type="button"
                className={`mx-2`}
                data-styletag="copy"
                onClick={handleButtonClick}
              >
                <i className="bi bi-copy text-xl text-858f96"></i>
              </button>

              {activeFormatting.copy && (
                <div className="absolute translate-y-[-44px] translate-x-[32rem] mr-1 p-1 px-2 bg-1D4469 text-white rounded">
                  Copied
                </div>
              )}

              <button
              type="button"
                className={`mx-2`}
                data-styletag="undo"
                onClick={handleButtonClick}
              >
                <i className="undo-icon text-858f96"></i>
              </button>

              <button
              type="button"
                className={`mx-2`}
                data-styletag="redo"
                onClick={handleButtonClick}
              >
                <i className="redo-icon text-858f96"></i>
              </button>
            </div>
            
          </div>
          <div className="basis-1/6 flex">
            <button 
              type="button" 
              className="bg-376EA3 border-none text-white py-2 px-4 w-100per"
           
              onClick={hndleCloseNotes}

              >
              <i className="bi bi-x-lg notes-submit"></i>
            </button>
            <button type="submit" className="bg-1D4469 border-none text-white py-2 px-4 w-100per">
             
              <i className="bi bi-send-fill notes-submit"></i>
            </button>
          </div>
      </div>
    </Fragment>
  );
});

export default NotesEditor;