
export const parseJSONValue = (text) => {
    try {
      return text?.value ? JSON.parse(text?.value) : text;
    } catch (e) {
      return text;
    }

 
  };
  