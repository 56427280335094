import React, { useState } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeUser } from '../../utils/userSlice';
import useRequireAuth from "../../utils/useRequireAuth";
 
const SideBar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const user = useRequireAuth();
  const navigate = useNavigate();  
  const dispatch = useDispatch();
 
  const handleSignOut = async () => {
    const Authtoken = user.token;
    try {
      dispatch(removeUser(null));
      navigate("/dashboard");
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };
 
  const sidebarLinks = [
    { title: 'Dashboard', path: '/dashboardMain', onClick: null },    
    { title: 'User Management', path: '/user', onClick: null },
    { title: 'Manage Permission', path: '/manage_permission', onClick: null },
    { title: 'Manage List', path: '/managelist', onClick: null },
    { title: 'Logout', path: null, onClick: handleSignOut },
  ];
 
  const handleMenuClick = (index) => {
    setActiveLink(index); // Set active link on menu click
  };
 
  return (
    <div className="bg-white shadow-md rounded-md w-[100%] mt-2 h-[580px]">
      <ul className="text-gray-600 rounded-md p-3">
        {sidebarLinks.map((link, index) => (
          link.path ? (
            <li
              key={index}
              className={`hover:bg-cyan-900 hover:text-white hover:font-normal cursor-pointer rounded mb-1 ${
                currentPath === link.path ? 'bg-cyan-900 text-white font-normal' : ''}`}
            >
              <NavLink
                exact="true"
                to={link.path}
                onClick={() => handleMenuClick(index)}
                className={`block px-4 py-2 cursor-pointer ${activeLink === index ? 'activeLink' : ''}`}
              >
                {link.title}
              </NavLink>
            </li>
          ) : (
            <li
              key={index}
              onClick={() => {
                handleMenuClick(index);
                if (link.onClick) link.onClick();
              }}
              className={`hover:bg-cyan-900 hover:text-white hover:font-normal cursor-pointer rounded mb-1 ${
                activeLink === index ? 'bg-cyan-900 text-white font-normal' : ''}`}
            >
              <span className="block px-4 py-2 cursor-pointer">
                {link.title}
              </span>
            </li>
          )
        ))}
      </ul>
    </div>
  );
}
 
export default SideBar;
