// import React from 'react'
// import LayoutHOC from '../LayoutHOC';
 
import React from "react";
import SideBar from "../Dashboard/SideBar";
import CompanyProfile from './CompanyProfile';
import CompanyLeftHOC from '../CompanyLeftHOC';
import CompanyBilling from './CompanyBilling';
import AddContact from './AddContact';
import AddAddress from './AddAddress';
import useRequireAuth from "../../utils/useRequireAuth";
import { useFetchCompanyCommonDataQuery } from "../../utils/company/companySelector";
function AddCompany() {
 const {token}=useRequireAuth();
 let userToken = token || JSON.parse(localStorage.getItem("myUserData")).token;
  const {
    data: companyCommnData,
    error,
    isLoading,
  } = useFetchCompanyCommonDataQuery(userToken);
  if ( isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <>
    <div className="container px-10 p-4">
      <div className="flex flex-col md:flex-row mt-14">
        <div className="w-full md:w-3/12 lg:w-3/12 mt-2"><SideBar/></div>
        <div className="w-full md:w-9/12 lg:w-9/12 mt-4 md:mt-0 md:ml-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
                <div className="">
                  <h5 className=" text-203C50 font-Vietnam text-24 font-500">New Company</h5>
                </div>
                <div className="flex items-center justify-end">
                    <label className="switch">
                        <input
                          type="checkbox"
                          defaultValue=""
                          className="sr-only peer"
                          defaultChecked=""
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-600 dark:peer-focus:ring-green-600 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    </label>
                </div>
            </div>
            <div className="bg-white w-full mb-3">
                <CompanyProfile 
                  businessCategoryData={companyCommnData?.data?.businessCategory} 
                  countryData={companyCommnData?.data?.country} 
                  currencyData={companyCommnData?.data?.currency}
                  initialCompanyGroup={companyCommnData?.data?.company_group}
                />      
            </div>
            <div className="w-full mb-3">
              <CompanyBilling accounting={companyCommnData?.data?.accountingMethod} taxFormData={companyCommnData?.data?.taxForm}/>      
            </div>
            <div className="w-full mb-3">
              <AddAddress />      
            </div>
            <div className="w-full mb-3">
              <AddContact />     
            </div>
        </div>        
      </div>
    </div>    
  </>
  )
}
 
 
//export default LayoutHOC(AddCompany);
 
export default CompanyLeftHOC(AddCompany);