import React, { useRef, useState } from 'react'
import useCustomFormValidation from "../hooks/ErrorHandling/useCustomFormValidation"; 
import { FORGET_PASS_URL } from '../utils/constants';
//import { useNavigate } from 'react-router-dom';
import FooterFixed from './FooterFixed';

const validationRules = {
  email: [
    {
      required: true,
      errorMessage: 'Cannot be left blank',
   },
   {
     email: true,
     errorMessage: 'Provide valid email',
   }
  ]
};

const ForgetPass = () => { 
    //const Navigate = useNavigate(); 
    const [error,setErrors]=useState(false);   
    const [reserror,setResErrors]=useState(false);
    const [succesmsg,setSuccErrors]=useState(false);
    const [inputValue, setInputValue] = useState('');
    const password = useRef(null);
    const password_confirmd = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null); 

    const [formData, setFormData] = useState({
      email: ""
    });
  
    const {
      values,
      errors,
      touched,
      handleChange,
      validateField,
      isFormValid,
    } = useCustomFormValidation(formData, validationRules);

    const handleInputChange = (event) => {
      handleChange(event);
      const { name, value } = event.target;
      setInputValue(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };    

    const handleButtomClick = async (event) => {      
      event.preventDefault();      
      const formValid = isFormValid();
      if(formValid===true && formData!==''){  
        try {
          const response = await fetch(FORGET_PASS_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });
          const data = await response.json();          
          if(data.status =='success') {
            setSuccErrors(data.message);
            setInputValue('');            
          }
          if(data.status =='error') {
            setResErrors(data.message);
            //setFormData('');            
          }
          //console.warn(data,"data here now")            
        } catch (error) {
          console.error('Error:', error);
          setErrorMessage('An error occurred. Please try again.');
        }
      }  
    }; 


  return (
      <div className="bg-white">
        <div className='flex items-center justify-center min-h-screen'>
          <div className="px-2 py-8 border border-EDEDED rounded shadow-md mx-28 w-1/2 max-w-md">                  
                <div className="text-1D5D9B text-24 font-700 pb-5 px-5 text-center">Forget Password</div>
                <div 
                  className='h-8 text-center'
                >
                  {succesmsg && <span className="mt-1 text-green-500 font-normal text-14">{succesmsg}</span>}
                </div>
                <div className="space-y-5">
                    <form onSubmit={(e) => e.preventDefault()} className="flex flex-col py-2 px-5" id="ResetPassForm">
                        <label htmlFor="email" className="text-545B61 text-14 font-500">Email</label>
                        <input 
                          //ref={email} 
                          className="border-1 border-E4EDF3 rounded-md focus:outline outline-1D5D9B focus: border-1 focus:border-sky-700 w-full px-1" 
                          type="email"
                          name="email"
                          id="email" 
                          placeholder=""
                          value={inputValue}
                          onChange={handleInputChange} 
                        />
                        <div
                          className='h-8'
                        >
                          {
                            (errors.email && (
                              <span className="text-red-500 font-normal text-12">{errors.email}</span>
                            )) || (reserror && (
                              <span className="text-red-500 font-normal text-12">{reserror}</span>
                            ))
                          }
                        </div>
                        <button 
                          onClick={handleButtomClick} 
                          type="submit" 
                          className="bg-sky-700 w-full text-white font-bold py-1 rounded-md mb-3 hover:bg-sky-600"
                        >
                          Submit
                        </button>
                                                
                    </form>
                    <div className="flex items-center"><hr className="flex-grow border-t-2 border-gray-200"/></div>
                    <div className="text-center text-14">
                      Don't have a account yet?{" "}
                      <a href='/signup' className="text-red-500 hover:cursor-pointer font-700">Sign Up now.</a>
                    </div>
                    <div className="flex justify-center text-center">
                      <p className="w-550px text-14">
                      By continuing, you are indicating that you have read and agree to the
                      <a className="text-sky-700 font-bold"> Terms of Use</a> and <a className="text-sky-700 font-bold">Privacy
                          Policy.</a></p>              
                    </div>
                </div>
            </div>
            <div><FooterFixed /></div>
        </div>        
      </div>                
  );
}

export default ForgetPass