import { useState } from 'react';

const useTableDensity = () => {
  const [densityMode, setDensityMode] = useState('table-compact');

  const toggleDensityMode = () => {
    setDensityMode(prevMode => {
      switch (prevMode) {
        case 'table-compact':
          return 'table-medium';
        case 'table-medium':
          return 'table-comfortable';
        case 'table-comfortable':
          return 'table-advance';
        case 'table-advance':
          return 'table-compact';
        default:
          return 'table-compact';
      }
    });
  };

  const densityIcons = {
    'table-compact': 'bi bi-list tableheaderfiltericon text-2541558A hover:text-254155',
    'table-medium': 'bi bi-grid tableheaderfiltericon text-254155 border border-253E50 px-1 rounded',
    'table-comfortable': 'bi bi-view-list tableheaderfiltericon text-254155 border border-253E50 px-1 rounded',
    'table-advance': 'bi bi-view-stacked tableheaderfiltericon text-254155 border border-253E50 px-1 rounded',
  };

  return { densityMode, densityIcons, toggleDensityMode };
};

export default useTableDensity;
