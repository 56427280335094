import React from 'react'

const FullScreenMode = ({event,children}) => {
  return (
        <button onClick={event} type='button' className='tooltip'>
           
           {children}

           <span className={`tooltiptext text-[10px] w-max bottom-full left-1/2 ml-[-32px]`}>Full Screen</span>

           
        </button>
  )
}

export default FullScreenMode