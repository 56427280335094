import React, { useState ,useEffect } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import {
  useFetchTaskDataQuery,
  useFetchCustomerUseListDataQuery,
} from "../../utils/customer/customerSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveTasks, hideMessage } from "../../utils/customer/customerTaskSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RecurrenceModal from "./RecurrenceModal";
import OwnerDropDown from "../GlobalFeature/Fields/OwnerDropDown";
import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import { useFetchCustomerAllTaskPodDataQuery } from "../../utils/customer/customerSelect";
import CreatePortalHOC from "../GlobalFeature/Fields/CreatePortalHOC";
import useClickOutside from "../../hooks/Screen/useClickOutside";

const formatDate = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const formattedDate = `${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getFullYear()}`;
  return formattedDate;
};
const CustomerAddTaskModel = ({ isOpen,closePopup,taskid,handelSubmitData,taskPod}) => {
  const { token } = useRequireAuth();
  const { id:customerId } = useParams();
  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [selectedItem, setSelectedItem] = useState(null);
  const [ownerError, setOwnerError] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [statusTask,setStatusTask]=useState(null);
  const [isOpens,setIsOpens]=useState(false);
  const [contactName,setContactName]=useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopupRecur = () => {
    setIsPopupOpen(false);
  };

  const [endDate, setEndDate] = useState('');
  const handleStartDateChange = (date) => {
    setStartDate(date??new Date());
    if (endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date??new Date());
  };

  const [formData, setFormData] = useState({
    subject:"",
    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "status") {
      setStatusTask(value);
    } else if (name === "category_id") {
      if (value === "Task") {
          setIsOpens(true)
        } else {
            setIsOpens(false);
        }
    }
    
    setFormData({ ...formData, [name]: value });
};
  const [formErrors, setFormErrors] = useState({
      subject:'',
  });
  const validateForm = () => {
    const requiredFields = ['subject'];
    const errors = {};
    requiredFields.forEach(field => {
        if (!formData[field]) {
            errors[field] = 'Cannot be left blank.';
        }
    });
    setFormErrors(errors);
    return Object.keys(errors)?.length === 0;
  };

  const handleSubmitTask = async(event) => {
    event.preventDefault();
   
    if (!validateForm()) {
        
      return false;
    }

    const taskDatas = {
        subject:event.target.subject.value,
        category_id:event.target.category_id.value,
        priority:event.target.priority.value,
        task_pod: event.target.task_pod.value,
        start_date: formatDate(startDate),
        end_date:(isOpens && isOpens===true)?formatDate(endDate):null,
        customer_id: customerId,
        company_id: parsedCompanyData.company_id,
        description: event.target.description.value,
        owner:selectedItem??'',
        contact_name: event.target.contact_name.value,
        status: event.target.status.value
    };
    
   
   try {
    closePopup();
    await dispatch(saveTasks({ taskDatas, token }));
    await handelSubmitData(customerId);
    event.target.reset();
   } catch (error) {
    
   }
  
};

  const {
    data: customerTaskCategory,
    error,
    isLoading,
  } = useFetchTaskDataQuery({
    token,
    jsonData: {
      customer_id: customerId,
      company_id: parsedCompanyData.company_id,
    },
  });

  const {
    data: customerTaskUserList,
    error: customerTaskUserListerror,
    isLoading: customerTaskUserListLoading,
  } = useFetchCustomerUseListDataQuery({
    token,
  });
  const handleInsert = (item) => {
    setSelectedItem(item);
  };
  const tableDetail = {
    tableidclass: 'taskmodelowner'

  }


  const [
    fetchPersonInTable,
    { data: personTableData },
  ] = useLazyFetchPersonInTableQuery();

  const fetchPersonData = (data) => {
    fetchPersonInTable({token: token,data:data})
  }

  const handlePersonData=(value)=>{
    setContactName(value.name)
   }

   const { data: customerTaskPodList } = useFetchCustomerAllTaskPodDataQuery({token});

   const CloseTaskOutsideClick = useClickOutside (() =>{
    closePopup(true);
   })
  
  return (
    <CreatePortalHOC dialog isOpen={isOpen}>
      <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-gray-900 bg-opacity-50">
        <div className="relative mt-18 bg-white  font-popns drop-shadow-lg  rounded-md w-[750px] overflow-hidden no-scrollbar" ref={CloseTaskOutsideClick}>
          <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
            {/* <h3 className="text-2xl text-2C495D ps-3">{taskid ? ("Edit Task"):("Create Task")}</h3> */}
            <button
              onClick={closePopup}
              type="button"
              className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              data-modal-hide="assets-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <form
            id={tableDetail?.tableidclass}
            onSubmit={handleSubmitTask}
            className={`w-full lg:max-h-[85vh]  overflow-hidden  no-scrollbar pt-3 ${tableDetail?.tableidclass}`}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
              <div className="basis-5/5 mb-3">
                <label
                  htmlFor="subject"
                  className="block mb-2 text-lg text-434852"
                >
                  <span className="text-sm font-medium text-434852">Subject</span> 
                  <span className=" text-red-500"> *</span>
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  placeholder="Enter Subject"
                
                  onChange={handleChange}
                  className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full h-10"
                />
                {formErrors.subject && (
                  <span className="text-red-500 text-xs">
                    Cannot be left blank.
                  </span>
                )}
              </div>
              <div className="basis-5/5 mb-3">
                <label
                  htmlFor="categories"
                  className="block mb-2 text-lg text-434852"
                >
                  <span className="text-sm font-medium text-434852">Category </span>
                
                </label>
                <div className="flex items-center justify-end">
                <select
                  id={`category_id`}
                  name="category_id"
                  onChange={handleChange}
                  className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full h-10 appearance-none pr-10`}
                
                >
                  <option>--</option>
                  {!customerTaskCategory?.data?.task_category
                    ? null
                    : customerTaskCategory?.data?.task_category.map(
                        (priority) => (
                          <option
                            key={priority.id}
                            value={priority.value}

                            selected={priority.value==='Meeting'}
                          
                          >
                            {priority.value}
                          </option>
                        )
                      )}
                </select>
                <div className="absolute flex px-2 pointer-events-none">
                  <h4><i className="bi bi-chevron-down"></i></h4>
                </div>
                </div>
                {formErrors.category_id && (
                  <span className="text-red-500 text-xs">
                    Cannot be left blank.
                  </span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
              <div className="basis-5/5 mb-3">
                <label
                  htmlFor="duedate"
                  className="basis-5/5 block mb-2 text-lg text-434852 "
                >
                <div className="flex justify-between gap-1">
                <div>
                  <span className="font-medium	text-sm text-434852">Due Date</span>
                  <span className=" text-red-500"> *</span>
                  </div>
                <div className="gap-1 flex items-center  hover:text-sm" onClick={openPopup}>
                  <span><i className="bi bi-repeat text-red-500"></i></span>
                  <span className="text-xs"><text type="button">Recurrence</text></span>
                  </div>
                  </div>
                  {isPopupOpen && (
                      <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                        <RecurrenceModal closePopup={closePopupRecur} className={`text-gray-700 fixed inset-0 transition-all duration-300 ${isPopupOpen ? 'blur-sm' : ''}`} />
                      </div>
                    )}
                </label>
                  
                  <div className="flex">
                  <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select a start date"
                      className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10"
                  />

                    
                    {isOpens && (
                      <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select an end date"
                      className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10"
                  />
                )}   
                    {/* <i className="bi bi-calendar2-event absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none cursor:pointer"></i> */}
                  </div>
              </div>
              <div className="basis-5/5 mb-3">
                <label
                  htmlFor="priority"
                  className="block mb-2 text-lg text-434852"
                >
                  <span className="font-medium	text-sm text-434852">Priority</span>
              
                </label>
                <div className="flex items-center justify-end">
                <select
                  id={`priority`}
                  name="priority"
                  onChange={handleChange}
                  className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10`}
                
                >
                  <option>--</option>
                  {!customerTaskCategory?.data?.priority
                    ? null
                    : customerTaskCategory?.data?.priority.map((priority) => (
                        <option
                          key={priority.id}
                          value={priority.value}

                          selected={priority.value==='Low'}
                        
                        >
                          {priority.value}
                        </option>
                      ))}
                </select>
                <div className="absolute flex px-2 pointer-events-none">
                  <h4><i className="bi bi-chevron-down"></i></h4>
                </div>
                </div>
                {formErrors.priority && (
                  <span className="text-red-500 text-xs">
                    Cannot be left blank.{" "}
                  </span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
              <div className="basis-5/5">
                <label
                  htmlFor="task_pod"
                  className="block mb-2"
                >
                  <span className="font-medium	text-sm text-434852">Link To Taskpod</span>
                  <span className=" text-red-500"> *</span>
                </label>
                <div className="flex items-center justify-end">
                  <select
                    id={`task_pod`}
                    name="task_pod"
                    onChange={handleChange}
                    className={`custom-select mb-3 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10`}
                  
                  >
                    <option>--</option>

                    {!customerTaskPodList
                      ? null
                      : customerTaskPodList?.data.map((ownerList) => (
                          <option key={ownerList.id} value={ownerList.pod_name}  selected={(ownerList.pod_name===(taskPod)||(ownerList.id===9))}>
                            {ownerList.pod_name}
                          </option>
                        ))}
                  </select>
                  <div className="absolute flex px-2 pointer-events-none">
                  <h4><i className="bi bi-chevron-down"></i></h4></div>
                </div>
                {formErrors.task_pod && (
                  <span className="text-red-500 text-xs">
                    Cannot be left blank.{" "}
                  </span>
                )}
                </div>
              
              <div className="basis-5/5">
                <label htmlFor="owner" className="block mb-2">
                <span className="font-medium	text-sm text-434852">Owner</span>
                </label>
              
                  <OwnerDropDown 
                        tableidclass={tableDetail.tableidclass} 
                        fetchPersonInTable={fetchPersonData}  
                        DropData={personTableData} 
                        handleInsert={handleInsert}
                        handlePersonData={handlePersonData}
                        
                  /> 

                  {/* <SearchableInput
                    data={customerTaskUserList}
                    name="owner"
                    onInsert={handleInsert}
                    value={selectedItem}
                    passvalue=""
                    
                    onChange={handleChange}
                  /> */}
            
                {formErrors.owner && (
                  <span className="text-red-500 text-xs">
                    Cannot be left blank.
                  </span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
              <div className="basis-5/5">
                <label
                  htmlFor="contact_name"
                  className="block mb-2"
                >
                  <span className="font-medium	text-sm text-434852">Contact Name</span>
                </label>
                <input
                  type="text"
                  id="contact_name"
                  name="contact_name"
                  placeholder="Enter Contact Name"
                
                  onChange={handleChange}
                  className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10"
                
                />
              </div>
              <div className="basis-5/5">
                <label
                  htmlFor="status"
                  className="block mb-2"
                >
                  <span className="font-medium	text-sm text-434852">Status</span>
                </label>
                <div className="flex items-center justify-end">
                <select
                  id={`status`}
                  name="status"
                  placeholderText="Select Status"
                  onChange={handleChange}
                  className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10`}
                >
                  {/* <option>Select Status</option> */}
                  {!customerTaskCategory?.data?.status
                    ? null
                    : customerTaskCategory?.data?.status.map((status) => (
                        <option
                          key={status.id}
                          value={status.value}
                        
                        >
                          {status.value}
                        </option>
                      ))}
                </select>
                <div className="absolute flex px-2 pointer-events-none">
                  <h4><i className="bi bi-chevron-down"></i></h4>
                </div>
                </div>
                {formErrors.status && (
                  <span className="text-red-500 text-xs">
                    Cannot be left blank.{" "}
                  </span>
                )}
              </div>
            </div>
            <div className="my-3 py-3 pe-3 rounded-md border-2 border-DFE2E5">
              <div className="h-[30vh] addTask overflow-auto">
                <div className="grid grid-cols-1">
                  <div className="basis-5/5 mb-3 px-6">
                    <label
                      htmlFor="description"
                      className="font-medium	block mb-2 text-sm text-434852"
                    >
                      Description
                    </label>
                    <textarea
                      type="message"
                      id="description"
                      name="description"
                      placeholder="Enter Description"
                    
                    
                      onChange={handleChange}
                      className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-[120px]"
                    />
                    <div className="text-sm text-red-400"></div>
                  </div>
                </div>
                <div>
                  {/* <CustomerEditTaskActivity /> */}
                </div>
                <div className="grid grid-cols-1 hidden">
                  <div className="basis-5/5 mb-3 px-6">
                    <label
                      htmlFor="remark"
                      className="block mb-2 text-sm text-434852"
                    >
                      Remark
                    </label>
                    <textarea
                      type="message"
                      id="remarks"
                      name="remarks"
                      onChange={handleChange}
                      className="bg-white rounded border border-DFE2E5 text-gray-900 text-sm block w-full p-2.5 h-20"
                    />
                    <div className="text-sm text-red-400"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sticky bottom-0 bg-gray-200 p-4 flex justify-end py-4 modal-footer text-md w-full rounded-none z-[9998]">
              <div className="px-6">
                <button
                  onClick={closePopup}
                  type="button"
                  className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-2 text-center rounded-sm"
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold text-gray-900 border border-gray-200 py-2 px-5 ms-3 rounded-sm"
                >
                  SAVE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </CreatePortalHOC>
  );
};
export default CustomerAddTaskModel;