import React, { useState, useEffect } from 'react';
import useClickOutside from '../../../hooks/Screen/useClickOutside';

const SelectField = ({ setExtraFieldData, extraFieldData, options, setExtraField }) => {
  const initialMatchedOption = options[0];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialMatchedOption);
  const dropdownRef = useClickOutside(() => setIsDropdownOpen(false));

  useEffect(() => {
    const matchedOption = options.find(option => option.value === extraFieldData.field_type) || initialMatchedOption;
    setSelectedOption(matchedOption);
  }, [extraFieldData, options]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (option) => {
    setExtraFieldData(prevState => ({
      ...prevState,
      field_options: []
    }));
    setSelectedOption({ label: option.label, svg: option?.svg });
    setExtraFieldData(prevState => ({
      ...prevState,
      field_type: option.value
    }));
    setIsDropdownOpen(false);
  };

  const handleClose = () => {
    setExtraField(false);
  };

  return (
    <div className="bg-white shadow-t-lg rounded-t-lg pl-7  pb-0">
        <div className='flex justify-end mt-1 '>
          <h5 className="w-full font-500 text-14 font-poppins pt-3 text-535252">Field Type</h5>
          <button className='mb-3 mr-2' onClick={handleClose}>  
            <i className="bi bi-x-circle"></i>
          </button>
        </div>
      <div>
        <div className="relative pr-7" ref={dropdownRef}>
          <button
            onClick={handleDropdownToggle}
            disabled={extraFieldData?.id}
            className="bg-white outline-none leading-3 focus:border-none px-4 w-full h-8  text-12 font-400 font-poppins mb-3 focus:outline-none focus:ring-1 border border-CADBEB rounded-md text-left"
          >
            <div className="flex justify-between">
              <div className="flex">
                {selectedOption?.svg && (<span className="me-2">{selectedOption?.svg}</span>)}
                {selectedOption?.label}
              </div>
              <i className={`bi ${isDropdownOpen ? 'bi-chevron-up' : 'bi-chevron-down'} text-59656A mr-2`}></i>
            </div>
          </button>
          {isDropdownOpen && (
            <ul className="absolute bg-white border border-CADBEB rounded-md mt-1 w-full z-10">
              {options.map((option) => (
                <li
                  key={option.value}
                  className="relative px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center"
                  onClick={() => handleOptionSelect(option)}
                >
                  <div className="w-[10%]">
                    {option.svg && option.svg}
                  </div>
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectField;























// import React,{useState,useRef,useEffect} from 'react'
// import useClickOutside from '../../../hooks/Screen/useClickOutside';

// const SelectField = ({setExtraFieldData,extraFieldData,options}) => {
//     const initialMatchedOption = options[0];
    
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [selectedOption, setSelectedOption] = useState(initialMatchedOption);
//     const dropdownRef = useClickOutside(() => setIsDropdownOpen(false));

//     useEffect(() => {
//       const matchedOption = options.find(option => option.value === extraFieldData.field_type) || initialMatchedOption;
//       setSelectedOption(matchedOption);
//     }, [extraFieldData,options]);

//     const handleDropdownToggle = () => {
//         setIsDropdownOpen(!isDropdownOpen);
        
//     };

//     const handleOptionSelect = (option) => {
//                 setExtraFieldData(prevState => ({
//                   ...prevState,
//                   field_options: []
//                 }));
//               setSelectedOption({label:option.label,svg:option?.svg});
//               setExtraFieldData(prevState => ({
//                 ...prevState,
//                 field_type: option.value
//               }));

//               setIsDropdownOpen(false);
//     };

   
//   return (
//     <div className=" bg-white shadow-t-lg rounded-t-lg px-7 pb-0" >
//         <div >
//         <div className='flex justify-end mt-3'>
                          
//               <h5 className="w-full font-500 text-14 font-poppins text-535252 ">Field Type</h5>
//               <button className='mb-1 ' >
//               <i className="bi bi-x-circle"></i>
//             </button> 
//         </div>
//           <div className="relative" ref={dropdownRef}>
//             <button
//               onClick={handleDropdownToggle}
//               disabled={extraFieldData?.id}
//               className="bg-white outline-none leading-3 focus:border-none px-4 w-full h-8 text-12 font-400 font-poppins mb-3 focus:outline-none focus:ring-1 border border-CADBEB rounded-md text-left"
//             >
//               <div className="flex justify-between">
//                     <div className="flex">
//                       {selectedOption?.svg && (<span className="me-2">{selectedOption?.svg}</span>) }

//                       {selectedOption?.label}
//                     </div>
//                     <i className={`bi ${isDropdownOpen ? 'bi-chevron-up' : 'bi-chevron-down'} text-59656A mr-2`}></i>
//                </div>
//             </button>
//             {isDropdownOpen && (
//               <ul className="absolute bg-white border border-CADBEB rounded-md mt-1 w-full z-10">
//                 {options.map((option) => (
//                   <li
//                     key={option.value}
//                     className="relative px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center"
//                     onClick={() => handleOptionSelect(option)}
//                   >
                    
//                       <div className="w-[10%]">
//                             {option.svg && (
//                               option.svg
//                             )}
//                       </div>
                 
//                     {option.label}
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </div>
//         </div>
//       </div>
//   )
// }

// export default SelectField








