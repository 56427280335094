import React, { Fragment } from 'react'
import useDropdownPosition from '../../../hooks/Dropdown/useDropDownPosition';

const HoverElement = ({Data,Values,HoverActiveClass}) => {


  return (
    <Fragment>
    <div className={`bg-white px-2 py-1.5 justify-center shadow-12  ${HoverActiveClass}`}>
      <div className="flex flex-row gap-x-2.5">
        <div className="">
          <h4>
            <i className="bi bi-person-circle"></i>
          </h4>
        </div>
        <div className="flex items-center">
          <p className="text-2999BC text-xs font-medium">
            {Data && Data[Values?.value1]}
          </p>
        </div>
      </div>
      <div className="flex flex-row gap-x-2.5">
        <div className="">
          <h4>
            <i className="bi bi-envelope"></i>
          </h4>
        </div>
        <div className="flex items-center">
          <p className="text-F16136 text-xs font-medium">
            {Data && Data[Values?.value2]}
          </p>
        </div>
      </div>

      {Data && Data[Values?.value3]   &&
      

      (
      <div className="flex flex-row gap-x-2.5">
        <div className="">
          <h4>
            <i className="bi bi-telephone"></i>
          </h4>
        </div>
        <div className="flex items-center">
          <p className="text-F16136 text-xs font-medium">
            {Data && Data[Values?.value3]}
          </p>
        </div>
      </div>
      )
      
      }
      
    </div>
    {/* <div className={`${HoverActiveclassName}-triangle`}>
      <i className="bi bi-caret-down-fill text-white text-md"></i>
    </div> */}

   </Fragment>
  )
}

export default HoverElement