const closeTaskBody = [
    //   {
    //       "id": 1,
    //       "team_designation": {
    //           "value": "BD Leader",
    //           "type": "dropdown"
    //       },
    //       "team_name": {
    //           "value": "Kuldeep",
    //           "type": "text"
    //       },
    //       "team_mail": {
    //           "value": "kuldeep@globalfpo.com",
    //           "type": "text"
    //       },
    //       "team_action": {
    //               "value": <div><i className='bi bi-pencil-square'></i> <i className='bi bi-pencil-square'></i></div>  ,
    //               "type": "button"
    //       }
    //   },
    //   {
    //         "id": 2,
    //         "team_designation": {
    //             "value": "BD Leader",
    //             "type": "dropdown"
    //         },
    //         "team_name": {
    //             "value": "Suman",
    //             "type": "text"
    //         },
    //         "team_mail": {
    //             "value": "suman@globalfpo.com",
    //             "type": "text"
    //         },
    //         "team_action": {
    //                 "value": <i className="bi bi-trash3 text-18 text-F84545 font-600"></i>,
    //                 "type": "icon"
    //         }
    //   },
    //   {
    //         "id": 3,
    //         "team_designation": {
    //             "value": "BD Leader",
    //             "type": "dropdown"
    //         },
    //         "team_name": {
    //             "value": "Khusdeev",
    //             "type": "text"
    //         },
    //         "team_mail": {
    //             "value": "khusdeev@globalfpo.com",
    //             "type": "text"
    //         },
    //         "team_action": {
    //                 "value": <i className="bi bi-trash3 text-18 text-F84545 font-600"></i>,
    //                 "type": "icon"
    //         }
    //   },
    //   {
    //         "id": 4,
    //         "team_designation": {
    //             "value": "BD Leader",
    //             "type": "dropdown"
    //         },
    //         "team_name": {
    //             "value": "Sukhveer",
    //             "type": "text"
    //         },
    //         "team_mail": {
    //             "value": "sukhveer@globalfpo.com",
    //             "type": "text"
    //         },
    //         "team_action": {
    //                 "value": <i className="bi bi-trash3 text-18 text-F84545 font-600"></i>,
    //                 "type": "icon"
    //         }
    //   },
    //   {
    //         "id": 4,
    //         "team_designation": {
    //             "value": "BD Leader",
    //             "type": "dropdown"
    //         },
    //         "team_name": {
    //             "value": "Jais",
    //             "type": "text"
    //         },
    //         "team_mail": {
    //             "value": "jais@globalfpo.com",
    //             "type": "text"
    //         },
    //         "team_action": {
    //                 "value": <i className="bi bi-trash3 text-18 text-F84545 font-600"></i>,
    //                 "type": "icon"
    //         }
    //   },
    //   {
    //         "id": 6,
    //         "team_designation": {
    //             "value": "BD Leader",
    //             "type": "dropdown"
    //         },
    //         "team_name": {
    //             "value": "Raj",
    //             "type": "text"
    //         },
    //         "team_mail": {
    //             "value": "raj@globalfpo.com",
    //             "type": "text"
    //         },
    //         "team_action": {
    //                 "value": <i className="bi bi-trash3 text-18 text-F84545 font-600"></i>,
    //                 "type": "icon"
    //         }
    //   },
    ]
    export default closeTaskBody