
import React, { useState, useEffect, useRef } from "react";
import CM from "../../assets/images/profile.webp";
import { getInitials } from "../utilityFunctions/stringUtility";


const UserPopUpDetails = ({onHandleShowDetails,tableDetail,dataInputRow,Item,customerData,headerKey}) => {
  
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const popupRef = useRef(null);

  const closePopup = () => {
    setDetailsVisible(false);
  };

  const toggleDetails = (event) => {
    event.stopPropagation()
    setDetailsVisible((prevState) => !prevState);
    onHandleShowDetails()
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    if (isDetailsVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDetailsVisible]);

  const getRequiredData=()=>{
    const {customer_id,user_name,email,mobile,reporting,manager_email,manager_phone}=customerData
    if(headerKey==="user_name"){
        return ({name:user_name?.value,email:email?.value,mobile:mobile?.value})
    }
    else if(headerKey==="reporting"){
        return ({name:reporting?.value,email:manager_email?.value,mobile:manager_phone?.value})
    }
  }
 
  return (
    <div className="py-2 min-w-[249px]">
      <div className="rounded-lg bg-white">
        <div className="border-b-2 space-y-2 pb-2">
          <div className="flex px-2 space-x-2">
            <div>
              <div className="bg-blue-500 w-5 h-5  rounded-full flex justify-center items-center " sx={{ bgcolor: "blue",width:16,height:16,fontSize:"10px" }}>{getInitials(getRequiredData()?.name,1)}</div>
            </div>
            <div className="text-2999BC text-14">
              <label>{getRequiredData()?.name}</label>
            </div>
          </div>
          <div className="flex px-2 space-x-2 my-2">
            <div className="w-5 text-center">
              <i className="bi bi-envelope bi-xl"></i>
            </div>
            <div className="text-F16136 text-12">
              <label>{getRequiredData()?.email}</label>
            </div>
          </div>
          <div className="flex px-2 space-x-2 my-2">
            <div className="w-5 text-center">
              <i className="bi bi-telephone bi-xl"></i>
            </div>
            <div className={`${getRequiredData()?.mobile? "text-F16136":"text-light-grey"} text-12`}>
              <label>{getRequiredData()?.mobile? getRequiredData()?.mobile : "Not Available"}</label>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-2 me-5 text-12">
          <button className="text-blue-500 inline-block" onClick={toggleDetails}>
            {isDetailsVisible ? "Show more" : "Show more"}
          </button>
        </div>
       
      </div>
    </div>
  );
};

export default UserPopUpDetails;
