import React, { useState, Fragment,useEffect } from "react";
import CustomerListTable from "../customer/CustomerListTable";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import FullScreenMode from "../GlobalFeature/Table/HeadTop/FullScreenMode";
import CustomerAddressStaticHeader from "../customer/CustomerAddressStaticHeader";
import useRequireAuth from "../../utils/useRequireAuth";
import VendorAddressModel from "./VendorAddressModel";
import { useParams } from "react-router-dom";
import { useFetchVendorAllAddressQuery } from "../../utils/customer/customerTaskTable";
import { useFetchVendorDefaultAddressQuery } from "../../utils/customer/customerSelect";

const VendorAddress = () => {
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const { id: vendorId } = useParams();
  const { token } = useRequireAuth();
  const [customer, setCustomer] = useState([]);
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [initialFilter, setInitialFilter] = useState([]);
  const [addressData, setAddressData] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleNavigate = (taskid) => {
    if (taskid) {
      setAddressData(taskid);
    }
  };


  const { data: customerAddress, refetch: refetchCustomerAddress } =
  useFetchVendorAllAddressQuery({
      token: token,
      vendorId: vendorId,
      pagination: `${customerTablePagination}`,

  });

  useEffect(() => {
    if (customerAddress) {
      setCustomer(customerAddress.data.data);
      const { data: omitData, ...restData } = customerAddress.data;
      setInitialFilter(restData??'');
    }
  }, [customerAddress, customerTablePagination]);


  const closePopup = () => {
    setIsPopupOpen(false);
    setAddressData('');
  };

  const { data: customerAddressData, refetch } =
  useFetchVendorDefaultAddressQuery({ token, vendorId });

  const openPopup = () => {
    setIsPopupOpen(true);
  };



  return (
    <>
      <div className="tablemaindiv">
        <div className={` h-full ${isFullscreen ? "mt-[-16px]" : "pt-2"} `}>
          {addressData && (
            <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
              <VendorAddressModel
               
                closePopup={closePopup}
                addressid={addressData}
                AddressData={customerAddressData}
                refetchSingle={refetch}
                refetchTable={refetchCustomerAddress}
              />
            </div>
          )}
          {isPopupOpen && (
            <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
              <VendorAddressModel
              
                closePopup={closePopup}
                AddressData={customerAddressData}
                refetchSingle={refetch}
                refetchTable={refetchCustomerAddress}
              />
            </div>
          )}
          {isFullscreen ? (
            <Fragment>
              <div className="flex justify-end ">
                <FullScreenMode event={(e) => toggleFullscreen(e)}>
                  {isFullscreen ? (
                    <i className="bi bi-fullscreen-exit"></i>
                  ) : (
                    <i className="bi bi-fullscreen text-xl text-284356"></i>
                  )}
                </FullScreenMode>
              </div>
              <CustomerListTable
                tableDetail={{
                  tableidclass: "fullscreen-table",
                  tableparentidclass: "tableparent",
                  tablename: "Address",
                  tablenameclass: "font-medium text-16 text-203C50 capitalize",
                  tableSearch: true,
                  tableFilter: true,
                  tableResizer: true,
                  tableheader: false,
                  dataactivity: true,
                  datetedata: true,
                  forcefullyfreeze: true,
                  addbutton: (
                    <button
                      onClick={openPopup}
                      type="button"
                      className="text-2541558A hover:text-254155"
                    >
                      <i className="bi bi-plus-lg"></i>
                    </button>
                  ),
                }}
                thead={CustomerAddressStaticHeader}
                tbody={customer}
                tablePaginationData={setCustomerTablePagination}
                initialFilter={initialFilter}
                navigateNameCondition={["address_type"]}
                handleNavigate={handleNavigate}
              />
            </Fragment>
          ) : (
            <Fragment>
              <div className="flex justify-between">
                <h2 className="font-Vietnam text-20 font-600  text-203C50">
                  Address
                </h2>
                <div className="flex gap-8">
                  <div className="flex items-center gap-2">
                    <button
                      type="button"
                      className="text-2541558A hover:text-254155"
                      onClick={openPopup}
                    >
                      <i className="bi bi-plus-lg"></i>
                    </button>

                    <FullScreenMode event={(e) => toggleFullscreen(e)}>
                      {isFullscreen ? (
                        <i className="bi bi-fullscreen-exit"></i>
                      ) : (
                        <i className="bi bi-fullscreen text-xl text-284356"></i>
                      )}
                    </FullScreenMode>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          <div className="bg-white p-2">
            {customerAddressData?.data?.length > 0 ? (
              customerAddressData?.data.map((address, index) => (
                <div className={`grid grid-cols-4 gap-x-3 p-5 `}>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Address Type</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.address_type}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">Address</p>
                    <p className="text-sm font-normal text-2C495D">
                      {address.address}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">City</p>
                    <p className="text-sm font-normal text-2C495D">
                      {address.city}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">State</p>
                    <p className="text-sm font-normal text-2C495D">
                      {address.state}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Country</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.country_id}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Zip Code</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.zip_code}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">
                      Point of Contact
                    </p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.point_of_contact}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Primary Email</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.primary_email}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">
                      Secondary Email
                    </p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.secondry_email}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Phone 1</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.phone1}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Phone 2</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.phone2}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <Fragment>
                <div className={`grid grid-cols-3 gap-x-3  `}>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">
                      Address Type
                    </p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">
                      Point of Contact
                    </p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">Address</p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">City</p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">State</p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">Zip Code</p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">Country</p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">Phone 1</p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">Phone 2</p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">
                      Primary Email
                    </p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">
                      Secondary Email
                    </p>
                    <p className="text-sm font-normal text-2C495D"></p>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorAddress;
