import { useMemo } from 'react';

const useFirstLetters = (text) => {
  const firstLetters = useMemo(() => {
    if (typeof text !== 'string') {
      return '';
    }
    
    return text?.split(' ')?.map(word => word.charAt(0)).join('');
  }, [text]);

  return firstLetters;
};

export default useFirstLetters;
