const PermissionHeader = [
    {
        "group_name": "Group Name",
        "group_name_status": 1
    },

    
    {
      "about_group": "Description",
      "about_group_status": 1
    },
    {
      "permission": "Permissions Level",
      "permission_status": 1
    },
    
]


export default PermissionHeader