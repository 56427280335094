import React, { useState, useEffect, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useClickOutside from "../../hooks/Screen/useClickOutside";
import useRandomWordGenerator from "../../hooks/Random/useRandomWordGenerator";
import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import useRequireAuth from "../../utils/useRequireAuth";
import OwnerDropDown from "../GlobalFeature/Fields/OwnerDropDown";
import {
  useAddUpdateUserListMutation,
  useFetchGetAllDataQuery,
} from "../../utils/customer/customerTaskTable";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import { EDIT_USER_LIST_BASED_ON_ID, GET_GROUP_DATA } from "../../utils/constants";
import Dialog from "@mui/material/Dialog";

const validationRules = {
  first_name: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  last_name: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  email: [
    {
      required: true,
      errorMessage: "Cannot be left blank",
    },
    {
      email: true,
      errorMessage: "Provide valid email",
    },
  ],
  emp_id: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  designation: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  department: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  owner: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  dob: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  doj: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  gender: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  assign_to: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  permission_level: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
};

const AddUserModal = ({ closePopup, handelsave, editData, title }) => {
  const randomWord = useRandomWordGenerator();

  const initialFormData = {
    first_name: "",
    last_name: "",
    password: "123456789",
    email: "",
    owner: "",
    emp_id: "",
    designation: "",
    department: "",
    reportingTo: "",
    workLoc: "",
    language: "",
    country: "",
    timeZone: "",
    dob: "",
    doj: "",
    gender: "",
    assign_to: "",
    permission_level: "",
    seattingLocation: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  // const [isExternalUser, setIsExternalUser] = useState(false);
  const [groupData,setGroupData]=useState([])
  
  useEffect(()=>{
    fetchGroupData()
  },[])
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateField,
    isFormValid,
  } = useCustomFormValidation(formData, validationRules);

  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });

  const { token } = useRequireAuth();

  const [fetchPersonInTable, { data: personTableData }] =
    useLazyFetchPersonInTableQuery();

  const fetchPersonData = (data) => {
    fetchPersonInTable({ token: token, data: data });
  };

  const fetchTaskEditData = async (editData) => {
    const url = `${EDIT_USER_LIST_BASED_ON_ID}${editData}`;
    const getData = await getCommonDataBasedOnId(url, token);
    if (getData?.length) {
      setFormData(getData[0]);
    }
  };

  useEffect(() => {
    fetchTaskEditData(editData);
  }, [editData]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const isPopupOpenclickoutside = useClickOutside(() => {
    setIsPopupOpen(false);
  });

  const handleFieldChange = (e) => {
    handleChange(e);
    const { name, value,checked } = e.target;
    if(name==="external"){
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked?1:0,
      }));
    }
    else{
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    
  };

  const tableDetail = {
    tableidclass: "usermodelreporting",
  };
  const handleInsert = (item) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      owner: item,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dob: date ? date.toISOString().split("T")[0] : "",
    }));
  };

  const handleDateOfJoin = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      doj: date ? date.toISOString().split("T")[0] : "",
    }));
  };

  const [addUpdateUserList, { isLoading, isError, isSuccess, error }] =
    useAddUpdateUserListMutation();

  const { data: allAddressData } = useFetchGetAllDataQuery({
    token: token,
  });

  const getMinDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 50);
    return date;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formValid = isFormValid();

    if (!formValid) {
      return;
    }

    try {
      const data = {
        ...formData,
        id: editData,
      };

      const response = await addUpdateUserList({ token, data: data });

      if (response?.data?.status) {
        setAlert({
          visible: true,
          message: response?.data?.data?.original?.message,
          type: "success",
        });
        setFormData(initialFormData);
        closePopup();
        setIsPopupOpen(false);
        handelsave();
      } else if (response?.error?.data?.message) {
        setAlert({
          visible: true,
          message: response?.error?.data?.message,
          type: "danger",
        });
      }
    } catch (err) {
      console.error("Error while adding/updating user:", err);
    }
  };

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };


  const fetchGroupData = async () => {
    const getData = await getCommonDataBasedOnId(GET_GROUP_DATA, token);
    if (getData?.length) {
      setGroupData(getData);
    }
  };

  return (
    <Fragment>
      <Dialog open={true}>
        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />
        <div className="fixed inset-0 flex items-center justify-center z-[9999] ">
          <div
            className="relative mt-18 bg-white  font-popns drop-shadow-lg  rounded-md w-[970px] overflow-hidden no-scrollbar"
            ref={isPopupOpenclickoutside}
          >
            <div className="mx-6 flex items-center justify-between py-4 md:py-3  border-b border-BECBD3 rounded-t">
              <h3 className="text-xl text-434852 font-500">{title}</h3>
              <button
                onClick={() => {
                  closePopup();
                }}
                type="button"
                className="text-454545 bg-F1F5F8 hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                data-modal-hide="assets-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <form
              className={`w-full lg:max-h-[85vh]  overflow-auto  pt-3`}
              onSubmit={handleSubmit}
            >
              <div className="flex justify-between">
                <div className="px-6 py-2">
                  <label className="text-434852 text-base font-semibold ">
                    Basic Information
                  </label>
                </div>
                <div className="px-6 py-2 flex space-x-4">
                  <label className="text-434852 text-base font-semibold ">
                    External User
                  </label>
                  <div className={`flex items-center`}>
                    <label
                      className={`inline-flex items-center cursor-pointer`}
                    >
                      <input
                        type={`checkbox`}
                        name={"external"}
                        value={formData?.external}
                        className={`sr-only peer`}
                        onChange={handleFieldChange}
                      />
                      <div
                        className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
                      ></div>
                    </label>
                  </div>
                </div>
              </div>
              {!formData?.external && (
                <div>
                  <input
                    name="password"
                    type="password"
                    value={
                      formData?.password ? formData?.password : "123456789"
                    }
                    hidden
                  />
                  <div className="grid grid-cols-3 gap-4 px-6">
                    <div className="basis-5/5 mb-3">
                      <label htmlFor="first_name" className="block text-lg">
                        <span className="text-sm font-500 text-434852">
                          First Name
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="Enter First Name"
                        value={formData?.first_name}
                        onChange={handleFieldChange}
                        className="bg-white border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full rounded-5px"
                      />
                      {errors?.first_name && (
                        <span className="text-red-500 text-xxs">
                          {errors?.first_name}
                        </span>
                      )}
                    </div>
                    <div className="basis-5/5 mb-3">
                      <label
                        htmlFor="last_name"
                        className="block text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Last Name
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Enter Last Name"
                        value={formData?.last_name}
                        onChange={handleFieldChange}
                        className="bg-white  border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full  rounded-5px "
                      />
                      {errors.last_name && (
                        <span className="text-red-500 text-xxs">
                          {errors.last_name}
                        </span>
                      )}
                    </div>

                    <div className="mt-2">
                      <label htmlFor="email " className="block ">
                        <span className="font-medium	text-sm text-434852">
                          Email
                        </span>
                        <span className=" text-red-500"> *</span>
                        {/* {errors.email && (
                          <span className="text-red-700">{errors.email}</span>
                        )} */}
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter Email"
                        value={formData?.email}
                        onChange={handleFieldChange}
                        className="bg-white  border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full  rounded-5px "
                      />
                      {errors.email && (
                        <span className="text-red-500 text-xxs">
                          {errors.email}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="text-gray-700 text-base font-semibold px-6 py-2">
                    Company Information
                  </div>
                  <div className="grid grid-cols-3  gap-4 px-6">
                    <div className="basis-5/5 mb-3">
                      <label
                        htmlFor="emp_id"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Employee ID
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <input
                        type="text"
                        id="emp_id"
                        name="emp_id"
                        placeholder="Enter Employee ID"
                        value={formData?.emp_id}
                        onChange={handleFieldChange}
                        className="bg-white  border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full  rounded-5px"
                      />
                      {errors.emp_id && (
                        <span className="text-red-500 text-xxs">
                          {errors.emp_id}
                        </span>
                      )}
                    </div>

                    <div className="relative basis-5/5 mb-3">
                      <label
                        htmlFor="designation"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Designation
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>

                      <div className="flex items-center justify-end">
                        <select
                          id={`designation`}
                          name="designation"
                          value={formData?.designation}
                          onChange={handleFieldChange}
                          className={`custom-select bg-white border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full rounded-5px py-0.5`}
                        >
                          <option selected hidden>
                            Select Designation
                          </option>
                          {allAddressData?.designations &&
                            allAddressData?.designations.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.designation}
                              </option>
                            ))}
                        </select>
                        <div className="absolute flex px-2 pb-2 pointer-events-none py-2">
                          <h4>
                            <i class="bi bi-chevron-down"></i>
                          </h4>
                        </div>
                      </div>
                      {errors.designation && (
                        <span className="text-red-500 text-xxs">
                          {errors.designation}
                        </span>
                      )}
                    </div>
                    <div className=" relative basis-5/5 mb-3">
                      <label
                        htmlFor="department"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Department
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>

                      <div className="flex items-center justify-end">
                        <select
                          id={`department`}
                          name="department"
                          value={formData?.department}
                          onChange={handleFieldChange}
                          className={`custom-select bg-white  border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full py-0.5 rounded-5px`}
                        >
                          <option selected hidden>
                            Select Department
                          </option>
                          {allAddressData?.departments &&
                            allAddressData?.departments.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.department}
                              </option>
                            ))}
                        </select>
                        <div className="absolute flex px-2 pb-2 pointer-events-none py-2">
                          <h4>
                            <i class="bi bi-chevron-down"></i>
                          </h4>
                        </div>
                      </div>
                      {errors.department && (
                        <span className="text-red-500 text-xxs">
                          {errors.department}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="w-[35%] px-6">
                    <div className=" relative basis-5/5 mb-3">
                      <label
                        htmlFor="owner"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Reporting To
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <div>
                        <OwnerDropDown
                          tableidclass={tableDetail.tableidclass}
                          fetchPersonInTable={fetchPersonData}
                          DropData={personTableData}
                          handleInsert={handleInsert}
                          owner={formData?.owner}
                          fieldname={"owner"}
                        />
                      </div>
                      {errors.owner && (
                        <span className="text-red-500 text-xxs">
                          {errors.owner}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="px-6 py-2">
                    <span className=" text-gray-700 text-base font-semibold font-['Poppins'] capitalize">
                      Additional Information
                    </span>
                  </div>
                  <div className="grid grid-cols-3  gap-4 px-6">
                    <div className="">
                      <label
                        htmlFor="dob"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Date of Birth
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <div className="custom-datepicker">
                        <DatePicker
                          name="dob"
                          selected={
                            formData?.dob ? new Date(formData.dob) : null
                          }
                          onChange={handleDateChange}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Select a start date"
                          className="custom-select bg-white border border-DFE2E5 focus:outline-none focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full rounded-5px"
                          maxDate={new Date()}
                          minDate={getMinDate()}
                          showYearDropdown
                          showMonthDropdown
                          scrollableYearDropdown
                        />
                      </div>
                      {errors.dob && (
                        <span className="text-red-500 text-xxs">
                          {errors.dob}
                        </span>
                      )}
                    </div>

                    <div className="relative basis-5/5 mb-3">
                      <label
                        htmlFor="gender"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Gender
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <div className="flex items-center justify-end">
                        <select
                          id={`gender`}
                          name="gender"
                          value={formData?.gender}
                          onChange={handleFieldChange}
                          className={`custom-select bg-white border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full py-0.5 rounded-5px`}
                        >
                          <option selected hidden>
                            Select Your Gender
                          </option>
                          <option>Male</option>
                          <option>Female</option>
                        </select>
                        <div className="absolute flex px-2 pb-2 pointer-events-none">
                          <h4>
                            <i class="bi bi-chevron-down"></i>
                          </h4>
                        </div>
                      </div>
                      {errors.gender && (
                        <span className="text-red-500 text-xxs">
                          {errors.gender}
                        </span>
                      )}
                    </div>
                    <div className="">
                      <label
                        htmlFor="doj"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Date of Joining
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <div className="custom-datepicker">
                        <DatePicker
                          name="doj"
                          selected={
                            formData?.doj ? new Date(formData?.doj) : null
                          }
                          onChange={handleDateOfJoin}
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Select a start date"
                          className="custom-select bg-white  border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full rounded-5px"
                          maxDate={new Date()}
                          minDate={getMinDate()}
                          showYearDropdown
                          showMonthDropdown
                          scrollableYearDropdown
                        />
                      </div>
                      {errors.doj && (
                        <span className="text-red-500 text-xxs">
                          {errors.doj}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="px-6 py-2 ">
                    <span className=" text-gray-700 text-base font-semibold font-['Poppins'] capitalize">
                      Access Level
                    </span>
                  </div>
                  <div className="grid grid-cols-3  gap-4 px-6 mb-6">
                    <div className="">
                      <label
                        htmlFor="assign_to"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Assign To
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <div className="flex items-center justify-end">
                        <select
                          id={`assign_to`}
                          name="assign_to"
                          value={formData?.assign_to}
                          onChange={handleFieldChange}
                          className={`custom-select bg-white border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full py-0.5 rounded-5px`}
                        >
                          <option selected hidden>
                            Select Group
                          </option>
                          {groupData.map((EachItem,i)=>(<option className="cursor-pointer"  selected={formData?.group_id === EachItem.id} key={EachItem.id}>{EachItem.group_name}</option>))}
                        </select>
                        <div className="absolute flex px-2 pt-1 pointer-events-none">
                          <h4>
                            <i class="bi bi-chevron-down"></i>
                          </h4>
                        </div>
                      </div>
                      {errors.assign_to && (
                        <span className="text-red-500 text-xxs">
                          {errors.assign_to}
                        </span>
                      )}
                    </div>

                    <div className="relative basis-5/5 mb-3">
                      <label
                        htmlFor="gender"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                        Permission Level
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <div className="flex items-center justify-end">
                        <select
                          id={`permission_level`}
                          name="permission_level"
                          value={formData?.permission_level}
                          onChange={handleFieldChange}
                          className={`custom-select bg-white border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full py-0.5 rounded-5px`}
                        >
                          <option selected hidden>
                            Select Level
                          </option>
                          <option value="0" selected={parseInt(formData?.owner)===0}>Contributor</option>
                          <option value="1" selected={parseInt(formData?.owner)===1}>Owner</option>
                        </select>
                        <div className="absolute flex px-2 pt-1 pointer-events-none">
                          <h4>
                            <i class="bi bi-chevron-down"></i>
                          </h4>
                        </div>
                      </div>
                      {errors.permission_level && (
                        <span className="text-red-500 text-xxs">
                          {errors.permission_level}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="sticky bottom-0 p-4 flex justify-end px-6 py-4 modal-footer text-md w-full rounded-none z-[9998]">
                    <div className="px-2">
                      <button
                        onClick={() => {
                          closePopup();
                        }}
                        type="button"
                        className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 px-5 py-1 text-center rounded-5px"
                      >
                        CANCEL
                      </button>
                      <button
                        type="submit"
                        className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn border border-gray-200 py-1 px-5 ms-3 rounded-5px"
                      >
                        {editData ? "Update" : "SAVE"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {formData?.external && (
                <div>
                  <input
                    name="password"
                    type="password"
                    value={
                      formData?.password ? formData?.password : "123456789"
                    }
                    hidden
                  />
                  <div className="grid grid-cols-3 gap-4 px-6">
                    <div className="basis-5/5 mb-3">
                      <label htmlFor="first_name" className="block text-lg">
                        <span className="text-sm font-500 text-434852">
                          First Name
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="Enter First Name"
                        value={formData?.first_name}
                        onChange={handleFieldChange}
                        className="bg-white border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full rounded-5px"
                      />
                      {errors?.first_name && (
                        <span className="text-red-500 text-xxs">
                          {errors?.first_name}
                        </span>
                      )}
                    </div>
                    <div className="basis-5/5 mb-3">
                      <label
                        htmlFor="last_name"
                        className="block text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Last Name
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Enter Last Name"
                        value={formData?.last_name}
                        onChange={handleFieldChange}
                        className="bg-white  border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full  rounded-5px "
                      />
                      {errors.last_name && (
                        <span className="text-red-500 text-xxs">
                          {errors.last_name}
                        </span>
                      )}
                    </div>

                    <div className="mt-2">
                      <label htmlFor="email " className="block ">
                        <span className="font-medium	text-sm text-434852">
                          Email
                        </span>
                        <span className=" text-red-500"> *</span>
                        {/* {errors.email && (
                          <span className="text-red-700">{errors.email}</span>
                        )} */}
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter Email"
                        value={formData?.email}
                        onChange={handleFieldChange}
                        className="bg-white  border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full  rounded-5px "
                      />
                      {errors.email && (
                        <span className="text-red-500 text-xxs">
                          {errors.email}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="text-gray-700 text-base font-semibold px-6 py-2">
                    Company Information
                  </div>

                  <div className="w-[35%] px-6">
                    <div className=" relative basis-5/5 mb-3">
                      <label
                        htmlFor="owner"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Reporting To
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <div>
                        <OwnerDropDown
                          tableidclass={tableDetail.tableidclass}
                          fetchPersonInTable={fetchPersonData}
                          DropData={personTableData}
                          handleInsert={handleInsert}
                          owner={formData?.owner}
                          fieldname={"owner"}
                        />
                      </div>
                      {errors.owner && (
                        <span className="text-red-500 text-xxs">
                          {errors.owner}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="px-6 py-2 ">
                    <span className=" text-gray-700 text-base font-semibold font-['Poppins'] capitalize">
                      Access Level
                    </span>
                  </div>
                  <div className="grid grid-cols-3  gap-4 px-6 mb-6">
                    <div className="">
                      <label
                        htmlFor="assign_to"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                          Assign To
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <div className="flex items-center justify-end">
                      <select
                          id={`assign_to`}
                          name="assign_to"
                          value={formData?.assign_to}
                          onChange={handleFieldChange}
                          className={`custom-select bg-white border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full py-0.5 rounded-5px`}
                        >
                          <option selected hidden>
                            Select Group
                          </option>
                          {groupData.map((EachItem,i)=>(<option className="cursor-pointer"  selected={formData?.group_id === EachItem.id} key={EachItem.id}>{EachItem.group_name}</option>))}
                        </select>
                        <div className="absolute flex px-2 pt-1 pointer-events-none">
                          <h4>
                            <i class="bi bi-chevron-down"></i>
                          </h4>
                        </div>
                      </div>
                      {errors.assign_to && (
                        <span className="text-red-500 text-xxs">
                          {errors.assign_to}
                        </span>
                      )}
                    </div>

                    <div className="relative basis-5/5 mb-3">
                      <label
                        htmlFor="gender"
                        className="block  text-lg text-434852"
                      >
                        <span className="text-sm font-500 text-434852">
                        Permission Level
                        </span>
                        <span className=" text-red-500"> *</span>
                      </label>
                      <div className="flex items-center justify-end">
                      <select
                          id={`permission_level`}
                          name="permission_level"
                          value={formData?.permission_level}
                          onChange={handleFieldChange}
                          className={`custom-select bg-white border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full py-0.5 rounded-5px`}
                        >
                          <option selected hidden>
                            Select Level
                          </option>
                          <option value="0" selected={parseInt(formData?.owner)===0}>Contributor</option>
                          <option value="1" selected={parseInt(formData?.owner)===1}>Owner</option>
                        </select>
                        <div className="absolute flex px-2 pt-1 pointer-events-none">
                          <h4>
                            <i class="bi bi-chevron-down"></i>
                          </h4>
                        </div>
                      </div>
                      {errors.permission_level && (
                        <span className="text-red-500 text-xxs">
                          {errors.permission_level}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="sticky bottom-0 p-4 flex justify-end px-6 py-4 modal-footer text-md w-full rounded-none z-[9998]">
                    <div className="px-2">
                      <button
                        onClick={() => {
                          closePopup();
                        }}
                        type="button"
                        className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 px-5 py-1 text-center rounded-5px"
                      >
                        CANCEL
                      </button>
                      <button
                        type="submit"
                        className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn border border-gray-200 py-1 px-5 ms-3 rounded-5px"
                      >
                        {editData ? "Update" : "SAVE"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};
export default AddUserModal;