import React, { useState, useRef, useEffect, useCallback } from "react";
import NotesIcon from "../../../Icons/NotesIcon";
import CustomerListTaskPopup from "../../../customer/CustomerListTaskPopup";
import TasListkModal from "../../../customer/TasListkModal";
import CustomerAddTaskModel from "../../../customer/CustomerAddTaskModel";
import {
  useFetchCustomerNotesColorDataQuery,
  useFetchTaskDataQuery,
} from "../../../../utils/customer/customerSelect";
import useRequireAuth from "../../../../utils/useRequireAuth";
import { useDispatch, useSelector } from "react-redux";
import { saveNotes } from "../../../../utils/customer/customerNotesSlice";
import NotesContent from "./NotesContent";
import CustomerNameViewNotes from "./CustomerNameViewNotes";
import CustomerNameNotesEditor from "./CustomerNameNotesEditor";
import useClickOutside from "../../../../hooks/Screen/useClickOutside";
import { useFetchCustomerNotesTableDataQuery, useLazyFetchCustomerNotesTableDataQuery } from "../../../../utils/customer/customerTaskTable";
import { CUSTMOER_NOTES_DATA_GET } from "../../../../utils/constants";
import { getCommonDataBasedOnId } from "../../../store/customer/CustomerSocialAddUpdateFunction";
import GrantIcon from "../../../Icons/GrantIcon";
import AddUpdateCustomerDelegation from "../../../customer/AddUpdateCustomerDelegation";
import TaskIcon from "../../../Icons/TaskIcon";
import useDropdownPosition from "../../../../hooks/Dropdown/useDropDownPosition";
import CreatePortalHOC from "../../Fields/CreatePortalHOC";
import FirstMessage from "../../MessageAlert/FirstMessage";
const CustomerName = ({ Item,multiData,refetchCustomerData }) => {
  const { token } = useRequireAuth();
  const dispatch = useDispatch();
  const [isOpenHover, setIsOpenHover] = useState(false);
  const { dropdownPosition, dropdownRef } = useDropdownPosition(true, 'fullscreen-table');
  const [dropdownCoord, setDropdownCoord] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [taskPopupOpen, setTaskPopupOpen] = useState();
  const [notesSetting, setNotesSetting] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const textareaRef = useRef(null);
  const popupRef = useRef(null);
  const [notesData, setNotesData] = useState([]);
  const [viewNotes, setViewNotes] = useState({
    allnotes: false,
    addnotes: false,
  });
  
  const [initialContent, setInitialContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [colour, setColor] = useState("#FF0000");
  const [formData, setFormData] = useState({
    notes_title: "",
    notes_category_id: "",
    colour_code: "",
  });

  const {
    data: customerColor,
    error: customerCodeerror,
    isLoading: customerColorLoading,
  } = useFetchCustomerNotesColorDataQuery({
    token,
  });
  const [show,setShow]=useState('');
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  

  const handleInputChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSetColour = (colour) => {
    setColor(colour);
  };

  const [ NotesFetchDataLazy,{refetch: refetchCustomerNotesData} ] =
  useLazyFetchCustomerNotesTableDataQuery(); 


  const handleNotesClick = async(key) => {
    setViewNotes({ allnotes: true, addnotes: true });

      await NotesFetchDataLazy({
        customerId:Item?.customer_id?.id,
        token: token,
      }).unwrap().then((response) => {
        setNotesData(response.data);
      }).catch((error) => {
        console.error('Error fetching customer notes data:', error);
      });
  }; 
  // const handleNotesClick = (key) => {
  //   setViewNotes({ allnotes: true, addnotes: true });
  // };
  const handleCloseNotes = () => {
    setViewNotes({ allnotes: false, addnotes: false });
  };

  const closenotesoutsideclick = useClickOutside(() => {
    setViewNotes({ allnotes: false, addnotes: false });
  });

  function handleFileUpload() {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.click();
    input.addEventListener("change", (event) => {
      const files = event.target.files;
      const fileList = Array.from(files);
      // setSelectedFiles(fileList);
      setSelectedFiles((prevFiles) => [...prevFiles, ...fileList]);
      // console.log(fileList ,"filelist")
    });
  }

  const resetFile = () => {
    setSelectedFiles([]);
  };

  const closePopupCustomer = () => {
    setTaskPopupOpen(false);
    setCustomerAccess(false);
  };

  const [firstPopupOpen, setFirstPopupOpen] = useState(false);
  const [secondPopupOpen, setSecondPopupOpen] = useState(false);
  const [customerAccess, setCustomerAccess] = useState(false);

  const openFirstPopup = (id) => {
    setFirstPopupOpen(id);
  };

  const openSecondPopup = () => {
    setFirstPopupOpen(false);
    setSecondPopupOpen(true);
  };

  const closeSecondPopup = () => {
    setSecondPopupOpen(false);
  };

  const isOpen = () => {};

  const handleClickDeleteFile = (index) => {
    if (index >= 0 && index < selectedFiles?.length) {
      setSelectedFiles((prevFiles) =>
        prevFiles.filter((file, idx) => idx !== index)
      );
    }
  };

  // const { data: customerTableALL, refetch: refetchCustomerData } =
  //   useFetchCustomerNotesTableDataQuery({
  //     customerId: Item?.customer_id?.id,
  //     token: token,
  //   });

  // useEffect(() => {
  //   if (customerTableALL) {
  //     setNotesData(customerTableALL?.data);
  //   }
  // }, [customerTableALL, refetchCustomerData]);


  const { data: customerTaskCategory } = useFetchTaskDataQuery({
    token,
    jsonData: { customer_id: Item?.customer_id?.id, company_id: Item?.company_id?.id },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const notesData = {
      notes_category_id: e?.target?.notes_category_id?.value,
      colour_code: colour,
      notes_title: formData.notes_title,
      company_id: Item?.company_id?.id,
      customer_id: Item?.customer_id?.id,
      notes_body: textareaRef.current.innerHTML,
      rag_status: "wip",
      attachment: selectedFiles,
    };
    if (textareaRef.current.innerHTML !== "") {
      await dispatch(saveNotes({ notesData, token }));
      await fetchData();
      resetFile();
      setRefreshKey((prevKey) => prevKey + 1);
    }
  };

  const fetchData = async () => {
    const url = `${CUSTMOER_NOTES_DATA_GET}${Item?.customer_id?.id}`;
    const response = await getCommonDataBasedOnId(url, token);
    setNotesData(response?.data);
  };

  const handleCustomerAccess = () => {
    setCustomerAccess(true);
  };

  const closePopup = () => {
    setCustomerAccess(false);
    setFirstPopupOpen(false);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup();
    }
  };  

  useEffect(() => {
    if (dropdownCoord !== null && !isOpenHover) {
        setIsOpenHover(true);
    }
  }, [dropdownCoord]);

  const getCreatePortalPosition = () => {
    if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect(); // Get the bounding rectangle
        const left = (rect.left)+18;
        const top = (rect.top)-20;
        return ({
            top: top + window.scrollY,
            left: left + window.scrollX,
            borderRadius: '5',
        });
    }
  }

  const handleOpen = (event) => {
    event.stopPropagation()
    setDropdownCoord((prevState) => { return getCreatePortalPosition() })
  }

  const handleClose = () => {
    setDropdownCoord(null)
    setIsOpenHover(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  return (
    <>
    {/* <div className={`w-full absolute top-0 h-full mujeeb`} ref={dropdownRef} onMouseOver={handleOpen} onMouseLeave={handleClose}>123456</div> */}
  
  <div onMouseOver={handleOpen}  onMouseLeave={handleClose}>
    <button   
      type="button"
      id="threedot"
      className="threedot text-2541558A hover:text-254155"
      ref={dropdownRef} 
    
    >
      <i class="bi bi-three-dots-vertical text-14"></i>
    </button>
    <CreatePortalHOC isOpen={isOpenHover} onClose={handleClose} position={dropdownCoord} verticalDist={8}>
        <div className="">
        <div class="arrow-left"></div>
        {multiData.length < 2 && (
          <>
            <div className="p-2 pt-3 border-b border-gray-200">
              <button
                onClick={() => openFirstPopup(Item?.customer_id?.id)}
                type="button"
                className="text-14 text-778FA5 hover:text-1D4469"
              >
                <span className="">
                  <TaskIcon />
                  <span className="ms-2">Add Task</span>
                </span>
              </button>
            </div>
            <div className="p-2 border-b border-gray-200">
              <button
                onClick={handleNotesClick}
                type="button"
                className="text-14 text-778FA5 hover:text-1D4469"
              >
                <span className="">
                  <NotesIcon />
                  <span className="ms-2">Add Notes</span>
                </span>
              </button>
            </div>
          </>  
        )}
        <div className="p-2">
          <button
            onClick={handleCustomerAccess}
            type="button"
            className="text-14 text-778FA5 hover:text-1D4469"
          >
            <span className="">
              <GrantIcon />
              <span className="ms-2">Add Delegation</span>
            </span>
          </button>
        </div>
        </div>
    </CreatePortalHOC>

  </div>

    {/* <div className="customHoverElement" key={refreshKey}>
      <div class="arrow-left hidden"></div>
      <div className="p-2 pt-3 border-b border-gray-200">
        <button
          onClick={() => openFirstPopup(Item?.customer_id?.id)}
          type="button"
          className="text-14 text-778FA5 hover:text-1D4469"
        >
          <span className="tablehoverelement">
            <TaskIcon />
            <span className="ms-2">Add Task</span>
          </span>
        </button>
      </div>
      <div className="p-2 border-b border-gray-200">
        <button
          onClick={handleNotesClick}
          type="button"
          className="text-14 text-778FA5 hover:text-1D4469"
        >
          <span className="tablehoverelement">
            <NotesIcon />
            <span className="ms-2">Add Notes</span>
          </span>
        </button>
      </div>
      <div className="p-2">
        <button
          onClick={handleCustomerAccess}
          type="button"
          className="text-14 text-778FA5 hover:text-1D4469"
        >
          <span className="tablehoverelement">
            <GrantIcon />
            <span className="ms-2">Add Delegation</span>
          </span>
        </button>
      </div>
    </div>
    <button   
      type="button"
      id="treedot"
      className="treedot text-2541558A hover:text-254155 me-1"
    >
      <i class="bi bi-three-dots-vertical text-14"></i>
    </button>  */} 
      {firstPopupOpen && (
        // <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
        
          <TasListkModal
            isOpen={isOpen}
            openSecondPopup={openSecondPopup}
            closePopup={closePopup}
            customerId={Item?.customer_id?.id}
            customerName={Item?.customer_name.value}
            popupRef={popupRef}
          />
        // </div>
      )}

      {/* Second Popup */}
      {secondPopupOpen && (
        <CustomerAddTaskModel isOpen={isOpen} closePopup={closeSecondPopup} />
      )}
      
      {customerAccess && isOpen && (
        <AddUpdateCustomerDelegation
          isOpen={isOpen}
          closePopup={closePopupCustomer}
          customerId={Item?.customer_id?.id}
          multiData={multiData}
          companyId={Item?.company_id?.value}
          refetchCustomerData={refetchCustomerData}
          show={show}
          setShow={setShow}
          alert={alert}
          setAlert={setAlert}
        />
      )}
      
      {viewNotes?.allnotes && viewNotes?.addnotes && (
        <CreatePortalHOC className="absolute top-0" dialog isOpen={isOpen}>
        <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
          <div className="fixed inset-0 flex justify-end z-[9999] bg-gray-900 bg-opacity-50">
            <div
              ref={closenotesoutsideclick}
              className="relative  bg-white  font-popns drop-shadow-lg  rounded-md w-[634px] overflow-hidden no-scrollbar top-0"
            >
              <div className="px-4 h-full flex flex-col">
                <div className="flex justify-between items-center py-2 ">
                  <div className="text-xl font-medium text-203C50">{Item?.customer_name?.value} - Notes</div>
                  <div
                    className="rounded-[5px] bg-F1F5F8 w-[30px] h-[30px] flex justify-center items-center"
                    onClick={handleCloseNotes}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                </div>   
                <NotesContent notesData={notesData} key={refreshKey} />
                
                <div
                  className={`notes m-2  ${
                    Object.values(viewNotes).some((value) => value)
                      ? "show"
                      : ""
                  }`}
                >
                  <CustomerNameViewNotes
                    key={refreshKey}
                    setNotesSetting={setNotesSetting}
                    notesSetting={notesSetting}
                    handleNotesClick={handleNotesClick}
                    textareaRef={textareaRef}
                    visible={viewNotes.addnotes}
                    selectedFile={selectedFiles}
                    resetFile={resetFile}
                    customerTaskCategory={customerTaskCategory}
                    customerColor={customerColor}
                    handleSubmit={handleSubmit}
                    handleInputChange={handleInputChange}
                    formData={formData}
                    handleSetColour={handleSetColour}
                  >
                    <div
                      className={`grid grid-cols-1 ${
                        viewNotes.addnotes ? "show" : "hidden"
                      }`}
                    >
                      <CustomerNameNotesEditor
                        key={refreshKey}
                        textareaRef={textareaRef}
                        initialContent={initialContent}
                        setInitialContent={setInitialContent}
                        notesSetting={notesSetting}
                        handleFileUpload={handleFileUpload}
                        selectedFile={selectedFiles}
                        hndleCloseNotes={handleCloseNotes}
                        handleClickDeleteFile={handleClickDeleteFile}
                      />
                    </div>
                  </CustomerNameViewNotes>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        </CreatePortalHOC>
      )}

      <div className="relative inline-block">
        {isPopupOpen && (
          <div className="">
            <CustomerListTaskPopup
              isPopupOpen={isPopupOpen}
              setIsPopupOpen={setIsPopupOpen}
            />
          </div>
        )}
      </div>
      {alert.visible && <FirstMessage
      type={alert.type}
      message={alert.message}
      visible={alert.visible}
      onHide={hideAlert}
    />}
    
    
  </>
  );
};

export default CustomerName;
