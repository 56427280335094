import React, { useState } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import { saveContactPersonDataAsync } from "../../utils/customer/contactPersonsSlice";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFetchCustomerDesignationQuery } from "../../utils/customer/customerSelect";
import useClickOutside from "../../hooks/Screen/useClickOutside";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";



const validationRules = {

  name: {
    required: true,
    errorMessage: 'Name is required.',
  },
  contact_no: [
    {
       required: true,
       errorMessage: 'Phone Number is required.',
    },
    {
      number: true,
      errorMessage: 'Phone Number should contain only digits.',
    },
    {
      maxDigits: 15,
      errorMessage: 'Must be between 1 to 15 digits.',
    }
  ],
  contact_email: [
    {
      required: true,
      errorMessage: 'Email is required.',
   },
   {
     email: true,
     errorMessage: 'Provide valid email',
   },
  ],

};


const CustomerContactPersonModel = ({ isOpen, onClose ,handleSubmitData}) => {
  const { token } = useRequireAuth();
  const { id } = useParams();
  const customerId = parseInt(id);
  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [isValidEmail,setIsValidEmail]=useState(true);
  
  const closeModalOutside = useClickOutside(() => {
    onClose();
  });

  const [formErrors, setFormErrors] = useState({
    name:'',
    contact_no:'',
    contact_email:'',
});
const [formData, setFormData] = useState({
    name:"",
    contact_no:'',
    contact_email:'',  
});

const { values, errors, touched, handleChange, handleBlur, validateField, isFormValid } = useCustomFormValidation(
  formData,
  validationRules
);

  const handleFieldChange = (e) => {
    handleChange(e)
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const requiredFields = ['name','contact_no','contact_email'];
    const errors = {};
    requiredFields.forEach(field => {
        if (!formData[field]) {
            errors[field] = 'Cannot be left blank.';
        }
    });
   
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async(event) => {
    event.preventDefault();
    
    const formValid = isFormValid();

    if(!formValid){
      return;
    }

    if (
      event.target.name.value &&
      event.target.contact_no.value !== "" &&
      event.target.contact_email.value !== ""
    ) {
      const socialData = {
        customer_id: customerId,
        name: event.target.name.value,
        contact_no:event.target.contact_no.value,
        contact_email:event.target.contact_email.value,
        company_id: parsedCompanyData.company_id,
      };
      try {
        await dispatch(saveContactPersonDataAsync({ socialData, token }));
        await handleSubmitData(customerId);
        onClose();
      } catch (error) {
      }
     
    }
  };

  const {
    data: designationList,
    error: designationListError,
    isLoading: designationListLoading,
  } = useFetchCustomerDesignationQuery({ token });
  

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <div className="fixed inset-y-0 z-[999] grid h-screen place-items-center justify-start" ref={closeModalOutside}>
      <div className="relative  py-3 mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md w-500px overflow-hidden no-scrollbar">
        <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
          <div className="px-2">
           
          </div>
          <br />
          <h3 className="text-20 font-500 text-434852">Add Contact</h3>

          <button
            onClick={onClose}
            type="button"
            className="text-454545 bg-F1F5F8 hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <form
          onSubmit={handleSave}
          className="w-full lg:max-h-[70vh] overflow-x-hidden  no-scrollbar"
        >
          <div className="basis-5/5 px-6 pt-5">
            <label
              htmlFor="Designation"
              className="block mb-1 text-14 font-500 text-434852"
            >
              Name <span className="mb-1 text-red-500"> * </span>
              {errors.name && (
              <span className="text-red-500">
                {errors.name}
              </span>
            )}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              onChange={handleFieldChange}
              onBlur={handleBlur}
             
              className="bg-white border h-[30px] border-DFE2E5 rounded-[5px] text-gray-900 text-sm block w-full p-2.5"
            />
           
          </div>

          <div className="p-3 md:px-6 pt-4 ">
            <label
              htmlFor="contact_email"
              className="block mb-1 text-14 font-500 text-434852"
            >
              Email <span className=" text-red-500"> * </span>
              {errors.contact_email && (
              <span className="text-red-500">
                {errors?.contact_email}
              </span>
              )}
            </label>
            <input
              type="text"
              id="contact_email"
              name="contact_email"
              onChange={handleFieldChange}
              onBlur={handleBlur}
              className="bg-white border border-DFE2E5 rounded-[5px] text-gray-900 text-sm block w-full h-[30px]"
            />
             
            
          </div>
          <div className="basis-5/5  px-6 pt-1">
            <label
              htmlFor="contact_no"
              className="block mb-1 text-14 font-500 text-434852"
            >
              Contact No <span className=" text-red-500"> * </span>
              {errors.contact_no && (
                      <span className="text-red-500">
                        {errors.contact_no}
                      </span>
                    )}
            </label>
            <input
              type="text"
              id="contact_no"
              name="contact_no"
              onChange={handleFieldChange}
              onBlur={handleBlur}
              maxLength="16" 
              className="bg-white rounded-[5px] border border-DFE2E5 text-gray-900 text-sm block w-full p-2.5 h-[30px] "
            />     
          </div>

          <div className="flex justify-end py-4 pt-8 text-md w-full">
            <div className="px-6">
              <button
                onClick={onClose}
                type="button"
                className="px-6 py-1 text-16  rounded font-500 bg-F4F4F4 focus:outline-none me-2"
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="px-6 py-1 text-16 rounded font-500 bg-1D4469 text-white focus:outline-none cbtn-transition-bg cbtn-transition-bg "
              >
                SAVE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerContactPersonModel;
