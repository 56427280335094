import React, { useState, useEffect } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import {
  useFetchTaskDataQuery,
  useFetchCustomerUseListDataQuery,
} from "../../utils/customer/customerSelect";

import { saveTasks, hideMessage } from "../../utils/customer/customerTaskSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import CustomerEditTaskActivity from "./CustomerEditTaskActivity";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import { CUSTOMER_EDIT_TASK_DATA } from "../../utils/constants";
import OwnerDropDown from "../GlobalFeature/Fields/OwnerDropDown";
import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import { useFetchCustomerAllTaskPodDataQuery } from "../../utils/customer/customerSelect";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddCombineDate from "./AddCombineDate";
const validationRules = {
  subject: {
    required: true,
    errorMessage: 'Subject is required.',
  },
  due_date: {
    required: true,
    errorMessage: 'Due is required.',
  },
 
};

const formatDate = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const formattedDate = `${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getFullYear()}`;
  return formattedDate;
};

const dateformatValue = (dateString) => {
  if (dateString && typeof dateString === "string") {
    const datesplit = dateString.split("-");
    const datevalue = `${datesplit[2]}-${datesplit[1]}-${datesplit[0]}`;
    const dateObj = new Date(datevalue + "T00:00:00Z");
    return dateObj;
  } else {
    return undefined;
  }
};


const EditTaskModel = ({ isOpen, closePopup, taskid, handelSubmitData }) => {
 
  const { token } = useRequireAuth();
  const { id } = useParams();
  const customerId = parseInt(id);
  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [selectedItem, setSelectedItem] = useState("");
  const [isOpens, setIsOpens] = useState(false);
  const [statusTask, setStatusTask] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [dueDate,setDueDate]=useState();


  const [contactName,setContactName]=useState(null);
  const [formData, setFormData] = useState({
    subject: "",
    category_id: "",
    due_date:"",
    priority: "",
    owner: "",
    task_pod: "",
    contact_name: "",
    status: "",
    description: "",
    closing_remarks: "",
   
  });
 
  const handleInputDateChange=(value)=>{
    setFormData(prevFormData => ({
      ...prevFormData,
      due_date:value.due_date,
    }));

  }

  const { values, errors, touched, handleChange, handleBlur, validateField, isFormValid } = useCustomFormValidation(
    formData,
    validationRules
  );

  const fetchTaskEditData = async (taskid) => {
    const url = `${CUSTOMER_EDIT_TASK_DATA}${taskid}`;
    const getData = await getCommonDataBasedOnId(url, token);
    setFormData(getData[0]);
    setDueDate(getData[0]?.due_date);
    if(getData[0]?.category_id==='Task'){
        setIsOpens(true);
    }
  };
  



  useEffect(() => {
    fetchTaskEditData(taskid);
  }, [taskid]);

  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'category_id') {
      if (value === 'Task') {
        setIsOpens(true);
      } else {
        setIsOpens(false);
      }
      setRefreshKey(prevKey => prevKey + 1);
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };
 
  const handleSubmitTask = async (event) => {
    event.preventDefault();
    const formValid = isFormValid();
    if(!formValid){
      return;
    }

    try {

      const taskDatas = {
        ...formData,
        priority: formData.priority || event.target.priority.value,
        status: formData.status || event.target.status.value,
        category_id: formData.category_id || event.target.category_id.value,
        task_pod:formData.task_pod || event.target.task_pod.value,
        customer_id: customerId,
        due_date:formData.due_date,
        company_id: parsedCompanyData.company_id,
        id: taskid,
    };

    

    await dispatch(saveTasks({ taskDatas, token }));
    await handelSubmitData(customerId);
    closePopup();
    event.target.reset();
      
    } catch (error) {
      console.log(error)
    }
  };

  const {
    data: customerTaskCategory,
    error,
    isLoading,
  } = useFetchTaskDataQuery({
    token,
    jsonData: {
      customer_id: customerId,
      company_id: parsedCompanyData.company_id,
    },
  });

 

  const { data: customerTaskPodList } = useFetchCustomerAllTaskPodDataQuery({token});

  const handleInsert = (item) => {
    setSelectedItem(item);
    setFormData((prevFormData) => ({
      ...prevFormData,
      owner: item,
    }));
  };
 
  const isVisible = (status) =>
    status !== "Not started" &&
    status !== "Work in Progress" &&
    status !== "Hold";

  const onFocus = () => {};
  const tableDetail = {
    tableidclass: "taskmodelowner",
  };

  const [fetchPersonInTable, { data: personTableData }] =
    useLazyFetchPersonInTableQuery();

  const fetchPersonData = (data) => {
    fetchPersonInTable({ token: token, data: data });
  };

 
  const isEditTask=Boolean(taskid)
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999]">
      <div className="relative mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md w-[750px] overflow-hidden no-scrollbar">
        <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
          <h3 className="text-2xl text-2C495D ps-3">
            {isEditTask ? "Edit Task" : "Create Task"}
            -{formData?.subject}-{formData?.category_id}
          </h3>
          <button
            onClick={closePopup}
            type="button"
            className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="flex ">
          <div className="w-[60%]">
            <form
              id={tableDetail?.tableidclass}
              onSubmit={handleSubmitTask}
              className={`w-full lg:max-h-[85vh]  overflow-hidden  no-scrollbar pt-3 ${tableDetail?.tableidclass}`}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="subject"
                    className="block mb-2 text-sm text-434852"
                  >
                    Subject <span className=" text-red-500"> *</span>
                    {(errors.subject && formData.subject==='') && <span className="text-red-700">{errors.subject}</span>}
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Enter Subject"
                    value={formData.subject && formData.subject}
                    onChange={handleInputChange}
                    className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10"
                  />
                 
                </div>
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="categories"
                    className="block mb-2 text-sm text-434852"
                  >
                    Category
                  </label>
                  <div className="flex items-center justify-end">
                    <select
                      id={`category_id`}
                      name="category_id"
                      onChange={handleInputChange}
                      className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10 appearance-none pr-10`}
                    >
                      {!customerTaskCategory?.data?.task_category
                        ? null
                        : customerTaskCategory?.data?.task_category.map(
                            (priority) => (
                              <option
                                key={priority.id}
                                value={priority.value}
                                selected={
                                  priority.value === formData.category_id
                                }
                              >
                                {priority.value}
                              </option>
                            )
                          )}
                    </select>
                    <div className="absolute flex px-2 pointer-events-none">
                      <h4>
                        <i className="bi bi-chevron-down"></i>
                      </h4>
                    </div>
                  </div>                 
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="duedate"
                    className="basis-5/5 block mb-2 text-sm text-434852"
                  >
                    Due Date <span className=" text-red-500"> *</span>
                    {(errors.due_date && formData.due_date==='') && <span className="text-red-700">{errors.due_date}</span>}
                  </label>

                  <div className="flex ">
                    <AddCombineDate
                      isOpens={isOpens}
                      FieldChange={handleInputDateChange}
                      FieldValue={dueDate}
                    />

                  </div>
                </div>
                <div className="basis-5/5 mb-3">
                  <label
                    htmlFor="priority"
                    className="block mb-2 text-sm text-434852"
                  >
                    Priority
                  </label>
                  <div className="flex items-center justify-end">
                    <select
                      id={`priority`}
                      name="priority"
                      onChange={handleInputChange}
                      className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10`}
                    >
                      <option>Select Priority</option>
                      {customerTaskCategory?.data?.priority
                        && customerTaskCategory?.data?.priority.map(
                            (priority) => (
                              <option
                                key={priority.id}
                                value={priority.value}
                                selected={
                                  priority.value === formData.priority
                                }
                              >
                                {priority.value}
                              </option>
                            )
                          )}
                    </select>
                    <div className="absolute flex px-2 pointer-events-none">
                      <h4>
                        <i className="bi bi-chevron-down"></i>
                      </h4>
                    </div>
                  </div>                 
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
                <div className="basis-5/5">
                  <label
                    htmlFor="task_pod"
                    className="block mb-2 text-sm text-434852"
                  >
                    Link To Taskpod <span className=" text-red-500"> *</span>
                    {(errors.task_pod && formData.task_pod==='') && <span className="text-red-700">{errors.task_pod}</span>}
                  </label>
                  <div className="flex items-center justify-end">
                    <select
                      id={`task_pod`}
                      name="task_pod"
                      onChange={handleInputChange}
                      className={`custom-select mb-3 bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10`}
                    >
                      <option>Select Link</option>
                      {!customerTaskPodList
                        ? null
                        : customerTaskPodList?.data.map((ownerList) => (
                            <option
                              key={ownerList.id}
                              value={ownerList.pod_name}
                              selected={
                                ownerList.pod_name === formData.task_pod
                              }
                            >
                              {ownerList.pod_name}
                            </option>
                          ))}
                    </select>
                    <div className="absolute flex px-2 pointer-events-none">
                      <h4>
                        <i className="bi bi-chevron-down"></i>
                      </h4>
                    </div>
                  </div>
                  
                </div>

                <div className="basis-5/5">
                  <label
                    htmlFor="owner"
                    className="block mb-2 text-sm text-434852"
                  >
                    Owner
                  </label>
                  <OwnerDropDown
                    tableidclass={tableDetail.tableidclass}
                    fetchPersonInTable={fetchPersonData}
                    DropData={personTableData}
                    handleInsert={handleInsert}
                    owner={formData.owner}
                  />
                
                 
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
                <div className="basis-5/5">
                  <label
                    htmlFor="contact_name"
                    className="block mb-2 text-sm text-434852"
                  >
                    Contact Name
                  </label>
                  <input
                    type="text"
                    id="contact_name"
                    name="contact_name"
                    placeholder="Enter Contact Name"
                    value={formData.contact_name && formData.contact_name}
                    onChange={handleInputChange}
                    className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10"
                  />
                </div>
                <div className="basis-5/5">
                  <label
                    htmlFor="status"
                    className="block mb-2 text-sm text-434852"
                  >
                    Status
                  </label>
                  <div className="flex items-center justify-end">
                    <select
                      id={`status`}
                      name="status"
                      placeholderText="Select Status"
                      onChange={handleInputChange}
                      className={`custom-select bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-10`}
                     
                    >
                    
                      {customerTaskCategory?.data?.status
                        ? customerTaskCategory.data.status.map((status) => (
                            <option key={status.id} value={status.value} selected={formData.status.trim()===status.value.trim()}>
                              {status.value}
                            </option>
                          ))
                        : null}
                    </select>

                    <div className="absolute flex px-2 pointer-events-none">
                      <h4>
                        <i className="bi bi-chevron-down"></i>
                      </h4>
                    </div>
                  </div>
                 
                </div>
              </div>
              <div className="my-3 pe-3">
                <div className="addTask">
                  <div className="grid grid-cols-1">
                    <div className="basis-5/5 mb-3 px-6">
                      <label
                        htmlFor="description"
                        className="block mb-2 text-sm text-434852"
                      >
                        Description
                      </label>
                      <textarea
                        type="message"
                        id="description"
                        name="description"
                        placeholder="Enter Description"
                        onChange={handleInputChange}
                        value={formData.description && formData.description}
                        rows="1"
                        className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-[80px]"
                        style={{ style: "20%" }}
                      ></textarea>
                      <div className="text-sm text-red-400"></div>
                    </div>
                  </div>

                  <div
                    className={`grid grid-cols-1 ${
                      isVisible(statusTask) && isVisible(formData.status.trim())
                        ? ""
                        : "hidden"
                    }`}
                  >
                    <div className="basis-5/5 mb-3 px-6">
                      <label
                        htmlFor="remarks"
                        className="block mb-2 text-sm text-434852"
                      >
                        Closing Remark
                      </label>

                      <textarea
                        type="message"
                        id="closing_remarks"
                        name="closing_remarks"
                        placeholder="Enter remark"
                        onChange={handleInputChange}
                        value={formData.closing_remarks && formData.closing_remarks}
                        rows="1"
                        className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-sm block w-full h-[80px]"
                        style={{ style: "20%" }}
                      ></textarea>

                      <div className="text-sm text-red-400"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sticky bottom-0 bg-gray-200 p-4 flex justify-end py-4 modal-footer text-md w-full rounded-none">
                <div className="px-6">
                  <button
                    onClick={closePopup}
                    type="button"
                    className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-1 text-center rounded-5px"
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold border border-gray-200 py-1 px-5 ms-3 rounded-5px"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="w-[40%] pr-6">
            <CustomerEditTaskActivity onFocus={onFocus} taskid={taskid} />
            <div className="h-[67.5px] w-[40%] absolute bottom-0 left-[59%] bg-white p-4" style={{ borderTop: "1px solid #DADCDE" }}></div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default EditTaskModel;