import React, { useState, useRef, useEffect, Fragment } from "react";
import { useFetchCompanyCommonDataQuery } from "../../utils/company/companySelector";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import { parseJSONValue } from "../../utils/json/parseJSONValue";
import { useCustomerTableMutation } from "../../utils/customer/customerTable";
import useClickOutside from "../../hooks/Screen/useClickOutside";
import { useDispatch } from "react-redux";
import useFormDataAppend from "../../hooks/FormSubmit/useFormDataAppend";
import { saveVendor } from "../../utils/vendor/vendorTeamSlice";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import { EDIT_VENDOR_DATA_LIST } from "../../utils/constants";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import { BASE_URL } from "../../utils/constants";

const validationRules = {
  vendor_name: {
    required: true,
    errorMessage: "Name is required.",
  },
};

const EditVendor = ({ closeModal, vendorData }) => {
  const dispatch = useDispatch();
  const { appendIfNotEmpty } = useFormDataAppend();
  const [customerData, setCustomerData] = useState({
    vendor_name: "",
    vendor_display_name: "",
    website: "",
    type: "",
    customerFile: null,
    business_category: "",
    currency: "",
    phone: "",
    email: "",
    customerfile: "",
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateField,
    isFormValid,
  } = useCustomFormValidation(customerData, validationRules);
  const message = useSelector((state) => state.vendors);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const navigate = useNavigate();
  const { token } = useRequireAuth();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const {
    vendors,
    loading,
    error: errorData,
  } = useSelector((state) => state.vendors);

  const fetchData = async (vendorData) => {
    const url = `${EDIT_VENDOR_DATA_LIST}${vendorData}`;
    const response = await getCommonDataBasedOnId(url, token);

    if (response?.data && response?.data?.length > 0) {
      setCustomerData(response?.data[0]);
    }
  };

  useEffect(() => {
    fetchData(vendorData);
  }, [vendorData]);

  const parsedDatas = (value) => {
    if (typeof value === "string") {
      try {
        const response = JSON.parse(value);
        return response?.value;
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return null;
      }
    }
    return null;
  };

  const url = `${BASE_URL}${customerData?.vendor_profile_pic}`;

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (customerData?.vendor_profile_pic) {
      setSelectedFile(url);
    }
  }, [customerData?.vendor_profile_pic]);
  const fileInputRef = useRef(null);
  const [typeData, setTypeData] = useState({});
  const parsedtypeData = Array.isArray(parseJSONValue(typeData))
    ? parseJSONValue(typeData)
    : [];

  const [CustomerTableDrop, { data: customerCommnData }] =
    useCustomerTableMutation();
  const parsedData = Array.isArray(parseJSONValue(customerCommnData?.data))
    ? parseJSONValue(customerCommnData?.data)
    : [];
  const [isOpen, setIsOpen] = useState(false);
  const dropclickoutside = useClickOutside(() => {
    setIsOpen(null);
  });

  const toggleDropdown = (event) => {
    const { id } = event.currentTarget;
    const newIsOpen = isOpen === id ? null : id;
    setIsOpen(newIsOpen);
    if (newIsOpen) {
      const completedata = {
        model_name: "customers",
        field_name: id,
      };

      CustomerTableDrop({ token: token, data: completedata });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const completedata = {
        model_name: "customers",
        field_name: "type",
      };
      const result = await CustomerTableDrop({
        token: token,
        data: completedata,
      });
      setTypeData(result?.data?.data);
    };

    fetchData();
  }, []);

  const handleOptionChange = (event, item) => {
    const name = event.currentTarget.getAttribute("name");
    handleChange({
      target: {
        name,
        value: item?.value,
      },
    });

    setCustomerData({ ...customerData, [name]: item });

    setIsOpen(null);
  };

  const handleInputChange = (event) => {
    handleChange(event);
    const { name, value } = event.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
    }
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    const formValid = isFormValid();
    if (!formValid) {
      return;
    }
    const imgName = fileInputRef.current.files[0];
    const formDataWithFile = new FormData();
    formDataWithFile.append("company_id", parsedCompanyData.company_id);
    Object.entries(customerData).forEach(([key, value]) => {
      appendIfNotEmpty(formDataWithFile, key, value);
    });
    formDataWithFile.append("profile_pic", imgName);
    formDataWithFile.append("id", customerData?.id);
    try {
      const resultAction = await dispatch(
        saveVendor({ formDataWithFile, token })
      );
      const { payload } = resultAction;
      if (payload?.status) {
        setAlert({
          visible: true,
          message: payload?.message,
          type: "success",
        });
      }
    } catch (error) {
      console.error("Failed to save vendor:", error);
      setAlert({
        visible: true,
        message: "Failed to save vendor. Please try again.",
        type: "error",
      });
    }
  };

  const {
    data: companyCommnData,
    error,
    isLoading,
  } = useFetchCompanyCommonDataQuery(token);
  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999]">
      <div className="bg-black bg-opacity-50 absolute inset-0  "></div>
      <div className="bg-white p-8 rounded shadow-lg relative z-10 justify-items-center">
        <div className={`p-4`}>
          <FirstMessage
            type={alert.type}
            message={alert.message}
            visible={alert.visible}
            onHide={hideAlert}
          />

          <div className="flex justify-between my-2 mb-4">
            <div className="text-xl">New Vendor</div>
            <div className="group inline-block p-2 relative">
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                ></path>
              </svg>
              <div
                className="absolute inset-0 rounded-full border-2 border-transparent group-hover:border-gray-500 group-hover:cursor-pointer transition-all duration-300 ease-in-out"
                onClick={closeModal}
              ></div>
            </div>
          </div>

          {/* <div className={`rounded-md bg-white mt-4 p-4`}> */}
          <form onSubmit={handleButtonClick}>
            <div className={`flex gap-3 px-3 mb-3`}>
              <div className={`gap-3 grid grid-cols-2`}>
                {/* Rakesh */}
                <div>
                  <label
                    htmlFor={`vendor_name`}
                    className={`block mb-2 text-535252 text-md font-small`}
                  >
                    Name <span className="text-red-700">* </span>
                    {errors.vendor_name && (
                      <span className="text-red-700">{errors.vendor_name}</span>
                    )}
                  </label>
                  <input
                    type={`text`}
                    id={`vendor_name`}
                    name="vendor_name"
                    onChange={handleInputChange}
                    value={customerData?.vendor_name}
                    onBlur={handleBlur}
                    className={`w-360px  border-BFBFBF text-sm  focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                    placeholder={`Enter Name  . . . `}
                  />
                </div>
                <div>
                  <label
                    htmlFor={`vendor_display_name`}
                    className={`block mb-2 text-535252 text-md font-small`}
                  >
                    Cust. Display Name
                  </label>
                  <input
                    type={`text`}
                    id={`vendor_display_name`}
                    name="vendor_display_name"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    value={customerData?.vendor_display_name}
                    className={`w-[93%] border border-gray-300 text-gray-900 text-sm  focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                    placeholder={`Enter vendor Display Name  . . . `}
                  />
                </div>
                <div>
                  <label
                    htmlFor={`website`}
                    className={`block mb-2 text-535252 text-md font-small`}
                  >
                    Website
                    {errors && (
                      <span className="text-red-700"> {errors.website}</span>
                    )}
                  </label>
                  <input
                    type={`text`}
                    id={`website`}
                    name="website"
                    onChange={handleInputChange}
                    value={customerData?.website}
                    className={`w-360px border border-gray-300 text-gray-900 text-sm  focus:ring-1d4469 focus:border-1d4469 w-full px-2.5 py-1`}
                    placeholder={`Enter Website`}
                  />
                </div>

                <div>
                  <label
                    htmlFor={`type`}
                    className={`mb-1 text-1D4469 text-sm font-normal`}
                  >
                    Type
                    {errors && (
                      <span className="text-red-700"> {errors.type}</span>
                    )}
                  </label>
                  <div id={`type`} className={`py-1.5 flex gap-2`}>
                    {parsedtypeData &&
                      parsedtypeData?.map((item, index) => (
                        <button
                          type={`button`}
                          name="type"
                          value={item?.value}
                          className={`capitalize px-6 py-2 text-sm font-normal border hover:text-white hover:bg-neutral-600 border-stone-300 focus:outline-none option ${
                            customerData?.type === item?.value
                              ? "text-[#f6ebeb] bg-gray-800 active"
                              : "border"
                          }`}
                          data-option={item?.value}
                          onClick={(event) => handleInputChange(event)}
                        >
                          {item?.value}
                        </button>
                      ))}
                  </div>
                </div>
              </div>

              <div className="flex flex-1 justify-end">
                <div className="w-48 me-5">
                  <div className="border-dashed border-2 border-BFBFBF h-full">
                    <div
                      id="image-preview"
                      className="max-w-sm rounded-lg flex items-center justify-center text-center cursor-pointer h-full "
                    >
                      <input
                        id="upload"
                        name="customerfile"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                      />
                      <label htmlFor="upload" className="cursor-pointer">
                        {selectedFile && selectedFile?.length > 0 ? (
                          <Fragment>
                            <div className="relative">
                              <img
                                src={selectedFile}
                                alt="img preview"
                                className="object-cover"
                              />
                              {selectedFile && selectedFile !== "" ? (
                                <span className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center"></span>
                              ) : (
                                <span className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center">
                                  <span className="relative">
                                    <i className="bi bi-circle-fill text-1E1E1E text-4xl"></i>
                                    <i className="bi bi-upload text-2xl text-white absolute top-0 left-0 right-0 bottom-0" />
                                  </span>
                                </span>
                              )}
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <i className="bi bi-upload text-2xl" />
                            <p className="text-lg text-gray-500">Add Logo</p>
                          </Fragment>
                        )}

                        <span
                          id="filename"
                          className="text-gray-500 bg-gray-200 z-50"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`gap-12 flex px-3 mb-3`}>
              <div className="w-[363px]">
                <label
                  htmlFor={`business_category`}
                  className={`block mb-2 text-535252 text-md font-small`}
                >
                  Business Category
                  {errors && (
                    <span className="text-red-700">
                      {" "}
                      {errors.business_category}
                    </span>
                  )}
                </label>

                <div className="relative">
                  <button
                    id="business_category"
                    onClick={(event) => {
                      toggleDropdown(event);
                    }}
                    className={`w-full h-[33px] tracking-wide bg-white text-535252 border border-BFBFBF  px-2 leading-tight focus:border-gray-500 focus:ring-1 focus:outline-none focus:ring-grey-100 py-1 text-center inline-flex items-center justify-between`}
                    type="button"
                    style={{
                      borderRadius: "1px",
                      background: "#FFF",
                    }}
                  >
                    <span
                      className={`tracking-wide py-1 px-3 text-xs rounded-full text-black`}
                      style={{
                        backgroundColor:
                          customerData?.business_category?.color_code,
                      }}
                    >
                      {customerData?.business_category?.value ||
                        "Select an option"}
                    </span>

                    <span>
                      <i
                        className={`bi bi-chevron-${
                          isOpen === "business_category" ? "up" : "down"
                        }`}
                      ></i>
                    </span>
                  </button>

                  {isOpen === "business_category" && (
                    <div
                      ref={dropclickoutside}
                      id="dropdownRadioBgHover"
                      className={`w-full z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute `}
                    >
                      <ul
                        className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 h-[210px] overflow-auto"
                        aria-labelledby="status"
                        style={{
                          borderRadius: "1px",
                          border: "0.4px solid #535252",
                          background: "#FFF",
                          boxShadow: "0px 0px 4px 0px #535252",
                        }}
                      >
                        {parsedData &&
                          parsedData?.map((item, index) => (
                            <li
                              key={index}
                              className="border-b border-DADADA"
                              name="business_category"
                              onClick={(event) => {
                                handleOptionChange(event, item);
                              }}
                            >
                              <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                {item?.color_code && (
                                  <input
                                    id={`status-radio-${index}`}
                                    type="radio"
                                    value={item && item?.value}
                                    name="business_category"
                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-grey-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                    checked={
                                      customerData?.business_category?.value ===
                                      item?.value
                                    }
                                    onChange={(event) => {
                                      handleOptionChange(event, item);
                                    }}
                                    style={{
                                      backgroundColor: item?.color_code,
                                      width: "25px",
                                      height: "25px",
                                      borderRadius: "5px",
                                      backgroundSize: "3rem",
                                    }}
                                  />
                                )}

                                <label
                                  htmlFor={`status-radio-${index}`}
                                  className="w-full ml-2 text-sm tracking-wide text-383838 rounded dark:text-gray-300"
                                >
                                  {item && item?.value}
                                </label>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-[363px]">
                <label
                  htmlFor={`business_category`}
                  className={`block mb-2 text-535252 text-md font-small`}
                >
                  Currency
                  {errors && (
                    <span className="text-red-700">
                      {" "}
                      {errors.business_category}
                    </span>
                  )}
                </label>
                <select
                  className="w-full py-0.5"
                  id={`currency`}
                  name={`currency`}
                  onChange={handleInputChange}
                >
                  <option>Please Currency</option>
                  {companyCommnData?.data?.currency &&
                    companyCommnData?.data?.currency.map((CurrencyData) => (
                      <option
                        key={CurrencyData.id}
                        value={CurrencyData.currency_name_symbol}
                        selected={
                          CurrencyData.currency_name_symbol ===
                          customerData?.currency
                        }
                      >
                        {CurrencyData.currency_name_symbol}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className={`flex justify-end pt-3`}>
              <div></div>
              <button
                onClick={closeModal}
                type={`button`}
                className={`text-535252 bg-F4F4F4 border border-EAE2E2 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2 me-5 mb-2 focus:outline-none`}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className={`py-2 px-5 me-2 mb-2 text-sm font-medium text-white bg-1D4469 focus:outline-none  focus:z-10 focus:ring-4 focus:ring-gray-100 
                     `}
              >
                SAVE
              </button>
            </div>
          </form>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default EditVendor;
