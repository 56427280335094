import React from 'react'

const NotesIcon = () => {
  return (
    <>
      <i class="bi bi-journal-plus"></i>
    </>
  );
};

export default NotesIcon