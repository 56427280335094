import React from "react";
import Header from "./Header";
import Leftmenu from "./Leftmenu";
import Footer from "./Footer";
import { SidebarProvider } from './SidebarContext';

const LayoutHOC = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <div>
          <Header />
          <div>
            <SidebarProvider>
                <Leftmenu />
                <main id="maincontent">
                       <WrappedComponent {...this.props} />
                </main>
            </SidebarProvider>
          </div>           
        </div>
      );
    }
  };
};

export default LayoutHOC;
