import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, COMPANY_PROFILE_BASIS_OF_USER_LOGIN,COMMON_URL } from '../constants';

export const companyListApi = createApi({
  reducerPath: 'companyListApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCompanyList: builder.query({
      query: (token) => ({
        url: COMPANY_PROFILE_BASIS_OF_USER_LOGIN,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const companyCommonDataApi = createApi({
  reducerPath: 'companyCommonDataApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCompanyCommonData: builder.query({ 
      query: (token) => ({
        url: COMMON_URL,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useFetchCompanyListQuery,
  useLazyFetchCompanyListQuery,
} = companyListApi;

export const {
  useFetchCompanyCommonDataQuery,
  useLazyFetchCompanyCommonDataQuery,
} = companyCommonDataApi;
