import React, { useRef, Fragment, useEffect, useState } from "react";
import SubmitIcon from "../Icons/SubmitIcon";
import {saveActivity} from "../../utils/customer/customerTaskEventSlice";
import { useDispatch,useSelector } from "react-redux";
import useRequireAuth from "../../utils/useRequireAuth";
import { CUSTOMER_ACTIVITY_GET_EVENT } from "../../utils/constants";


import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import InputWithSubmit from "../GlobalFeature/Fields/InputWithSubmit";
const formatchangeDateformate = (dateString) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const parts = dateString.split("-");
  const day = parseInt(parts[0], 10);
  const monthIndex = parseInt(parts[1], 10) - 1;
  const year = parts[2].split(" ");
  const formattedDate = `${day} ${monthNames[monthIndex]} ${year[0].slice(
    -2
  )}  ${year[1]}`;
  return formattedDate;
};

const CustomerTaskHoverActivity = ({ onFocus,taskid, taskCategory ,activity,onBlur,closeActivityModal}) => {
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const handleInputChange = (event) => {
    const { value } = event.target;
  };
  const [taskEvent,setTaskEvent]=useState([]);
  const activitys = useSelector(state => state.activity);
  const scrollRef = useRef(null);
  const dispatch=useDispatch();
  const { token } = useRequireAuth();
  const url = `${CUSTOMER_ACTIVITY_GET_EVENT}?task_id=${taskid}`;
 useEffect(() => {
  const fetchData = async () => {
    try {

      if (activitys?.activity?.length === 0 || activitys?.activity?.data === '') {
        const data = await getCommonDataBasedOnId(url, token);
        setTaskEvent(data?.data);
      } else {
        setTaskEvent(activitys?.activity?.data);
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
    }
  };

  fetchData();
}, [url, token, taskid, activitys?.activity?.data]);

  const handleSaveTaskEvent = (event) => {
    event.preventDefault();
    const taskEvent = {
      description: event.target.description.value,
      task_id: taskid,
    };
    
    dispatch(saveActivity({ taskEvent, token }));
    event.target.description.value = '';
  };
  const[isActivityModalOpen, setActivityModalOpen] = useState(true);


  const closeActivityModals = () => {
    setActivityModalOpen(false)
    closeActivityModal();
  }
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Scroll to the bottom of the container when data updates
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [taskEvent]);
  return (
    <>
    {isActivityModalOpen && (
      <div className="bg-white shadow-9 rounded-10px w-full px-4 py-3">
        <div className="flex justify-between">
          <div className="flex gap-x-2.5">
            <p className="flex items-center text-md text-2C495D">Activity</p>
            <button
              type="button"
              onClick={openModal}
            >
                <h5 className="text-545F65"><i className="bi bi-arrows-fullscreen"></i></h5>
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={closeActivityModals}
            >
                <h3 className="text-545F65"><i className="bi bi-x"></i></h3>
            </button>
          </div>
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-opacity-50">
                <div className="">
                  <div className="relative p-3 mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md overflow-hidden no-scrollbar" style={{width: '600px'}}>
                        <div className="flex items-center justify-between rounded-t">
                            <p className='text-md text-2C495D'>Activity</p>
                            <button
                                  type="button"
                                  onClick={closeModal}
                            ><h3 className="text-545F65"><i className="bi bi-x"></i></h3>
                            </button>
                        </div>
                        <div ref={scrollRef} className="flex flex-col max-h-[400px] overflow-y-scroll scrollbar-hidden">
                          <p className="mb-3">{taskCategory}</p>
                          {!taskEvent
                            ? null
                            : taskEvent.map((CategoryData) => (
                                <div className="px-2 py-2 bg-F1F6F9 rounded-5px mb-3">
                                  <div className="px-1 flex items-center gap-x-2">
                                    <div>
                                      <img className="w-8 h-8 me-2 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="user photo" />
                                    </div>
                                    <div className="flex flex-col">
                                      <p className="text-2999BC font-medium text-xs">
                                        {CategoryData.created_by}
                                      </p>
                                      <p className="text-F16136 font-medium text-xxs">
                                        {formatchangeDateformate(CategoryData.created_at)}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="text-xs text-justify text-535252 m-1">
                                    {CategoryData.description}
                                  </p>
                                </div>
                              ))}
                        </div>
                        <InputWithSubmit handleSaveEvent={handleSaveTaskEvent}  />

                  </div>
                </div>
              </div>
            )}
        </div>
        <div ref={scrollRef} className="flex flex-col max-h-[400px] overflow-y-scroll scrollbar-hidden">
          <p className="mb-3">{taskCategory}</p>
          {!taskEvent
            ? null
            : taskEvent.map((CategoryData) => (
                <div className="px-2 py-2 bg-F1F6F9 rounded-5px mb-3">
                  <div className="px-1 flex items-center gap-x-2">
                    <div>
                      <img className="w-8 h-8 me-2 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="user photo" /> 
                    </div>
                    <div className="flex flex-col">
                      <p className="text-2999BC font-medium text-xs">
                        {CategoryData.created_by}
                      </p>
                      <p className="text-F16136 font-medium text-xxs">
                        {formatchangeDateformate(CategoryData.created_at)}
                      </p>
                    </div>
                  </div>
                  <p className="text-xs text-justify text-535252 m-1">
                    {CategoryData.description}
                  </p>
                </div>
              ))}       
        </div>
        <InputWithSubmit handleSaveEvent={handleSaveTaskEvent}  />


      </div>
    )}
    </>
  );
};
export default CustomerTaskHoverActivity;