import { useCallback } from 'react';

const useInitialLetters = () => {
  const getInitials = useCallback((name) => {
    const words = name.trim().split(' ');
    if (words.length === 1) {
      return words[0].charAt(0).toUpperCase(); // Single name
    } else if (words.length >= 2) {
      return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase(); // First two letters of two words
    }
  }, []);

  return { getInitials };
};

export default useInitialLetters;
