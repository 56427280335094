// import React, { useState, useEffect, Fragment } from "react";
// import useDropdownPosition from "../../../hooks/Dropdown/useDropDownPosition";
// import SaveEditFirst from "../SaveEdit/SaveEditFirst";
// import { parseJSONValue } from "../../../utils/json/parseJSONValue";
// import useClickOutside from "../../../hooks/Screen/useClickOutside";


// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';


// const SearchDropDown = ({
//   tableidclass,
//   setDataInputRow,
//   dataInputRow,
//   SpecificElement,
//   DropData,
//   UpdateTableField,
//   FieldValue,
//   Item,
// }) => {
//   const parsedDropData = Array.isArray(parseJSONValue(DropData))
//     ? parseJSONValue(DropData)
//     : [];

//   const [filter, setFilter] = useState("");
//   const [show, setShow] = useState(true);
//   const [selected, setSelected] = useState({
//     value: FieldValue.value,
//     id: FieldValue.id,
//   });
//   const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
//   const [hoveredItemPosition, setHoveredItemPosition] = useState({
//     x: 0,
//     y: 0,
//   });

//   const { dropdownPosition, dropdownRef } = useDropdownPosition(
//     show,
//     tableidclass
//   );

//   const close = () => {
//     setShow(false);
//     setFocusedOptionIndex(null); // Reset focused index when closing
//   };

//   const open = () => {
//     setShow(true);
//     setFilter(""); // Clear the filter when opening
//   };

//   const searchDropToggle = (e) => {
//     e.stopPropagation();
//     if (show) {
//       close();
//     } else {
//       open();
//     }
//   };

//   const isOpen = () => show === true;

//   const filteredOptions = () => {
//     return (
//       parsedDropData &&
//       parsedDropData?.filter((option) => {
//         return (
//           option.value?.toLowerCase().includes(filter?.toLowerCase()) ||
//           option.email?.toLowerCase().includes(filter?.toLowerCase())
//         );
//       })
//     );
//   };

//   const onOptionClick = (index) => {
//     setFocusedOptionIndex(index);
//     selectOption(index);
//   };

//   const selectOption = (index) => {
//     if (!isOpen()) {
//       return;
//     }
//     const focusedOption = filteredOptions()[index];
//     if (focusedOption) {
//       setSelected(focusedOption);
//       setFilter(`${focusedOption.value} ${focusedOption.email}`);
//     }
//     close();
//   };

//   const focusPrevOption = () => {
//     if (!isOpen()) {
//       return;
//     }
//     const optionsNum = filteredOptions().length - 1;
//     if (focusedOptionIndex > 0 && focusedOptionIndex <= optionsNum) {
//       setFocusedOptionIndex((prevIndex) => prevIndex - 1);
//     } else if (focusedOptionIndex === 0) {
//       setFocusedOptionIndex(optionsNum);
//     }
//   };

//   const focusNextOption = () => {
//     const optionsNum = filteredOptions().length - 1;
//     if (!isOpen()) {
//       open();
//     }
//     if (focusedOptionIndex === null || focusedOptionIndex === optionsNum) {
//       setFocusedOptionIndex(0);
//     } else if (focusedOptionIndex >= 0 && focusedOptionIndex < optionsNum) {
//       setFocusedOptionIndex((prevIndex) => prevIndex + 1);
//     }
//   };

//   const handleKeyDown = (e) => {
//     switch (e.key) {
//       case "Enter":
//         selectOption(focusedOptionIndex);
//         break;
//       case "ArrowUp":
//         focusPrevOption();
//         break;
//       case "ArrowDown":
//         focusNextOption();
//         break;
//       default:
//         break;
//     }
//   };

//   const handleCloseClick = (e) => {
//     e.stopPropagation();
//     setDataInputRow(null);
//     setShow(false);
//   };


//   const closeDropdownOutsideClick = useClickOutside(() => {
//     setShow(false);
//     setDataInputRow(null);
//   });
//   return (
//     <Fragment>
//       <div ref={closeDropdownOutsideClick}>

//       <div className="flex flex-col items-center" ref={dropdownRef}>
//         <div className=" flex flex-col items-center h-[33px]">
//           <div className="flex flex-col items-center relative">
//             <div
//               onClick={() => setShow(false)}
//               className="bg-white  flex border border-gray-200 rounded"
//             >
//               <input
//                 value={selected ? `${selected?.value || ""}` : filter} // Show selected value or filter
//                 onChange={(e) => {
//                   setFilter(e.target.value);
//                   setSelected(null);
//                 }}
//                 className="p-1 w-full px-2 appearance-none outline-none  text-gray-800"
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   setShow(true);
//                 }}
//                 onKeyDown={handleKeyDown}
//               />
//               <div className="text-gray-300 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
//                 <button
//                   type="button"
//                   onClick={searchDropToggle}
//                   className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none"
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="100%"
//                     height="100%"
//                     fill="none"
//                     viewBox="0 0 24 24"
//                     stroke="currentColor"
//                     strokeWidth="2"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                   >
//                     <polyline
//                       style={{ display: !isOpen() ? "block" : "none" }}
//                       points="18 15 12 20 6 15"
//                     ></polyline>
//                     <polyline
//                       style={{ display: isOpen() ? "block" : "none" }}
//                       points="18 15 12 9 6 15"
//                     ></polyline>
//                   </svg>
//                 </button>
//               </div>
//             </div>
//             {isOpen() && (
//               <div
//                 className={`absolute shadow bg-white z-40 rounded max-h-[225px] overflow-y-auto w-full ${
//                   dropdownPosition === "bottom"
//                     ? "top-full mt-2"
//                     : "bottom-full mb-2"
//                 }`}
//               >
//                 <div className="flex flex-col w-full">
//                   {filteredOptions()?.map((option, index) => (
//                     <div
//                       key={index}
//                       onClick={() => onOptionClick(index)}
//                       onMouseEnter={(e) => {
//                         setFocusedOptionIndex(index);
//                         setHoveredItemPosition({ x: e.clientX, y: e.clientY });
//                       }}
//                       onMouseLeave={() => setFocusedOptionIndex(null)}
//                       className={`flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100 ${
//                         focusedOptionIndex === index ? "bg-gray-100" : ""
//                       }`}
//                       aria-selected={focusedOptionIndex === index}
//                     >
//                       <div className="w-full items-center flex">
//                         <div className="mx-2 -mt-1">
//                           <span className="text-2999BC">{option.value}</span>
//                           <div className="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500">
//                             {option.email}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//       {dataInputRow?.rowIndex === SpecificElement && (
//         <SaveEditFirst
//           UpdateTableField={(e) => {
//             UpdateTableField(e, selected, Item);
//           }}
//           handleCloseClick={handleCloseClick}
//         />
//       )}

//       </div>

//     </Fragment>
//   );
// };

// export default SearchDropDown;












// import React, { useState, Fragment } from "react";
// import { Autocomplete, TextField, ClickAwayListener } from "@mui/material";
// import useDropdownPosition from "../../../hooks/Dropdown/useDropDownPosition";
// import SaveEditFirst from "../SaveEdit/SaveEditFirst";
// import { parseJSONValue } from "../../../utils/json/parseJSONValue";

// const SearchDropDown = ({
//   tableidclass,
//   setDataInputRow,
//   dataInputRow,
//   SpecificElement,
//   DropData,
//   UpdateTableField,
//   FieldValue,
//   Item,
// }) => {
//   const parsedDropData = Array.isArray(parseJSONValue(DropData))
//     ? parseJSONValue(DropData)
//     : [];

//   const [filter, setFilter] = useState("");
//   const [show, setShow] = useState(false);
//   const [selected, setSelected] = useState(FieldValue?.value);

//   const { dropdownPosition, dropdownRef } = useDropdownPosition(
//     show,
//     tableidclass
//   );

//   const handleDropdownToggle = () => {
//     setShow((prevShow) => !prevShow);
//   };

//   const handleOptionSelect = (event, newValue) => {
//     if (newValue) {
//       setSelected(newValue?.value);
//       setFilter(`${newValue?.value || ""}`);
//     } else {
//       setSelected("");
//       setFilter("");
//     }
//     setShow(false);
//   };

//   const handleInputChange = (event, value) => {
//     setFilter(value);
//     if (!value) {
//       setSelected("");
//     }
//   };

//   const handleCloseClick = (e) => {
//     e.stopPropagation();
//     setDataInputRow(null);
//     setShow(false);
//   };

//   return (
//     <ClickAwayListener onClickAway={() =>  {setShow(false); setDataInputRow(null);} }>
//       <div ref={dropdownRef}>
//         <Autocomplete
//           open={show}
//           onOpen={() => setShow(true)}
//           onClose={() => setShow(false)}
//           value={filter ? selected : null} // Use null when filter is empty
//           onChange={handleOptionSelect}
//           inputValue={filter}
//           onInputChange={handleInputChange}
//           options={parsedDropData}
//           getOptionLabel={(option) => `${option?.value || ""}`}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               label="Search"
//               variant="outlined"
//               onClick={handleDropdownToggle}
//             />
//           )}
//           renderOption={(props, option) => (
//             <li {...props}>
//               <div className="flex flex-col">
//                 <span className="text-2999BC">{option.value}</span>
              
//               </div>
//             </li>
//           )}
//         />
//         {dataInputRow?.rowIndex === SpecificElement && (
//           <SaveEditFirst
//             UpdateTableField={(e) => {
//               UpdateTableField(e, selected, Item);
//             }}
//             handleCloseClick={handleCloseClick}
//           />
//         )}
//       </div>
//     </ClickAwayListener>
//   );
// };

// export default SearchDropDown;












import React, { useState } from "react";
import { Autocomplete, TextField, ClickAwayListener } from "@mui/material";
import useDropdownPosition from "../../../hooks/Dropdown/useDropDownPosition";
import SaveEditFirst from "../SaveEdit/SaveEditFirst";
import { parseJSONValue } from "../../../utils/json/parseJSONValue";
import {  Popper} from '@mui/material';

const SearchDropDown = ({
  tableidclass,
  setDataInputRow,
  dataInputRow,
  SpecificElement,
  DropData,
  UpdateTableField,
  FieldValue,
  Item,
}) => {
  const parsedDropData = Array.isArray(parseJSONValue(DropData))
    ? parseJSONValue(DropData)
    : [];

  const [filter, setFilter] = useState(FieldValue?.value || "");
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(
    parsedDropData.find((option) => option.value === FieldValue?.value) || null
  );

  const { dropdownPosition, dropdownRef } = useDropdownPosition(
    show,
    tableidclass
  );

  const handleDropdownToggle = () => {
    setShow((prevShow) => !prevShow);
  };

  const handleOptionSelect = (event, newValue) => {
    if (newValue) {
      setSelected(newValue);
      setFilter(newValue.value);
    } else {
      setSelected(null);
      setFilter("");
    }
    setShow(false);
  };

  const handleInputChange = (event, value) => {
    setFilter(value);
    if (!value) {
      setSelected(null);
    }
  };

  const handleCloseClick = (e) => {
    e.stopPropagation();
    setDataInputRow(null);
    setShow(false);
  };
  const CustomPopper = (props) => {
    return <Popper {...props} sx={{ '& .MuiAutocomplete-listbox': { fontSize: '11px' } }} />;
  };

  return (
    <ClickAwayListener onClickAway={() => { setShow(false); setDataInputRow(null); }}>
      <div ref={dropdownRef}>
      <Autocomplete
        open={show}
      
        onOpen={() => setShow(true)}
        onClose={() => setShow(false)}
        value={selected}
        onChange={handleOptionSelect}
        inputValue={filter}
        onInputChange={handleInputChange}
        options={parsedDropData}
        getOptionLabel={(option) => `${option?.value || ""}`}
        PopperComponent={CustomPopper}
        renderInput={(params) => (
          <TextField
            {...params}
            onClick={handleDropdownToggle}
            sx={{
              width: 110,
               // Adjust width
              '& .MuiOutlinedInput-root': {
                '& input': {
                  boxShadow: 'none', // Remove any inside shadow
                  border: 'none', // Remove any inner borders
                  outline: 'none', // Remove focus outline
                },
              },
              '& .MuiInputBase-root': {
                fontSize: '11px', // Adjust font size
                padding: '8px',
                height: 30, // Adjust height
              },
            }}
          />
        )}
      />


  {dataInputRow?.rowIndex === SpecificElement && (
    <SaveEditFirst
      UpdateTableField={(e) => {
        UpdateTableField(e, selected, Item);
      }}
      handleCloseClick={handleCloseClick}
    />
  )}
      </div>
    </ClickAwayListener>
  );
};

export default SearchDropDown;
