import { useState, useCallback } from 'react';
import useRequireAuth from "../../utils/useRequireAuth";

const useDataInputHandler = (fetchtableBodyData,setDataInputRow,setClientCoord) => {
    const user = useRequireAuth();

  const handleDataInput = useCallback(async (rowIndex, item, columnKey,event) => {
    setDataInputRow({ rowIndex, columnKey });
    let rect={};
    if(event && event.target){
      const clickedElement = event.target.closest('td');
      rect = clickedElement.getBoundingClientRect();
      // console.log(clickedElement,"clickedElement")
      // console.log(rect,"rect")
      // console.log(rect.left,"rect.left")
      // console.log({top:rect.left,left:rect.bottom})
      // console.log("passed last")
    }
    if(setClientCoord)
    setClientCoord({top:Math.ceil(rect.top+rect.height),left:rect.left,element:rect})
    if (item[columnKey]?.type !== 'person') {
      try {
        await fetchtableBodyData(item[columnKey]?.field_name); 
        
      } catch (error) {
        console.error('Error fetching customer data:', error);
      }
    }
  }, [user.token]);

  return {
    handleDataInput,
  };
};

export default useDataInputHandler;
