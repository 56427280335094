import React, { useState,Fragment } from 'react'
import ExtraFieldAdd from './ExtraFieldAdd';

const Setting = ({setExtraField,extraField,children,setMenuVisible,menuVisible,handleMenuToggle,ApplyHeader,thead,ExtraFieldAddHeader,EditFieldData}) => {


  return (
    <Fragment>

                <label htmlFor="open-menu" tabIndex="0" aria-haspopup="true" role="button" aria-controls="menu" className="tooltip cursor-pointer" id="openmenu">
                    
                    <i className={` bi bi-list tableheadermenuicon tableheaderfiltericon  hover:text-254155 group-focus-within:text-white ${menuVisible ? 'text-254155 border border-253E50 px-1 rounded' : 'text-2541558A'}  `}></i>
                    <span className={`tooltiptext text-[10px] w-max bottom-full left-1/2 ml-[-50px] `}>Manage Field</span>
                    
                </label>
                <input type="checkbox" data-menu id="open-menu" className="peer hidden" checked={menuVisible} onChange={handleMenuToggle} />
   

                <aside className={`fixed z-[9999] w-full h-screen transition-transform duration-500 flex inset-y-0 ${menuVisible ? '' : 'translate-x-full'} peer-checked:transform-none peer-checked:opacity-100 right-0`} role="menu" id="menu" aria-labelledby="openmenu">
                      
                      
                      <label htmlFor="open-menu" className={`flex  ${extraField ? 'w-[67%]' : 'w-full' }`}>

                      </label>


                            {extraField &&
      
                            <Fragment>
                      <div className='flex flex-col w-[500px]'>
                            
                                <label htmlFor="open-menu" className='h-[13%]'>
                            
                                </label>
                                  <div className='pr-4'>
                                      <ExtraFieldAdd setExtraField={setExtraField} ExtraFieldAddHeader={ExtraFieldAddHeader} EditFieldData={EditFieldData}/>
                            
                                  </div>
                            
                            
                      </div>
                               
                            
                                {/* <div className='flex justify-end flex-col w-[500px] pr-4 mb-6'>
      
                                </div> */}
                            </Fragment>
                              
                            }



                  <nav className=" translate-x-[0%] text-1E1E1E font-poppins text-16 font-medium transition-all duration-500   box-border flex flex-col w-[500px] h-full peer-checked:transform-none peer-checked:opacity-100">
                    

                     <label htmlFor="open-menu" className='h-[18%]'>
                      
                     </label>

                    {/* <div className='relative top-[20%] lg:top-[32%] xl:top-[32%] 2xl:top-[19%] bg-white' id='customShadow'> */}
                    
                    
                    <div className='bg-white' id='customShadow'>

                    
                    
                    <div className='flex justify-between px-4 pt-2'>
                        <h2 className="text-1D4469  text-[20px]  font-poppins font-bold uppercase">MANAGE FIELD</h2>
                 
                          <div>
                                    <button className='border-1 py-1 px-4 rounded font-poppins text-14 font-600  bg-1D4469 text-white' onClick={ApplyHeader}>APPLY</button>
                                    <button onClick={handleMenuToggle} className='ms-2'> <i className="bi bi-x-circle-fill"></i> </button>
                          </div>
                 

                    </div>
                 

                           {children}
                          {/* <CustomerAccordionItem title="Item 2" handleClick={() => SettingSideDrop(1)} isOpen={settingFilter === 1}>
                            Content for item 2
                          </CustomerAccordionItem>
                          <CustomerAccordionItem title="Item 3" handleClick={() => SettingSideDrop(2)} isOpen={settingFilter === 2}>
                            Content for item 3
                          </CustomerAccordionItem> */}

                          
                    </div>

                  </nav>

                </aside>
                    <div className={`absolute top-0 bg-drawerActiveBg h-full pointer-events-none ${menuVisible ? 'block animate-fadeIn' : 'hidden'}`}></div>

    </Fragment>
   
  )
}

export default Setting