import { useState, useEffect } from 'react';

const useRandomWordGenerator = (initialLength = 6, intervalTime = 5000) => {
  const [randomWord, setRandomWord] = useState('');

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$#&@!';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRandomWord(generateRandomString(initialLength));
    }, intervalTime);

    // Set initial random word
    setRandomWord(generateRandomString(initialLength));

    // Clean up interval
    return () => clearInterval(interval);
  }, [initialLength, intervalTime]);

  return randomWord;
};

export default useRandomWordGenerator;
