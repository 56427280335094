import React, { Fragment,useState } from 'react'
import { useNavigate } from "react-router-dom";
import CustomerListTable from "./CustomerListTable";
import AddTeamTableHead from "./AddTeamTableHead";
import AddTeamTableBody from "./AddTeamTableBody";

import useRequireAuth from "../../utils/useRequireAuth";
import {useParams} from "react-router-dom"
import { useFetchCustomerTeamsDataQuery } from '../../utils/customer/customerSelect';


const AddTeam = () => {
    const navigate=useNavigate();
    const handleNavigate = (customer_id=null) => {
      // console.log("subashtest",customer_id);return false;
       navigate(`/customer`);
      // history.push('/another'); // Navigate to AnotherPage
    };
    const {token}=useRequireAuth();
    const {id:customerId}=useParams();
    const {
      data: customerTeamsData,
      error: customerTeamsError,
      isLoading: customerTeamsIsLoading
    } = useFetchCustomerTeamsDataQuery({ token, customerId });
    if (customerTeamsIsLoading) {
      return <div>Loading...</div>;
    }
    if (customerTeamsError) {
      return <div>Error: {customerTeamsError.message}</div>;
    }
  return (
    <Fragment>

            <div className="bg-white border border-1d446933 px-4 mb-4 max-h-[600px] overflow-auto">
            <h3 className="text-2C495D font-semibold text-xl">Teams</h3>
            <CustomerListTable
              tableDetail={
                {
                    tableidclass:"fullscreen-table",
                    tableSearch:true,
                    tableFilter:true,
                    tableResizer:true,
                    tableZoom:true,
                    tableheader:true
                }
              }
              thead={AddTeamTableHead}
              // customComponent={<AddTaskModal />}

              // handlePostThead={handlePostData}
              tbody={AddTeamTableBody}
              // fetchBodyData={fetchData}
              // fetchtableBodyData={fetchCustomerCommn}
              // customerCommnData={customerCommnData}
              // initialFilter={initialFilter}
              handleNavigate={handleNavigate}
              navigateNameCondition={["task_owner"]}
            />

                {/* <div className='py-4'>
                <h3 className="text-2C495D font-semibold text-xl">Team</h3>

                </div>
                <div className="w-full border border-slate-200 rounded-xl overflow-x-auto mb-2">
                    <table className="w-full divide-y divide-slate-200" id="rightTeamTable">
                        <thead className="bg-slate-100 text-slate-800">
                        <tr classNameName='font-14 text-14 '>
                            <th className="px-4 py-2 text-start">Leader Name</th>
                            <th className="px-4 py-2 text-start">Email</th>
                            <th className="px-4 py-2 text-start">Name</th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-slate-200 bg-white text-slate-800">
                        {!customerTeamsData?.data ? null : (
                           customerTeamsData?.data.map((teams) => (
                                <tr className="hover:bg-slate-100 cursor-pointer odd:bg-white even:bg-slate-50">
                                    <td className="px-4 py-2">{teams.reporting_person_id}</td>
                                    <td className="px-4 py-2">{teams.reporting_person_email}</td>
                                    <td className="px-4 py-2">{teams.reporting_person_name}</td>
                                </tr>
                          )))}
                        </tbody>
                    </table>
                </div> */}

                {/* <div className='mb-2'>
                    <select className='outline-0'>
                        <option value={'10'} selected>10</option>
                        <option value={'20'}>20</option>
                        <option value={'30'}>30</option>
                        <option value={'40'}>40</option>

                    </select>
                </div> */}
            </div>
    </Fragment>
  )
}

export default AddTeam