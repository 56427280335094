export const UserActiveFilters = [
    { label: "Active Users", value: 1 },
    { label: "Inactive Users", value: 0 }, 
]

export const UserTypeFilters = [
    { label: "Internal Users", value: 0 },
    { label: "External User", value: 1 },
    
]

