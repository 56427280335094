import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveSocialLinkDataAsync } from "../../utils/customer/socialLinkSlice";
import useRequireAuth from "../../utils/useRequireAuth";
import { useFetchSocialCategoryQuery } from "../../utils/customer/customerSelect";
import useClickOutside from "../../hooks/Screen/useClickOutside";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";

const validationRules = {
  link_type: {
    required: true,
    errorMessage: "Link Type is required.",
  },
  link: {
    required: true,
    errorMessage: "Social Link is required.",
  },
};

const CustomerSocialModel = ({ isOpen, onClose, handleSubmitData }) => {
  const closeoutsideclick = useClickOutside(() => {
    onClose();
  });

  const { id } = useParams();
  const customerId = parseInt(id);
  const dispatch = useDispatch();
  const { token } = useRequireAuth();
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  
  const [formErrors, setFormErrors] = useState({
    link_type: "",
    link: "",
  });
  const [formData, setFormData] = useState({
    link_type: "",
    link: "",
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateField,
    isFormValid,
  } = useCustomFormValidation(formData, validationRules);

  const handleFieldChange = (e) => {
    handleChange(e);

    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  

  const handleSave = async (event) => {
    event.preventDefault();
    const formValid = isFormValid();

    if (!formValid) {
      return;
    }

    const socialData = {
      customer_id: customerId,
      social_link_id: event.target.link_type.value,
      social_link: event.target.link.value,
      company_id: parsedCompanyData.company_id,
    };

    try {
      await dispatch(saveSocialLinkDataAsync({ socialData, token }));
      await handleSubmitData(customerId);
      setAlert({
        visible: true,
        message: "Social link added successfully.",
        type: "success",
      });
      onClose();
    } catch (error) {
      setAlert({
        visible: true,
        message: "There is some problem.",
        type: "danger",
      });
    }
  };

  const { data: customerSocialCategory, refetch: refetchCustomerData } =
    useFetchSocialCategoryQuery({ token });
    
    const hideAlert = () => {
      setAlert((prevState)=>({ ...prevState,visible: false }));
    };

  return (
    <div
      className="relative  py-3 mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md w-500px overflow-hidden no-scrollbar"
      ref={closeoutsideclick}
    >
      <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />
        <div className="px-2"></div>
        <br />
        <h3 className="text-20 text-434852 font-500">Add Link</h3>

        <button
          onClick={onClose}
          type="button"
          className="text-454545 bg-F1F5F8 hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
          data-modal-hide="assets-modal"
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
      </div>

      <form
        onSubmit={handleSave}
        className="w-full lg:max-h-[70vh]  overflow-x-hidden  no-scrollbar"
      >
        <div className="basis-5/5 mb-3 px-6 pt-6 ">
          <label htmlFor="link_type" className="block mb-2 text-sm text-434852">
            Link Type <span className=" text-red-500"> *</span>
            {errors && (
              <span className="text-red-700"> {errors.link_type}</span>
            )}
          </label>
          <select
            id={`link_type`}
            name="link_type"
            onChange={handleFieldChange}
            onBlur={handleBlur}
            className={`form-select border py-0 border-BFBFBF text-383838 text-md  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5`}
          >
            <option select hidden>
              Select Link Type
            </option>
            {!customerSocialCategory
              ? null
              : customerSocialCategory.map((CategoryData) => (
                  <option value={CategoryData.name}>
                    {" "}
                    {CategoryData.name}{" "}
                  </option>
                ))}
          </select>
        </div>

        <div className="p-3 md:px-6">
          <label htmlFor="link" className="block mb-2 text-sm text-434852">
            Social Links <span className=" text-red-500"> *</span>
            {errors && <span className="text-red-700"> {errors.link}</span>}
          </label>
          <input
            placeholder="Enter Name"
            type="text"
            id="link"
            name="link"
            onChange={handleFieldChange}
            className=" border border-gray-300 text-gray-900 text-sm  block w-full p-2.5 rounded-5px"
          />
          {formErrors.link && (
            <span className="text-red-500 text-xs">Cannot be left blank.</span>
          )}
        </div>

        <div className="flex justify-end pt-6 py-4 text-md w-full">
          <div className="px-6">
            <button
              onClick={onClose}
              type="button"
              className="px-6 py-1 text-16  rounded font-500 bg-F4F4F4 focus:outline-none me-2"
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="px-6 py-1 text-16 rounded font-500 bg-1D4469 text-white focus:outline-none cbtn-transition-bg cbtn-transition-bg"
            >
              SAVE
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CustomerSocialModel;
