const ManageListHeader = [
    {
      "list_name": "List Name",
      "list_name_list_status": 1
    },
    
    {
      "description": "Description",
      "description_status": 1
    },

]


export default ManageListHeader