
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login'; 
import Dashboard from './Dashboard'; 
import Customer from './customer/Customer'; 
import ProtectedRoute from '../utils/ProtectedRoute';
import AddCustomer from './customer/AddCustomer';
import AddCompany from './company/AddCompany';
import AddCustomer1 from './customer/AddCustomer1';
import CustomerDashboard from './customer/CustomerDashboard';
import Importcha from './chartofaccount/Importcha';
import AssetList from './chartofaccount/AssetList';
import Page from './FpoUSA/Page';
import DashboardMain from './Dashboard/DashboardMain';
import EditCompany from './company/EditCompany'; 
import SignupForm from './SignupForm';
import AddVendor from './Vendor/AddVendor';
import TaskPod from './TaskPod/TaskPod';
import Invoice from './customer/Invoice';
import UserPage from './User/UserPage';
import ManageList from './User/ManageList';
import DynamicListTable from './User/DynamicListTable';
import AddOrganization from './Organization/AddOrganization';
import ModalCompany from './company/ModalCompany';
import ManagePermission from './Permission/ManagePermission';
import CompanyDelegation from './company/CompanyDelegation';
import VendorList from './Vendor/VendorList';
import VendorDashboard from './Vendor/VendorDashboard';
import UserProfileDetails from './User/UserProfileDetails';
import Notification from './userProfile/Notification';
import UserProfileView from './User/UserProfileView';
import InvoiceView from './customer/InvoiceView';
import ForgetPass from './ForgetPass';
import ResetPassword from './ResetPassword';
import TaskRecurrenceCalendar from './TaskRecurrence/TaskRecurrenceCalendar';
import CustomerManagePermission from './customer/CustomerManagePermission';
const Body = () => { 
  return (
    <div className="bg-gray-100">
      <Router>
      <Routes> 
        <Route element={<ProtectedRoute />}>  
            <Route element={<UserPage />} path="/user"/>
            <Route element={<ManagePermission />} path="/manage_permission"/>
            <Route element={<ManagePermission />} path="/permission_level/:id"/>
            <Route element={<CompanyDelegation />} path="/companydelegation"/>
            <Route element={<ManageList />} path="/managelist"/>
            <Route element={<DynamicListTable />} path="/dynamic/:id"/>
            <Route element={<Dashboard />} path="/dashboard" exact/>
            <Route element={<Customer />} path="/customers" />

            <Route element={<TaskRecurrenceCalendar />} path="/taskRecurrCalendar" />


            <Route element={<Invoice />} path="/invoice" />
            <Route element={<TaskPod/>} path="/taskPod/:name"/>
            <Route element={<AddCustomer />} path="/add_customer" />
            <Route element={<AddCustomer1 />} path="/add_customer1/:id" />
            <Route element={<AddCompany />} path="/add_company" />
            <Route element={<EditCompany />} path="/edit_company/:id" />
            <Route element={<CustomerDashboard />} path="/customer_dashboard" />
            <Route element={<ModalCompany />} path="/modal_company" />
            <Route element={<Notification />} path="/notification" />
            <Route element={<InvoiceView />} path="/invoice_view" />
            <Route element={<UserProfileDetails />} path="/User_Profile_Details" />
            <Route element={<Importcha/>} path="/import_chart_of_account" />
            <Route element={<AssetList/>} path="/asset_chart_of_account" />
            <Route element={<Page/>} path="/globalfpousa" />
            <Route element={<DashboardMain />} path="/dashboardmain" /> 
            <Route element={<UserProfileView />} path="/Profile_View" /> 
            <Route element={<VendorList/>} path='/vendor_list'/>
            <Route element={<VendorDashboard/>} path='/vendor_dashboard/:id'/>
            <Route element={<AddVendor/>} path='/add_vendor'/>
            <Route element={<CustomerManagePermission/>} path='/company_permission'/>
        </Route> 
        <Route element={<SignupForm />} path="/signup" />
        <Route element={<ForgetPass />} path="/forget_password" />
        <Route element={<ResetPassword />} path="/reset_password/:token/:email" />
        <Route element={<AddOrganization />} path="/addorganization" /> 
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
    </div>
  );    
}


export default Body;