
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CUSTOMER_GET_DATA, CUSTOMERTABLE_VALUE_UPDATE,CUSTOMER_TABLEDROP_DATA,ADD_UPDATE_DROPDOWN,CUSTOMER_OTHERDETAIL_DYNAMIC_ADD_UPDATE,CUSTOMERTABLE_ADDFIELD_HEADERS,CUSTOMERTABLE_GETFIELD_HEADERS, BASE_URL, CUSTOMER_TABLEDYNAMIC_DROP, CUSTOMER_PERSON_IN_TABLE, CUSTOMERTABLE_UPDATEFIELD_HEADERS, RANDOM_PERSON, ADD_UPDATE_CUSTOMER_DELEGATION, DELETE_UPDATE_CUSTOMER_DELEGATION, ADD_CUSTOMER_DELEGATION } from '../constants';

export const customerTableShowUpdate = createApi({
  reducerPath: 'customerTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['CustomerTableData'],
  endpoints: (builder) => ({
    fetchCustomerTableData: builder.query({
      query: ({ token, companyId,owner, pagination }) => ({
        

        url: `${CUSTOMER_GET_DATA}${companyId}${pagination}&owner=${owner}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        providesTags: ['CustomerTableData'],
        // providesTags: (result, error, { companyId, pagination }) => 
        //   result ? [{ type: 'CustomerTableData', id: `${companyId}-${pagination}` }] : [],


      }),
    }),
    updateCustomerTableData: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMERTABLE_VALUE_UPDATE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
        invalidatesTags: ['CustomerTableData'],
        // invalidatesTags: (result, error, { data }) => 
        //   [{ type: 'CustomerTableData', id: `${data.companyId}-${data.pagination}` }],


      }),
    }),
    updateCustomerDelegation: builder.mutation({
      query: ({ token, data, isDelete }) => ({
        url: isDelete?DELETE_UPDATE_CUSTOMER_DELEGATION:ADD_CUSTOMER_DELEGATION,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
        invalidatesTags: ['CustomerTableData'],
        // invalidatesTags: (result, error, { data }) => 
        //   [{ type: 'CustomerTableData', id: `${data.companyId}-${data.pagination}` }],


      }),
    }),
  }),
});

export const {
  useFetchCustomerTableDataQuery,
  useUpdateCustomerTableDataMutation,
  useUpdateCustomerDelegationMutation
} = customerTableShowUpdate;




export const customerTableDropAdd = createApi({
  reducerPath: 'customerTableDropAdd',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['customerTableDropData'],
  endpoints: (builder) => ({
    // fetchCustomerTable: builder.query({
    //   query: ({token,data}) => ({
    //     url : `${CUSTOMER_TABLEDYNAMIC_DROP}/${data}`,
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       'Content-Type': 'application/json',
    //     },
    //     providesTags: ['customerTableDropData'],
    //   })
    // }),
    CustomerTable: builder.mutation({
      query: ({token,data}) => ({
        url : `${CUSTOMER_TABLEDYNAMIC_DROP}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        invalidatesTags: ['customerTableDropData'],
      })
    }),
    fetchPersonInTable: builder.query({
      query: ({token,data}) => ({
        url : `${CUSTOMER_PERSON_IN_TABLE}?search=${data}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        providesTags: ['customerTableDropData'],
      })
    }),
    
    CustomerTableDropAdd: builder.mutation({
      query: ({ token, data }) => ({
        url: ADD_UPDATE_DROPDOWN,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
        invalidatesTags: ['customerTableDropData'],
      }),
    })
  }),
});

export const {
  // useLazyFetchCustomerTableQuery,
  useCustomerTableMutation,
  useLazyFetchPersonInTableQuery,
  useCustomerTableDropAddMutation,
} = customerTableDropAdd;


export const randomUser = createApi({
  reducerPath: 'randomUser',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['randomUserData'],
  endpoints: (builder) => ({
    
    fetchRandomUser: builder.query({
      query: ({token,data}) => ({
        url : `${RANDOM_PERSON}?search=${data}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        providesTags: ['randomUserData'],
      })
    }),
    
  }),
});

export const {
  useLazyFetchRandomUserQuery,
} = randomUser;





export const custTableHeadFieldDynAddUp = createApi({
  reducerPath: 'custTableHeadFieldDynAddUp',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    CustTableHeadFieldDyn: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMER_OTHERDETAIL_DYNAMIC_ADD_UPDATE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    })
  }),
});

export const {
  useCustTableHeadFieldDynMutation,
} = custTableHeadFieldDynAddUp;




export const custTableAddFieldHeader = createApi({
  reducerPath: 'custTableAddFieldHeader',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['CustomerTableData'],
  endpoints: (builder) => ({
    CustTableAddFieldHeader: builder.mutation({
      query: ({ token, data }) => ({
        
        url: CUSTOMERTABLE_ADDFIELD_HEADERS,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
    CustTableUpdateFieldHeader: builder.mutation({
      query: ({ token, data }) => ({
       
        url: CUSTOMERTABLE_UPDATEFIELD_HEADERS,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
    fetchCustFieldHdr: builder.query({
      query: ({token,data}) => ({

        url : `${CUSTOMERTABLE_GETFIELD_HEADERS}/${data.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
         tagTypes: ['CustomerTableData'],

      })
    
    }),
  }),
});

export const {
  useLazyFetchCustFieldHdrQuery,
  useCustTableAddFieldHeaderMutation,
  useCustTableUpdateFieldHeaderMutation
} = custTableAddFieldHeader;
