const CustomerDelegationHeader = [
    {
        "user_name": "User Name",
        "user_name_status": 1
    },
    
    {
      "email": "Email",
      "email_status": 1
    },
    {
      "department": "Department",
      "department_status": 1
    },
    {
      "designation": "Designation",
      "designation_status": 1
    },
    {
      "reportingto":"Reporting",
      'reportingto_status':1
    },
    {
      "access_type": "Access",
      "access_type_status": 1
    },
    {
      "action": "Action",
      "action_status": 1
    }
]


export default CustomerDelegationHeader