const SocailHeader = [
    {
        "social_type": "Social Type",
        "social_type_status": 1
    },
    {
      "link_url": "Socail Link",
      "link_url_status": 1
    },
    {
      "action": "Action",
      "action_status": 1
    },
    
  
]


export default SocailHeader