import React, { useState, useEffect } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import { SAVE_BILLING, COMMON_URL } from "../../utils/constants";
import useEdiCompanyBilling from "../../utils/company/useEdiCompanyBilling";
import Shimmer from "../Shimmer";
import { deleteDoc } from "firebase/firestore";
import { saveData } from "../store/CompayProfileFuction/CompanyProfileAllFuction";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";

const EdiCompanyBilling = ({ company_id, accounting, taxFormData }) => {
  const user = useRequireAuth();
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const companyLocalData = localStorage.getItem("company_data");
  const company_profile_id = JSON.parse(companyLocalData)?.company_id || JSON.parse(localStorage.getItem("isLoggedIn"))?.company_id;
 
  const { data } = useEdiCompanyBilling(company_profile_id);  
  const [incomTaxNo, setIncomTaxNo] = useState(data?.incom_tax_no || "");
  const [gstNo, setGstNo] = useState(data?.gst_no || "");
  const [accoutingMethodID, setAccoutingMethodID] = useState(data?.accounting_method_id || "");
  const [taxFromID, setTaxFromID] = useState(data?.tax_form_id || "");
  const [companyID, setCcompanyID] = useState(company_profile_id || "");
  const [billSubmitted, setBillSubmitted] = useState(false);
  const [success, setSuccess] = useState("");
 
  useEffect(() => {
    if(data ===null){
      setIsFormFrozen(!isFormFrozen);
    }
    setIncomTaxNo(data?.incom_tax_no || "");
    setGstNo(data?.gst_no || "");
    setAccoutingMethodID(data?.accounting_method_id || "");
    setTaxFromID(data?.tax_form_id || "");
    setCcompanyID(company_profile_id || "");
  }, [data, company_profile_id]);
  
  const [formData, setFormData] = useState({
    incom_tax_no: '', 
    gst_no: '',   
    accounting_method_id:'',
    tax_form_id:''
  });
  const [isFormFrozen, setIsFormFrozen] = useState(true);

  const handleInputBillingChange = (event) => {
    const { name, value } = event.target;    
    switch (name) {
      case "incom_tax_no":
        setIncomTaxNo(value);
        break;
      case "gst_no":
        setGstNo(value);
        break;
      case "accounting_method_id":
        setAccoutingMethodID(value);
        break;
      case "tax_form_id":
        setTaxFromID(value);
        break;
      default:
        break;
    }
  };  
 
  const handleBillSave = async (event) => {
    event.preventDefault();
    
    try {
      const Authtoken = user.token;
      const formData = new FormData();
      formData.append("billing_id",data?.id);
      formData.append("company_profile_id", companyID);
      formData.append("incom_tax_no", event.target.incom_tax_no.value);
      formData.append("gst_no", event.target.gst_no.value);
      formData.append("accounting_method_id", event.target.accounting_method_id.value);
      formData.append("tax_form_id", event.target.tax_form_id.value);
      const response = await saveData(SAVE_BILLING, formData, Authtoken);
      setAlert({
        visible: true,
        message: response.message,
        type: "success",
      });
      setIsDivVisible(isDivVisible);
    } catch (error) {
      setAlert({
        visible: true,
        message: error.response?.data?.message || "An error occurred while submitting the form.",
        type: 'danger',
      });
    }
  };
 
  const [isDivVisible, setIsDivVisible] = useState(false);
  const handleButtonClick = () => {
    setIsDivVisible(!isDivVisible);
    setIsFormFrozen(!isFormFrozen);
  };
  const handleButtonClickShowEdit = () => {
    setIsDivVisible(!isDivVisible);
    setIsFormFrozen(!isFormFrozen);    
  };
 
  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  return (
    <div>
      <section className="links bg-white mt-5 pt-5">
          <form id="companyBillingForm"  onSubmit={handleBillSave}>
            <div className="font-poppins grid grid-cols-3 px-8 py-3 pt-5 gap-16">
              <div>
                <FirstMessage
                  type={alert.type}
                  message={alert.message}
                  visible={alert.visible}
                  onHide={hideAlert}
                />
                <label
                  htmlFor="Income Tax No."
                  className="block mb-1 text-535252 text-14 font-500"
                >
                  Income Tax No.
                </label>
                <input
                  className={`border border-gray-300 text-xs block w-full p-2.5 rounded-5px ${isFormFrozen ? 'text-gray-400' : ''}`}
                  type="text"
                  name="incom_tax_no"
                  value={incomTaxNo}
                  onChange={handleInputBillingChange}
                  readOnly={isFormFrozen}
                />
              </div>
              <div>
                <label
                  htmlFor="GST No."
                  className="block mb-1 text-535252 text-14 font-500"
                >
                  GST No.
                </label>
                <input
                  type="text"
                  name="gst_no"
                  value={gstNo}
                  onChange={handleInputBillingChange}
                  className={`border border-gray-300 text-xs block w-full p-2.5 rounded-5px ${isFormFrozen ? 'text-gray-400' : ''}`}
                  readOnly={isFormFrozen}
                />
              </div>
              <div>
                <label
                  htmlFor="Accounting Method"
                  className="block mb-1 text-535252 text-14 font-500"
                >
                  Accounting Method
                </label>
                <select
                  name="accounting_method_id"
                  value={accoutingMethodID}
                  onChange={handleInputBillingChange}
                  className={`text-xs block w-full px-2.5 py-0.5 ${isFormFrozen ? 'text-gray-400' : ''}`}
                  disabled={isFormFrozen}
                >
                  {accounting && accounting.length > 0 ? (
                    <>
                      <option value="">Select Accounting Method</option>
                      {accounting.map((Accounting) => (
                        <option key={Accounting.id} value={Accounting.id}>
                          {Accounting.accounting_method_name}
                        </option>
                      ))}
                      
                    </>
                  ) : (
                    <option value="">Select Accounting Method</option>
                  )}    
                </select>
              </div>
            </div>
            <div className="font-poppins grid grid-cols-3 px-8 py-3 pt-5 gap-16">
              <div>
                <label
                  htmlFor="Tax Form"
                  className="block mb-1 text-14 font-500 text-535252"
                >
                  Tax Form
                </label>
                <select
                  name="tax_form_id"
                  value={taxFromID}
                  onChange={handleInputBillingChange}
                  className={`text-xs block w-full px-2.5 py-0.5 ${isFormFrozen ? 'text-gray-400' : ''}`}
                  disabled={isFormFrozen}
                >
                  {taxFormData && taxFormData.length > 0 ? (
                    <>
                      <option value="">Select Tax Form</option>
                      {taxFormData.map((TaxFormData) => (
                        <option key={TaxFormData.id} value={TaxFormData.id}>
                          {TaxFormData.tax_form}
                        </option>
                      ))}
                      
                    </>
                  ) : (
                    <option value="">Select Tax Form</option>
                  )}
                </select>
              </div>
            </div>
            <div className="flex px-10 font-poppins py-3 justify-between">
              <div>
                {" "}
                {success && (
                  <span className="p p-1 text-green-400">
                    {success}
                  </span>
                )}{" "}
              </div>
              <div className="flex gap-4">
              {isDivVisible && (
                <span>
                <button
                  id="btncan_compbill"
                  type="button"
                  className="px-6 py-1 text-base rounded font-normal bg-F4F4F4 focus:outline-none me-2"
                  disabled={billSubmitted}
                  onClick={handleButtonClickShowEdit}
                >
                  CANCEL
                </button>
                <button
                  id="btncan_compbill"
                  type="submit"
                  className="px-6 py-1 text-base rounded font-normal bg-1D4469 text-white focus:outline-none"
                  disabled={billSubmitted}
                >
                  Update
                </button>
                </span>
              )}
              {data !== null ? (
                !isDivVisible && (
                  <button
                    id="btnedit_compbill"
                    type="button"
                    className="px-6 py-1 text-base rounded font-normal bg-1D4469 text-white focus:outline-none"
                    onClick={handleButtonClick}
                  >
                    Edit
                  </button>
                )
              ) : (
                <button
                  id="btnsave_compbill"
                  type="submit"
                  className="px-6 py-1 text-base rounded font-normal bg-1D4469 text-white focus:outline-none"
                >
                  Save
                </button>
              )}  
              </div>
            </div>
          </form>
      </section>
    </div>
  );
};

export default EdiCompanyBilling;
