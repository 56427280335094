import React, { useState ,useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchDynamicDataTableDataQuery,useUpdateDynamicDataTableDataMutation } from "../../utils/customer/customerTaskTable";
import useRequireAuth from "../../utils/useRequireAuth";
import { useFetchDynamicHeaderQuery } from "../../utils/customer/customerTaskTable";
import CustomerListTable from "../customer/CustomerListTable";
import SideBar from "../Dashboard/SideBar";
import AddCurrency from "./AddCurrency";
import HeaderDashboard from "../HeaderDashboard";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
const DynamicListTable =()=>{
  const {id:value}=useParams();
  const [customer,setCustomer]=useState([]);
  const {token}=useRequireAuth();
  const [customerTablePagination,setCustomerTablePagination]=useState(null);
  const [initialFilter,setInitialFilter]=useState([]);
  const navigate=useNavigate();
  const[header,setHeader]=useState();
  const [editData, setEditData] = useState();
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(null);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  
  const { data: customerTableALL, refetch: refetchCustomerData } =
  useFetchDynamicDataTableDataQuery({
      dynamicId:value,
      token: token,
      pagination: `${customerTablePagination}`,
    });
  const [updateDynamicTable]=useUpdateDynamicDataTableDataMutation()
  

    useEffect(() => {
      if (customerTableALL) {
        const { data, ...restData } = customerTableALL?.data;
        const newEdit = {
          field_name: "action", field_options:null,id:99,table_name:null,type: null ,value:"Delete"
        }
        setCustomer([...data,newEdit]);
        setInitialFilter(restData);
      }
    }, [customerTableALL, customerTablePagination, refetchCustomerData]);


    const { data: customerHeader, refetch } =
    useFetchDynamicHeaderQuery({
        dynamicId:value,
        token: token,
      });


      const handleNavigate = (taskid) => {
        if (taskid) {
          setIsEditPopupOpen(true);
          setEditData(taskid);
        }
      };
    

      useEffect(() => {
        if (customerHeader) {
          const generateDynamicArray = ({ data: { header } = {} }) => {
            if (!header) return [];
            return header.map(field => ({
              [field]: field.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
              [`${field}_status`]: 1
            }));
          };
          const result = generateDynamicArray(customerHeader);
          setHeader([...result]);
        }
      }, [customerHeader]);

      const hideAlert = () => {
        setAlert((prevState)=>({ ...prevState,visible: false }));
      };

      const closePopup = () => {
        setEditData();
        setIsEditPopupOpen(false);
        setIsPopupOpen(false);
       
      };
  const handelsave=()=>{
    refetchCustomerData()
  }

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const removeUnderscores = (str) => {
    if(str !=''){
      return str.replace(/_/g, ' ');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteGroup= (data)=>{
    const payload=
    {
      "field_name": data?.customer_id?.field_name,
      "id":data?.customer_id?.value
    }
    updateDynamicTable({data:payload,token})
  }
      
    return (
      <Fragment>
        <HeaderDashboard />
        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />  
      <nav className="">
        <div className="container ">
          <div class="flex justify-between ">
            <div class="flex justify-between ">
              <div class="tablemaindiv customerTaskTable">
                {
                  isPopupOpen && (
                    <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                      <AddCurrency
                      closePopup={closePopup}
                      dataName={customerHeader?.data?.tableName}
                      tableId={value}
                      handelsave={handelsave}
                      />
                    </div>
                  )
                }
                {editData && (
                  <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                    <AddCurrency
                     closePopup={closePopup}
                     dataName={customerHeader?.data?.tableName}
                     currencyId={editData}
                     tableId={value}
                     handelsave={handelsave}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="md:hidden">
            <button className="text-white focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>        
      </nav>
      <div className="p-4 pt-0 mt-14">
        <div class="flex grid-rows-2 gap-10">
          <div className="w-1/5">
            <SideBar />
          </div>
  
          <div className="w-9/12 mt-2">
            <div className="flex absolute mb-1">
              <button className="font-medium text-16 text-203C50 capitalize" onClick={handleBack}>
                <i className="bi bi-arrow-left me-2"></i>
                {customerHeader?.data?.tableName ? (
                  removeUnderscores(customerHeader?.data?.tableName)
                ) : (
                  null
                )}
              </button>
            </div>    
            <CustomerListTable
              tableDetail={{
                tableidclass: "fullscreen-table",
                tableparentidclass: "tableparent",
                tablenameclass: "font-medium text-16 text-203C50 capitalize",
                tableSearch: true,
                tableFilter: true,
                forcefullyfreeze:true,
                tableResizer: false,
                tableZoom: true,
                tableheader: false,
                dataactivity: true,
                headeraddfield: false,
                rowDisable:true,
                addbutton: (
                  <button
                    onClick={openPopup}
                    type="button"
                    className="text-2541558A hover:text-254155"
                  >
                    <i className="bi bi-plus-lg"></i>
                  </button>
                ),
                actionfield: (data) => data?.customer_id?.field_name!=="company_groups" && (
                  <Fragment>
                    <td className="w-[50px]">
                    <div className="text-left pl-[10px]" >
                        <button onClick={()=>handleDeleteGroup(data)}   className='text-F84545 text-15 text-left'> <i class="bi bi-trash3 text-18 text-F84545 font-600"></i> </button>
                      </div>
                    </td>
                  </Fragment>              
                ),
              }}
              thead={header}
              tbody={customer}
              tablePaginationData={setCustomerTablePagination}
              initialFilter={initialFilter}
              handleNavigate={handleNavigate}
              navigateNameCondition={[customerHeader?.data?.header[0]]}
              // ExtraFieldComponent={{
              //   action: (
              //     <Fragment>
              //          <h1>werwr</h1>
              //     </Fragment>
              //   )
              // }}
              
                             
            />
          </div>
        </div>
      </div>
      </Fragment>
    );
};
export default DynamicListTable;