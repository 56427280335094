import { useState, useEffect, useRef, Fragment } from "react";

import VendorAddress from "./VendorAddress";
import VendorSocialLink from "./VendorSocialLink";
import VendorContactPerson from "./VendorContactPerson";
import { BASE_URL } from "../../utils/constants";
import AddVendor from "./AddVendor";
import EditVendor from "./EditVendor";

const VendorHome = ({ vendorData }) => {

  const [isModalOpen,setIsModalOpen]=useState(false);
  const parsedData = (value) => {
    if (typeof value === "string") {
      const response = JSON.parse(value);
      return response?.value;
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal=()=>{
    setIsModalOpen(false);
  }
  return (
    <Fragment>
      <div className="flex flex-col">
        <div className="bg-white shadow-2 rounded p-5 mb-4">
          <div className="flex flex-row">
            <div
              htmlFor="dropzone-file"
              className="border-2 w-[121px] border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100 h-[121px]"
            >
              <div className="flex items-center h-full w-full text-center text-gray-500">
                {vendorData?.vendor_profile_pic && (
                  <img
                    src={
                      vendorData?.vendor_profile_pic
                        ? `${BASE_URL}${vendorData?.vendor_profile_pic}`
                        : "https://picsum.photos/200/300"
                    }
                    alt="Uploaded"
                    className="w-full h-full"
                  />
                )}
              </div>
            </div>

            <div className="ml-8 flex flex-col w-[81%]">
              <div className=" mb-4 flex justify-end">
                <button
                  className="py-2 px-6 border border-b-1 border-l-0 border-r-0 rounded-lg border-b-2C495D border-t-gray-300 text-2C495D text-sm font-500 hover:bg-2C495D hover:text-white hover:border-b-black hover:border-t-gray-300 transition"
                  onClick={openModal}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
          <hr class="border-b border-F1F6F9 my-3 h-1.5 bg-F1F6F9"></hr>
          <div className="flex flex-row flex-wrap">
            <div class="basis-1/4 mt-4 mb-3">
              <p class="text-sm font-light text-848687">Cus. Display Name</p>
              <p class="text-sm font-normal text-2C495D mb-2 capitalize">
                {vendorData?.vendor_display_name}
              </p>
            </div>
            <div class="basis-1/4 mt-4 mb-3">
              <p class="text-sm font-light text-848687">Website</p>
              <p class="text-sm font-normal text-2C495D mb-2 capitalize">
                {vendorData?.website}
              </p>
            </div>
            <div class="basis-1/4 mt-4 mb-3">
              <p class="text-sm font-light text-848687">Business Category</p>
              <p class="text-sm font-normal text-2C495D mb-2 capitalize">
                {parsedData(vendorData?.business_category)}
              </p>
            </div>
            <div class="basis-1/4 mt-4 mb-3">
              <p class="text-sm font-light text-848687">Type</p>
              <p class="text-sm font-normal text-2C495D mb-2 capitalize">
                {vendorData?.type}
              </p>
            </div>
            <div class="basis-1/4 mt-2">
           </div>
          </div>
          {isModalOpen && (
            <EditVendor vendorData={vendorData?.id} closeModal={closeModal}/>
          )}

        </div>
        <div class="rounded mb-3">
          <VendorSocialLink />
        </div>
        <div class=" rounded mb-3">
          <VendorAddress />
        </div>
        <div class="rounded">
          <VendorContactPerson />
        </div>
      </div>
    </Fragment>
  );
};

export default VendorHome;
