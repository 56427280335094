import React, { useState, useEffect  } from 'react';
import { CHARTS_OF_ACCOUNTS,CHARTS_OF_ACCOUNTS_DETAILS } from "../../utils/constants";
import useRequireAuth from "../../utils/useRequireAuth";
import LayoutHOC from "../LayoutHOC";
import Accordion from "./Accordion";
import AddAccount from './AddAccount';


const AssetList = ()=> {
    const auth = useRequireAuth();  
    const [data_chatofacc, setChardata] = useState(null);
    const [accordions, setAccordion] = useState([]);
    const [activeMenu, setActiveMenu] = useState(1);
    const [accordindMenuWiseData, setAccordindMenuWiseData] = useState([]);
    

    const fetchcoaData = async () => {
        try {
            const Authtoken = auth.token;
            const response = await fetch(CHARTS_OF_ACCOUNTS, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${Authtoken}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const data = await response.json();
            setChardata(data);
            createaccord(data); // Call createaccord function with fetched data
        } catch (error) {
            console.error("Error fetching data:", error);
            setChardata(null);
        }
    };
    const handleClick = (menu) => {
        setActiveMenu(menu);
    };
    useEffect(() => {
        if (auth.token) {
            fetchcoaData();
        }
    }, [auth.token]);
    

    //console.log(setAccordion)
    /* function generateData(data){
        console.log(data);
    } */
    const generateData = (children) => {

        
        if (Array.isArray(children)) {
            return (
                <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="bg-E6ECF1 px-6 py-4 text-2C495D text-left font-normal text-base">Code</th>
                            <th scope="col" className="bg-E6ECF1 px-6 py-4 text-2C495D text-left font-normal text-base">Name</th>
                            <th scope="col" className="bg-E6ECF1 px-6 py-4 text-2C495D text-left font-normal text-base">Detail Type</th>
                            <th scope="col" className="bg-E6ECF1 px-6 py-4 text-2C495D text-left font-normal text-base">Balance</th>
                            <th scope="col" className="bg-E6ECF1 px-6 py-4 text-2C495D text-left font-normal text-base text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        children.map(child => (
                            <tr key={child.id} className="border-neutral-200">
                                {/* Render table cells for each child */}
                                <td className="whitespace-nowrap border-b border-e border-s text-cyan-600 border-neutral-200 px-6 py-2 font-normal text-left">{child.code}</td>
                                <td className="whitespace-nowrap border-b border-e border-s text-cyan-600 border-neutral-200 px-6 py-2 font-normal text-left">{child.name}</td>
                                <td className="whitespace-nowrap border-b border-e border-s text-cyan-600 border-neutral-200 px-6 py-2 font-normal text-left">{child.detailType}</td>
                                <td className="whitespace-nowrap border-b border-e border-s text-cyan-600 border-neutral-200 px-6 py-2 font-normal text-left">{child.balance}</td>
                                <td className="whitespace-nowrap border-b border-e border-s text-cyan-600 border-neutral-200 px-6 py-2 font-normal text-center">
                                    {/* Buttons for each child */}
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#F16136" className="w-4 h-4">
                                            {/* SVG path for the button */}
                                        </svg>
                                    </button>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#F16136" className="w-4 h-4">
                                            {/* SVG path for the button */}
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            );
        }
        // Handle case where children is not an array
        return null;
    };
    

    const createaccord = (data_chatofacc) => { // Accept data_chatofacc as parameter
        if (data_chatofacc) {
            const updatedAccordions = data_chatofacc.map(item => ({
                key: item.id,
                title: item.name,
                data: generateData(item.children),
                isOpen: item.id === 1 // Set isOpen based on item.id
            }));
            setAccordion(updatedAccordions);
            console.log(updatedAccordions);
        }
    };

    const toggleAccordion = (accordionKey) => {
        const updatedAccordions = accordions.map((accord) => ({
            ...accord,
            isOpen: accord.key === accordionKey ? !accord.isOpen : false
        }));
        console.log("tog",updatedAccordions);
        setAccordion(updatedAccordions);
    
    };

    const handleMenuClick = (menuIndex) => {
        callFunctionAccordingToMenu(menuIndex);
        setActiveMenu(prevActiveMenu => prevActiveMenu === menuIndex ? null : menuIndex);   
    };
    
    const callFunctionAccordingToMenu = async (menuIndex) => {
       
        try {
            const idString = menuIndex.toString();
            const Authtoken = auth.token;
            
            const response = await fetch(`${CHARTS_OF_ACCOUNTS_DETAILS}${idString}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${Authtoken}`,
                    'Content-Type': 'application/json'
                },
            });
    
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
    
            const data = await response.json();
            setAccordindMenuWiseData(data);
            
        } catch (error) {
            console.error("Error fetching data:", error);
            setChardata(null);
        }
    };
    

    // const [isOpen, setIsOpen] = useState(false);

    // const openPopup = () => { 
    //     setIsOpen(true);
    // };

    // const closePopup = () => {
    //     setIsOpen(false);
    // };

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };
    
    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div className="flex-1 bg-F1F6F9 mt-16">
        
            <div className='bg-White pl-3'>
                <h5 className=" font-Vietnam text-2xl font-medium p-4">Chart Of Accountants</h5>
            </div>
            <div className="flex p-4 justify-between text-2C495D font-poppins text-base font-normal">
                <div className="flex p-4 gap-x-10">
                    <p className="cursor-pointer hover:cursor-wai hover:border-b-2 border-2C495D">Bank Transaction</p>
                    <p className='cursor-pointer hover:cursor-wai hover:border-b-2 border-2C495D'>App Transaction</p>
                    <p className='cursor-pointer hover:cursor-wai hover:border-b-2 border-2C495D'>Reciepts</p>
                    <p className='cursor-pointer hover:cursor-wai hover:border-b-2 border-2C495D'>Reconcil</p>
                    <p className='cursor-pointer hover:cursor-wai hover:border-b-2 border-2C495D'>Rules</p>
                    <p className='border-b-2 border-2C495D cursor-pointer hover:cursor-wai'>Chartered Accountants</p>
                </div>
            </div>
            <section>
                <div className="flex">
                    <div className="flex bg-white py-3 w-2/3  px-5 ms-7">
                        <button type="button" className="px-5">All</button>
                        
                        <button   onClick={() => {toggleAccordion(1); handleMenuClick(1);
                                }} type="button"  className={`px-5 ${activeMenu === 1 ? 'text-red-600' : ''}`}>Assets</button>
                        <button  onClick={() => {toggleAccordion(2); handleMenuClick(2);
                                }} type="button" className={`px-5 ${activeMenu === 2 ? 'text-red-600' : ''}`}>Lability/Credit Card</button>
                        <button onClick={() => {toggleAccordion(3); handleMenuClick(3);
                                }}  type="button" className={`px-5 ${activeMenu === 3 ? 'text-red-600' : ''}`}>Income</button>
                        <button onClick={() => {toggleAccordion(4); handleMenuClick(4);
                                }} type="button" className={`px-5 ${activeMenu === 4 ? 'text-red-600' : ''}`}>Expenses</button>
                        <button  onClick={() => {toggleAccordion(5); handleMenuClick(5);
                                }} type="button" className={`px-5 ${activeMenu === 5 ? 'text-red-600' : ''}`}>Equity</button>
                        <button  onClick={() => {toggleAccordion(6); handleMenuClick(6);
                                }} type="button" className={`px-5 ${activeMenu === 6 ? 'text-red-600' : ''}`}>Archive</button>
                    </div>
                    <div className="box-content flex flex-col justify-center w-14 h-[50px] border-1 border-solid bg-white border-BECBD3 ml-4">
                        <button onClick={openPopup} className="text-center text-288FB2 text-32" type="button">+</button>
                    </div>
                     
                </div>
            </section>
            <>
            {isPopupOpen && (
                <AddAccount isOpen={isPopupOpen}  onClose={closePopup} />
            )}
            </>
            <div className="m-5">
                {accordions.map((accordion) => (
                    <Accordion
                        key={accordion.key}
                        title={accordion.title}
                        data={accordion.data}
                        isOpen={accordion.isOpen}
                        toggleAccordion={() => toggleAccordion(accordion.key)} />
                ))}
            </div>
        </div>
    );
}

export default LayoutHOC(AssetList);