import React, { useState, useEffect } from 'react';
import { CHECK_ORG_DUPLICATE, SIGNUP_URL } from '../../utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUser } from '../../utils/userSlice';
import FooterFixed from '../FooterFixed';

const AddOrganization = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();  

  const [error, setErrors] = useState(false);
  const signupData = location.state?.signupData;

  const [orgData, setOrgData] = useState({
    org_name: '',
    org_website: '',
    org_category: '',
    org_country: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [emailErrors, setEmailErrors] = useState();
  const [btnVisible, setbtnVisible] = useState(true);

  useEffect(() => {
    if (!signupData) {
      navigate('/signup');
    }
  }, [signupData, navigate]);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setOrgData({ ...orgData, [name]: value });

    if (name === 'org_name') {
      await checkOrgExists(value);
    }
  };

  const checkOrgExists = async (org_name) => {
    try {
      const response = await fetch(`${CHECK_ORG_DUPLICATE}?org_name=${org_name}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("API Response:", data); // Log the response from the API

      if (data.exists) {
        setErrors(true);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          org_name: "Organization name already exists."
        }));
        setbtnVisible(false);
      } else {
        setErrors(false);
        setFormErrors((prevErrors) => {
          const { org_name, ...rest } = prevErrors;
          return rest;
        });
        setbtnVisible(true);
      }
    } catch (error) {
      console.error("Error checking organization name:", error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Basic form validation example
    const errors = {};
    if (!orgData.org_name) {
      errors.org_name = 'Name is required.';
    }
    if (!orgData.org_website) {
      errors.org_website = 'Website is required.';
    }
    if (!orgData.org_category) {
      errors.org_category = 'Category is required.';
    }
    if (!orgData.org_country) {
      errors.org_country = 'Country is required.';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Assuming SIGNUP_URL is defined somewhere with your backend URL
    try {
      const response = await fetch(SIGNUP_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...signupData, ...orgData }),
      });

      const data = await response.json();
      if (data?.status === true) {
        dispatch(addUser(data?.data));
        navigate("/dashboardMain");
      } else {
        const emailError = data?.data?.email[0];
        setEmailErrors(emailError);
        // setErrorMessage(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      // Handle fetch or other errors
      console.error('Error submitting form:', error);
    }
  };

  const handleClick = () => {
    navigate('/signup'); // Navigate to the "add_company" page
  };

  return (
    <div className='bg-white px-5'>
      <a className="absolute top-10" onClick={handleClick}>
        <button className="flex gap-x-4 bg-F4F4F4 border border-EAE2E2 rounded-lg px-6 py-2">
          <h4 className="text-7B7B7B"><i class="bi bi-arrow-left"></i></h4>Back</button>
      </a>  
      <div class="flex items-center justify-center min-h-screen">
        <div className='px-2 py-8 border border-EDEDED rounded shadow-md mx-28 w-1/2 max-w-md'>
          <div className='space-y-5 px-5'>
            <form onSubmit={handleFormSubmit} > 
                <h1 className='text-1D5D9B text-24 font-700 pb-3 px-5 my-5 text-center'>Organization Details</h1>         
                <div className='grid grid-cols-1 mb-3'>
                  <label htmlFor='org_name' className='text-545B61 text-14 font-500 mb-2'>
                  Org. Name <span className='text-red-600'>*</span>
                  </label>
                  <input
                    name='org_name'
                    className='border border-gray-300 text-xs block w-full p-2.5 italic rounded-5px'
                    type='text'
                    placeholder='Enter Organization Name...'
                    value={orgData.org_name}
                    onChange={handleInputChange}
                  />
                  {formErrors.org_name && <p className='text-12 text-red-400'>{formErrors.org_name}</p>}
                </div>

          <div className='grid grid-cols-1 mb-3'>
            <label htmlFor='org_website' className='block mb-2 text-545B61 text-14 font-500'>
              Website <span className='text-red-600'>*</span>
            </label>
            <input
              name='org_website'
              className='border border-gray-300 text-xs block w-full p-2.5 italic rounded-5px'
              type='text'
              placeholder='Enter Organization Website...'
              value={orgData.org_website}
              onChange={handleInputChange}
            />
            {formErrors.org_website && <p className='text-12 text-red-400'>{formErrors.org_website}</p>}
          </div>
          <div className='grid grid-cols-1 mb-3'>
            <label htmlFor='org_category' className='block mb-2 text-545B61 text-14 font-500'>
              Category <span className='text-red-600'>*</span>
            </label>
            <select name='org_category' className='form-select py-0 w-full' onChange={handleInputChange} value={orgData.org_category}>
              <option hidden value="">Select Category</option>
              <option>Business</option>
              <option>Individual</option>
            </select>
            {formErrors.org_category && <p className='text-12 text-red-400'>{formErrors.org_category}</p>}
          </div>

                <div className='grid grid-cols-1 mb-5'>
                  <label htmlFor='org_country' className='block mb-2 text-545B61 text-14 font-500'>
                    Country <span className='text-red-600'>*</span>
                  </label>
                  {/* <input
                    name='org_country'
                    className='border border-gray-300 text-xs block w-full p-2.5 italic rounded-5px'
                    type='text'
                    placeholder='Enter Organization Country...'
                    value={orgData.org_country}
                    onChange={handleInputChange}
                  /> */}

                  <select  name='org_country' className='form-select py-0 w-full'  onChange={handleInputChange}>
                    <option hidden selected>Select Country</option>
                    <option>USA</option>
                    <option>Canada</option>
                    <option>Australia</option>
                    <option>United Kingdom</option>
                    <option>India</option>
                    <option>Others</option>
                  </select>
                  
                  {formErrors.org_country && <p className='text-12 text-red-400'>{formErrors.org_country}</p>}
                </div>

              <div className='flex justify-end'>
              {btnVisible && (
                <button type='submit' className='mt-5 bg-sky-700 w-full text-white font-bold py-1 rounded-md hover:bg-sky-600'>
                  Submit
                </button>
              )}              
              </div>
              <div className='flex items-center justify-center py-2'>
                {emailErrors && <p className='text-12 text-red-400 mb-0'>{emailErrors}</p>}
              </div>
            </form>
          </div>
        </div>  
        <div><FooterFixed /></div>
    </div>
    </div>
  );
};

export default AddOrganization;
