import React, { useState, useEffect, Fragment } from "react";
import Profilepic from "../../assets/images/profile.webp";
import AddUserModal from "./AddUserModal";
import { Avatar, Dialog } from "@mui/material";
import { EDIT_USER_LIST_BASED_ON_ID } from "../../utils/constants";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import useRequireAuth from "../../utils/useRequireAuth";
import { deepPurple } from "@mui/material/colors";

const UserProfileView = ({ closePopup, handelsave, editData, title }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [editDataFlag,SetEditDataFlag]=useState(false)
  const [isDialogProfileViewOpen, setIsDialogProfileViewOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const {token}=useRequireAuth();

  const toggleProfileViewDialog = () => {
    setIsDialogProfileViewOpen(!isDialogProfileViewOpen);
  };
  
  const handleToggle = () => {
    setShowDetails((prevShowDetails) => !prevShowDetails);
  };

  const fetchTaskEditData = async (editData) => {
    const url = `${EDIT_USER_LIST_BASED_ON_ID}${editData}`;
    const getData = await getCommonDataBasedOnId(url, token);
    if(getData?.length){
      setFormData(getData[0]);
    }
  }; 

  useEffect(() => {
    fetchTaskEditData(editData);
  }, [editData]);


  const displayValue = (value) => {
    return value !== null && value !== undefined ? value : '--';
  };

  const getInitials=(value)=>(value && value.split(" ").map((item)=>(item[0])).join(""));

  

 console.log(formData,"formData");

  return (
    <Fragment>
        <Dialog anchor="right" open={isDialogProfileViewOpen} onClose={toggleProfileViewDialog}>
        {isDialogProfileViewOpen && (
            <div className="relative w-full h-full flex justify-center items-center">
            <AddUserModal
              closePopup={closePopup}
              editData={editData} // Pass the actual edit data here
              title="Edit User"
            />
          </div>
        )}
      </Dialog>
      {!isDialogProfileViewOpen && (
            <div className="fixed inset-0 flex justify-end z-[9999] " >
            <div className=" bg-white  font-popns drop-shadow-lg  rounded-md w-[652px] overflow-hidden no-scrollbar ">
              <div className="mx-6 flex items-center justify-between py-4 md:py-3  border-b border-BECBD3 rounded-t">
                <h3 className="text-xl text-434852 font-500">Profile View</h3>
                <button
                  type="button"
                  className="text-454545 bg-F1F5F8 hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                  data-modal-hide="assets-modal"
                  onClick={closePopup}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="mx-6 mt-4">
                <div>
                  <div className="flex justify-between pb-2 mb-2">
                    <div className={'flex'}>
                      <div className="p-2 rounded font-medium text-16 place-self-center">
                        {/* <img
                          className="h-[86px] w-[86px] rounded-full"
                          src={Profilepic}
                          alt="User"
                        /> */}
                          <Avatar sx={{ bgcolor: deepPurple[500], width: 86, height: 86, fontSize: "40px" }}>
                            {`${getInitials(formData?.first_name || 'N')}${getInitials(formData?.last_name || 'A')}`}
                          </Avatar>

                      </div>
                      <div className="mx-4 space-y-1 mt-2">
                        <h3 className="text-2C495D text-18 font-600">
                          {(formData?.first_name || formData?.last_name) ? `${formData?.first_name || ''} ${formData?.last_name || ''}`.trim() : 'NA'}
                        </h3>
                        <div className="flex space-x-2">
                          <label className="text-2C495D text-14 font-400">
                          {formData?.designation_name || 'NA'}
                          </label>
                        </div>
                        <div className="flex space-x-2">
                          <i className="bi bi-envelope"></i>
                          <label className="text-2C495D text-14 font-500">
                          {formData?.email}
                          </label>
                        </div>
                      </div>
                    </div>
    
                    <div className="flex items-center mt-4">
                        <button onClick={toggleProfileViewDialog}  className="px-4 py-1 bg-103149 text-white rounded">
                          Edit
                        </button>
                      </div>
    
                  </div>
                  <div>
                    {/* <div className="flex justify-between">
                      <div>
                        <button
                          onClick={handleToggle}
                          className="border border-[#1d4469] border-opacity-20 text-848687 text-12 font-400 py-1 px-2 rounded-lg flex items-center gap-3"
                        >
                          Show Details
                          <svg
                            className={`transition-transform duration-300 ${
                              showDetails ? "rotate-180" : ""
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="5"
                            viewBox="0 0 8 5"
                            fill="none"
                          >
                            <path
                              d="M3.99999 0C3.85661 0 3.71325 0.0600238 3.60394 0.179819L0.164113 3.9514C-0.0547045 4.19132 -0.0547045 4.58031 0.164113 4.82013C0.382843 5.05996 0.737548 5.05996 0.956383 4.82013L3.99999 1.48283L7.04361 4.82002C7.26243 5.05984 7.6171 5.05984 7.83581 4.82002C8.05473 4.58019 8.05473 4.19121 7.83581 3.95129L4.39603 0.179702C4.28667 0.0598874 4.14331 0 3.99999 0Z"
                              fill="#B4C0C8"
                            />
                          </svg>
                        </button>
                      </div>
                    </div> */}
                    {/* {showDetails && ( */}
                      <div className="mt-4">
                        <h3 className="text-18 text-2C495D font-600">
                          User Information
                        </h3>
                        <div className="flex mt-4">
                          <div className="w-[30%]">
                            <ul className={`space-y-3 text-14 text-5E5E5E font-400`}>
                              <li>First Name</li>
                              <li>Last Name</li>
                              <li>Email</li>
                              <li>Employee ID</li>
                              <li>Designation </li>
                              <li>Department </li>
                              <li>Date of Birth</li>
                              <li>Gender</li>
                              <li>Date of Joining</li>
                            </ul>
                          </div>
                          <div className="w-[30%]">
                            <ul className={`space-y-3 text-14 text-5E5E5E font-400`}>
                              <li>{formData?.first_name || 'NA'} </li>
                              <li>{formData?.last_name || 'NA'}</li>
                              <li>{formData?.email || 'NA'}</li>
                              <li>{formData?.emp_id || 'NA'}</li>
                              <li className="rounded-full border border-64B200 bg-[#EFFFDA] max-w-auto items-center px-2 gap-2 inline-flex ">{formData?.designation_name || 'NA'}
                                <svg className="ml-1" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.251049 0.756267C-0.083683 1.09796 -0.083683 1.65201 0.251049 1.9937L5.39392 7.24373C5.72864 7.58542 6.27138 7.58542 6.6061 7.24373L11.749 1.9937C12.0837 1.65201 12.0837 1.09796 11.749 0.756267C11.4142 0.414577 10.8715 0.414577 10.5368 0.756267L6.00001 5.38758L1.46324 0.756267C1.1285 0.414578 0.58579 0.414578 0.251049 0.756267Z" fill="#64B200"/>
                                </svg>
                              </li>
                              <li>{formData?.department_name || 'NA'}</li>
                              {/* <li>{formData?.department_name || 'NA'}</li> */}
                              <li>{formData?.dob || 'NA'}</li>
                              <li>{formData?.gender || 'NA'}</li>
                              <li>{formData?.doj || 'NA'}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
    
              {/* <form className={`w-full lg:max-h-[85vh]  overflow-auto  pt-3`}> */}
    
              {/* <div className="sticky bottom-0 p-4 flex justify-end py-4 modal-footer text-md w-full rounded-none z-[9998]">
                  <div className="px-2">
                    <button
                      type="button"
                      className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 px-5 py-1 text-center rounded-5px"
                      onClick={closePopup}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn text-gray-900 border border-gray-200 py-1 px-5 ms-3 rounded-5px"
                    >
                      {editData ? "Update" : "SAVE"}
                    </button>
                  </div>
                </div> */}
              {/* </form> */}
            </div>
          </div>
      )}
    
    </Fragment>
  );
};
export default UserProfileView;
