import React, { Fragment } from "react";
import { useFetchCustomerEditQuery } from "../../utils/customer/customerSelect";
import { parseJSONValue } from "../../utils/json/parseJSONValue";
import useRequireAuth from "../../utils/useRequireAuth";
import { useParams } from "react-router-dom";

const AddCustomerOtherDetail = ({ otherDetails }) => {
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const {
    data: customerEditData,
    isLoading,
    refetch: customerEditDataRefetch,
  } = useFetchCustomerEditQuery({ token, customerId });

  const maxLength = 15; // Maximum length before truncation

  // Truncate text if it exceeds maxLength
  const truncateText = (text) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const checkValue = (value, labelName) => {
    if (value && labelName !== "opportunity descriptions") {
      return value;
    }
    return null;
  };

  const parseValue = (value) => {
    try {
      return JSON.parse(parseJSONValue(value) || "{}");
    } catch (error) {
      console.error("JSON parsing error: ", error);
      return {};
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <Fragment>
      <div>
        {customerEditData?.data.map((item, index) => (
          <div key={index} className="p-3 bg-white rounded-b-lg">
            <div className="grid grid-cols-2 justify-between gap-4">
              {Object.keys(item).map((fieldKey) => {
                const parsedValue = parseValue(item[fieldKey]?.value);
                const checkElm = checkValue(parsedValue?.value, item[fieldKey]?.field_lable);

                return (
                  checkElm && (
                    <div key={fieldKey}>
                      <p className="text-5E5E5E text-14 capitalize">
                        {item[fieldKey]?.field_lable}
                      </p>
                      <span
                        className={`text-${parsedValue?.color_code} text-xs font-normal rounded-[5px]`}
                        style={{
                          background: parsedValue?.color_code,
                          padding: "4px 8px",
                        }}
                      >
                        {Array.isArray(parsedValue?.value)
                          ? parsedValue?.value.join(", ")
                          : truncateText(parsedValue?.value)}
                      </span>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default AddCustomerOtherDetail;
