import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, ClickAwayListener } from "@mui/material";
import useDropdownPosition from "../../../hooks/Dropdown/useDropDownPosition";
import SaveEditFirst from "../SaveEdit/SaveEditFirst";
import { parseJSONValue } from "../../../utils/json/parseJSONValue";
 
const PersonDropDown = ({
  tableidclass,
  setDataInputRow,
  dataInputRow,
  SpecificElement,
  DropData,
  UpdateTableField,
  FieldValue,
  Item,
  fetchPersonInTable
}) => {
  const parsedDropData = Array.isArray(parseJSONValue(DropData))
    ? parseJSONValue(DropData)
    : [];
 
  const menuData = parsedDropData.map((item) => ({
    label: item.name, // Corrected from 'lable' to 'label'
    value: item.email,
  }));
 
  const [filter, setFilter] = useState("");
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(
    parsedDropData.find((option) => option.value === FieldValue?.value) || null
  );
 
  useEffect(() => {
    fetchPersonInTable("");
  }, []);
 
  const { dropdownPosition, dropdownRef } = useDropdownPosition(
    show,
    tableidclass
  );
 
  const handleDropdownToggle = () => {
    setShow((prevShow) => !prevShow);
  };
 
  const handleOptionSelect = (event, newValue) => {
    if (newValue) {
      setSelected(newValue);
      setFilter(newValue.value);
    } else {
      setSelected(null);
      setFilter("");
    }
    setShow(false);
  };
 
  const handleInputChange = (event, value) => {
    setFilter(value);
    if (!value) {
      setSelected(null);
    }
  };
 
  const handleTextFieldClick = () => {
    setShow(true); // Open dropdown on single click
  };
 
  const handleCloseClick = (e) => {
    e.stopPropagation();
    setDataInputRow(null);
    setShow(false);
  };
 
  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.value.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  return (
          <ClickAwayListener onClickAway={() => { setShow(false); setDataInputRow(null); }}>
          <div ref={dropdownRef}>
          
          <Autocomplete
            value={selected}
            onChange={handleOptionSelect}
            inputValue={filter}
            selectOnFocus
            openOnFocus
            onInputChange={handleInputChange}
            options={menuData}
            autoHighlight
            getOptionLabel={(option) => `${option?.value || ""}`}
            size="small"
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoFocus
                InputProps={{
                  ...params.InputProps,
                  style: {
                    border: 'none', // Remove the inner border
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    boxShadow: 'none', // Remove inner box shadow
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <div className="flex flex-col">
                  <span className="text-black">{option.label}</span>
                  <span className="text-black">{option.value}</span>
                </div>
              </li>
            )}
          />

        {dataInputRow?.rowIndex === SpecificElement && (
<SaveEditFirst
            UpdateTableField={(e) => {
              UpdateTableField(e, selected, Item);
            }}
            handleCloseClick={handleCloseClick}
          />
        )}
</div>
</ClickAwayListener>
  );
};
 
export default PersonDropDown;















// import React, { useState, useEffect, Fragment } from 'react';
// import axios from 'axios';
// import useDropdownPosition from '../../../hooks/Dropdown/useDropDownPosition';
// import SaveEditFirst from '../SaveEdit/SaveEditFirst';
// import useDebounce from '../useDebounce';
// import useClickOutside from '../../../hooks/Screen/useClickOutside';
// import {
//   TextField,
//   Menu,
//   MenuItem,
//   CircularProgress,
//   Paper,
//   ListItem,
//   ListItemText,
//   List,
// } from '@mui/material';

// const PersonDropDown = ({ tableidclass, fetchPersonInTable, setDataInputRow, dataInputRow, SpecificElement, DropData, UpdateTableField, FieldValue, Item }) => {

//   const parsedDropData = Array.isArray(DropData) ? DropData : [];
  
//   const [selected, setSelected] = useState({ name: FieldValue?.value, id: FieldValue?.name });
//   const [filter, setFilter] = useState(FieldValue?.value || '');
//   const [show, setShow] = useState(false);

//   const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);
//   const [hoveredItemPosition, setHoveredItemPosition] = useState({ x: 0, y: 0 });
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const debouncedFilter = useDebounce(filter, 300);
//   const { dropdownPosition, dropdownRef } = useDropdownPosition(show, tableidclass);

//   useEffect(() => {
//     if (show) {
//       setIsLoading(true);
//       setError(null);
//       fetchPersonInTable(debouncedFilter);
//       setIsLoading(false);
//     } 
//   }, [debouncedFilter, show]);

//   const close = () => {
//     setShow(false);
//     setFocusedOptionIndex(null);
//   };

//   const open = () => {
//     setShow(true);
//     setFilter('');
//   };

//   const searchDropToggle = (e) => {
//     e.stopPropagation();
//     if (show) {
//       close();
//     } else {
//       open();
//     }
//   };

//   const isOpen = () => show === true;

//   const filteredOptions = () => {
//     return parsedDropData.filter(option => (
//       option.name?.toLowerCase().includes(filter?.toLowerCase()) ||
//       option.email?.toLowerCase().includes(filter?.toLowerCase())
//     ));
//   };

//   const onOptionClick = (index) => {
//     setFocusedOptionIndex(index);
//     selectOption(index);
//   };

//   const selectOption = (index) => {
//     if (!isOpen()) {
//       return;
//     }
//     const focusedOption = filteredOptions()[index];
//     if (focusedOption) {
//       setSelected(focusedOption);
//       setFilter(`${focusedOption.name} ${focusedOption.email}`);
//     }
//     close();
//   };

//   const focusPrevOption = () => {
//     if (!isOpen()) {
//       return;
//     }
//     const optionsNum = filteredOptions().length - 1;
//     if (focusedOptionIndex > 0 && focusedOptionIndex <= optionsNum) {
//       setFocusedOptionIndex(prevIndex => prevIndex - 1);
//     } else if (focusedOptionIndex === 0) {
//       setFocusedOptionIndex(optionsNum);
//     }
//   };

//   const focusNextOption = () => {
//     const optionsNum = filteredOptions().length - 1;
//     if (!isOpen()) {
//       open();
//     }
//     if (focusedOptionIndex === null || focusedOptionIndex === optionsNum) {
//       setFocusedOptionIndex(0);
//     } else if (focusedOptionIndex >= 0 && focusedOptionIndex < optionsNum) {
//       setFocusedOptionIndex(prevIndex => prevIndex + 1);
//     }
//   };

//   const handleKeyDown = (e) => {
//     switch (e.key) {
//       case 'Enter':
//         selectOption(focusedOptionIndex);
//         break;
//       case 'ArrowUp':
//         focusPrevOption();
//         break;
//       case 'ArrowDown':
//         focusNextOption();
//         break;
//       default:
//         break;
//     }
//   };

//   const handleCloseClick = (e) => {
//     e.stopPropagation();
//     setDataInputRow(null);
//     setShow(false);
//   };

//   const closeDropdownOutsideClick = useClickOutside(() => {
//     setShow(false);
//     setDataInputRow(null);
//   });

//   return (
//     <Fragment>
//       <div ref={closeDropdownOutsideClick}>
//         <div className="">
//           <div ref={dropdownRef}>
//             <div className="relative">
//               <TextField
//                 value={selected ? selected?.name : filter}
//                 onChange={(e) => {
//                   setFilter(e.target.value);
//                   setSelected(null);
//                 }}
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   setShow(true);
//                 }}
//                 onKeyUp={handleKeyDown}
//                 fullWidth
//                 variant="outlined"
//                 InputProps={{
//                   endAdornment: (
//                     <button type='button' onClick={searchDropToggle} className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
//                       <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
//                         <polyline style={{ display: !isOpen() ? 'block' : 'none' }} points="18 15 12 20 6 15"></polyline>
//                         <polyline style={{ display: isOpen() ? 'block' : 'none' }} points="18 15 12 9 6 15"></polyline>
//                       </svg>
//                     </button>
//                   ),
//                 }}
//               />
//               <Menu
//                 anchorEl={dropdownRef.current}
//                 open={isOpen()}
//                 onClose={close}
//                 PaperProps={{
//                   style: {
//                     maxHeight: 225,
//                     width: dropdownRef.current ? dropdownRef.current.clientWidth : null,
//                   },
//                 }}
//               >
//                 {isLoading ? (
//                   <CircularProgress />
//                 ) : (
//                   filteredOptions().map((option, index) => (
//                     <MenuItem
//                       key={index}
//                       onClick={() => onOptionClick(index)}
//                       onMouseEnter={(e) => { setFocusedOptionIndex(index); setHoveredItemPosition({ x: e.clientX, y: e.clientY }); }}
//                       onMouseLeave={() => setFocusedOptionIndex(null)}
//                       selected={focusedOptionIndex === index}
//                     >
//                       <ListItemText
//                         primary={option?.name}
//                         secondary={option?.email}
//                       />
//                     </MenuItem>
//                   ))
//                 )}
//               </Menu>
//             </div>
//           </div>
//         </div>
//         {dataInputRow?.rowIndex === SpecificElement && (
//           <SaveEditFirst UpdateTableField={(e) => { UpdateTableField(e, selected, Item); }} handleCloseClick={handleCloseClick} />
//         )}
//       </div>
//     </Fragment>
//   );
// };

// export default PersonDropDown;
