import React, { useRef } from 'react'
import CreatePortalHOC from '../GlobalFeature/Fields/CreatePortalHOC'
import UserPopUpDetails from '../../utils/customer/UserPopupDetails'

const UserHoverDetails = ({open,onClose,handleShowDetails,item,header,handleShowHover}) => {
  const UserNameRef=useRef()
  const getCreatePortalPosition = () => {
    if (UserNameRef.current) {
        const rect = UserNameRef.current.getBoundingClientRect(); // Get the bounding rectangle
        return ({
            top: rect.bottom + window.scrollY -8,
            left: rect.right + window.scrollX,
        });
    }
}
  return (
    <div ref={UserNameRef}>
           {<CreatePortalHOC isOpen={open} onClose={onClose} position={getCreatePortalPosition()}>
                <UserPopUpDetails handleShowHover={handleShowHover} customerData={item} headerKey={header.key} onHandleShowDetails={()=>handleShowDetails(item,header.key)} />
             
            </CreatePortalHOC>}
    </div>
   
  )
}

export default UserHoverDetails