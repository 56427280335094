const VendorContactHeader = [
    {
        "name": "Name",
        "name_status": 1
    },
    {
      "contact_email": "Email",
      "contact_email_status": 1
    },
    {
        "contact_no": "Phone Number",
        "contact_no_status": 1
      },
    {
      "action": "Action",
      "action_status": 1
    },
    
  
]


export default VendorContactHeader