const UserHeader = [
  {
    "emp_id": "Emp ID",
    "emp_status": 1
  },
  {
        "user_name": "User Name",
        "user_name_status": 1
    },
    
    
    {
      "email": "User Email",
      "email_status": 1
    },
    {
      "designation": "Designation",
      "designation_status": 1
    },
    // {
    //   "profile": "Profile",
    //   "profile_status": 1
    // },
    // {
    //   "role": "Role",
    //   "role_status": 1
    // },
    {
      "reporting": "Reporting",
      "reporting_status": 1
    },
    {
      "groups_name": "Group",
      "groups_name_status": 1
    },
    {
      "enabled": "Enabled",
      "enabled_status": 1
    },
    
    // {
    //   "doj": "Date of Joining",
    //   "doj_status": 1
    // },
    // {
    //   "action": "Action",
    //   "action_status": 1
    // },  
    // {
    //     'created_at':"Create Date",
    //     "created_at_status":1
    // }
]


export default UserHeader