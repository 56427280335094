import React from 'react';

const PermissionToggleButton = ({ isYes, toggleState }) => {
  return (
    <div className={`flex w-[140px] h-[30px] mr-3 ${isYes ? 'bg-white border border-D6D6D6 rounded-[50px]' : 'bg-F0F0F0 border border-D6D6D6 rounded-[50px]'}`}>
      <button
        type="button"
        className={`w-[73px] text-12 font-500 transition-colors duration-300 ${isYes ? 'bg-2999BC border border-4CC1E5 text-white rounded-[50px]' : 'text-383838'}`}
        onClick={() => toggleState(true)}
      >
        Yes
      </button>
      <button
        type="button"
        className={`w-[73px] text-12 font-500 transition-colors duration-300 ${isYes ? 'text-383838 ' : 'bg-444 border border-565656 text-white rounded-[50px]'}`}
        onClick={() => toggleState(false)}
      >
        No
      </button>
    </div>
  );
}

export default PermissionToggleButton;
