
// import { useState, useEffect, useCallback } from 'react';

// const useFullscreen = () => {
//   const [isFullscreen, setIsFullscreen] = useState(false);

//   const openFullscreen = useCallback((element) => {
//     if (element) {
//       if (element.requestFullscreen) {
//         element.requestFullscreen();
//       } else if (element.mozRequestFullScreen) {
//         element.mozRequestFullScreen();
//       } else if (element.webkitRequestFullscreen) {
//         element.webkitRequestFullscreen();
//       } else if (element.msRequestFullscreen) {
//         element.msRequestFullscreen();
//       }
//       setIsFullscreen(true);
//     }
//   }, []);

//   const closeFullscreen = useCallback(() => {
//     if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
//       if (document.exitFullscreen) {
//         document.exitFullscreen();
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//       } else if (document.webkitExitFullscreen) {
//         document.webkitExitFullscreen();
//       } else if (document.msExitFullscreen) {
//         document.msExitFullscreen();
//       }
//       setIsFullscreen(false);
//     }
//   }, []);

//   const handleFullscreenChange = () => {
//     const isCurrentlyFullscreen = Boolean(
//       document.fullscreenElement ||
//       document.webkitFullscreenElement ||
//       document.mozFullScreenElement ||
//       document.msFullscreenElement
//     );
//     setIsFullscreen(isCurrentlyFullscreen);
//   };

//   useEffect(() => {
//     document.addEventListener('fullscreenchange', handleFullscreenChange);
//     document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
//     document.addEventListener('mozfullscreenchange', handleFullscreenChange);
//     document.addEventListener('MSFullscreenChange', handleFullscreenChange);

//     return () => {
//       document.removeEventListener('fullscreenchange', handleFullscreenChange);
//       document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
//       document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
//       document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
//     };
//   }, []);

//   return { isFullscreen, openFullscreen, closeFullscreen };
// };

// export default useFullscreen;


// import { useState } from 'react';

// const useFullscreenToggle = () => {
//   const [isFullscreen, setIsFullscreen] = useState(false);

//   const toggleFullscreen = (e) => {
//     const closestClassElement = e.target.closest('.tablemaindiv');
//     const mainElement = e.target.closest('#maincontent');

//     setIsFullscreen(prevState => !prevState);
//     closestClassElement.classList.toggle('tablescreenfull');
//     if (!isFullscreen) {
//       closestClassElement.style.marginLeft = mainElement.style.marginLeft;
//     } else {
//       closestClassElement.style.marginLeft = '0px';
//     }
//   };

//   return { isFullscreen, toggleFullscreen };
// };

// export default useFullscreenToggle;




import { useState, useEffect } from 'react';
import { useSidebar } from '../../components/SidebarContext';

const useFullscreenToggle = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isTableMainDivPresent, setIsTableMainDivPresent] = useState(false);
  // const { sideBarColl, setSideBarColl } = useSidebar();
  const sidebarContext = useSidebar();
  const sideBarColl = sidebarContext?.sideBarColl;
  const setSideBarColl = sidebarContext?.setSideBarColl;

  useEffect(() => {
    const checkForTableMainDiv = () => {
      const tableMainDiv = document.querySelector('.tablemaindiv');
      setIsTableMainDivPresent(!!tableMainDiv); 
    };

    checkForTableMainDiv();
  }, []);

  const toggleFullscreen = (e) => {
    const closestClassElement = e.target.closest('.tablemaindiv');
    const mainElement = e.target.closest('#maincontent');

    if (!closestClassElement) {
      return;
    }

    setIsFullscreen(prevState => !prevState);


    setSideBarColl(!sideBarColl)


    if (isFullscreen) {
      closestClassElement.classList.remove('tablescreenfull');
      closestClassElement.style.marginLeft = '0px';
      document.body.style.overflow = '';
    } else {
      closestClassElement.classList.add('tablescreenfull');
      closestClassElement.style.marginLeft = mainElement ? mainElement.style.marginLeft : '0px';
      document.body.style.overflow = 'hidden';
    }
  };

  return { isFullscreen, toggleFullscreen, isTableMainDivPresent };
};

export default useFullscreenToggle;
