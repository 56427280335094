import React, { useState } from "react";
import { useDispatch } from 'react-redux';

import { useParams } from "react-router-dom";
import {saveTeamMemberDataAsync} from "../../utils/customer/artifactSlice";
import {useFetchCustomerArtiFactDataListQuery } from "../../utils/customer/customerSelect";
import useRequireAuth from "../../utils/useRequireAuth";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";


const validationRules = {
  doc_type : {
    required: true,
    errorMessage: 'doc  Type is required.',
  },
  document: {
    required: true,
    errorMessage: 'document is required.',
  },
 
};
const DocumentModal = ({ isOpen, onClose,handleSubmitData }) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const customerId = parseInt(id);
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  
  const [fileName, setFileName] = useState('');
  const { token } = useRequireAuth();
  const { data: ArtiFactDataList, error: ArtiFactDataListerror, isLoading: ArtiFactDataListloading } = useFetchCustomerArtiFactDataListQuery({ token });
  const [documentUpload,setDocumentUpload]=useState(null);

  const [formErrors, setFormErrors] = useState({
    doc_type:'',
    document:''

  });
  const [formData, setFormData] = useState({
    doc_type:"",
    document:''
  });

  const { values, errors, touched, handleChange, handleBlur, validateField, isFormValid } = useCustomFormValidation(
    formData,
    validationRules
  );
  
  const handleFieldChange = (e) => {
    const { name, value, files } = e.target;
    handleChange(e);
    if (files && files.length > 0) {
        const file = files[0];
        setFileName(file.name);
        setFormData((prevFormData) => ({ ...prevFormData, document: file }));
    } else {
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
};




  const triggerFileUpload = () => {
    document.getElementById('hiddenFileInput').click();
  };



  
  const handleSubmit = async(event) => {
    event.preventDefault();  
    
    const formValid = isFormValid();
    if(!formValid){
      return;
    }
      const socialData = {
        doc_type: event.target.doc_type.value,
        file_name: formData.document,
        customer_id: customerId,
        company_id: parsedCompanyData.company_id,
        descriptions:event.target.descriptions.value
      };
     
      try {
        await dispatch(saveTeamMemberDataAsync({ socialData, token }));
        onClose();
        await handleSubmitData(customerId);
      } catch (error) {
        
      }
  };

  return (
    <div className="fixed inset-y-0 z-[999] grid h-screen place-items-center justify-start">
      <div className="relative  mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg rounded-md w-500px overflow-hidden no-scrollbar">
        <div className="flex items-center justify-between p-4 md:p-3 border-b border-BECBD3 rounded-t">
          <div className="px-2">
           
            <br className="mb-2" />
          </div>
          <br />
          <h3 className="text-2xl text-2C495D">Document</h3>
          <button
            onClick={onClose}
            type="button"
            className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <form onSubmit={handleSubmit} className="w-full lg:max-h-[70vh] overflow-x-hidden no-scrollbar">
          <div className="basis-5/5 mb-3 px-6 pt-2 ">
            <label
              htmlFor="Designation"
              className="block mb-2 text-434852 text-14 font-500 "
            >
              Doc Type <span className=" text-red-500"> *</span>
              {errors && <span className="text-red-700"> {errors.doc_type}</span>}
            </label>
            <select
              className={`form-select rounded-md border border-DFE2E5 text-383838 text-md  block w-full px-2.5 py-1`}
              id={`doc_type`}
              name="doc_type"
              onChange={handleFieldChange}
            >
             
               {ArtiFactDataList && ArtiFactDataList.map((CountryData) => (
                  <option
                    key={CountryData.id}
                    value={CountryData.type}
                   
                  >
                    {CountryData.type}
                  </option>
                ))}
            </select>
            {formErrors.doc_type && (
                <span className="text-red-500 text-xs">
                  Cannot be left blank.
                </span>
            )}
          </div>
          <div className="basis-5/5 px-6">
            <label
              htmlFor="chart_of_accounts_id"
              className="block mb-2 text-14 font-500 text-434852"
            >
              File <span className="text-red-500"> *</span>
              {errors && <span className="text-red-700"> {errors.document}</span>}
            </label>
            <div className="relative flex items-center w-full">
              <input
                placeholder=""
                type="text"
                value={fileName}
                 name="document"
                onChange={handleFieldChange}
                readOnly
                className="bg-white border rounded-md border-DFE2E5 text-gray-900 text-sm block w-full p-2.5 h-10 pr-12 cursor-pointer"
                onClick={triggerFileUpload}
              />
              <div className="absolute inset-y-0 right-0 flex items-center px-3 rounded bg-gray-300 cursor-pointer" onClick={triggerFileUpload}>
                <i className="bi bi-box-arrow-up text-[#545F65] font-bold text-20"></i>
              </div>
              <input
                type="file"
                id="hiddenFileInput"
                className="hidden"
                name="document"
               onChange={handleFieldChange}
              />
               {formErrors.document && (
                <span className="text-red-500 text-xs">
                  Cannot be left blank.
                </span>
            )}
            </div>
          </div>
          <div className="p-3 md:px-6">
            <label
              htmlFor="chart_of_accounts_id"
              className="block mb-2 text-sm text-434852"
            >
              Description
            </label>
            <textarea
              name="descriptions"
              onChange={handleFieldChange}
              placeholder="Type Something..."
              className="bg-white border rounded-md border-DFE2E5 text-gray-900 text-sm block w-full h-24 resize-none"
            />
          </div>
          <div className="flex justify-end py-4  modal-footer text-md w-full">
            <div className="px-6">
              <button
                onClick={onClose}
                type="button"
                className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-2 text-center rounded-sm"
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold text-gray-900 border border-gray-200 py-2 px-5 ms-3 rounded-sm"
              >
                SAVE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DocumentModal;
