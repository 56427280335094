

import { useState, useEffect } from 'react';
import { parseJSONValue } from '../../utils/json/parseJSONValue';

const useDropdown = (initialIsOpen, FieldValue) => {

  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [selectedOption, setSelectedOption] = useState(FieldValue);

  
  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleOptionChange = (option, color) => {
    console.warn(option,"sdfsdfsdf")
    setSelectedOption(option);
    setIsOpen(false);
  };

  return {
    isOpen,
    setIsOpen,
    selectedOption,
    toggleDropdown,
    handleOptionChange,
  };
};

export default useDropdown;
