import React, { useState } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import { saveReportTeamMemberDataAsync } from "../../utils/customer/reportingTeamSlice";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFetchCustomerDepartmentQuery } from "../../utils/customer/customerSelect";

import { useFetchCustomerUseListDataQuery } from "../../utils/customer/customerSelect";
import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import OwnerDropDown from "../GlobalFeature/Fields/OwnerDropDown";

import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";


const validationRules = {
  designation_id : {
    required: true,
    errorMessage: 'Department is required.',
  },
  reporting_person_email: {
    required: true,
    errorMessage: 'email is required.',
  },

  reporting_person_name: {
    required: true,
    errorMessage: 'name is required.',
  },
 
};

const ReportingTeamModal = ({ isOpen, onClose ,handleSubmitData}) => {
  const { token } = useRequireAuth();
  const { id } = useParams();
  const customerId = parseInt(id);
  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [isValidEmail,setIsValidEmail]=useState(true);
  const [selectedItem,setSelectedItem]=useState(null);
  const [contactName,setContactName]=useState(null);
  
 
const [formData, setFormData] = useState({
  designation_id:"",
  reporting_person_email:'',
  reporting_person_name:'',
});


const handlePersonData = (value) => {
 
  setFormData({
    reporting_person_email: value.email,
    reporting_person_name: value.name,
  });
  setSelectedItem(value.email);
 
};

const { values, errors, touched, handleChange, handleBlur, validateField, isFormValid } = useCustomFormValidation(
  formData,
  validationRules
);
 
const handleFieldChange = (e) => {
  const { name, value} = e.target;
  handleChange(e);
  setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
};

 
  const handleSave = async(event) => {
    event.preventDefault();
   
    const formValid = isFormValid();
    if(!formValid){
      return;
    }

      const socialData = {
        customer_id: customerId,
        designation_id: event.target.designation_id.value,
        reporting_person_name: event.target.reporting_person_name.value ,
        reporting_person_email: formData.reporting_person_email,
        status:event.target.status.checked?1:0,
        company_id: parsedCompanyData.company_id,
      };

    
   
      try {
        await dispatch(saveReportTeamMemberDataAsync({ socialData, token }));
        await handleSubmitData(customerId);
        onClose();
      } catch (error) {
        console.error("Error saving data:", error);
      }
    
  };

  const {
    data: designationList,
    error: designationListError,
    isLoading: designationListLoading,
  } = useFetchCustomerDepartmentQuery({ token });
  

 
 
  const {
    data: customerTaskUserList,
    error: customerTaskUserListerror,
    isLoading: customerTaskUserListLoading,
  } = useFetchCustomerUseListDataQuery({
    token,
  });

  
  const [
    fetchPersonInTable,
    { data: personTableData },
  ] = useLazyFetchPersonInTableQuery();

  const fetchPersonData = (data) => {
    fetchPersonInTable({token: token,data:data})
  }
  const tableDetail = {
    tableidclass: 'taskmodelowner'

  }

  const handleInsert=()=>{

  }




  return (
    <div className="fixed inset-y-0 z-[999] grid h-screen place-items-center justify-start">
      <div className="relative  py-3 mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md w-500px overflow-hidden no-scrollbar">
        <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
          <div className="px-2">
           
          </div>
          <br />
          <h3 className="text-2xl text-2C495D">Reporting Team</h3>

          <button
            onClick={onClose}
            type="button"
            className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <form
          onSubmit={handleSave}
          className="w-full lg:max-h-[70vh]  overflow-x-hidden  no-scrollbar"
        >
          <div className="basis-5/5 mb-3 px-6 pt-2 ">
            <label
              htmlFor="Designation"
              className="block mb-2 text-sm text-434852"
            >
              Department <span className=" text-red-500"> *</span>
              {errors && <span className="text-red-700"> {errors.designation_id}</span>}
            </label>
            <select
              id={`designation_id`}
              name="designation_id"
              onChange={handleFieldChange}
              className={`form-select w-6 border border-BFBFBF text-383838 text-md  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1`}
            >
              <option> --</option>
              {!designationList
                ? null
                : designationList.map((category) => (
                    <option value={category.department}>{category.department}</option>
                  ))}
            </select>
          </div>
          <div className="p-3 md:px-6">
            <label
              htmlFor="chart_of_accounts_id"
              className="block mb-2 text-sm text-434852"
            >
              Email <span className=" text-red-500"> *</span>
              {errors && formData.reporting_person_email==='' && <span className="text-red-700"> {errors.reporting_person_email}</span>}
            </label>

            <OwnerDropDown 
              tableidclass={tableDetail.tableidclass} 
              fetchPersonInTable={fetchPersonData}  
              DropData={personTableData} 
              handleInsert ={handleInsert}
              handlePersonData={handlePersonData}
            /> 
          </div>

          <div className="basis-5/5  px-6">
            <label
              htmlFor="chart_of_accounts_id"
              className="block mb-2 text-sm text-434852"
            >
              Name <span className=" text-red-500"> *</span>
              {errors && formData.reporting_person_name==='' && <span className="text-red-700"> {errors.reporting_person_name}</span>}
            </label>

            <input
              placeholder="Enter Name"
              type="text"
              id="reporting_person_name"
              name="reporting_person_name"
              value={formData.reporting_person_name}
              onChange={handleFieldChange}
              className="bg-white border border-DFE2E5 text-gray-900 text-sm block w-full h-10"
            />
          
           
          </div>

         

          <div className="basis-5/5 mb-3 px-6">
            <label
              htmlFor="chart_of_accounts_id"
              className="block mb-2 text-sm text-434852"
            >
              Choose An Action <span className=" text-red-500"> *</span>
            </label>

            <div className="flex justify-between">
              <div>Enable/Disable</div>
              <label class="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  id=""
                  name="status"
                  onChange={handleFieldChange}
                  className="bg-white border border-DFE2E5 text-gray-900 text-sm block w-full p-2.5 h-10 sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
              </label>
            </div>
          </div>

          <div className="flex justify-end py-4  modal-footer text-md w-full">
            <div className="px-6">
              <button
                onClick={onClose}
                type="button"
                className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-2 text-center rounded-sm"
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold text-gray-900 border border-gray-200 py-2 px-5 ms-3 rounded-sm"
              >
                SAVE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportingTeamModal;
