import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from "./userSlice";
import customerReport from "./customer/reportingTeamSlice";
import customerSocial from "./customer/socialLinkSlice";
import addressSlice from "./customer/addressSlice";
import contactPersons from "./customer/contactPersonsSlice";
import customerArtifact from "./customer/artifactSlice";
import customerServices from "./customer/servicesSlice"
import profileReducer from "./companyProfileSlice";


import { customerSelectApi ,customerEditDataApi ,customerNotesDataApi,customerTeamsDataApi,
  customerAddressDataApi,customerArtiFactDataApi,
  customerServicesDataApi,
  customerContactPersonDataApi,
  customerSocialLinkDataApi,
  customerReportingTeamDataApi,
  customerTaskDataApi,
  customerTasksTableDataApi,
  customerGetOtherDetailDataApi,
  customerNotesColorDataApi,
  customerManagerListDataApi,
  customerArtiFactApi,
  customerAddressDataBaseOnIdApi,
  customerDesignationApi,
  customeTaskUserList,
  customeTaskPOdList,
  customerEditTaskApi,
  customerServiceCategoryApi,
  customerSocialCategoryApi,
  customeAllTaskPOdList,
  companyAddressDataApi,
  getVendorDefaultAddress,
  customerDepartment
} from './customer/customerSelect';

import { customerTableShowUpdate,customerTableDropAdd,randomUser,custTableHeadFieldDynAddUp,custTableAddFieldHeader } from "./customer/customerTable";
import { customerHeaderApi } from "./customer/customerListHeader";
import customerNotesReducer from './customer/customerNotesSlice';
import customerTaskReduces from './customer/customerTaskSlice'
import vendorTeamSlice from "./vendor/vendorTeamSlice";
import customerTasksPodSlice from './customer/taskPodSlice'
import customerTaskEventReducer from './customer/customerTaskEventSlice'
import vendorSocialSlice from "./vendor/vendorSocialSlice";
import { companyListApi ,companyCommonDataApi } from "./company/companySelector";
import { customerTaskTableShowUpdate,
  TaskGetOrgAccording,
  customerTaskTableDropAdd,
  customerSocialShowUpdate,
  customerDocumentUpdate,
  customerReportingTeamUpdate,
  customerTaskDueTableShowUpdate,
  customerTaskCloseTableShowUpdate,
  customerContactTableShowUpdate,
  customerTaskPodTableShowUpdate,
  customerTaskActivityCount,
  companyTableShowUpdate,
  customerServiceTableShowUpdate,
  customerAddressTableShowUpdate,
  customerAllAddressTableShowUpdate,
  customerTaskHeaderApi,
  customerCompanyDataApi,
  customerAddressSingleDataApi,
  customerNotesTableDataApi,
  customerNotesDataTableShowUpdate,
  customerActivityDataTableShowUpdate,
  userDataTableShowUpdate,
  manageDataTableShowUpdate,
  dynamicDataTableShowUpdate,
  dynamicHeader,
  dynamicEditValue,
  getAllData,
  companyDelegation,
  customerTableDelegation,
  companyAddressTableShowUpdate,
  companyAddressSingleDataApi,
  vendorList,
  vendorSocial,
  vendorContact,
  vendorAllAddress,
  customerGroup,
  deleteTableCategoriesData
  

 } from "./customer/customerTaskTable";


 import {managePermissions,PermissionsAddUpdate,selectPermission,PermissionModule} from "./users/permissions"
 import {manageGroups} from "./users/groups"
 import {orgGroupAddUpdate,orgGroupAddUpdateMember} from "./users/Organizations"
import { EnumFieldGetApi } from "./enumApi";



 const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['customerTableShowUpdate','customerTableDropAdd','customerHeaderApi'], 
};

// const persistedReducer = persistReducer(persistConfig, combineReducers({
//   user: userReducer, 
//   profile:profileReducer,
//   customerReportingTeam: customerReport,
//   customerSocialLink: customerSocial,
//   customerAddresses: addressSlice,
//   contactPersons: contactPersons,
//   customerArtifact: customerArtifact,
//   customerServices: customerServices,
//   notes:customerNotesReducer,
//   tasks:customerTaskReduces,
//   activity:customerTaskEventReducer,
//   taskPod:customerTasksPodSlice,
//   vendors:vendorTeamSlice,
//   vendorSocial:vendorSocialSlice,
//   [customerSelectApi.reducerPath]: customerSelectApi.reducer,
//   [companyListApi.reducerPath]: companyListApi.reducer,  
//   [customerHeaderApi.reducerPath]: customerHeaderApi.reducer, 
//   [companyCommonDataApi.reducerPath]: companyCommonDataApi.reducer, 
//   [customerEditDataApi.reducerPath]: customerEditDataApi.reducer, 
//   [customerNotesDataApi.reducerPath]: customerNotesDataApi.reducer,
//   [customerTeamsDataApi.reducerPath]: customerTeamsDataApi.reducer, 
//   [customerAddressDataApi.reducerPath]: customerAddressDataApi.reducer, 
//   [customerArtiFactDataApi.reducerPath]: customerArtiFactDataApi.reducer, 
//   [customerServicesDataApi.reducerPath]: customerServicesDataApi.reducer, 
//   [customerContactPersonDataApi.reducerPath]: customerContactPersonDataApi.reducer, 
//   [customerSocialLinkDataApi.reducerPath]: customerSocialLinkDataApi.reducer, 
//   [customerReportingTeamDataApi.reducerPath]: customerReportingTeamDataApi.reducer,
//   [customerTaskDataApi.reducerPath]: customerTaskDataApi.reducer, 
//   [customerTableDropAdd.reducerPath]: customerTableDropAdd.reducer,
//   [custTableAddFieldHeader.reducerPath]: custTableAddFieldHeader.reducer,
//   [customerGetOtherDetailDataApi.reducerPath]: customerGetOtherDetailDataApi.reducer,
//   [customerNotesColorDataApi.reducerPath]: customerNotesColorDataApi.reducer,
//   [customerManagerListDataApi.reducerPath]: customerManagerListDataApi.reducer,
//   [customerArtiFactApi.reducerPath]: customerArtiFactApi.reducer,
//   [customerTableShowUpdate.reducerPath]: customerTableShowUpdate.reducer,
//   [customerAddressDataBaseOnIdApi.reducerPath]: customerAddressDataBaseOnIdApi.reducer,
//   [customerDesignationApi.reducerPath]:customerDesignationApi.reducer,
//   [customerTaskTableShowUpdate.reducerPath]:customerTaskTableShowUpdate.reducer,
//   [TaskGetOrgAccording.reducerPath]:TaskGetOrgAccording.reducer,

//   [EnumFieldGetApi.reducerPath]:EnumFieldGetApi.reducer,
  

//   [customerTaskTableDropAdd.reducerPath]:customerTaskTableDropAdd.reducer,
//   [customeTaskUserList.reducerPath]:customeTaskUserList.reducer,
//   [customeTaskPOdList.reducerPath]:customeTaskPOdList.reducer,
//   [customerEditTaskApi.reducerPath]:customerEditTaskApi.reducer,
//   [customerServiceCategoryApi.reducerPath]:customerServiceCategoryApi.reducer,
//   [customerSocialShowUpdate.reducerPath]:customerSocialShowUpdate.reducer,
//   [customerSocialCategoryApi.reducerPath]:customerSocialCategoryApi.reducer,
//   [customerDocumentUpdate.reducerPath]:customerDocumentUpdate.reducer,
//   [customerReportingTeamUpdate.reducerPath]:customerReportingTeamUpdate.reducer,
//   [customerTaskDueTableShowUpdate.reducerPath]:customerTaskDueTableShowUpdate.reducer,
//   [customerTaskCloseTableShowUpdate.reducerPath]:customerTaskCloseTableShowUpdate.reducer,
//   [customerContactTableShowUpdate.reducerPath]:customerContactTableShowUpdate.reducer,
//   [customerTaskPodTableShowUpdate.reducerPath]:customerTaskPodTableShowUpdate.reducer,
//   [customerTaskActivityCount.reducerPath]:customerTaskActivityCount.reducer,
//   [companyTableShowUpdate.reducerPath]:companyTableShowUpdate.reducer,
//   [customerServiceTableShowUpdate.reducerPath]:customerServiceTableShowUpdate.reducer,
//   [customerAddressTableShowUpdate.reducerPath]:customerAddressTableShowUpdate.reducer,
//   [customerAllAddressTableShowUpdate.reducerPath]:customerAllAddressTableShowUpdate.reducer,
//   [customeAllTaskPOdList.reducerPath]:customeAllTaskPOdList.reducer,
//   [customerTaskHeaderApi.reducerPath]:customerTaskHeaderApi.reducer,
//   [customerCompanyDataApi.reducerPath]:customerCompanyDataApi.reducer,
//   [customerAddressSingleDataApi.reducerPath]:customerAddressSingleDataApi.reducer,
//   [customerNotesTableDataApi.reducerPath]:customerNotesTableDataApi.reducer,
//   [customerNotesDataTableShowUpdate.reducerPath]:customerNotesDataTableShowUpdate.reducer,
//   [customerActivityDataTableShowUpdate.reducerPath]:customerActivityDataTableShowUpdate.reducer,
//   [userDataTableShowUpdate.reducerPath]:userDataTableShowUpdate.reducer,
//   [manageDataTableShowUpdate.reducerPath]:manageDataTableShowUpdate.reducer,
//   [dynamicDataTableShowUpdate.reducerPath]:dynamicDataTableShowUpdate.reducer,
//   [dynamicHeader.reducerPath]:dynamicHeader.reducer,
//   [dynamicEditValue.reducerPath]:dynamicEditValue.reducer,
//   [getAllData.reducerPath]:getAllData.reducer,
//   [managePermissions.reducerPath]:managePermissions.reducer,
//   [PermissionsAddUpdate.reducerPath]:PermissionsAddUpdate.reducer,
//   [selectPermission.reducerPath]:selectPermission.reducer,
//   [PermissionModule.reducerPath]:PermissionModule.reducer,
//   [manageGroups.reducerPath]:manageGroups.reducer,
//   [orgGroupAddUpdate.reducerPath]:orgGroupAddUpdate.reducer,
//   [orgGroupAddUpdateMember.reducerPath]:orgGroupAddUpdateMember.reducer,
//   [companyDelegation.reducerPath]:companyDelegation.reducer,
//   [customerTableDelegation.reducerPath]:customerTableDelegation.reducer,
//   [companyAddressDataApi.reducerPath]:companyAddressDataApi.reducer,
//   [companyAddressTableShowUpdate.reducerPath]:companyAddressTableShowUpdate.reducer,
//   [companyAddressSingleDataApi.reducerPath]:companyAddressSingleDataApi.reducer,
//   [vendorList.reducerPath]:vendorList.reducer,
//   [vendorSocial.reducerPath]:vendorSocial.reducer,
//   [vendorContact.reducerPath]:vendorContact.reducer,
//   [vendorAllAddress.reducerPath]:vendorAllAddress.reducer,
//   [getVendorDefaultAddress.reducerPath]:getVendorDefaultAddress.reducer,
//   [customerDepartment.reducerPath]:customerDepartment.reducer,
//   [customerGroup.reducerPath]:customerGroup.reducer,
//   [deleteTableCategoriesData.reducerPath]:deleteTableCategoriesData.reducer


 
//   // Add other reducers here
// }));
const allReducers = combineReducers({
  user: userReducer, 
  profile:profileReducer,
  customerReportingTeam: customerReport,
  customerSocialLink: customerSocial,
  customerAddresses: addressSlice,
  contactPersons: contactPersons,
  customerArtifact: customerArtifact,
  customerServices: customerServices,
  notes:customerNotesReducer,
  tasks:customerTaskReduces,
  activity:customerTaskEventReducer,
  taskPod:customerTasksPodSlice,
  vendors:vendorTeamSlice,
  vendorSocial:vendorSocialSlice,
  [customerSelectApi.reducerPath]: customerSelectApi.reducer,
  [companyListApi.reducerPath]: companyListApi.reducer,  
  [customerHeaderApi.reducerPath]: customerHeaderApi.reducer, 
  [companyCommonDataApi.reducerPath]: companyCommonDataApi.reducer, 
  [customerEditDataApi.reducerPath]: customerEditDataApi.reducer, 
  [customerNotesDataApi.reducerPath]: customerNotesDataApi.reducer,
  [customerTeamsDataApi.reducerPath]: customerTeamsDataApi.reducer, 
  [customerAddressDataApi.reducerPath]: customerAddressDataApi.reducer, 
  [customerArtiFactDataApi.reducerPath]: customerArtiFactDataApi.reducer, 
  [customerServicesDataApi.reducerPath]: customerServicesDataApi.reducer, 
  [customerContactPersonDataApi.reducerPath]: customerContactPersonDataApi.reducer, 
  [customerSocialLinkDataApi.reducerPath]: customerSocialLinkDataApi.reducer, 
  [customerReportingTeamDataApi.reducerPath]: customerReportingTeamDataApi.reducer,
  [customerTaskDataApi.reducerPath]: customerTaskDataApi.reducer, 
  [customerTableDropAdd.reducerPath]: customerTableDropAdd.reducer,
  [randomUser.reducerPath]: randomUser.reducer,
  [custTableAddFieldHeader.reducerPath]: custTableAddFieldHeader.reducer,
  [customerGetOtherDetailDataApi.reducerPath]: customerGetOtherDetailDataApi.reducer,
  [customerNotesColorDataApi.reducerPath]: customerNotesColorDataApi.reducer,
  [customerManagerListDataApi.reducerPath]: customerManagerListDataApi.reducer,
  [customerArtiFactApi.reducerPath]: customerArtiFactApi.reducer,
  [customerTableShowUpdate.reducerPath]: customerTableShowUpdate.reducer,
  [customerAddressDataBaseOnIdApi.reducerPath]: customerAddressDataBaseOnIdApi.reducer,
  [customerDesignationApi.reducerPath]:customerDesignationApi.reducer,
  [customerTaskTableShowUpdate.reducerPath]:customerTaskTableShowUpdate.reducer,
  [TaskGetOrgAccording.reducerPath]:TaskGetOrgAccording.reducer,

  [EnumFieldGetApi.reducerPath]:EnumFieldGetApi.reducer,
  

  [customerTaskTableDropAdd.reducerPath]:customerTaskTableDropAdd.reducer,
  [customeTaskUserList.reducerPath]:customeTaskUserList.reducer,
  [customeTaskPOdList.reducerPath]:customeTaskPOdList.reducer,
  [customerEditTaskApi.reducerPath]:customerEditTaskApi.reducer,
  [customerServiceCategoryApi.reducerPath]:customerServiceCategoryApi.reducer,
  [customerSocialShowUpdate.reducerPath]:customerSocialShowUpdate.reducer,
  [customerSocialCategoryApi.reducerPath]:customerSocialCategoryApi.reducer,
  [customerDocumentUpdate.reducerPath]:customerDocumentUpdate.reducer,
  [customerReportingTeamUpdate.reducerPath]:customerReportingTeamUpdate.reducer,
  [customerTaskDueTableShowUpdate.reducerPath]:customerTaskDueTableShowUpdate.reducer,
  [customerTaskCloseTableShowUpdate.reducerPath]:customerTaskCloseTableShowUpdate.reducer,
  [customerContactTableShowUpdate.reducerPath]:customerContactTableShowUpdate.reducer,
  [customerTaskPodTableShowUpdate.reducerPath]:customerTaskPodTableShowUpdate.reducer,
  [customerTaskActivityCount.reducerPath]:customerTaskActivityCount.reducer,
  [companyTableShowUpdate.reducerPath]:companyTableShowUpdate.reducer,
  [customerServiceTableShowUpdate.reducerPath]:customerServiceTableShowUpdate.reducer,
  [customerAddressTableShowUpdate.reducerPath]:customerAddressTableShowUpdate.reducer,
  [customerAllAddressTableShowUpdate.reducerPath]:customerAllAddressTableShowUpdate.reducer,
  [customeAllTaskPOdList.reducerPath]:customeAllTaskPOdList.reducer,
  [customerTaskHeaderApi.reducerPath]:customerTaskHeaderApi.reducer,
  [customerCompanyDataApi.reducerPath]:customerCompanyDataApi.reducer,
  [customerAddressSingleDataApi.reducerPath]:customerAddressSingleDataApi.reducer,
  [customerNotesTableDataApi.reducerPath]:customerNotesTableDataApi.reducer,
  [customerNotesDataTableShowUpdate.reducerPath]:customerNotesDataTableShowUpdate.reducer,
  [customerActivityDataTableShowUpdate.reducerPath]:customerActivityDataTableShowUpdate.reducer,
  [userDataTableShowUpdate.reducerPath]:userDataTableShowUpdate.reducer,
  [manageDataTableShowUpdate.reducerPath]:manageDataTableShowUpdate.reducer,
  [dynamicDataTableShowUpdate.reducerPath]:dynamicDataTableShowUpdate.reducer,
  [dynamicHeader.reducerPath]:dynamicHeader.reducer,
  [dynamicEditValue.reducerPath]:dynamicEditValue.reducer,
  [getAllData.reducerPath]:getAllData.reducer,
  [managePermissions.reducerPath]:managePermissions.reducer,
  [PermissionsAddUpdate.reducerPath]:PermissionsAddUpdate.reducer,
  [selectPermission.reducerPath]:selectPermission.reducer,
  [PermissionModule.reducerPath]:PermissionModule.reducer,
  [manageGroups.reducerPath]:manageGroups.reducer,
  [orgGroupAddUpdate.reducerPath]:orgGroupAddUpdate.reducer,
  [orgGroupAddUpdateMember.reducerPath]:orgGroupAddUpdateMember.reducer,
  [companyDelegation.reducerPath]:companyDelegation.reducer,
  [customerTableDelegation.reducerPath]:customerTableDelegation.reducer,
  [companyAddressDataApi.reducerPath]:companyAddressDataApi.reducer,
  [companyAddressTableShowUpdate.reducerPath]:companyAddressTableShowUpdate.reducer,
  [companyAddressSingleDataApi.reducerPath]:companyAddressSingleDataApi.reducer,
  [vendorList.reducerPath]:vendorList.reducer,
  [vendorSocial.reducerPath]:vendorSocial.reducer,
  [vendorContact.reducerPath]:vendorContact.reducer,
  [vendorAllAddress.reducerPath]:vendorAllAddress.reducer,
  [getVendorDefaultAddress.reducerPath]:getVendorDefaultAddress.reducer,
  [customerDepartment.reducerPath]:customerDepartment.reducer,
  [customerGroup.reducerPath]:customerGroup.reducer,
  [deleteTableCategoriesData.reducerPath]:deleteTableCategoriesData.reducer


 
  // Add other reducers here
})



const appStore = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(
      {
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }
    )
      .concat(customerSelectApi.middleware)
      .concat(customerHeaderApi.middleware)
      .concat(companyListApi.middleware)
      .concat(companyCommonDataApi.middleware)
      .concat(customerEditDataApi.middleware)
      .concat(customerNotesDataApi.middleware)
      .concat(customerTeamsDataApi.middleware)
      .concat(customerAddressDataApi.middleware)
      .concat(customerArtiFactDataApi.middleware)
      .concat(customerServicesDataApi.middleware)
      .concat(customerContactPersonDataApi.middleware)
      .concat(customerSocialLinkDataApi.middleware)
      .concat(customerReportingTeamDataApi.middleware)
      .concat(customerTaskDataApi.middleware)
      .concat(customerTableShowUpdate.middleware)
      .concat(customerTableDropAdd.middleware)
      .concat(randomUser.middleware)
      .concat(custTableAddFieldHeader.middleware)
      .concat(customerGetOtherDetailDataApi.middleware)
      .concat(customerNotesColorDataApi.middleware)
      .concat(customerManagerListDataApi.middleware)
      .concat(customerArtiFactApi.middleware)
      .concat(customerAddressDataBaseOnIdApi.middleware)
      .concat(customerDesignationApi.middleware)
      .concat(customerTaskTableShowUpdate.middleware)
      .concat(TaskGetOrgAccording.middleware)
      .concat(customerTaskTableDropAdd.middleware)
      .concat(customeTaskUserList.middleware)
      
      .concat(EnumFieldGetApi.middleware)

      .concat(customeTaskPOdList.middleware)
      .concat(customerEditTaskApi.middleware)
      .concat(customerSocialShowUpdate.middleware)
      .concat(customerSocialCategoryApi.middleware)
      .concat(customerDocumentUpdate.middleware)
      .concat(customerReportingTeamUpdate.middleware)
      .concat(customerTaskDueTableShowUpdate.middleware)
      .concat(customerTaskCloseTableShowUpdate.middleware)
      .concat(customerContactTableShowUpdate.middleware)
      .concat(customerTaskPodTableShowUpdate.middleware)
      .concat(customerTaskActivityCount.middleware)
      .concat(companyTableShowUpdate.middleware)
      .concat(customerServiceTableShowUpdate.middleware)
      .concat(customerAddressTableShowUpdate.middleware)
      .concat(customerAllAddressTableShowUpdate.middleware)
      .concat(customeAllTaskPOdList.middleware)
      .concat(customerTaskHeaderApi.middleware)
      .concat(customerCompanyDataApi.middleware)
      .concat(customerAddressSingleDataApi.middleware)
      .concat(customerNotesTableDataApi.middleware)
      .concat(customerNotesDataTableShowUpdate.middleware)
      .concat(customerActivityDataTableShowUpdate.middleware)
      .concat(userDataTableShowUpdate.middleware)
      .concat(manageDataTableShowUpdate.middleware)
      .concat(dynamicDataTableShowUpdate.middleware)
      .concat(dynamicHeader.middleware)
      .concat(dynamicEditValue.middleware)
      .concat(getAllData.middleware)
      .concat(customerServiceCategoryApi.middleware)
      .concat(managePermissions.middleware)
      .concat(PermissionsAddUpdate.middleware)
      .concat(selectPermission.middleware)
      .concat(PermissionModule.middleware)
      .concat(manageGroups.middleware)
      .concat(orgGroupAddUpdate.middleware)
      .concat(orgGroupAddUpdateMember.middleware)
      .concat(companyDelegation.middleware)
      .concat(customerTableDelegation.middleware)
      .concat(companyAddressDataApi.middleware)
      .concat(companyAddressTableShowUpdate.middleware)
      .concat(companyAddressSingleDataApi.middleware)
      .concat(vendorList.middleware)
      .concat(vendorSocial.middleware)
      .concat(vendorContact.middleware)
      .concat(vendorAllAddress.middleware)
      .concat(getVendorDefaultAddress.middleware)
      .concat(customerDepartment.middleware)
      .concat(customerGroup.middleware)



});

setupListeners(appStore.dispatch);

// const persistor = persistStore(appStore);
const persistor = appStore;


export {appStore,persistor};