import React, { Fragment, useState } from 'react';
 
export const ClientBillingCard = ({ isOpen, onToggle, handleRouteSection }) => {
    const [isActive, setIsActive] = useState(false);
 
    const handleSvgClick = (e) => {
        e.stopPropagation(); // Prevent click event from bubbling up to the parent div
        setIsActive(!isActive);
        onToggle(); // Toggle content visibility
    };
 
    return (
        <div className="relative bg-1d44691a border border-1D446933 mb-3 mt-3 cursor-pointer rounded" onClick={handleRouteSection}>
            <div className="flex justify-between items-center px-3 py-2">
                <h4 className="text-2C495D font-normal h-[24] w-[267] gap-133 cursor-pointer">Billing</h4> 
                <button
          onClick={(e) => { e.stopPropagation(); onToggle(); }}
          // className={`flex items-center justify-center  rounded 
          //   ${isOpen ? 'bg-white  border border-gray-500' : 'hover:bg-gray-300 hover:border-gray-400 '}`}
          className={`relative flex w-[20px] h-[20px] p-3 justify-center items-center flex-shrink-0 transition-all duration-200 ease-in-out hover:bg-white hover:border-white
            ${isOpen ? '' : ' border-none hover:bg-white hover:border-2 hover:border-white'}`}
        >
          {isOpen ? (
            <i className={`bi  bi-${isOpen ? "chevron-up" : "chevron-down" } `}></i>
          ) : (
            <i className={`bi bi-${isOpen ? "chevron-up" : "chevron-down" } `}></i>
          )}
        </button>
            </div>
 
            {isOpen && (
                <Fragment>
                    {/* Content here if needed */}
                </Fragment>
            )}
        </div>
    );
};