export const parseJSONFields = (data) => {
    const parsedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        try {
          parsedData[key] = JSON.parse(data[key]);
          if(parsedData[key]?.value)
            parsedData[key] = JSON.parse(data[key])?.value;
        } catch (e) {
          parsedData[key] = data[key];
        }
      }
    }
    return parsedData;
  };