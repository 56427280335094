
// import React, { Fragment, useState, useEffect } from 'react';
// import SaveEditFirst from '../SaveEdit/SaveEditFirst';
// import useDropdownPosition from '../../../hooks/Dropdown/useDropDownPosition';
// import { parseJSONValue } from '../../../utils/json/parseJSONValue';
// import useClickOutside from '../../../hooks/Screen/useClickOutside';

// const MultipleSelectDropdown = ({ tableidclass, setDataInputRow, DropData = [], initialIsOpen, UpdateTableField, Item }) => {
//   const parsedDropData = Array.isArray(parseJSONValue(DropData)) ? parseJSONValue(DropData) : [];
 

//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [isOpen, setIsOpen] = useState(initialIsOpen);
//   const [tooltipVisible, setTooltipVisible] = useState(false);

//   // useEffect(() => {
//   //   if (Array.isArray(options)) {
//   //     const transformedOptions = options.map((option) => ({ value: option.value }));
//   //     setSelectedOptions(transformedOptions);
//   //   }
//   // }, [options]);

//   // useEffect(() => {
//   //   if (Item && Item?.value) {
//   //       const parsedValue = parseJSONValue(Item);
//   // console.warn(parsedValue,"parsedDropData")

//   //       if (Array.isArray(parsedValue?.value)) {
//   //         const transformedOptions = parsedValue.value.map((option) => ({ value: option }));
//   //         setSelectedOptions(transformedOptions);
//   //       }
//   //   }
//   // }, [Item]);


//   useEffect(() => {
//     if (Item && Item?.value) {
//       const parsedValue = parseJSONValue(Item);
//       console.warn(parsedValue, "parsedDropData");
  
//       // Check if parsedValue.value is a string and split it into an array
//       if (typeof parsedValue?.value === 'string') {
//         const valuesArray = parsedValue.value.split(';').map(value => value.trim());
//         const transformedOptions = valuesArray.map(option => ({ value: option }));
//         setSelectedOptions(transformedOptions);
//       }
//     }
//   }, [Item]);
  

//   const { dropdownPosition, dropdownRef } = useDropdownPosition(isOpen, tableidclass);

//   const toggleDropdown = () => setIsOpen(!isOpen);

//   const handleSelectOption = (option) => {
//     let updatedSelectedOptions;
//     if (selectedOptions.some(selected => selected?.value === option?.value)) {
//       updatedSelectedOptions = selectedOptions.filter(selected => selected?.value !== option?.value);
//     } else {
//       updatedSelectedOptions = [...selectedOptions, option];
//     }

//     setSelectedOptions(updatedSelectedOptions);
//   };

//   const isOptionSelected = (option) => {
//     return selectedOptions.some(selected => selected?.value === option?.value);
//   };

//   const displayedOptions = selectedOptions.slice(0, 2);
//   const remainingOptions = selectedOptions.slice(2);
//   const remainingCount = selectedOptions.length - displayedOptions.length;

//   useEffect(() => {
//     //console.log('Selected options:', selectedOptions);
//   }, [selectedOptions]);


//   const closeDropdownOutsideClick = useClickOutside(() => {
//     setIsOpen(false);
//     setDataInputRow(null);
//   });

//   return (
//     <Fragment>
//       <div ref={closeDropdownOutsideClick}>

//       <div className="relative inline-block text-left" ref={dropdownRef}>
//         {tooltipVisible && (
//           <div className="absolute left-0 top-[40px] mb-2 w-auto p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-[9999999]">
//             {remainingOptions?.map(option => option?.value)?.join('; ')}
//           </div>
//         )}
//         <div>
//           <button
//             type="button"
//             onClick={toggleDropdown}
//             className="inline-flex items-center justify-between w-full h-[33px] rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-xs text-gray-700 hover:bg-gray-50 focus:outline-none"
//           >
//             {selectedOptions.length === 0 ? (
//               'Select options'
//             ) : (
//               <div>
//                 {displayedOptions?.map(option => option?.value)?.join('; ')}
//                 {remainingCount > 0 && (
//                   <Fragment>
//                     <span>; </span>
//                     <span
//                       className='border-2 rounded px-1'
//                       onMouseEnter={() => setTooltipVisible(true)}
//                       onMouseLeave={() => setTooltipVisible(false)}
//                     >
//                       +{remainingCount}
//                     </span>
//                   </Fragment>
//                 )}
//               </div>
//             )}
//             <svg
//               className="ml-2 h-5 w-5"
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 20 20"
//               fill="currentColor"
//               aria-hidden="true"
//             >
//               <path
//                 fillRule="evenodd"
//                 d="M5.292 7.292a1 1 0 011.414 0L10 10.586l3.293-3.294a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//                 clipRule="evenodd"
//               />
//             </svg>
//           </button>
//         </div>

//         {isOpen && (
//           <div
//             className={`absolute mt-1 w-full rounded-md bg-white shadow-lg z-10 ${dropdownPosition === 'bottom' ? 'top-full mt-2' : 'bottom-full mb-2'}`}
//             onBlur={() => setIsOpen(false)}
//             tabIndex={0}
//           >
//             <ul className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
//               {parsedDropData && parsedDropData?.map((option) => (
//                 <li
//                   key={option?.value}
//                   className={`cursor-pointer select-none relative py-2 pl-10 pr-4 ${
//                     isOptionSelected(option) ? 'text-white bg-1D4469 border' : 'text-gray-900'
//                   }`}
//                   onClick={() => handleSelectOption(option)}
//                 >
//                   <span
//                     className={`block truncate ${
//                       isOptionSelected(option) ? 'font-semibold' : 'font-normal'
//                     }`}
//                   >
//                     {option?.value}
//                   </span>
//                   {isOptionSelected(option) && (
//                     <span className="absolute inset-y-0 left-0 flex items-center pl-3">
//                       <svg
//                         className="h-5 w-5 text-white"
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 20 20"
//                         fill="currentColor"
//                         aria-hidden="true"
//                       >
//                         <path
//                           fillRule="evenodd"
//                           d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                     </span>
//                   )}
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}

//         <SaveEditFirst
//           UpdateTableField={(e) => { UpdateTableField(e, selectedOptions, Item); }}
//           handleCloseClick={(e) => {
//             e.stopPropagation();
//             setDataInputRow(null);
//           }}
//         />
//       </div>

//       </div>

//     </Fragment>
//   );
// };

// export default MultipleSelectDropdown;






import React, { Fragment, useState, useMemo, useEffect, useRef } from 'react';
import SaveEditFirst from '../SaveEdit/SaveEditFirst';
import useDropdownPosition from '../../../hooks/Dropdown/useDropDownPosition';
import { parseJSONValue } from '../../../utils/json/parseJSONValue';
import { ClickAwayListener, ListItemText, Checkbox, TextField, InputAdornment, Popper, ListSubheader, Paper, MenuItem, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const containsText = (text, searchText) =>
  text.toLowerCase().includes(searchText.toLowerCase());

const MultipleSelectDropdown = ({ tableidclass, setDataInputRow, DropData = [], initialIsOpen, UpdateTableField, Item }) => {
  const parsedDropData = Array.isArray(parseJSONValue(DropData)) ? parseJSONValue(DropData) : [];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (Item && Item?.value) {
      const parsedValue = parseJSONValue(Item);
      if (typeof parsedValue?.value === 'string') {
        const valuesArray = parsedValue.value.split(';').map(value => value.trim());
        const transformedOptions = valuesArray.map(option => ({ value: option }));
        setSelectedOptions(transformedOptions);
      }
    }
  }, [Item]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        searchInputRef?.current?.focus();
      }, 0);
    }
  }, [isOpen]);

  const handleSelectOption = (option) => {
    let updatedSelectedOptions;
    if (selectedOptions.some(selected => selected?.value === option?.value)) {
      updatedSelectedOptions = selectedOptions.filter(selected => selected?.value !== option?.value);
    } else {
      updatedSelectedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedSelectedOptions);
  };

  const isOptionSelected = (option) => {
    return selectedOptions.some(selected => selected?.value === option?.value);
  };

  const displayedOptions = useMemo(() =>
    parsedDropData.filter(option =>
      containsText(option.value, searchText)
    ), [searchText, parsedDropData]);

  const closeDropdownOutsideClick = () => {
    setIsOpen(false);
    setDataInputRow(null);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={closeDropdownOutsideClick}>
        <div className="relative inline-block text-left " ref={anchorRef}>
          {/* Trigger for Dropdown */}
          <div
            className="flex items-center justify-between w-full h-[28px] text-12 border rounded  cursor-pointer"
            onClick={() => setIsOpen(prev => !prev)}
            style={{
              minWidth: '300px',
              maxWidth: '190px',
              overflowX: 'auto',
              fontSize: '12',// Adjust trigger font size (14px)
            }}
          >
            <span className='ml-5 '>{selectedOptions.length > 0 ? selectedOptions.map(option => option.value).join('; ') : 'Select options'}</span>
            <IconButton size="small">
              <ArrowDropDownIcon />
            </IconButton>
          </div>

          {/* Popper for Dropdown */}
          <Popper open={isOpen} anchorEl={anchorRef.current} placement="bottom-start" style={{ zIndex: 10 }}>
            <Paper
              style={{
                width: anchorRef.current ? anchorRef.current.clientWidth : undefined,
                maxHeight: 206, // Set max height for dropdown
                overflowY: 'auto',
                maxWidth: 350,
                marginTop:4
              }}
            >
              {/* Search Bar */}
              <ListSubheader>
                    <MenuItem 
                      sx={{
                        backgroundColor: 'transparent', // Set default background color to transparent
                        '&:hover': {
                          backgroundColor: 'transparent', // Remove background color on hover
                          cursor: 'default', // Optional: Change cursor to default to indicate non-interactive state
                        },
                        '&:active': {
                          backgroundColor: 'transparent', // Remove background color on click
                        },
                        // Optionally, remove other styles like padding if needed
                        padding: 0,
                      }}
                    >
                  <TextField className='bg-white'
                      size="small"
                      placeholder="Type to search..."
                      fullWidth
                      sx={{
                        width: '350', // Adjust width
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            // border: 'none', // Remove border
                          },
                          '& input': {
                            padding: '0px', // Remove padding from input
                            paddingLeft: '0px', // Remove left padding specifically
                            boxShadow: 'none', // Remove any box shadow
                            border: 'none', // Remove border
                          },
                        },
                        '& .MuiInputBase-root': {
                          fontSize: '11px', // Adjust font size
                          height: 28, 
                          width: 250,
                          padding: 0, // Ensure no extra padding around the input
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className='bg-white' position="start" sx={{ marginRight: '2px' }}>
                            <SearchIcon className='ml-1 bg-white'/> {/* Adjust margin as needed */}
                          </InputAdornment>
                        ),
                      }}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      inputRef={searchInputRef}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />

                </MenuItem>
              </ListSubheader>

              {/* Menu Items */}
              {displayedOptions.length === 0 ? (
                <MenuItem disabled>No results found</MenuItem>
              ) : (
                displayedOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => handleSelectOption(option)}
                    style={{
                      minHeight: '30px', // Adjust height of each menu item
                      padding: '5px 10px', // Adjust padding for a more compact look
                    }}
                  >
                    <Checkbox
                      checked={isOptionSelected(option)}
                      style={{
                        padding: '0 8px', // Reduce checkbox padding
                        transform: 'scale(0.8)', // Reduce checkbox size
                      }}
                    />
                    <ListItemText
                      primary={option.value}
                      primaryTypographyProps={{
                        style: {
                          fontSize: '0.75rem', // Reduce font size for options (12px)
                        },
                      }}
                    />
                  </MenuItem>
                ))
              )}
            </Paper>
          </Popper>

          {/* Save/Close Button */}
          <SaveEditFirst
            UpdateTableField={(e) => { UpdateTableField(e, selectedOptions, Item); }}
            handleCloseClick={(e) => {
              e.stopPropagation();
              setDataInputRow(null);
            }}
          />
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};



















// export default MultipleSelectDropdown;

// import React, { Fragment, useState, useEffect } from 'react';
// import SaveEditFirst from '../SaveEdit/SaveEditFirst';
// import useDropdownPosition from '../../../hooks/Dropdown/useDropDownPosition';
// import { parseJSONValue } from '../../../utils/json/parseJSONValue';
// import useClickOutside from '../../../hooks/Screen/useClickOutside';
// import { Autocomplete, Checkbox, TextField, Popper, ClickAwayListener, ListItemText } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// const CustomPopper = (props) => {
//   const { dropdownPosition, ...other } = props;
//   return (
//     <Popper
//       {...other}
//       modifiers={{
//         offset: {
//           enabled: true,
//           offset: dropdownPosition === 'bottom' ? '0, 10' : '0, -10',
//         },
//       }}
//       className={`absolute z-10 ${dropdownPosition === 'bottom' ? 'mt-2' : 'mb-2'}`}
//     />
//   );
// };

// const MultipleSelectDropdown = ({ tableidclass, setDataInputRow, DropData = [], initialIsOpen, UpdateTableField, Item }) => {
//   const parsedDropData = Array.isArray(parseJSONValue(DropData)) ? parseJSONValue(DropData) : [];
  
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [isOpen, setIsOpen] = useState(initialIsOpen);

//   useEffect(() => {
//     if (Item && Item?.value) {
//       const parsedValue = parseJSONValue(Item);
//       if (typeof parsedValue?.value === 'string') {
//         const valuesArray = parsedValue.value.split(';').map(value => value.trim());
//         const transformedOptions = valuesArray.map(option => ({ value: option }));
//         setSelectedOptions(transformedOptions);
//       }
//     }
//   }, [Item]);

//   const { dropdownPosition, dropdownRef } = useDropdownPosition(isOpen, tableidclass);

//   const handleSelectOption = (event, newValue) => {
//     setSelectedOptions(newValue);
//   };

//   const isOptionSelected = (option) => {
//     return selectedOptions.some(selected => selected?.value === option?.value);
//   };

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter' && dropdownRef.current) {
//       const highlightedOption = dropdownRef.current.querySelector('.Mui-focused');
//       if (highlightedOption) {
//         const optionValue = highlightedOption.getAttribute('data-value');
//         if (optionValue) {
//           const option = parsedDropData.find(opt => opt.value === optionValue);
//           if (option) {
//             setSelectedOptions(prevOptions => [...prevOptions, option]);
//           }
//           event.preventDefault(); // Prevent default action to avoid form submission or other default behavior
//         }
//       }
//     }
//   };

//   const closeDropdownOutsideClick = () => {
//     setIsOpen(false);
//     setDataInputRow(null);
//   };

//   return (
//     <Fragment>
//       <ClickAwayListener onClickAway={closeDropdownOutsideClick}>
//         <div className="relative inline-block text-left" ref={dropdownRef}>
//           {/* <Autocomplete
//             multiple
//             options={parsedDropData || []}
//             getOptionLabel={(option) => option?.value || ''}
//             value={selectedOptions}
//             onChange={handleSelectOption}
//             open={isOpen}
//             onOpen={() => setIsOpen(true)}
//             onClose={() => setIsOpen(false)}
//             disableCloseOnSelect
//             onKeyDown={handleKeyDown} // Add key down handler
//             PopperComponent={(props) => <CustomPopper {...props} dropdownPosition={dropdownPosition} />}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 variant="outlined"
//                 size="small"
//                 onClick={() => setIsOpen(!isOpen)}
//                 placeholder="Select options"
//                 style={{ 
//                   width: '200px',  // Adjust width
//                   fontSize: '11px', 
//                 }}
//                 InputProps={{
//                   ...params.InputProps,
//                   style: {
//                     fontSize: '11px', // Decrease font size for input field
//                     border: 'none', // Remove the inner border
//                     boxShadow: 'none', // Remove the inner box shadow
//                   },
//                 }}
//                 inputProps={{
//                   ...params.inputProps,
//                   style: {
//                     fontSize: '11px', // Decrease font size for the typed text
//                     border: 'none', // Remove the inner border
//                     boxShadow: 'none', // Remove the inner box shadow
//                   },
//                 }}
//               />
//             )}
//             renderOption={(props, option, { selected }) => (
//               <li {...props} key={option?.value} style={{ fontSize: '11px' }} data-value={option.value}>
//                 <Checkbox 
//                   checked={selected || isOptionSelected(option)}
//                   style={{ fontSize: '11px' }}
//                 />
//                 <ListItemText primary={option?.value} style={{ fontSize: '11px' }} />
//               </li>
//             )}
//             renderTags={(selected) => selected.map(option => option?.value).join('; ')}
//             style={{ width: '200px', fontSize: '11px' }} // Adjust width and font size for tags
//           /> */}
//           <Autocomplete
//           multiple
//           options={parsedDropData || []}
//           getOptionLabel={(option) => option?.value || ''}
//           value={selectedOptions}
//           onChange={handleSelectOption}
//           open={isOpen}
//           onOpen={() => setIsOpen(true)}
//           onClose={() => setIsOpen(false)}
//           // disableCloseOnSelect
//           onKeyDown={handleKeyDown} // Handle keyboard events
//           PopperComponent={(props) => <CustomPopper {...props} dropdownPosition={dropdownPosition} />}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               variant="outlined"
//               size="small"
//               onClick={() => setIsOpen(!isOpen)}
//               placeholder="Select options"
//               sx={{
//                 width: '200px',
//                 fontSize: '11px',
//                 '& .MuiOutlinedInput-root': {
//                   padding: '8px', // Adjust padding for better spacing
//                 },
//                 '& .MuiInputBase-root': {
//                   fontSize: '11px', // Font size for input text
//                 },
//                 '& .MuiOutlinedInput-notchedOutline': {
//                   border: 'none', // Remove the outer border
//                 },
//                 '&:hover .MuiOutlinedInput-notchedOutline': {
//                   border: 'none', // Keep the border removed on hover
//                 },
//                 '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                   border: 'none', // No border when focused
//                 },
//               }}
//               InputProps={{
//                 ...params.InputProps,
//                 style: {
//                   fontSize: '11px',
//                   boxShadow: 'none', // Remove box shadow
//                 },
//               }}
//               inputProps={{
//                 ...params.inputProps,
//                 style: {
//                   fontSize: '11px',
//                 },
//               }}
//             />
//           )}
//           renderOption={(props, option, { selected }) => (
//             <li {...props} key={option?.value} style={{ fontSize: '11px', display: 'flex', alignItems: 'center' }}>
//               <Checkbox 
//                 checked={selected || isOptionSelected(option)}
//                 size="small" // Reduce checkbox size
//                 sx={{
//                   padding: '4px', // Less padding around checkbox
//                   fontSize: '11px',
//                 }}
//               />
//               <ListItemText 
//                 primary={option?.value} 
//                 sx={{ fontSize: '11px', marginLeft: '8px' }} // Spacing between checkbox and text
//               />
//             </li>
//           )}
//           renderTags={(selected) => selected.map(option => option?.value).join('; ')}
//           sx={{
//             width: '200px',
//             fontSize: '11px', // Adjust font size for tags
//             '& .MuiChip-root': {
//               fontSize: '11px', // Reduce chip font size
//             },
//           }}
//           />

//             <SaveEditFirst
//               UpdateTableField={(e) => { UpdateTableField(e, selectedOptions, Item); }}
//               handleCloseClick={(e) => {
//                 e.stopPropagation();
//                 setDataInputRow(null);
//               }}
//           />
//         </div>
//       </ClickAwayListener>
//     </Fragment>
//   );
// };

export default MultipleSelectDropdown;
