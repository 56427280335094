import React, { Fragment, useState ,useEffect} from "react";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import OwnerDropDown from "../GlobalFeature/Fields/OwnerDropDown";
import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import useRequireAuth from "../../utils/useRequireAuth";
import { useOrgGroupAddUpdateMemberMutation } from "../../utils/users/Organizations";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import { GET_ORGANIZATION_EDIT_DATA } from "../../utils/constants";

const AddGroups = ({refetchData,groupid, closePopup,editId,action}) => {
  const userdata = useRequireAuth();
  const [errors, setErrors] = useState({});

  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });

  const initialFormData = {
    owner: "",
    email:""
  };
  const [formData, setFormData] = useState({
    owner: 0,
    email:"",
    group_id: groupid?.group_name?.id,
  });
  console.log(groupid?.group_name?.id, 3000)
  
  const fetchTaskEditData = async (editId) => {
    const token=userdata?.token;
    const url = `${GET_ORGANIZATION_EDIT_DATA}${editId}`;
    const getData = await getCommonDataBasedOnId(url, token);

   setFormData(getData?.data[0]);
   
  };

  useEffect(() => {
    fetchTaskEditData(editId);
  }, [editId]);

 
  const handleFieldChange = (e) => {
   
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [fetchPersonInTable, { data: personTableData }] =
    useLazyFetchPersonInTableQuery();

  const fetchPersonData = (data) => {
    fetchPersonInTable({ token: userdata?.token, data: data });
  };

  const [
    orgGroupAddUpdateMember,
    { orgGroupMemberDropLoad, orgGroupMemberDroperror },
  ] = useOrgGroupAddUpdateMemberMutation();

  const validate = () => {
    let tempErrors = {};

    if (!formData?.email) {
      tempErrors.email = 'Cannot be left blank';
    }
    if (!formData?.owner) {
      tempErrors.owner = 'Cannot be left blank';
    } 

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!validate()){
      return false;
    }
   
    try {
      const groupData={
        ...formData,
        id:editId,
        group_id:groupid?.group_name?.id
      }
    
      const response = await orgGroupAddUpdateMember({
        token: userdata?.token,
        data: groupData,
      });

      
      if (!response?.error) {
        setAlert({
          visible: true,
          message: "Successfully Record Insert",
          type: "success",
        });
        refetchData()
        setFormData(initialFormData);
        closePopup();
      } else{
        setAlert({
          visible: true,
          message: response?.error?.data?.error,
          type: "danger",
        });
        refetchData();
        closePopup();
      }
    } catch (err) {
      console.error("Error while adding/updating:", err);
    }
  };

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  const tableDetail = {
    tableidclass: "usermodelmember",
  };

  const handleInsert = (item) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: item,
    }));
  };

 
  return (
    <Fragment>
      <FirstMessage
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onHide={hideAlert}
      />
      <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-gray-900 bg-opacity-50">
        <div className="relative mt-18 bg-white  font-popns drop-shadow-lg  rounded-md w-[520px] overflow-hidden no-scrollbar">
          <div className="mx-6 flex items-center justify-between py-4 md:py-3  border-b border-BECBD3 rounded-t">
            <h3 className="text-xl text-434852 font-500">{action} Member</h3>
            <button
              onClick={closePopup}
              type="button"
              className="text-454545 bg-F1F5F8 hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              data-modal-hide="assets-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <form
            className={`w-full lg:max-h-[85vh]  overflow-auto  pt-3`}
            onSubmit={handleSubmit}
          >
            <div className="grid px-6 mb-4">
              <div>
                <label htmlFor="email" className="block text-sm">
                  <span className=" text-434852">
                    Name
                  </span>
                  <span className=" text-red-500"> *</span>                  
                </label>              
                <OwnerDropDown
                  tableidclass={tableDetail.tableidclass}
                  fetchPersonInTable={fetchPersonData}
                  DropData={personTableData}
                  handleInsert={handleInsert}
                  owner={formData?.email}
                  fieldname={"email"}
                />
              </div>
              {errors.owner && (
                <span className="text-red-500 text-xxs">{errors.owner}</span>
              )}
            </div>
            <div className="basis-5/5 mb-3 px-6 pt-2">
              <label
                htmlFor="Designation"
                className="block mb-2 text-sm text-434852"
              >
                Access <span className=" text-red-500"> *</span>                
              </label>
              <select
                id={`owner`}
                name="owner"
                onChange={handleFieldChange}
                className={`form-select border border-BFBFBF text-383838 text-md  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1`}
              >
                <option value="0" selected={formData?.owner === 0}>
                  Contributor
                </option>
                <option value="1" selected={formData?.owner === 1}>
                  Owner
                </option>
               
              </select>
              {errors && (
                  <span className="text-red-500 text-xxs"> {errors?.owner}</span>
                )}
            </div>
            <div className="sticky bottom-0 p-4 flex justify-end py-4 modal-footer text-md w-full rounded-none z-[9998]">
              <div className="px-2">
                <button
                  onClick={closePopup}
                  type="button"
                  className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-normal px-5 py-1 text-center rounded-5px"
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-normal border border-gray-200 py-1 px-5 ms-3 rounded-5px"
                >
                  SAVE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>   
    </Fragment>
  );
};

export default AddGroups;
