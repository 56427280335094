import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddTaskModal from "./AddTaskModal";
import CustomerTaskNavbar from "./CustomerTaskNavbar";
import TaskRecurrenceCalendar from "../TaskRecurrence/TaskRecurrenceCalendar";


const AddTask = ({taskOpenTab}) => {

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [islistview, setIsLisView] = useState(true);
  const [iscalview, setIsCalView] = useState(false);

  const [view, setView] = useState("Day");


  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="tablemaindiv customerTaskTable">
      <>
        {isPopupOpen && (
          <AddTaskModal isOpen={isPopupOpen} onClose={closePopup} taskOpenTab={taskOpenTab}/>
        )}
      </>
      {islistview && (
        <CustomerTaskNavbar taskOpenTab={taskOpenTab}  setView={setView} view={view}/>
      )}
      {/* {iscalview && (
        <TaskRecurrenceCalendar setView={setView} view={view}/>
      )} */}
    </div>
  );
};

export default AddTask;
