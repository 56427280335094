import React, { Fragment, useEffect, useState, useRef } from "react";
import SaveEditFirst from "../SaveEdit/SaveEditFirst";
import TextareaSubmit from "./TextareaSubmit";
import useClickOutside from "../../../hooks/Screen/useClickOutside";
import useKeyboardShortcuts from "../../../hooks/KeysHandle/useKeyboardShortcuts";
import CreatePortalHOC from "./CreatePortalHOC";
import { useLazyFetchPersonInTableQuery, useUpdateCustomerDelegationMutation } from "../../../utils/customer/customerTable";
import useRequireAuth from "../../../utils/useRequireAuth";
import { Alert, Snackbar } from "@mui/material";
const MultiPerson = ({
  tableidclass,
  FieldType,
  FieldValue,
  FieldChange,
  setDataInputRow,
  UpdateTableField,
  Item,
  Header,
  clientCoord,
  DataRow,
  commonData,
  ItemComplete,
  refetchCustomerData,
  multiData
}) => {
  const [inputvalue, setInputValue] = useState([]);
  const inputRef = useRef(null);
  const [overlayStyle, setOverlayStyle] = useState({});
  const [samplestate, setsamplestate] = useState(false);
  const [alert, setAlert] = useState({})
  const inputcloseoutside = useClickOutside(() => {
    setDataInputRow(null);
  });
  const { token } = useRequireAuth();


  useEffect(() => {
    if (FieldType === "date" && FieldValue) {
      setInputValue(formatDateForDisplay(FieldValue));
    } else {
      setInputValue(FieldValue);
    }
  }, [FieldValue, FieldType]);

  const formatDateForDisplay = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const formatDateForInput = (date) => {
    if (!date) return "";
    const [day, month, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    if (FieldType === "date") {
      newValue = formatDateForInput(newValue);
      setInputValue(formatDateForDisplay(newValue)); // Display format
    } else {
      setInputValue(newValue.slice(0, 200));
    }
    FieldChange({ target: { value: newValue } });
  };

  const [fetchPersonInTable, { data: personTableData }] = useLazyFetchPersonInTableQuery();
    const [updateCustomerDelegation, { isLoading: delegationLoading, isError: delegationError, isSuccess: delegationSuccess }] = useUpdateCustomerDelegationMutation()
    const [searchTerm, setSearchTerm] = useState("");
    const fetchPersonData = (data) => {
      fetchPersonInTable({ token: token, data: data });
    };
    useEffect(() => {
      fetchPersonData("")
    }, [])
    const handleCheck = (e) => {
      setSearchTerm(e.target.value)
    }

    const handleSelectPerson = async (event, value, deleteBool) => {
      console.log(event,"aakash event")
      event.stopPropagation()
      const org_id = JSON.parse(localStorage.getItem("myUserData"))?.org_id;
      console.log(ItemComplete, "ItemComplete aakash")
      
        const payload = {
          customer_id: ItemComplete?.customer_id?.id,
          company_id: ItemComplete?.company_id?.value,
          user_id: value?.id,
          org_id: org_id,
          multiData:multiData
        }
      
     

      try {
        const updateDelegationResponse=await updateCustomerDelegation({ token: token, data: payload, isDelete: deleteBool })
      //  console.log(updateDelegationResponse,"updateDelegationResponse aakash")
        if (updateDelegationResponse?.data?.status==true) {
          setAlert({
            open: true,
            message: "Delegation Updated Successfully",
            type: "success",
          });
          refetchCustomerData()
        }
        else  {
          setAlert({
            open: true,
            message: "Error updating data!",
            type: "danger",
          });
          refetchCustomerData()
        }
      } catch (error) {
        console.error("Error updating data:", error);
        // setAlert({
        //   open: true,
        //   message: "Error updating data!",
        //   type: "danger",
        // });
        
        // setLoading(false);
      } finally {
        // setLoading(false);
      }
    };

    const handleCloseAlert = () => {
      setAlert((prevState) => ({ ...prevState, open: false }))
    }






  const getMultiPersonSelection = ( open, personList, clientCoord ) => {
    
    return (
      <>
        <div className="flex items-end">
          <CreatePortalHOC
            isOpen={true}
            // onClose={() => {}}
            position={clientCoord}
            noBackground
            noBoxShadow
            verticalDist={0}
          >
            {/* <div className="table-overlay inset-0 flex items-center justify-end"> */}
            <div
              className={` !p-0 w-[${clientCoord?.element?.width - 20}px]`}
              ref={inputcloseoutside}
            >
              <div className="w-[294px] bg-white rounded-md border shadow-3 pt-3 mb-6 absolute z-[1] ">
                <div
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={() => {
                //   handleMouseLeave("D0");
                // }}
                >
                  <input
                    type="text"
                    placeholder="Type a name or email address"
                    className="ml-2 w-[270px] h-[38px] border-[#B6BBBF] bg-[#F8F8F8] rounded-md text-[12px] font-[400] text-[#848687] mb-2"
                    value={searchTerm}
                    onChange={handleCheck}
                  />

                  <div className="pb-3 align-center ">
                    <h3 className="text-14 font-500 ml-4 mb-2 ">Assigned</h3>
                    <ul className="list-none max-h-[70px] overflow-y-auto">
                      {personList && personList.map((eachInput, index) => {
                        const initials = eachInput.name
                          .split(" ")
                          .filter((word) => word.length > 0)
                          .map((word) => word.charAt(0).toUpperCase())
                          .slice(0, 2)
                          .join("");
                        return (
                          <li
                            key={index}
                            className="list-none cursor-pointer py-1"
                            onClick={(e) => handleSelectPerson(e, eachInput, true)}
                          // onClick={() => handleSelectPerson(eachInput)}
                          >
                            <div className="flex justify-between">
                              <div className="text-[#2999BC] text-12 font-400 flex pl-4 space-x-2">
                                <div className="bg-white text-10 font-600 border-[#AAC9DB] p-[10px] w-5 h-5 flex items-center justify-center rounded-full border-[1px]">
                                  {initials}
                                </div>
                                <p className="mb-1 ">{eachInput.name}</p>
                              </div>
                              <div className="pr-6 mb-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                >
                                  <path
                                    d="M1.13754 11.9938C0.843338 12.0111 0.553997 11.9122 0.330534 11.7178C-0.110178 11.2693 -0.110178 10.545 0.330534 10.0965L9.98068 0.33402C10.4391 -0.0998917 11.1583 -0.0757709 11.5873 0.387942C11.9751 0.807275 11.9977 1.4518 11.6402 1.89787L1.93318 11.7178C1.7126 11.9093 1.4279 12.0081 1.13754 11.9938Z"
                                    fill="#BFC3C7"
                                  />
                                  <path
                                    d="M10.7763 11.9938C10.4782 11.9925 10.1924 11.8728 9.98068 11.6603L0.330496 1.89783C-0.0778002 1.41549 -0.0222901 0.689586 0.454505 0.276501C0.880057 -0.092167 1.50766 -0.092167 1.93318 0.276501L11.6402 10.039C12.0984 10.473 12.1221 11.2007 11.6931 11.6643C11.676 11.6827 11.6584 11.7006 11.6402 11.7178C11.4025 11.9269 11.0897 12.0268 10.7763 11.9938Z"
                                    fill="#BFC3C7"
                                  />
                                </svg>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-14 font-500 ml-4 pb-3">Suggestions</h3>
                  </div>
                  {/* <ul className="pl-5 text-12 font-400 space-y-3 text-[#2999BC]">
                                              {personTableData.map((eachInput, index) => (
                                                <div className="flex space-x-2 cursor-pointer"> 
                                                <div className="bg-white text-12 font-600 border-[#AAC9DB] p-[10px] w-5 h-5 flex items-center justify-center rounded-full border-[1px] "> {eachInput.name.charAt(0).toUpperCase()}</div>
                                                      <li
                                                        key={index}
                                                        onClick={() => handleSelectPerson(eachInput)}
                                                      >
                                                        {eachInput.name}
                                                      </li>
                                                </div>
                                              ))}
                                            </ul> */}

                  <ul className="pl-5 text-12 font-400 space-y-3 text-[#2999BC] h-[75px] overflow-y-auto  ">
                    {console.log(personTableData, "personTableData")}
                    {personTableData &&
                      personTableData
                        ?.filter(
                          (eachInput) =>
                            eachInput.name
                              .toLowerCase()
                              .includes(
                                searchTerm || ""
                              ) &&
                            !personList.some((eachValue) =>
                              eachValue.name
                                .toLowerCase()
                                .includes(eachInput.name.toLowerCase())
                            )
                        )
                        .map((eachInput, index) => {
                          const initials = eachInput.name
                            .split(" ")
                            .filter((word) => word.length > 0)
                            .map((word) => word.charAt(0).toUpperCase())
                            .slice(0, 2)
                            .join("");

                          return (
                            <div
                              className="flex space-x-2 cursor-pointer"
                              key={index}
                            >
                              <div className="bg-white text-10 font-600 border-[#AAC9DB] p-[10px] w-5 h-5 flex items-center justify-center rounded-full border-[1px]">
                                {initials}
                              </div>
                              <li
                                onClick={(e) =>
                                  handleSelectPerson(e, eachInput)
                                }
                              >
                                {eachInput.name}
                              </li>
                            </div>
                          );
                        })}
                  </ul>
                </div>
              </div>
            </div>
          </CreatePortalHOC>


          <div className="pt-1 space-x-1 flex items-end">
            {personList
              ?.filter((assigned, index) => index < 3)
              .map((eachInput, index) => {
                const initials = eachInput.name
                  .split(" ")
                  .filter((word) => word.length > 0)
                  .map((word) => word.charAt(0).toUpperCase())
                  .slice(0, 2)
                  .join("");

                return (
                  <div key={index} className="relative">
                    <div className="bg-gray-200 text-gray-500 text-12 font-600 border-gray-300  p-[10px] w-5 h-5 flex items-center justify-center rounded-full border-[1px]">
                      {initials}
                    </div>
                    <button
                      className="absolute top-[-4px] right-[-4px] bg-blue-500 text-white rounded-full w-[10px] h-[10px] flex items-center justify-center text-xs cursor-pointer"
                      onClick={(e) => handleSelectPerson(e, eachInput, true)}
                    >
                      &times;
                    </button>
                  </div>
                );
              })}
            {personList?.length > 3 && (
              <div className="">
                <div className="bg-gray-200 text-gray-500 text-12 font-600 border-gray-300   w-7 h-5 flex items-center justify-center rounded-full border-[1px]">
                  + {personList?.length - 3}
                </div>
                
              </div>
            )}
          </div>
        </div>
        {alert?.open && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={alert?.open}
            onClose={handleCloseAlert}
            key={"top" + "right"}
            autoHideDuration={1000}
          >
            <Alert
              onClose={handleCloseAlert}
              severity={alert?.type || 'success'} // 'success' for green, 'error' for red
              sx={{
                backgroundColor: alert?.type === 'success' ? 'green' : 'red',
                color: 'white',
              }}
            >
              {alert?.message}
            </Alert>
          </Snackbar>
        )}
      </>
    );
  };

  useKeyboardShortcuts({
    Enter: (event) => {
      event.preventDefault();
      UpdateTableField(event, { value: inputvalue }, Item);
    },
    Escape: (event) => {
      event.preventDefault();
      setDataInputRow(null);
    },
  });

  return (
    <Fragment>
      {true ? (
        <Fragment>
          {/* <input
            ref={inputRef}
            className="p-3 w-full h-[33px] bg-white"
            disabled
            readOnly
          /> */}

          {/* <div className="absolute left-1/2 transform -translate-x-1/2 z-10 mt-3 w-[300px]"> */}
          {/* <div className={`w-full absolute top-0 h-full ${HoverActiveClass}`} ref={dropdownRef} onMouseOver={handleOpen} onMouseLeave={handleClose}>
            </div> */}


          {/* <TextareaSubmit
                Value={inputvalue}
                onChange={handleChange}
                handleSaveEvent={(e) =>
                  UpdateTableField(e, { value: inputvalue }, Item)
                }
              /> */}
       
         { getMultiPersonSelection(true,inputvalue,clientCoord)}

        </Fragment>
      ) : (
        <Fragment>
          <input
            type={FieldType === "number" ? "text" : FieldType}
            value={inputvalue}
            onChange={handleChange}
            className="p-3 w-full h-[33px]"
          />

          <SaveEditFirst
            UpdateTableField={(e) =>
              UpdateTableField(e, { value: inputvalue }, Item)
            }
            handleCloseClick={(e) => {
              e.stopPropagation();
              setDataInputRow(null);
            }}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
export default MultiPerson;
