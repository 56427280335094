import React, { useState, useEffect } from 'react';
import Setting from '../Setting';

const SettingHeader = ({thead,setStoredThead, handlePostThead,ExtraFieldAddHeader,GetAdditionalField,GetAddFieldData,tableDetail }) => {

  const [menuVisible, setMenuVisible] = useState(false);
  const [extraField, setExtraField] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [orderedThead, setOrderedThead] = useState([]);
  const [editFieldData,setEditFieldData] = useState(null)
  const [componentKey, setComponentKey] = useState(0); 

  
  useEffect(() => {
    setOrderedThead(thead);
  }, [thead]);

  useEffect(() => {
    setEditFieldData(GetAddFieldData);
  }, [GetAddFieldData]);

  const handleMenuToggle = () => {
    setMenuVisible(prev => !prev);
    setExtraField(false);
  };

  const CreateExtraField = () => {
    if(editFieldData){
      setEditFieldData(null)

    }
    else{
      setExtraField(!extraField);

    }
  };
  const EditExtraField = (e,headeritem) => {
    console.warn(headeritem,"headeritem")
       GetAdditionalField(headeritem)
       setExtraField(true);
       setComponentKey(prevKey => prevKey + 1);
  }

  const filteredThead =  Array.isArray(orderedThead) && orderedThead?.filter(headerItem =>
    headerItem[Object.keys(headerItem)[0]]?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleSearchInputChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleDragStart = (headerItem, index) => {
    setDraggedItem({ headerItem, index });
  };

  const handleDragOver = (index) => {
    setDragOverIndex(index);
  };

  const handleDragLeave = () => {
    setDragOverIndex(null);
  };

  const handleDrop = (status, targetIndex) => {
    if (draggedItem) {
      const updatedThead = [...orderedThead];
      const updatedItem = { ...draggedItem.headerItem };
      updatedItem[Object.keys(updatedItem)[1]] = status ? '1' : '0';

      updatedThead.splice(draggedItem.index, 1);
      updatedThead.splice(targetIndex, 0, updatedItem);

      setOrderedThead(updatedThead);
      setStoredThead(updatedThead)
      setDraggedItem(null);
      setDragOverIndex(null);
    }
  };

  const handleCheckboxChange = (index, checked) => {
    const updatedThead = [...orderedThead];
    updatedThead[index] = {
      ...updatedThead[index],
      [Object.keys(updatedThead[index])[1]]: checked ? '1' : '0'
    };
    setOrderedThead(updatedThead);
    setStoredThead(updatedThead)
  };

  return (
    <div className="relative">
      <Setting  key={componentKey} setExtraField={setExtraField} extraField={extraField} setMenuVisible={setMenuVisible} menuVisible={menuVisible} handleMenuToggle={handleMenuToggle} setOrderedThead={setOrderedThead} ApplyHeader={() => { handlePostThead(orderedThead) }} ExtraFieldAddHeader={ExtraFieldAddHeader} EditFieldData={editFieldData}>
       
       <div>

        <div className="px-4">
          <div className="relative mt-3">
            <input
              className="w-full h-full pl-10 pr-3 py-2 border border-gray-300 rounded focus:outline-none shadow"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
            <i className="bi bi-search absolute left-3 top-1/2 transform -translate-y-1/2 text-1E1E1E "></i>
          </div>
        </div>

        <div className="settingheaderfield px-4 customscrollbar w-100 bg-white customscrollbar overflow-auto">
          {Array.isArray(filteredThead) && filteredThead?.some(headerItem => Boolean(Number(headerItem[Object.keys(headerItem)[1]]))) && (
            <div className="flex flex-wrap mb-5">
              <h4 className="text-535252 font-poppins font-600 text-14 ms-5 mt-5">Show Field</h4>
              {Array.isArray(filteredThead) && filteredThead.map((headerItem, index) =>
                Boolean(Number(headerItem[Object.keys(headerItem)[1]])) === true ? (
                  <div
                    className={`w-full flex justify-between items-center my-2 ${dragOverIndex === index ? 'bg-gray-200' : ''}`}
                    key={index}
                    draggable
                    onDragStart={() => handleDragStart(headerItem, index)}
                    onDragOver={() => handleDragOver(index)}
                    onDragLeave={handleDragLeave}
                    onDrop={(e) => handleDrop(true, index)}
                  >
                    <div className="flex items-center">
                      <button className="cursor-grab">
                        <i className="bi bi-grip-vertical mr-2 text-CCD4DC hover:text-667279"></i>
                      </button>
                      <span className="checkbox-label capitalize">{headerItem[Object.keys(headerItem)[0]]}</span>
                    </div>
                    <div className="flex items-center">
                      {headerItem.id &&
                      
                          (
                            <button type='button'
                            onClick={(e) => {
                              EditExtraField(e,headerItem);
                            }}>
                                  <i className="bi bi-pencil-fill text-gray-200 mr-4 hover:text-667279"></i>
                            </button>
                          )
                      }
                     
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          id={`checkbox-${index}`}
                          checked={Boolean(Number(headerItem[Object.keys(headerItem)[1]]))}
                          onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                        />
                        <label htmlFor={`checkbox-${index}`} className="bottom-[15px]"></label>
                      </div>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          )}

          <hr className="p-3 text-BEC6CC font-20"></hr>

          {Array.isArray(filteredThead) && filteredThead?.some(headerItem => Boolean(Number(headerItem[Object.keys(headerItem)[1]])) === false) && (
            <div className="flex flex-wrap">
              <h4 className="text-535252 font-poppins font-600 text-14 ms-5">Hidden Field</h4>
              {Array.isArray(filteredThead) &&  filteredThead.map((headerItem, index) =>
                Boolean(Number(headerItem[Object.keys(headerItem)[1]])) === false ? (
                  <div
                    className={`w-full flex justify-between items-center my-2 ${dragOverIndex === index ? 'bg-gray-200' : ''}`}
                    key={index}
                    draggable
                    onDragStart={() => handleDragStart(headerItem, index)}
                    onDragOver={() => handleDragOver(index)}
                    onDragLeave={handleDragLeave}
                    onDrop={(e) => handleDrop(false, index)}
                  >
                    <div className="flex items-center">
                      <button className="cursor-grab">
                        <i className="bi bi-grip-vertical mr-2 text-CCD4DC hover:text-667279"></i>
                      </button>
                      <span className="checkbox-label capitalize">{headerItem[Object.keys(headerItem)[0]]}</span>
                    </div>
                    <div className="flex items-center">
                            {headerItem.id &&
                              
                              (
                              <button type='button' 
                              onClick={(e) => {
                                EditExtraField(e,headerItem);
                              }}>
                                    <i className="bi bi-pencil-fill text-gray-200 mr-4 hover:text-667279"></i>
                              </button>
        
                              )
                            }
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          id={`checkbox-${index}`}
                          checked={Boolean(Number(headerItem[Object.keys(headerItem)[1]]))}
                          onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                        />
                        <label htmlFor={`checkbox-${index}`} className="bottom-[15px]"></label>
                      </div>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          )}
        </div>       
       </div>


       {tableDetail && (tableDetail?.headeraddfield !== false && 

       (
                <div
                    className="py-2 px-6 bg-white flex justify-center"
                    style={{ borderRadius: '10px 10px 0px 0px',
                      background: '#FFF',
                      boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.50)'
                    }}
                >
                    <div className="p-1 w-full h-full">
                      <button
                        className="text-white rounded-md bg-2999BC shadow-md w-full h-10 font-poppins font-400 text-14"
                        onClick={() => {
                          CreateExtraField();
                        }}
                      >
                        Add Field
                      </button>
                    </div>
                </div>
       )
       
       
       )}
      

      </Setting>
    </div>
  );
};
export default SettingHeader;
