import React, { Fragment, useState } from 'react';
import useClickOutside from '../../hooks/Screen/useClickOutside';
import AddTaskModal from '../customer/AddTaskModal';

const EditOptionsModal = ({ onClose,onOpen, onSave,refetch }) => {
  const [selection, setSelection] = useState('specific'); // Default to 'specific'

  console.warn(onOpen,"onOpen")
  const handleSave = () => {
    onSave(selection);
  };

  const clickoutsideclose = useClickOutside(() => {
    onClose()
  });

  return (
    <Fragment>

{onOpen?.selection    ?

         (
          <AddTaskModal
          closePopup={onClose}
          taskid={onOpen}
          refetch={refetch}
          // handleSubmit={handleModalSubmit}
          />
           
         )

       
        :


        (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-[99999999999]">
              <div className="bg-white p-6 rounded-lg shadow-lg w-1/3 max-w-md" ref={clickoutsideclose}>
                <h2 className="text-xl font-semibold mb-4">Edit Event</h2>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="entireSeries"
                    name="editOption"
                    value="entire"
                    checked={selection === 'entire'}
                    onChange={() => setSelection('entire')}
                    className="mr-2"
                  />
                  <label htmlFor="entireSeries" className="mr-4">Edit entire series</label>
                </div>
                <div className="mb-4">
                  <input
                    type="radio"
                    id="specificOccurrence"
                    name="editOption"
                    value="specific"
                    checked={selection === 'specific'}
                    onChange={() => setSelection('specific')}
                    className="mr-2"
                  />
                  <label htmlFor="specificOccurrence">Edit this occurrence only</label>
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={handleSave}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Save
                  </button>
                  <button
                    onClick={onClose}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

        )

    



}


  


    </Fragment>
    
  );
};

export default EditOptionsModal;
