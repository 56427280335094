import { useEffect } from 'react';

const useKeyboardShortcuts = (shortcuts) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && shortcuts.Enter) {
        shortcuts.Enter(event);
      } else if (event.key === 'Escape' && shortcuts.Escape) {
        shortcuts.Escape(event);
      }
      else if (event.altKey && event.key === 's' && shortcuts.ToggleSearchBar) {
          shortcuts.ToggleSearchBar(event);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [shortcuts]);
};

export default useKeyboardShortcuts;
