const Emojies = [
  {
    codes: "1F600",
    char: "😀",
    name: "grinning face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F603",
    char: "😃",
    name: "grinning face with big eyes",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F604",
    char: "😄",
    name: "grinning face with smiling eyes",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F601",
    char: "😁",
    name: "beaming face with smiling eyes",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F606",
    char: "😆",
    name: "grinning squinting face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F605",
    char: "😅",
    name: "grinning face with sweat",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F923",
    char: "🤣",
    name: "rolling on the floor laughing",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F602",
    char: "😂",
    name: "face with tears of joy",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F642",
    char: "🙂",
    name: "slightly smiling face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F643",
    char: "🙃",
    name: "upside-down face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1FAE0",
    char: "🫠",
    name: "melting face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F609",
    char: "😉",
    name: "winking face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F60A",
    char: "😊",
    name: "smiling face with smiling eyes",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F607",
    char: "😇",
    name: "smiling face with halo",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F970",
    char: "🥰",
    name: "smiling face with hearts",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F60D",
    char: "😍",
    name: "smiling face with heart-eyes",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F929",
    char: "🤩",
    name: "star-struck",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F618",
    char: "😘",
    name: "face blowing a kiss",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F617",
    char: "😗",
    name: "kissing face",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "263A FE0F",
    char: "☺️",
    name: "smiling face",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "263A",
    char: "☺",
    name: "smiling face",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F61A",
    char: "😚",
    name: "kissing face with closed eyes",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F619",
    char: "😙",
    name: "kissing face with smiling eyes",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F972",
    char: "🥲",
    name: "smiling face with tear",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F60B",
    char: "😋",
    name: "face savoring food",
    category: "Smileys & Emotion (face-tongue)",
    group: "Smileys & Emotion",
    subgroup: "face-tongue",
  },
  {
    codes: "1F61B",
    char: "😛",
    name: "face with tongue",
    category: "Smileys & Emotion (face-tongue)",
    group: "Smileys & Emotion",
    subgroup: "face-tongue",
  },
  {
    codes: "1F61C",
    char: "😜",
    name: "winking face with tongue",
    category: "Smileys & Emotion (face-tongue)",
    group: "Smileys & Emotion",
    subgroup: "face-tongue",
  },
  {
    codes: "1F92A",
    char: "🤪",
    name: "zany face",
    category: "Smileys & Emotion (face-tongue)",
    group: "Smileys & Emotion",
    subgroup: "face-tongue",
  },
  {
    codes: "1F61D",
    char: "😝",
    name: "squinting face with tongue",
    category: "Smileys & Emotion (face-tongue)",
    group: "Smileys & Emotion",
    subgroup: "face-tongue",
  },
  {
    codes: "1F911",
    char: "🤑",
    name: "money-mouth face",
    category: "Smileys & Emotion (face-tongue)",
    group: "Smileys & Emotion",
    subgroup: "face-tongue",
  },
  {
    codes: "1F917",
    char: "🤗",
    name: "smiling face with open hands",
    category: "Smileys & Emotion (face-hand)",
    group: "Smileys & Emotion",
    subgroup: "face-hand",
  },
  {
    codes: "1F92D",
    char: "🤭",
    name: "face with hand over mouth",
    category: "Smileys & Emotion (face-hand)",
    group: "Smileys & Emotion",
    subgroup: "face-hand",
  },
  {
    codes: "1FAE2",
    char: "🫢",
    name: "face with open eyes and hand over mouth",
    category: "Smileys & Emotion (face-hand)",
    group: "Smileys & Emotion",
    subgroup: "face-hand",
  },
  {
    codes: "1FAE3",
    char: "🫣",
    name: "face with peeking eye",
    category: "Smileys & Emotion (face-hand)",
    group: "Smileys & Emotion",
    subgroup: "face-hand",
  },
  {
    codes: "1F92B",
    char: "🤫",
    name: "shushing face",
    category: "Smileys & Emotion (face-hand)",
    group: "Smileys & Emotion",
    subgroup: "face-hand",
  },
  {
    codes: "1F914",
    char: "🤔",
    name: "thinking face",
    category: "Smileys & Emotion (face-hand)",
    group: "Smileys & Emotion",
    subgroup: "face-hand",
  },
  {
    codes: "1FAE1",
    char: "🫡",
    name: "saluting face",
    category: "Smileys & Emotion (face-hand)",
    group: "Smileys & Emotion",
    subgroup: "face-hand",
  },
  {
    codes: "1F910",
    char: "🤐",
    name: "zipper-mouth face",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F928",
    char: "🤨",
    name: "face with raised eyebrow",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F610",
    char: "😐",
    name: "neutral face",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F611",
    char: "😑",
    name: "expressionless face",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F636",
    char: "😶",
    name: "face without mouth",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1FAE5",
    char: "🫥",
    name: "dotted line face",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F636 200D 1F32B FE0F",
    char: "😶‍🌫️",
    name: "face in clouds",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F636 200D 1F32B",
    char: "😶‍🌫",
    name: "face in clouds",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F60F",
    char: "😏",
    name: "smirking face",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F612",
    char: "😒",
    name: "unamused face",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F644",
    char: "🙄",
    name: "face with rolling eyes",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F62C",
    char: "😬",
    name: "grimacing face",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F62E 200D 1F4A8",
    char: "😮‍💨",
    name: "face exhaling",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F925",
    char: "🤥",
    name: "lying face",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1FAE8",
    char: "🫨",
    name: "shaking face",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F642 200D 2194 FE0F",
    char: "🙂‍↔️",
    name: "head shaking horizontally",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F642 200D 2194",
    char: "🙂‍↔",
    name: "head shaking horizontally",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F642 200D 2195 FE0F",
    char: "🙂‍↕️",
    name: "head shaking vertically",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F642 200D 2195",
    char: "🙂‍↕",
    name: "head shaking vertically",
    category: "Smileys & Emotion (face-neutral-skeptical)",
    group: "Smileys & Emotion",
    subgroup: "face-neutral-skeptical",
  },
  {
    codes: "1F60C",
    char: "😌",
    name: "relieved face",
    category: "Smileys & Emotion (face-sleepy)",
    group: "Smileys & Emotion",
    subgroup: "face-sleepy",
  },
  {
    codes: "1F614",
    char: "😔",
    name: "pensive face",
    category: "Smileys & Emotion (face-sleepy)",
    group: "Smileys & Emotion",
    subgroup: "face-sleepy",
  },
  {
    codes: "1F62A",
    char: "😪",
    name: "sleepy face",
    category: "Smileys & Emotion (face-sleepy)",
    group: "Smileys & Emotion",
    subgroup: "face-sleepy",
  },
  {
    codes: "1F924",
    char: "🤤",
    name: "drooling face",
    category: "Smileys & Emotion (face-sleepy)",
    group: "Smileys & Emotion",
    subgroup: "face-sleepy",
  },
  {
    codes: "1F634",
    char: "😴",
    name: "sleeping face",
    category: "Smileys & Emotion (face-sleepy)",
    group: "Smileys & Emotion",
    subgroup: "face-sleepy",
  },
  {
    codes: "1F637",
    char: "😷",
    name: "face with medical mask",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F912",
    char: "🤒",
    name: "face with thermometer",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F915",
    char: "🤕",
    name: "face with head-bandage",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F922",
    char: "🤢",
    name: "nauseated face",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F92E",
    char: "🤮",
    name: "face vomiting",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F927",
    char: "🤧",
    name: "sneezing face",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F975",
    char: "🥵",
    name: "hot face",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F976",
    char: "🥶",
    name: "cold face",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F974",
    char: "🥴",
    name: "woozy face",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F635",
    char: "😵",
    name: "face with crossed-out eyes",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F635 200D 1F4AB",
    char: "😵‍💫",
    name: "face with spiral eyes",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F92F",
    char: "🤯",
    name: "exploding head",
    category: "Smileys & Emotion (face-unwell)",
    group: "Smileys & Emotion",
    subgroup: "face-unwell",
  },
  {
    codes: "1F920",
    char: "🤠",
    name: "cowboy hat face",
    category: "Smileys & Emotion (face-hat)",
    group: "Smileys & Emotion",
    subgroup: "face-hat",
  },
  {
    codes: "1F973",
    char: "🥳",
    name: "partying face",
    category: "Smileys & Emotion (face-hat)",
    group: "Smileys & Emotion",
    subgroup: "face-hat",
  },
  {
    codes: "1F978",
    char: "🥸",
    name: "disguised face",
    category: "Smileys & Emotion (face-hat)",
    group: "Smileys & Emotion",
    subgroup: "face-hat",
  },
  {
    codes: "1F60E",
    char: "😎",
    name: "smiling face with sunglasses",
    category: "Smileys & Emotion (face-glasses)",
    group: "Smileys & Emotion",
    subgroup: "face-glasses",
  },
  {
    codes: "1F913",
    char: "🤓",
    name: "nerd face",
    category: "Smileys & Emotion (face-glasses)",
    group: "Smileys & Emotion",
    subgroup: "face-glasses",
  },
  {
    codes: "1F9D0",
    char: "🧐",
    name: "face with monocle",
    category: "Smileys & Emotion (face-glasses)",
    group: "Smileys & Emotion",
    subgroup: "face-glasses",
  },
  {
    codes: "1F615",
    char: "😕",
    name: "confused face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1FAE4",
    char: "🫤",
    name: "face with diagonal mouth",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F61F",
    char: "😟",
    name: "worried face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F641",
    char: "🙁",
    name: "slightly frowning face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "2639 FE0F",
    char: "☹️",
    name: "frowning face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "2639",
    char: "☹",
    name: "frowning face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F62E",
    char: "😮",
    name: "face with open mouth",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F62F",
    char: "😯",
    name: "hushed face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F632",
    char: "😲",
    name: "astonished face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F633",
    char: "😳",
    name: "flushed face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F97A",
    char: "🥺",
    name: "pleading face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F979",
    char: "🥹",
    name: "face holding back tears",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F626",
    char: "😦",
    name: "frowning face with open mouth",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F627",
    char: "😧",
    name: "anguished face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F628",
    char: "😨",
    name: "fearful face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F630",
    char: "😰",
    name: "anxious face with sweat",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F625",
    char: "😥",
    name: "sad but relieved face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F622",
    char: "😢",
    name: "crying face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F62D",
    char: "😭",
    name: "loudly crying face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F631",
    char: "😱",
    name: "face screaming in fear",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F616",
    char: "😖",
    name: "confounded face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F623",
    char: "😣",
    name: "persevering face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F61E",
    char: "😞",
    name: "disappointed face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F613",
    char: "😓",
    name: "downcast face with sweat",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F629",
    char: "😩",
    name: "weary face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F62B",
    char: "😫",
    name: "tired face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F971",
    char: "🥱",
    name: "yawning face",
    category: "Smileys & Emotion (face-concerned)",
    group: "Smileys & Emotion",
    subgroup: "face-concerned",
  },
  {
    codes: "1F624",
    char: "😤",
    name: "face with steam from nose",
    category: "Smileys & Emotion (face-negative)",
    group: "Smileys & Emotion",
    subgroup: "face-negative",
  },
  {
    codes: "1F621",
    char: "😡",
    name: "enraged face",
    category: "Smileys & Emotion (face-negative)",
    group: "Smileys & Emotion",
    subgroup: "face-negative",
  },
  {
    codes: "1F620",
    char: "😠",
    name: "angry face",
    category: "Smileys & Emotion (face-negative)",
    group: "Smileys & Emotion",
    subgroup: "face-negative",
  },
  {
    codes: "1F92C",
    char: "🤬",
    name: "face with symbols on mouth",
    category: "Smileys & Emotion (face-negative)",
    group: "Smileys & Emotion",
    subgroup: "face-negative",
  },
  {
    codes: "1F608",
    char: "😈",
    name: "smiling face with horns",
    category: "Smileys & Emotion (face-negative)",
    group: "Smileys & Emotion",
    subgroup: "face-negative",
  },
  {
    codes: "1F47F",
    char: "👿",
    name: "angry face with horns",
    category: "Smileys & Emotion (face-negative)",
    group: "Smileys & Emotion",
    subgroup: "face-negative",
  },
  {
    codes: "1F480",
    char: "💀",
    name: "skull",
    category: "Smileys & Emotion (face-negative)",
    group: "Smileys & Emotion",
    subgroup: "face-negative",
  },
  {
    codes: "2620 FE0F",
    char: "☠️",
    name: "skull and crossbones",
    category: "Smileys & Emotion (face-negative)",
    group: "Smileys & Emotion",
    subgroup: "face-negative",
  },
  {
    codes: "2620",
    char: "☠",
    name: "skull and crossbones",
    category: "Smileys & Emotion (face-negative)",
    group: "Smileys & Emotion",
    subgroup: "face-negative",
  },
  {
    codes: "1F4A9",
    char: "💩",
    name: "pile of poo",
    category: "Smileys & Emotion (face-costume)",
    group: "Smileys & Emotion",
    subgroup: "face-costume",
  },
  {
    codes: "1F921",
    char: "🤡",
    name: "clown face",
    category: "Smileys & Emotion (face-costume)",
    group: "Smileys & Emotion",
    subgroup: "face-costume",
  },
  {
    codes: "1F479",
    char: "👹",
    name: "ogre",
    category: "Smileys & Emotion (face-costume)",
    group: "Smileys & Emotion",
    subgroup: "face-costume",
  },
  {
    codes: "1F47A",
    char: "👺",
    name: "goblin",
    category: "Smileys & Emotion (face-costume)",
    group: "Smileys & Emotion",
    subgroup: "face-costume",
  },
  {
    codes: "1F47B",
    char: "👻",
    name: "ghost",
    category: "Smileys & Emotion (face-costume)",
    group: "Smileys & Emotion",
    subgroup: "face-costume",
  },
  {
    codes: "1F47D",
    char: "👽",
    name: "alien",
    category: "Smileys & Emotion (face-costume)",
    group: "Smileys & Emotion",
    subgroup: "face-costume",
  },
  {
    codes: "1F47E",
    char: "👾",
    name: "alien monster",
    category: "Smileys & Emotion (face-costume)",
    group: "Smileys & Emotion",
    subgroup: "face-costume",
  },
  {
    codes: "1F916",
    char: "🤖",
    name: "robot",
    category: "Smileys & Emotion (face-costume)",
    group: "Smileys & Emotion",
    subgroup: "face-costume",
  },
  {
    codes: "1F63A",
    char: "😺",
    name: "grinning cat",
    category: "Smileys & Emotion (cat-face)",
    group: "Smileys & Emotion",
    subgroup: "cat-face",
  },
  {
    codes: "1F638",
    char: "😸",
    name: "grinning cat with smiling eyes",
    category: "Smileys & Emotion (cat-face)",
    group: "Smileys & Emotion",
    subgroup: "cat-face",
  },
  {
    codes: "1F639",
    char: "😹",
    name: "cat with tears of joy",
    category: "Smileys & Emotion (cat-face)",
    group: "Smileys & Emotion",
    subgroup: "cat-face",
  },
  {
    codes: "1F63B",
    char: "😻",
    name: "smiling cat with heart-eyes",
    category: "Smileys & Emotion (cat-face)",
    group: "Smileys & Emotion",
    subgroup: "cat-face",
  },
  {
    codes: "1F63C",
    char: "😼",
    name: "cat with wry smile",
    category: "Smileys & Emotion (cat-face)",
    group: "Smileys & Emotion",
    subgroup: "cat-face",
  },
  {
    codes: "1F63D",
    char: "😽",
    name: "kissing cat",
    category: "Smileys & Emotion (cat-face)",
    group: "Smileys & Emotion",
    subgroup: "cat-face",
  },
  {
    codes: "1F640",
    char: "🙀",
    name: "weary cat",
    category: "Smileys & Emotion (cat-face)",
    group: "Smileys & Emotion",
    subgroup: "cat-face",
  },
  {
    codes: "1F63F",
    char: "😿",
    name: "crying cat",
    category: "Smileys & Emotion (cat-face)",
    group: "Smileys & Emotion",
    subgroup: "cat-face",
  },
  {
    codes: "1F63E",
    char: "😾",
    name: "pouting cat",
    category: "Smileys & Emotion (cat-face)",
    group: "Smileys & Emotion",
    subgroup: "cat-face",
  },
  {
    codes: "1F648",
    char: "🙈",
    name: "see-no-evil monkey",
    category: "Smileys & Emotion (monkey-face)",
    group: "Smileys & Emotion",
    subgroup: "monkey-face",
  },
  {
    codes: "1F649",
    char: "🙉",
    name: "hear-no-evil monkey",
    category: "Smileys & Emotion (monkey-face)",
    group: "Smileys & Emotion",
    subgroup: "monkey-face",
  },
  {
    codes: "1F64A",
    char: "🙊",
    name: "speak-no-evil monkey",
    category: "Smileys & Emotion (monkey-face)",
    group: "Smileys & Emotion",
    subgroup: "monkey-face",
  },
  {
    codes: "1F48C",
    char: "💌",
    name: "love letter",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F498",
    char: "💘",
    name: "heart with arrow",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F49D",
    char: "💝",
    name: "heart with ribbon",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F496",
    char: "💖",
    name: "sparkling heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F497",
    char: "💗",
    name: "growing heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F493",
    char: "💓",
    name: "beating heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F49E",
    char: "💞",
    name: "revolving hearts",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F495",
    char: "💕",
    name: "two hearts",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F49F",
    char: "💟",
    name: "heart decoration",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "2763 FE0F",
    char: "❣️",
    name: "heart exclamation",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "2763",
    char: "❣",
    name: "heart exclamation",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F494",
    char: "💔",
    name: "broken heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "2764 FE0F 200D 1F525",
    char: "❤️‍🔥",
    name: "heart on fire",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "2764 200D 1F525",
    char: "❤‍🔥",
    name: "heart on fire",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "2764 FE0F 200D 1FA79",
    char: "❤️‍🩹",
    name: "mending heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "2764 200D 1FA79",
    char: "❤‍🩹",
    name: "mending heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "2764 FE0F",
    char: "❤️",
    name: "red heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "2764",
    char: "❤",
    name: "red heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1FA77",
    char: "🩷",
    name: "pink heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F9E1",
    char: "🧡",
    name: "orange heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F49B",
    char: "💛",
    name: "yellow heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F49A",
    char: "💚",
    name: "green heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F499",
    char: "💙",
    name: "blue heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1FA75",
    char: "🩵",
    name: "light blue heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F49C",
    char: "💜",
    name: "purple heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F90E",
    char: "🤎",
    name: "brown heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F5A4",
    char: "🖤",
    name: "black heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1FA76",
    char: "🩶",
    name: "grey heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F90D",
    char: "🤍",
    name: "white heart",
    category: "Smileys & Emotion (heart)",
    group: "Smileys & Emotion",
    subgroup: "heart",
  },
  {
    codes: "1F48B",
    char: "💋",
    name: "kiss mark",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F4AF",
    char: "💯",
    name: "hundred points",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F4A2",
    char: "💢",
    name: "anger symbol",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F4A5",
    char: "💥",
    name: "collision",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F4AB",
    char: "💫",
    name: "dizzy",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F4A6",
    char: "💦",
    name: "sweat droplets",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F4A8",
    char: "💨",
    name: "dashing away",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F573 FE0F",
    char: "🕳️",
    name: "hole",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F573",
    char: "🕳",
    name: "hole",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F4AC",
    char: "💬",
    name: "speech balloon",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F441 FE0F 200D 1F5E8 FE0F",
    char: "👁️‍🗨️",
    name: "eye in speech bubble",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F441 200D 1F5E8 FE0F",
    char: "👁‍🗨️",
    name: "eye in speech bubble",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F441 FE0F 200D 1F5E8",
    char: "👁️‍🗨",
    name: "eye in speech bubble",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F441 200D 1F5E8",
    char: "👁‍🗨",
    name: "eye in speech bubble",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F5E8 FE0F",
    char: "🗨️",
    name: "left speech bubble",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F5E8",
    char: "🗨",
    name: "left speech bubble",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F5EF FE0F",
    char: "🗯️",
    name: "right anger bubble",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F5EF",
    char: "🗯",
    name: "right anger bubble",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F4AD",
    char: "💭",
    name: "thought balloon",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F4A4",
    char: "💤",
    name: "ZZZ",
    category: "Smileys & Emotion (emotion)",
    group: "Smileys & Emotion",
    subgroup: "emotion",
  },
  {
    codes: "1F44B",
    char: "👋",
    name: "waving hand",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F44B 1F3FB",
    char: "👋🏻",
    name: "waving hand: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F44B 1F3FC",
    char: "👋🏼",
    name: "waving hand: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F44B 1F3FD",
    char: "👋🏽",
    name: "waving hand: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F44B 1F3FE",
    char: "👋🏾",
    name: "waving hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F44B 1F3FF",
    char: "👋🏿",
    name: "waving hand: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F91A",
    char: "🤚",
    name: "raised back of hand",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F91A 1F3FB",
    char: "🤚🏻",
    name: "raised back of hand: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F91A 1F3FC",
    char: "🤚🏼",
    name: "raised back of hand: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F91A 1F3FD",
    char: "🤚🏽",
    name: "raised back of hand: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F91A 1F3FE",
    char: "🤚🏾",
    name: "raised back of hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F91A 1F3FF",
    char: "🤚🏿",
    name: "raised back of hand: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F590 FE0F",
    char: "🖐️",
    name: "hand with fingers splayed",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F590",
    char: "🖐",
    name: "hand with fingers splayed",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F590 1F3FB",
    char: "🖐🏻",
    name: "hand with fingers splayed: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F590 1F3FC",
    char: "🖐🏼",
    name: "hand with fingers splayed: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F590 1F3FD",
    char: "🖐🏽",
    name: "hand with fingers splayed: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F590 1F3FE",
    char: "🖐🏾",
    name: "hand with fingers splayed: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F590 1F3FF",
    char: "🖐🏿",
    name: "hand with fingers splayed: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "270B",
    char: "✋",
    name: "raised hand",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "270B 1F3FB",
    char: "✋🏻",
    name: "raised hand: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "270B 1F3FC",
    char: "✋🏼",
    name: "raised hand: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "270B 1F3FD",
    char: "✋🏽",
    name: "raised hand: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "270B 1F3FE",
    char: "✋🏾",
    name: "raised hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "270B 1F3FF",
    char: "✋🏿",
    name: "raised hand: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F596",
    char: "🖖",
    name: "vulcan salute",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F596 1F3FB",
    char: "🖖🏻",
    name: "vulcan salute: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F596 1F3FC",
    char: "🖖🏼",
    name: "vulcan salute: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F596 1F3FD",
    char: "🖖🏽",
    name: "vulcan salute: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F596 1F3FE",
    char: "🖖🏾",
    name: "vulcan salute: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F596 1F3FF",
    char: "🖖🏿",
    name: "vulcan salute: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF1",
    char: "🫱",
    name: "rightwards hand",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF1 1F3FB",
    char: "🫱🏻",
    name: "rightwards hand: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF1 1F3FC",
    char: "🫱🏼",
    name: "rightwards hand: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF1 1F3FD",
    char: "🫱🏽",
    name: "rightwards hand: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF1 1F3FE",
    char: "🫱🏾",
    name: "rightwards hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF1 1F3FF",
    char: "🫱🏿",
    name: "rightwards hand: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF2",
    char: "🫲",
    name: "leftwards hand",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF2 1F3FB",
    char: "🫲🏻",
    name: "leftwards hand: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF2 1F3FC",
    char: "🫲🏼",
    name: "leftwards hand: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF2 1F3FD",
    char: "🫲🏽",
    name: "leftwards hand: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF2 1F3FE",
    char: "🫲🏾",
    name: "leftwards hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF2 1F3FF",
    char: "🫲🏿",
    name: "leftwards hand: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF3",
    char: "🫳",
    name: "palm down hand",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF3 1F3FB",
    char: "🫳🏻",
    name: "palm down hand: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF3 1F3FC",
    char: "🫳🏼",
    name: "palm down hand: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF3 1F3FD",
    char: "🫳🏽",
    name: "palm down hand: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF3 1F3FE",
    char: "🫳🏾",
    name: "palm down hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF3 1F3FF",
    char: "🫳🏿",
    name: "palm down hand: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF4",
    char: "🫴",
    name: "palm up hand",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF4 1F3FB",
    char: "🫴🏻",
    name: "palm up hand: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF4 1F3FC",
    char: "🫴🏼",
    name: "palm up hand: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF4 1F3FD",
    char: "🫴🏽",
    name: "palm up hand: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF4 1F3FE",
    char: "🫴🏾",
    name: "palm up hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF4 1F3FF",
    char: "🫴🏿",
    name: "palm up hand: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF7",
    char: "🫷",
    name: "leftwards pushing hand",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF7 1F3FB",
    char: "🫷🏻",
    name: "leftwards pushing hand: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF7 1F3FC",
    char: "🫷🏼",
    name: "leftwards pushing hand: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF7 1F3FD",
    char: "🫷🏽",
    name: "leftwards pushing hand: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF7 1F3FE",
    char: "🫷🏾",
    name: "leftwards pushing hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF7 1F3FF",
    char: "🫷🏿",
    name: "leftwards pushing hand: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF8",
    char: "🫸",
    name: "rightwards pushing hand",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF8 1F3FB",
    char: "🫸🏻",
    name: "rightwards pushing hand: light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF8 1F3FC",
    char: "🫸🏼",
    name: "rightwards pushing hand: medium-light skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF8 1F3FD",
    char: "🫸🏽",
    name: "rightwards pushing hand: medium skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF8 1F3FE",
    char: "🫸🏾",
    name: "rightwards pushing hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1FAF8 1F3FF",
    char: "🫸🏿",
    name: "rightwards pushing hand: dark skin tone",
    category: "People & Body (hand-fingers-open)",
    group: "People & Body",
    subgroup: "hand-fingers-open",
  },
  {
    codes: "1F44C",
    char: "👌",
    name: "OK hand",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F44C 1F3FB",
    char: "👌🏻",
    name: "OK hand: light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F44C 1F3FC",
    char: "👌🏼",
    name: "OK hand: medium-light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F44C 1F3FD",
    char: "👌🏽",
    name: "OK hand: medium skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F44C 1F3FE",
    char: "👌🏾",
    name: "OK hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F44C 1F3FF",
    char: "👌🏿",
    name: "OK hand: dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90C",
    char: "🤌",
    name: "pinched fingers",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90C 1F3FB",
    char: "🤌🏻",
    name: "pinched fingers: light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90C 1F3FC",
    char: "🤌🏼",
    name: "pinched fingers: medium-light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90C 1F3FD",
    char: "🤌🏽",
    name: "pinched fingers: medium skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90C 1F3FE",
    char: "🤌🏾",
    name: "pinched fingers: medium-dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90C 1F3FF",
    char: "🤌🏿",
    name: "pinched fingers: dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90F",
    char: "🤏",
    name: "pinching hand",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90F 1F3FB",
    char: "🤏🏻",
    name: "pinching hand: light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90F 1F3FC",
    char: "🤏🏼",
    name: "pinching hand: medium-light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90F 1F3FD",
    char: "🤏🏽",
    name: "pinching hand: medium skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90F 1F3FE",
    char: "🤏🏾",
    name: "pinching hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F90F 1F3FF",
    char: "🤏🏿",
    name: "pinching hand: dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "270C FE0F",
    char: "✌️",
    name: "victory hand",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "270C",
    char: "✌",
    name: "victory hand",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "270C 1F3FB",
    char: "✌🏻",
    name: "victory hand: light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "270C 1F3FC",
    char: "✌🏼",
    name: "victory hand: medium-light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "270C 1F3FD",
    char: "✌🏽",
    name: "victory hand: medium skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "270C 1F3FE",
    char: "✌🏾",
    name: "victory hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "270C 1F3FF",
    char: "✌🏿",
    name: "victory hand: dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91E",
    char: "🤞",
    name: "crossed fingers",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91E 1F3FB",
    char: "🤞🏻",
    name: "crossed fingers: light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91E 1F3FC",
    char: "🤞🏼",
    name: "crossed fingers: medium-light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91E 1F3FD",
    char: "🤞🏽",
    name: "crossed fingers: medium skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91E 1F3FE",
    char: "🤞🏾",
    name: "crossed fingers: medium-dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91E 1F3FF",
    char: "🤞🏿",
    name: "crossed fingers: dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1FAF0",
    char: "🫰",
    name: "hand with index finger and thumb crossed",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1FAF0 1F3FB",
    char: "🫰🏻",
    name: "hand with index finger and thumb crossed: light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1FAF0 1F3FC",
    char: "🫰🏼",
    name: "hand with index finger and thumb crossed: medium-light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1FAF0 1F3FD",
    char: "🫰🏽",
    name: "hand with index finger and thumb crossed: medium skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1FAF0 1F3FE",
    char: "🫰🏾",
    name: "hand with index finger and thumb crossed: medium-dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1FAF0 1F3FF",
    char: "🫰🏿",
    name: "hand with index finger and thumb crossed: dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91F",
    char: "🤟",
    name: "love-you gesture",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91F 1F3FB",
    char: "🤟🏻",
    name: "love-you gesture: light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91F 1F3FC",
    char: "🤟🏼",
    name: "love-you gesture: medium-light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91F 1F3FD",
    char: "🤟🏽",
    name: "love-you gesture: medium skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91F 1F3FE",
    char: "🤟🏾",
    name: "love-you gesture: medium-dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F91F 1F3FF",
    char: "🤟🏿",
    name: "love-you gesture: dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F918",
    char: "🤘",
    name: "sign of the horns",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F918 1F3FB",
    char: "🤘🏻",
    name: "sign of the horns: light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F918 1F3FC",
    char: "🤘🏼",
    name: "sign of the horns: medium-light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F918 1F3FD",
    char: "🤘🏽",
    name: "sign of the horns: medium skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F918 1F3FE",
    char: "🤘🏾",
    name: "sign of the horns: medium-dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F918 1F3FF",
    char: "🤘🏿",
    name: "sign of the horns: dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F919",
    char: "🤙",
    name: "call me hand",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F919 1F3FB",
    char: "🤙🏻",
    name: "call me hand: light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F919 1F3FC",
    char: "🤙🏼",
    name: "call me hand: medium-light skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F919 1F3FD",
    char: "🤙🏽",
    name: "call me hand: medium skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F919 1F3FE",
    char: "🤙🏾",
    name: "call me hand: medium-dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F919 1F3FF",
    char: "🤙🏿",
    name: "call me hand: dark skin tone",
    category: "People & Body (hand-fingers-partial)",
    group: "People & Body",
    subgroup: "hand-fingers-partial",
  },
  {
    codes: "1F448",
    char: "👈",
    name: "backhand index pointing left",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F448 1F3FB",
    char: "👈🏻",
    name: "backhand index pointing left: light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F448 1F3FC",
    char: "👈🏼",
    name: "backhand index pointing left: medium-light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F448 1F3FD",
    char: "👈🏽",
    name: "backhand index pointing left: medium skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F448 1F3FE",
    char: "👈🏾",
    name: "backhand index pointing left: medium-dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F448 1F3FF",
    char: "👈🏿",
    name: "backhand index pointing left: dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F449",
    char: "👉",
    name: "backhand index pointing right",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F449 1F3FB",
    char: "👉🏻",
    name: "backhand index pointing right: light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F449 1F3FC",
    char: "👉🏼",
    name: "backhand index pointing right: medium-light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F449 1F3FD",
    char: "👉🏽",
    name: "backhand index pointing right: medium skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F449 1F3FE",
    char: "👉🏾",
    name: "backhand index pointing right: medium-dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F449 1F3FF",
    char: "👉🏿",
    name: "backhand index pointing right: dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F446",
    char: "👆",
    name: "backhand index pointing up",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F446 1F3FB",
    char: "👆🏻",
    name: "backhand index pointing up: light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F446 1F3FC",
    char: "👆🏼",
    name: "backhand index pointing up: medium-light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F446 1F3FD",
    char: "👆🏽",
    name: "backhand index pointing up: medium skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F446 1F3FE",
    char: "👆🏾",
    name: "backhand index pointing up: medium-dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F446 1F3FF",
    char: "👆🏿",
    name: "backhand index pointing up: dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F595",
    char: "🖕",
    name: "middle finger",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F595 1F3FB",
    char: "🖕🏻",
    name: "middle finger: light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F595 1F3FC",
    char: "🖕🏼",
    name: "middle finger: medium-light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F595 1F3FD",
    char: "🖕🏽",
    name: "middle finger: medium skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F595 1F3FE",
    char: "🖕🏾",
    name: "middle finger: medium-dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F595 1F3FF",
    char: "🖕🏿",
    name: "middle finger: dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F447",
    char: "👇",
    name: "backhand index pointing down",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F447 1F3FB",
    char: "👇🏻",
    name: "backhand index pointing down: light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F447 1F3FC",
    char: "👇🏼",
    name: "backhand index pointing down: medium-light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F447 1F3FD",
    char: "👇🏽",
    name: "backhand index pointing down: medium skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F447 1F3FE",
    char: "👇🏾",
    name: "backhand index pointing down: medium-dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F447 1F3FF",
    char: "👇🏿",
    name: "backhand index pointing down: dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "261D FE0F",
    char: "☝️",
    name: "index pointing up",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "261D",
    char: "☝",
    name: "index pointing up",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "261D 1F3FB",
    char: "☝🏻",
    name: "index pointing up: light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "261D 1F3FC",
    char: "☝🏼",
    name: "index pointing up: medium-light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "261D 1F3FD",
    char: "☝🏽",
    name: "index pointing up: medium skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "261D 1F3FE",
    char: "☝🏾",
    name: "index pointing up: medium-dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "261D 1F3FF",
    char: "☝🏿",
    name: "index pointing up: dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1FAF5",
    char: "🫵",
    name: "index pointing at the viewer",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1FAF5 1F3FB",
    char: "🫵🏻",
    name: "index pointing at the viewer: light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1FAF5 1F3FC",
    char: "🫵🏼",
    name: "index pointing at the viewer: medium-light skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1FAF5 1F3FD",
    char: "🫵🏽",
    name: "index pointing at the viewer: medium skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1FAF5 1F3FE",
    char: "🫵🏾",
    name: "index pointing at the viewer: medium-dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1FAF5 1F3FF",
    char: "🫵🏿",
    name: "index pointing at the viewer: dark skin tone",
    category: "People & Body (hand-single-finger)",
    group: "People & Body",
    subgroup: "hand-single-finger",
  },
  {
    codes: "1F44D",
    char: "👍",
    name: "thumbs up",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44D 1F3FB",
    char: "👍🏻",
    name: "thumbs up: light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44D 1F3FC",
    char: "👍🏼",
    name: "thumbs up: medium-light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44D 1F3FD",
    char: "👍🏽",
    name: "thumbs up: medium skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44D 1F3FE",
    char: "👍🏾",
    name: "thumbs up: medium-dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44D 1F3FF",
    char: "👍🏿",
    name: "thumbs up: dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44E",
    char: "👎",
    name: "thumbs down",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44E 1F3FB",
    char: "👎🏻",
    name: "thumbs down: light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44E 1F3FC",
    char: "👎🏼",
    name: "thumbs down: medium-light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44E 1F3FD",
    char: "👎🏽",
    name: "thumbs down: medium skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44E 1F3FE",
    char: "👎🏾",
    name: "thumbs down: medium-dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44E 1F3FF",
    char: "👎🏿",
    name: "thumbs down: dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "270A",
    char: "✊",
    name: "raised fist",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "270A 1F3FB",
    char: "✊🏻",
    name: "raised fist: light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "270A 1F3FC",
    char: "✊🏼",
    name: "raised fist: medium-light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "270A 1F3FD",
    char: "✊🏽",
    name: "raised fist: medium skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "270A 1F3FE",
    char: "✊🏾",
    name: "raised fist: medium-dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "270A 1F3FF",
    char: "✊🏿",
    name: "raised fist: dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44A",
    char: "👊",
    name: "oncoming fist",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44A 1F3FB",
    char: "👊🏻",
    name: "oncoming fist: light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44A 1F3FC",
    char: "👊🏼",
    name: "oncoming fist: medium-light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44A 1F3FD",
    char: "👊🏽",
    name: "oncoming fist: medium skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44A 1F3FE",
    char: "👊🏾",
    name: "oncoming fist: medium-dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44A 1F3FF",
    char: "👊🏿",
    name: "oncoming fist: dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91B",
    char: "🤛",
    name: "left-facing fist",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91B 1F3FB",
    char: "🤛🏻",
    name: "left-facing fist: light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91B 1F3FC",
    char: "🤛🏼",
    name: "left-facing fist: medium-light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91B 1F3FD",
    char: "🤛🏽",
    name: "left-facing fist: medium skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91B 1F3FE",
    char: "🤛🏾",
    name: "left-facing fist: medium-dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91B 1F3FF",
    char: "🤛🏿",
    name: "left-facing fist: dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91C",
    char: "🤜",
    name: "right-facing fist",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91C 1F3FB",
    char: "🤜🏻",
    name: "right-facing fist: light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91C 1F3FC",
    char: "🤜🏼",
    name: "right-facing fist: medium-light skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91C 1F3FD",
    char: "🤜🏽",
    name: "right-facing fist: medium skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91C 1F3FE",
    char: "🤜🏾",
    name: "right-facing fist: medium-dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F91C 1F3FF",
    char: "🤜🏿",
    name: "right-facing fist: dark skin tone",
    category: "People & Body (hand-fingers-closed)",
    group: "People & Body",
    subgroup: "hand-fingers-closed",
  },
  {
    codes: "1F44F",
    char: "👏",
    name: "clapping hands",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F44F 1F3FB",
    char: "👏🏻",
    name: "clapping hands: light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F44F 1F3FC",
    char: "👏🏼",
    name: "clapping hands: medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F44F 1F3FD",
    char: "👏🏽",
    name: "clapping hands: medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F44F 1F3FE",
    char: "👏🏾",
    name: "clapping hands: medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F44F 1F3FF",
    char: "👏🏿",
    name: "clapping hands: dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64C",
    char: "🙌",
    name: "raising hands",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64C 1F3FB",
    char: "🙌🏻",
    name: "raising hands: light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64C 1F3FC",
    char: "🙌🏼",
    name: "raising hands: medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64C 1F3FD",
    char: "🙌🏽",
    name: "raising hands: medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64C 1F3FE",
    char: "🙌🏾",
    name: "raising hands: medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64C 1F3FF",
    char: "🙌🏿",
    name: "raising hands: dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF6",
    char: "🫶",
    name: "heart hands",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF6 1F3FB",
    char: "🫶🏻",
    name: "heart hands: light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF6 1F3FC",
    char: "🫶🏼",
    name: "heart hands: medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF6 1F3FD",
    char: "🫶🏽",
    name: "heart hands: medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF6 1F3FE",
    char: "🫶🏾",
    name: "heart hands: medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF6 1F3FF",
    char: "🫶🏿",
    name: "heart hands: dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F450",
    char: "👐",
    name: "open hands",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F450 1F3FB",
    char: "👐🏻",
    name: "open hands: light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F450 1F3FC",
    char: "👐🏼",
    name: "open hands: medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F450 1F3FD",
    char: "👐🏽",
    name: "open hands: medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F450 1F3FE",
    char: "👐🏾",
    name: "open hands: medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F450 1F3FF",
    char: "👐🏿",
    name: "open hands: dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F932",
    char: "🤲",
    name: "palms up together",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F932 1F3FB",
    char: "🤲🏻",
    name: "palms up together: light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F932 1F3FC",
    char: "🤲🏼",
    name: "palms up together: medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F932 1F3FD",
    char: "🤲🏽",
    name: "palms up together: medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F932 1F3FE",
    char: "🤲🏾",
    name: "palms up together: medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F932 1F3FF",
    char: "🤲🏿",
    name: "palms up together: dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F91D",
    char: "🤝",
    name: "handshake",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F91D 1F3FB",
    char: "🤝🏻",
    name: "handshake: light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F91D 1F3FC",
    char: "🤝🏼",
    name: "handshake: medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F91D 1F3FD",
    char: "🤝🏽",
    name: "handshake: medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F91D 1F3FE",
    char: "🤝🏾",
    name: "handshake: medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F91D 1F3FF",
    char: "🤝🏿",
    name: "handshake: dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FB 200D 1FAF2 1F3FC",
    char: "🫱🏻‍🫲🏼",
    name: "handshake: light skin tone, medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FB 200D 1FAF2 1F3FD",
    char: "🫱🏻‍🫲🏽",
    name: "handshake: light skin tone, medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FB 200D 1FAF2 1F3FE",
    char: "🫱🏻‍🫲🏾",
    name: "handshake: light skin tone, medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FB 200D 1FAF2 1F3FF",
    char: "🫱🏻‍🫲🏿",
    name: "handshake: light skin tone, dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FC 200D 1FAF2 1F3FB",
    char: "🫱🏼‍🫲🏻",
    name: "handshake: medium-light skin tone, light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FC 200D 1FAF2 1F3FD",
    char: "🫱🏼‍🫲🏽",
    name: "handshake: medium-light skin tone, medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FC 200D 1FAF2 1F3FE",
    char: "🫱🏼‍🫲🏾",
    name: "handshake: medium-light skin tone, medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FC 200D 1FAF2 1F3FF",
    char: "🫱🏼‍🫲🏿",
    name: "handshake: medium-light skin tone, dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FD 200D 1FAF2 1F3FB",
    char: "🫱🏽‍🫲🏻",
    name: "handshake: medium skin tone, light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FD 200D 1FAF2 1F3FC",
    char: "🫱🏽‍🫲🏼",
    name: "handshake: medium skin tone, medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FD 200D 1FAF2 1F3FE",
    char: "🫱🏽‍🫲🏾",
    name: "handshake: medium skin tone, medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FD 200D 1FAF2 1F3FF",
    char: "🫱🏽‍🫲🏿",
    name: "handshake: medium skin tone, dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FE 200D 1FAF2 1F3FB",
    char: "🫱🏾‍🫲🏻",
    name: "handshake: medium-dark skin tone, light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FE 200D 1FAF2 1F3FC",
    char: "🫱🏾‍🫲🏼",
    name: "handshake: medium-dark skin tone, medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FE 200D 1FAF2 1F3FD",
    char: "🫱🏾‍🫲🏽",
    name: "handshake: medium-dark skin tone, medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FE 200D 1FAF2 1F3FF",
    char: "🫱🏾‍🫲🏿",
    name: "handshake: medium-dark skin tone, dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FF 200D 1FAF2 1F3FB",
    char: "🫱🏿‍🫲🏻",
    name: "handshake: dark skin tone, light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FF 200D 1FAF2 1F3FC",
    char: "🫱🏿‍🫲🏼",
    name: "handshake: dark skin tone, medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FF 200D 1FAF2 1F3FD",
    char: "🫱🏿‍🫲🏽",
    name: "handshake: dark skin tone, medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1FAF1 1F3FF 200D 1FAF2 1F3FE",
    char: "🫱🏿‍🫲🏾",
    name: "handshake: dark skin tone, medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64F",
    char: "🙏",
    name: "folded hands",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64F 1F3FB",
    char: "🙏🏻",
    name: "folded hands: light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64F 1F3FC",
    char: "🙏🏼",
    name: "folded hands: medium-light skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64F 1F3FD",
    char: "🙏🏽",
    name: "folded hands: medium skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64F 1F3FE",
    char: "🙏🏾",
    name: "folded hands: medium-dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "1F64F 1F3FF",
    char: "🙏🏿",
    name: "folded hands: dark skin tone",
    category: "People & Body (hands)",
    group: "People & Body",
    subgroup: "hands",
  },
  {
    codes: "270D FE0F",
    char: "✍️",
    name: "writing hand",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "270D",
    char: "✍",
    name: "writing hand",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "270D 1F3FB",
    char: "✍🏻",
    name: "writing hand: light skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "270D 1F3FC",
    char: "✍🏼",
    name: "writing hand: medium-light skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "270D 1F3FD",
    char: "✍🏽",
    name: "writing hand: medium skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "270D 1F3FE",
    char: "✍🏾",
    name: "writing hand: medium-dark skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "270D 1F3FF",
    char: "✍🏿",
    name: "writing hand: dark skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F485",
    char: "💅",
    name: "nail polish",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F485 1F3FB",
    char: "💅🏻",
    name: "nail polish: light skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F485 1F3FC",
    char: "💅🏼",
    name: "nail polish: medium-light skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F485 1F3FD",
    char: "💅🏽",
    name: "nail polish: medium skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F485 1F3FE",
    char: "💅🏾",
    name: "nail polish: medium-dark skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F485 1F3FF",
    char: "💅🏿",
    name: "nail polish: dark skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F933",
    char: "🤳",
    name: "selfie",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F933 1F3FB",
    char: "🤳🏻",
    name: "selfie: light skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F933 1F3FC",
    char: "🤳🏼",
    name: "selfie: medium-light skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F933 1F3FD",
    char: "🤳🏽",
    name: "selfie: medium skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F933 1F3FE",
    char: "🤳🏾",
    name: "selfie: medium-dark skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F933 1F3FF",
    char: "🤳🏿",
    name: "selfie: dark skin tone",
    category: "People & Body (hand-prop)",
    group: "People & Body",
    subgroup: "hand-prop",
  },
  {
    codes: "1F4AA",
    char: "💪",
    name: "flexed biceps",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F4AA 1F3FB",
    char: "💪🏻",
    name: "flexed biceps: light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F4AA 1F3FC",
    char: "💪🏼",
    name: "flexed biceps: medium-light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F4AA 1F3FD",
    char: "💪🏽",
    name: "flexed biceps: medium skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F4AA 1F3FE",
    char: "💪🏾",
    name: "flexed biceps: medium-dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F4AA 1F3FF",
    char: "💪🏿",
    name: "flexed biceps: dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9BE",
    char: "🦾",
    name: "mechanical arm",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9BF",
    char: "🦿",
    name: "mechanical leg",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B5",
    char: "🦵",
    name: "leg",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B5 1F3FB",
    char: "🦵🏻",
    name: "leg: light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B5 1F3FC",
    char: "🦵🏼",
    name: "leg: medium-light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B5 1F3FD",
    char: "🦵🏽",
    name: "leg: medium skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B5 1F3FE",
    char: "🦵🏾",
    name: "leg: medium-dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B5 1F3FF",
    char: "🦵🏿",
    name: "leg: dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B6",
    char: "🦶",
    name: "foot",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B6 1F3FB",
    char: "🦶🏻",
    name: "foot: light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B6 1F3FC",
    char: "🦶🏼",
    name: "foot: medium-light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B6 1F3FD",
    char: "🦶🏽",
    name: "foot: medium skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B6 1F3FE",
    char: "🦶🏾",
    name: "foot: medium-dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B6 1F3FF",
    char: "🦶🏿",
    name: "foot: dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F442",
    char: "👂",
    name: "ear",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F442 1F3FB",
    char: "👂🏻",
    name: "ear: light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F442 1F3FC",
    char: "👂🏼",
    name: "ear: medium-light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F442 1F3FD",
    char: "👂🏽",
    name: "ear: medium skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F442 1F3FE",
    char: "👂🏾",
    name: "ear: medium-dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F442 1F3FF",
    char: "👂🏿",
    name: "ear: dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9BB",
    char: "🦻",
    name: "ear with hearing aid",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9BB 1F3FB",
    char: "🦻🏻",
    name: "ear with hearing aid: light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9BB 1F3FC",
    char: "🦻🏼",
    name: "ear with hearing aid: medium-light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9BB 1F3FD",
    char: "🦻🏽",
    name: "ear with hearing aid: medium skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9BB 1F3FE",
    char: "🦻🏾",
    name: "ear with hearing aid: medium-dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9BB 1F3FF",
    char: "🦻🏿",
    name: "ear with hearing aid: dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F443",
    char: "👃",
    name: "nose",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F443 1F3FB",
    char: "👃🏻",
    name: "nose: light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F443 1F3FC",
    char: "👃🏼",
    name: "nose: medium-light skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F443 1F3FD",
    char: "👃🏽",
    name: "nose: medium skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F443 1F3FE",
    char: "👃🏾",
    name: "nose: medium-dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F443 1F3FF",
    char: "👃🏿",
    name: "nose: dark skin tone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9E0",
    char: "🧠",
    name: "brain",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1FAC0",
    char: "🫀",
    name: "anatomical heart",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1FAC1",
    char: "🫁",
    name: "lungs",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B7",
    char: "🦷",
    name: "tooth",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F9B4",
    char: "🦴",
    name: "bone",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F440",
    char: "👀",
    name: "eyes",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F441 FE0F",
    char: "👁️",
    name: "eye",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F441",
    char: "👁",
    name: "eye",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F445",
    char: "👅",
    name: "tongue",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F444",
    char: "👄",
    name: "mouth",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1FAE6",
    char: "🫦",
    name: "biting lip",
    category: "People & Body (body-parts)",
    group: "People & Body",
    subgroup: "body-parts",
  },
  {
    codes: "1F476",
    char: "👶",
    name: "baby",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F476 1F3FB",
    char: "👶🏻",
    name: "baby: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F476 1F3FC",
    char: "👶🏼",
    name: "baby: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F476 1F3FD",
    char: "👶🏽",
    name: "baby: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F476 1F3FE",
    char: "👶🏾",
    name: "baby: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F476 1F3FF",
    char: "👶🏿",
    name: "baby: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D2",
    char: "🧒",
    name: "child",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D2 1F3FB",
    char: "🧒🏻",
    name: "child: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D2 1F3FC",
    char: "🧒🏼",
    name: "child: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D2 1F3FD",
    char: "🧒🏽",
    name: "child: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D2 1F3FE",
    char: "🧒🏾",
    name: "child: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D2 1F3FF",
    char: "🧒🏿",
    name: "child: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F466",
    char: "👦",
    name: "boy",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F466 1F3FB",
    char: "👦🏻",
    name: "boy: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F466 1F3FC",
    char: "👦🏼",
    name: "boy: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F466 1F3FD",
    char: "👦🏽",
    name: "boy: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F466 1F3FE",
    char: "👦🏾",
    name: "boy: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F466 1F3FF",
    char: "👦🏿",
    name: "boy: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F467",
    char: "👧",
    name: "girl",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F467 1F3FB",
    char: "👧🏻",
    name: "girl: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F467 1F3FC",
    char: "👧🏼",
    name: "girl: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F467 1F3FD",
    char: "👧🏽",
    name: "girl: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F467 1F3FE",
    char: "👧🏾",
    name: "girl: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F467 1F3FF",
    char: "👧🏿",
    name: "girl: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1",
    char: "🧑",
    name: "person",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FB",
    char: "🧑🏻",
    name: "person: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FC",
    char: "🧑🏼",
    name: "person: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FD",
    char: "🧑🏽",
    name: "person: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FE",
    char: "🧑🏾",
    name: "person: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FF",
    char: "🧑🏿",
    name: "person: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471",
    char: "👱",
    name: "person: blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FB",
    char: "👱🏻",
    name: "person: light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FC",
    char: "👱🏼",
    name: "person: medium-light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FD",
    char: "👱🏽",
    name: "person: medium skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FE",
    char: "👱🏾",
    name: "person: medium-dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FF",
    char: "👱🏿",
    name: "person: dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468",
    char: "👨",
    name: "man",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FB",
    char: "👨🏻",
    name: "man: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FC",
    char: "👨🏼",
    name: "man: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FD",
    char: "👨🏽",
    name: "man: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FE",
    char: "👨🏾",
    name: "man: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FF",
    char: "👨🏿",
    name: "man: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4",
    char: "🧔",
    name: "person: beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FB",
    char: "🧔🏻",
    name: "person: light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FC",
    char: "🧔🏼",
    name: "person: medium-light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FD",
    char: "🧔🏽",
    name: "person: medium skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FE",
    char: "🧔🏾",
    name: "person: medium-dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FF",
    char: "🧔🏿",
    name: "person: dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 200D 2642 FE0F",
    char: "🧔‍♂️",
    name: "man: beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 200D 2642",
    char: "🧔‍♂",
    name: "man: beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FB 200D 2642 FE0F",
    char: "🧔🏻‍♂️",
    name: "man: light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FB 200D 2642",
    char: "🧔🏻‍♂",
    name: "man: light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FC 200D 2642 FE0F",
    char: "🧔🏼‍♂️",
    name: "man: medium-light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FC 200D 2642",
    char: "🧔🏼‍♂",
    name: "man: medium-light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FD 200D 2642 FE0F",
    char: "🧔🏽‍♂️",
    name: "man: medium skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FD 200D 2642",
    char: "🧔🏽‍♂",
    name: "man: medium skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FE 200D 2642 FE0F",
    char: "🧔🏾‍♂️",
    name: "man: medium-dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FE 200D 2642",
    char: "🧔🏾‍♂",
    name: "man: medium-dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FF 200D 2642 FE0F",
    char: "🧔🏿‍♂️",
    name: "man: dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FF 200D 2642",
    char: "🧔🏿‍♂",
    name: "man: dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 200D 2640 FE0F",
    char: "🧔‍♀️",
    name: "woman: beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 200D 2640",
    char: "🧔‍♀",
    name: "woman: beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FB 200D 2640 FE0F",
    char: "🧔🏻‍♀️",
    name: "woman: light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FB 200D 2640",
    char: "🧔🏻‍♀",
    name: "woman: light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FC 200D 2640 FE0F",
    char: "🧔🏼‍♀️",
    name: "woman: medium-light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FC 200D 2640",
    char: "🧔🏼‍♀",
    name: "woman: medium-light skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FD 200D 2640 FE0F",
    char: "🧔🏽‍♀️",
    name: "woman: medium skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FD 200D 2640",
    char: "🧔🏽‍♀",
    name: "woman: medium skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FE 200D 2640 FE0F",
    char: "🧔🏾‍♀️",
    name: "woman: medium-dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FE 200D 2640",
    char: "🧔🏾‍♀",
    name: "woman: medium-dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FF 200D 2640 FE0F",
    char: "🧔🏿‍♀️",
    name: "woman: dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D4 1F3FF 200D 2640",
    char: "🧔🏿‍♀",
    name: "woman: dark skin tone, beard",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 200D 1F9B0",
    char: "👨‍🦰",
    name: "man: red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FB 200D 1F9B0",
    char: "👨🏻‍🦰",
    name: "man: light skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FC 200D 1F9B0",
    char: "👨🏼‍🦰",
    name: "man: medium-light skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FD 200D 1F9B0",
    char: "👨🏽‍🦰",
    name: "man: medium skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FE 200D 1F9B0",
    char: "👨🏾‍🦰",
    name: "man: medium-dark skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FF 200D 1F9B0",
    char: "👨🏿‍🦰",
    name: "man: dark skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 200D 1F9B1",
    char: "👨‍🦱",
    name: "man: curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FB 200D 1F9B1",
    char: "👨🏻‍🦱",
    name: "man: light skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FC 200D 1F9B1",
    char: "👨🏼‍🦱",
    name: "man: medium-light skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FD 200D 1F9B1",
    char: "👨🏽‍🦱",
    name: "man: medium skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FE 200D 1F9B1",
    char: "👨🏾‍🦱",
    name: "man: medium-dark skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FF 200D 1F9B1",
    char: "👨🏿‍🦱",
    name: "man: dark skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 200D 1F9B3",
    char: "👨‍🦳",
    name: "man: white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FB 200D 1F9B3",
    char: "👨🏻‍🦳",
    name: "man: light skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FC 200D 1F9B3",
    char: "👨🏼‍🦳",
    name: "man: medium-light skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FD 200D 1F9B3",
    char: "👨🏽‍🦳",
    name: "man: medium skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FE 200D 1F9B3",
    char: "👨🏾‍🦳",
    name: "man: medium-dark skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FF 200D 1F9B3",
    char: "👨🏿‍🦳",
    name: "man: dark skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 200D 1F9B2",
    char: "👨‍🦲",
    name: "man: bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FB 200D 1F9B2",
    char: "👨🏻‍🦲",
    name: "man: light skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FC 200D 1F9B2",
    char: "👨🏼‍🦲",
    name: "man: medium-light skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FD 200D 1F9B2",
    char: "👨🏽‍🦲",
    name: "man: medium skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FE 200D 1F9B2",
    char: "👨🏾‍🦲",
    name: "man: medium-dark skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F468 1F3FF 200D 1F9B2",
    char: "👨🏿‍🦲",
    name: "man: dark skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469",
    char: "👩",
    name: "woman",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FB",
    char: "👩🏻",
    name: "woman: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FC",
    char: "👩🏼",
    name: "woman: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FD",
    char: "👩🏽",
    name: "woman: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FE",
    char: "👩🏾",
    name: "woman: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FF",
    char: "👩🏿",
    name: "woman: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 200D 1F9B0",
    char: "👩‍🦰",
    name: "woman: red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FB 200D 1F9B0",
    char: "👩🏻‍🦰",
    name: "woman: light skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FC 200D 1F9B0",
    char: "👩🏼‍🦰",
    name: "woman: medium-light skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FD 200D 1F9B0",
    char: "👩🏽‍🦰",
    name: "woman: medium skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FE 200D 1F9B0",
    char: "👩🏾‍🦰",
    name: "woman: medium-dark skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FF 200D 1F9B0",
    char: "👩🏿‍🦰",
    name: "woman: dark skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 200D 1F9B0",
    char: "🧑‍🦰",
    name: "person: red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9B0",
    char: "🧑🏻‍🦰",
    name: "person: light skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9B0",
    char: "🧑🏼‍🦰",
    name: "person: medium-light skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9B0",
    char: "🧑🏽‍🦰",
    name: "person: medium skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9B0",
    char: "🧑🏾‍🦰",
    name: "person: medium-dark skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9B0",
    char: "🧑🏿‍🦰",
    name: "person: dark skin tone, red hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 200D 1F9B1",
    char: "👩‍🦱",
    name: "woman: curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FB 200D 1F9B1",
    char: "👩🏻‍🦱",
    name: "woman: light skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FC 200D 1F9B1",
    char: "👩🏼‍🦱",
    name: "woman: medium-light skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FD 200D 1F9B1",
    char: "👩🏽‍🦱",
    name: "woman: medium skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FE 200D 1F9B1",
    char: "👩🏾‍🦱",
    name: "woman: medium-dark skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FF 200D 1F9B1",
    char: "👩🏿‍🦱",
    name: "woman: dark skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 200D 1F9B1",
    char: "🧑‍🦱",
    name: "person: curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9B1",
    char: "🧑🏻‍🦱",
    name: "person: light skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9B1",
    char: "🧑🏼‍🦱",
    name: "person: medium-light skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9B1",
    char: "🧑🏽‍🦱",
    name: "person: medium skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9B1",
    char: "🧑🏾‍🦱",
    name: "person: medium-dark skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9B1",
    char: "🧑🏿‍🦱",
    name: "person: dark skin tone, curly hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 200D 1F9B3",
    char: "👩‍🦳",
    name: "woman: white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FB 200D 1F9B3",
    char: "👩🏻‍🦳",
    name: "woman: light skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FC 200D 1F9B3",
    char: "👩🏼‍🦳",
    name: "woman: medium-light skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FD 200D 1F9B3",
    char: "👩🏽‍🦳",
    name: "woman: medium skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FE 200D 1F9B3",
    char: "👩🏾‍🦳",
    name: "woman: medium-dark skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FF 200D 1F9B3",
    char: "👩🏿‍🦳",
    name: "woman: dark skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 200D 1F9B3",
    char: "🧑‍🦳",
    name: "person: white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9B3",
    char: "🧑🏻‍🦳",
    name: "person: light skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9B3",
    char: "🧑🏼‍🦳",
    name: "person: medium-light skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9B3",
    char: "🧑🏽‍🦳",
    name: "person: medium skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9B3",
    char: "🧑🏾‍🦳",
    name: "person: medium-dark skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9B3",
    char: "🧑🏿‍🦳",
    name: "person: dark skin tone, white hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 200D 1F9B2",
    char: "👩‍🦲",
    name: "woman: bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FB 200D 1F9B2",
    char: "👩🏻‍🦲",
    name: "woman: light skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FC 200D 1F9B2",
    char: "👩🏼‍🦲",
    name: "woman: medium-light skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FD 200D 1F9B2",
    char: "👩🏽‍🦲",
    name: "woman: medium skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FE 200D 1F9B2",
    char: "👩🏾‍🦲",
    name: "woman: medium-dark skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F469 1F3FF 200D 1F9B2",
    char: "👩🏿‍🦲",
    name: "woman: dark skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 200D 1F9B2",
    char: "🧑‍🦲",
    name: "person: bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9B2",
    char: "🧑🏻‍🦲",
    name: "person: light skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9B2",
    char: "🧑🏼‍🦲",
    name: "person: medium-light skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9B2",
    char: "🧑🏽‍🦲",
    name: "person: medium skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9B2",
    char: "🧑🏾‍🦲",
    name: "person: medium-dark skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9B2",
    char: "🧑🏿‍🦲",
    name: "person: dark skin tone, bald",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 200D 2640 FE0F",
    char: "👱‍♀️",
    name: "woman: blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 200D 2640",
    char: "👱‍♀",
    name: "woman: blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FB 200D 2640 FE0F",
    char: "👱🏻‍♀️",
    name: "woman: light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FB 200D 2640",
    char: "👱🏻‍♀",
    name: "woman: light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FC 200D 2640 FE0F",
    char: "👱🏼‍♀️",
    name: "woman: medium-light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FC 200D 2640",
    char: "👱🏼‍♀",
    name: "woman: medium-light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FD 200D 2640 FE0F",
    char: "👱🏽‍♀️",
    name: "woman: medium skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FD 200D 2640",
    char: "👱🏽‍♀",
    name: "woman: medium skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FE 200D 2640 FE0F",
    char: "👱🏾‍♀️",
    name: "woman: medium-dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FE 200D 2640",
    char: "👱🏾‍♀",
    name: "woman: medium-dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FF 200D 2640 FE0F",
    char: "👱🏿‍♀️",
    name: "woman: dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FF 200D 2640",
    char: "👱🏿‍♀",
    name: "woman: dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 200D 2642 FE0F",
    char: "👱‍♂️",
    name: "man: blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 200D 2642",
    char: "👱‍♂",
    name: "man: blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FB 200D 2642 FE0F",
    char: "👱🏻‍♂️",
    name: "man: light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FB 200D 2642",
    char: "👱🏻‍♂",
    name: "man: light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FC 200D 2642 FE0F",
    char: "👱🏼‍♂️",
    name: "man: medium-light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FC 200D 2642",
    char: "👱🏼‍♂",
    name: "man: medium-light skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FD 200D 2642 FE0F",
    char: "👱🏽‍♂️",
    name: "man: medium skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FD 200D 2642",
    char: "👱🏽‍♂",
    name: "man: medium skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FE 200D 2642 FE0F",
    char: "👱🏾‍♂️",
    name: "man: medium-dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FE 200D 2642",
    char: "👱🏾‍♂",
    name: "man: medium-dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FF 200D 2642 FE0F",
    char: "👱🏿‍♂️",
    name: "man: dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F471 1F3FF 200D 2642",
    char: "👱🏿‍♂",
    name: "man: dark skin tone, blond hair",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D3",
    char: "🧓",
    name: "older person",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D3 1F3FB",
    char: "🧓🏻",
    name: "older person: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D3 1F3FC",
    char: "🧓🏼",
    name: "older person: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D3 1F3FD",
    char: "🧓🏽",
    name: "older person: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D3 1F3FE",
    char: "🧓🏾",
    name: "older person: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F9D3 1F3FF",
    char: "🧓🏿",
    name: "older person: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F474",
    char: "👴",
    name: "old man",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F474 1F3FB",
    char: "👴🏻",
    name: "old man: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F474 1F3FC",
    char: "👴🏼",
    name: "old man: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F474 1F3FD",
    char: "👴🏽",
    name: "old man: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F474 1F3FE",
    char: "👴🏾",
    name: "old man: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F474 1F3FF",
    char: "👴🏿",
    name: "old man: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F475",
    char: "👵",
    name: "old woman",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F475 1F3FB",
    char: "👵🏻",
    name: "old woman: light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F475 1F3FC",
    char: "👵🏼",
    name: "old woman: medium-light skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F475 1F3FD",
    char: "👵🏽",
    name: "old woman: medium skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F475 1F3FE",
    char: "👵🏾",
    name: "old woman: medium-dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F475 1F3FF",
    char: "👵🏿",
    name: "old woman: dark skin tone",
    category: "People & Body (person)",
    group: "People & Body",
    subgroup: "person",
  },
  {
    codes: "1F64D",
    char: "🙍",
    name: "person frowning",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FB",
    char: "🙍🏻",
    name: "person frowning: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FC",
    char: "🙍🏼",
    name: "person frowning: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FD",
    char: "🙍🏽",
    name: "person frowning: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FE",
    char: "🙍🏾",
    name: "person frowning: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FF",
    char: "🙍🏿",
    name: "person frowning: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 200D 2642 FE0F",
    char: "🙍‍♂️",
    name: "man frowning",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 200D 2642",
    char: "🙍‍♂",
    name: "man frowning",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FB 200D 2642 FE0F",
    char: "🙍🏻‍♂️",
    name: "man frowning: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FB 200D 2642",
    char: "🙍🏻‍♂",
    name: "man frowning: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FC 200D 2642 FE0F",
    char: "🙍🏼‍♂️",
    name: "man frowning: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FC 200D 2642",
    char: "🙍🏼‍♂",
    name: "man frowning: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FD 200D 2642 FE0F",
    char: "🙍🏽‍♂️",
    name: "man frowning: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FD 200D 2642",
    char: "🙍🏽‍♂",
    name: "man frowning: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FE 200D 2642 FE0F",
    char: "🙍🏾‍♂️",
    name: "man frowning: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FE 200D 2642",
    char: "🙍🏾‍♂",
    name: "man frowning: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FF 200D 2642 FE0F",
    char: "🙍🏿‍♂️",
    name: "man frowning: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FF 200D 2642",
    char: "🙍🏿‍♂",
    name: "man frowning: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 200D 2640 FE0F",
    char: "🙍‍♀️",
    name: "woman frowning",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 200D 2640",
    char: "🙍‍♀",
    name: "woman frowning",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FB 200D 2640 FE0F",
    char: "🙍🏻‍♀️",
    name: "woman frowning: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FB 200D 2640",
    char: "🙍🏻‍♀",
    name: "woman frowning: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FC 200D 2640 FE0F",
    char: "🙍🏼‍♀️",
    name: "woman frowning: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FC 200D 2640",
    char: "🙍🏼‍♀",
    name: "woman frowning: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FD 200D 2640 FE0F",
    char: "🙍🏽‍♀️",
    name: "woman frowning: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FD 200D 2640",
    char: "🙍🏽‍♀",
    name: "woman frowning: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FE 200D 2640 FE0F",
    char: "🙍🏾‍♀️",
    name: "woman frowning: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FE 200D 2640",
    char: "🙍🏾‍♀",
    name: "woman frowning: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FF 200D 2640 FE0F",
    char: "🙍🏿‍♀️",
    name: "woman frowning: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64D 1F3FF 200D 2640",
    char: "🙍🏿‍♀",
    name: "woman frowning: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E",
    char: "🙎",
    name: "person pouting",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FB",
    char: "🙎🏻",
    name: "person pouting: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FC",
    char: "🙎🏼",
    name: "person pouting: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FD",
    char: "🙎🏽",
    name: "person pouting: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FE",
    char: "🙎🏾",
    name: "person pouting: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FF",
    char: "🙎🏿",
    name: "person pouting: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 200D 2642 FE0F",
    char: "🙎‍♂️",
    name: "man pouting",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 200D 2642",
    char: "🙎‍♂",
    name: "man pouting",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FB 200D 2642 FE0F",
    char: "🙎🏻‍♂️",
    name: "man pouting: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FB 200D 2642",
    char: "🙎🏻‍♂",
    name: "man pouting: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FC 200D 2642 FE0F",
    char: "🙎🏼‍♂️",
    name: "man pouting: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FC 200D 2642",
    char: "🙎🏼‍♂",
    name: "man pouting: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FD 200D 2642 FE0F",
    char: "🙎🏽‍♂️",
    name: "man pouting: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FD 200D 2642",
    char: "🙎🏽‍♂",
    name: "man pouting: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FE 200D 2642 FE0F",
    char: "🙎🏾‍♂️",
    name: "man pouting: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FE 200D 2642",
    char: "🙎🏾‍♂",
    name: "man pouting: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FF 200D 2642 FE0F",
    char: "🙎🏿‍♂️",
    name: "man pouting: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FF 200D 2642",
    char: "🙎🏿‍♂",
    name: "man pouting: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 200D 2640 FE0F",
    char: "🙎‍♀️",
    name: "woman pouting",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 200D 2640",
    char: "🙎‍♀",
    name: "woman pouting",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FB 200D 2640 FE0F",
    char: "🙎🏻‍♀️",
    name: "woman pouting: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FB 200D 2640",
    char: "🙎🏻‍♀",
    name: "woman pouting: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FC 200D 2640 FE0F",
    char: "🙎🏼‍♀️",
    name: "woman pouting: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FC 200D 2640",
    char: "🙎🏼‍♀",
    name: "woman pouting: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FD 200D 2640 FE0F",
    char: "🙎🏽‍♀️",
    name: "woman pouting: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FD 200D 2640",
    char: "🙎🏽‍♀",
    name: "woman pouting: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FE 200D 2640 FE0F",
    char: "🙎🏾‍♀️",
    name: "woman pouting: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FE 200D 2640",
    char: "🙎🏾‍♀",
    name: "woman pouting: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FF 200D 2640 FE0F",
    char: "🙎🏿‍♀️",
    name: "woman pouting: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64E 1F3FF 200D 2640",
    char: "🙎🏿‍♀",
    name: "woman pouting: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645",
    char: "🙅",
    name: "person gesturing NO",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FB",
    char: "🙅🏻",
    name: "person gesturing NO: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FC",
    char: "🙅🏼",
    name: "person gesturing NO: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FD",
    char: "🙅🏽",
    name: "person gesturing NO: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FE",
    char: "🙅🏾",
    name: "person gesturing NO: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FF",
    char: "🙅🏿",
    name: "person gesturing NO: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 200D 2642 FE0F",
    char: "🙅‍♂️",
    name: "man gesturing NO",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 200D 2642",
    char: "🙅‍♂",
    name: "man gesturing NO",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FB 200D 2642 FE0F",
    char: "🙅🏻‍♂️",
    name: "man gesturing NO: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FB 200D 2642",
    char: "🙅🏻‍♂",
    name: "man gesturing NO: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FC 200D 2642 FE0F",
    char: "🙅🏼‍♂️",
    name: "man gesturing NO: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FC 200D 2642",
    char: "🙅🏼‍♂",
    name: "man gesturing NO: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FD 200D 2642 FE0F",
    char: "🙅🏽‍♂️",
    name: "man gesturing NO: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FD 200D 2642",
    char: "🙅🏽‍♂",
    name: "man gesturing NO: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FE 200D 2642 FE0F",
    char: "🙅🏾‍♂️",
    name: "man gesturing NO: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FE 200D 2642",
    char: "🙅🏾‍♂",
    name: "man gesturing NO: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FF 200D 2642 FE0F",
    char: "🙅🏿‍♂️",
    name: "man gesturing NO: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FF 200D 2642",
    char: "🙅🏿‍♂",
    name: "man gesturing NO: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 200D 2640 FE0F",
    char: "🙅‍♀️",
    name: "woman gesturing NO",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 200D 2640",
    char: "🙅‍♀",
    name: "woman gesturing NO",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FB 200D 2640 FE0F",
    char: "🙅🏻‍♀️",
    name: "woman gesturing NO: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FB 200D 2640",
    char: "🙅🏻‍♀",
    name: "woman gesturing NO: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FC 200D 2640 FE0F",
    char: "🙅🏼‍♀️",
    name: "woman gesturing NO: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FC 200D 2640",
    char: "🙅🏼‍♀",
    name: "woman gesturing NO: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FD 200D 2640 FE0F",
    char: "🙅🏽‍♀️",
    name: "woman gesturing NO: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FD 200D 2640",
    char: "🙅🏽‍♀",
    name: "woman gesturing NO: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FE 200D 2640 FE0F",
    char: "🙅🏾‍♀️",
    name: "woman gesturing NO: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FE 200D 2640",
    char: "🙅🏾‍♀",
    name: "woman gesturing NO: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FF 200D 2640 FE0F",
    char: "🙅🏿‍♀️",
    name: "woman gesturing NO: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F645 1F3FF 200D 2640",
    char: "🙅🏿‍♀",
    name: "woman gesturing NO: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646",
    char: "🙆",
    name: "person gesturing OK",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FB",
    char: "🙆🏻",
    name: "person gesturing OK: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FC",
    char: "🙆🏼",
    name: "person gesturing OK: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FD",
    char: "🙆🏽",
    name: "person gesturing OK: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FE",
    char: "🙆🏾",
    name: "person gesturing OK: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FF",
    char: "🙆🏿",
    name: "person gesturing OK: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 200D 2642 FE0F",
    char: "🙆‍♂️",
    name: "man gesturing OK",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 200D 2642",
    char: "🙆‍♂",
    name: "man gesturing OK",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FB 200D 2642 FE0F",
    char: "🙆🏻‍♂️",
    name: "man gesturing OK: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FB 200D 2642",
    char: "🙆🏻‍♂",
    name: "man gesturing OK: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FC 200D 2642 FE0F",
    char: "🙆🏼‍♂️",
    name: "man gesturing OK: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FC 200D 2642",
    char: "🙆🏼‍♂",
    name: "man gesturing OK: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FD 200D 2642 FE0F",
    char: "🙆🏽‍♂️",
    name: "man gesturing OK: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FD 200D 2642",
    char: "🙆🏽‍♂",
    name: "man gesturing OK: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FE 200D 2642 FE0F",
    char: "🙆🏾‍♂️",
    name: "man gesturing OK: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FE 200D 2642",
    char: "🙆🏾‍♂",
    name: "man gesturing OK: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FF 200D 2642 FE0F",
    char: "🙆🏿‍♂️",
    name: "man gesturing OK: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FF 200D 2642",
    char: "🙆🏿‍♂",
    name: "man gesturing OK: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 200D 2640 FE0F",
    char: "🙆‍♀️",
    name: "woman gesturing OK",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 200D 2640",
    char: "🙆‍♀",
    name: "woman gesturing OK",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FB 200D 2640 FE0F",
    char: "🙆🏻‍♀️",
    name: "woman gesturing OK: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FB 200D 2640",
    char: "🙆🏻‍♀",
    name: "woman gesturing OK: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FC 200D 2640 FE0F",
    char: "🙆🏼‍♀️",
    name: "woman gesturing OK: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FC 200D 2640",
    char: "🙆🏼‍♀",
    name: "woman gesturing OK: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FD 200D 2640 FE0F",
    char: "🙆🏽‍♀️",
    name: "woman gesturing OK: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FD 200D 2640",
    char: "🙆🏽‍♀",
    name: "woman gesturing OK: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FE 200D 2640 FE0F",
    char: "🙆🏾‍♀️",
    name: "woman gesturing OK: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FE 200D 2640",
    char: "🙆🏾‍♀",
    name: "woman gesturing OK: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FF 200D 2640 FE0F",
    char: "🙆🏿‍♀️",
    name: "woman gesturing OK: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F646 1F3FF 200D 2640",
    char: "🙆🏿‍♀",
    name: "woman gesturing OK: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481",
    char: "💁",
    name: "person tipping hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FB",
    char: "💁🏻",
    name: "person tipping hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FC",
    char: "💁🏼",
    name: "person tipping hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FD",
    char: "💁🏽",
    name: "person tipping hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FE",
    char: "💁🏾",
    name: "person tipping hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FF",
    char: "💁🏿",
    name: "person tipping hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 200D 2642 FE0F",
    char: "💁‍♂️",
    name: "man tipping hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 200D 2642",
    char: "💁‍♂",
    name: "man tipping hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FB 200D 2642 FE0F",
    char: "💁🏻‍♂️",
    name: "man tipping hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FB 200D 2642",
    char: "💁🏻‍♂",
    name: "man tipping hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FC 200D 2642 FE0F",
    char: "💁🏼‍♂️",
    name: "man tipping hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FC 200D 2642",
    char: "💁🏼‍♂",
    name: "man tipping hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FD 200D 2642 FE0F",
    char: "💁🏽‍♂️",
    name: "man tipping hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FD 200D 2642",
    char: "💁🏽‍♂",
    name: "man tipping hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FE 200D 2642 FE0F",
    char: "💁🏾‍♂️",
    name: "man tipping hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FE 200D 2642",
    char: "💁🏾‍♂",
    name: "man tipping hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FF 200D 2642 FE0F",
    char: "💁🏿‍♂️",
    name: "man tipping hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FF 200D 2642",
    char: "💁🏿‍♂",
    name: "man tipping hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 200D 2640 FE0F",
    char: "💁‍♀️",
    name: "woman tipping hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 200D 2640",
    char: "💁‍♀",
    name: "woman tipping hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FB 200D 2640 FE0F",
    char: "💁🏻‍♀️",
    name: "woman tipping hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FB 200D 2640",
    char: "💁🏻‍♀",
    name: "woman tipping hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FC 200D 2640 FE0F",
    char: "💁🏼‍♀️",
    name: "woman tipping hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FC 200D 2640",
    char: "💁🏼‍♀",
    name: "woman tipping hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FD 200D 2640 FE0F",
    char: "💁🏽‍♀️",
    name: "woman tipping hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FD 200D 2640",
    char: "💁🏽‍♀",
    name: "woman tipping hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FE 200D 2640 FE0F",
    char: "💁🏾‍♀️",
    name: "woman tipping hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FE 200D 2640",
    char: "💁🏾‍♀",
    name: "woman tipping hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FF 200D 2640 FE0F",
    char: "💁🏿‍♀️",
    name: "woman tipping hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F481 1F3FF 200D 2640",
    char: "💁🏿‍♀",
    name: "woman tipping hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B",
    char: "🙋",
    name: "person raising hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FB",
    char: "🙋🏻",
    name: "person raising hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FC",
    char: "🙋🏼",
    name: "person raising hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FD",
    char: "🙋🏽",
    name: "person raising hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FE",
    char: "🙋🏾",
    name: "person raising hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FF",
    char: "🙋🏿",
    name: "person raising hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 200D 2642 FE0F",
    char: "🙋‍♂️",
    name: "man raising hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 200D 2642",
    char: "🙋‍♂",
    name: "man raising hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FB 200D 2642 FE0F",
    char: "🙋🏻‍♂️",
    name: "man raising hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FB 200D 2642",
    char: "🙋🏻‍♂",
    name: "man raising hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FC 200D 2642 FE0F",
    char: "🙋🏼‍♂️",
    name: "man raising hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FC 200D 2642",
    char: "🙋🏼‍♂",
    name: "man raising hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FD 200D 2642 FE0F",
    char: "🙋🏽‍♂️",
    name: "man raising hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FD 200D 2642",
    char: "🙋🏽‍♂",
    name: "man raising hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FE 200D 2642 FE0F",
    char: "🙋🏾‍♂️",
    name: "man raising hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FE 200D 2642",
    char: "🙋🏾‍♂",
    name: "man raising hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FF 200D 2642 FE0F",
    char: "🙋🏿‍♂️",
    name: "man raising hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FF 200D 2642",
    char: "🙋🏿‍♂",
    name: "man raising hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 200D 2640 FE0F",
    char: "🙋‍♀️",
    name: "woman raising hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 200D 2640",
    char: "🙋‍♀",
    name: "woman raising hand",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FB 200D 2640 FE0F",
    char: "🙋🏻‍♀️",
    name: "woman raising hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FB 200D 2640",
    char: "🙋🏻‍♀",
    name: "woman raising hand: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FC 200D 2640 FE0F",
    char: "🙋🏼‍♀️",
    name: "woman raising hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FC 200D 2640",
    char: "🙋🏼‍♀",
    name: "woman raising hand: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FD 200D 2640 FE0F",
    char: "🙋🏽‍♀️",
    name: "woman raising hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FD 200D 2640",
    char: "🙋🏽‍♀",
    name: "woman raising hand: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FE 200D 2640 FE0F",
    char: "🙋🏾‍♀️",
    name: "woman raising hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FE 200D 2640",
    char: "🙋🏾‍♀",
    name: "woman raising hand: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FF 200D 2640 FE0F",
    char: "🙋🏿‍♀️",
    name: "woman raising hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F64B 1F3FF 200D 2640",
    char: "🙋🏿‍♀",
    name: "woman raising hand: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF",
    char: "🧏",
    name: "deaf person",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FB",
    char: "🧏🏻",
    name: "deaf person: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FC",
    char: "🧏🏼",
    name: "deaf person: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FD",
    char: "🧏🏽",
    name: "deaf person: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FE",
    char: "🧏🏾",
    name: "deaf person: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FF",
    char: "🧏🏿",
    name: "deaf person: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 200D 2642 FE0F",
    char: "🧏‍♂️",
    name: "deaf man",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 200D 2642",
    char: "🧏‍♂",
    name: "deaf man",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FB 200D 2642 FE0F",
    char: "🧏🏻‍♂️",
    name: "deaf man: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FB 200D 2642",
    char: "🧏🏻‍♂",
    name: "deaf man: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FC 200D 2642 FE0F",
    char: "🧏🏼‍♂️",
    name: "deaf man: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FC 200D 2642",
    char: "🧏🏼‍♂",
    name: "deaf man: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FD 200D 2642 FE0F",
    char: "🧏🏽‍♂️",
    name: "deaf man: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FD 200D 2642",
    char: "🧏🏽‍♂",
    name: "deaf man: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FE 200D 2642 FE0F",
    char: "🧏🏾‍♂️",
    name: "deaf man: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FE 200D 2642",
    char: "🧏🏾‍♂",
    name: "deaf man: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FF 200D 2642 FE0F",
    char: "🧏🏿‍♂️",
    name: "deaf man: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FF 200D 2642",
    char: "🧏🏿‍♂",
    name: "deaf man: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 200D 2640 FE0F",
    char: "🧏‍♀️",
    name: "deaf woman",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 200D 2640",
    char: "🧏‍♀",
    name: "deaf woman",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FB 200D 2640 FE0F",
    char: "🧏🏻‍♀️",
    name: "deaf woman: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FB 200D 2640",
    char: "🧏🏻‍♀",
    name: "deaf woman: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FC 200D 2640 FE0F",
    char: "🧏🏼‍♀️",
    name: "deaf woman: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FC 200D 2640",
    char: "🧏🏼‍♀",
    name: "deaf woman: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FD 200D 2640 FE0F",
    char: "🧏🏽‍♀️",
    name: "deaf woman: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FD 200D 2640",
    char: "🧏🏽‍♀",
    name: "deaf woman: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FE 200D 2640 FE0F",
    char: "🧏🏾‍♀️",
    name: "deaf woman: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FE 200D 2640",
    char: "🧏🏾‍♀",
    name: "deaf woman: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FF 200D 2640 FE0F",
    char: "🧏🏿‍♀️",
    name: "deaf woman: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9CF 1F3FF 200D 2640",
    char: "🧏🏿‍♀",
    name: "deaf woman: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647",
    char: "🙇",
    name: "person bowing",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FB",
    char: "🙇🏻",
    name: "person bowing: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FC",
    char: "🙇🏼",
    name: "person bowing: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FD",
    char: "🙇🏽",
    name: "person bowing: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FE",
    char: "🙇🏾",
    name: "person bowing: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FF",
    char: "🙇🏿",
    name: "person bowing: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 200D 2642 FE0F",
    char: "🙇‍♂️",
    name: "man bowing",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 200D 2642",
    char: "🙇‍♂",
    name: "man bowing",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FB 200D 2642 FE0F",
    char: "🙇🏻‍♂️",
    name: "man bowing: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FB 200D 2642",
    char: "🙇🏻‍♂",
    name: "man bowing: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FC 200D 2642 FE0F",
    char: "🙇🏼‍♂️",
    name: "man bowing: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FC 200D 2642",
    char: "🙇🏼‍♂",
    name: "man bowing: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FD 200D 2642 FE0F",
    char: "🙇🏽‍♂️",
    name: "man bowing: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FD 200D 2642",
    char: "🙇🏽‍♂",
    name: "man bowing: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FE 200D 2642 FE0F",
    char: "🙇🏾‍♂️",
    name: "man bowing: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FE 200D 2642",
    char: "🙇🏾‍♂",
    name: "man bowing: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FF 200D 2642 FE0F",
    char: "🙇🏿‍♂️",
    name: "man bowing: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FF 200D 2642",
    char: "🙇🏿‍♂",
    name: "man bowing: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 200D 2640 FE0F",
    char: "🙇‍♀️",
    name: "woman bowing",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 200D 2640",
    char: "🙇‍♀",
    name: "woman bowing",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FB 200D 2640 FE0F",
    char: "🙇🏻‍♀️",
    name: "woman bowing: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FB 200D 2640",
    char: "🙇🏻‍♀",
    name: "woman bowing: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FC 200D 2640 FE0F",
    char: "🙇🏼‍♀️",
    name: "woman bowing: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FC 200D 2640",
    char: "🙇🏼‍♀",
    name: "woman bowing: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FD 200D 2640 FE0F",
    char: "🙇🏽‍♀️",
    name: "woman bowing: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FD 200D 2640",
    char: "🙇🏽‍♀",
    name: "woman bowing: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FE 200D 2640 FE0F",
    char: "🙇🏾‍♀️",
    name: "woman bowing: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FE 200D 2640",
    char: "🙇🏾‍♀",
    name: "woman bowing: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FF 200D 2640 FE0F",
    char: "🙇🏿‍♀️",
    name: "woman bowing: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F647 1F3FF 200D 2640",
    char: "🙇🏿‍♀",
    name: "woman bowing: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926",
    char: "🤦",
    name: "person facepalming",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FB",
    char: "🤦🏻",
    name: "person facepalming: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FC",
    char: "🤦🏼",
    name: "person facepalming: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FD",
    char: "🤦🏽",
    name: "person facepalming: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FE",
    char: "🤦🏾",
    name: "person facepalming: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FF",
    char: "🤦🏿",
    name: "person facepalming: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 200D 2642 FE0F",
    char: "🤦‍♂️",
    name: "man facepalming",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 200D 2642",
    char: "🤦‍♂",
    name: "man facepalming",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FB 200D 2642 FE0F",
    char: "🤦🏻‍♂️",
    name: "man facepalming: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FB 200D 2642",
    char: "🤦🏻‍♂",
    name: "man facepalming: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FC 200D 2642 FE0F",
    char: "🤦🏼‍♂️",
    name: "man facepalming: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FC 200D 2642",
    char: "🤦🏼‍♂",
    name: "man facepalming: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FD 200D 2642 FE0F",
    char: "🤦🏽‍♂️",
    name: "man facepalming: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FD 200D 2642",
    char: "🤦🏽‍♂",
    name: "man facepalming: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FE 200D 2642 FE0F",
    char: "🤦🏾‍♂️",
    name: "man facepalming: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FE 200D 2642",
    char: "🤦🏾‍♂",
    name: "man facepalming: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FF 200D 2642 FE0F",
    char: "🤦🏿‍♂️",
    name: "man facepalming: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FF 200D 2642",
    char: "🤦🏿‍♂",
    name: "man facepalming: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 200D 2640 FE0F",
    char: "🤦‍♀️",
    name: "woman facepalming",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 200D 2640",
    char: "🤦‍♀",
    name: "woman facepalming",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FB 200D 2640 FE0F",
    char: "🤦🏻‍♀️",
    name: "woman facepalming: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FB 200D 2640",
    char: "🤦🏻‍♀",
    name: "woman facepalming: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FC 200D 2640 FE0F",
    char: "🤦🏼‍♀️",
    name: "woman facepalming: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FC 200D 2640",
    char: "🤦🏼‍♀",
    name: "woman facepalming: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FD 200D 2640 FE0F",
    char: "🤦🏽‍♀️",
    name: "woman facepalming: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FD 200D 2640",
    char: "🤦🏽‍♀",
    name: "woman facepalming: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FE 200D 2640 FE0F",
    char: "🤦🏾‍♀️",
    name: "woman facepalming: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FE 200D 2640",
    char: "🤦🏾‍♀",
    name: "woman facepalming: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FF 200D 2640 FE0F",
    char: "🤦🏿‍♀️",
    name: "woman facepalming: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F926 1F3FF 200D 2640",
    char: "🤦🏿‍♀",
    name: "woman facepalming: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937",
    char: "🤷",
    name: "person shrugging",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FB",
    char: "🤷🏻",
    name: "person shrugging: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FC",
    char: "🤷🏼",
    name: "person shrugging: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FD",
    char: "🤷🏽",
    name: "person shrugging: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FE",
    char: "🤷🏾",
    name: "person shrugging: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FF",
    char: "🤷🏿",
    name: "person shrugging: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 200D 2642 FE0F",
    char: "🤷‍♂️",
    name: "man shrugging",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 200D 2642",
    char: "🤷‍♂",
    name: "man shrugging",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FB 200D 2642 FE0F",
    char: "🤷🏻‍♂️",
    name: "man shrugging: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FB 200D 2642",
    char: "🤷🏻‍♂",
    name: "man shrugging: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FC 200D 2642 FE0F",
    char: "🤷🏼‍♂️",
    name: "man shrugging: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FC 200D 2642",
    char: "🤷🏼‍♂",
    name: "man shrugging: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FD 200D 2642 FE0F",
    char: "🤷🏽‍♂️",
    name: "man shrugging: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FD 200D 2642",
    char: "🤷🏽‍♂",
    name: "man shrugging: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FE 200D 2642 FE0F",
    char: "🤷🏾‍♂️",
    name: "man shrugging: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FE 200D 2642",
    char: "🤷🏾‍♂",
    name: "man shrugging: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FF 200D 2642 FE0F",
    char: "🤷🏿‍♂️",
    name: "man shrugging: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FF 200D 2642",
    char: "🤷🏿‍♂",
    name: "man shrugging: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 200D 2640 FE0F",
    char: "🤷‍♀️",
    name: "woman shrugging",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 200D 2640",
    char: "🤷‍♀",
    name: "woman shrugging",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FB 200D 2640 FE0F",
    char: "🤷🏻‍♀️",
    name: "woman shrugging: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FB 200D 2640",
    char: "🤷🏻‍♀",
    name: "woman shrugging: light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FC 200D 2640 FE0F",
    char: "🤷🏼‍♀️",
    name: "woman shrugging: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FC 200D 2640",
    char: "🤷🏼‍♀",
    name: "woman shrugging: medium-light skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FD 200D 2640 FE0F",
    char: "🤷🏽‍♀️",
    name: "woman shrugging: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FD 200D 2640",
    char: "🤷🏽‍♀",
    name: "woman shrugging: medium skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FE 200D 2640 FE0F",
    char: "🤷🏾‍♀️",
    name: "woman shrugging: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FE 200D 2640",
    char: "🤷🏾‍♀",
    name: "woman shrugging: medium-dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FF 200D 2640 FE0F",
    char: "🤷🏿‍♀️",
    name: "woman shrugging: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F937 1F3FF 200D 2640",
    char: "🤷🏿‍♀",
    name: "woman shrugging: dark skin tone",
    category: "People & Body (person-gesture)",
    group: "People & Body",
    subgroup: "person-gesture",
  },
  {
    codes: "1F9D1 200D 2695 FE0F",
    char: "🧑‍⚕️",
    name: "health worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 2695",
    char: "🧑‍⚕",
    name: "health worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 2695 FE0F",
    char: "🧑🏻‍⚕️",
    name: "health worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 2695",
    char: "🧑🏻‍⚕",
    name: "health worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 2695 FE0F",
    char: "🧑🏼‍⚕️",
    name: "health worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 2695",
    char: "🧑🏼‍⚕",
    name: "health worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 2695 FE0F",
    char: "🧑🏽‍⚕️",
    name: "health worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 2695",
    char: "🧑🏽‍⚕",
    name: "health worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 2695 FE0F",
    char: "🧑🏾‍⚕️",
    name: "health worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 2695",
    char: "🧑🏾‍⚕",
    name: "health worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 2695 FE0F",
    char: "🧑🏿‍⚕️",
    name: "health worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 2695",
    char: "🧑🏿‍⚕",
    name: "health worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 2695 FE0F",
    char: "👨‍⚕️",
    name: "man health worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 2695",
    char: "👨‍⚕",
    name: "man health worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 2695 FE0F",
    char: "👨🏻‍⚕️",
    name: "man health worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 2695",
    char: "👨🏻‍⚕",
    name: "man health worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 2695 FE0F",
    char: "👨🏼‍⚕️",
    name: "man health worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 2695",
    char: "👨🏼‍⚕",
    name: "man health worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 2695 FE0F",
    char: "👨🏽‍⚕️",
    name: "man health worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 2695",
    char: "👨🏽‍⚕",
    name: "man health worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 2695 FE0F",
    char: "👨🏾‍⚕️",
    name: "man health worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 2695",
    char: "👨🏾‍⚕",
    name: "man health worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 2695 FE0F",
    char: "👨🏿‍⚕️",
    name: "man health worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 2695",
    char: "👨🏿‍⚕",
    name: "man health worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 2695 FE0F",
    char: "👩‍⚕️",
    name: "woman health worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 2695",
    char: "👩‍⚕",
    name: "woman health worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 2695 FE0F",
    char: "👩🏻‍⚕️",
    name: "woman health worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 2695",
    char: "👩🏻‍⚕",
    name: "woman health worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 2695 FE0F",
    char: "👩🏼‍⚕️",
    name: "woman health worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 2695",
    char: "👩🏼‍⚕",
    name: "woman health worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 2695 FE0F",
    char: "👩🏽‍⚕️",
    name: "woman health worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 2695",
    char: "👩🏽‍⚕",
    name: "woman health worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 2695 FE0F",
    char: "👩🏾‍⚕️",
    name: "woman health worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 2695",
    char: "👩🏾‍⚕",
    name: "woman health worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 2695 FE0F",
    char: "👩🏿‍⚕️",
    name: "woman health worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 2695",
    char: "👩🏿‍⚕",
    name: "woman health worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F393",
    char: "🧑‍🎓",
    name: "student",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F393",
    char: "🧑🏻‍🎓",
    name: "student: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F393",
    char: "🧑🏼‍🎓",
    name: "student: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F393",
    char: "🧑🏽‍🎓",
    name: "student: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F393",
    char: "🧑🏾‍🎓",
    name: "student: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F393",
    char: "🧑🏿‍🎓",
    name: "student: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F393",
    char: "👨‍🎓",
    name: "man student",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F393",
    char: "👨🏻‍🎓",
    name: "man student: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F393",
    char: "👨🏼‍🎓",
    name: "man student: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F393",
    char: "👨🏽‍🎓",
    name: "man student: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F393",
    char: "👨🏾‍🎓",
    name: "man student: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F393",
    char: "👨🏿‍🎓",
    name: "man student: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F393",
    char: "👩‍🎓",
    name: "woman student",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F393",
    char: "👩🏻‍🎓",
    name: "woman student: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F393",
    char: "👩🏼‍🎓",
    name: "woman student: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F393",
    char: "👩🏽‍🎓",
    name: "woman student: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F393",
    char: "👩🏾‍🎓",
    name: "woman student: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F393",
    char: "👩🏿‍🎓",
    name: "woman student: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F3EB",
    char: "🧑‍🏫",
    name: "teacher",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F3EB",
    char: "🧑🏻‍🏫",
    name: "teacher: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F3EB",
    char: "🧑🏼‍🏫",
    name: "teacher: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F3EB",
    char: "🧑🏽‍🏫",
    name: "teacher: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F3EB",
    char: "🧑🏾‍🏫",
    name: "teacher: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F3EB",
    char: "🧑🏿‍🏫",
    name: "teacher: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F3EB",
    char: "👨‍🏫",
    name: "man teacher",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F3EB",
    char: "👨🏻‍🏫",
    name: "man teacher: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F3EB",
    char: "👨🏼‍🏫",
    name: "man teacher: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F3EB",
    char: "👨🏽‍🏫",
    name: "man teacher: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F3EB",
    char: "👨🏾‍🏫",
    name: "man teacher: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F3EB",
    char: "👨🏿‍🏫",
    name: "man teacher: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F3EB",
    char: "👩‍🏫",
    name: "woman teacher",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F3EB",
    char: "👩🏻‍🏫",
    name: "woman teacher: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F3EB",
    char: "👩🏼‍🏫",
    name: "woman teacher: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F3EB",
    char: "👩🏽‍🏫",
    name: "woman teacher: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F3EB",
    char: "👩🏾‍🏫",
    name: "woman teacher: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F3EB",
    char: "👩🏿‍🏫",
    name: "woman teacher: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 2696 FE0F",
    char: "🧑‍⚖️",
    name: "judge",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 2696",
    char: "🧑‍⚖",
    name: "judge",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 2696 FE0F",
    char: "🧑🏻‍⚖️",
    name: "judge: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 2696",
    char: "🧑🏻‍⚖",
    name: "judge: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 2696 FE0F",
    char: "🧑🏼‍⚖️",
    name: "judge: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 2696",
    char: "🧑🏼‍⚖",
    name: "judge: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 2696 FE0F",
    char: "🧑🏽‍⚖️",
    name: "judge: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 2696",
    char: "🧑🏽‍⚖",
    name: "judge: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 2696 FE0F",
    char: "🧑🏾‍⚖️",
    name: "judge: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 2696",
    char: "🧑🏾‍⚖",
    name: "judge: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 2696 FE0F",
    char: "🧑🏿‍⚖️",
    name: "judge: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 2696",
    char: "🧑🏿‍⚖",
    name: "judge: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 2696 FE0F",
    char: "👨‍⚖️",
    name: "man judge",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 2696",
    char: "👨‍⚖",
    name: "man judge",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 2696 FE0F",
    char: "👨🏻‍⚖️",
    name: "man judge: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 2696",
    char: "👨🏻‍⚖",
    name: "man judge: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 2696 FE0F",
    char: "👨🏼‍⚖️",
    name: "man judge: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 2696",
    char: "👨🏼‍⚖",
    name: "man judge: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 2696 FE0F",
    char: "👨🏽‍⚖️",
    name: "man judge: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 2696",
    char: "👨🏽‍⚖",
    name: "man judge: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 2696 FE0F",
    char: "👨🏾‍⚖️",
    name: "man judge: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 2696",
    char: "👨🏾‍⚖",
    name: "man judge: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 2696 FE0F",
    char: "👨🏿‍⚖️",
    name: "man judge: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 2696",
    char: "👨🏿‍⚖",
    name: "man judge: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 2696 FE0F",
    char: "👩‍⚖️",
    name: "woman judge",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 2696",
    char: "👩‍⚖",
    name: "woman judge",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 2696 FE0F",
    char: "👩🏻‍⚖️",
    name: "woman judge: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 2696",
    char: "👩🏻‍⚖",
    name: "woman judge: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 2696 FE0F",
    char: "👩🏼‍⚖️",
    name: "woman judge: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 2696",
    char: "👩🏼‍⚖",
    name: "woman judge: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 2696 FE0F",
    char: "👩🏽‍⚖️",
    name: "woman judge: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 2696",
    char: "👩🏽‍⚖",
    name: "woman judge: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 2696 FE0F",
    char: "👩🏾‍⚖️",
    name: "woman judge: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 2696",
    char: "👩🏾‍⚖",
    name: "woman judge: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 2696 FE0F",
    char: "👩🏿‍⚖️",
    name: "woman judge: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 2696",
    char: "👩🏿‍⚖",
    name: "woman judge: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F33E",
    char: "🧑‍🌾",
    name: "farmer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F33E",
    char: "🧑🏻‍🌾",
    name: "farmer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F33E",
    char: "🧑🏼‍🌾",
    name: "farmer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F33E",
    char: "🧑🏽‍🌾",
    name: "farmer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F33E",
    char: "🧑🏾‍🌾",
    name: "farmer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F33E",
    char: "🧑🏿‍🌾",
    name: "farmer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F33E",
    char: "👨‍🌾",
    name: "man farmer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F33E",
    char: "👨🏻‍🌾",
    name: "man farmer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F33E",
    char: "👨🏼‍🌾",
    name: "man farmer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F33E",
    char: "👨🏽‍🌾",
    name: "man farmer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F33E",
    char: "👨🏾‍🌾",
    name: "man farmer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F33E",
    char: "👨🏿‍🌾",
    name: "man farmer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F33E",
    char: "👩‍🌾",
    name: "woman farmer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F33E",
    char: "👩🏻‍🌾",
    name: "woman farmer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F33E",
    char: "👩🏼‍🌾",
    name: "woman farmer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F33E",
    char: "👩🏽‍🌾",
    name: "woman farmer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F33E",
    char: "👩🏾‍🌾",
    name: "woman farmer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F33E",
    char: "👩🏿‍🌾",
    name: "woman farmer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F373",
    char: "🧑‍🍳",
    name: "cook",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F373",
    char: "🧑🏻‍🍳",
    name: "cook: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F373",
    char: "🧑🏼‍🍳",
    name: "cook: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F373",
    char: "🧑🏽‍🍳",
    name: "cook: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F373",
    char: "🧑🏾‍🍳",
    name: "cook: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F373",
    char: "🧑🏿‍🍳",
    name: "cook: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F373",
    char: "👨‍🍳",
    name: "man cook",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F373",
    char: "👨🏻‍🍳",
    name: "man cook: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F373",
    char: "👨🏼‍🍳",
    name: "man cook: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F373",
    char: "👨🏽‍🍳",
    name: "man cook: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F373",
    char: "👨🏾‍🍳",
    name: "man cook: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F373",
    char: "👨🏿‍🍳",
    name: "man cook: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F373",
    char: "👩‍🍳",
    name: "woman cook",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F373",
    char: "👩🏻‍🍳",
    name: "woman cook: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F373",
    char: "👩🏼‍🍳",
    name: "woman cook: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F373",
    char: "👩🏽‍🍳",
    name: "woman cook: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F373",
    char: "👩🏾‍🍳",
    name: "woman cook: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F373",
    char: "👩🏿‍🍳",
    name: "woman cook: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F527",
    char: "🧑‍🔧",
    name: "mechanic",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F527",
    char: "🧑🏻‍🔧",
    name: "mechanic: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F527",
    char: "🧑🏼‍🔧",
    name: "mechanic: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F527",
    char: "🧑🏽‍🔧",
    name: "mechanic: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F527",
    char: "🧑🏾‍🔧",
    name: "mechanic: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F527",
    char: "🧑🏿‍🔧",
    name: "mechanic: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F527",
    char: "👨‍🔧",
    name: "man mechanic",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F527",
    char: "👨🏻‍🔧",
    name: "man mechanic: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F527",
    char: "👨🏼‍🔧",
    name: "man mechanic: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F527",
    char: "👨🏽‍🔧",
    name: "man mechanic: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F527",
    char: "👨🏾‍🔧",
    name: "man mechanic: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F527",
    char: "👨🏿‍🔧",
    name: "man mechanic: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F527",
    char: "👩‍🔧",
    name: "woman mechanic",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F527",
    char: "👩🏻‍🔧",
    name: "woman mechanic: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F527",
    char: "👩🏼‍🔧",
    name: "woman mechanic: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F527",
    char: "👩🏽‍🔧",
    name: "woman mechanic: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F527",
    char: "👩🏾‍🔧",
    name: "woman mechanic: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F527",
    char: "👩🏿‍🔧",
    name: "woman mechanic: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F3ED",
    char: "🧑‍🏭",
    name: "factory worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F3ED",
    char: "🧑🏻‍🏭",
    name: "factory worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F3ED",
    char: "🧑🏼‍🏭",
    name: "factory worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F3ED",
    char: "🧑🏽‍🏭",
    name: "factory worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F3ED",
    char: "🧑🏾‍🏭",
    name: "factory worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F3ED",
    char: "🧑🏿‍🏭",
    name: "factory worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F3ED",
    char: "👨‍🏭",
    name: "man factory worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F3ED",
    char: "👨🏻‍🏭",
    name: "man factory worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F3ED",
    char: "👨🏼‍🏭",
    name: "man factory worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F3ED",
    char: "👨🏽‍🏭",
    name: "man factory worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F3ED",
    char: "👨🏾‍🏭",
    name: "man factory worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F3ED",
    char: "👨🏿‍🏭",
    name: "man factory worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F3ED",
    char: "👩‍🏭",
    name: "woman factory worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F3ED",
    char: "👩🏻‍🏭",
    name: "woman factory worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F3ED",
    char: "👩🏼‍🏭",
    name: "woman factory worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F3ED",
    char: "👩🏽‍🏭",
    name: "woman factory worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F3ED",
    char: "👩🏾‍🏭",
    name: "woman factory worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F3ED",
    char: "👩🏿‍🏭",
    name: "woman factory worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F4BC",
    char: "🧑‍💼",
    name: "office worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F4BC",
    char: "🧑🏻‍💼",
    name: "office worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F4BC",
    char: "🧑🏼‍💼",
    name: "office worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F4BC",
    char: "🧑🏽‍💼",
    name: "office worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F4BC",
    char: "🧑🏾‍💼",
    name: "office worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F4BC",
    char: "🧑🏿‍💼",
    name: "office worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F4BC",
    char: "👨‍💼",
    name: "man office worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F4BC",
    char: "👨🏻‍💼",
    name: "man office worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F4BC",
    char: "👨🏼‍💼",
    name: "man office worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F4BC",
    char: "👨🏽‍💼",
    name: "man office worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F4BC",
    char: "👨🏾‍💼",
    name: "man office worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F4BC",
    char: "👨🏿‍💼",
    name: "man office worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F4BC",
    char: "👩‍💼",
    name: "woman office worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F4BC",
    char: "👩🏻‍💼",
    name: "woman office worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F4BC",
    char: "👩🏼‍💼",
    name: "woman office worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F4BC",
    char: "👩🏽‍💼",
    name: "woman office worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F4BC",
    char: "👩🏾‍💼",
    name: "woman office worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F4BC",
    char: "👩🏿‍💼",
    name: "woman office worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F52C",
    char: "🧑‍🔬",
    name: "scientist",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F52C",
    char: "🧑🏻‍🔬",
    name: "scientist: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F52C",
    char: "🧑🏼‍🔬",
    name: "scientist: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F52C",
    char: "🧑🏽‍🔬",
    name: "scientist: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F52C",
    char: "🧑🏾‍🔬",
    name: "scientist: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F52C",
    char: "🧑🏿‍🔬",
    name: "scientist: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F52C",
    char: "👨‍🔬",
    name: "man scientist",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F52C",
    char: "👨🏻‍🔬",
    name: "man scientist: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F52C",
    char: "👨🏼‍🔬",
    name: "man scientist: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F52C",
    char: "👨🏽‍🔬",
    name: "man scientist: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F52C",
    char: "👨🏾‍🔬",
    name: "man scientist: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F52C",
    char: "👨🏿‍🔬",
    name: "man scientist: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F52C",
    char: "👩‍🔬",
    name: "woman scientist",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F52C",
    char: "👩🏻‍🔬",
    name: "woman scientist: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F52C",
    char: "👩🏼‍🔬",
    name: "woman scientist: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F52C",
    char: "👩🏽‍🔬",
    name: "woman scientist: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F52C",
    char: "👩🏾‍🔬",
    name: "woman scientist: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F52C",
    char: "👩🏿‍🔬",
    name: "woman scientist: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F4BB",
    char: "🧑‍💻",
    name: "technologist",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F4BB",
    char: "🧑🏻‍💻",
    name: "technologist: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F4BB",
    char: "🧑🏼‍💻",
    name: "technologist: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F4BB",
    char: "🧑🏽‍💻",
    name: "technologist: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F4BB",
    char: "🧑🏾‍💻",
    name: "technologist: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F4BB",
    char: "🧑🏿‍💻",
    name: "technologist: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F4BB",
    char: "👨‍💻",
    name: "man technologist",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F4BB",
    char: "👨🏻‍💻",
    name: "man technologist: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F4BB",
    char: "👨🏼‍💻",
    name: "man technologist: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F4BB",
    char: "👨🏽‍💻",
    name: "man technologist: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F4BB",
    char: "👨🏾‍💻",
    name: "man technologist: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F4BB",
    char: "👨🏿‍💻",
    name: "man technologist: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F4BB",
    char: "👩‍💻",
    name: "woman technologist",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F4BB",
    char: "👩🏻‍💻",
    name: "woman technologist: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F4BB",
    char: "👩🏼‍💻",
    name: "woman technologist: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F4BB",
    char: "👩🏽‍💻",
    name: "woman technologist: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F4BB",
    char: "👩🏾‍💻",
    name: "woman technologist: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F4BB",
    char: "👩🏿‍💻",
    name: "woman technologist: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F3A4",
    char: "🧑‍🎤",
    name: "singer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F3A4",
    char: "🧑🏻‍🎤",
    name: "singer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F3A4",
    char: "🧑🏼‍🎤",
    name: "singer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F3A4",
    char: "🧑🏽‍🎤",
    name: "singer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F3A4",
    char: "🧑🏾‍🎤",
    name: "singer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F3A4",
    char: "🧑🏿‍🎤",
    name: "singer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F3A4",
    char: "👨‍🎤",
    name: "man singer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F3A4",
    char: "👨🏻‍🎤",
    name: "man singer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F3A4",
    char: "👨🏼‍🎤",
    name: "man singer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F3A4",
    char: "👨🏽‍🎤",
    name: "man singer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F3A4",
    char: "👨🏾‍🎤",
    name: "man singer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F3A4",
    char: "👨🏿‍🎤",
    name: "man singer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F3A4",
    char: "👩‍🎤",
    name: "woman singer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F3A4",
    char: "👩🏻‍🎤",
    name: "woman singer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F3A4",
    char: "👩🏼‍🎤",
    name: "woman singer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F3A4",
    char: "👩🏽‍🎤",
    name: "woman singer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F3A4",
    char: "👩🏾‍🎤",
    name: "woman singer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F3A4",
    char: "👩🏿‍🎤",
    name: "woman singer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F3A8",
    char: "🧑‍🎨",
    name: "artist",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F3A8",
    char: "🧑🏻‍🎨",
    name: "artist: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F3A8",
    char: "🧑🏼‍🎨",
    name: "artist: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F3A8",
    char: "🧑🏽‍🎨",
    name: "artist: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F3A8",
    char: "🧑🏾‍🎨",
    name: "artist: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F3A8",
    char: "🧑🏿‍🎨",
    name: "artist: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F3A8",
    char: "👨‍🎨",
    name: "man artist",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F3A8",
    char: "👨🏻‍🎨",
    name: "man artist: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F3A8",
    char: "👨🏼‍🎨",
    name: "man artist: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F3A8",
    char: "👨🏽‍🎨",
    name: "man artist: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F3A8",
    char: "👨🏾‍🎨",
    name: "man artist: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F3A8",
    char: "👨🏿‍🎨",
    name: "man artist: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F3A8",
    char: "👩‍🎨",
    name: "woman artist",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F3A8",
    char: "👩🏻‍🎨",
    name: "woman artist: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F3A8",
    char: "👩🏼‍🎨",
    name: "woman artist: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F3A8",
    char: "👩🏽‍🎨",
    name: "woman artist: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F3A8",
    char: "👩🏾‍🎨",
    name: "woman artist: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F3A8",
    char: "👩🏿‍🎨",
    name: "woman artist: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 2708 FE0F",
    char: "🧑‍✈️",
    name: "pilot",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 2708",
    char: "🧑‍✈",
    name: "pilot",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 2708 FE0F",
    char: "🧑🏻‍✈️",
    name: "pilot: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 2708",
    char: "🧑🏻‍✈",
    name: "pilot: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 2708 FE0F",
    char: "🧑🏼‍✈️",
    name: "pilot: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 2708",
    char: "🧑🏼‍✈",
    name: "pilot: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 2708 FE0F",
    char: "🧑🏽‍✈️",
    name: "pilot: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 2708",
    char: "🧑🏽‍✈",
    name: "pilot: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 2708 FE0F",
    char: "🧑🏾‍✈️",
    name: "pilot: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 2708",
    char: "🧑🏾‍✈",
    name: "pilot: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 2708 FE0F",
    char: "🧑🏿‍✈️",
    name: "pilot: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 2708",
    char: "🧑🏿‍✈",
    name: "pilot: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 2708 FE0F",
    char: "👨‍✈️",
    name: "man pilot",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 2708",
    char: "👨‍✈",
    name: "man pilot",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 2708 FE0F",
    char: "👨🏻‍✈️",
    name: "man pilot: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 2708",
    char: "👨🏻‍✈",
    name: "man pilot: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 2708 FE0F",
    char: "👨🏼‍✈️",
    name: "man pilot: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 2708",
    char: "👨🏼‍✈",
    name: "man pilot: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 2708 FE0F",
    char: "👨🏽‍✈️",
    name: "man pilot: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 2708",
    char: "👨🏽‍✈",
    name: "man pilot: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 2708 FE0F",
    char: "👨🏾‍✈️",
    name: "man pilot: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 2708",
    char: "👨🏾‍✈",
    name: "man pilot: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 2708 FE0F",
    char: "👨🏿‍✈️",
    name: "man pilot: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 2708",
    char: "👨🏿‍✈",
    name: "man pilot: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 2708 FE0F",
    char: "👩‍✈️",
    name: "woman pilot",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 2708",
    char: "👩‍✈",
    name: "woman pilot",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 2708 FE0F",
    char: "👩🏻‍✈️",
    name: "woman pilot: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 2708",
    char: "👩🏻‍✈",
    name: "woman pilot: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 2708 FE0F",
    char: "👩🏼‍✈️",
    name: "woman pilot: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 2708",
    char: "👩🏼‍✈",
    name: "woman pilot: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 2708 FE0F",
    char: "👩🏽‍✈️",
    name: "woman pilot: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 2708",
    char: "👩🏽‍✈",
    name: "woman pilot: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 2708 FE0F",
    char: "👩🏾‍✈️",
    name: "woman pilot: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 2708",
    char: "👩🏾‍✈",
    name: "woman pilot: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 2708 FE0F",
    char: "👩🏿‍✈️",
    name: "woman pilot: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 2708",
    char: "👩🏿‍✈",
    name: "woman pilot: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F680",
    char: "🧑‍🚀",
    name: "astronaut",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F680",
    char: "🧑🏻‍🚀",
    name: "astronaut: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F680",
    char: "🧑🏼‍🚀",
    name: "astronaut: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F680",
    char: "🧑🏽‍🚀",
    name: "astronaut: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F680",
    char: "🧑🏾‍🚀",
    name: "astronaut: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F680",
    char: "🧑🏿‍🚀",
    name: "astronaut: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F680",
    char: "👨‍🚀",
    name: "man astronaut",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F680",
    char: "👨🏻‍🚀",
    name: "man astronaut: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F680",
    char: "👨🏼‍🚀",
    name: "man astronaut: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F680",
    char: "👨🏽‍🚀",
    name: "man astronaut: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F680",
    char: "👨🏾‍🚀",
    name: "man astronaut: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F680",
    char: "👨🏿‍🚀",
    name: "man astronaut: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F680",
    char: "👩‍🚀",
    name: "woman astronaut",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F680",
    char: "👩🏻‍🚀",
    name: "woman astronaut: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F680",
    char: "👩🏼‍🚀",
    name: "woman astronaut: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F680",
    char: "👩🏽‍🚀",
    name: "woman astronaut: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F680",
    char: "👩🏾‍🚀",
    name: "woman astronaut: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F680",
    char: "👩🏿‍🚀",
    name: "woman astronaut: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F692",
    char: "🧑‍🚒",
    name: "firefighter",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F692",
    char: "🧑🏻‍🚒",
    name: "firefighter: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F692",
    char: "🧑🏼‍🚒",
    name: "firefighter: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F692",
    char: "🧑🏽‍🚒",
    name: "firefighter: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F692",
    char: "🧑🏾‍🚒",
    name: "firefighter: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F692",
    char: "🧑🏿‍🚒",
    name: "firefighter: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F692",
    char: "👨‍🚒",
    name: "man firefighter",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F692",
    char: "👨🏻‍🚒",
    name: "man firefighter: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F692",
    char: "👨🏼‍🚒",
    name: "man firefighter: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F692",
    char: "👨🏽‍🚒",
    name: "man firefighter: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F692",
    char: "👨🏾‍🚒",
    name: "man firefighter: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F692",
    char: "👨🏿‍🚒",
    name: "man firefighter: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F692",
    char: "👩‍🚒",
    name: "woman firefighter",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F692",
    char: "👩🏻‍🚒",
    name: "woman firefighter: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F692",
    char: "👩🏼‍🚒",
    name: "woman firefighter: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F692",
    char: "👩🏽‍🚒",
    name: "woman firefighter: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F692",
    char: "👩🏾‍🚒",
    name: "woman firefighter: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F692",
    char: "👩🏿‍🚒",
    name: "woman firefighter: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E",
    char: "👮",
    name: "police officer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FB",
    char: "👮🏻",
    name: "police officer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FC",
    char: "👮🏼",
    name: "police officer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FD",
    char: "👮🏽",
    name: "police officer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FE",
    char: "👮🏾",
    name: "police officer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FF",
    char: "👮🏿",
    name: "police officer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 200D 2642 FE0F",
    char: "👮‍♂️",
    name: "man police officer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 200D 2642",
    char: "👮‍♂",
    name: "man police officer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FB 200D 2642 FE0F",
    char: "👮🏻‍♂️",
    name: "man police officer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FB 200D 2642",
    char: "👮🏻‍♂",
    name: "man police officer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FC 200D 2642 FE0F",
    char: "👮🏼‍♂️",
    name: "man police officer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FC 200D 2642",
    char: "👮🏼‍♂",
    name: "man police officer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FD 200D 2642 FE0F",
    char: "👮🏽‍♂️",
    name: "man police officer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FD 200D 2642",
    char: "👮🏽‍♂",
    name: "man police officer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FE 200D 2642 FE0F",
    char: "👮🏾‍♂️",
    name: "man police officer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FE 200D 2642",
    char: "👮🏾‍♂",
    name: "man police officer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FF 200D 2642 FE0F",
    char: "👮🏿‍♂️",
    name: "man police officer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FF 200D 2642",
    char: "👮🏿‍♂",
    name: "man police officer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 200D 2640 FE0F",
    char: "👮‍♀️",
    name: "woman police officer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 200D 2640",
    char: "👮‍♀",
    name: "woman police officer",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FB 200D 2640 FE0F",
    char: "👮🏻‍♀️",
    name: "woman police officer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FB 200D 2640",
    char: "👮🏻‍♀",
    name: "woman police officer: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FC 200D 2640 FE0F",
    char: "👮🏼‍♀️",
    name: "woman police officer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FC 200D 2640",
    char: "👮🏼‍♀",
    name: "woman police officer: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FD 200D 2640 FE0F",
    char: "👮🏽‍♀️",
    name: "woman police officer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FD 200D 2640",
    char: "👮🏽‍♀",
    name: "woman police officer: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FE 200D 2640 FE0F",
    char: "👮🏾‍♀️",
    name: "woman police officer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FE 200D 2640",
    char: "👮🏾‍♀",
    name: "woman police officer: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FF 200D 2640 FE0F",
    char: "👮🏿‍♀️",
    name: "woman police officer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F46E 1F3FF 200D 2640",
    char: "👮🏿‍♀",
    name: "woman police officer: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 FE0F",
    char: "🕵️",
    name: "detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575",
    char: "🕵",
    name: "detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FB",
    char: "🕵🏻",
    name: "detective: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FC",
    char: "🕵🏼",
    name: "detective: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FD",
    char: "🕵🏽",
    name: "detective: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FE",
    char: "🕵🏾",
    name: "detective: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FF",
    char: "🕵🏿",
    name: "detective: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 FE0F 200D 2642 FE0F",
    char: "🕵️‍♂️",
    name: "man detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 200D 2642 FE0F",
    char: "🕵‍♂️",
    name: "man detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 FE0F 200D 2642",
    char: "🕵️‍♂",
    name: "man detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 200D 2642",
    char: "🕵‍♂",
    name: "man detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FB 200D 2642 FE0F",
    char: "🕵🏻‍♂️",
    name: "man detective: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FB 200D 2642",
    char: "🕵🏻‍♂",
    name: "man detective: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FC 200D 2642 FE0F",
    char: "🕵🏼‍♂️",
    name: "man detective: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FC 200D 2642",
    char: "🕵🏼‍♂",
    name: "man detective: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FD 200D 2642 FE0F",
    char: "🕵🏽‍♂️",
    name: "man detective: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FD 200D 2642",
    char: "🕵🏽‍♂",
    name: "man detective: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FE 200D 2642 FE0F",
    char: "🕵🏾‍♂️",
    name: "man detective: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FE 200D 2642",
    char: "🕵🏾‍♂",
    name: "man detective: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FF 200D 2642 FE0F",
    char: "🕵🏿‍♂️",
    name: "man detective: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FF 200D 2642",
    char: "🕵🏿‍♂",
    name: "man detective: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 FE0F 200D 2640 FE0F",
    char: "🕵️‍♀️",
    name: "woman detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 200D 2640 FE0F",
    char: "🕵‍♀️",
    name: "woman detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 FE0F 200D 2640",
    char: "🕵️‍♀",
    name: "woman detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 200D 2640",
    char: "🕵‍♀",
    name: "woman detective",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FB 200D 2640 FE0F",
    char: "🕵🏻‍♀️",
    name: "woman detective: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FB 200D 2640",
    char: "🕵🏻‍♀",
    name: "woman detective: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FC 200D 2640 FE0F",
    char: "🕵🏼‍♀️",
    name: "woman detective: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FC 200D 2640",
    char: "🕵🏼‍♀",
    name: "woman detective: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FD 200D 2640 FE0F",
    char: "🕵🏽‍♀️",
    name: "woman detective: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FD 200D 2640",
    char: "🕵🏽‍♀",
    name: "woman detective: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FE 200D 2640 FE0F",
    char: "🕵🏾‍♀️",
    name: "woman detective: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FE 200D 2640",
    char: "🕵🏾‍♀",
    name: "woman detective: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FF 200D 2640 FE0F",
    char: "🕵🏿‍♀️",
    name: "woman detective: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F575 1F3FF 200D 2640",
    char: "🕵🏿‍♀",
    name: "woman detective: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482",
    char: "💂",
    name: "guard",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FB",
    char: "💂🏻",
    name: "guard: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FC",
    char: "💂🏼",
    name: "guard: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FD",
    char: "💂🏽",
    name: "guard: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FE",
    char: "💂🏾",
    name: "guard: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FF",
    char: "💂🏿",
    name: "guard: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 200D 2642 FE0F",
    char: "💂‍♂️",
    name: "man guard",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 200D 2642",
    char: "💂‍♂",
    name: "man guard",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FB 200D 2642 FE0F",
    char: "💂🏻‍♂️",
    name: "man guard: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FB 200D 2642",
    char: "💂🏻‍♂",
    name: "man guard: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FC 200D 2642 FE0F",
    char: "💂🏼‍♂️",
    name: "man guard: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FC 200D 2642",
    char: "💂🏼‍♂",
    name: "man guard: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FD 200D 2642 FE0F",
    char: "💂🏽‍♂️",
    name: "man guard: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FD 200D 2642",
    char: "💂🏽‍♂",
    name: "man guard: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FE 200D 2642 FE0F",
    char: "💂🏾‍♂️",
    name: "man guard: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FE 200D 2642",
    char: "💂🏾‍♂",
    name: "man guard: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FF 200D 2642 FE0F",
    char: "💂🏿‍♂️",
    name: "man guard: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FF 200D 2642",
    char: "💂🏿‍♂",
    name: "man guard: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 200D 2640 FE0F",
    char: "💂‍♀️",
    name: "woman guard",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 200D 2640",
    char: "💂‍♀",
    name: "woman guard",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FB 200D 2640 FE0F",
    char: "💂🏻‍♀️",
    name: "woman guard: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FB 200D 2640",
    char: "💂🏻‍♀",
    name: "woman guard: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FC 200D 2640 FE0F",
    char: "💂🏼‍♀️",
    name: "woman guard: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FC 200D 2640",
    char: "💂🏼‍♀",
    name: "woman guard: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FD 200D 2640 FE0F",
    char: "💂🏽‍♀️",
    name: "woman guard: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FD 200D 2640",
    char: "💂🏽‍♀",
    name: "woman guard: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FE 200D 2640 FE0F",
    char: "💂🏾‍♀️",
    name: "woman guard: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FE 200D 2640",
    char: "💂🏾‍♀",
    name: "woman guard: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FF 200D 2640 FE0F",
    char: "💂🏿‍♀️",
    name: "woman guard: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F482 1F3FF 200D 2640",
    char: "💂🏿‍♀",
    name: "woman guard: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F977",
    char: "🥷",
    name: "ninja",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F977 1F3FB",
    char: "🥷🏻",
    name: "ninja: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F977 1F3FC",
    char: "🥷🏼",
    name: "ninja: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F977 1F3FD",
    char: "🥷🏽",
    name: "ninja: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F977 1F3FE",
    char: "🥷🏾",
    name: "ninja: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F977 1F3FF",
    char: "🥷🏿",
    name: "ninja: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477",
    char: "👷",
    name: "construction worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FB",
    char: "👷🏻",
    name: "construction worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FC",
    char: "👷🏼",
    name: "construction worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FD",
    char: "👷🏽",
    name: "construction worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FE",
    char: "👷🏾",
    name: "construction worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FF",
    char: "👷🏿",
    name: "construction worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 200D 2642 FE0F",
    char: "👷‍♂️",
    name: "man construction worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 200D 2642",
    char: "👷‍♂",
    name: "man construction worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FB 200D 2642 FE0F",
    char: "👷🏻‍♂️",
    name: "man construction worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FB 200D 2642",
    char: "👷🏻‍♂",
    name: "man construction worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FC 200D 2642 FE0F",
    char: "👷🏼‍♂️",
    name: "man construction worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FC 200D 2642",
    char: "👷🏼‍♂",
    name: "man construction worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FD 200D 2642 FE0F",
    char: "👷🏽‍♂️",
    name: "man construction worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FD 200D 2642",
    char: "👷🏽‍♂",
    name: "man construction worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FE 200D 2642 FE0F",
    char: "👷🏾‍♂️",
    name: "man construction worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FE 200D 2642",
    char: "👷🏾‍♂",
    name: "man construction worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FF 200D 2642 FE0F",
    char: "👷🏿‍♂️",
    name: "man construction worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FF 200D 2642",
    char: "👷🏿‍♂",
    name: "man construction worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 200D 2640 FE0F",
    char: "👷‍♀️",
    name: "woman construction worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 200D 2640",
    char: "👷‍♀",
    name: "woman construction worker",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FB 200D 2640 FE0F",
    char: "👷🏻‍♀️",
    name: "woman construction worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FB 200D 2640",
    char: "👷🏻‍♀",
    name: "woman construction worker: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FC 200D 2640 FE0F",
    char: "👷🏼‍♀️",
    name: "woman construction worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FC 200D 2640",
    char: "👷🏼‍♀",
    name: "woman construction worker: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FD 200D 2640 FE0F",
    char: "👷🏽‍♀️",
    name: "woman construction worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FD 200D 2640",
    char: "👷🏽‍♀",
    name: "woman construction worker: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FE 200D 2640 FE0F",
    char: "👷🏾‍♀️",
    name: "woman construction worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FE 200D 2640",
    char: "👷🏾‍♀",
    name: "woman construction worker: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FF 200D 2640 FE0F",
    char: "👷🏿‍♀️",
    name: "woman construction worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F477 1F3FF 200D 2640",
    char: "👷🏿‍♀",
    name: "woman construction worker: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC5",
    char: "🫅",
    name: "person with crown",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC5 1F3FB",
    char: "🫅🏻",
    name: "person with crown: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC5 1F3FC",
    char: "🫅🏼",
    name: "person with crown: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC5 1F3FD",
    char: "🫅🏽",
    name: "person with crown: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC5 1F3FE",
    char: "🫅🏾",
    name: "person with crown: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC5 1F3FF",
    char: "🫅🏿",
    name: "person with crown: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F934",
    char: "🤴",
    name: "prince",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F934 1F3FB",
    char: "🤴🏻",
    name: "prince: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F934 1F3FC",
    char: "🤴🏼",
    name: "prince: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F934 1F3FD",
    char: "🤴🏽",
    name: "prince: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F934 1F3FE",
    char: "🤴🏾",
    name: "prince: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F934 1F3FF",
    char: "🤴🏿",
    name: "prince: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F478",
    char: "👸",
    name: "princess",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F478 1F3FB",
    char: "👸🏻",
    name: "princess: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F478 1F3FC",
    char: "👸🏼",
    name: "princess: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F478 1F3FD",
    char: "👸🏽",
    name: "princess: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F478 1F3FE",
    char: "👸🏾",
    name: "princess: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F478 1F3FF",
    char: "👸🏿",
    name: "princess: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473",
    char: "👳",
    name: "person wearing turban",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FB",
    char: "👳🏻",
    name: "person wearing turban: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FC",
    char: "👳🏼",
    name: "person wearing turban: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FD",
    char: "👳🏽",
    name: "person wearing turban: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FE",
    char: "👳🏾",
    name: "person wearing turban: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FF",
    char: "👳🏿",
    name: "person wearing turban: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 200D 2642 FE0F",
    char: "👳‍♂️",
    name: "man wearing turban",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 200D 2642",
    char: "👳‍♂",
    name: "man wearing turban",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FB 200D 2642 FE0F",
    char: "👳🏻‍♂️",
    name: "man wearing turban: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FB 200D 2642",
    char: "👳🏻‍♂",
    name: "man wearing turban: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FC 200D 2642 FE0F",
    char: "👳🏼‍♂️",
    name: "man wearing turban: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FC 200D 2642",
    char: "👳🏼‍♂",
    name: "man wearing turban: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FD 200D 2642 FE0F",
    char: "👳🏽‍♂️",
    name: "man wearing turban: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FD 200D 2642",
    char: "👳🏽‍♂",
    name: "man wearing turban: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FE 200D 2642 FE0F",
    char: "👳🏾‍♂️",
    name: "man wearing turban: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FE 200D 2642",
    char: "👳🏾‍♂",
    name: "man wearing turban: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FF 200D 2642 FE0F",
    char: "👳🏿‍♂️",
    name: "man wearing turban: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FF 200D 2642",
    char: "👳🏿‍♂",
    name: "man wearing turban: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 200D 2640 FE0F",
    char: "👳‍♀️",
    name: "woman wearing turban",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 200D 2640",
    char: "👳‍♀",
    name: "woman wearing turban",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FB 200D 2640 FE0F",
    char: "👳🏻‍♀️",
    name: "woman wearing turban: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FB 200D 2640",
    char: "👳🏻‍♀",
    name: "woman wearing turban: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FC 200D 2640 FE0F",
    char: "👳🏼‍♀️",
    name: "woman wearing turban: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FC 200D 2640",
    char: "👳🏼‍♀",
    name: "woman wearing turban: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FD 200D 2640 FE0F",
    char: "👳🏽‍♀️",
    name: "woman wearing turban: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FD 200D 2640",
    char: "👳🏽‍♀",
    name: "woman wearing turban: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FE 200D 2640 FE0F",
    char: "👳🏾‍♀️",
    name: "woman wearing turban: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FE 200D 2640",
    char: "👳🏾‍♀",
    name: "woman wearing turban: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FF 200D 2640 FE0F",
    char: "👳🏿‍♀️",
    name: "woman wearing turban: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F473 1F3FF 200D 2640",
    char: "👳🏿‍♀",
    name: "woman wearing turban: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F472",
    char: "👲",
    name: "person with skullcap",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F472 1F3FB",
    char: "👲🏻",
    name: "person with skullcap: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F472 1F3FC",
    char: "👲🏼",
    name: "person with skullcap: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F472 1F3FD",
    char: "👲🏽",
    name: "person with skullcap: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F472 1F3FE",
    char: "👲🏾",
    name: "person with skullcap: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F472 1F3FF",
    char: "👲🏿",
    name: "person with skullcap: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D5",
    char: "🧕",
    name: "woman with headscarf",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D5 1F3FB",
    char: "🧕🏻",
    name: "woman with headscarf: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D5 1F3FC",
    char: "🧕🏼",
    name: "woman with headscarf: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D5 1F3FD",
    char: "🧕🏽",
    name: "woman with headscarf: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D5 1F3FE",
    char: "🧕🏾",
    name: "woman with headscarf: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D5 1F3FF",
    char: "🧕🏿",
    name: "woman with headscarf: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935",
    char: "🤵",
    name: "person in tuxedo",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FB",
    char: "🤵🏻",
    name: "person in tuxedo: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FC",
    char: "🤵🏼",
    name: "person in tuxedo: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FD",
    char: "🤵🏽",
    name: "person in tuxedo: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FE",
    char: "🤵🏾",
    name: "person in tuxedo: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FF",
    char: "🤵🏿",
    name: "person in tuxedo: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 200D 2642 FE0F",
    char: "🤵‍♂️",
    name: "man in tuxedo",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 200D 2642",
    char: "🤵‍♂",
    name: "man in tuxedo",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FB 200D 2642 FE0F",
    char: "🤵🏻‍♂️",
    name: "man in tuxedo: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FB 200D 2642",
    char: "🤵🏻‍♂",
    name: "man in tuxedo: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FC 200D 2642 FE0F",
    char: "🤵🏼‍♂️",
    name: "man in tuxedo: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FC 200D 2642",
    char: "🤵🏼‍♂",
    name: "man in tuxedo: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FD 200D 2642 FE0F",
    char: "🤵🏽‍♂️",
    name: "man in tuxedo: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FD 200D 2642",
    char: "🤵🏽‍♂",
    name: "man in tuxedo: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FE 200D 2642 FE0F",
    char: "🤵🏾‍♂️",
    name: "man in tuxedo: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FE 200D 2642",
    char: "🤵🏾‍♂",
    name: "man in tuxedo: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FF 200D 2642 FE0F",
    char: "🤵🏿‍♂️",
    name: "man in tuxedo: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FF 200D 2642",
    char: "🤵🏿‍♂",
    name: "man in tuxedo: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 200D 2640 FE0F",
    char: "🤵‍♀️",
    name: "woman in tuxedo",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 200D 2640",
    char: "🤵‍♀",
    name: "woman in tuxedo",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FB 200D 2640 FE0F",
    char: "🤵🏻‍♀️",
    name: "woman in tuxedo: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FB 200D 2640",
    char: "🤵🏻‍♀",
    name: "woman in tuxedo: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FC 200D 2640 FE0F",
    char: "🤵🏼‍♀️",
    name: "woman in tuxedo: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FC 200D 2640",
    char: "🤵🏼‍♀",
    name: "woman in tuxedo: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FD 200D 2640 FE0F",
    char: "🤵🏽‍♀️",
    name: "woman in tuxedo: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FD 200D 2640",
    char: "🤵🏽‍♀",
    name: "woman in tuxedo: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FE 200D 2640 FE0F",
    char: "🤵🏾‍♀️",
    name: "woman in tuxedo: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FE 200D 2640",
    char: "🤵🏾‍♀",
    name: "woman in tuxedo: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FF 200D 2640 FE0F",
    char: "🤵🏿‍♀️",
    name: "woman in tuxedo: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F935 1F3FF 200D 2640",
    char: "🤵🏿‍♀",
    name: "woman in tuxedo: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470",
    char: "👰",
    name: "person with veil",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FB",
    char: "👰🏻",
    name: "person with veil: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FC",
    char: "👰🏼",
    name: "person with veil: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FD",
    char: "👰🏽",
    name: "person with veil: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FE",
    char: "👰🏾",
    name: "person with veil: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FF",
    char: "👰🏿",
    name: "person with veil: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 200D 2642 FE0F",
    char: "👰‍♂️",
    name: "man with veil",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 200D 2642",
    char: "👰‍♂",
    name: "man with veil",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FB 200D 2642 FE0F",
    char: "👰🏻‍♂️",
    name: "man with veil: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FB 200D 2642",
    char: "👰🏻‍♂",
    name: "man with veil: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FC 200D 2642 FE0F",
    char: "👰🏼‍♂️",
    name: "man with veil: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FC 200D 2642",
    char: "👰🏼‍♂",
    name: "man with veil: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FD 200D 2642 FE0F",
    char: "👰🏽‍♂️",
    name: "man with veil: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FD 200D 2642",
    char: "👰🏽‍♂",
    name: "man with veil: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FE 200D 2642 FE0F",
    char: "👰🏾‍♂️",
    name: "man with veil: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FE 200D 2642",
    char: "👰🏾‍♂",
    name: "man with veil: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FF 200D 2642 FE0F",
    char: "👰🏿‍♂️",
    name: "man with veil: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FF 200D 2642",
    char: "👰🏿‍♂",
    name: "man with veil: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 200D 2640 FE0F",
    char: "👰‍♀️",
    name: "woman with veil",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 200D 2640",
    char: "👰‍♀",
    name: "woman with veil",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FB 200D 2640 FE0F",
    char: "👰🏻‍♀️",
    name: "woman with veil: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FB 200D 2640",
    char: "👰🏻‍♀",
    name: "woman with veil: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FC 200D 2640 FE0F",
    char: "👰🏼‍♀️",
    name: "woman with veil: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FC 200D 2640",
    char: "👰🏼‍♀",
    name: "woman with veil: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FD 200D 2640 FE0F",
    char: "👰🏽‍♀️",
    name: "woman with veil: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FD 200D 2640",
    char: "👰🏽‍♀",
    name: "woman with veil: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FE 200D 2640 FE0F",
    char: "👰🏾‍♀️",
    name: "woman with veil: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FE 200D 2640",
    char: "👰🏾‍♀",
    name: "woman with veil: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FF 200D 2640 FE0F",
    char: "👰🏿‍♀️",
    name: "woman with veil: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F470 1F3FF 200D 2640",
    char: "👰🏿‍♀",
    name: "woman with veil: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F930",
    char: "🤰",
    name: "pregnant woman",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F930 1F3FB",
    char: "🤰🏻",
    name: "pregnant woman: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F930 1F3FC",
    char: "🤰🏼",
    name: "pregnant woman: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F930 1F3FD",
    char: "🤰🏽",
    name: "pregnant woman: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F930 1F3FE",
    char: "🤰🏾",
    name: "pregnant woman: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F930 1F3FF",
    char: "🤰🏿",
    name: "pregnant woman: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC3",
    char: "🫃",
    name: "pregnant man",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC3 1F3FB",
    char: "🫃🏻",
    name: "pregnant man: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC3 1F3FC",
    char: "🫃🏼",
    name: "pregnant man: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC3 1F3FD",
    char: "🫃🏽",
    name: "pregnant man: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC3 1F3FE",
    char: "🫃🏾",
    name: "pregnant man: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC3 1F3FF",
    char: "🫃🏿",
    name: "pregnant man: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC4",
    char: "🫄",
    name: "pregnant person",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC4 1F3FB",
    char: "🫄🏻",
    name: "pregnant person: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC4 1F3FC",
    char: "🫄🏼",
    name: "pregnant person: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC4 1F3FD",
    char: "🫄🏽",
    name: "pregnant person: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC4 1F3FE",
    char: "🫄🏾",
    name: "pregnant person: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1FAC4 1F3FF",
    char: "🫄🏿",
    name: "pregnant person: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F931",
    char: "🤱",
    name: "breast-feeding",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F931 1F3FB",
    char: "🤱🏻",
    name: "breast-feeding: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F931 1F3FC",
    char: "🤱🏼",
    name: "breast-feeding: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F931 1F3FD",
    char: "🤱🏽",
    name: "breast-feeding: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F931 1F3FE",
    char: "🤱🏾",
    name: "breast-feeding: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F931 1F3FF",
    char: "🤱🏿",
    name: "breast-feeding: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 200D 1F37C",
    char: "👩‍🍼",
    name: "woman feeding baby",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FB 200D 1F37C",
    char: "👩🏻‍🍼",
    name: "woman feeding baby: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FC 200D 1F37C",
    char: "👩🏼‍🍼",
    name: "woman feeding baby: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FD 200D 1F37C",
    char: "👩🏽‍🍼",
    name: "woman feeding baby: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FE 200D 1F37C",
    char: "👩🏾‍🍼",
    name: "woman feeding baby: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F469 1F3FF 200D 1F37C",
    char: "👩🏿‍🍼",
    name: "woman feeding baby: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 200D 1F37C",
    char: "👨‍🍼",
    name: "man feeding baby",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FB 200D 1F37C",
    char: "👨🏻‍🍼",
    name: "man feeding baby: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FC 200D 1F37C",
    char: "👨🏼‍🍼",
    name: "man feeding baby: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FD 200D 1F37C",
    char: "👨🏽‍🍼",
    name: "man feeding baby: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FE 200D 1F37C",
    char: "👨🏾‍🍼",
    name: "man feeding baby: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F468 1F3FF 200D 1F37C",
    char: "👨🏿‍🍼",
    name: "man feeding baby: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 200D 1F37C",
    char: "🧑‍🍼",
    name: "person feeding baby",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F37C",
    char: "🧑🏻‍🍼",
    name: "person feeding baby: light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F37C",
    char: "🧑🏼‍🍼",
    name: "person feeding baby: medium-light skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F37C",
    char: "🧑🏽‍🍼",
    name: "person feeding baby: medium skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F37C",
    char: "🧑🏾‍🍼",
    name: "person feeding baby: medium-dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F37C",
    char: "🧑🏿‍🍼",
    name: "person feeding baby: dark skin tone",
    category: "People & Body (person-role)",
    group: "People & Body",
    subgroup: "person-role",
  },
  {
    codes: "1F47C",
    char: "👼",
    name: "baby angel",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F47C 1F3FB",
    char: "👼🏻",
    name: "baby angel: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F47C 1F3FC",
    char: "👼🏼",
    name: "baby angel: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F47C 1F3FD",
    char: "👼🏽",
    name: "baby angel: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F47C 1F3FE",
    char: "👼🏾",
    name: "baby angel: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F47C 1F3FF",
    char: "👼🏿",
    name: "baby angel: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F385",
    char: "🎅",
    name: "Santa Claus",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F385 1F3FB",
    char: "🎅🏻",
    name: "Santa Claus: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F385 1F3FC",
    char: "🎅🏼",
    name: "Santa Claus: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F385 1F3FD",
    char: "🎅🏽",
    name: "Santa Claus: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F385 1F3FE",
    char: "🎅🏾",
    name: "Santa Claus: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F385 1F3FF",
    char: "🎅🏿",
    name: "Santa Claus: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F936",
    char: "🤶",
    name: "Mrs. Claus",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F936 1F3FB",
    char: "🤶🏻",
    name: "Mrs. Claus: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F936 1F3FC",
    char: "🤶🏼",
    name: "Mrs. Claus: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F936 1F3FD",
    char: "🤶🏽",
    name: "Mrs. Claus: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F936 1F3FE",
    char: "🤶🏾",
    name: "Mrs. Claus: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F936 1F3FF",
    char: "🤶🏿",
    name: "Mrs. Claus: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D1 200D 1F384",
    char: "🧑‍🎄",
    name: "mx claus",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F384",
    char: "🧑🏻‍🎄",
    name: "mx claus: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F384",
    char: "🧑🏼‍🎄",
    name: "mx claus: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F384",
    char: "🧑🏽‍🎄",
    name: "mx claus: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F384",
    char: "🧑🏾‍🎄",
    name: "mx claus: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F384",
    char: "🧑🏿‍🎄",
    name: "mx claus: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8",
    char: "🦸",
    name: "superhero",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FB",
    char: "🦸🏻",
    name: "superhero: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FC",
    char: "🦸🏼",
    name: "superhero: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FD",
    char: "🦸🏽",
    name: "superhero: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FE",
    char: "🦸🏾",
    name: "superhero: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FF",
    char: "🦸🏿",
    name: "superhero: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 200D 2642 FE0F",
    char: "🦸‍♂️",
    name: "man superhero",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 200D 2642",
    char: "🦸‍♂",
    name: "man superhero",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FB 200D 2642 FE0F",
    char: "🦸🏻‍♂️",
    name: "man superhero: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FB 200D 2642",
    char: "🦸🏻‍♂",
    name: "man superhero: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FC 200D 2642 FE0F",
    char: "🦸🏼‍♂️",
    name: "man superhero: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FC 200D 2642",
    char: "🦸🏼‍♂",
    name: "man superhero: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FD 200D 2642 FE0F",
    char: "🦸🏽‍♂️",
    name: "man superhero: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FD 200D 2642",
    char: "🦸🏽‍♂",
    name: "man superhero: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FE 200D 2642 FE0F",
    char: "🦸🏾‍♂️",
    name: "man superhero: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FE 200D 2642",
    char: "🦸🏾‍♂",
    name: "man superhero: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FF 200D 2642 FE0F",
    char: "🦸🏿‍♂️",
    name: "man superhero: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FF 200D 2642",
    char: "🦸🏿‍♂",
    name: "man superhero: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 200D 2640 FE0F",
    char: "🦸‍♀️",
    name: "woman superhero",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 200D 2640",
    char: "🦸‍♀",
    name: "woman superhero",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FB 200D 2640 FE0F",
    char: "🦸🏻‍♀️",
    name: "woman superhero: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FB 200D 2640",
    char: "🦸🏻‍♀",
    name: "woman superhero: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FC 200D 2640 FE0F",
    char: "🦸🏼‍♀️",
    name: "woman superhero: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FC 200D 2640",
    char: "🦸🏼‍♀",
    name: "woman superhero: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FD 200D 2640 FE0F",
    char: "🦸🏽‍♀️",
    name: "woman superhero: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FD 200D 2640",
    char: "🦸🏽‍♀",
    name: "woman superhero: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FE 200D 2640 FE0F",
    char: "🦸🏾‍♀️",
    name: "woman superhero: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FE 200D 2640",
    char: "🦸🏾‍♀",
    name: "woman superhero: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FF 200D 2640 FE0F",
    char: "🦸🏿‍♀️",
    name: "woman superhero: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B8 1F3FF 200D 2640",
    char: "🦸🏿‍♀",
    name: "woman superhero: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9",
    char: "🦹",
    name: "supervillain",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FB",
    char: "🦹🏻",
    name: "supervillain: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FC",
    char: "🦹🏼",
    name: "supervillain: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FD",
    char: "🦹🏽",
    name: "supervillain: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FE",
    char: "🦹🏾",
    name: "supervillain: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FF",
    char: "🦹🏿",
    name: "supervillain: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 200D 2642 FE0F",
    char: "🦹‍♂️",
    name: "man supervillain",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 200D 2642",
    char: "🦹‍♂",
    name: "man supervillain",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FB 200D 2642 FE0F",
    char: "🦹🏻‍♂️",
    name: "man supervillain: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FB 200D 2642",
    char: "🦹🏻‍♂",
    name: "man supervillain: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FC 200D 2642 FE0F",
    char: "🦹🏼‍♂️",
    name: "man supervillain: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FC 200D 2642",
    char: "🦹🏼‍♂",
    name: "man supervillain: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FD 200D 2642 FE0F",
    char: "🦹🏽‍♂️",
    name: "man supervillain: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FD 200D 2642",
    char: "🦹🏽‍♂",
    name: "man supervillain: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FE 200D 2642 FE0F",
    char: "🦹🏾‍♂️",
    name: "man supervillain: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FE 200D 2642",
    char: "🦹🏾‍♂",
    name: "man supervillain: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FF 200D 2642 FE0F",
    char: "🦹🏿‍♂️",
    name: "man supervillain: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FF 200D 2642",
    char: "🦹🏿‍♂",
    name: "man supervillain: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 200D 2640 FE0F",
    char: "🦹‍♀️",
    name: "woman supervillain",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 200D 2640",
    char: "🦹‍♀",
    name: "woman supervillain",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FB 200D 2640 FE0F",
    char: "🦹🏻‍♀️",
    name: "woman supervillain: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FB 200D 2640",
    char: "🦹🏻‍♀",
    name: "woman supervillain: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FC 200D 2640 FE0F",
    char: "🦹🏼‍♀️",
    name: "woman supervillain: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FC 200D 2640",
    char: "🦹🏼‍♀",
    name: "woman supervillain: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FD 200D 2640 FE0F",
    char: "🦹🏽‍♀️",
    name: "woman supervillain: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FD 200D 2640",
    char: "🦹🏽‍♀",
    name: "woman supervillain: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FE 200D 2640 FE0F",
    char: "🦹🏾‍♀️",
    name: "woman supervillain: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FE 200D 2640",
    char: "🦹🏾‍♀",
    name: "woman supervillain: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FF 200D 2640 FE0F",
    char: "🦹🏿‍♀️",
    name: "woman supervillain: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9B9 1F3FF 200D 2640",
    char: "🦹🏿‍♀",
    name: "woman supervillain: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9",
    char: "🧙",
    name: "mage",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FB",
    char: "🧙🏻",
    name: "mage: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FC",
    char: "🧙🏼",
    name: "mage: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FD",
    char: "🧙🏽",
    name: "mage: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FE",
    char: "🧙🏾",
    name: "mage: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FF",
    char: "🧙🏿",
    name: "mage: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 200D 2642 FE0F",
    char: "🧙‍♂️",
    name: "man mage",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 200D 2642",
    char: "🧙‍♂",
    name: "man mage",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FB 200D 2642 FE0F",
    char: "🧙🏻‍♂️",
    name: "man mage: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FB 200D 2642",
    char: "🧙🏻‍♂",
    name: "man mage: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FC 200D 2642 FE0F",
    char: "🧙🏼‍♂️",
    name: "man mage: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FC 200D 2642",
    char: "🧙🏼‍♂",
    name: "man mage: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FD 200D 2642 FE0F",
    char: "🧙🏽‍♂️",
    name: "man mage: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FD 200D 2642",
    char: "🧙🏽‍♂",
    name: "man mage: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FE 200D 2642 FE0F",
    char: "🧙🏾‍♂️",
    name: "man mage: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FE 200D 2642",
    char: "🧙🏾‍♂",
    name: "man mage: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FF 200D 2642 FE0F",
    char: "🧙🏿‍♂️",
    name: "man mage: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FF 200D 2642",
    char: "🧙🏿‍♂",
    name: "man mage: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 200D 2640 FE0F",
    char: "🧙‍♀️",
    name: "woman mage",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 200D 2640",
    char: "🧙‍♀",
    name: "woman mage",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FB 200D 2640 FE0F",
    char: "🧙🏻‍♀️",
    name: "woman mage: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FB 200D 2640",
    char: "🧙🏻‍♀",
    name: "woman mage: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FC 200D 2640 FE0F",
    char: "🧙🏼‍♀️",
    name: "woman mage: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FC 200D 2640",
    char: "🧙🏼‍♀",
    name: "woman mage: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FD 200D 2640 FE0F",
    char: "🧙🏽‍♀️",
    name: "woman mage: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FD 200D 2640",
    char: "🧙🏽‍♀",
    name: "woman mage: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FE 200D 2640 FE0F",
    char: "🧙🏾‍♀️",
    name: "woman mage: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FE 200D 2640",
    char: "🧙🏾‍♀",
    name: "woman mage: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FF 200D 2640 FE0F",
    char: "🧙🏿‍♀️",
    name: "woman mage: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9D9 1F3FF 200D 2640",
    char: "🧙🏿‍♀",
    name: "woman mage: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA",
    char: "🧚",
    name: "fairy",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FB",
    char: "🧚🏻",
    name: "fairy: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FC",
    char: "🧚🏼",
    name: "fairy: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FD",
    char: "🧚🏽",
    name: "fairy: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FE",
    char: "🧚🏾",
    name: "fairy: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FF",
    char: "🧚🏿",
    name: "fairy: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 200D 2642 FE0F",
    char: "🧚‍♂️",
    name: "man fairy",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 200D 2642",
    char: "🧚‍♂",
    name: "man fairy",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FB 200D 2642 FE0F",
    char: "🧚🏻‍♂️",
    name: "man fairy: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FB 200D 2642",
    char: "🧚🏻‍♂",
    name: "man fairy: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FC 200D 2642 FE0F",
    char: "🧚🏼‍♂️",
    name: "man fairy: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FC 200D 2642",
    char: "🧚🏼‍♂",
    name: "man fairy: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FD 200D 2642 FE0F",
    char: "🧚🏽‍♂️",
    name: "man fairy: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FD 200D 2642",
    char: "🧚🏽‍♂",
    name: "man fairy: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FE 200D 2642 FE0F",
    char: "🧚🏾‍♂️",
    name: "man fairy: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FE 200D 2642",
    char: "🧚🏾‍♂",
    name: "man fairy: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FF 200D 2642 FE0F",
    char: "🧚🏿‍♂️",
    name: "man fairy: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FF 200D 2642",
    char: "🧚🏿‍♂",
    name: "man fairy: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 200D 2640 FE0F",
    char: "🧚‍♀️",
    name: "woman fairy",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 200D 2640",
    char: "🧚‍♀",
    name: "woman fairy",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FB 200D 2640 FE0F",
    char: "🧚🏻‍♀️",
    name: "woman fairy: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FB 200D 2640",
    char: "🧚🏻‍♀",
    name: "woman fairy: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FC 200D 2640 FE0F",
    char: "🧚🏼‍♀️",
    name: "woman fairy: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FC 200D 2640",
    char: "🧚🏼‍♀",
    name: "woman fairy: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FD 200D 2640 FE0F",
    char: "🧚🏽‍♀️",
    name: "woman fairy: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FD 200D 2640",
    char: "🧚🏽‍♀",
    name: "woman fairy: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FE 200D 2640 FE0F",
    char: "🧚🏾‍♀️",
    name: "woman fairy: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FE 200D 2640",
    char: "🧚🏾‍♀",
    name: "woman fairy: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FF 200D 2640 FE0F",
    char: "🧚🏿‍♀️",
    name: "woman fairy: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DA 1F3FF 200D 2640",
    char: "🧚🏿‍♀",
    name: "woman fairy: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB",
    char: "🧛",
    name: "vampire",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FB",
    char: "🧛🏻",
    name: "vampire: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FC",
    char: "🧛🏼",
    name: "vampire: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FD",
    char: "🧛🏽",
    name: "vampire: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FE",
    char: "🧛🏾",
    name: "vampire: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FF",
    char: "🧛🏿",
    name: "vampire: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 200D 2642 FE0F",
    char: "🧛‍♂️",
    name: "man vampire",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 200D 2642",
    char: "🧛‍♂",
    name: "man vampire",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FB 200D 2642 FE0F",
    char: "🧛🏻‍♂️",
    name: "man vampire: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FB 200D 2642",
    char: "🧛🏻‍♂",
    name: "man vampire: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FC 200D 2642 FE0F",
    char: "🧛🏼‍♂️",
    name: "man vampire: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FC 200D 2642",
    char: "🧛🏼‍♂",
    name: "man vampire: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FD 200D 2642 FE0F",
    char: "🧛🏽‍♂️",
    name: "man vampire: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FD 200D 2642",
    char: "🧛🏽‍♂",
    name: "man vampire: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FE 200D 2642 FE0F",
    char: "🧛🏾‍♂️",
    name: "man vampire: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FE 200D 2642",
    char: "🧛🏾‍♂",
    name: "man vampire: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FF 200D 2642 FE0F",
    char: "🧛🏿‍♂️",
    name: "man vampire: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FF 200D 2642",
    char: "🧛🏿‍♂",
    name: "man vampire: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 200D 2640 FE0F",
    char: "🧛‍♀️",
    name: "woman vampire",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 200D 2640",
    char: "🧛‍♀",
    name: "woman vampire",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FB 200D 2640 FE0F",
    char: "🧛🏻‍♀️",
    name: "woman vampire: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FB 200D 2640",
    char: "🧛🏻‍♀",
    name: "woman vampire: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FC 200D 2640 FE0F",
    char: "🧛🏼‍♀️",
    name: "woman vampire: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FC 200D 2640",
    char: "🧛🏼‍♀",
    name: "woman vampire: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FD 200D 2640 FE0F",
    char: "🧛🏽‍♀️",
    name: "woman vampire: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FD 200D 2640",
    char: "🧛🏽‍♀",
    name: "woman vampire: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FE 200D 2640 FE0F",
    char: "🧛🏾‍♀️",
    name: "woman vampire: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FE 200D 2640",
    char: "🧛🏾‍♀",
    name: "woman vampire: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FF 200D 2640 FE0F",
    char: "🧛🏿‍♀️",
    name: "woman vampire: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DB 1F3FF 200D 2640",
    char: "🧛🏿‍♀",
    name: "woman vampire: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC",
    char: "🧜",
    name: "merperson",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FB",
    char: "🧜🏻",
    name: "merperson: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FC",
    char: "🧜🏼",
    name: "merperson: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FD",
    char: "🧜🏽",
    name: "merperson: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FE",
    char: "🧜🏾",
    name: "merperson: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FF",
    char: "🧜🏿",
    name: "merperson: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 200D 2642 FE0F",
    char: "🧜‍♂️",
    name: "merman",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 200D 2642",
    char: "🧜‍♂",
    name: "merman",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FB 200D 2642 FE0F",
    char: "🧜🏻‍♂️",
    name: "merman: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FB 200D 2642",
    char: "🧜🏻‍♂",
    name: "merman: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FC 200D 2642 FE0F",
    char: "🧜🏼‍♂️",
    name: "merman: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FC 200D 2642",
    char: "🧜🏼‍♂",
    name: "merman: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FD 200D 2642 FE0F",
    char: "🧜🏽‍♂️",
    name: "merman: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FD 200D 2642",
    char: "🧜🏽‍♂",
    name: "merman: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FE 200D 2642 FE0F",
    char: "🧜🏾‍♂️",
    name: "merman: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FE 200D 2642",
    char: "🧜🏾‍♂",
    name: "merman: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FF 200D 2642 FE0F",
    char: "🧜🏿‍♂️",
    name: "merman: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FF 200D 2642",
    char: "🧜🏿‍♂",
    name: "merman: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 200D 2640 FE0F",
    char: "🧜‍♀️",
    name: "mermaid",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 200D 2640",
    char: "🧜‍♀",
    name: "mermaid",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FB 200D 2640 FE0F",
    char: "🧜🏻‍♀️",
    name: "mermaid: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FB 200D 2640",
    char: "🧜🏻‍♀",
    name: "mermaid: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FC 200D 2640 FE0F",
    char: "🧜🏼‍♀️",
    name: "mermaid: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FC 200D 2640",
    char: "🧜🏼‍♀",
    name: "mermaid: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FD 200D 2640 FE0F",
    char: "🧜🏽‍♀️",
    name: "mermaid: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FD 200D 2640",
    char: "🧜🏽‍♀",
    name: "mermaid: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FE 200D 2640 FE0F",
    char: "🧜🏾‍♀️",
    name: "mermaid: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FE 200D 2640",
    char: "🧜🏾‍♀",
    name: "mermaid: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FF 200D 2640 FE0F",
    char: "🧜🏿‍♀️",
    name: "mermaid: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DC 1F3FF 200D 2640",
    char: "🧜🏿‍♀",
    name: "mermaid: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD",
    char: "🧝",
    name: "elf",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FB",
    char: "🧝🏻",
    name: "elf: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FC",
    char: "🧝🏼",
    name: "elf: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FD",
    char: "🧝🏽",
    name: "elf: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FE",
    char: "🧝🏾",
    name: "elf: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FF",
    char: "🧝🏿",
    name: "elf: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 200D 2642 FE0F",
    char: "🧝‍♂️",
    name: "man elf",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 200D 2642",
    char: "🧝‍♂",
    name: "man elf",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FB 200D 2642 FE0F",
    char: "🧝🏻‍♂️",
    name: "man elf: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FB 200D 2642",
    char: "🧝🏻‍♂",
    name: "man elf: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FC 200D 2642 FE0F",
    char: "🧝🏼‍♂️",
    name: "man elf: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FC 200D 2642",
    char: "🧝🏼‍♂",
    name: "man elf: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FD 200D 2642 FE0F",
    char: "🧝🏽‍♂️",
    name: "man elf: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FD 200D 2642",
    char: "🧝🏽‍♂",
    name: "man elf: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FE 200D 2642 FE0F",
    char: "🧝🏾‍♂️",
    name: "man elf: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FE 200D 2642",
    char: "🧝🏾‍♂",
    name: "man elf: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FF 200D 2642 FE0F",
    char: "🧝🏿‍♂️",
    name: "man elf: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FF 200D 2642",
    char: "🧝🏿‍♂",
    name: "man elf: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 200D 2640 FE0F",
    char: "🧝‍♀️",
    name: "woman elf",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 200D 2640",
    char: "🧝‍♀",
    name: "woman elf",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FB 200D 2640 FE0F",
    char: "🧝🏻‍♀️",
    name: "woman elf: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FB 200D 2640",
    char: "🧝🏻‍♀",
    name: "woman elf: light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FC 200D 2640 FE0F",
    char: "🧝🏼‍♀️",
    name: "woman elf: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FC 200D 2640",
    char: "🧝🏼‍♀",
    name: "woman elf: medium-light skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FD 200D 2640 FE0F",
    char: "🧝🏽‍♀️",
    name: "woman elf: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FD 200D 2640",
    char: "🧝🏽‍♀",
    name: "woman elf: medium skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FE 200D 2640 FE0F",
    char: "🧝🏾‍♀️",
    name: "woman elf: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FE 200D 2640",
    char: "🧝🏾‍♀",
    name: "woman elf: medium-dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FF 200D 2640 FE0F",
    char: "🧝🏿‍♀️",
    name: "woman elf: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DD 1F3FF 200D 2640",
    char: "🧝🏿‍♀",
    name: "woman elf: dark skin tone",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DE",
    char: "🧞",
    name: "genie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DE 200D 2642 FE0F",
    char: "🧞‍♂️",
    name: "man genie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DE 200D 2642",
    char: "🧞‍♂",
    name: "man genie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DE 200D 2640 FE0F",
    char: "🧞‍♀️",
    name: "woman genie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DE 200D 2640",
    char: "🧞‍♀",
    name: "woman genie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DF",
    char: "🧟",
    name: "zombie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DF 200D 2642 FE0F",
    char: "🧟‍♂️",
    name: "man zombie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DF 200D 2642",
    char: "🧟‍♂",
    name: "man zombie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DF 200D 2640 FE0F",
    char: "🧟‍♀️",
    name: "woman zombie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9DF 200D 2640",
    char: "🧟‍♀",
    name: "woman zombie",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F9CC",
    char: "🧌",
    name: "troll",
    category: "People & Body (person-fantasy)",
    group: "People & Body",
    subgroup: "person-fantasy",
  },
  {
    codes: "1F486",
    char: "💆",
    name: "person getting massage",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FB",
    char: "💆🏻",
    name: "person getting massage: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FC",
    char: "💆🏼",
    name: "person getting massage: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FD",
    char: "💆🏽",
    name: "person getting massage: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FE",
    char: "💆🏾",
    name: "person getting massage: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FF",
    char: "💆🏿",
    name: "person getting massage: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 200D 2642 FE0F",
    char: "💆‍♂️",
    name: "man getting massage",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 200D 2642",
    char: "💆‍♂",
    name: "man getting massage",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FB 200D 2642 FE0F",
    char: "💆🏻‍♂️",
    name: "man getting massage: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FB 200D 2642",
    char: "💆🏻‍♂",
    name: "man getting massage: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FC 200D 2642 FE0F",
    char: "💆🏼‍♂️",
    name: "man getting massage: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FC 200D 2642",
    char: "💆🏼‍♂",
    name: "man getting massage: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FD 200D 2642 FE0F",
    char: "💆🏽‍♂️",
    name: "man getting massage: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FD 200D 2642",
    char: "💆🏽‍♂",
    name: "man getting massage: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FE 200D 2642 FE0F",
    char: "💆🏾‍♂️",
    name: "man getting massage: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FE 200D 2642",
    char: "💆🏾‍♂",
    name: "man getting massage: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FF 200D 2642 FE0F",
    char: "💆🏿‍♂️",
    name: "man getting massage: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FF 200D 2642",
    char: "💆🏿‍♂",
    name: "man getting massage: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 200D 2640 FE0F",
    char: "💆‍♀️",
    name: "woman getting massage",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 200D 2640",
    char: "💆‍♀",
    name: "woman getting massage",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FB 200D 2640 FE0F",
    char: "💆🏻‍♀️",
    name: "woman getting massage: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FB 200D 2640",
    char: "💆🏻‍♀",
    name: "woman getting massage: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FC 200D 2640 FE0F",
    char: "💆🏼‍♀️",
    name: "woman getting massage: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FC 200D 2640",
    char: "💆🏼‍♀",
    name: "woman getting massage: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FD 200D 2640 FE0F",
    char: "💆🏽‍♀️",
    name: "woman getting massage: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FD 200D 2640",
    char: "💆🏽‍♀",
    name: "woman getting massage: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FE 200D 2640 FE0F",
    char: "💆🏾‍♀️",
    name: "woman getting massage: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FE 200D 2640",
    char: "💆🏾‍♀",
    name: "woman getting massage: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FF 200D 2640 FE0F",
    char: "💆🏿‍♀️",
    name: "woman getting massage: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F486 1F3FF 200D 2640",
    char: "💆🏿‍♀",
    name: "woman getting massage: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487",
    char: "💇",
    name: "person getting haircut",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FB",
    char: "💇🏻",
    name: "person getting haircut: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FC",
    char: "💇🏼",
    name: "person getting haircut: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FD",
    char: "💇🏽",
    name: "person getting haircut: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FE",
    char: "💇🏾",
    name: "person getting haircut: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FF",
    char: "💇🏿",
    name: "person getting haircut: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 200D 2642 FE0F",
    char: "💇‍♂️",
    name: "man getting haircut",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 200D 2642",
    char: "💇‍♂",
    name: "man getting haircut",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FB 200D 2642 FE0F",
    char: "💇🏻‍♂️",
    name: "man getting haircut: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FB 200D 2642",
    char: "💇🏻‍♂",
    name: "man getting haircut: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FC 200D 2642 FE0F",
    char: "💇🏼‍♂️",
    name: "man getting haircut: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FC 200D 2642",
    char: "💇🏼‍♂",
    name: "man getting haircut: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FD 200D 2642 FE0F",
    char: "💇🏽‍♂️",
    name: "man getting haircut: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FD 200D 2642",
    char: "💇🏽‍♂",
    name: "man getting haircut: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FE 200D 2642 FE0F",
    char: "💇🏾‍♂️",
    name: "man getting haircut: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FE 200D 2642",
    char: "💇🏾‍♂",
    name: "man getting haircut: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FF 200D 2642 FE0F",
    char: "💇🏿‍♂️",
    name: "man getting haircut: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FF 200D 2642",
    char: "💇🏿‍♂",
    name: "man getting haircut: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 200D 2640 FE0F",
    char: "💇‍♀️",
    name: "woman getting haircut",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 200D 2640",
    char: "💇‍♀",
    name: "woman getting haircut",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FB 200D 2640 FE0F",
    char: "💇🏻‍♀️",
    name: "woman getting haircut: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FB 200D 2640",
    char: "💇🏻‍♀",
    name: "woman getting haircut: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FC 200D 2640 FE0F",
    char: "💇🏼‍♀️",
    name: "woman getting haircut: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FC 200D 2640",
    char: "💇🏼‍♀",
    name: "woman getting haircut: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FD 200D 2640 FE0F",
    char: "💇🏽‍♀️",
    name: "woman getting haircut: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FD 200D 2640",
    char: "💇🏽‍♀",
    name: "woman getting haircut: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FE 200D 2640 FE0F",
    char: "💇🏾‍♀️",
    name: "woman getting haircut: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FE 200D 2640",
    char: "💇🏾‍♀",
    name: "woman getting haircut: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FF 200D 2640 FE0F",
    char: "💇🏿‍♀️",
    name: "woman getting haircut: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F487 1F3FF 200D 2640",
    char: "💇🏿‍♀",
    name: "woman getting haircut: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6",
    char: "🚶",
    name: "person walking",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB",
    char: "🚶🏻",
    name: "person walking: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC",
    char: "🚶🏼",
    name: "person walking: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD",
    char: "🚶🏽",
    name: "person walking: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE",
    char: "🚶🏾",
    name: "person walking: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF",
    char: "🚶🏿",
    name: "person walking: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2642 FE0F",
    char: "🚶‍♂️",
    name: "man walking",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2642",
    char: "🚶‍♂",
    name: "man walking",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2642 FE0F",
    char: "🚶🏻‍♂️",
    name: "man walking: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2642",
    char: "🚶🏻‍♂",
    name: "man walking: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2642 FE0F",
    char: "🚶🏼‍♂️",
    name: "man walking: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2642",
    char: "🚶🏼‍♂",
    name: "man walking: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2642 FE0F",
    char: "🚶🏽‍♂️",
    name: "man walking: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2642",
    char: "🚶🏽‍♂",
    name: "man walking: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2642 FE0F",
    char: "🚶🏾‍♂️",
    name: "man walking: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2642",
    char: "🚶🏾‍♂",
    name: "man walking: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2642 FE0F",
    char: "🚶🏿‍♂️",
    name: "man walking: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2642",
    char: "🚶🏿‍♂",
    name: "man walking: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2640 FE0F",
    char: "🚶‍♀️",
    name: "woman walking",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2640",
    char: "🚶‍♀",
    name: "woman walking",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2640 FE0F",
    char: "🚶🏻‍♀️",
    name: "woman walking: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2640",
    char: "🚶🏻‍♀",
    name: "woman walking: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2640 FE0F",
    char: "🚶🏼‍♀️",
    name: "woman walking: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2640",
    char: "🚶🏼‍♀",
    name: "woman walking: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2640 FE0F",
    char: "🚶🏽‍♀️",
    name: "woman walking: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2640",
    char: "🚶🏽‍♀",
    name: "woman walking: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2640 FE0F",
    char: "🚶🏾‍♀️",
    name: "woman walking: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2640",
    char: "🚶🏾‍♀",
    name: "woman walking: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2640 FE0F",
    char: "🚶🏿‍♀️",
    name: "woman walking: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2640",
    char: "🚶🏿‍♀",
    name: "woman walking: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 27A1 FE0F",
    char: "🚶‍➡️",
    name: "person walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 27A1",
    char: "🚶‍➡",
    name: "person walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 27A1 FE0F",
    char: "🚶🏻‍➡️",
    name: "person walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 27A1",
    char: "🚶🏻‍➡",
    name: "person walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 27A1 FE0F",
    char: "🚶🏼‍➡️",
    name: "person walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 27A1",
    char: "🚶🏼‍➡",
    name: "person walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 27A1 FE0F",
    char: "🚶🏽‍➡️",
    name: "person walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 27A1",
    char: "🚶🏽‍➡",
    name: "person walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 27A1 FE0F",
    char: "🚶🏾‍➡️",
    name: "person walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 27A1",
    char: "🚶🏾‍➡",
    name: "person walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 27A1 FE0F",
    char: "🚶🏿‍➡️",
    name: "person walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 27A1",
    char: "🚶🏿‍➡",
    name: "person walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🚶‍♀️‍➡️",
    name: "woman walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2640 200D 27A1 FE0F",
    char: "🚶‍♀‍➡️",
    name: "woman walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2640 FE0F 200D 27A1",
    char: "🚶‍♀️‍➡",
    name: "woman walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2640 200D 27A1",
    char: "🚶‍♀‍➡",
    name: "woman walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🚶🏻‍♀️‍➡️",
    name: "woman walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2640 200D 27A1 FE0F",
    char: "🚶🏻‍♀‍➡️",
    name: "woman walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2640 FE0F 200D 27A1",
    char: "🚶🏻‍♀️‍➡",
    name: "woman walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2640 200D 27A1",
    char: "🚶🏻‍♀‍➡",
    name: "woman walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🚶🏼‍♀️‍➡️",
    name: "woman walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2640 200D 27A1 FE0F",
    char: "🚶🏼‍♀‍➡️",
    name: "woman walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2640 FE0F 200D 27A1",
    char: "🚶🏼‍♀️‍➡",
    name: "woman walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2640 200D 27A1",
    char: "🚶🏼‍♀‍➡",
    name: "woman walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🚶🏽‍♀️‍➡️",
    name: "woman walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2640 200D 27A1 FE0F",
    char: "🚶🏽‍♀‍➡️",
    name: "woman walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2640 FE0F 200D 27A1",
    char: "🚶🏽‍♀️‍➡",
    name: "woman walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2640 200D 27A1",
    char: "🚶🏽‍♀‍➡",
    name: "woman walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🚶🏾‍♀️‍➡️",
    name: "woman walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2640 200D 27A1 FE0F",
    char: "🚶🏾‍♀‍➡️",
    name: "woman walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2640 FE0F 200D 27A1",
    char: "🚶🏾‍♀️‍➡",
    name: "woman walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2640 200D 27A1",
    char: "🚶🏾‍♀‍➡",
    name: "woman walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🚶🏿‍♀️‍➡️",
    name: "woman walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2640 200D 27A1 FE0F",
    char: "🚶🏿‍♀‍➡️",
    name: "woman walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2640 FE0F 200D 27A1",
    char: "🚶🏿‍♀️‍➡",
    name: "woman walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2640 200D 27A1",
    char: "🚶🏿‍♀‍➡",
    name: "woman walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🚶‍♂️‍➡️",
    name: "man walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2642 200D 27A1 FE0F",
    char: "🚶‍♂‍➡️",
    name: "man walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2642 FE0F 200D 27A1",
    char: "🚶‍♂️‍➡",
    name: "man walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 200D 2642 200D 27A1",
    char: "🚶‍♂‍➡",
    name: "man walking facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🚶🏻‍♂️‍➡️",
    name: "man walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2642 200D 27A1 FE0F",
    char: "🚶🏻‍♂‍➡️",
    name: "man walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2642 FE0F 200D 27A1",
    char: "🚶🏻‍♂️‍➡",
    name: "man walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FB 200D 2642 200D 27A1",
    char: "🚶🏻‍♂‍➡",
    name: "man walking facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🚶🏼‍♂️‍➡️",
    name: "man walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2642 200D 27A1 FE0F",
    char: "🚶🏼‍♂‍➡️",
    name: "man walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2642 FE0F 200D 27A1",
    char: "🚶🏼‍♂️‍➡",
    name: "man walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FC 200D 2642 200D 27A1",
    char: "🚶🏼‍♂‍➡",
    name: "man walking facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🚶🏽‍♂️‍➡️",
    name: "man walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2642 200D 27A1 FE0F",
    char: "🚶🏽‍♂‍➡️",
    name: "man walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2642 FE0F 200D 27A1",
    char: "🚶🏽‍♂️‍➡",
    name: "man walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FD 200D 2642 200D 27A1",
    char: "🚶🏽‍♂‍➡",
    name: "man walking facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🚶🏾‍♂️‍➡️",
    name: "man walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2642 200D 27A1 FE0F",
    char: "🚶🏾‍♂‍➡️",
    name: "man walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2642 FE0F 200D 27A1",
    char: "🚶🏾‍♂️‍➡",
    name: "man walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FE 200D 2642 200D 27A1",
    char: "🚶🏾‍♂‍➡",
    name: "man walking facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🚶🏿‍♂️‍➡️",
    name: "man walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2642 200D 27A1 FE0F",
    char: "🚶🏿‍♂‍➡️",
    name: "man walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2642 FE0F 200D 27A1",
    char: "🚶🏿‍♂️‍➡",
    name: "man walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F6B6 1F3FF 200D 2642 200D 27A1",
    char: "🚶🏿‍♂‍➡",
    name: "man walking facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD",
    char: "🧍",
    name: "person standing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FB",
    char: "🧍🏻",
    name: "person standing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FC",
    char: "🧍🏼",
    name: "person standing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FD",
    char: "🧍🏽",
    name: "person standing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FE",
    char: "🧍🏾",
    name: "person standing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FF",
    char: "🧍🏿",
    name: "person standing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 200D 2642 FE0F",
    char: "🧍‍♂️",
    name: "man standing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 200D 2642",
    char: "🧍‍♂",
    name: "man standing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FB 200D 2642 FE0F",
    char: "🧍🏻‍♂️",
    name: "man standing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FB 200D 2642",
    char: "🧍🏻‍♂",
    name: "man standing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FC 200D 2642 FE0F",
    char: "🧍🏼‍♂️",
    name: "man standing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FC 200D 2642",
    char: "🧍🏼‍♂",
    name: "man standing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FD 200D 2642 FE0F",
    char: "🧍🏽‍♂️",
    name: "man standing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FD 200D 2642",
    char: "🧍🏽‍♂",
    name: "man standing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FE 200D 2642 FE0F",
    char: "🧍🏾‍♂️",
    name: "man standing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FE 200D 2642",
    char: "🧍🏾‍♂",
    name: "man standing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FF 200D 2642 FE0F",
    char: "🧍🏿‍♂️",
    name: "man standing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FF 200D 2642",
    char: "🧍🏿‍♂",
    name: "man standing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 200D 2640 FE0F",
    char: "🧍‍♀️",
    name: "woman standing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 200D 2640",
    char: "🧍‍♀",
    name: "woman standing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FB 200D 2640 FE0F",
    char: "🧍🏻‍♀️",
    name: "woman standing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FB 200D 2640",
    char: "🧍🏻‍♀",
    name: "woman standing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FC 200D 2640 FE0F",
    char: "🧍🏼‍♀️",
    name: "woman standing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FC 200D 2640",
    char: "🧍🏼‍♀",
    name: "woman standing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FD 200D 2640 FE0F",
    char: "🧍🏽‍♀️",
    name: "woman standing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FD 200D 2640",
    char: "🧍🏽‍♀",
    name: "woman standing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FE 200D 2640 FE0F",
    char: "🧍🏾‍♀️",
    name: "woman standing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FE 200D 2640",
    char: "🧍🏾‍♀",
    name: "woman standing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FF 200D 2640 FE0F",
    char: "🧍🏿‍♀️",
    name: "woman standing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CD 1F3FF 200D 2640",
    char: "🧍🏿‍♀",
    name: "woman standing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE",
    char: "🧎",
    name: "person kneeling",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB",
    char: "🧎🏻",
    name: "person kneeling: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC",
    char: "🧎🏼",
    name: "person kneeling: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD",
    char: "🧎🏽",
    name: "person kneeling: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE",
    char: "🧎🏾",
    name: "person kneeling: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF",
    char: "🧎🏿",
    name: "person kneeling: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2642 FE0F",
    char: "🧎‍♂️",
    name: "man kneeling",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2642",
    char: "🧎‍♂",
    name: "man kneeling",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2642 FE0F",
    char: "🧎🏻‍♂️",
    name: "man kneeling: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2642",
    char: "🧎🏻‍♂",
    name: "man kneeling: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2642 FE0F",
    char: "🧎🏼‍♂️",
    name: "man kneeling: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2642",
    char: "🧎🏼‍♂",
    name: "man kneeling: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2642 FE0F",
    char: "🧎🏽‍♂️",
    name: "man kneeling: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2642",
    char: "🧎🏽‍♂",
    name: "man kneeling: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2642 FE0F",
    char: "🧎🏾‍♂️",
    name: "man kneeling: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2642",
    char: "🧎🏾‍♂",
    name: "man kneeling: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2642 FE0F",
    char: "🧎🏿‍♂️",
    name: "man kneeling: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2642",
    char: "🧎🏿‍♂",
    name: "man kneeling: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2640 FE0F",
    char: "🧎‍♀️",
    name: "woman kneeling",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2640",
    char: "🧎‍♀",
    name: "woman kneeling",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2640 FE0F",
    char: "🧎🏻‍♀️",
    name: "woman kneeling: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2640",
    char: "🧎🏻‍♀",
    name: "woman kneeling: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2640 FE0F",
    char: "🧎🏼‍♀️",
    name: "woman kneeling: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2640",
    char: "🧎🏼‍♀",
    name: "woman kneeling: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2640 FE0F",
    char: "🧎🏽‍♀️",
    name: "woman kneeling: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2640",
    char: "🧎🏽‍♀",
    name: "woman kneeling: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2640 FE0F",
    char: "🧎🏾‍♀️",
    name: "woman kneeling: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2640",
    char: "🧎🏾‍♀",
    name: "woman kneeling: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2640 FE0F",
    char: "🧎🏿‍♀️",
    name: "woman kneeling: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2640",
    char: "🧎🏿‍♀",
    name: "woman kneeling: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 27A1 FE0F",
    char: "🧎‍➡️",
    name: "person kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 27A1",
    char: "🧎‍➡",
    name: "person kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 27A1 FE0F",
    char: "🧎🏻‍➡️",
    name: "person kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 27A1",
    char: "🧎🏻‍➡",
    name: "person kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 27A1 FE0F",
    char: "🧎🏼‍➡️",
    name: "person kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 27A1",
    char: "🧎🏼‍➡",
    name: "person kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 27A1 FE0F",
    char: "🧎🏽‍➡️",
    name: "person kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 27A1",
    char: "🧎🏽‍➡",
    name: "person kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 27A1 FE0F",
    char: "🧎🏾‍➡️",
    name: "person kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 27A1",
    char: "🧎🏾‍➡",
    name: "person kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 27A1 FE0F",
    char: "🧎🏿‍➡️",
    name: "person kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 27A1",
    char: "🧎🏿‍➡",
    name: "person kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🧎‍♀️‍➡️",
    name: "woman kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2640 200D 27A1 FE0F",
    char: "🧎‍♀‍➡️",
    name: "woman kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2640 FE0F 200D 27A1",
    char: "🧎‍♀️‍➡",
    name: "woman kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2640 200D 27A1",
    char: "🧎‍♀‍➡",
    name: "woman kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🧎🏻‍♀️‍➡️",
    name: "woman kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2640 200D 27A1 FE0F",
    char: "🧎🏻‍♀‍➡️",
    name: "woman kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2640 FE0F 200D 27A1",
    char: "🧎🏻‍♀️‍➡",
    name: "woman kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2640 200D 27A1",
    char: "🧎🏻‍♀‍➡",
    name: "woman kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🧎🏼‍♀️‍➡️",
    name: "woman kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2640 200D 27A1 FE0F",
    char: "🧎🏼‍♀‍➡️",
    name: "woman kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2640 FE0F 200D 27A1",
    char: "🧎🏼‍♀️‍➡",
    name: "woman kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2640 200D 27A1",
    char: "🧎🏼‍♀‍➡",
    name: "woman kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🧎🏽‍♀️‍➡️",
    name: "woman kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2640 200D 27A1 FE0F",
    char: "🧎🏽‍♀‍➡️",
    name: "woman kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2640 FE0F 200D 27A1",
    char: "🧎🏽‍♀️‍➡",
    name: "woman kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2640 200D 27A1",
    char: "🧎🏽‍♀‍➡",
    name: "woman kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🧎🏾‍♀️‍➡️",
    name: "woman kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2640 200D 27A1 FE0F",
    char: "🧎🏾‍♀‍➡️",
    name: "woman kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2640 FE0F 200D 27A1",
    char: "🧎🏾‍♀️‍➡",
    name: "woman kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2640 200D 27A1",
    char: "🧎🏾‍♀‍➡",
    name: "woman kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🧎🏿‍♀️‍➡️",
    name: "woman kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2640 200D 27A1 FE0F",
    char: "🧎🏿‍♀‍➡️",
    name: "woman kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2640 FE0F 200D 27A1",
    char: "🧎🏿‍♀️‍➡",
    name: "woman kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2640 200D 27A1",
    char: "🧎🏿‍♀‍➡",
    name: "woman kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🧎‍♂️‍➡️",
    name: "man kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2642 200D 27A1 FE0F",
    char: "🧎‍♂‍➡️",
    name: "man kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2642 FE0F 200D 27A1",
    char: "🧎‍♂️‍➡",
    name: "man kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 200D 2642 200D 27A1",
    char: "🧎‍♂‍➡",
    name: "man kneeling facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🧎🏻‍♂️‍➡️",
    name: "man kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2642 200D 27A1 FE0F",
    char: "🧎🏻‍♂‍➡️",
    name: "man kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2642 FE0F 200D 27A1",
    char: "🧎🏻‍♂️‍➡",
    name: "man kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FB 200D 2642 200D 27A1",
    char: "🧎🏻‍♂‍➡",
    name: "man kneeling facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🧎🏼‍♂️‍➡️",
    name: "man kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2642 200D 27A1 FE0F",
    char: "🧎🏼‍♂‍➡️",
    name: "man kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2642 FE0F 200D 27A1",
    char: "🧎🏼‍♂️‍➡",
    name: "man kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FC 200D 2642 200D 27A1",
    char: "🧎🏼‍♂‍➡",
    name: "man kneeling facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🧎🏽‍♂️‍➡️",
    name: "man kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2642 200D 27A1 FE0F",
    char: "🧎🏽‍♂‍➡️",
    name: "man kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2642 FE0F 200D 27A1",
    char: "🧎🏽‍♂️‍➡",
    name: "man kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FD 200D 2642 200D 27A1",
    char: "🧎🏽‍♂‍➡",
    name: "man kneeling facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🧎🏾‍♂️‍➡️",
    name: "man kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2642 200D 27A1 FE0F",
    char: "🧎🏾‍♂‍➡️",
    name: "man kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2642 FE0F 200D 27A1",
    char: "🧎🏾‍♂️‍➡",
    name: "man kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FE 200D 2642 200D 27A1",
    char: "🧎🏾‍♂‍➡",
    name: "man kneeling facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🧎🏿‍♂️‍➡️",
    name: "man kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2642 200D 27A1 FE0F",
    char: "🧎🏿‍♂‍➡️",
    name: "man kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2642 FE0F 200D 27A1",
    char: "🧎🏿‍♂️‍➡",
    name: "man kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9CE 1F3FF 200D 2642 200D 27A1",
    char: "🧎🏿‍♂‍➡",
    name: "man kneeling facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 200D 1F9AF",
    char: "🧑‍🦯",
    name: "person with white cane",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9AF",
    char: "🧑🏻‍🦯",
    name: "person with white cane: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9AF",
    char: "🧑🏼‍🦯",
    name: "person with white cane: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9AF",
    char: "🧑🏽‍🦯",
    name: "person with white cane: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9AF",
    char: "🧑🏾‍🦯",
    name: "person with white cane: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9AF",
    char: "🧑🏿‍🦯",
    name: "person with white cane: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 200D 1F9AF 200D 27A1 FE0F",
    char: "🧑‍🦯‍➡️",
    name: "person with white cane facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 200D 1F9AF 200D 27A1",
    char: "🧑‍🦯‍➡",
    name: "person with white cane facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9AF 200D 27A1 FE0F",
    char: "🧑🏻‍🦯‍➡️",
    name: "person with white cane facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9AF 200D 27A1",
    char: "🧑🏻‍🦯‍➡",
    name: "person with white cane facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9AF 200D 27A1 FE0F",
    char: "🧑🏼‍🦯‍➡️",
    name: "person with white cane facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9AF 200D 27A1",
    char: "🧑🏼‍🦯‍➡",
    name: "person with white cane facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9AF 200D 27A1 FE0F",
    char: "🧑🏽‍🦯‍➡️",
    name: "person with white cane facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9AF 200D 27A1",
    char: "🧑🏽‍🦯‍➡",
    name: "person with white cane facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9AF 200D 27A1 FE0F",
    char: "🧑🏾‍🦯‍➡️",
    name: "person with white cane facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9AF 200D 27A1",
    char: "🧑🏾‍🦯‍➡",
    name: "person with white cane facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9AF 200D 27A1 FE0F",
    char: "🧑🏿‍🦯‍➡️",
    name: "person with white cane facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9AF 200D 27A1",
    char: "🧑🏿‍🦯‍➡",
    name: "person with white cane facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 200D 1F9AF",
    char: "👨‍🦯",
    name: "man with white cane",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FB 200D 1F9AF",
    char: "👨🏻‍🦯",
    name: "man with white cane: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FC 200D 1F9AF",
    char: "👨🏼‍🦯",
    name: "man with white cane: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FD 200D 1F9AF",
    char: "👨🏽‍🦯",
    name: "man with white cane: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FE 200D 1F9AF",
    char: "👨🏾‍🦯",
    name: "man with white cane: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FF 200D 1F9AF",
    char: "👨🏿‍🦯",
    name: "man with white cane: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 200D 1F9AF 200D 27A1 FE0F",
    char: "👨‍🦯‍➡️",
    name: "man with white cane facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 200D 1F9AF 200D 27A1",
    char: "👨‍🦯‍➡",
    name: "man with white cane facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FB 200D 1F9AF 200D 27A1 FE0F",
    char: "👨🏻‍🦯‍➡️",
    name: "man with white cane facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FB 200D 1F9AF 200D 27A1",
    char: "👨🏻‍🦯‍➡",
    name: "man with white cane facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FC 200D 1F9AF 200D 27A1 FE0F",
    char: "👨🏼‍🦯‍➡️",
    name: "man with white cane facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FC 200D 1F9AF 200D 27A1",
    char: "👨🏼‍🦯‍➡",
    name: "man with white cane facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FD 200D 1F9AF 200D 27A1 FE0F",
    char: "👨🏽‍🦯‍➡️",
    name: "man with white cane facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FD 200D 1F9AF 200D 27A1",
    char: "👨🏽‍🦯‍➡",
    name: "man with white cane facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FE 200D 1F9AF 200D 27A1 FE0F",
    char: "👨🏾‍🦯‍➡️",
    name: "man with white cane facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FE 200D 1F9AF 200D 27A1",
    char: "👨🏾‍🦯‍➡",
    name: "man with white cane facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FF 200D 1F9AF 200D 27A1 FE0F",
    char: "👨🏿‍🦯‍➡️",
    name: "man with white cane facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FF 200D 1F9AF 200D 27A1",
    char: "👨🏿‍🦯‍➡",
    name: "man with white cane facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 200D 1F9AF",
    char: "👩‍🦯",
    name: "woman with white cane",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FB 200D 1F9AF",
    char: "👩🏻‍🦯",
    name: "woman with white cane: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FC 200D 1F9AF",
    char: "👩🏼‍🦯",
    name: "woman with white cane: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FD 200D 1F9AF",
    char: "👩🏽‍🦯",
    name: "woman with white cane: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FE 200D 1F9AF",
    char: "👩🏾‍🦯",
    name: "woman with white cane: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FF 200D 1F9AF",
    char: "👩🏿‍🦯",
    name: "woman with white cane: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 200D 1F9AF 200D 27A1 FE0F",
    char: "👩‍🦯‍➡️",
    name: "woman with white cane facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 200D 1F9AF 200D 27A1",
    char: "👩‍🦯‍➡",
    name: "woman with white cane facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FB 200D 1F9AF 200D 27A1 FE0F",
    char: "👩🏻‍🦯‍➡️",
    name: "woman with white cane facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FB 200D 1F9AF 200D 27A1",
    char: "👩🏻‍🦯‍➡",
    name: "woman with white cane facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FC 200D 1F9AF 200D 27A1 FE0F",
    char: "👩🏼‍🦯‍➡️",
    name: "woman with white cane facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FC 200D 1F9AF 200D 27A1",
    char: "👩🏼‍🦯‍➡",
    name: "woman with white cane facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FD 200D 1F9AF 200D 27A1 FE0F",
    char: "👩🏽‍🦯‍➡️",
    name: "woman with white cane facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FD 200D 1F9AF 200D 27A1",
    char: "👩🏽‍🦯‍➡",
    name: "woman with white cane facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FE 200D 1F9AF 200D 27A1 FE0F",
    char: "👩🏾‍🦯‍➡️",
    name: "woman with white cane facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FE 200D 1F9AF 200D 27A1",
    char: "👩🏾‍🦯‍➡",
    name: "woman with white cane facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FF 200D 1F9AF 200D 27A1 FE0F",
    char: "👩🏿‍🦯‍➡️",
    name: "woman with white cane facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FF 200D 1F9AF 200D 27A1",
    char: "👩🏿‍🦯‍➡",
    name: "woman with white cane facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 200D 1F9BC",
    char: "🧑‍🦼",
    name: "person in motorized wheelchair",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9BC",
    char: "🧑🏻‍🦼",
    name: "person in motorized wheelchair: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9BC",
    char: "🧑🏼‍🦼",
    name: "person in motorized wheelchair: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9BC",
    char: "🧑🏽‍🦼",
    name: "person in motorized wheelchair: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9BC",
    char: "🧑🏾‍🦼",
    name: "person in motorized wheelchair: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9BC",
    char: "🧑🏿‍🦼",
    name: "person in motorized wheelchair: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 200D 1F9BC 200D 27A1 FE0F",
    char: "🧑‍🦼‍➡️",
    name: "person in motorized wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 200D 1F9BC 200D 27A1",
    char: "🧑‍🦼‍➡",
    name: "person in motorized wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9BC 200D 27A1 FE0F",
    char: "🧑🏻‍🦼‍➡️",
    name: "person in motorized wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9BC 200D 27A1",
    char: "🧑🏻‍🦼‍➡",
    name: "person in motorized wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9BC 200D 27A1 FE0F",
    char: "🧑🏼‍🦼‍➡️",
    name: "person in motorized wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9BC 200D 27A1",
    char: "🧑🏼‍🦼‍➡",
    name: "person in motorized wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9BC 200D 27A1 FE0F",
    char: "🧑🏽‍🦼‍➡️",
    name: "person in motorized wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9BC 200D 27A1",
    char: "🧑🏽‍🦼‍➡",
    name: "person in motorized wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9BC 200D 27A1 FE0F",
    char: "🧑🏾‍🦼‍➡️",
    name: "person in motorized wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9BC 200D 27A1",
    char: "🧑🏾‍🦼‍➡",
    name: "person in motorized wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9BC 200D 27A1 FE0F",
    char: "🧑🏿‍🦼‍➡️",
    name: "person in motorized wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9BC 200D 27A1",
    char: "🧑🏿‍🦼‍➡",
    name: "person in motorized wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 200D 1F9BC",
    char: "👨‍🦼",
    name: "man in motorized wheelchair",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FB 200D 1F9BC",
    char: "👨🏻‍🦼",
    name: "man in motorized wheelchair: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FC 200D 1F9BC",
    char: "👨🏼‍🦼",
    name: "man in motorized wheelchair: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FD 200D 1F9BC",
    char: "👨🏽‍🦼",
    name: "man in motorized wheelchair: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FE 200D 1F9BC",
    char: "👨🏾‍🦼",
    name: "man in motorized wheelchair: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FF 200D 1F9BC",
    char: "👨🏿‍🦼",
    name: "man in motorized wheelchair: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 200D 1F9BC 200D 27A1 FE0F",
    char: "👨‍🦼‍➡️",
    name: "man in motorized wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 200D 1F9BC 200D 27A1",
    char: "👨‍🦼‍➡",
    name: "man in motorized wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FB 200D 1F9BC 200D 27A1 FE0F",
    char: "👨🏻‍🦼‍➡️",
    name: "man in motorized wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FB 200D 1F9BC 200D 27A1",
    char: "👨🏻‍🦼‍➡",
    name: "man in motorized wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FC 200D 1F9BC 200D 27A1 FE0F",
    char: "👨🏼‍🦼‍➡️",
    name: "man in motorized wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FC 200D 1F9BC 200D 27A1",
    char: "👨🏼‍🦼‍➡",
    name: "man in motorized wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FD 200D 1F9BC 200D 27A1 FE0F",
    char: "👨🏽‍🦼‍➡️",
    name: "man in motorized wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FD 200D 1F9BC 200D 27A1",
    char: "👨🏽‍🦼‍➡",
    name: "man in motorized wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FE 200D 1F9BC 200D 27A1 FE0F",
    char: "👨🏾‍🦼‍➡️",
    name: "man in motorized wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FE 200D 1F9BC 200D 27A1",
    char: "👨🏾‍🦼‍➡",
    name: "man in motorized wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FF 200D 1F9BC 200D 27A1 FE0F",
    char: "👨🏿‍🦼‍➡️",
    name: "man in motorized wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FF 200D 1F9BC 200D 27A1",
    char: "👨🏿‍🦼‍➡",
    name: "man in motorized wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 200D 1F9BC",
    char: "👩‍🦼",
    name: "woman in motorized wheelchair",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FB 200D 1F9BC",
    char: "👩🏻‍🦼",
    name: "woman in motorized wheelchair: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FC 200D 1F9BC",
    char: "👩🏼‍🦼",
    name: "woman in motorized wheelchair: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FD 200D 1F9BC",
    char: "👩🏽‍🦼",
    name: "woman in motorized wheelchair: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FE 200D 1F9BC",
    char: "👩🏾‍🦼",
    name: "woman in motorized wheelchair: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FF 200D 1F9BC",
    char: "👩🏿‍🦼",
    name: "woman in motorized wheelchair: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 200D 1F9BC 200D 27A1 FE0F",
    char: "👩‍🦼‍➡️",
    name: "woman in motorized wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 200D 1F9BC 200D 27A1",
    char: "👩‍🦼‍➡",
    name: "woman in motorized wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FB 200D 1F9BC 200D 27A1 FE0F",
    char: "👩🏻‍🦼‍➡️",
    name: "woman in motorized wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FB 200D 1F9BC 200D 27A1",
    char: "👩🏻‍🦼‍➡",
    name: "woman in motorized wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FC 200D 1F9BC 200D 27A1 FE0F",
    char: "👩🏼‍🦼‍➡️",
    name: "woman in motorized wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FC 200D 1F9BC 200D 27A1",
    char: "👩🏼‍🦼‍➡",
    name: "woman in motorized wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FD 200D 1F9BC 200D 27A1 FE0F",
    char: "👩🏽‍🦼‍➡️",
    name: "woman in motorized wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FD 200D 1F9BC 200D 27A1",
    char: "👩🏽‍🦼‍➡",
    name: "woman in motorized wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FE 200D 1F9BC 200D 27A1 FE0F",
    char: "👩🏾‍🦼‍➡️",
    name: "woman in motorized wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FE 200D 1F9BC 200D 27A1",
    char: "👩🏾‍🦼‍➡",
    name: "woman in motorized wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FF 200D 1F9BC 200D 27A1 FE0F",
    char: "👩🏿‍🦼‍➡️",
    name: "woman in motorized wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FF 200D 1F9BC 200D 27A1",
    char: "👩🏿‍🦼‍➡",
    name: "woman in motorized wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 200D 1F9BD",
    char: "🧑‍🦽",
    name: "person in manual wheelchair",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9BD",
    char: "🧑🏻‍🦽",
    name: "person in manual wheelchair: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9BD",
    char: "🧑🏼‍🦽",
    name: "person in manual wheelchair: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9BD",
    char: "🧑🏽‍🦽",
    name: "person in manual wheelchair: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9BD",
    char: "🧑🏾‍🦽",
    name: "person in manual wheelchair: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9BD",
    char: "🧑🏿‍🦽",
    name: "person in manual wheelchair: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 200D 1F9BD 200D 27A1 FE0F",
    char: "🧑‍🦽‍➡️",
    name: "person in manual wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 200D 1F9BD 200D 27A1",
    char: "🧑‍🦽‍➡",
    name: "person in manual wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9BD 200D 27A1 FE0F",
    char: "🧑🏻‍🦽‍➡️",
    name: "person in manual wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F9BD 200D 27A1",
    char: "🧑🏻‍🦽‍➡",
    name: "person in manual wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9BD 200D 27A1 FE0F",
    char: "🧑🏼‍🦽‍➡️",
    name: "person in manual wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F9BD 200D 27A1",
    char: "🧑🏼‍🦽‍➡",
    name: "person in manual wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9BD 200D 27A1 FE0F",
    char: "🧑🏽‍🦽‍➡️",
    name: "person in manual wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F9BD 200D 27A1",
    char: "🧑🏽‍🦽‍➡",
    name: "person in manual wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9BD 200D 27A1 FE0F",
    char: "🧑🏾‍🦽‍➡️",
    name: "person in manual wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F9BD 200D 27A1",
    char: "🧑🏾‍🦽‍➡",
    name: "person in manual wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9BD 200D 27A1 FE0F",
    char: "🧑🏿‍🦽‍➡️",
    name: "person in manual wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F9BD 200D 27A1",
    char: "🧑🏿‍🦽‍➡",
    name: "person in manual wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 200D 1F9BD",
    char: "👨‍🦽",
    name: "man in manual wheelchair",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FB 200D 1F9BD",
    char: "👨🏻‍🦽",
    name: "man in manual wheelchair: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FC 200D 1F9BD",
    char: "👨🏼‍🦽",
    name: "man in manual wheelchair: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FD 200D 1F9BD",
    char: "👨🏽‍🦽",
    name: "man in manual wheelchair: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FE 200D 1F9BD",
    char: "👨🏾‍🦽",
    name: "man in manual wheelchair: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FF 200D 1F9BD",
    char: "👨🏿‍🦽",
    name: "man in manual wheelchair: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 200D 1F9BD 200D 27A1 FE0F",
    char: "👨‍🦽‍➡️",
    name: "man in manual wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 200D 1F9BD 200D 27A1",
    char: "👨‍🦽‍➡",
    name: "man in manual wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FB 200D 1F9BD 200D 27A1 FE0F",
    char: "👨🏻‍🦽‍➡️",
    name: "man in manual wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FB 200D 1F9BD 200D 27A1",
    char: "👨🏻‍🦽‍➡",
    name: "man in manual wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FC 200D 1F9BD 200D 27A1 FE0F",
    char: "👨🏼‍🦽‍➡️",
    name: "man in manual wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FC 200D 1F9BD 200D 27A1",
    char: "👨🏼‍🦽‍➡",
    name: "man in manual wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FD 200D 1F9BD 200D 27A1 FE0F",
    char: "👨🏽‍🦽‍➡️",
    name: "man in manual wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FD 200D 1F9BD 200D 27A1",
    char: "👨🏽‍🦽‍➡",
    name: "man in manual wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FE 200D 1F9BD 200D 27A1 FE0F",
    char: "👨🏾‍🦽‍➡️",
    name: "man in manual wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FE 200D 1F9BD 200D 27A1",
    char: "👨🏾‍🦽‍➡",
    name: "man in manual wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FF 200D 1F9BD 200D 27A1 FE0F",
    char: "👨🏿‍🦽‍➡️",
    name: "man in manual wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F468 1F3FF 200D 1F9BD 200D 27A1",
    char: "👨🏿‍🦽‍➡",
    name: "man in manual wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 200D 1F9BD",
    char: "👩‍🦽",
    name: "woman in manual wheelchair",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FB 200D 1F9BD",
    char: "👩🏻‍🦽",
    name: "woman in manual wheelchair: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FC 200D 1F9BD",
    char: "👩🏼‍🦽",
    name: "woman in manual wheelchair: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FD 200D 1F9BD",
    char: "👩🏽‍🦽",
    name: "woman in manual wheelchair: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FE 200D 1F9BD",
    char: "👩🏾‍🦽",
    name: "woman in manual wheelchair: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FF 200D 1F9BD",
    char: "👩🏿‍🦽",
    name: "woman in manual wheelchair: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 200D 1F9BD 200D 27A1 FE0F",
    char: "👩‍🦽‍➡️",
    name: "woman in manual wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 200D 1F9BD 200D 27A1",
    char: "👩‍🦽‍➡",
    name: "woman in manual wheelchair facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FB 200D 1F9BD 200D 27A1 FE0F",
    char: "👩🏻‍🦽‍➡️",
    name: "woman in manual wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FB 200D 1F9BD 200D 27A1",
    char: "👩🏻‍🦽‍➡",
    name: "woman in manual wheelchair facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FC 200D 1F9BD 200D 27A1 FE0F",
    char: "👩🏼‍🦽‍➡️",
    name: "woman in manual wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FC 200D 1F9BD 200D 27A1",
    char: "👩🏼‍🦽‍➡",
    name: "woman in manual wheelchair facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FD 200D 1F9BD 200D 27A1 FE0F",
    char: "👩🏽‍🦽‍➡️",
    name: "woman in manual wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FD 200D 1F9BD 200D 27A1",
    char: "👩🏽‍🦽‍➡",
    name: "woman in manual wheelchair facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FE 200D 1F9BD 200D 27A1 FE0F",
    char: "👩🏾‍🦽‍➡️",
    name: "woman in manual wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FE 200D 1F9BD 200D 27A1",
    char: "👩🏾‍🦽‍➡",
    name: "woman in manual wheelchair facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FF 200D 1F9BD 200D 27A1 FE0F",
    char: "👩🏿‍🦽‍➡️",
    name: "woman in manual wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F469 1F3FF 200D 1F9BD 200D 27A1",
    char: "👩🏿‍🦽‍➡",
    name: "woman in manual wheelchair facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3",
    char: "🏃",
    name: "person running",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB",
    char: "🏃🏻",
    name: "person running: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC",
    char: "🏃🏼",
    name: "person running: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD",
    char: "🏃🏽",
    name: "person running: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE",
    char: "🏃🏾",
    name: "person running: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF",
    char: "🏃🏿",
    name: "person running: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2642 FE0F",
    char: "🏃‍♂️",
    name: "man running",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2642",
    char: "🏃‍♂",
    name: "man running",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2642 FE0F",
    char: "🏃🏻‍♂️",
    name: "man running: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2642",
    char: "🏃🏻‍♂",
    name: "man running: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2642 FE0F",
    char: "🏃🏼‍♂️",
    name: "man running: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2642",
    char: "🏃🏼‍♂",
    name: "man running: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2642 FE0F",
    char: "🏃🏽‍♂️",
    name: "man running: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2642",
    char: "🏃🏽‍♂",
    name: "man running: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2642 FE0F",
    char: "🏃🏾‍♂️",
    name: "man running: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2642",
    char: "🏃🏾‍♂",
    name: "man running: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2642 FE0F",
    char: "🏃🏿‍♂️",
    name: "man running: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2642",
    char: "🏃🏿‍♂",
    name: "man running: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2640 FE0F",
    char: "🏃‍♀️",
    name: "woman running",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2640",
    char: "🏃‍♀",
    name: "woman running",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2640 FE0F",
    char: "🏃🏻‍♀️",
    name: "woman running: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2640",
    char: "🏃🏻‍♀",
    name: "woman running: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2640 FE0F",
    char: "🏃🏼‍♀️",
    name: "woman running: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2640",
    char: "🏃🏼‍♀",
    name: "woman running: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2640 FE0F",
    char: "🏃🏽‍♀️",
    name: "woman running: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2640",
    char: "🏃🏽‍♀",
    name: "woman running: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2640 FE0F",
    char: "🏃🏾‍♀️",
    name: "woman running: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2640",
    char: "🏃🏾‍♀",
    name: "woman running: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2640 FE0F",
    char: "🏃🏿‍♀️",
    name: "woman running: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2640",
    char: "🏃🏿‍♀",
    name: "woman running: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 27A1 FE0F",
    char: "🏃‍➡️",
    name: "person running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 27A1",
    char: "🏃‍➡",
    name: "person running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 27A1 FE0F",
    char: "🏃🏻‍➡️",
    name: "person running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 27A1",
    char: "🏃🏻‍➡",
    name: "person running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 27A1 FE0F",
    char: "🏃🏼‍➡️",
    name: "person running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 27A1",
    char: "🏃🏼‍➡",
    name: "person running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 27A1 FE0F",
    char: "🏃🏽‍➡️",
    name: "person running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 27A1",
    char: "🏃🏽‍➡",
    name: "person running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 27A1 FE0F",
    char: "🏃🏾‍➡️",
    name: "person running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 27A1",
    char: "🏃🏾‍➡",
    name: "person running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 27A1 FE0F",
    char: "🏃🏿‍➡️",
    name: "person running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 27A1",
    char: "🏃🏿‍➡",
    name: "person running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🏃‍♀️‍➡️",
    name: "woman running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2640 200D 27A1 FE0F",
    char: "🏃‍♀‍➡️",
    name: "woman running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2640 FE0F 200D 27A1",
    char: "🏃‍♀️‍➡",
    name: "woman running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2640 200D 27A1",
    char: "🏃‍♀‍➡",
    name: "woman running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🏃🏻‍♀️‍➡️",
    name: "woman running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2640 200D 27A1 FE0F",
    char: "🏃🏻‍♀‍➡️",
    name: "woman running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2640 FE0F 200D 27A1",
    char: "🏃🏻‍♀️‍➡",
    name: "woman running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2640 200D 27A1",
    char: "🏃🏻‍♀‍➡",
    name: "woman running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🏃🏼‍♀️‍➡️",
    name: "woman running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2640 200D 27A1 FE0F",
    char: "🏃🏼‍♀‍➡️",
    name: "woman running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2640 FE0F 200D 27A1",
    char: "🏃🏼‍♀️‍➡",
    name: "woman running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2640 200D 27A1",
    char: "🏃🏼‍♀‍➡",
    name: "woman running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🏃🏽‍♀️‍➡️",
    name: "woman running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2640 200D 27A1 FE0F",
    char: "🏃🏽‍♀‍➡️",
    name: "woman running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2640 FE0F 200D 27A1",
    char: "🏃🏽‍♀️‍➡",
    name: "woman running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2640 200D 27A1",
    char: "🏃🏽‍♀‍➡",
    name: "woman running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🏃🏾‍♀️‍➡️",
    name: "woman running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2640 200D 27A1 FE0F",
    char: "🏃🏾‍♀‍➡️",
    name: "woman running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2640 FE0F 200D 27A1",
    char: "🏃🏾‍♀️‍➡",
    name: "woman running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2640 200D 27A1",
    char: "🏃🏾‍♀‍➡",
    name: "woman running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2640 FE0F 200D 27A1 FE0F",
    char: "🏃🏿‍♀️‍➡️",
    name: "woman running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2640 200D 27A1 FE0F",
    char: "🏃🏿‍♀‍➡️",
    name: "woman running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2640 FE0F 200D 27A1",
    char: "🏃🏿‍♀️‍➡",
    name: "woman running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2640 200D 27A1",
    char: "🏃🏿‍♀‍➡",
    name: "woman running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🏃‍♂️‍➡️",
    name: "man running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2642 200D 27A1 FE0F",
    char: "🏃‍♂‍➡️",
    name: "man running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2642 FE0F 200D 27A1",
    char: "🏃‍♂️‍➡",
    name: "man running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 200D 2642 200D 27A1",
    char: "🏃‍♂‍➡",
    name: "man running facing right",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🏃🏻‍♂️‍➡️",
    name: "man running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2642 200D 27A1 FE0F",
    char: "🏃🏻‍♂‍➡️",
    name: "man running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2642 FE0F 200D 27A1",
    char: "🏃🏻‍♂️‍➡",
    name: "man running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FB 200D 2642 200D 27A1",
    char: "🏃🏻‍♂‍➡",
    name: "man running facing right: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🏃🏼‍♂️‍➡️",
    name: "man running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2642 200D 27A1 FE0F",
    char: "🏃🏼‍♂‍➡️",
    name: "man running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2642 FE0F 200D 27A1",
    char: "🏃🏼‍♂️‍➡",
    name: "man running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FC 200D 2642 200D 27A1",
    char: "🏃🏼‍♂‍➡",
    name: "man running facing right: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🏃🏽‍♂️‍➡️",
    name: "man running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2642 200D 27A1 FE0F",
    char: "🏃🏽‍♂‍➡️",
    name: "man running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2642 FE0F 200D 27A1",
    char: "🏃🏽‍♂️‍➡",
    name: "man running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FD 200D 2642 200D 27A1",
    char: "🏃🏽‍♂‍➡",
    name: "man running facing right: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🏃🏾‍♂️‍➡️",
    name: "man running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2642 200D 27A1 FE0F",
    char: "🏃🏾‍♂‍➡️",
    name: "man running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2642 FE0F 200D 27A1",
    char: "🏃🏾‍♂️‍➡",
    name: "man running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FE 200D 2642 200D 27A1",
    char: "🏃🏾‍♂‍➡",
    name: "man running facing right: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2642 FE0F 200D 27A1 FE0F",
    char: "🏃🏿‍♂️‍➡️",
    name: "man running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2642 200D 27A1 FE0F",
    char: "🏃🏿‍♂‍➡️",
    name: "man running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2642 FE0F 200D 27A1",
    char: "🏃🏿‍♂️‍➡",
    name: "man running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F3C3 1F3FF 200D 2642 200D 27A1",
    char: "🏃🏿‍♂‍➡",
    name: "man running facing right: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F483",
    char: "💃",
    name: "woman dancing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F483 1F3FB",
    char: "💃🏻",
    name: "woman dancing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F483 1F3FC",
    char: "💃🏼",
    name: "woman dancing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F483 1F3FD",
    char: "💃🏽",
    name: "woman dancing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F483 1F3FE",
    char: "💃🏾",
    name: "woman dancing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F483 1F3FF",
    char: "💃🏿",
    name: "woman dancing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F57A",
    char: "🕺",
    name: "man dancing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F57A 1F3FB",
    char: "🕺🏻",
    name: "man dancing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F57A 1F3FC",
    char: "🕺🏼",
    name: "man dancing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F57A 1F3FD",
    char: "🕺🏽",
    name: "man dancing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F57A 1F3FE",
    char: "🕺🏾",
    name: "man dancing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F57A 1F3FF",
    char: "🕺🏿",
    name: "man dancing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F574 FE0F",
    char: "🕴️",
    name: "person in suit levitating",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F574",
    char: "🕴",
    name: "person in suit levitating",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F574 1F3FB",
    char: "🕴🏻",
    name: "person in suit levitating: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F574 1F3FC",
    char: "🕴🏼",
    name: "person in suit levitating: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F574 1F3FD",
    char: "🕴🏽",
    name: "person in suit levitating: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F574 1F3FE",
    char: "🕴🏾",
    name: "person in suit levitating: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F574 1F3FF",
    char: "🕴🏿",
    name: "person in suit levitating: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F46F",
    char: "👯",
    name: "people with bunny ears",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F46F 200D 2642 FE0F",
    char: "👯‍♂️",
    name: "men with bunny ears",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F46F 200D 2642",
    char: "👯‍♂",
    name: "men with bunny ears",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F46F 200D 2640 FE0F",
    char: "👯‍♀️",
    name: "women with bunny ears",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F46F 200D 2640",
    char: "👯‍♀",
    name: "women with bunny ears",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6",
    char: "🧖",
    name: "person in steamy room",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FB",
    char: "🧖🏻",
    name: "person in steamy room: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FC",
    char: "🧖🏼",
    name: "person in steamy room: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FD",
    char: "🧖🏽",
    name: "person in steamy room: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FE",
    char: "🧖🏾",
    name: "person in steamy room: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FF",
    char: "🧖🏿",
    name: "person in steamy room: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 200D 2642 FE0F",
    char: "🧖‍♂️",
    name: "man in steamy room",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 200D 2642",
    char: "🧖‍♂",
    name: "man in steamy room",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FB 200D 2642 FE0F",
    char: "🧖🏻‍♂️",
    name: "man in steamy room: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FB 200D 2642",
    char: "🧖🏻‍♂",
    name: "man in steamy room: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FC 200D 2642 FE0F",
    char: "🧖🏼‍♂️",
    name: "man in steamy room: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FC 200D 2642",
    char: "🧖🏼‍♂",
    name: "man in steamy room: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FD 200D 2642 FE0F",
    char: "🧖🏽‍♂️",
    name: "man in steamy room: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FD 200D 2642",
    char: "🧖🏽‍♂",
    name: "man in steamy room: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FE 200D 2642 FE0F",
    char: "🧖🏾‍♂️",
    name: "man in steamy room: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FE 200D 2642",
    char: "🧖🏾‍♂",
    name: "man in steamy room: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FF 200D 2642 FE0F",
    char: "🧖🏿‍♂️",
    name: "man in steamy room: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FF 200D 2642",
    char: "🧖🏿‍♂",
    name: "man in steamy room: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 200D 2640 FE0F",
    char: "🧖‍♀️",
    name: "woman in steamy room",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 200D 2640",
    char: "🧖‍♀",
    name: "woman in steamy room",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FB 200D 2640 FE0F",
    char: "🧖🏻‍♀️",
    name: "woman in steamy room: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FB 200D 2640",
    char: "🧖🏻‍♀",
    name: "woman in steamy room: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FC 200D 2640 FE0F",
    char: "🧖🏼‍♀️",
    name: "woman in steamy room: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FC 200D 2640",
    char: "🧖🏼‍♀",
    name: "woman in steamy room: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FD 200D 2640 FE0F",
    char: "🧖🏽‍♀️",
    name: "woman in steamy room: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FD 200D 2640",
    char: "🧖🏽‍♀",
    name: "woman in steamy room: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FE 200D 2640 FE0F",
    char: "🧖🏾‍♀️",
    name: "woman in steamy room: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FE 200D 2640",
    char: "🧖🏾‍♀",
    name: "woman in steamy room: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FF 200D 2640 FE0F",
    char: "🧖🏿‍♀️",
    name: "woman in steamy room: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D6 1F3FF 200D 2640",
    char: "🧖🏿‍♀",
    name: "woman in steamy room: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7",
    char: "🧗",
    name: "person climbing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FB",
    char: "🧗🏻",
    name: "person climbing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FC",
    char: "🧗🏼",
    name: "person climbing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FD",
    char: "🧗🏽",
    name: "person climbing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FE",
    char: "🧗🏾",
    name: "person climbing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FF",
    char: "🧗🏿",
    name: "person climbing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 200D 2642 FE0F",
    char: "🧗‍♂️",
    name: "man climbing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 200D 2642",
    char: "🧗‍♂",
    name: "man climbing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FB 200D 2642 FE0F",
    char: "🧗🏻‍♂️",
    name: "man climbing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FB 200D 2642",
    char: "🧗🏻‍♂",
    name: "man climbing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FC 200D 2642 FE0F",
    char: "🧗🏼‍♂️",
    name: "man climbing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FC 200D 2642",
    char: "🧗🏼‍♂",
    name: "man climbing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FD 200D 2642 FE0F",
    char: "🧗🏽‍♂️",
    name: "man climbing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FD 200D 2642",
    char: "🧗🏽‍♂",
    name: "man climbing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FE 200D 2642 FE0F",
    char: "🧗🏾‍♂️",
    name: "man climbing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FE 200D 2642",
    char: "🧗🏾‍♂",
    name: "man climbing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FF 200D 2642 FE0F",
    char: "🧗🏿‍♂️",
    name: "man climbing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FF 200D 2642",
    char: "🧗🏿‍♂",
    name: "man climbing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 200D 2640 FE0F",
    char: "🧗‍♀️",
    name: "woman climbing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 200D 2640",
    char: "🧗‍♀",
    name: "woman climbing",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FB 200D 2640 FE0F",
    char: "🧗🏻‍♀️",
    name: "woman climbing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FB 200D 2640",
    char: "🧗🏻‍♀",
    name: "woman climbing: light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FC 200D 2640 FE0F",
    char: "🧗🏼‍♀️",
    name: "woman climbing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FC 200D 2640",
    char: "🧗🏼‍♀",
    name: "woman climbing: medium-light skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FD 200D 2640 FE0F",
    char: "🧗🏽‍♀️",
    name: "woman climbing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FD 200D 2640",
    char: "🧗🏽‍♀",
    name: "woman climbing: medium skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FE 200D 2640 FE0F",
    char: "🧗🏾‍♀️",
    name: "woman climbing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FE 200D 2640",
    char: "🧗🏾‍♀",
    name: "woman climbing: medium-dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FF 200D 2640 FE0F",
    char: "🧗🏿‍♀️",
    name: "woman climbing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F9D7 1F3FF 200D 2640",
    char: "🧗🏿‍♀",
    name: "woman climbing: dark skin tone",
    category: "People & Body (person-activity)",
    group: "People & Body",
    subgroup: "person-activity",
  },
  {
    codes: "1F93A",
    char: "🤺",
    name: "person fencing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C7",
    char: "🏇",
    name: "horse racing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C7 1F3FB",
    char: "🏇🏻",
    name: "horse racing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C7 1F3FC",
    char: "🏇🏼",
    name: "horse racing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C7 1F3FD",
    char: "🏇🏽",
    name: "horse racing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C7 1F3FE",
    char: "🏇🏾",
    name: "horse racing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C7 1F3FF",
    char: "🏇🏿",
    name: "horse racing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F7 FE0F",
    char: "⛷️",
    name: "skier",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F7",
    char: "⛷",
    name: "skier",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C2",
    char: "🏂",
    name: "snowboarder",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C2 1F3FB",
    char: "🏂🏻",
    name: "snowboarder: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C2 1F3FC",
    char: "🏂🏼",
    name: "snowboarder: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C2 1F3FD",
    char: "🏂🏽",
    name: "snowboarder: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C2 1F3FE",
    char: "🏂🏾",
    name: "snowboarder: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C2 1F3FF",
    char: "🏂🏿",
    name: "snowboarder: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC FE0F",
    char: "🏌️",
    name: "person golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC",
    char: "🏌",
    name: "person golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FB",
    char: "🏌🏻",
    name: "person golfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FC",
    char: "🏌🏼",
    name: "person golfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FD",
    char: "🏌🏽",
    name: "person golfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FE",
    char: "🏌🏾",
    name: "person golfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FF",
    char: "🏌🏿",
    name: "person golfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC FE0F 200D 2642 FE0F",
    char: "🏌️‍♂️",
    name: "man golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 200D 2642 FE0F",
    char: "🏌‍♂️",
    name: "man golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC FE0F 200D 2642",
    char: "🏌️‍♂",
    name: "man golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 200D 2642",
    char: "🏌‍♂",
    name: "man golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FB 200D 2642 FE0F",
    char: "🏌🏻‍♂️",
    name: "man golfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FB 200D 2642",
    char: "🏌🏻‍♂",
    name: "man golfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FC 200D 2642 FE0F",
    char: "🏌🏼‍♂️",
    name: "man golfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FC 200D 2642",
    char: "🏌🏼‍♂",
    name: "man golfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FD 200D 2642 FE0F",
    char: "🏌🏽‍♂️",
    name: "man golfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FD 200D 2642",
    char: "🏌🏽‍♂",
    name: "man golfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FE 200D 2642 FE0F",
    char: "🏌🏾‍♂️",
    name: "man golfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FE 200D 2642",
    char: "🏌🏾‍♂",
    name: "man golfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FF 200D 2642 FE0F",
    char: "🏌🏿‍♂️",
    name: "man golfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FF 200D 2642",
    char: "🏌🏿‍♂",
    name: "man golfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC FE0F 200D 2640 FE0F",
    char: "🏌️‍♀️",
    name: "woman golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 200D 2640 FE0F",
    char: "🏌‍♀️",
    name: "woman golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC FE0F 200D 2640",
    char: "🏌️‍♀",
    name: "woman golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 200D 2640",
    char: "🏌‍♀",
    name: "woman golfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FB 200D 2640 FE0F",
    char: "🏌🏻‍♀️",
    name: "woman golfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FB 200D 2640",
    char: "🏌🏻‍♀",
    name: "woman golfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FC 200D 2640 FE0F",
    char: "🏌🏼‍♀️",
    name: "woman golfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FC 200D 2640",
    char: "🏌🏼‍♀",
    name: "woman golfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FD 200D 2640 FE0F",
    char: "🏌🏽‍♀️",
    name: "woman golfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FD 200D 2640",
    char: "🏌🏽‍♀",
    name: "woman golfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FE 200D 2640 FE0F",
    char: "🏌🏾‍♀️",
    name: "woman golfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FE 200D 2640",
    char: "🏌🏾‍♀",
    name: "woman golfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FF 200D 2640 FE0F",
    char: "🏌🏿‍♀️",
    name: "woman golfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CC 1F3FF 200D 2640",
    char: "🏌🏿‍♀",
    name: "woman golfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4",
    char: "🏄",
    name: "person surfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FB",
    char: "🏄🏻",
    name: "person surfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FC",
    char: "🏄🏼",
    name: "person surfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FD",
    char: "🏄🏽",
    name: "person surfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FE",
    char: "🏄🏾",
    name: "person surfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FF",
    char: "🏄🏿",
    name: "person surfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 200D 2642 FE0F",
    char: "🏄‍♂️",
    name: "man surfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 200D 2642",
    char: "🏄‍♂",
    name: "man surfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FB 200D 2642 FE0F",
    char: "🏄🏻‍♂️",
    name: "man surfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FB 200D 2642",
    char: "🏄🏻‍♂",
    name: "man surfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FC 200D 2642 FE0F",
    char: "🏄🏼‍♂️",
    name: "man surfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FC 200D 2642",
    char: "🏄🏼‍♂",
    name: "man surfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FD 200D 2642 FE0F",
    char: "🏄🏽‍♂️",
    name: "man surfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FD 200D 2642",
    char: "🏄🏽‍♂",
    name: "man surfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FE 200D 2642 FE0F",
    char: "🏄🏾‍♂️",
    name: "man surfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FE 200D 2642",
    char: "🏄🏾‍♂",
    name: "man surfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FF 200D 2642 FE0F",
    char: "🏄🏿‍♂️",
    name: "man surfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FF 200D 2642",
    char: "🏄🏿‍♂",
    name: "man surfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 200D 2640 FE0F",
    char: "🏄‍♀️",
    name: "woman surfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 200D 2640",
    char: "🏄‍♀",
    name: "woman surfing",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FB 200D 2640 FE0F",
    char: "🏄🏻‍♀️",
    name: "woman surfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FB 200D 2640",
    char: "🏄🏻‍♀",
    name: "woman surfing: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FC 200D 2640 FE0F",
    char: "🏄🏼‍♀️",
    name: "woman surfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FC 200D 2640",
    char: "🏄🏼‍♀",
    name: "woman surfing: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FD 200D 2640 FE0F",
    char: "🏄🏽‍♀️",
    name: "woman surfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FD 200D 2640",
    char: "🏄🏽‍♀",
    name: "woman surfing: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FE 200D 2640 FE0F",
    char: "🏄🏾‍♀️",
    name: "woman surfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FE 200D 2640",
    char: "🏄🏾‍♀",
    name: "woman surfing: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FF 200D 2640 FE0F",
    char: "🏄🏿‍♀️",
    name: "woman surfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3C4 1F3FF 200D 2640",
    char: "🏄🏿‍♀",
    name: "woman surfing: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3",
    char: "🚣",
    name: "person rowing boat",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FB",
    char: "🚣🏻",
    name: "person rowing boat: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FC",
    char: "🚣🏼",
    name: "person rowing boat: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FD",
    char: "🚣🏽",
    name: "person rowing boat: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FE",
    char: "🚣🏾",
    name: "person rowing boat: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FF",
    char: "🚣🏿",
    name: "person rowing boat: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 200D 2642 FE0F",
    char: "🚣‍♂️",
    name: "man rowing boat",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 200D 2642",
    char: "🚣‍♂",
    name: "man rowing boat",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FB 200D 2642 FE0F",
    char: "🚣🏻‍♂️",
    name: "man rowing boat: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FB 200D 2642",
    char: "🚣🏻‍♂",
    name: "man rowing boat: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FC 200D 2642 FE0F",
    char: "🚣🏼‍♂️",
    name: "man rowing boat: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FC 200D 2642",
    char: "🚣🏼‍♂",
    name: "man rowing boat: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FD 200D 2642 FE0F",
    char: "🚣🏽‍♂️",
    name: "man rowing boat: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FD 200D 2642",
    char: "🚣🏽‍♂",
    name: "man rowing boat: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FE 200D 2642 FE0F",
    char: "🚣🏾‍♂️",
    name: "man rowing boat: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FE 200D 2642",
    char: "🚣🏾‍♂",
    name: "man rowing boat: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FF 200D 2642 FE0F",
    char: "🚣🏿‍♂️",
    name: "man rowing boat: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FF 200D 2642",
    char: "🚣🏿‍♂",
    name: "man rowing boat: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 200D 2640 FE0F",
    char: "🚣‍♀️",
    name: "woman rowing boat",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 200D 2640",
    char: "🚣‍♀",
    name: "woman rowing boat",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FB 200D 2640 FE0F",
    char: "🚣🏻‍♀️",
    name: "woman rowing boat: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FB 200D 2640",
    char: "🚣🏻‍♀",
    name: "woman rowing boat: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FC 200D 2640 FE0F",
    char: "🚣🏼‍♀️",
    name: "woman rowing boat: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FC 200D 2640",
    char: "🚣🏼‍♀",
    name: "woman rowing boat: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FD 200D 2640 FE0F",
    char: "🚣🏽‍♀️",
    name: "woman rowing boat: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FD 200D 2640",
    char: "🚣🏽‍♀",
    name: "woman rowing boat: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FE 200D 2640 FE0F",
    char: "🚣🏾‍♀️",
    name: "woman rowing boat: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FE 200D 2640",
    char: "🚣🏾‍♀",
    name: "woman rowing boat: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FF 200D 2640 FE0F",
    char: "🚣🏿‍♀️",
    name: "woman rowing boat: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6A3 1F3FF 200D 2640",
    char: "🚣🏿‍♀",
    name: "woman rowing boat: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA",
    char: "🏊",
    name: "person swimming",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FB",
    char: "🏊🏻",
    name: "person swimming: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FC",
    char: "🏊🏼",
    name: "person swimming: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FD",
    char: "🏊🏽",
    name: "person swimming: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FE",
    char: "🏊🏾",
    name: "person swimming: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FF",
    char: "🏊🏿",
    name: "person swimming: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 200D 2642 FE0F",
    char: "🏊‍♂️",
    name: "man swimming",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 200D 2642",
    char: "🏊‍♂",
    name: "man swimming",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FB 200D 2642 FE0F",
    char: "🏊🏻‍♂️",
    name: "man swimming: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FB 200D 2642",
    char: "🏊🏻‍♂",
    name: "man swimming: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FC 200D 2642 FE0F",
    char: "🏊🏼‍♂️",
    name: "man swimming: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FC 200D 2642",
    char: "🏊🏼‍♂",
    name: "man swimming: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FD 200D 2642 FE0F",
    char: "🏊🏽‍♂️",
    name: "man swimming: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FD 200D 2642",
    char: "🏊🏽‍♂",
    name: "man swimming: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FE 200D 2642 FE0F",
    char: "🏊🏾‍♂️",
    name: "man swimming: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FE 200D 2642",
    char: "🏊🏾‍♂",
    name: "man swimming: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FF 200D 2642 FE0F",
    char: "🏊🏿‍♂️",
    name: "man swimming: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FF 200D 2642",
    char: "🏊🏿‍♂",
    name: "man swimming: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 200D 2640 FE0F",
    char: "🏊‍♀️",
    name: "woman swimming",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 200D 2640",
    char: "🏊‍♀",
    name: "woman swimming",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FB 200D 2640 FE0F",
    char: "🏊🏻‍♀️",
    name: "woman swimming: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FB 200D 2640",
    char: "🏊🏻‍♀",
    name: "woman swimming: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FC 200D 2640 FE0F",
    char: "🏊🏼‍♀️",
    name: "woman swimming: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FC 200D 2640",
    char: "🏊🏼‍♀",
    name: "woman swimming: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FD 200D 2640 FE0F",
    char: "🏊🏽‍♀️",
    name: "woman swimming: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FD 200D 2640",
    char: "🏊🏽‍♀",
    name: "woman swimming: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FE 200D 2640 FE0F",
    char: "🏊🏾‍♀️",
    name: "woman swimming: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FE 200D 2640",
    char: "🏊🏾‍♀",
    name: "woman swimming: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FF 200D 2640 FE0F",
    char: "🏊🏿‍♀️",
    name: "woman swimming: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CA 1F3FF 200D 2640",
    char: "🏊🏿‍♀",
    name: "woman swimming: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 FE0F",
    char: "⛹️",
    name: "person bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9",
    char: "⛹",
    name: "person bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FB",
    char: "⛹🏻",
    name: "person bouncing ball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FC",
    char: "⛹🏼",
    name: "person bouncing ball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FD",
    char: "⛹🏽",
    name: "person bouncing ball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FE",
    char: "⛹🏾",
    name: "person bouncing ball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FF",
    char: "⛹🏿",
    name: "person bouncing ball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 FE0F 200D 2642 FE0F",
    char: "⛹️‍♂️",
    name: "man bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 200D 2642 FE0F",
    char: "⛹‍♂️",
    name: "man bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 FE0F 200D 2642",
    char: "⛹️‍♂",
    name: "man bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 200D 2642",
    char: "⛹‍♂",
    name: "man bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FB 200D 2642 FE0F",
    char: "⛹🏻‍♂️",
    name: "man bouncing ball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FB 200D 2642",
    char: "⛹🏻‍♂",
    name: "man bouncing ball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FC 200D 2642 FE0F",
    char: "⛹🏼‍♂️",
    name: "man bouncing ball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FC 200D 2642",
    char: "⛹🏼‍♂",
    name: "man bouncing ball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FD 200D 2642 FE0F",
    char: "⛹🏽‍♂️",
    name: "man bouncing ball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FD 200D 2642",
    char: "⛹🏽‍♂",
    name: "man bouncing ball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FE 200D 2642 FE0F",
    char: "⛹🏾‍♂️",
    name: "man bouncing ball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FE 200D 2642",
    char: "⛹🏾‍♂",
    name: "man bouncing ball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FF 200D 2642 FE0F",
    char: "⛹🏿‍♂️",
    name: "man bouncing ball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FF 200D 2642",
    char: "⛹🏿‍♂",
    name: "man bouncing ball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 FE0F 200D 2640 FE0F",
    char: "⛹️‍♀️",
    name: "woman bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 200D 2640 FE0F",
    char: "⛹‍♀️",
    name: "woman bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 FE0F 200D 2640",
    char: "⛹️‍♀",
    name: "woman bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 200D 2640",
    char: "⛹‍♀",
    name: "woman bouncing ball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FB 200D 2640 FE0F",
    char: "⛹🏻‍♀️",
    name: "woman bouncing ball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FB 200D 2640",
    char: "⛹🏻‍♀",
    name: "woman bouncing ball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FC 200D 2640 FE0F",
    char: "⛹🏼‍♀️",
    name: "woman bouncing ball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FC 200D 2640",
    char: "⛹🏼‍♀",
    name: "woman bouncing ball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FD 200D 2640 FE0F",
    char: "⛹🏽‍♀️",
    name: "woman bouncing ball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FD 200D 2640",
    char: "⛹🏽‍♀",
    name: "woman bouncing ball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FE 200D 2640 FE0F",
    char: "⛹🏾‍♀️",
    name: "woman bouncing ball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FE 200D 2640",
    char: "⛹🏾‍♀",
    name: "woman bouncing ball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FF 200D 2640 FE0F",
    char: "⛹🏿‍♀️",
    name: "woman bouncing ball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "26F9 1F3FF 200D 2640",
    char: "⛹🏿‍♀",
    name: "woman bouncing ball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB FE0F",
    char: "🏋️",
    name: "person lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB",
    char: "🏋",
    name: "person lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FB",
    char: "🏋🏻",
    name: "person lifting weights: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FC",
    char: "🏋🏼",
    name: "person lifting weights: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FD",
    char: "🏋🏽",
    name: "person lifting weights: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FE",
    char: "🏋🏾",
    name: "person lifting weights: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FF",
    char: "🏋🏿",
    name: "person lifting weights: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB FE0F 200D 2642 FE0F",
    char: "🏋️‍♂️",
    name: "man lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 200D 2642 FE0F",
    char: "🏋‍♂️",
    name: "man lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB FE0F 200D 2642",
    char: "🏋️‍♂",
    name: "man lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 200D 2642",
    char: "🏋‍♂",
    name: "man lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FB 200D 2642 FE0F",
    char: "🏋🏻‍♂️",
    name: "man lifting weights: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FB 200D 2642",
    char: "🏋🏻‍♂",
    name: "man lifting weights: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FC 200D 2642 FE0F",
    char: "🏋🏼‍♂️",
    name: "man lifting weights: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FC 200D 2642",
    char: "🏋🏼‍♂",
    name: "man lifting weights: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FD 200D 2642 FE0F",
    char: "🏋🏽‍♂️",
    name: "man lifting weights: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FD 200D 2642",
    char: "🏋🏽‍♂",
    name: "man lifting weights: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FE 200D 2642 FE0F",
    char: "🏋🏾‍♂️",
    name: "man lifting weights: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FE 200D 2642",
    char: "🏋🏾‍♂",
    name: "man lifting weights: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FF 200D 2642 FE0F",
    char: "🏋🏿‍♂️",
    name: "man lifting weights: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FF 200D 2642",
    char: "🏋🏿‍♂",
    name: "man lifting weights: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB FE0F 200D 2640 FE0F",
    char: "🏋️‍♀️",
    name: "woman lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 200D 2640 FE0F",
    char: "🏋‍♀️",
    name: "woman lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB FE0F 200D 2640",
    char: "🏋️‍♀",
    name: "woman lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 200D 2640",
    char: "🏋‍♀",
    name: "woman lifting weights",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FB 200D 2640 FE0F",
    char: "🏋🏻‍♀️",
    name: "woman lifting weights: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FB 200D 2640",
    char: "🏋🏻‍♀",
    name: "woman lifting weights: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FC 200D 2640 FE0F",
    char: "🏋🏼‍♀️",
    name: "woman lifting weights: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FC 200D 2640",
    char: "🏋🏼‍♀",
    name: "woman lifting weights: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FD 200D 2640 FE0F",
    char: "🏋🏽‍♀️",
    name: "woman lifting weights: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FD 200D 2640",
    char: "🏋🏽‍♀",
    name: "woman lifting weights: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FE 200D 2640 FE0F",
    char: "🏋🏾‍♀️",
    name: "woman lifting weights: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FE 200D 2640",
    char: "🏋🏾‍♀",
    name: "woman lifting weights: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FF 200D 2640 FE0F",
    char: "🏋🏿‍♀️",
    name: "woman lifting weights: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F3CB 1F3FF 200D 2640",
    char: "🏋🏿‍♀",
    name: "woman lifting weights: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4",
    char: "🚴",
    name: "person biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FB",
    char: "🚴🏻",
    name: "person biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FC",
    char: "🚴🏼",
    name: "person biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FD",
    char: "🚴🏽",
    name: "person biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FE",
    char: "🚴🏾",
    name: "person biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FF",
    char: "🚴🏿",
    name: "person biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 200D 2642 FE0F",
    char: "🚴‍♂️",
    name: "man biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 200D 2642",
    char: "🚴‍♂",
    name: "man biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FB 200D 2642 FE0F",
    char: "🚴🏻‍♂️",
    name: "man biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FB 200D 2642",
    char: "🚴🏻‍♂",
    name: "man biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FC 200D 2642 FE0F",
    char: "🚴🏼‍♂️",
    name: "man biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FC 200D 2642",
    char: "🚴🏼‍♂",
    name: "man biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FD 200D 2642 FE0F",
    char: "🚴🏽‍♂️",
    name: "man biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FD 200D 2642",
    char: "🚴🏽‍♂",
    name: "man biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FE 200D 2642 FE0F",
    char: "🚴🏾‍♂️",
    name: "man biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FE 200D 2642",
    char: "🚴🏾‍♂",
    name: "man biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FF 200D 2642 FE0F",
    char: "🚴🏿‍♂️",
    name: "man biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FF 200D 2642",
    char: "🚴🏿‍♂",
    name: "man biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 200D 2640 FE0F",
    char: "🚴‍♀️",
    name: "woman biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 200D 2640",
    char: "🚴‍♀",
    name: "woman biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FB 200D 2640 FE0F",
    char: "🚴🏻‍♀️",
    name: "woman biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FB 200D 2640",
    char: "🚴🏻‍♀",
    name: "woman biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FC 200D 2640 FE0F",
    char: "🚴🏼‍♀️",
    name: "woman biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FC 200D 2640",
    char: "🚴🏼‍♀",
    name: "woman biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FD 200D 2640 FE0F",
    char: "🚴🏽‍♀️",
    name: "woman biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FD 200D 2640",
    char: "🚴🏽‍♀",
    name: "woman biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FE 200D 2640 FE0F",
    char: "🚴🏾‍♀️",
    name: "woman biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FE 200D 2640",
    char: "🚴🏾‍♀",
    name: "woman biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FF 200D 2640 FE0F",
    char: "🚴🏿‍♀️",
    name: "woman biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B4 1F3FF 200D 2640",
    char: "🚴🏿‍♀",
    name: "woman biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5",
    char: "🚵",
    name: "person mountain biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FB",
    char: "🚵🏻",
    name: "person mountain biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FC",
    char: "🚵🏼",
    name: "person mountain biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FD",
    char: "🚵🏽",
    name: "person mountain biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FE",
    char: "🚵🏾",
    name: "person mountain biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FF",
    char: "🚵🏿",
    name: "person mountain biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 200D 2642 FE0F",
    char: "🚵‍♂️",
    name: "man mountain biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 200D 2642",
    char: "🚵‍♂",
    name: "man mountain biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FB 200D 2642 FE0F",
    char: "🚵🏻‍♂️",
    name: "man mountain biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FB 200D 2642",
    char: "🚵🏻‍♂",
    name: "man mountain biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FC 200D 2642 FE0F",
    char: "🚵🏼‍♂️",
    name: "man mountain biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FC 200D 2642",
    char: "🚵🏼‍♂",
    name: "man mountain biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FD 200D 2642 FE0F",
    char: "🚵🏽‍♂️",
    name: "man mountain biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FD 200D 2642",
    char: "🚵🏽‍♂",
    name: "man mountain biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FE 200D 2642 FE0F",
    char: "🚵🏾‍♂️",
    name: "man mountain biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FE 200D 2642",
    char: "🚵🏾‍♂",
    name: "man mountain biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FF 200D 2642 FE0F",
    char: "🚵🏿‍♂️",
    name: "man mountain biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FF 200D 2642",
    char: "🚵🏿‍♂",
    name: "man mountain biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 200D 2640 FE0F",
    char: "🚵‍♀️",
    name: "woman mountain biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 200D 2640",
    char: "🚵‍♀",
    name: "woman mountain biking",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FB 200D 2640 FE0F",
    char: "🚵🏻‍♀️",
    name: "woman mountain biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FB 200D 2640",
    char: "🚵🏻‍♀",
    name: "woman mountain biking: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FC 200D 2640 FE0F",
    char: "🚵🏼‍♀️",
    name: "woman mountain biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FC 200D 2640",
    char: "🚵🏼‍♀",
    name: "woman mountain biking: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FD 200D 2640 FE0F",
    char: "🚵🏽‍♀️",
    name: "woman mountain biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FD 200D 2640",
    char: "🚵🏽‍♀",
    name: "woman mountain biking: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FE 200D 2640 FE0F",
    char: "🚵🏾‍♀️",
    name: "woman mountain biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FE 200D 2640",
    char: "🚵🏾‍♀",
    name: "woman mountain biking: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FF 200D 2640 FE0F",
    char: "🚵🏿‍♀️",
    name: "woman mountain biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F6B5 1F3FF 200D 2640",
    char: "🚵🏿‍♀",
    name: "woman mountain biking: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938",
    char: "🤸",
    name: "person cartwheeling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FB",
    char: "🤸🏻",
    name: "person cartwheeling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FC",
    char: "🤸🏼",
    name: "person cartwheeling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FD",
    char: "🤸🏽",
    name: "person cartwheeling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FE",
    char: "🤸🏾",
    name: "person cartwheeling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FF",
    char: "🤸🏿",
    name: "person cartwheeling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 200D 2642 FE0F",
    char: "🤸‍♂️",
    name: "man cartwheeling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 200D 2642",
    char: "🤸‍♂",
    name: "man cartwheeling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FB 200D 2642 FE0F",
    char: "🤸🏻‍♂️",
    name: "man cartwheeling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FB 200D 2642",
    char: "🤸🏻‍♂",
    name: "man cartwheeling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FC 200D 2642 FE0F",
    char: "🤸🏼‍♂️",
    name: "man cartwheeling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FC 200D 2642",
    char: "🤸🏼‍♂",
    name: "man cartwheeling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FD 200D 2642 FE0F",
    char: "🤸🏽‍♂️",
    name: "man cartwheeling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FD 200D 2642",
    char: "🤸🏽‍♂",
    name: "man cartwheeling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FE 200D 2642 FE0F",
    char: "🤸🏾‍♂️",
    name: "man cartwheeling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FE 200D 2642",
    char: "🤸🏾‍♂",
    name: "man cartwheeling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FF 200D 2642 FE0F",
    char: "🤸🏿‍♂️",
    name: "man cartwheeling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FF 200D 2642",
    char: "🤸🏿‍♂",
    name: "man cartwheeling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 200D 2640 FE0F",
    char: "🤸‍♀️",
    name: "woman cartwheeling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 200D 2640",
    char: "🤸‍♀",
    name: "woman cartwheeling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FB 200D 2640 FE0F",
    char: "🤸🏻‍♀️",
    name: "woman cartwheeling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FB 200D 2640",
    char: "🤸🏻‍♀",
    name: "woman cartwheeling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FC 200D 2640 FE0F",
    char: "🤸🏼‍♀️",
    name: "woman cartwheeling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FC 200D 2640",
    char: "🤸🏼‍♀",
    name: "woman cartwheeling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FD 200D 2640 FE0F",
    char: "🤸🏽‍♀️",
    name: "woman cartwheeling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FD 200D 2640",
    char: "🤸🏽‍♀",
    name: "woman cartwheeling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FE 200D 2640 FE0F",
    char: "🤸🏾‍♀️",
    name: "woman cartwheeling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FE 200D 2640",
    char: "🤸🏾‍♀",
    name: "woman cartwheeling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FF 200D 2640 FE0F",
    char: "🤸🏿‍♀️",
    name: "woman cartwheeling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F938 1F3FF 200D 2640",
    char: "🤸🏿‍♀",
    name: "woman cartwheeling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93C",
    char: "🤼",
    name: "people wrestling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93C 200D 2642 FE0F",
    char: "🤼‍♂️",
    name: "men wrestling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93C 200D 2642",
    char: "🤼‍♂",
    name: "men wrestling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93C 200D 2640 FE0F",
    char: "🤼‍♀️",
    name: "women wrestling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93C 200D 2640",
    char: "🤼‍♀",
    name: "women wrestling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D",
    char: "🤽",
    name: "person playing water polo",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FB",
    char: "🤽🏻",
    name: "person playing water polo: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FC",
    char: "🤽🏼",
    name: "person playing water polo: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FD",
    char: "🤽🏽",
    name: "person playing water polo: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FE",
    char: "🤽🏾",
    name: "person playing water polo: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FF",
    char: "🤽🏿",
    name: "person playing water polo: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 200D 2642 FE0F",
    char: "🤽‍♂️",
    name: "man playing water polo",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 200D 2642",
    char: "🤽‍♂",
    name: "man playing water polo",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FB 200D 2642 FE0F",
    char: "🤽🏻‍♂️",
    name: "man playing water polo: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FB 200D 2642",
    char: "🤽🏻‍♂",
    name: "man playing water polo: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FC 200D 2642 FE0F",
    char: "🤽🏼‍♂️",
    name: "man playing water polo: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FC 200D 2642",
    char: "🤽🏼‍♂",
    name: "man playing water polo: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FD 200D 2642 FE0F",
    char: "🤽🏽‍♂️",
    name: "man playing water polo: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FD 200D 2642",
    char: "🤽🏽‍♂",
    name: "man playing water polo: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FE 200D 2642 FE0F",
    char: "🤽🏾‍♂️",
    name: "man playing water polo: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FE 200D 2642",
    char: "🤽🏾‍♂",
    name: "man playing water polo: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FF 200D 2642 FE0F",
    char: "🤽🏿‍♂️",
    name: "man playing water polo: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FF 200D 2642",
    char: "🤽🏿‍♂",
    name: "man playing water polo: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 200D 2640 FE0F",
    char: "🤽‍♀️",
    name: "woman playing water polo",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 200D 2640",
    char: "🤽‍♀",
    name: "woman playing water polo",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FB 200D 2640 FE0F",
    char: "🤽🏻‍♀️",
    name: "woman playing water polo: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FB 200D 2640",
    char: "🤽🏻‍♀",
    name: "woman playing water polo: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FC 200D 2640 FE0F",
    char: "🤽🏼‍♀️",
    name: "woman playing water polo: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FC 200D 2640",
    char: "🤽🏼‍♀",
    name: "woman playing water polo: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FD 200D 2640 FE0F",
    char: "🤽🏽‍♀️",
    name: "woman playing water polo: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FD 200D 2640",
    char: "🤽🏽‍♀",
    name: "woman playing water polo: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FE 200D 2640 FE0F",
    char: "🤽🏾‍♀️",
    name: "woman playing water polo: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FE 200D 2640",
    char: "🤽🏾‍♀",
    name: "woman playing water polo: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FF 200D 2640 FE0F",
    char: "🤽🏿‍♀️",
    name: "woman playing water polo: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93D 1F3FF 200D 2640",
    char: "🤽🏿‍♀",
    name: "woman playing water polo: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E",
    char: "🤾",
    name: "person playing handball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FB",
    char: "🤾🏻",
    name: "person playing handball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FC",
    char: "🤾🏼",
    name: "person playing handball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FD",
    char: "🤾🏽",
    name: "person playing handball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FE",
    char: "🤾🏾",
    name: "person playing handball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FF",
    char: "🤾🏿",
    name: "person playing handball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 200D 2642 FE0F",
    char: "🤾‍♂️",
    name: "man playing handball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 200D 2642",
    char: "🤾‍♂",
    name: "man playing handball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FB 200D 2642 FE0F",
    char: "🤾🏻‍♂️",
    name: "man playing handball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FB 200D 2642",
    char: "🤾🏻‍♂",
    name: "man playing handball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FC 200D 2642 FE0F",
    char: "🤾🏼‍♂️",
    name: "man playing handball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FC 200D 2642",
    char: "🤾🏼‍♂",
    name: "man playing handball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FD 200D 2642 FE0F",
    char: "🤾🏽‍♂️",
    name: "man playing handball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FD 200D 2642",
    char: "🤾🏽‍♂",
    name: "man playing handball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FE 200D 2642 FE0F",
    char: "🤾🏾‍♂️",
    name: "man playing handball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FE 200D 2642",
    char: "🤾🏾‍♂",
    name: "man playing handball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FF 200D 2642 FE0F",
    char: "🤾🏿‍♂️",
    name: "man playing handball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FF 200D 2642",
    char: "🤾🏿‍♂",
    name: "man playing handball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 200D 2640 FE0F",
    char: "🤾‍♀️",
    name: "woman playing handball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 200D 2640",
    char: "🤾‍♀",
    name: "woman playing handball",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FB 200D 2640 FE0F",
    char: "🤾🏻‍♀️",
    name: "woman playing handball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FB 200D 2640",
    char: "🤾🏻‍♀",
    name: "woman playing handball: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FC 200D 2640 FE0F",
    char: "🤾🏼‍♀️",
    name: "woman playing handball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FC 200D 2640",
    char: "🤾🏼‍♀",
    name: "woman playing handball: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FD 200D 2640 FE0F",
    char: "🤾🏽‍♀️",
    name: "woman playing handball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FD 200D 2640",
    char: "🤾🏽‍♀",
    name: "woman playing handball: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FE 200D 2640 FE0F",
    char: "🤾🏾‍♀️",
    name: "woman playing handball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FE 200D 2640",
    char: "🤾🏾‍♀",
    name: "woman playing handball: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FF 200D 2640 FE0F",
    char: "🤾🏿‍♀️",
    name: "woman playing handball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F93E 1F3FF 200D 2640",
    char: "🤾🏿‍♀",
    name: "woman playing handball: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939",
    char: "🤹",
    name: "person juggling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FB",
    char: "🤹🏻",
    name: "person juggling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FC",
    char: "🤹🏼",
    name: "person juggling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FD",
    char: "🤹🏽",
    name: "person juggling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FE",
    char: "🤹🏾",
    name: "person juggling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FF",
    char: "🤹🏿",
    name: "person juggling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 200D 2642 FE0F",
    char: "🤹‍♂️",
    name: "man juggling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 200D 2642",
    char: "🤹‍♂",
    name: "man juggling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FB 200D 2642 FE0F",
    char: "🤹🏻‍♂️",
    name: "man juggling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FB 200D 2642",
    char: "🤹🏻‍♂",
    name: "man juggling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FC 200D 2642 FE0F",
    char: "🤹🏼‍♂️",
    name: "man juggling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FC 200D 2642",
    char: "🤹🏼‍♂",
    name: "man juggling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FD 200D 2642 FE0F",
    char: "🤹🏽‍♂️",
    name: "man juggling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FD 200D 2642",
    char: "🤹🏽‍♂",
    name: "man juggling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FE 200D 2642 FE0F",
    char: "🤹🏾‍♂️",
    name: "man juggling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FE 200D 2642",
    char: "🤹🏾‍♂",
    name: "man juggling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FF 200D 2642 FE0F",
    char: "🤹🏿‍♂️",
    name: "man juggling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FF 200D 2642",
    char: "🤹🏿‍♂",
    name: "man juggling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 200D 2640 FE0F",
    char: "🤹‍♀️",
    name: "woman juggling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 200D 2640",
    char: "🤹‍♀",
    name: "woman juggling",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FB 200D 2640 FE0F",
    char: "🤹🏻‍♀️",
    name: "woman juggling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FB 200D 2640",
    char: "🤹🏻‍♀",
    name: "woman juggling: light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FC 200D 2640 FE0F",
    char: "🤹🏼‍♀️",
    name: "woman juggling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FC 200D 2640",
    char: "🤹🏼‍♀",
    name: "woman juggling: medium-light skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FD 200D 2640 FE0F",
    char: "🤹🏽‍♀️",
    name: "woman juggling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FD 200D 2640",
    char: "🤹🏽‍♀",
    name: "woman juggling: medium skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FE 200D 2640 FE0F",
    char: "🤹🏾‍♀️",
    name: "woman juggling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FE 200D 2640",
    char: "🤹🏾‍♀",
    name: "woman juggling: medium-dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FF 200D 2640 FE0F",
    char: "🤹🏿‍♀️",
    name: "woman juggling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F939 1F3FF 200D 2640",
    char: "🤹🏿‍♀",
    name: "woman juggling: dark skin tone",
    category: "People & Body (person-sport)",
    group: "People & Body",
    subgroup: "person-sport",
  },
  {
    codes: "1F9D8",
    char: "🧘",
    name: "person in lotus position",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FB",
    char: "🧘🏻",
    name: "person in lotus position: light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FC",
    char: "🧘🏼",
    name: "person in lotus position: medium-light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FD",
    char: "🧘🏽",
    name: "person in lotus position: medium skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FE",
    char: "🧘🏾",
    name: "person in lotus position: medium-dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FF",
    char: "🧘🏿",
    name: "person in lotus position: dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 200D 2642 FE0F",
    char: "🧘‍♂️",
    name: "man in lotus position",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 200D 2642",
    char: "🧘‍♂",
    name: "man in lotus position",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FB 200D 2642 FE0F",
    char: "🧘🏻‍♂️",
    name: "man in lotus position: light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FB 200D 2642",
    char: "🧘🏻‍♂",
    name: "man in lotus position: light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FC 200D 2642 FE0F",
    char: "🧘🏼‍♂️",
    name: "man in lotus position: medium-light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FC 200D 2642",
    char: "🧘🏼‍♂",
    name: "man in lotus position: medium-light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FD 200D 2642 FE0F",
    char: "🧘🏽‍♂️",
    name: "man in lotus position: medium skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FD 200D 2642",
    char: "🧘🏽‍♂",
    name: "man in lotus position: medium skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FE 200D 2642 FE0F",
    char: "🧘🏾‍♂️",
    name: "man in lotus position: medium-dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FE 200D 2642",
    char: "🧘🏾‍♂",
    name: "man in lotus position: medium-dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FF 200D 2642 FE0F",
    char: "🧘🏿‍♂️",
    name: "man in lotus position: dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FF 200D 2642",
    char: "🧘🏿‍♂",
    name: "man in lotus position: dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 200D 2640 FE0F",
    char: "🧘‍♀️",
    name: "woman in lotus position",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 200D 2640",
    char: "🧘‍♀",
    name: "woman in lotus position",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FB 200D 2640 FE0F",
    char: "🧘🏻‍♀️",
    name: "woman in lotus position: light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FB 200D 2640",
    char: "🧘🏻‍♀",
    name: "woman in lotus position: light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FC 200D 2640 FE0F",
    char: "🧘🏼‍♀️",
    name: "woman in lotus position: medium-light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FC 200D 2640",
    char: "🧘🏼‍♀",
    name: "woman in lotus position: medium-light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FD 200D 2640 FE0F",
    char: "🧘🏽‍♀️",
    name: "woman in lotus position: medium skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FD 200D 2640",
    char: "🧘🏽‍♀",
    name: "woman in lotus position: medium skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FE 200D 2640 FE0F",
    char: "🧘🏾‍♀️",
    name: "woman in lotus position: medium-dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FE 200D 2640",
    char: "🧘🏾‍♀",
    name: "woman in lotus position: medium-dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FF 200D 2640 FE0F",
    char: "🧘🏿‍♀️",
    name: "woman in lotus position: dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D8 1F3FF 200D 2640",
    char: "🧘🏿‍♀",
    name: "woman in lotus position: dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6C0",
    char: "🛀",
    name: "person taking bath",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6C0 1F3FB",
    char: "🛀🏻",
    name: "person taking bath: light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6C0 1F3FC",
    char: "🛀🏼",
    name: "person taking bath: medium-light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6C0 1F3FD",
    char: "🛀🏽",
    name: "person taking bath: medium skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6C0 1F3FE",
    char: "🛀🏾",
    name: "person taking bath: medium-dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6C0 1F3FF",
    char: "🛀🏿",
    name: "person taking bath: dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6CC",
    char: "🛌",
    name: "person in bed",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6CC 1F3FB",
    char: "🛌🏻",
    name: "person in bed: light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6CC 1F3FC",
    char: "🛌🏼",
    name: "person in bed: medium-light skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6CC 1F3FD",
    char: "🛌🏽",
    name: "person in bed: medium skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6CC 1F3FE",
    char: "🛌🏾",
    name: "person in bed: medium-dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F6CC 1F3FF",
    char: "🛌🏿",
    name: "person in bed: dark skin tone",
    category: "People & Body (person-resting)",
    group: "People & Body",
    subgroup: "person-resting",
  },
  {
    codes: "1F9D1 200D 1F91D 200D 1F9D1",
    char: "🧑‍🤝‍🧑",
    name: "people holding hands",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FB",
    char: "🧑🏻‍🤝‍🧑🏻",
    name: "people holding hands: light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FC",
    char: "🧑🏻‍🤝‍🧑🏼",
    name: "people holding hands: light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FD",
    char: "🧑🏻‍🤝‍🧑🏽",
    name: "people holding hands: light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FE",
    char: "🧑🏻‍🤝‍🧑🏾",
    name: "people holding hands: light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 1F91D 200D 1F9D1 1F3FF",
    char: "🧑🏻‍🤝‍🧑🏿",
    name: "people holding hands: light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FB",
    char: "🧑🏼‍🤝‍🧑🏻",
    name: "people holding hands: medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FC",
    char: "🧑🏼‍🤝‍🧑🏼",
    name: "people holding hands: medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FD",
    char: "🧑🏼‍🤝‍🧑🏽",
    name: "people holding hands: medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FE",
    char: "🧑🏼‍🤝‍🧑🏾",
    name: "people holding hands: medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 1F91D 200D 1F9D1 1F3FF",
    char: "🧑🏼‍🤝‍🧑🏿",
    name: "people holding hands: medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FB",
    char: "🧑🏽‍🤝‍🧑🏻",
    name: "people holding hands: medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FC",
    char: "🧑🏽‍🤝‍🧑🏼",
    name: "people holding hands: medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FD",
    char: "🧑🏽‍🤝‍🧑🏽",
    name: "people holding hands: medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FE",
    char: "🧑🏽‍🤝‍🧑🏾",
    name: "people holding hands: medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 1F91D 200D 1F9D1 1F3FF",
    char: "🧑🏽‍🤝‍🧑🏿",
    name: "people holding hands: medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FB",
    char: "🧑🏾‍🤝‍🧑🏻",
    name: "people holding hands: medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FC",
    char: "🧑🏾‍🤝‍🧑🏼",
    name: "people holding hands: medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FD",
    char: "🧑🏾‍🤝‍🧑🏽",
    name: "people holding hands: medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FE",
    char: "🧑🏾‍🤝‍🧑🏾",
    name: "people holding hands: medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 1F91D 200D 1F9D1 1F3FF",
    char: "🧑🏾‍🤝‍🧑🏿",
    name: "people holding hands: medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FB",
    char: "🧑🏿‍🤝‍🧑🏻",
    name: "people holding hands: dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FC",
    char: "🧑🏿‍🤝‍🧑🏼",
    name: "people holding hands: dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FD",
    char: "🧑🏿‍🤝‍🧑🏽",
    name: "people holding hands: dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FE",
    char: "🧑🏿‍🤝‍🧑🏾",
    name: "people holding hands: dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 1F91D 200D 1F9D1 1F3FF",
    char: "🧑🏿‍🤝‍🧑🏿",
    name: "people holding hands: dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46D",
    char: "👭",
    name: "women holding hands",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46D 1F3FB",
    char: "👭🏻",
    name: "women holding hands: light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 1F91D 200D 1F469 1F3FC",
    char: "👩🏻‍🤝‍👩🏼",
    name: "women holding hands: light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 1F91D 200D 1F469 1F3FD",
    char: "👩🏻‍🤝‍👩🏽",
    name: "women holding hands: light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 1F91D 200D 1F469 1F3FE",
    char: "👩🏻‍🤝‍👩🏾",
    name: "women holding hands: light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 1F91D 200D 1F469 1F3FF",
    char: "👩🏻‍🤝‍👩🏿",
    name: "women holding hands: light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 1F91D 200D 1F469 1F3FB",
    char: "👩🏼‍🤝‍👩🏻",
    name: "women holding hands: medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46D 1F3FC",
    char: "👭🏼",
    name: "women holding hands: medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 1F91D 200D 1F469 1F3FD",
    char: "👩🏼‍🤝‍👩🏽",
    name: "women holding hands: medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 1F91D 200D 1F469 1F3FE",
    char: "👩🏼‍🤝‍👩🏾",
    name: "women holding hands: medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 1F91D 200D 1F469 1F3FF",
    char: "👩🏼‍🤝‍👩🏿",
    name: "women holding hands: medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 1F91D 200D 1F469 1F3FB",
    char: "👩🏽‍🤝‍👩🏻",
    name: "women holding hands: medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 1F91D 200D 1F469 1F3FC",
    char: "👩🏽‍🤝‍👩🏼",
    name: "women holding hands: medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46D 1F3FD",
    char: "👭🏽",
    name: "women holding hands: medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 1F91D 200D 1F469 1F3FE",
    char: "👩🏽‍🤝‍👩🏾",
    name: "women holding hands: medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 1F91D 200D 1F469 1F3FF",
    char: "👩🏽‍🤝‍👩🏿",
    name: "women holding hands: medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 1F91D 200D 1F469 1F3FB",
    char: "👩🏾‍🤝‍👩🏻",
    name: "women holding hands: medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 1F91D 200D 1F469 1F3FC",
    char: "👩🏾‍🤝‍👩🏼",
    name: "women holding hands: medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 1F91D 200D 1F469 1F3FD",
    char: "👩🏾‍🤝‍👩🏽",
    name: "women holding hands: medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46D 1F3FE",
    char: "👭🏾",
    name: "women holding hands: medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 1F91D 200D 1F469 1F3FF",
    char: "👩🏾‍🤝‍👩🏿",
    name: "women holding hands: medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 1F91D 200D 1F469 1F3FB",
    char: "👩🏿‍🤝‍👩🏻",
    name: "women holding hands: dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 1F91D 200D 1F469 1F3FC",
    char: "👩🏿‍🤝‍👩🏼",
    name: "women holding hands: dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 1F91D 200D 1F469 1F3FD",
    char: "👩🏿‍🤝‍👩🏽",
    name: "women holding hands: dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 1F91D 200D 1F469 1F3FE",
    char: "👩🏿‍🤝‍👩🏾",
    name: "women holding hands: dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46D 1F3FF",
    char: "👭🏿",
    name: "women holding hands: dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46B",
    char: "👫",
    name: "woman and man holding hands",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46B 1F3FB",
    char: "👫🏻",
    name: "woman and man holding hands: light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 1F91D 200D 1F468 1F3FC",
    char: "👩🏻‍🤝‍👨🏼",
    name: "woman and man holding hands: light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 1F91D 200D 1F468 1F3FD",
    char: "👩🏻‍🤝‍👨🏽",
    name: "woman and man holding hands: light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 1F91D 200D 1F468 1F3FE",
    char: "👩🏻‍🤝‍👨🏾",
    name: "woman and man holding hands: light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 1F91D 200D 1F468 1F3FF",
    char: "👩🏻‍🤝‍👨🏿",
    name: "woman and man holding hands: light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 1F91D 200D 1F468 1F3FB",
    char: "👩🏼‍🤝‍👨🏻",
    name: "woman and man holding hands: medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46B 1F3FC",
    char: "👫🏼",
    name: "woman and man holding hands: medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 1F91D 200D 1F468 1F3FD",
    char: "👩🏼‍🤝‍👨🏽",
    name: "woman and man holding hands: medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 1F91D 200D 1F468 1F3FE",
    char: "👩🏼‍🤝‍👨🏾",
    name: "woman and man holding hands: medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 1F91D 200D 1F468 1F3FF",
    char: "👩🏼‍🤝‍👨🏿",
    name: "woman and man holding hands: medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 1F91D 200D 1F468 1F3FB",
    char: "👩🏽‍🤝‍👨🏻",
    name: "woman and man holding hands: medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 1F91D 200D 1F468 1F3FC",
    char: "👩🏽‍🤝‍👨🏼",
    name: "woman and man holding hands: medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46B 1F3FD",
    char: "👫🏽",
    name: "woman and man holding hands: medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 1F91D 200D 1F468 1F3FE",
    char: "👩🏽‍🤝‍👨🏾",
    name: "woman and man holding hands: medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 1F91D 200D 1F468 1F3FF",
    char: "👩🏽‍🤝‍👨🏿",
    name: "woman and man holding hands: medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 1F91D 200D 1F468 1F3FB",
    char: "👩🏾‍🤝‍👨🏻",
    name: "woman and man holding hands: medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 1F91D 200D 1F468 1F3FC",
    char: "👩🏾‍🤝‍👨🏼",
    name: "woman and man holding hands: medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 1F91D 200D 1F468 1F3FD",
    char: "👩🏾‍🤝‍👨🏽",
    name: "woman and man holding hands: medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46B 1F3FE",
    char: "👫🏾",
    name: "woman and man holding hands: medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 1F91D 200D 1F468 1F3FF",
    char: "👩🏾‍🤝‍👨🏿",
    name: "woman and man holding hands: medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 1F91D 200D 1F468 1F3FB",
    char: "👩🏿‍🤝‍👨🏻",
    name: "woman and man holding hands: dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 1F91D 200D 1F468 1F3FC",
    char: "👩🏿‍🤝‍👨🏼",
    name: "woman and man holding hands: dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 1F91D 200D 1F468 1F3FD",
    char: "👩🏿‍🤝‍👨🏽",
    name: "woman and man holding hands: dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 1F91D 200D 1F468 1F3FE",
    char: "👩🏿‍🤝‍👨🏾",
    name: "woman and man holding hands: dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46B 1F3FF",
    char: "👫🏿",
    name: "woman and man holding hands: dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46C",
    char: "👬",
    name: "men holding hands",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46C 1F3FB",
    char: "👬🏻",
    name: "men holding hands: light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 1F91D 200D 1F468 1F3FC",
    char: "👨🏻‍🤝‍👨🏼",
    name: "men holding hands: light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 1F91D 200D 1F468 1F3FD",
    char: "👨🏻‍🤝‍👨🏽",
    name: "men holding hands: light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 1F91D 200D 1F468 1F3FE",
    char: "👨🏻‍🤝‍👨🏾",
    name: "men holding hands: light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 1F91D 200D 1F468 1F3FF",
    char: "👨🏻‍🤝‍👨🏿",
    name: "men holding hands: light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 1F91D 200D 1F468 1F3FB",
    char: "👨🏼‍🤝‍👨🏻",
    name: "men holding hands: medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46C 1F3FC",
    char: "👬🏼",
    name: "men holding hands: medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 1F91D 200D 1F468 1F3FD",
    char: "👨🏼‍🤝‍👨🏽",
    name: "men holding hands: medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 1F91D 200D 1F468 1F3FE",
    char: "👨🏼‍🤝‍👨🏾",
    name: "men holding hands: medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 1F91D 200D 1F468 1F3FF",
    char: "👨🏼‍🤝‍👨🏿",
    name: "men holding hands: medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 1F91D 200D 1F468 1F3FB",
    char: "👨🏽‍🤝‍👨🏻",
    name: "men holding hands: medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 1F91D 200D 1F468 1F3FC",
    char: "👨🏽‍🤝‍👨🏼",
    name: "men holding hands: medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46C 1F3FD",
    char: "👬🏽",
    name: "men holding hands: medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 1F91D 200D 1F468 1F3FE",
    char: "👨🏽‍🤝‍👨🏾",
    name: "men holding hands: medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 1F91D 200D 1F468 1F3FF",
    char: "👨🏽‍🤝‍👨🏿",
    name: "men holding hands: medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 1F91D 200D 1F468 1F3FB",
    char: "👨🏾‍🤝‍👨🏻",
    name: "men holding hands: medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 1F91D 200D 1F468 1F3FC",
    char: "👨🏾‍🤝‍👨🏼",
    name: "men holding hands: medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 1F91D 200D 1F468 1F3FD",
    char: "👨🏾‍🤝‍👨🏽",
    name: "men holding hands: medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46C 1F3FE",
    char: "👬🏾",
    name: "men holding hands: medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 1F91D 200D 1F468 1F3FF",
    char: "👨🏾‍🤝‍👨🏿",
    name: "men holding hands: medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 1F91D 200D 1F468 1F3FB",
    char: "👨🏿‍🤝‍👨🏻",
    name: "men holding hands: dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 1F91D 200D 1F468 1F3FC",
    char: "👨🏿‍🤝‍👨🏼",
    name: "men holding hands: dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 1F91D 200D 1F468 1F3FD",
    char: "👨🏿‍🤝‍👨🏽",
    name: "men holding hands: dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 1F91D 200D 1F468 1F3FE",
    char: "👨🏿‍🤝‍👨🏾",
    name: "men holding hands: dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F46C 1F3FF",
    char: "👬🏿",
    name: "men holding hands: dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F48F",
    char: "💏",
    name: "kiss",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F48F 1F3FB",
    char: "💏🏻",
    name: "kiss: light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F48F 1F3FC",
    char: "💏🏼",
    name: "kiss: medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F48F 1F3FD",
    char: "💏🏽",
    name: "kiss: medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F48F 1F3FE",
    char: "💏🏾",
    name: "kiss: medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F48F 1F3FF",
    char: "💏🏿",
    name: "kiss: dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FC",
    char: "🧑🏻‍❤️‍💋‍🧑🏼",
    name: "kiss: person, person, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 200D 1F48B 200D 1F9D1 1F3FC",
    char: "🧑🏻‍❤‍💋‍🧑🏼",
    name: "kiss: person, person, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FD",
    char: "🧑🏻‍❤️‍💋‍🧑🏽",
    name: "kiss: person, person, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 200D 1F48B 200D 1F9D1 1F3FD",
    char: "🧑🏻‍❤‍💋‍🧑🏽",
    name: "kiss: person, person, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FE",
    char: "🧑🏻‍❤️‍💋‍🧑🏾",
    name: "kiss: person, person, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 200D 1F48B 200D 1F9D1 1F3FE",
    char: "🧑🏻‍❤‍💋‍🧑🏾",
    name: "kiss: person, person, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FF",
    char: "🧑🏻‍❤️‍💋‍🧑🏿",
    name: "kiss: person, person, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 200D 1F48B 200D 1F9D1 1F3FF",
    char: "🧑🏻‍❤‍💋‍🧑🏿",
    name: "kiss: person, person, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FB",
    char: "🧑🏼‍❤️‍💋‍🧑🏻",
    name: "kiss: person, person, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 200D 1F48B 200D 1F9D1 1F3FB",
    char: "🧑🏼‍❤‍💋‍🧑🏻",
    name: "kiss: person, person, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FD",
    char: "🧑🏼‍❤️‍💋‍🧑🏽",
    name: "kiss: person, person, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 200D 1F48B 200D 1F9D1 1F3FD",
    char: "🧑🏼‍❤‍💋‍🧑🏽",
    name: "kiss: person, person, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FE",
    char: "🧑🏼‍❤️‍💋‍🧑🏾",
    name: "kiss: person, person, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 200D 1F48B 200D 1F9D1 1F3FE",
    char: "🧑🏼‍❤‍💋‍🧑🏾",
    name: "kiss: person, person, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FF",
    char: "🧑🏼‍❤️‍💋‍🧑🏿",
    name: "kiss: person, person, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 200D 1F48B 200D 1F9D1 1F3FF",
    char: "🧑🏼‍❤‍💋‍🧑🏿",
    name: "kiss: person, person, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FB",
    char: "🧑🏽‍❤️‍💋‍🧑🏻",
    name: "kiss: person, person, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 200D 1F48B 200D 1F9D1 1F3FB",
    char: "🧑🏽‍❤‍💋‍🧑🏻",
    name: "kiss: person, person, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FC",
    char: "🧑🏽‍❤️‍💋‍🧑🏼",
    name: "kiss: person, person, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 200D 1F48B 200D 1F9D1 1F3FC",
    char: "🧑🏽‍❤‍💋‍🧑🏼",
    name: "kiss: person, person, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FE",
    char: "🧑🏽‍❤️‍💋‍🧑🏾",
    name: "kiss: person, person, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 200D 1F48B 200D 1F9D1 1F3FE",
    char: "🧑🏽‍❤‍💋‍🧑🏾",
    name: "kiss: person, person, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FF",
    char: "🧑🏽‍❤️‍💋‍🧑🏿",
    name: "kiss: person, person, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 200D 1F48B 200D 1F9D1 1F3FF",
    char: "🧑🏽‍❤‍💋‍🧑🏿",
    name: "kiss: person, person, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FB",
    char: "🧑🏾‍❤️‍💋‍🧑🏻",
    name: "kiss: person, person, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 200D 1F48B 200D 1F9D1 1F3FB",
    char: "🧑🏾‍❤‍💋‍🧑🏻",
    name: "kiss: person, person, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FC",
    char: "🧑🏾‍❤️‍💋‍🧑🏼",
    name: "kiss: person, person, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 200D 1F48B 200D 1F9D1 1F3FC",
    char: "🧑🏾‍❤‍💋‍🧑🏼",
    name: "kiss: person, person, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FD",
    char: "🧑🏾‍❤️‍💋‍🧑🏽",
    name: "kiss: person, person, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 200D 1F48B 200D 1F9D1 1F3FD",
    char: "🧑🏾‍❤‍💋‍🧑🏽",
    name: "kiss: person, person, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FF",
    char: "🧑🏾‍❤️‍💋‍🧑🏿",
    name: "kiss: person, person, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 200D 1F48B 200D 1F9D1 1F3FF",
    char: "🧑🏾‍❤‍💋‍🧑🏿",
    name: "kiss: person, person, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FB",
    char: "🧑🏿‍❤️‍💋‍🧑🏻",
    name: "kiss: person, person, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 200D 1F48B 200D 1F9D1 1F3FB",
    char: "🧑🏿‍❤‍💋‍🧑🏻",
    name: "kiss: person, person, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FC",
    char: "🧑🏿‍❤️‍💋‍🧑🏼",
    name: "kiss: person, person, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 200D 1F48B 200D 1F9D1 1F3FC",
    char: "🧑🏿‍❤‍💋‍🧑🏼",
    name: "kiss: person, person, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FD",
    char: "🧑🏿‍❤️‍💋‍🧑🏽",
    name: "kiss: person, person, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 200D 1F48B 200D 1F9D1 1F3FD",
    char: "🧑🏿‍❤‍💋‍🧑🏽",
    name: "kiss: person, person, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F9D1 1F3FE",
    char: "🧑🏿‍❤️‍💋‍🧑🏾",
    name: "kiss: person, person, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 200D 1F48B 200D 1F9D1 1F3FE",
    char: "🧑🏿‍❤‍💋‍🧑🏾",
    name: "kiss: person, person, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 2764 FE0F 200D 1F48B 200D 1F468",
    char: "👩‍❤️‍💋‍👨",
    name: "kiss: woman, man",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 2764 200D 1F48B 200D 1F468",
    char: "👩‍❤‍💋‍👨",
    name: "kiss: woman, man",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏻‍❤️‍💋‍👨🏻",
    name: "kiss: woman, man, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏻‍❤‍💋‍👨🏻",
    name: "kiss: woman, man, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏻‍❤️‍💋‍👨🏼",
    name: "kiss: woman, man, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏻‍❤‍💋‍👨🏼",
    name: "kiss: woman, man, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏻‍❤️‍💋‍👨🏽",
    name: "kiss: woman, man, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏻‍❤‍💋‍👨🏽",
    name: "kiss: woman, man, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏻‍❤️‍💋‍👨🏾",
    name: "kiss: woman, man, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏻‍❤‍💋‍👨🏾",
    name: "kiss: woman, man, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏻‍❤️‍💋‍👨🏿",
    name: "kiss: woman, man, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏻‍❤‍💋‍👨🏿",
    name: "kiss: woman, man, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏼‍❤️‍💋‍👨🏻",
    name: "kiss: woman, man, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏼‍❤‍💋‍👨🏻",
    name: "kiss: woman, man, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏼‍❤️‍💋‍👨🏼",
    name: "kiss: woman, man, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏼‍❤‍💋‍👨🏼",
    name: "kiss: woman, man, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏼‍❤️‍💋‍👨🏽",
    name: "kiss: woman, man, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏼‍❤‍💋‍👨🏽",
    name: "kiss: woman, man, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏼‍❤️‍💋‍👨🏾",
    name: "kiss: woman, man, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏼‍❤‍💋‍👨🏾",
    name: "kiss: woman, man, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏼‍❤️‍💋‍👨🏿",
    name: "kiss: woman, man, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏼‍❤‍💋‍👨🏿",
    name: "kiss: woman, man, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏽‍❤️‍💋‍👨🏻",
    name: "kiss: woman, man, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏽‍❤‍💋‍👨🏻",
    name: "kiss: woman, man, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏽‍❤️‍💋‍👨🏼",
    name: "kiss: woman, man, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏽‍❤‍💋‍👨🏼",
    name: "kiss: woman, man, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏽‍❤️‍💋‍👨🏽",
    name: "kiss: woman, man, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏽‍❤‍💋‍👨🏽",
    name: "kiss: woman, man, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏽‍❤️‍💋‍👨🏾",
    name: "kiss: woman, man, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏽‍❤‍💋‍👨🏾",
    name: "kiss: woman, man, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏽‍❤️‍💋‍👨🏿",
    name: "kiss: woman, man, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏽‍❤‍💋‍👨🏿",
    name: "kiss: woman, man, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏾‍❤️‍💋‍👨🏻",
    name: "kiss: woman, man, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏾‍❤‍💋‍👨🏻",
    name: "kiss: woman, man, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏾‍❤️‍💋‍👨🏼",
    name: "kiss: woman, man, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏾‍❤‍💋‍👨🏼",
    name: "kiss: woman, man, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏾‍❤️‍💋‍👨🏽",
    name: "kiss: woman, man, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏾‍❤‍💋‍👨🏽",
    name: "kiss: woman, man, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏾‍❤️‍💋‍👨🏾",
    name: "kiss: woman, man, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏾‍❤‍💋‍👨🏾",
    name: "kiss: woman, man, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏾‍❤️‍💋‍👨🏿",
    name: "kiss: woman, man, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏾‍❤‍💋‍👨🏿",
    name: "kiss: woman, man, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏿‍❤️‍💋‍👨🏻",
    name: "kiss: woman, man, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👩🏿‍❤‍💋‍👨🏻",
    name: "kiss: woman, man, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏿‍❤️‍💋‍👨🏼",
    name: "kiss: woman, man, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👩🏿‍❤‍💋‍👨🏼",
    name: "kiss: woman, man, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏿‍❤️‍💋‍👨🏽",
    name: "kiss: woman, man, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👩🏿‍❤‍💋‍👨🏽",
    name: "kiss: woman, man, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏿‍❤️‍💋‍👨🏾",
    name: "kiss: woman, man, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👩🏿‍❤‍💋‍👨🏾",
    name: "kiss: woman, man, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏿‍❤️‍💋‍👨🏿",
    name: "kiss: woman, man, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👩🏿‍❤‍💋‍👨🏿",
    name: "kiss: woman, man, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 2764 FE0F 200D 1F48B 200D 1F468",
    char: "👨‍❤️‍💋‍👨",
    name: "kiss: man, man",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 2764 200D 1F48B 200D 1F468",
    char: "👨‍❤‍💋‍👨",
    name: "kiss: man, man",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏻‍❤️‍💋‍👨🏻",
    name: "kiss: man, man, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏻‍❤‍💋‍👨🏻",
    name: "kiss: man, man, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏻‍❤️‍💋‍👨🏼",
    name: "kiss: man, man, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏻‍❤‍💋‍👨🏼",
    name: "kiss: man, man, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏻‍❤️‍💋‍👨🏽",
    name: "kiss: man, man, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏻‍❤‍💋‍👨🏽",
    name: "kiss: man, man, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏻‍❤️‍💋‍👨🏾",
    name: "kiss: man, man, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏻‍❤‍💋‍👨🏾",
    name: "kiss: man, man, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏻‍❤️‍💋‍👨🏿",
    name: "kiss: man, man, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏻‍❤‍💋‍👨🏿",
    name: "kiss: man, man, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏼‍❤️‍💋‍👨🏻",
    name: "kiss: man, man, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏼‍❤‍💋‍👨🏻",
    name: "kiss: man, man, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏼‍❤️‍💋‍👨🏼",
    name: "kiss: man, man, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏼‍❤‍💋‍👨🏼",
    name: "kiss: man, man, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏼‍❤️‍💋‍👨🏽",
    name: "kiss: man, man, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏼‍❤‍💋‍👨🏽",
    name: "kiss: man, man, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏼‍❤️‍💋‍👨🏾",
    name: "kiss: man, man, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏼‍❤‍💋‍👨🏾",
    name: "kiss: man, man, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏼‍❤️‍💋‍👨🏿",
    name: "kiss: man, man, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏼‍❤‍💋‍👨🏿",
    name: "kiss: man, man, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏽‍❤️‍💋‍👨🏻",
    name: "kiss: man, man, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏽‍❤‍💋‍👨🏻",
    name: "kiss: man, man, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏽‍❤️‍💋‍👨🏼",
    name: "kiss: man, man, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏽‍❤‍💋‍👨🏼",
    name: "kiss: man, man, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏽‍❤️‍💋‍👨🏽",
    name: "kiss: man, man, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏽‍❤‍💋‍👨🏽",
    name: "kiss: man, man, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏽‍❤️‍💋‍👨🏾",
    name: "kiss: man, man, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏽‍❤‍💋‍👨🏾",
    name: "kiss: man, man, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏽‍❤️‍💋‍👨🏿",
    name: "kiss: man, man, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏽‍❤‍💋‍👨🏿",
    name: "kiss: man, man, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏾‍❤️‍💋‍👨🏻",
    name: "kiss: man, man, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏾‍❤‍💋‍👨🏻",
    name: "kiss: man, man, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏾‍❤️‍💋‍👨🏼",
    name: "kiss: man, man, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏾‍❤‍💋‍👨🏼",
    name: "kiss: man, man, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏾‍❤️‍💋‍👨🏽",
    name: "kiss: man, man, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏾‍❤‍💋‍👨🏽",
    name: "kiss: man, man, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏾‍❤️‍💋‍👨🏾",
    name: "kiss: man, man, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏾‍❤‍💋‍👨🏾",
    name: "kiss: man, man, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏾‍❤️‍💋‍👨🏿",
    name: "kiss: man, man, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏾‍❤‍💋‍👨🏿",
    name: "kiss: man, man, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏿‍❤️‍💋‍👨🏻",
    name: "kiss: man, man, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FB",
    char: "👨🏿‍❤‍💋‍👨🏻",
    name: "kiss: man, man, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏿‍❤️‍💋‍👨🏼",
    name: "kiss: man, man, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FC",
    char: "👨🏿‍❤‍💋‍👨🏼",
    name: "kiss: man, man, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏿‍❤️‍💋‍👨🏽",
    name: "kiss: man, man, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FD",
    char: "👨🏿‍❤‍💋‍👨🏽",
    name: "kiss: man, man, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏿‍❤️‍💋‍👨🏾",
    name: "kiss: man, man, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FE",
    char: "👨🏿‍❤‍💋‍👨🏾",
    name: "kiss: man, man, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏿‍❤️‍💋‍👨🏿",
    name: "kiss: man, man, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F48B 200D 1F468 1F3FF",
    char: "👨🏿‍❤‍💋‍👨🏿",
    name: "kiss: man, man, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 2764 FE0F 200D 1F48B 200D 1F469",
    char: "👩‍❤️‍💋‍👩",
    name: "kiss: woman, woman",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 2764 200D 1F48B 200D 1F469",
    char: "👩‍❤‍💋‍👩",
    name: "kiss: woman, woman",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏻‍❤️‍💋‍👩🏻",
    name: "kiss: woman, woman, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏻‍❤‍💋‍👩🏻",
    name: "kiss: woman, woman, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏻‍❤️‍💋‍👩🏼",
    name: "kiss: woman, woman, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏻‍❤‍💋‍👩🏼",
    name: "kiss: woman, woman, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏻‍❤️‍💋‍👩🏽",
    name: "kiss: woman, woman, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏻‍❤‍💋‍👩🏽",
    name: "kiss: woman, woman, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏻‍❤️‍💋‍👩🏾",
    name: "kiss: woman, woman, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏻‍❤‍💋‍👩🏾",
    name: "kiss: woman, woman, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏻‍❤️‍💋‍👩🏿",
    name: "kiss: woman, woman, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏻‍❤‍💋‍👩🏿",
    name: "kiss: woman, woman, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏼‍❤️‍💋‍👩🏻",
    name: "kiss: woman, woman, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏼‍❤‍💋‍👩🏻",
    name: "kiss: woman, woman, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏼‍❤️‍💋‍👩🏼",
    name: "kiss: woman, woman, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏼‍❤‍💋‍👩🏼",
    name: "kiss: woman, woman, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏼‍❤️‍💋‍👩🏽",
    name: "kiss: woman, woman, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏼‍❤‍💋‍👩🏽",
    name: "kiss: woman, woman, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏼‍❤️‍💋‍👩🏾",
    name: "kiss: woman, woman, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏼‍❤‍💋‍👩🏾",
    name: "kiss: woman, woman, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏼‍❤️‍💋‍👩🏿",
    name: "kiss: woman, woman, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏼‍❤‍💋‍👩🏿",
    name: "kiss: woman, woman, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏽‍❤️‍💋‍👩🏻",
    name: "kiss: woman, woman, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏽‍❤‍💋‍👩🏻",
    name: "kiss: woman, woman, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏽‍❤️‍💋‍👩🏼",
    name: "kiss: woman, woman, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏽‍❤‍💋‍👩🏼",
    name: "kiss: woman, woman, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏽‍❤️‍💋‍👩🏽",
    name: "kiss: woman, woman, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏽‍❤‍💋‍👩🏽",
    name: "kiss: woman, woman, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏽‍❤️‍💋‍👩🏾",
    name: "kiss: woman, woman, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏽‍❤‍💋‍👩🏾",
    name: "kiss: woman, woman, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏽‍❤️‍💋‍👩🏿",
    name: "kiss: woman, woman, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏽‍❤‍💋‍👩🏿",
    name: "kiss: woman, woman, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏾‍❤️‍💋‍👩🏻",
    name: "kiss: woman, woman, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏾‍❤‍💋‍👩🏻",
    name: "kiss: woman, woman, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏾‍❤️‍💋‍👩🏼",
    name: "kiss: woman, woman, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏾‍❤‍💋‍👩🏼",
    name: "kiss: woman, woman, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏾‍❤️‍💋‍👩🏽",
    name: "kiss: woman, woman, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏾‍❤‍💋‍👩🏽",
    name: "kiss: woman, woman, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏾‍❤️‍💋‍👩🏾",
    name: "kiss: woman, woman, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏾‍❤‍💋‍👩🏾",
    name: "kiss: woman, woman, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏾‍❤️‍💋‍👩🏿",
    name: "kiss: woman, woman, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏾‍❤‍💋‍👩🏿",
    name: "kiss: woman, woman, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏿‍❤️‍💋‍👩🏻",
    name: "kiss: woman, woman, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FB",
    char: "👩🏿‍❤‍💋‍👩🏻",
    name: "kiss: woman, woman, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏿‍❤️‍💋‍👩🏼",
    name: "kiss: woman, woman, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FC",
    char: "👩🏿‍❤‍💋‍👩🏼",
    name: "kiss: woman, woman, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏿‍❤️‍💋‍👩🏽",
    name: "kiss: woman, woman, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FD",
    char: "👩🏿‍❤‍💋‍👩🏽",
    name: "kiss: woman, woman, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏿‍❤️‍💋‍👩🏾",
    name: "kiss: woman, woman, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FE",
    char: "👩🏿‍❤‍💋‍👩🏾",
    name: "kiss: woman, woman, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏿‍❤️‍💋‍👩🏿",
    name: "kiss: woman, woman, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F48B 200D 1F469 1F3FF",
    char: "👩🏿‍❤‍💋‍👩🏿",
    name: "kiss: woman, woman, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F491",
    char: "💑",
    name: "couple with heart",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F491 1F3FB",
    char: "💑🏻",
    name: "couple with heart: light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F491 1F3FC",
    char: "💑🏼",
    name: "couple with heart: medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F491 1F3FD",
    char: "💑🏽",
    name: "couple with heart: medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F491 1F3FE",
    char: "💑🏾",
    name: "couple with heart: medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F491 1F3FF",
    char: "💑🏿",
    name: "couple with heart: dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 FE0F 200D 1F9D1 1F3FC",
    char: "🧑🏻‍❤️‍🧑🏼",
    name: "couple with heart: person, person, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 200D 1F9D1 1F3FC",
    char: "🧑🏻‍❤‍🧑🏼",
    name: "couple with heart: person, person, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 FE0F 200D 1F9D1 1F3FD",
    char: "🧑🏻‍❤️‍🧑🏽",
    name: "couple with heart: person, person, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 200D 1F9D1 1F3FD",
    char: "🧑🏻‍❤‍🧑🏽",
    name: "couple with heart: person, person, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 FE0F 200D 1F9D1 1F3FE",
    char: "🧑🏻‍❤️‍🧑🏾",
    name: "couple with heart: person, person, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 200D 1F9D1 1F3FE",
    char: "🧑🏻‍❤‍🧑🏾",
    name: "couple with heart: person, person, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 FE0F 200D 1F9D1 1F3FF",
    char: "🧑🏻‍❤️‍🧑🏿",
    name: "couple with heart: person, person, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FB 200D 2764 200D 1F9D1 1F3FF",
    char: "🧑🏻‍❤‍🧑🏿",
    name: "couple with heart: person, person, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 FE0F 200D 1F9D1 1F3FB",
    char: "🧑🏼‍❤️‍🧑🏻",
    name: "couple with heart: person, person, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 200D 1F9D1 1F3FB",
    char: "🧑🏼‍❤‍🧑🏻",
    name: "couple with heart: person, person, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 FE0F 200D 1F9D1 1F3FD",
    char: "🧑🏼‍❤️‍🧑🏽",
    name: "couple with heart: person, person, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 200D 1F9D1 1F3FD",
    char: "🧑🏼‍❤‍🧑🏽",
    name: "couple with heart: person, person, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 FE0F 200D 1F9D1 1F3FE",
    char: "🧑🏼‍❤️‍🧑🏾",
    name: "couple with heart: person, person, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 200D 1F9D1 1F3FE",
    char: "🧑🏼‍❤‍🧑🏾",
    name: "couple with heart: person, person, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 FE0F 200D 1F9D1 1F3FF",
    char: "🧑🏼‍❤️‍🧑🏿",
    name: "couple with heart: person, person, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FC 200D 2764 200D 1F9D1 1F3FF",
    char: "🧑🏼‍❤‍🧑🏿",
    name: "couple with heart: person, person, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 FE0F 200D 1F9D1 1F3FB",
    char: "🧑🏽‍❤️‍🧑🏻",
    name: "couple with heart: person, person, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 200D 1F9D1 1F3FB",
    char: "🧑🏽‍❤‍🧑🏻",
    name: "couple with heart: person, person, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 FE0F 200D 1F9D1 1F3FC",
    char: "🧑🏽‍❤️‍🧑🏼",
    name: "couple with heart: person, person, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 200D 1F9D1 1F3FC",
    char: "🧑🏽‍❤‍🧑🏼",
    name: "couple with heart: person, person, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 FE0F 200D 1F9D1 1F3FE",
    char: "🧑🏽‍❤️‍🧑🏾",
    name: "couple with heart: person, person, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 200D 1F9D1 1F3FE",
    char: "🧑🏽‍❤‍🧑🏾",
    name: "couple with heart: person, person, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 FE0F 200D 1F9D1 1F3FF",
    char: "🧑🏽‍❤️‍🧑🏿",
    name: "couple with heart: person, person, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FD 200D 2764 200D 1F9D1 1F3FF",
    char: "🧑🏽‍❤‍🧑🏿",
    name: "couple with heart: person, person, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 FE0F 200D 1F9D1 1F3FB",
    char: "🧑🏾‍❤️‍🧑🏻",
    name: "couple with heart: person, person, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 200D 1F9D1 1F3FB",
    char: "🧑🏾‍❤‍🧑🏻",
    name: "couple with heart: person, person, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 FE0F 200D 1F9D1 1F3FC",
    char: "🧑🏾‍❤️‍🧑🏼",
    name: "couple with heart: person, person, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 200D 1F9D1 1F3FC",
    char: "🧑🏾‍❤‍🧑🏼",
    name: "couple with heart: person, person, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 FE0F 200D 1F9D1 1F3FD",
    char: "🧑🏾‍❤️‍🧑🏽",
    name: "couple with heart: person, person, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 200D 1F9D1 1F3FD",
    char: "🧑🏾‍❤‍🧑🏽",
    name: "couple with heart: person, person, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 FE0F 200D 1F9D1 1F3FF",
    char: "🧑🏾‍❤️‍🧑🏿",
    name: "couple with heart: person, person, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FE 200D 2764 200D 1F9D1 1F3FF",
    char: "🧑🏾‍❤‍🧑🏿",
    name: "couple with heart: person, person, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 FE0F 200D 1F9D1 1F3FB",
    char: "🧑🏿‍❤️‍🧑🏻",
    name: "couple with heart: person, person, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 200D 1F9D1 1F3FB",
    char: "🧑🏿‍❤‍🧑🏻",
    name: "couple with heart: person, person, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 FE0F 200D 1F9D1 1F3FC",
    char: "🧑🏿‍❤️‍🧑🏼",
    name: "couple with heart: person, person, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 200D 1F9D1 1F3FC",
    char: "🧑🏿‍❤‍🧑🏼",
    name: "couple with heart: person, person, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 FE0F 200D 1F9D1 1F3FD",
    char: "🧑🏿‍❤️‍🧑🏽",
    name: "couple with heart: person, person, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 200D 1F9D1 1F3FD",
    char: "🧑🏿‍❤‍🧑🏽",
    name: "couple with heart: person, person, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 FE0F 200D 1F9D1 1F3FE",
    char: "🧑🏿‍❤️‍🧑🏾",
    name: "couple with heart: person, person, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F9D1 1F3FF 200D 2764 200D 1F9D1 1F3FE",
    char: "🧑🏿‍❤‍🧑🏾",
    name: "couple with heart: person, person, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 2764 FE0F 200D 1F468",
    char: "👩‍❤️‍👨",
    name: "couple with heart: woman, man",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 2764 200D 1F468",
    char: "👩‍❤‍👨",
    name: "couple with heart: woman, man",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👩🏻‍❤️‍👨🏻",
    name: "couple with heart: woman, man, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F468 1F3FB",
    char: "👩🏻‍❤‍👨🏻",
    name: "couple with heart: woman, man, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👩🏻‍❤️‍👨🏼",
    name: "couple with heart: woman, man, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F468 1F3FC",
    char: "👩🏻‍❤‍👨🏼",
    name: "couple with heart: woman, man, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👩🏻‍❤️‍👨🏽",
    name: "couple with heart: woman, man, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F468 1F3FD",
    char: "👩🏻‍❤‍👨🏽",
    name: "couple with heart: woman, man, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👩🏻‍❤️‍👨🏾",
    name: "couple with heart: woman, man, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F468 1F3FE",
    char: "👩🏻‍❤‍👨🏾",
    name: "couple with heart: woman, man, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👩🏻‍❤️‍👨🏿",
    name: "couple with heart: woman, man, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F468 1F3FF",
    char: "👩🏻‍❤‍👨🏿",
    name: "couple with heart: woman, man, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👩🏼‍❤️‍👨🏻",
    name: "couple with heart: woman, man, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F468 1F3FB",
    char: "👩🏼‍❤‍👨🏻",
    name: "couple with heart: woman, man, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👩🏼‍❤️‍👨🏼",
    name: "couple with heart: woman, man, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F468 1F3FC",
    char: "👩🏼‍❤‍👨🏼",
    name: "couple with heart: woman, man, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👩🏼‍❤️‍👨🏽",
    name: "couple with heart: woman, man, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F468 1F3FD",
    char: "👩🏼‍❤‍👨🏽",
    name: "couple with heart: woman, man, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👩🏼‍❤️‍👨🏾",
    name: "couple with heart: woman, man, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F468 1F3FE",
    char: "👩🏼‍❤‍👨🏾",
    name: "couple with heart: woman, man, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👩🏼‍❤️‍👨🏿",
    name: "couple with heart: woman, man, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F468 1F3FF",
    char: "👩🏼‍❤‍👨🏿",
    name: "couple with heart: woman, man, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👩🏽‍❤️‍👨🏻",
    name: "couple with heart: woman, man, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F468 1F3FB",
    char: "👩🏽‍❤‍👨🏻",
    name: "couple with heart: woman, man, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👩🏽‍❤️‍👨🏼",
    name: "couple with heart: woman, man, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F468 1F3FC",
    char: "👩🏽‍❤‍👨🏼",
    name: "couple with heart: woman, man, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👩🏽‍❤️‍👨🏽",
    name: "couple with heart: woman, man, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F468 1F3FD",
    char: "👩🏽‍❤‍👨🏽",
    name: "couple with heart: woman, man, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👩🏽‍❤️‍👨🏾",
    name: "couple with heart: woman, man, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F468 1F3FE",
    char: "👩🏽‍❤‍👨🏾",
    name: "couple with heart: woman, man, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👩🏽‍❤️‍👨🏿",
    name: "couple with heart: woman, man, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F468 1F3FF",
    char: "👩🏽‍❤‍👨🏿",
    name: "couple with heart: woman, man, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👩🏾‍❤️‍👨🏻",
    name: "couple with heart: woman, man, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F468 1F3FB",
    char: "👩🏾‍❤‍👨🏻",
    name: "couple with heart: woman, man, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👩🏾‍❤️‍👨🏼",
    name: "couple with heart: woman, man, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F468 1F3FC",
    char: "👩🏾‍❤‍👨🏼",
    name: "couple with heart: woman, man, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👩🏾‍❤️‍👨🏽",
    name: "couple with heart: woman, man, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F468 1F3FD",
    char: "👩🏾‍❤‍👨🏽",
    name: "couple with heart: woman, man, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👩🏾‍❤️‍👨🏾",
    name: "couple with heart: woman, man, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F468 1F3FE",
    char: "👩🏾‍❤‍👨🏾",
    name: "couple with heart: woman, man, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👩🏾‍❤️‍👨🏿",
    name: "couple with heart: woman, man, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F468 1F3FF",
    char: "👩🏾‍❤‍👨🏿",
    name: "couple with heart: woman, man, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👩🏿‍❤️‍👨🏻",
    name: "couple with heart: woman, man, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F468 1F3FB",
    char: "👩🏿‍❤‍👨🏻",
    name: "couple with heart: woman, man, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👩🏿‍❤️‍👨🏼",
    name: "couple with heart: woman, man, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F468 1F3FC",
    char: "👩🏿‍❤‍👨🏼",
    name: "couple with heart: woman, man, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👩🏿‍❤️‍👨🏽",
    name: "couple with heart: woman, man, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F468 1F3FD",
    char: "👩🏿‍❤‍👨🏽",
    name: "couple with heart: woman, man, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👩🏿‍❤️‍👨🏾",
    name: "couple with heart: woman, man, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F468 1F3FE",
    char: "👩🏿‍❤‍👨🏾",
    name: "couple with heart: woman, man, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👩🏿‍❤️‍👨🏿",
    name: "couple with heart: woman, man, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F468 1F3FF",
    char: "👩🏿‍❤‍👨🏿",
    name: "couple with heart: woman, man, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 2764 FE0F 200D 1F468",
    char: "👨‍❤️‍👨",
    name: "couple with heart: man, man",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 2764 200D 1F468",
    char: "👨‍❤‍👨",
    name: "couple with heart: man, man",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👨🏻‍❤️‍👨🏻",
    name: "couple with heart: man, man, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F468 1F3FB",
    char: "👨🏻‍❤‍👨🏻",
    name: "couple with heart: man, man, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👨🏻‍❤️‍👨🏼",
    name: "couple with heart: man, man, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F468 1F3FC",
    char: "👨🏻‍❤‍👨🏼",
    name: "couple with heart: man, man, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👨🏻‍❤️‍👨🏽",
    name: "couple with heart: man, man, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F468 1F3FD",
    char: "👨🏻‍❤‍👨🏽",
    name: "couple with heart: man, man, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👨🏻‍❤️‍👨🏾",
    name: "couple with heart: man, man, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F468 1F3FE",
    char: "👨🏻‍❤‍👨🏾",
    name: "couple with heart: man, man, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👨🏻‍❤️‍👨🏿",
    name: "couple with heart: man, man, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FB 200D 2764 200D 1F468 1F3FF",
    char: "👨🏻‍❤‍👨🏿",
    name: "couple with heart: man, man, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👨🏼‍❤️‍👨🏻",
    name: "couple with heart: man, man, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F468 1F3FB",
    char: "👨🏼‍❤‍👨🏻",
    name: "couple with heart: man, man, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👨🏼‍❤️‍👨🏼",
    name: "couple with heart: man, man, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F468 1F3FC",
    char: "👨🏼‍❤‍👨🏼",
    name: "couple with heart: man, man, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👨🏼‍❤️‍👨🏽",
    name: "couple with heart: man, man, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F468 1F3FD",
    char: "👨🏼‍❤‍👨🏽",
    name: "couple with heart: man, man, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👨🏼‍❤️‍👨🏾",
    name: "couple with heart: man, man, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F468 1F3FE",
    char: "👨🏼‍❤‍👨🏾",
    name: "couple with heart: man, man, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👨🏼‍❤️‍👨🏿",
    name: "couple with heart: man, man, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FC 200D 2764 200D 1F468 1F3FF",
    char: "👨🏼‍❤‍👨🏿",
    name: "couple with heart: man, man, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👨🏽‍❤️‍👨🏻",
    name: "couple with heart: man, man, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F468 1F3FB",
    char: "👨🏽‍❤‍👨🏻",
    name: "couple with heart: man, man, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👨🏽‍❤️‍👨🏼",
    name: "couple with heart: man, man, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F468 1F3FC",
    char: "👨🏽‍❤‍👨🏼",
    name: "couple with heart: man, man, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👨🏽‍❤️‍👨🏽",
    name: "couple with heart: man, man, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F468 1F3FD",
    char: "👨🏽‍❤‍👨🏽",
    name: "couple with heart: man, man, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👨🏽‍❤️‍👨🏾",
    name: "couple with heart: man, man, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F468 1F3FE",
    char: "👨🏽‍❤‍👨🏾",
    name: "couple with heart: man, man, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👨🏽‍❤️‍👨🏿",
    name: "couple with heart: man, man, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FD 200D 2764 200D 1F468 1F3FF",
    char: "👨🏽‍❤‍👨🏿",
    name: "couple with heart: man, man, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👨🏾‍❤️‍👨🏻",
    name: "couple with heart: man, man, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F468 1F3FB",
    char: "👨🏾‍❤‍👨🏻",
    name: "couple with heart: man, man, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👨🏾‍❤️‍👨🏼",
    name: "couple with heart: man, man, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F468 1F3FC",
    char: "👨🏾‍❤‍👨🏼",
    name: "couple with heart: man, man, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👨🏾‍❤️‍👨🏽",
    name: "couple with heart: man, man, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F468 1F3FD",
    char: "👨🏾‍❤‍👨🏽",
    name: "couple with heart: man, man, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👨🏾‍❤️‍👨🏾",
    name: "couple with heart: man, man, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F468 1F3FE",
    char: "👨🏾‍❤‍👨🏾",
    name: "couple with heart: man, man, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👨🏾‍❤️‍👨🏿",
    name: "couple with heart: man, man, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FE 200D 2764 200D 1F468 1F3FF",
    char: "👨🏾‍❤‍👨🏿",
    name: "couple with heart: man, man, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FB",
    char: "👨🏿‍❤️‍👨🏻",
    name: "couple with heart: man, man, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F468 1F3FB",
    char: "👨🏿‍❤‍👨🏻",
    name: "couple with heart: man, man, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FC",
    char: "👨🏿‍❤️‍👨🏼",
    name: "couple with heart: man, man, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F468 1F3FC",
    char: "👨🏿‍❤‍👨🏼",
    name: "couple with heart: man, man, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FD",
    char: "👨🏿‍❤️‍👨🏽",
    name: "couple with heart: man, man, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F468 1F3FD",
    char: "👨🏿‍❤‍👨🏽",
    name: "couple with heart: man, man, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FE",
    char: "👨🏿‍❤️‍👨🏾",
    name: "couple with heart: man, man, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F468 1F3FE",
    char: "👨🏿‍❤‍👨🏾",
    name: "couple with heart: man, man, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 FE0F 200D 1F468 1F3FF",
    char: "👨🏿‍❤️‍👨🏿",
    name: "couple with heart: man, man, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 1F3FF 200D 2764 200D 1F468 1F3FF",
    char: "👨🏿‍❤‍👨🏿",
    name: "couple with heart: man, man, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 2764 FE0F 200D 1F469",
    char: "👩‍❤️‍👩",
    name: "couple with heart: woman, woman",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 2764 200D 1F469",
    char: "👩‍❤‍👩",
    name: "couple with heart: woman, woman",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FB",
    char: "👩🏻‍❤️‍👩🏻",
    name: "couple with heart: woman, woman, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F469 1F3FB",
    char: "👩🏻‍❤‍👩🏻",
    name: "couple with heart: woman, woman, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FC",
    char: "👩🏻‍❤️‍👩🏼",
    name: "couple with heart: woman, woman, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F469 1F3FC",
    char: "👩🏻‍❤‍👩🏼",
    name: "couple with heart: woman, woman, light skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FD",
    char: "👩🏻‍❤️‍👩🏽",
    name: "couple with heart: woman, woman, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F469 1F3FD",
    char: "👩🏻‍❤‍👩🏽",
    name: "couple with heart: woman, woman, light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FE",
    char: "👩🏻‍❤️‍👩🏾",
    name: "couple with heart: woman, woman, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F469 1F3FE",
    char: "👩🏻‍❤‍👩🏾",
    name: "couple with heart: woman, woman, light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 FE0F 200D 1F469 1F3FF",
    char: "👩🏻‍❤️‍👩🏿",
    name: "couple with heart: woman, woman, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FB 200D 2764 200D 1F469 1F3FF",
    char: "👩🏻‍❤‍👩🏿",
    name: "couple with heart: woman, woman, light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FB",
    char: "👩🏼‍❤️‍👩🏻",
    name: "couple with heart: woman, woman, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F469 1F3FB",
    char: "👩🏼‍❤‍👩🏻",
    name: "couple with heart: woman, woman, medium-light skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FC",
    char: "👩🏼‍❤️‍👩🏼",
    name: "couple with heart: woman, woman, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F469 1F3FC",
    char: "👩🏼‍❤‍👩🏼",
    name: "couple with heart: woman, woman, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FD",
    char: "👩🏼‍❤️‍👩🏽",
    name: "couple with heart: woman, woman, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F469 1F3FD",
    char: "👩🏼‍❤‍👩🏽",
    name: "couple with heart: woman, woman, medium-light skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FE",
    char: "👩🏼‍❤️‍👩🏾",
    name: "couple with heart: woman, woman, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F469 1F3FE",
    char: "👩🏼‍❤‍👩🏾",
    name: "couple with heart: woman, woman, medium-light skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 FE0F 200D 1F469 1F3FF",
    char: "👩🏼‍❤️‍👩🏿",
    name: "couple with heart: woman, woman, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FC 200D 2764 200D 1F469 1F3FF",
    char: "👩🏼‍❤‍👩🏿",
    name: "couple with heart: woman, woman, medium-light skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FB",
    char: "👩🏽‍❤️‍👩🏻",
    name: "couple with heart: woman, woman, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F469 1F3FB",
    char: "👩🏽‍❤‍👩🏻",
    name: "couple with heart: woman, woman, medium skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FC",
    char: "👩🏽‍❤️‍👩🏼",
    name: "couple with heart: woman, woman, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F469 1F3FC",
    char: "👩🏽‍❤‍👩🏼",
    name: "couple with heart: woman, woman, medium skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FD",
    char: "👩🏽‍❤️‍👩🏽",
    name: "couple with heart: woman, woman, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F469 1F3FD",
    char: "👩🏽‍❤‍👩🏽",
    name: "couple with heart: woman, woman, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FE",
    char: "👩🏽‍❤️‍👩🏾",
    name: "couple with heart: woman, woman, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F469 1F3FE",
    char: "👩🏽‍❤‍👩🏾",
    name: "couple with heart: woman, woman, medium skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 FE0F 200D 1F469 1F3FF",
    char: "👩🏽‍❤️‍👩🏿",
    name: "couple with heart: woman, woman, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FD 200D 2764 200D 1F469 1F3FF",
    char: "👩🏽‍❤‍👩🏿",
    name: "couple with heart: woman, woman, medium skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FB",
    char: "👩🏾‍❤️‍👩🏻",
    name: "couple with heart: woman, woman, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F469 1F3FB",
    char: "👩🏾‍❤‍👩🏻",
    name: "couple with heart: woman, woman, medium-dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FC",
    char: "👩🏾‍❤️‍👩🏼",
    name: "couple with heart: woman, woman, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F469 1F3FC",
    char: "👩🏾‍❤‍👩🏼",
    name: "couple with heart: woman, woman, medium-dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FD",
    char: "👩🏾‍❤️‍👩🏽",
    name: "couple with heart: woman, woman, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F469 1F3FD",
    char: "👩🏾‍❤‍👩🏽",
    name: "couple with heart: woman, woman, medium-dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FE",
    char: "👩🏾‍❤️‍👩🏾",
    name: "couple with heart: woman, woman, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F469 1F3FE",
    char: "👩🏾‍❤‍👩🏾",
    name: "couple with heart: woman, woman, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 FE0F 200D 1F469 1F3FF",
    char: "👩🏾‍❤️‍👩🏿",
    name: "couple with heart: woman, woman, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FE 200D 2764 200D 1F469 1F3FF",
    char: "👩🏾‍❤‍👩🏿",
    name: "couple with heart: woman, woman, medium-dark skin tone, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FB",
    char: "👩🏿‍❤️‍👩🏻",
    name: "couple with heart: woman, woman, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F469 1F3FB",
    char: "👩🏿‍❤‍👩🏻",
    name: "couple with heart: woman, woman, dark skin tone, light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FC",
    char: "👩🏿‍❤️‍👩🏼",
    name: "couple with heart: woman, woman, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F469 1F3FC",
    char: "👩🏿‍❤‍👩🏼",
    name: "couple with heart: woman, woman, dark skin tone, medium-light skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FD",
    char: "👩🏿‍❤️‍👩🏽",
    name: "couple with heart: woman, woman, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F469 1F3FD",
    char: "👩🏿‍❤‍👩🏽",
    name: "couple with heart: woman, woman, dark skin tone, medium skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FE",
    char: "👩🏿‍❤️‍👩🏾",
    name: "couple with heart: woman, woman, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F469 1F3FE",
    char: "👩🏿‍❤‍👩🏾",
    name: "couple with heart: woman, woman, dark skin tone, medium-dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 FE0F 200D 1F469 1F3FF",
    char: "👩🏿‍❤️‍👩🏿",
    name: "couple with heart: woman, woman, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 1F3FF 200D 2764 200D 1F469 1F3FF",
    char: "👩🏿‍❤‍👩🏿",
    name: "couple with heart: woman, woman, dark skin tone",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F469 200D 1F466",
    char: "👨‍👩‍👦",
    name: "family: man, woman, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F469 200D 1F467",
    char: "👨‍👩‍👧",
    name: "family: man, woman, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F469 200D 1F467 200D 1F466",
    char: "👨‍👩‍👧‍👦",
    name: "family: man, woman, girl, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F469 200D 1F466 200D 1F466",
    char: "👨‍👩‍👦‍👦",
    name: "family: man, woman, boy, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F469 200D 1F467 200D 1F467",
    char: "👨‍👩‍👧‍👧",
    name: "family: man, woman, girl, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F468 200D 1F466",
    char: "👨‍👨‍👦",
    name: "family: man, man, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F468 200D 1F467",
    char: "👨‍👨‍👧",
    name: "family: man, man, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F468 200D 1F467 200D 1F466",
    char: "👨‍👨‍👧‍👦",
    name: "family: man, man, girl, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F468 200D 1F466 200D 1F466",
    char: "👨‍👨‍👦‍👦",
    name: "family: man, man, boy, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F468 200D 1F467 200D 1F467",
    char: "👨‍👨‍👧‍👧",
    name: "family: man, man, girl, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F469 200D 1F466",
    char: "👩‍👩‍👦",
    name: "family: woman, woman, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F469 200D 1F467",
    char: "👩‍👩‍👧",
    name: "family: woman, woman, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F469 200D 1F467 200D 1F466",
    char: "👩‍👩‍👧‍👦",
    name: "family: woman, woman, girl, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F469 200D 1F466 200D 1F466",
    char: "👩‍👩‍👦‍👦",
    name: "family: woman, woman, boy, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F469 200D 1F467 200D 1F467",
    char: "👩‍👩‍👧‍👧",
    name: "family: woman, woman, girl, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F466",
    char: "👨‍👦",
    name: "family: man, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F466 200D 1F466",
    char: "👨‍👦‍👦",
    name: "family: man, boy, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F467",
    char: "👨‍👧",
    name: "family: man, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F467 200D 1F466",
    char: "👨‍👧‍👦",
    name: "family: man, girl, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F468 200D 1F467 200D 1F467",
    char: "👨‍👧‍👧",
    name: "family: man, girl, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F466",
    char: "👩‍👦",
    name: "family: woman, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F466 200D 1F466",
    char: "👩‍👦‍👦",
    name: "family: woman, boy, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F467",
    char: "👩‍👧",
    name: "family: woman, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F467 200D 1F466",
    char: "👩‍👧‍👦",
    name: "family: woman, girl, boy",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F469 200D 1F467 200D 1F467",
    char: "👩‍👧‍👧",
    name: "family: woman, girl, girl",
    category: "People & Body (family)",
    group: "People & Body",
    subgroup: "family",
  },
  {
    codes: "1F5E3 FE0F",
    char: "🗣️",
    name: "speaking head",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F5E3",
    char: "🗣",
    name: "speaking head",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F464",
    char: "👤",
    name: "bust in silhouette",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F465",
    char: "👥",
    name: "busts in silhouette",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1FAC2",
    char: "🫂",
    name: "people hugging",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F46A",
    char: "👪",
    name: "family",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F9D1 200D 1F9D1 200D 1F9D2",
    char: "🧑‍🧑‍🧒",
    name: "family: adult, adult, child",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F9D1 200D 1F9D1 200D 1F9D2 200D 1F9D2",
    char: "🧑‍🧑‍🧒‍🧒",
    name: "family: adult, adult, child, child",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F9D1 200D 1F9D2",
    char: "🧑‍🧒",
    name: "family: adult, child",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F9D1 200D 1F9D2 200D 1F9D2",
    char: "🧑‍🧒‍🧒",
    name: "family: adult, child, child",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F463",
    char: "👣",
    name: "footprints",
    category: "People & Body (person-symbol)",
    group: "People & Body",
    subgroup: "person-symbol",
  },
  {
    codes: "1F3FB",
    char: "🏻",
    name: "light skin tone",
    category: "Component (skin-tone)",
    group: "Component",
    subgroup: "skin-tone",
  },
  {
    codes: "1F3FC",
    char: "🏼",
    name: "medium-light skin tone",
    category: "Component (skin-tone)",
    group: "Component",
    subgroup: "skin-tone",
  },
  {
    codes: "1F3FD",
    char: "🏽",
    name: "medium skin tone",
    category: "Component (skin-tone)",
    group: "Component",
    subgroup: "skin-tone",
  },
  {
    codes: "1F3FE",
    char: "🏾",
    name: "medium-dark skin tone",
    category: "Component (skin-tone)",
    group: "Component",
    subgroup: "skin-tone",
  },
  {
    codes: "1F3FF",
    char: "🏿",
    name: "dark skin tone",
    category: "Component (skin-tone)",
    group: "Component",
    subgroup: "skin-tone",
  },
  {
    codes: "1F9B0",
    char: "🦰",
    name: "red hair",
    category: "Component (hair-style)",
    group: "Component",
    subgroup: "hair-style",
  },
  {
    codes: "1F9B1",
    char: "🦱",
    name: "curly hair",
    category: "Component (hair-style)",
    group: "Component",
    subgroup: "hair-style",
  },
  {
    codes: "1F9B3",
    char: "🦳",
    name: "white hair",
    category: "Component (hair-style)",
    group: "Component",
    subgroup: "hair-style",
  },
  {
    codes: "1F9B2",
    char: "🦲",
    name: "bald",
    category: "Component (hair-style)",
    group: "Component",
    subgroup: "hair-style",
  },
  {
    codes: "1F435",
    char: "🐵",
    name: "monkey face",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F412",
    char: "🐒",
    name: "monkey",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F98D",
    char: "🦍",
    name: "gorilla",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F9A7",
    char: "🦧",
    name: "orangutan",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F436",
    char: "🐶",
    name: "dog face",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F415",
    char: "🐕",
    name: "dog",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F9AE",
    char: "🦮",
    name: "guide dog",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F415 200D 1F9BA",
    char: "🐕‍🦺",
    name: "service dog",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F429",
    char: "🐩",
    name: "poodle",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F43A",
    char: "🐺",
    name: "wolf",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F98A",
    char: "🦊",
    name: "fox",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F99D",
    char: "🦝",
    name: "raccoon",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F431",
    char: "🐱",
    name: "cat face",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F408",
    char: "🐈",
    name: "cat",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F408 200D 2B1B",
    char: "🐈‍⬛",
    name: "black cat",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F981",
    char: "🦁",
    name: "lion",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F42F",
    char: "🐯",
    name: "tiger face",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F405",
    char: "🐅",
    name: "tiger",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F406",
    char: "🐆",
    name: "leopard",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F434",
    char: "🐴",
    name: "horse face",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1FACE",
    char: "🫎",
    name: "moose",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1FACF",
    char: "🫏",
    name: "donkey",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F40E",
    char: "🐎",
    name: "horse",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F984",
    char: "🦄",
    name: "unicorn",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F993",
    char: "🦓",
    name: "zebra",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F98C",
    char: "🦌",
    name: "deer",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F9AC",
    char: "🦬",
    name: "bison",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F42E",
    char: "🐮",
    name: "cow face",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F402",
    char: "🐂",
    name: "ox",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F403",
    char: "🐃",
    name: "water buffalo",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F404",
    char: "🐄",
    name: "cow",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F437",
    char: "🐷",
    name: "pig face",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F416",
    char: "🐖",
    name: "pig",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F417",
    char: "🐗",
    name: "boar",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F43D",
    char: "🐽",
    name: "pig nose",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F40F",
    char: "🐏",
    name: "ram",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F411",
    char: "🐑",
    name: "ewe",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F410",
    char: "🐐",
    name: "goat",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F42A",
    char: "🐪",
    name: "camel",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F42B",
    char: "🐫",
    name: "two-hump camel",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F999",
    char: "🦙",
    name: "llama",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F992",
    char: "🦒",
    name: "giraffe",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F418",
    char: "🐘",
    name: "elephant",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F9A3",
    char: "🦣",
    name: "mammoth",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F98F",
    char: "🦏",
    name: "rhinoceros",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F99B",
    char: "🦛",
    name: "hippopotamus",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F42D",
    char: "🐭",
    name: "mouse face",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F401",
    char: "🐁",
    name: "mouse",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F400",
    char: "🐀",
    name: "rat",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F439",
    char: "🐹",
    name: "hamster",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F430",
    char: "🐰",
    name: "rabbit face",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F407",
    char: "🐇",
    name: "rabbit",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F43F FE0F",
    char: "🐿️",
    name: "chipmunk",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F43F",
    char: "🐿",
    name: "chipmunk",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F9AB",
    char: "🦫",
    name: "beaver",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F994",
    char: "🦔",
    name: "hedgehog",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F987",
    char: "🦇",
    name: "bat",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F43B",
    char: "🐻",
    name: "bear",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F43B 200D 2744 FE0F",
    char: "🐻‍❄️",
    name: "polar bear",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F43B 200D 2744",
    char: "🐻‍❄",
    name: "polar bear",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F428",
    char: "🐨",
    name: "koala",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F43C",
    char: "🐼",
    name: "panda",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F9A5",
    char: "🦥",
    name: "sloth",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F9A6",
    char: "🦦",
    name: "otter",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F9A8",
    char: "🦨",
    name: "skunk",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F998",
    char: "🦘",
    name: "kangaroo",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F9A1",
    char: "🦡",
    name: "badger",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F43E",
    char: "🐾",
    name: "paw prints",
    category: "Animals & Nature (animal-mammal)",
    group: "Animals & Nature",
    subgroup: "animal-mammal",
  },
  {
    codes: "1F983",
    char: "🦃",
    name: "turkey",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F414",
    char: "🐔",
    name: "chicken",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F413",
    char: "🐓",
    name: "rooster",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F423",
    char: "🐣",
    name: "hatching chick",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F424",
    char: "🐤",
    name: "baby chick",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F425",
    char: "🐥",
    name: "front-facing baby chick",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F426",
    char: "🐦",
    name: "bird",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F427",
    char: "🐧",
    name: "penguin",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F54A FE0F",
    char: "🕊️",
    name: "dove",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F54A",
    char: "🕊",
    name: "dove",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F985",
    char: "🦅",
    name: "eagle",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F986",
    char: "🦆",
    name: "duck",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F9A2",
    char: "🦢",
    name: "swan",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F989",
    char: "🦉",
    name: "owl",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F9A4",
    char: "🦤",
    name: "dodo",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1FAB6",
    char: "🪶",
    name: "feather",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F9A9",
    char: "🦩",
    name: "flamingo",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F99A",
    char: "🦚",
    name: "peacock",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F99C",
    char: "🦜",
    name: "parrot",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1FABD",
    char: "🪽",
    name: "wing",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F426 200D 2B1B",
    char: "🐦‍⬛",
    name: "black bird",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1FABF",
    char: "🪿",
    name: "goose",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F426 200D 1F525",
    char: "🐦‍🔥",
    name: "phoenix",
    category: "Animals & Nature (animal-bird)",
    group: "Animals & Nature",
    subgroup: "animal-bird",
  },
  {
    codes: "1F438",
    char: "🐸",
    name: "frog",
    category: "Animals & Nature (animal-amphibian)",
    group: "Animals & Nature",
    subgroup: "animal-amphibian",
  },
  {
    codes: "1F40A",
    char: "🐊",
    name: "crocodile",
    category: "Animals & Nature (animal-reptile)",
    group: "Animals & Nature",
    subgroup: "animal-reptile",
  },
  {
    codes: "1F422",
    char: "🐢",
    name: "turtle",
    category: "Animals & Nature (animal-reptile)",
    group: "Animals & Nature",
    subgroup: "animal-reptile",
  },
  {
    codes: "1F98E",
    char: "🦎",
    name: "lizard",
    category: "Animals & Nature (animal-reptile)",
    group: "Animals & Nature",
    subgroup: "animal-reptile",
  },
  {
    codes: "1F40D",
    char: "🐍",
    name: "snake",
    category: "Animals & Nature (animal-reptile)",
    group: "Animals & Nature",
    subgroup: "animal-reptile",
  },
  {
    codes: "1F432",
    char: "🐲",
    name: "dragon face",
    category: "Animals & Nature (animal-reptile)",
    group: "Animals & Nature",
    subgroup: "animal-reptile",
  },
  {
    codes: "1F409",
    char: "🐉",
    name: "dragon",
    category: "Animals & Nature (animal-reptile)",
    group: "Animals & Nature",
    subgroup: "animal-reptile",
  },
  {
    codes: "1F995",
    char: "🦕",
    name: "sauropod",
    category: "Animals & Nature (animal-reptile)",
    group: "Animals & Nature",
    subgroup: "animal-reptile",
  },
  {
    codes: "1F996",
    char: "🦖",
    name: "T-Rex",
    category: "Animals & Nature (animal-reptile)",
    group: "Animals & Nature",
    subgroup: "animal-reptile",
  },
  {
    codes: "1F433",
    char: "🐳",
    name: "spouting whale",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F40B",
    char: "🐋",
    name: "whale",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F42C",
    char: "🐬",
    name: "dolphin",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F9AD",
    char: "🦭",
    name: "seal",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F41F",
    char: "🐟",
    name: "fish",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F420",
    char: "🐠",
    name: "tropical fish",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F421",
    char: "🐡",
    name: "blowfish",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F988",
    char: "🦈",
    name: "shark",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F419",
    char: "🐙",
    name: "octopus",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F41A",
    char: "🐚",
    name: "spiral shell",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1FAB8",
    char: "🪸",
    name: "coral",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1FABC",
    char: "🪼",
    name: "jellyfish",
    category: "Animals & Nature (animal-marine)",
    group: "Animals & Nature",
    subgroup: "animal-marine",
  },
  {
    codes: "1F40C",
    char: "🐌",
    name: "snail",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F98B",
    char: "🦋",
    name: "butterfly",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F41B",
    char: "🐛",
    name: "bug",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F41C",
    char: "🐜",
    name: "ant",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F41D",
    char: "🐝",
    name: "honeybee",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1FAB2",
    char: "🪲",
    name: "beetle",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F41E",
    char: "🐞",
    name: "lady beetle",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F997",
    char: "🦗",
    name: "cricket",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1FAB3",
    char: "🪳",
    name: "cockroach",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F577 FE0F",
    char: "🕷️",
    name: "spider",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F577",
    char: "🕷",
    name: "spider",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F578 FE0F",
    char: "🕸️",
    name: "spider web",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F578",
    char: "🕸",
    name: "spider web",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F982",
    char: "🦂",
    name: "scorpion",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F99F",
    char: "🦟",
    name: "mosquito",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1FAB0",
    char: "🪰",
    name: "fly",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1FAB1",
    char: "🪱",
    name: "worm",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F9A0",
    char: "🦠",
    name: "microbe",
    category: "Animals & Nature (animal-bug)",
    group: "Animals & Nature",
    subgroup: "animal-bug",
  },
  {
    codes: "1F490",
    char: "💐",
    name: "bouquet",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F338",
    char: "🌸",
    name: "cherry blossom",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F4AE",
    char: "💮",
    name: "white flower",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1FAB7",
    char: "🪷",
    name: "lotus",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F3F5 FE0F",
    char: "🏵️",
    name: "rosette",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F3F5",
    char: "🏵",
    name: "rosette",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F339",
    char: "🌹",
    name: "rose",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F940",
    char: "🥀",
    name: "wilted flower",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F33A",
    char: "🌺",
    name: "hibiscus",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F33B",
    char: "🌻",
    name: "sunflower",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F33C",
    char: "🌼",
    name: "blossom",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F337",
    char: "🌷",
    name: "tulip",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1FABB",
    char: "🪻",
    name: "hyacinth",
    category: "Animals & Nature (plant-flower)",
    group: "Animals & Nature",
    subgroup: "plant-flower",
  },
  {
    codes: "1F331",
    char: "🌱",
    name: "seedling",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1FAB4",
    char: "🪴",
    name: "potted plant",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F332",
    char: "🌲",
    name: "evergreen tree",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F333",
    char: "🌳",
    name: "deciduous tree",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F334",
    char: "🌴",
    name: "palm tree",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F335",
    char: "🌵",
    name: "cactus",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F33E",
    char: "🌾",
    name: "sheaf of rice",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F33F",
    char: "🌿",
    name: "herb",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "2618 FE0F",
    char: "☘️",
    name: "shamrock",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "2618",
    char: "☘",
    name: "shamrock",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F340",
    char: "🍀",
    name: "four leaf clover",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F341",
    char: "🍁",
    name: "maple leaf",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F342",
    char: "🍂",
    name: "fallen leaf",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F343",
    char: "🍃",
    name: "leaf fluttering in wind",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1FAB9",
    char: "🪹",
    name: "empty nest",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1FABA",
    char: "🪺",
    name: "nest with eggs",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F344",
    char: "🍄",
    name: "mushroom",
    category: "Animals & Nature (plant-other)",
    group: "Animals & Nature",
    subgroup: "plant-other",
  },
  {
    codes: "1F347",
    char: "🍇",
    name: "grapes",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F348",
    char: "🍈",
    name: "melon",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F349",
    char: "🍉",
    name: "watermelon",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F34A",
    char: "🍊",
    name: "tangerine",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F34B",
    char: "🍋",
    name: "lemon",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F34B 200D 1F7E9",
    char: "🍋‍🟩",
    name: "lime",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F34C",
    char: "🍌",
    name: "banana",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F34D",
    char: "🍍",
    name: "pineapple",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F96D",
    char: "🥭",
    name: "mango",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F34E",
    char: "🍎",
    name: "red apple",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F34F",
    char: "🍏",
    name: "green apple",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F350",
    char: "🍐",
    name: "pear",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F351",
    char: "🍑",
    name: "peach",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F352",
    char: "🍒",
    name: "cherries",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F353",
    char: "🍓",
    name: "strawberry",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1FAD0",
    char: "🫐",
    name: "blueberries",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F95D",
    char: "🥝",
    name: "kiwi fruit",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F345",
    char: "🍅",
    name: "tomato",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1FAD2",
    char: "🫒",
    name: "olive",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F965",
    char: "🥥",
    name: "coconut",
    category: "Food & Drink (food-fruit)",
    group: "Food & Drink",
    subgroup: "food-fruit",
  },
  {
    codes: "1F951",
    char: "🥑",
    name: "avocado",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F346",
    char: "🍆",
    name: "eggplant",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F954",
    char: "🥔",
    name: "potato",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F955",
    char: "🥕",
    name: "carrot",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F33D",
    char: "🌽",
    name: "ear of corn",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F336 FE0F",
    char: "🌶️",
    name: "hot pepper",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F336",
    char: "🌶",
    name: "hot pepper",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1FAD1",
    char: "🫑",
    name: "bell pepper",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F952",
    char: "🥒",
    name: "cucumber",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F96C",
    char: "🥬",
    name: "leafy green",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F966",
    char: "🥦",
    name: "broccoli",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F9C4",
    char: "🧄",
    name: "garlic",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F9C5",
    char: "🧅",
    name: "onion",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F95C",
    char: "🥜",
    name: "peanuts",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1FAD8",
    char: "🫘",
    name: "beans",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F330",
    char: "🌰",
    name: "chestnut",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1FADA",
    char: "🫚",
    name: "ginger root",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1FADB",
    char: "🫛",
    name: "pea pod",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F344 200D 1F7EB",
    char: "🍄‍🟫",
    name: "brown mushroom",
    category: "Food & Drink (food-vegetable)",
    group: "Food & Drink",
    subgroup: "food-vegetable",
  },
  {
    codes: "1F35E",
    char: "🍞",
    name: "bread",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F950",
    char: "🥐",
    name: "croissant",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F956",
    char: "🥖",
    name: "baguette bread",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1FAD3",
    char: "🫓",
    name: "flatbread",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F968",
    char: "🥨",
    name: "pretzel",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F96F",
    char: "🥯",
    name: "bagel",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F95E",
    char: "🥞",
    name: "pancakes",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F9C7",
    char: "🧇",
    name: "waffle",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F9C0",
    char: "🧀",
    name: "cheese wedge",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F356",
    char: "🍖",
    name: "meat on bone",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F357",
    char: "🍗",
    name: "poultry leg",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F969",
    char: "🥩",
    name: "cut of meat",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F953",
    char: "🥓",
    name: "bacon",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F354",
    char: "🍔",
    name: "hamburger",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F35F",
    char: "🍟",
    name: "french fries",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F355",
    char: "🍕",
    name: "pizza",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F32D",
    char: "🌭",
    name: "hot dog",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F96A",
    char: "🥪",
    name: "sandwich",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F32E",
    char: "🌮",
    name: "taco",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F32F",
    char: "🌯",
    name: "burrito",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1FAD4",
    char: "🫔",
    name: "tamale",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F959",
    char: "🥙",
    name: "stuffed flatbread",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F9C6",
    char: "🧆",
    name: "falafel",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F95A",
    char: "🥚",
    name: "egg",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F373",
    char: "🍳",
    name: "cooking",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F958",
    char: "🥘",
    name: "shallow pan of food",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F372",
    char: "🍲",
    name: "pot of food",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1FAD5",
    char: "🫕",
    name: "fondue",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F963",
    char: "🥣",
    name: "bowl with spoon",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F957",
    char: "🥗",
    name: "green salad",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F37F",
    char: "🍿",
    name: "popcorn",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F9C8",
    char: "🧈",
    name: "butter",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F9C2",
    char: "🧂",
    name: "salt",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F96B",
    char: "🥫",
    name: "canned food",
    category: "Food & Drink (food-prepared)",
    group: "Food & Drink",
    subgroup: "food-prepared",
  },
  {
    codes: "1F371",
    char: "🍱",
    name: "bento box",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F358",
    char: "🍘",
    name: "rice cracker",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F359",
    char: "🍙",
    name: "rice ball",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F35A",
    char: "🍚",
    name: "cooked rice",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F35B",
    char: "🍛",
    name: "curry rice",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F35C",
    char: "🍜",
    name: "steaming bowl",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F35D",
    char: "🍝",
    name: "spaghetti",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F360",
    char: "🍠",
    name: "roasted sweet potato",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F362",
    char: "🍢",
    name: "oden",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F363",
    char: "🍣",
    name: "sushi",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F364",
    char: "🍤",
    name: "fried shrimp",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F365",
    char: "🍥",
    name: "fish cake with swirl",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F96E",
    char: "🥮",
    name: "moon cake",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F361",
    char: "🍡",
    name: "dango",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F95F",
    char: "🥟",
    name: "dumpling",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F960",
    char: "🥠",
    name: "fortune cookie",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F961",
    char: "🥡",
    name: "takeout box",
    category: "Food & Drink (food-asian)",
    group: "Food & Drink",
    subgroup: "food-asian",
  },
  {
    codes: "1F980",
    char: "🦀",
    name: "crab",
    category: "Food & Drink (food-marine)",
    group: "Food & Drink",
    subgroup: "food-marine",
  },
  {
    codes: "1F99E",
    char: "🦞",
    name: "lobster",
    category: "Food & Drink (food-marine)",
    group: "Food & Drink",
    subgroup: "food-marine",
  },
  {
    codes: "1F990",
    char: "🦐",
    name: "shrimp",
    category: "Food & Drink (food-marine)",
    group: "Food & Drink",
    subgroup: "food-marine",
  },
  {
    codes: "1F991",
    char: "🦑",
    name: "squid",
    category: "Food & Drink (food-marine)",
    group: "Food & Drink",
    subgroup: "food-marine",
  },
  {
    codes: "1F9AA",
    char: "🦪",
    name: "oyster",
    category: "Food & Drink (food-marine)",
    group: "Food & Drink",
    subgroup: "food-marine",
  },
  {
    codes: "1F366",
    char: "🍦",
    name: "soft ice cream",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F367",
    char: "🍧",
    name: "shaved ice",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F368",
    char: "🍨",
    name: "ice cream",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F369",
    char: "🍩",
    name: "doughnut",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F36A",
    char: "🍪",
    name: "cookie",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F382",
    char: "🎂",
    name: "birthday cake",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F370",
    char: "🍰",
    name: "shortcake",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F9C1",
    char: "🧁",
    name: "cupcake",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F967",
    char: "🥧",
    name: "pie",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F36B",
    char: "🍫",
    name: "chocolate bar",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F36C",
    char: "🍬",
    name: "candy",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F36D",
    char: "🍭",
    name: "lollipop",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F36E",
    char: "🍮",
    name: "custard",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F36F",
    char: "🍯",
    name: "honey pot",
    category: "Food & Drink (food-sweet)",
    group: "Food & Drink",
    subgroup: "food-sweet",
  },
  {
    codes: "1F37C",
    char: "🍼",
    name: "baby bottle",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F95B",
    char: "🥛",
    name: "glass of milk",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "2615",
    char: "☕",
    name: "hot beverage",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1FAD6",
    char: "🫖",
    name: "teapot",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F375",
    char: "🍵",
    name: "teacup without handle",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F376",
    char: "🍶",
    name: "sake",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F37E",
    char: "🍾",
    name: "bottle with popping cork",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F377",
    char: "🍷",
    name: "wine glass",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F378",
    char: "🍸",
    name: "cocktail glass",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F379",
    char: "🍹",
    name: "tropical drink",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F37A",
    char: "🍺",
    name: "beer mug",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F37B",
    char: "🍻",
    name: "clinking beer mugs",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F942",
    char: "🥂",
    name: "clinking glasses",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F943",
    char: "🥃",
    name: "tumbler glass",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1FAD7",
    char: "🫗",
    name: "pouring liquid",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F964",
    char: "🥤",
    name: "cup with straw",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F9CB",
    char: "🧋",
    name: "bubble tea",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F9C3",
    char: "🧃",
    name: "beverage box",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F9C9",
    char: "🧉",
    name: "mate",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F9CA",
    char: "🧊",
    name: "ice",
    category: "Food & Drink (drink)",
    group: "Food & Drink",
    subgroup: "drink",
  },
  {
    codes: "1F962",
    char: "🥢",
    name: "chopsticks",
    category: "Food & Drink (dishware)",
    group: "Food & Drink",
    subgroup: "dishware",
  },
  {
    codes: "1F37D FE0F",
    char: "🍽️",
    name: "fork and knife with plate",
    category: "Food & Drink (dishware)",
    group: "Food & Drink",
    subgroup: "dishware",
  },
  {
    codes: "1F37D",
    char: "🍽",
    name: "fork and knife with plate",
    category: "Food & Drink (dishware)",
    group: "Food & Drink",
    subgroup: "dishware",
  },
  {
    codes: "1F374",
    char: "🍴",
    name: "fork and knife",
    category: "Food & Drink (dishware)",
    group: "Food & Drink",
    subgroup: "dishware",
  },
  {
    codes: "1F944",
    char: "🥄",
    name: "spoon",
    category: "Food & Drink (dishware)",
    group: "Food & Drink",
    subgroup: "dishware",
  },
  {
    codes: "1F52A",
    char: "🔪",
    name: "kitchen knife",
    category: "Food & Drink (dishware)",
    group: "Food & Drink",
    subgroup: "dishware",
  },
  {
    codes: "1FAD9",
    char: "🫙",
    name: "jar",
    category: "Food & Drink (dishware)",
    group: "Food & Drink",
    subgroup: "dishware",
  },
  {
    codes: "1F3FA",
    char: "🏺",
    name: "amphora",
    category: "Food & Drink (dishware)",
    group: "Food & Drink",
    subgroup: "dishware",
  },
  {
    codes: "1F30D",
    char: "🌍",
    name: "globe showing Europe-Africa",
    category: "Travel & Places (place-map)",
    group: "Travel & Places",
    subgroup: "place-map",
  },
  {
    codes: "1F30E",
    char: "🌎",
    name: "globe showing Americas",
    category: "Travel & Places (place-map)",
    group: "Travel & Places",
    subgroup: "place-map",
  },
  {
    codes: "1F30F",
    char: "🌏",
    name: "globe showing Asia-Australia",
    category: "Travel & Places (place-map)",
    group: "Travel & Places",
    subgroup: "place-map",
  },
  {
    codes: "1F310",
    char: "🌐",
    name: "globe with meridians",
    category: "Travel & Places (place-map)",
    group: "Travel & Places",
    subgroup: "place-map",
  },
  {
    codes: "1F5FA FE0F",
    char: "🗺️",
    name: "world map",
    category: "Travel & Places (place-map)",
    group: "Travel & Places",
    subgroup: "place-map",
  },
  {
    codes: "1F5FA",
    char: "🗺",
    name: "world map",
    category: "Travel & Places (place-map)",
    group: "Travel & Places",
    subgroup: "place-map",
  },
  {
    codes: "1F5FE",
    char: "🗾",
    name: "map of Japan",
    category: "Travel & Places (place-map)",
    group: "Travel & Places",
    subgroup: "place-map",
  },
  {
    codes: "1F9ED",
    char: "🧭",
    name: "compass",
    category: "Travel & Places (place-map)",
    group: "Travel & Places",
    subgroup: "place-map",
  },
  {
    codes: "1F3D4 FE0F",
    char: "🏔️",
    name: "snow-capped mountain",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3D4",
    char: "🏔",
    name: "snow-capped mountain",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "26F0 FE0F",
    char: "⛰️",
    name: "mountain",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "26F0",
    char: "⛰",
    name: "mountain",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F30B",
    char: "🌋",
    name: "volcano",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F5FB",
    char: "🗻",
    name: "mount fuji",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3D5 FE0F",
    char: "🏕️",
    name: "camping",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3D5",
    char: "🏕",
    name: "camping",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3D6 FE0F",
    char: "🏖️",
    name: "beach with umbrella",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3D6",
    char: "🏖",
    name: "beach with umbrella",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3DC FE0F",
    char: "🏜️",
    name: "desert",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3DC",
    char: "🏜",
    name: "desert",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3DD FE0F",
    char: "🏝️",
    name: "desert island",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3DD",
    char: "🏝",
    name: "desert island",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3DE FE0F",
    char: "🏞️",
    name: "national park",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3DE",
    char: "🏞",
    name: "national park",
    category: "Travel & Places (place-geographic)",
    group: "Travel & Places",
    subgroup: "place-geographic",
  },
  {
    codes: "1F3DF FE0F",
    char: "🏟️",
    name: "stadium",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3DF",
    char: "🏟",
    name: "stadium",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3DB FE0F",
    char: "🏛️",
    name: "classical building",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3DB",
    char: "🏛",
    name: "classical building",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3D7 FE0F",
    char: "🏗️",
    name: "building construction",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3D7",
    char: "🏗",
    name: "building construction",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F9F1",
    char: "🧱",
    name: "brick",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1FAA8",
    char: "🪨",
    name: "rock",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1FAB5",
    char: "🪵",
    name: "wood",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F6D6",
    char: "🛖",
    name: "hut",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3D8 FE0F",
    char: "🏘️",
    name: "houses",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3D8",
    char: "🏘",
    name: "houses",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3DA FE0F",
    char: "🏚️",
    name: "derelict house",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3DA",
    char: "🏚",
    name: "derelict house",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3E0",
    char: "🏠",
    name: "house",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3E1",
    char: "🏡",
    name: "house with garden",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3E2",
    char: "🏢",
    name: "office building",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3E3",
    char: "🏣",
    name: "Japanese post office",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3E4",
    char: "🏤",
    name: "post office",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3E5",
    char: "🏥",
    name: "hospital",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3E6",
    char: "🏦",
    name: "bank",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3E8",
    char: "🏨",
    name: "hotel",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3E9",
    char: "🏩",
    name: "love hotel",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3EA",
    char: "🏪",
    name: "convenience store",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3EB",
    char: "🏫",
    name: "school",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3EC",
    char: "🏬",
    name: "department store",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3ED",
    char: "🏭",
    name: "factory",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3EF",
    char: "🏯",
    name: "Japanese castle",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F3F0",
    char: "🏰",
    name: "castle",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F492",
    char: "💒",
    name: "wedding",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F5FC",
    char: "🗼",
    name: "Tokyo tower",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "1F5FD",
    char: "🗽",
    name: "Statue of Liberty",
    category: "Travel & Places (place-building)",
    group: "Travel & Places",
    subgroup: "place-building",
  },
  {
    codes: "26EA",
    char: "⛪",
    name: "church",
    category: "Travel & Places (place-religious)",
    group: "Travel & Places",
    subgroup: "place-religious",
  },
  {
    codes: "1F54C",
    char: "🕌",
    name: "mosque",
    category: "Travel & Places (place-religious)",
    group: "Travel & Places",
    subgroup: "place-religious",
  },
  {
    codes: "1F6D5",
    char: "🛕",
    name: "hindu temple",
    category: "Travel & Places (place-religious)",
    group: "Travel & Places",
    subgroup: "place-religious",
  },
  {
    codes: "1F54D",
    char: "🕍",
    name: "synagogue",
    category: "Travel & Places (place-religious)",
    group: "Travel & Places",
    subgroup: "place-religious",
  },
  {
    codes: "26E9 FE0F",
    char: "⛩️",
    name: "shinto shrine",
    category: "Travel & Places (place-religious)",
    group: "Travel & Places",
    subgroup: "place-religious",
  },
  {
    codes: "26E9",
    char: "⛩",
    name: "shinto shrine",
    category: "Travel & Places (place-religious)",
    group: "Travel & Places",
    subgroup: "place-religious",
  },
  {
    codes: "1F54B",
    char: "🕋",
    name: "kaaba",
    category: "Travel & Places (place-religious)",
    group: "Travel & Places",
    subgroup: "place-religious",
  },
  {
    codes: "26F2",
    char: "⛲",
    name: "fountain",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "26FA",
    char: "⛺",
    name: "tent",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F301",
    char: "🌁",
    name: "foggy",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F303",
    char: "🌃",
    name: "night with stars",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F3D9 FE0F",
    char: "🏙️",
    name: "cityscape",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F3D9",
    char: "🏙",
    name: "cityscape",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F304",
    char: "🌄",
    name: "sunrise over mountains",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F305",
    char: "🌅",
    name: "sunrise",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F306",
    char: "🌆",
    name: "cityscape at dusk",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F307",
    char: "🌇",
    name: "sunset",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F309",
    char: "🌉",
    name: "bridge at night",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "2668 FE0F",
    char: "♨️",
    name: "hot springs",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "2668",
    char: "♨",
    name: "hot springs",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F3A0",
    char: "🎠",
    name: "carousel horse",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F6DD",
    char: "🛝",
    name: "playground slide",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F3A1",
    char: "🎡",
    name: "ferris wheel",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F3A2",
    char: "🎢",
    name: "roller coaster",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F488",
    char: "💈",
    name: "barber pole",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F3AA",
    char: "🎪",
    name: "circus tent",
    category: "Travel & Places (place-other)",
    group: "Travel & Places",
    subgroup: "place-other",
  },
  {
    codes: "1F682",
    char: "🚂",
    name: "locomotive",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F683",
    char: "🚃",
    name: "railway car",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F684",
    char: "🚄",
    name: "high-speed train",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F685",
    char: "🚅",
    name: "bullet train",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F686",
    char: "🚆",
    name: "train",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F687",
    char: "🚇",
    name: "metro",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F688",
    char: "🚈",
    name: "light rail",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F689",
    char: "🚉",
    name: "station",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F68A",
    char: "🚊",
    name: "tram",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F69D",
    char: "🚝",
    name: "monorail",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F69E",
    char: "🚞",
    name: "mountain railway",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F68B",
    char: "🚋",
    name: "tram car",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F68C",
    char: "🚌",
    name: "bus",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F68D",
    char: "🚍",
    name: "oncoming bus",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F68E",
    char: "🚎",
    name: "trolleybus",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F690",
    char: "🚐",
    name: "minibus",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F691",
    char: "🚑",
    name: "ambulance",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F692",
    char: "🚒",
    name: "fire engine",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F693",
    char: "🚓",
    name: "police car",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F694",
    char: "🚔",
    name: "oncoming police car",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F695",
    char: "🚕",
    name: "taxi",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F696",
    char: "🚖",
    name: "oncoming taxi",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F697",
    char: "🚗",
    name: "automobile",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F698",
    char: "🚘",
    name: "oncoming automobile",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F699",
    char: "🚙",
    name: "sport utility vehicle",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6FB",
    char: "🛻",
    name: "pickup truck",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F69A",
    char: "🚚",
    name: "delivery truck",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F69B",
    char: "🚛",
    name: "articulated lorry",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F69C",
    char: "🚜",
    name: "tractor",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F3CE FE0F",
    char: "🏎️",
    name: "racing car",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F3CE",
    char: "🏎",
    name: "racing car",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F3CD FE0F",
    char: "🏍️",
    name: "motorcycle",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F3CD",
    char: "🏍",
    name: "motorcycle",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6F5",
    char: "🛵",
    name: "motor scooter",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F9BD",
    char: "🦽",
    name: "manual wheelchair",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F9BC",
    char: "🦼",
    name: "motorized wheelchair",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6FA",
    char: "🛺",
    name: "auto rickshaw",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6B2",
    char: "🚲",
    name: "bicycle",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6F4",
    char: "🛴",
    name: "kick scooter",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6F9",
    char: "🛹",
    name: "skateboard",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6FC",
    char: "🛼",
    name: "roller skate",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F68F",
    char: "🚏",
    name: "bus stop",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6E3 FE0F",
    char: "🛣️",
    name: "motorway",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6E3",
    char: "🛣",
    name: "motorway",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6E4 FE0F",
    char: "🛤️",
    name: "railway track",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6E4",
    char: "🛤",
    name: "railway track",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6E2 FE0F",
    char: "🛢️",
    name: "oil drum",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6E2",
    char: "🛢",
    name: "oil drum",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "26FD",
    char: "⛽",
    name: "fuel pump",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6DE",
    char: "🛞",
    name: "wheel",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6A8",
    char: "🚨",
    name: "police car light",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6A5",
    char: "🚥",
    name: "horizontal traffic light",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6A6",
    char: "🚦",
    name: "vertical traffic light",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6D1",
    char: "🛑",
    name: "stop sign",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "1F6A7",
    char: "🚧",
    name: "construction",
    category: "Travel & Places (transport-ground)",
    group: "Travel & Places",
    subgroup: "transport-ground",
  },
  {
    codes: "2693",
    char: "⚓",
    name: "anchor",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "1F6DF",
    char: "🛟",
    name: "ring buoy",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "26F5",
    char: "⛵",
    name: "sailboat",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "1F6F6",
    char: "🛶",
    name: "canoe",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "1F6A4",
    char: "🚤",
    name: "speedboat",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "1F6F3 FE0F",
    char: "🛳️",
    name: "passenger ship",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "1F6F3",
    char: "🛳",
    name: "passenger ship",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "26F4 FE0F",
    char: "⛴️",
    name: "ferry",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "26F4",
    char: "⛴",
    name: "ferry",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "1F6E5 FE0F",
    char: "🛥️",
    name: "motor boat",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "1F6E5",
    char: "🛥",
    name: "motor boat",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "1F6A2",
    char: "🚢",
    name: "ship",
    category: "Travel & Places (transport-water)",
    group: "Travel & Places",
    subgroup: "transport-water",
  },
  {
    codes: "2708 FE0F",
    char: "✈️",
    name: "airplane",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "2708",
    char: "✈",
    name: "airplane",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6E9 FE0F",
    char: "🛩️",
    name: "small airplane",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6E9",
    char: "🛩",
    name: "small airplane",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6EB",
    char: "🛫",
    name: "airplane departure",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6EC",
    char: "🛬",
    name: "airplane arrival",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1FA82",
    char: "🪂",
    name: "parachute",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F4BA",
    char: "💺",
    name: "seat",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F681",
    char: "🚁",
    name: "helicopter",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F69F",
    char: "🚟",
    name: "suspension railway",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6A0",
    char: "🚠",
    name: "mountain cableway",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6A1",
    char: "🚡",
    name: "aerial tramway",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6F0 FE0F",
    char: "🛰️",
    name: "satellite",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6F0",
    char: "🛰",
    name: "satellite",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F680",
    char: "🚀",
    name: "rocket",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6F8",
    char: "🛸",
    name: "flying saucer",
    category: "Travel & Places (transport-air)",
    group: "Travel & Places",
    subgroup: "transport-air",
  },
  {
    codes: "1F6CE FE0F",
    char: "🛎️",
    name: "bellhop bell",
    category: "Travel & Places (hotel)",
    group: "Travel & Places",
    subgroup: "hotel",
  },
  {
    codes: "1F6CE",
    char: "🛎",
    name: "bellhop bell",
    category: "Travel & Places (hotel)",
    group: "Travel & Places",
    subgroup: "hotel",
  },
  {
    codes: "1F9F3",
    char: "🧳",
    name: "luggage",
    category: "Travel & Places (hotel)",
    group: "Travel & Places",
    subgroup: "hotel",
  },
  {
    codes: "231B",
    char: "⌛",
    name: "hourglass done",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "23F3",
    char: "⏳",
    name: "hourglass not done",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "231A",
    char: "⌚",
    name: "watch",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "23F0",
    char: "⏰",
    name: "alarm clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "23F1 FE0F",
    char: "⏱️",
    name: "stopwatch",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "23F1",
    char: "⏱",
    name: "stopwatch",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "23F2 FE0F",
    char: "⏲️",
    name: "timer clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "23F2",
    char: "⏲",
    name: "timer clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F570 FE0F",
    char: "🕰️",
    name: "mantelpiece clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F570",
    char: "🕰",
    name: "mantelpiece clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F55B",
    char: "🕛",
    name: "twelve o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F567",
    char: "🕧",
    name: "twelve-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F550",
    char: "🕐",
    name: "one o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F55C",
    char: "🕜",
    name: "one-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F551",
    char: "🕑",
    name: "two o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F55D",
    char: "🕝",
    name: "two-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F552",
    char: "🕒",
    name: "three o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F55E",
    char: "🕞",
    name: "three-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F553",
    char: "🕓",
    name: "four o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F55F",
    char: "🕟",
    name: "four-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F554",
    char: "🕔",
    name: "five o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F560",
    char: "🕠",
    name: "five-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F555",
    char: "🕕",
    name: "six o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F561",
    char: "🕡",
    name: "six-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F556",
    char: "🕖",
    name: "seven o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F562",
    char: "🕢",
    name: "seven-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F557",
    char: "🕗",
    name: "eight o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F563",
    char: "🕣",
    name: "eight-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F558",
    char: "🕘",
    name: "nine o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F564",
    char: "🕤",
    name: "nine-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F559",
    char: "🕙",
    name: "ten o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F565",
    char: "🕥",
    name: "ten-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F55A",
    char: "🕚",
    name: "eleven o’clock",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F566",
    char: "🕦",
    name: "eleven-thirty",
    category: "Travel & Places (time)",
    group: "Travel & Places",
    subgroup: "time",
  },
  {
    codes: "1F311",
    char: "🌑",
    name: "new moon",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F312",
    char: "🌒",
    name: "waxing crescent moon",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F313",
    char: "🌓",
    name: "first quarter moon",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F314",
    char: "🌔",
    name: "waxing gibbous moon",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F315",
    char: "🌕",
    name: "full moon",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F316",
    char: "🌖",
    name: "waning gibbous moon",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F317",
    char: "🌗",
    name: "last quarter moon",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F318",
    char: "🌘",
    name: "waning crescent moon",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F319",
    char: "🌙",
    name: "crescent moon",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F31A",
    char: "🌚",
    name: "new moon face",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F31B",
    char: "🌛",
    name: "first quarter moon face",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F31C",
    char: "🌜",
    name: "last quarter moon face",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F321 FE0F",
    char: "🌡️",
    name: "thermometer",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F321",
    char: "🌡",
    name: "thermometer",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2600 FE0F",
    char: "☀️",
    name: "sun",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2600",
    char: "☀",
    name: "sun",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F31D",
    char: "🌝",
    name: "full moon face",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F31E",
    char: "🌞",
    name: "sun with face",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1FA90",
    char: "🪐",
    name: "ringed planet",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2B50",
    char: "⭐",
    name: "star",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F31F",
    char: "🌟",
    name: "glowing star",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F320",
    char: "🌠",
    name: "shooting star",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F30C",
    char: "🌌",
    name: "milky way",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2601 FE0F",
    char: "☁️",
    name: "cloud",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2601",
    char: "☁",
    name: "cloud",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "26C5",
    char: "⛅",
    name: "sun behind cloud",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "26C8 FE0F",
    char: "⛈️",
    name: "cloud with lightning and rain",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "26C8",
    char: "⛈",
    name: "cloud with lightning and rain",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F324 FE0F",
    char: "🌤️",
    name: "sun behind small cloud",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F324",
    char: "🌤",
    name: "sun behind small cloud",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F325 FE0F",
    char: "🌥️",
    name: "sun behind large cloud",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F325",
    char: "🌥",
    name: "sun behind large cloud",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F326 FE0F",
    char: "🌦️",
    name: "sun behind rain cloud",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F326",
    char: "🌦",
    name: "sun behind rain cloud",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F327 FE0F",
    char: "🌧️",
    name: "cloud with rain",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F327",
    char: "🌧",
    name: "cloud with rain",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F328 FE0F",
    char: "🌨️",
    name: "cloud with snow",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F328",
    char: "🌨",
    name: "cloud with snow",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F329 FE0F",
    char: "🌩️",
    name: "cloud with lightning",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F329",
    char: "🌩",
    name: "cloud with lightning",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F32A FE0F",
    char: "🌪️",
    name: "tornado",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F32A",
    char: "🌪",
    name: "tornado",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F32B FE0F",
    char: "🌫️",
    name: "fog",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F32B",
    char: "🌫",
    name: "fog",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F32C FE0F",
    char: "🌬️",
    name: "wind face",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F32C",
    char: "🌬",
    name: "wind face",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F300",
    char: "🌀",
    name: "cyclone",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F308",
    char: "🌈",
    name: "rainbow",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F302",
    char: "🌂",
    name: "closed umbrella",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2602 FE0F",
    char: "☂️",
    name: "umbrella",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2602",
    char: "☂",
    name: "umbrella",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2614",
    char: "☔",
    name: "umbrella with rain drops",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "26F1 FE0F",
    char: "⛱️",
    name: "umbrella on ground",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "26F1",
    char: "⛱",
    name: "umbrella on ground",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "26A1",
    char: "⚡",
    name: "high voltage",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2744 FE0F",
    char: "❄️",
    name: "snowflake",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2744",
    char: "❄",
    name: "snowflake",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2603 FE0F",
    char: "☃️",
    name: "snowman",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2603",
    char: "☃",
    name: "snowman",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "26C4",
    char: "⛄",
    name: "snowman without snow",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2604 FE0F",
    char: "☄️",
    name: "comet",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "2604",
    char: "☄",
    name: "comet",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F525",
    char: "🔥",
    name: "fire",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F4A7",
    char: "💧",
    name: "droplet",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F30A",
    char: "🌊",
    name: "water wave",
    category: "Travel & Places (sky & weather)",
    group: "Travel & Places",
    subgroup: "sky & weather",
  },
  {
    codes: "1F383",
    char: "🎃",
    name: "jack-o-lantern",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F384",
    char: "🎄",
    name: "Christmas tree",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F386",
    char: "🎆",
    name: "fireworks",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F387",
    char: "🎇",
    name: "sparkler",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F9E8",
    char: "🧨",
    name: "firecracker",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "2728",
    char: "✨",
    name: "sparkles",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F388",
    char: "🎈",
    name: "balloon",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F389",
    char: "🎉",
    name: "party popper",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F38A",
    char: "🎊",
    name: "confetti ball",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F38B",
    char: "🎋",
    name: "tanabata tree",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F38D",
    char: "🎍",
    name: "pine decoration",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F38E",
    char: "🎎",
    name: "Japanese dolls",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F38F",
    char: "🎏",
    name: "carp streamer",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F390",
    char: "🎐",
    name: "wind chime",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F391",
    char: "🎑",
    name: "moon viewing ceremony",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F9E7",
    char: "🧧",
    name: "red envelope",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F380",
    char: "🎀",
    name: "ribbon",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F381",
    char: "🎁",
    name: "wrapped gift",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F397 FE0F",
    char: "🎗️",
    name: "reminder ribbon",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F397",
    char: "🎗",
    name: "reminder ribbon",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F39F FE0F",
    char: "🎟️",
    name: "admission tickets",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F39F",
    char: "🎟",
    name: "admission tickets",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F3AB",
    char: "🎫",
    name: "ticket",
    category: "Activities (event)",
    group: "Activities",
    subgroup: "event",
  },
  {
    codes: "1F396 FE0F",
    char: "🎖️",
    name: "military medal",
    category: "Activities (award-medal)",
    group: "Activities",
    subgroup: "award-medal",
  },
  {
    codes: "1F396",
    char: "🎖",
    name: "military medal",
    category: "Activities (award-medal)",
    group: "Activities",
    subgroup: "award-medal",
  },
  {
    codes: "1F3C6",
    char: "🏆",
    name: "trophy",
    category: "Activities (award-medal)",
    group: "Activities",
    subgroup: "award-medal",
  },
  {
    codes: "1F3C5",
    char: "🏅",
    name: "sports medal",
    category: "Activities (award-medal)",
    group: "Activities",
    subgroup: "award-medal",
  },
  {
    codes: "1F947",
    char: "🥇",
    name: "1st place medal",
    category: "Activities (award-medal)",
    group: "Activities",
    subgroup: "award-medal",
  },
  {
    codes: "1F948",
    char: "🥈",
    name: "2nd place medal",
    category: "Activities (award-medal)",
    group: "Activities",
    subgroup: "award-medal",
  },
  {
    codes: "1F949",
    char: "🥉",
    name: "3rd place medal",
    category: "Activities (award-medal)",
    group: "Activities",
    subgroup: "award-medal",
  },
  {
    codes: "26BD",
    char: "⚽",
    name: "soccer ball",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "26BE",
    char: "⚾",
    name: "baseball",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F94E",
    char: "🥎",
    name: "softball",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3C0",
    char: "🏀",
    name: "basketball",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3D0",
    char: "🏐",
    name: "volleyball",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3C8",
    char: "🏈",
    name: "american football",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3C9",
    char: "🏉",
    name: "rugby football",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3BE",
    char: "🎾",
    name: "tennis",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F94F",
    char: "🥏",
    name: "flying disc",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3B3",
    char: "🎳",
    name: "bowling",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3CF",
    char: "🏏",
    name: "cricket game",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3D1",
    char: "🏑",
    name: "field hockey",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3D2",
    char: "🏒",
    name: "ice hockey",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F94D",
    char: "🥍",
    name: "lacrosse",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3D3",
    char: "🏓",
    name: "ping pong",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3F8",
    char: "🏸",
    name: "badminton",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F94A",
    char: "🥊",
    name: "boxing glove",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F94B",
    char: "🥋",
    name: "martial arts uniform",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F945",
    char: "🥅",
    name: "goal net",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "26F3",
    char: "⛳",
    name: "flag in hole",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "26F8 FE0F",
    char: "⛸️",
    name: "ice skate",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "26F8",
    char: "⛸",
    name: "ice skate",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3A3",
    char: "🎣",
    name: "fishing pole",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F93F",
    char: "🤿",
    name: "diving mask",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3BD",
    char: "🎽",
    name: "running shirt",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3BF",
    char: "🎿",
    name: "skis",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F6F7",
    char: "🛷",
    name: "sled",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F94C",
    char: "🥌",
    name: "curling stone",
    category: "Activities (sport)",
    group: "Activities",
    subgroup: "sport",
  },
  {
    codes: "1F3AF",
    char: "🎯",
    name: "bullseye",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1FA80",
    char: "🪀",
    name: "yo-yo",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1FA81",
    char: "🪁",
    name: "kite",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F52B",
    char: "🔫",
    name: "water pistol",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F3B1",
    char: "🎱",
    name: "pool 8 ball",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F52E",
    char: "🔮",
    name: "crystal ball",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1FA84",
    char: "🪄",
    name: "magic wand",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F3AE",
    char: "🎮",
    name: "video game",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F579 FE0F",
    char: "🕹️",
    name: "joystick",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F579",
    char: "🕹",
    name: "joystick",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F3B0",
    char: "🎰",
    name: "slot machine",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F3B2",
    char: "🎲",
    name: "game die",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F9E9",
    char: "🧩",
    name: "puzzle piece",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F9F8",
    char: "🧸",
    name: "teddy bear",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1FA85",
    char: "🪅",
    name: "piñata",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1FAA9",
    char: "🪩",
    name: "mirror ball",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1FA86",
    char: "🪆",
    name: "nesting dolls",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "2660 FE0F",
    char: "♠️",
    name: "spade suit",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "2660",
    char: "♠",
    name: "spade suit",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "2665 FE0F",
    char: "♥️",
    name: "heart suit",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "2665",
    char: "♥",
    name: "heart suit",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "2666 FE0F",
    char: "♦️",
    name: "diamond suit",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "2666",
    char: "♦",
    name: "diamond suit",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "2663 FE0F",
    char: "♣️",
    name: "club suit",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "2663",
    char: "♣",
    name: "club suit",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "265F FE0F",
    char: "♟️",
    name: "chess pawn",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "265F",
    char: "♟",
    name: "chess pawn",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F0CF",
    char: "🃏",
    name: "joker",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F004",
    char: "🀄",
    name: "mahjong red dragon",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F3B4",
    char: "🎴",
    name: "flower playing cards",
    category: "Activities (game)",
    group: "Activities",
    subgroup: "game",
  },
  {
    codes: "1F3AD",
    char: "🎭",
    name: "performing arts",
    category: "Activities (arts & crafts)",
    group: "Activities",
    subgroup: "arts & crafts",
  },
  {
    codes: "1F5BC FE0F",
    char: "🖼️",
    name: "framed picture",
    category: "Activities (arts & crafts)",
    group: "Activities",
    subgroup: "arts & crafts",
  },
  {
    codes: "1F5BC",
    char: "🖼",
    name: "framed picture",
    category: "Activities (arts & crafts)",
    group: "Activities",
    subgroup: "arts & crafts",
  },
  {
    codes: "1F3A8",
    char: "🎨",
    name: "artist palette",
    category: "Activities (arts & crafts)",
    group: "Activities",
    subgroup: "arts & crafts",
  },
  {
    codes: "1F9F5",
    char: "🧵",
    name: "thread",
    category: "Activities (arts & crafts)",
    group: "Activities",
    subgroup: "arts & crafts",
  },
  {
    codes: "1FAA1",
    char: "🪡",
    name: "sewing needle",
    category: "Activities (arts & crafts)",
    group: "Activities",
    subgroup: "arts & crafts",
  },
  {
    codes: "1F9F6",
    char: "🧶",
    name: "yarn",
    category: "Activities (arts & crafts)",
    group: "Activities",
    subgroup: "arts & crafts",
  },
  {
    codes: "1FAA2",
    char: "🪢",
    name: "knot",
    category: "Activities (arts & crafts)",
    group: "Activities",
    subgroup: "arts & crafts",
  },
  {
    codes: "1F453",
    char: "👓",
    name: "glasses",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F576 FE0F",
    char: "🕶️",
    name: "sunglasses",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F576",
    char: "🕶",
    name: "sunglasses",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F97D",
    char: "🥽",
    name: "goggles",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F97C",
    char: "🥼",
    name: "lab coat",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F9BA",
    char: "🦺",
    name: "safety vest",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F454",
    char: "👔",
    name: "necktie",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F455",
    char: "👕",
    name: "t-shirt",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F456",
    char: "👖",
    name: "jeans",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F9E3",
    char: "🧣",
    name: "scarf",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F9E4",
    char: "🧤",
    name: "gloves",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F9E5",
    char: "🧥",
    name: "coat",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F9E6",
    char: "🧦",
    name: "socks",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F457",
    char: "👗",
    name: "dress",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F458",
    char: "👘",
    name: "kimono",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F97B",
    char: "🥻",
    name: "sari",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1FA71",
    char: "🩱",
    name: "one-piece swimsuit",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1FA72",
    char: "🩲",
    name: "briefs",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1FA73",
    char: "🩳",
    name: "shorts",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F459",
    char: "👙",
    name: "bikini",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F45A",
    char: "👚",
    name: "woman’s clothes",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1FAAD",
    char: "🪭",
    name: "folding hand fan",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F45B",
    char: "👛",
    name: "purse",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F45C",
    char: "👜",
    name: "handbag",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F45D",
    char: "👝",
    name: "clutch bag",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F6CD FE0F",
    char: "🛍️",
    name: "shopping bags",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F6CD",
    char: "🛍",
    name: "shopping bags",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F392",
    char: "🎒",
    name: "backpack",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1FA74",
    char: "🩴",
    name: "thong sandal",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F45E",
    char: "👞",
    name: "man’s shoe",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F45F",
    char: "👟",
    name: "running shoe",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F97E",
    char: "🥾",
    name: "hiking boot",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F97F",
    char: "🥿",
    name: "flat shoe",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F460",
    char: "👠",
    name: "high-heeled shoe",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F461",
    char: "👡",
    name: "woman’s sandal",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1FA70",
    char: "🩰",
    name: "ballet shoes",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F462",
    char: "👢",
    name: "woman’s boot",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1FAAE",
    char: "🪮",
    name: "hair pick",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F451",
    char: "👑",
    name: "crown",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F452",
    char: "👒",
    name: "woman’s hat",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F3A9",
    char: "🎩",
    name: "top hat",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F393",
    char: "🎓",
    name: "graduation cap",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F9E2",
    char: "🧢",
    name: "billed cap",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1FA96",
    char: "🪖",
    name: "military helmet",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "26D1 FE0F",
    char: "⛑️",
    name: "rescue worker’s helmet",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "26D1",
    char: "⛑",
    name: "rescue worker’s helmet",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F4FF",
    char: "📿",
    name: "prayer beads",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F484",
    char: "💄",
    name: "lipstick",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F48D",
    char: "💍",
    name: "ring",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F48E",
    char: "💎",
    name: "gem stone",
    category: "Objects (clothing)",
    group: "Objects",
    subgroup: "clothing",
  },
  {
    codes: "1F507",
    char: "🔇",
    name: "muted speaker",
    category: "Objects (sound)",
    group: "Objects",
    subgroup: "sound",
  },
  {
    codes: "1F508",
    char: "🔈",
    name: "speaker low volume",
    category: "Objects (sound)",
    group: "Objects",
    subgroup: "sound",
  },
  {
    codes: "1F509",
    char: "🔉",
    name: "speaker medium volume",
    category: "Objects (sound)",
    group: "Objects",
    subgroup: "sound",
  },
  {
    codes: "1F50A",
    char: "🔊",
    name: "speaker high volume",
    category: "Objects (sound)",
    group: "Objects",
    subgroup: "sound",
  },
  {
    codes: "1F4E2",
    char: "📢",
    name: "loudspeaker",
    category: "Objects (sound)",
    group: "Objects",
    subgroup: "sound",
  },
  {
    codes: "1F4E3",
    char: "📣",
    name: "megaphone",
    category: "Objects (sound)",
    group: "Objects",
    subgroup: "sound",
  },
  {
    codes: "1F4EF",
    char: "📯",
    name: "postal horn",
    category: "Objects (sound)",
    group: "Objects",
    subgroup: "sound",
  },
  {
    codes: "1F514",
    char: "🔔",
    name: "bell",
    category: "Objects (sound)",
    group: "Objects",
    subgroup: "sound",
  },
  {
    codes: "1F515",
    char: "🔕",
    name: "bell with slash",
    category: "Objects (sound)",
    group: "Objects",
    subgroup: "sound",
  },
  {
    codes: "1F3BC",
    char: "🎼",
    name: "musical score",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F3B5",
    char: "🎵",
    name: "musical note",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F3B6",
    char: "🎶",
    name: "musical notes",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F399 FE0F",
    char: "🎙️",
    name: "studio microphone",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F399",
    char: "🎙",
    name: "studio microphone",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F39A FE0F",
    char: "🎚️",
    name: "level slider",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F39A",
    char: "🎚",
    name: "level slider",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F39B FE0F",
    char: "🎛️",
    name: "control knobs",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F39B",
    char: "🎛",
    name: "control knobs",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F3A4",
    char: "🎤",
    name: "microphone",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F3A7",
    char: "🎧",
    name: "headphone",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F4FB",
    char: "📻",
    name: "radio",
    category: "Objects (music)",
    group: "Objects",
    subgroup: "music",
  },
  {
    codes: "1F3B7",
    char: "🎷",
    name: "saxophone",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1FA97",
    char: "🪗",
    name: "accordion",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1F3B8",
    char: "🎸",
    name: "guitar",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1F3B9",
    char: "🎹",
    name: "musical keyboard",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1F3BA",
    char: "🎺",
    name: "trumpet",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1F3BB",
    char: "🎻",
    name: "violin",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1FA95",
    char: "🪕",
    name: "banjo",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1F941",
    char: "🥁",
    name: "drum",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1FA98",
    char: "🪘",
    name: "long drum",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1FA87",
    char: "🪇",
    name: "maracas",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1FA88",
    char: "🪈",
    name: "flute",
    category: "Objects (musical-instrument)",
    group: "Objects",
    subgroup: "musical-instrument",
  },
  {
    codes: "1F4F1",
    char: "📱",
    name: "mobile phone",
    category: "Objects (phone)",
    group: "Objects",
    subgroup: "phone",
  },
  {
    codes: "1F4F2",
    char: "📲",
    name: "mobile phone with arrow",
    category: "Objects (phone)",
    group: "Objects",
    subgroup: "phone",
  },
  {
    codes: "260E FE0F",
    char: "☎️",
    name: "telephone",
    category: "Objects (phone)",
    group: "Objects",
    subgroup: "phone",
  },
  {
    codes: "260E",
    char: "☎",
    name: "telephone",
    category: "Objects (phone)",
    group: "Objects",
    subgroup: "phone",
  },
  {
    codes: "1F4DE",
    char: "📞",
    name: "telephone receiver",
    category: "Objects (phone)",
    group: "Objects",
    subgroup: "phone",
  },
  {
    codes: "1F4DF",
    char: "📟",
    name: "pager",
    category: "Objects (phone)",
    group: "Objects",
    subgroup: "phone",
  },
  {
    codes: "1F4E0",
    char: "📠",
    name: "fax machine",
    category: "Objects (phone)",
    group: "Objects",
    subgroup: "phone",
  },
  {
    codes: "1F50B",
    char: "🔋",
    name: "battery",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1FAAB",
    char: "🪫",
    name: "low battery",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F50C",
    char: "🔌",
    name: "electric plug",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F4BB",
    char: "💻",
    name: "laptop",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F5A5 FE0F",
    char: "🖥️",
    name: "desktop computer",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F5A5",
    char: "🖥",
    name: "desktop computer",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F5A8 FE0F",
    char: "🖨️",
    name: "printer",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F5A8",
    char: "🖨",
    name: "printer",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "2328 FE0F",
    char: "⌨️",
    name: "keyboard",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "2328",
    char: "⌨",
    name: "keyboard",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F5B1 FE0F",
    char: "🖱️",
    name: "computer mouse",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F5B1",
    char: "🖱",
    name: "computer mouse",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F5B2 FE0F",
    char: "🖲️",
    name: "trackball",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F5B2",
    char: "🖲",
    name: "trackball",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F4BD",
    char: "💽",
    name: "computer disk",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F4BE",
    char: "💾",
    name: "floppy disk",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F4BF",
    char: "💿",
    name: "optical disk",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F4C0",
    char: "📀",
    name: "dvd",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F9EE",
    char: "🧮",
    name: "abacus",
    category: "Objects (computer)",
    group: "Objects",
    subgroup: "computer",
  },
  {
    codes: "1F3A5",
    char: "🎥",
    name: "movie camera",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F39E FE0F",
    char: "🎞️",
    name: "film frames",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F39E",
    char: "🎞",
    name: "film frames",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F4FD FE0F",
    char: "📽️",
    name: "film projector",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F4FD",
    char: "📽",
    name: "film projector",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F3AC",
    char: "🎬",
    name: "clapper board",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F4FA",
    char: "📺",
    name: "television",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F4F7",
    char: "📷",
    name: "camera",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F4F8",
    char: "📸",
    name: "camera with flash",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F4F9",
    char: "📹",
    name: "video camera",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F4FC",
    char: "📼",
    name: "videocassette",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F50D",
    char: "🔍",
    name: "magnifying glass tilted left",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F50E",
    char: "🔎",
    name: "magnifying glass tilted right",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F56F FE0F",
    char: "🕯️",
    name: "candle",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F56F",
    char: "🕯",
    name: "candle",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F4A1",
    char: "💡",
    name: "light bulb",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F526",
    char: "🔦",
    name: "flashlight",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F3EE",
    char: "🏮",
    name: "red paper lantern",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1FA94",
    char: "🪔",
    name: "diya lamp",
    category: "Objects (light & video)",
    group: "Objects",
    subgroup: "light & video",
  },
  {
    codes: "1F4D4",
    char: "📔",
    name: "notebook with decorative cover",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4D5",
    char: "📕",
    name: "closed book",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4D6",
    char: "📖",
    name: "open book",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4D7",
    char: "📗",
    name: "green book",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4D8",
    char: "📘",
    name: "blue book",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4D9",
    char: "📙",
    name: "orange book",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4DA",
    char: "📚",
    name: "books",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4D3",
    char: "📓",
    name: "notebook",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4D2",
    char: "📒",
    name: "ledger",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4C3",
    char: "📃",
    name: "page with curl",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4DC",
    char: "📜",
    name: "scroll",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4C4",
    char: "📄",
    name: "page facing up",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4F0",
    char: "📰",
    name: "newspaper",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F5DE FE0F",
    char: "🗞️",
    name: "rolled-up newspaper",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F5DE",
    char: "🗞",
    name: "rolled-up newspaper",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4D1",
    char: "📑",
    name: "bookmark tabs",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F516",
    char: "🔖",
    name: "bookmark",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F3F7 FE0F",
    char: "🏷️",
    name: "label",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F3F7",
    char: "🏷",
    name: "label",
    category: "Objects (book-paper)",
    group: "Objects",
    subgroup: "book-paper",
  },
  {
    codes: "1F4B0",
    char: "💰",
    name: "money bag",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "1FA99",
    char: "🪙",
    name: "coin",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "1F4B4",
    char: "💴",
    name: "yen banknote",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "1F4B5",
    char: "💵",
    name: "dollar banknote",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "1F4B6",
    char: "💶",
    name: "euro banknote",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "1F4B7",
    char: "💷",
    name: "pound banknote",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "1F4B8",
    char: "💸",
    name: "money with wings",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "1F4B3",
    char: "💳",
    name: "credit card",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "1F9FE",
    char: "🧾",
    name: "receipt",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "1F4B9",
    char: "💹",
    name: "chart increasing with yen",
    category: "Objects (money)",
    group: "Objects",
    subgroup: "money",
  },
  {
    codes: "2709 FE0F",
    char: "✉️",
    name: "envelope",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "2709",
    char: "✉",
    name: "envelope",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4E7",
    char: "📧",
    name: "e-mail",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4E8",
    char: "📨",
    name: "incoming envelope",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4E9",
    char: "📩",
    name: "envelope with arrow",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4E4",
    char: "📤",
    name: "outbox tray",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4E5",
    char: "📥",
    name: "inbox tray",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4E6",
    char: "📦",
    name: "package",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4EB",
    char: "📫",
    name: "closed mailbox with raised flag",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4EA",
    char: "📪",
    name: "closed mailbox with lowered flag",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4EC",
    char: "📬",
    name: "open mailbox with raised flag",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4ED",
    char: "📭",
    name: "open mailbox with lowered flag",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F4EE",
    char: "📮",
    name: "postbox",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F5F3 FE0F",
    char: "🗳️",
    name: "ballot box with ballot",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "1F5F3",
    char: "🗳",
    name: "ballot box with ballot",
    category: "Objects (mail)",
    group: "Objects",
    subgroup: "mail",
  },
  {
    codes: "270F FE0F",
    char: "✏️",
    name: "pencil",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "270F",
    char: "✏",
    name: "pencil",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "2712 FE0F",
    char: "✒️",
    name: "black nib",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "2712",
    char: "✒",
    name: "black nib",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F58B FE0F",
    char: "🖋️",
    name: "fountain pen",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F58B",
    char: "🖋",
    name: "fountain pen",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F58A FE0F",
    char: "🖊️",
    name: "pen",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F58A",
    char: "🖊",
    name: "pen",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F58C FE0F",
    char: "🖌️",
    name: "paintbrush",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F58C",
    char: "🖌",
    name: "paintbrush",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F58D FE0F",
    char: "🖍️",
    name: "crayon",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F58D",
    char: "🖍",
    name: "crayon",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F4DD",
    char: "📝",
    name: "memo",
    category: "Objects (writing)",
    group: "Objects",
    subgroup: "writing",
  },
  {
    codes: "1F4BC",
    char: "💼",
    name: "briefcase",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4C1",
    char: "📁",
    name: "file folder",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4C2",
    char: "📂",
    name: "open file folder",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5C2 FE0F",
    char: "🗂️",
    name: "card index dividers",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5C2",
    char: "🗂",
    name: "card index dividers",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4C5",
    char: "📅",
    name: "calendar",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4C6",
    char: "📆",
    name: "tear-off calendar",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5D2 FE0F",
    char: "🗒️",
    name: "spiral notepad",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5D2",
    char: "🗒",
    name: "spiral notepad",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5D3 FE0F",
    char: "🗓️",
    name: "spiral calendar",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5D3",
    char: "🗓",
    name: "spiral calendar",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4C7",
    char: "📇",
    name: "card index",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4C8",
    char: "📈",
    name: "chart increasing",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4C9",
    char: "📉",
    name: "chart decreasing",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4CA",
    char: "📊",
    name: "bar chart",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4CB",
    char: "📋",
    name: "clipboard",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4CC",
    char: "📌",
    name: "pushpin",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4CD",
    char: "📍",
    name: "round pushpin",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4CE",
    char: "📎",
    name: "paperclip",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F587 FE0F",
    char: "🖇️",
    name: "linked paperclips",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F587",
    char: "🖇",
    name: "linked paperclips",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4CF",
    char: "📏",
    name: "straight ruler",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F4D0",
    char: "📐",
    name: "triangular ruler",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "2702 FE0F",
    char: "✂️",
    name: "scissors",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "2702",
    char: "✂",
    name: "scissors",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5C3 FE0F",
    char: "🗃️",
    name: "card file box",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5C3",
    char: "🗃",
    name: "card file box",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5C4 FE0F",
    char: "🗄️",
    name: "file cabinet",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5C4",
    char: "🗄",
    name: "file cabinet",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5D1 FE0F",
    char: "🗑️",
    name: "wastebasket",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F5D1",
    char: "🗑",
    name: "wastebasket",
    category: "Objects (office)",
    group: "Objects",
    subgroup: "office",
  },
  {
    codes: "1F512",
    char: "🔒",
    name: "locked",
    category: "Objects (lock)",
    group: "Objects",
    subgroup: "lock",
  },
  {
    codes: "1F513",
    char: "🔓",
    name: "unlocked",
    category: "Objects (lock)",
    group: "Objects",
    subgroup: "lock",
  },
  {
    codes: "1F50F",
    char: "🔏",
    name: "locked with pen",
    category: "Objects (lock)",
    group: "Objects",
    subgroup: "lock",
  },
  {
    codes: "1F510",
    char: "🔐",
    name: "locked with key",
    category: "Objects (lock)",
    group: "Objects",
    subgroup: "lock",
  },
  {
    codes: "1F511",
    char: "🔑",
    name: "key",
    category: "Objects (lock)",
    group: "Objects",
    subgroup: "lock",
  },
  {
    codes: "1F5DD FE0F",
    char: "🗝️",
    name: "old key",
    category: "Objects (lock)",
    group: "Objects",
    subgroup: "lock",
  },
  {
    codes: "1F5DD",
    char: "🗝",
    name: "old key",
    category: "Objects (lock)",
    group: "Objects",
    subgroup: "lock",
  },
  {
    codes: "1F528",
    char: "🔨",
    name: "hammer",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1FA93",
    char: "🪓",
    name: "axe",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "26CF FE0F",
    char: "⛏️",
    name: "pick",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "26CF",
    char: "⛏",
    name: "pick",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "2692 FE0F",
    char: "⚒️",
    name: "hammer and pick",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "2692",
    char: "⚒",
    name: "hammer and pick",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F6E0 FE0F",
    char: "🛠️",
    name: "hammer and wrench",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F6E0",
    char: "🛠",
    name: "hammer and wrench",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F5E1 FE0F",
    char: "🗡️",
    name: "dagger",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F5E1",
    char: "🗡",
    name: "dagger",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "2694 FE0F",
    char: "⚔️",
    name: "crossed swords",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "2694",
    char: "⚔",
    name: "crossed swords",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F4A3",
    char: "💣",
    name: "bomb",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1FA83",
    char: "🪃",
    name: "boomerang",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F3F9",
    char: "🏹",
    name: "bow and arrow",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F6E1 FE0F",
    char: "🛡️",
    name: "shield",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F6E1",
    char: "🛡",
    name: "shield",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1FA9A",
    char: "🪚",
    name: "carpentry saw",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F527",
    char: "🔧",
    name: "wrench",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1FA9B",
    char: "🪛",
    name: "screwdriver",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F529",
    char: "🔩",
    name: "nut and bolt",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "2699 FE0F",
    char: "⚙️",
    name: "gear",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "2699",
    char: "⚙",
    name: "gear",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F5DC FE0F",
    char: "🗜️",
    name: "clamp",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F5DC",
    char: "🗜",
    name: "clamp",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "2696 FE0F",
    char: "⚖️",
    name: "balance scale",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "2696",
    char: "⚖",
    name: "balance scale",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F9AF",
    char: "🦯",
    name: "white cane",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F517",
    char: "🔗",
    name: "link",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "26D3 FE0F 200D 1F4A5",
    char: "⛓️‍💥",
    name: "broken chain",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "26D3 200D 1F4A5",
    char: "⛓‍💥",
    name: "broken chain",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "26D3 FE0F",
    char: "⛓️",
    name: "chains",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "26D3",
    char: "⛓",
    name: "chains",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1FA9D",
    char: "🪝",
    name: "hook",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F9F0",
    char: "🧰",
    name: "toolbox",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1F9F2",
    char: "🧲",
    name: "magnet",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "1FA9C",
    char: "🪜",
    name: "ladder",
    category: "Objects (tool)",
    group: "Objects",
    subgroup: "tool",
  },
  {
    codes: "2697 FE0F",
    char: "⚗️",
    name: "alembic",
    category: "Objects (science)",
    group: "Objects",
    subgroup: "science",
  },
  {
    codes: "2697",
    char: "⚗",
    name: "alembic",
    category: "Objects (science)",
    group: "Objects",
    subgroup: "science",
  },
  {
    codes: "1F9EA",
    char: "🧪",
    name: "test tube",
    category: "Objects (science)",
    group: "Objects",
    subgroup: "science",
  },
  {
    codes: "1F9EB",
    char: "🧫",
    name: "petri dish",
    category: "Objects (science)",
    group: "Objects",
    subgroup: "science",
  },
  {
    codes: "1F9EC",
    char: "🧬",
    name: "dna",
    category: "Objects (science)",
    group: "Objects",
    subgroup: "science",
  },
  {
    codes: "1F52C",
    char: "🔬",
    name: "microscope",
    category: "Objects (science)",
    group: "Objects",
    subgroup: "science",
  },
  {
    codes: "1F52D",
    char: "🔭",
    name: "telescope",
    category: "Objects (science)",
    group: "Objects",
    subgroup: "science",
  },
  {
    codes: "1F4E1",
    char: "📡",
    name: "satellite antenna",
    category: "Objects (science)",
    group: "Objects",
    subgroup: "science",
  },
  {
    codes: "1F489",
    char: "💉",
    name: "syringe",
    category: "Objects (medical)",
    group: "Objects",
    subgroup: "medical",
  },
  {
    codes: "1FA78",
    char: "🩸",
    name: "drop of blood",
    category: "Objects (medical)",
    group: "Objects",
    subgroup: "medical",
  },
  {
    codes: "1F48A",
    char: "💊",
    name: "pill",
    category: "Objects (medical)",
    group: "Objects",
    subgroup: "medical",
  },
  {
    codes: "1FA79",
    char: "🩹",
    name: "adhesive bandage",
    category: "Objects (medical)",
    group: "Objects",
    subgroup: "medical",
  },
  {
    codes: "1FA7C",
    char: "🩼",
    name: "crutch",
    category: "Objects (medical)",
    group: "Objects",
    subgroup: "medical",
  },
  {
    codes: "1FA7A",
    char: "🩺",
    name: "stethoscope",
    category: "Objects (medical)",
    group: "Objects",
    subgroup: "medical",
  },
  {
    codes: "1FA7B",
    char: "🩻",
    name: "x-ray",
    category: "Objects (medical)",
    group: "Objects",
    subgroup: "medical",
  },
  {
    codes: "1F6AA",
    char: "🚪",
    name: "door",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6D7",
    char: "🛗",
    name: "elevator",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1FA9E",
    char: "🪞",
    name: "mirror",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1FA9F",
    char: "🪟",
    name: "window",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6CF FE0F",
    char: "🛏️",
    name: "bed",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6CF",
    char: "🛏",
    name: "bed",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6CB FE0F",
    char: "🛋️",
    name: "couch and lamp",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6CB",
    char: "🛋",
    name: "couch and lamp",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1FA91",
    char: "🪑",
    name: "chair",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6BD",
    char: "🚽",
    name: "toilet",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1FAA0",
    char: "🪠",
    name: "plunger",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6BF",
    char: "🚿",
    name: "shower",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6C1",
    char: "🛁",
    name: "bathtub",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1FAA4",
    char: "🪤",
    name: "mouse trap",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1FA92",
    char: "🪒",
    name: "razor",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F9F4",
    char: "🧴",
    name: "lotion bottle",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F9F7",
    char: "🧷",
    name: "safety pin",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F9F9",
    char: "🧹",
    name: "broom",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F9FA",
    char: "🧺",
    name: "basket",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F9FB",
    char: "🧻",
    name: "roll of paper",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1FAA3",
    char: "🪣",
    name: "bucket",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F9FC",
    char: "🧼",
    name: "soap",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1FAE7",
    char: "🫧",
    name: "bubbles",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1FAA5",
    char: "🪥",
    name: "toothbrush",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F9FD",
    char: "🧽",
    name: "sponge",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F9EF",
    char: "🧯",
    name: "fire extinguisher",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6D2",
    char: "🛒",
    name: "shopping cart",
    category: "Objects (household)",
    group: "Objects",
    subgroup: "household",
  },
  {
    codes: "1F6AC",
    char: "🚬",
    name: "cigarette",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "26B0 FE0F",
    char: "⚰️",
    name: "coffin",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "26B0",
    char: "⚰",
    name: "coffin",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "1FAA6",
    char: "🪦",
    name: "headstone",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "26B1 FE0F",
    char: "⚱️",
    name: "funeral urn",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "26B1",
    char: "⚱",
    name: "funeral urn",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "1F9FF",
    char: "🧿",
    name: "nazar amulet",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "1FAAC",
    char: "🪬",
    name: "hamsa",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "1F5FF",
    char: "🗿",
    name: "moai",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "1FAA7",
    char: "🪧",
    name: "placard",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "1FAAA",
    char: "🪪",
    name: "identification card",
    category: "Objects (other-object)",
    group: "Objects",
    subgroup: "other-object",
  },
  {
    codes: "1F3E7",
    char: "🏧",
    name: "ATM sign",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6AE",
    char: "🚮",
    name: "litter in bin sign",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6B0",
    char: "🚰",
    name: "potable water",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "267F",
    char: "♿",
    name: "wheelchair symbol",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6B9",
    char: "🚹",
    name: "men’s room",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6BA",
    char: "🚺",
    name: "women’s room",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6BB",
    char: "🚻",
    name: "restroom",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6BC",
    char: "🚼",
    name: "baby symbol",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6BE",
    char: "🚾",
    name: "water closet",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6C2",
    char: "🛂",
    name: "passport control",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6C3",
    char: "🛃",
    name: "customs",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6C4",
    char: "🛄",
    name: "baggage claim",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "1F6C5",
    char: "🛅",
    name: "left luggage",
    category: "Symbols (transport-sign)",
    group: "Symbols",
    subgroup: "transport-sign",
  },
  {
    codes: "26A0 FE0F",
    char: "⚠️",
    name: "warning",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "26A0",
    char: "⚠",
    name: "warning",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "1F6B8",
    char: "🚸",
    name: "children crossing",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "26D4",
    char: "⛔",
    name: "no entry",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "1F6AB",
    char: "🚫",
    name: "prohibited",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "1F6B3",
    char: "🚳",
    name: "no bicycles",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "1F6AD",
    char: "🚭",
    name: "no smoking",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "1F6AF",
    char: "🚯",
    name: "no littering",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "1F6B1",
    char: "🚱",
    name: "non-potable water",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "1F6B7",
    char: "🚷",
    name: "no pedestrians",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "1F4F5",
    char: "📵",
    name: "no mobile phones",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "1F51E",
    char: "🔞",
    name: "no one under eighteen",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "2622 FE0F",
    char: "☢️",
    name: "radioactive",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "2622",
    char: "☢",
    name: "radioactive",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "2623 FE0F",
    char: "☣️",
    name: "biohazard",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "2623",
    char: "☣",
    name: "biohazard",
    category: "Symbols (warning)",
    group: "Symbols",
    subgroup: "warning",
  },
  {
    codes: "2B06 FE0F",
    char: "⬆️",
    name: "up arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2B06",
    char: "⬆",
    name: "up arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2197 FE0F",
    char: "↗️",
    name: "up-right arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2197",
    char: "↗",
    name: "up-right arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "27A1 FE0F",
    char: "➡️",
    name: "right arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "27A1",
    char: "➡",
    name: "right arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2198 FE0F",
    char: "↘️",
    name: "down-right arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2198",
    char: "↘",
    name: "down-right arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2B07 FE0F",
    char: "⬇️",
    name: "down arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2B07",
    char: "⬇",
    name: "down arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2199 FE0F",
    char: "↙️",
    name: "down-left arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2199",
    char: "↙",
    name: "down-left arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2B05 FE0F",
    char: "⬅️",
    name: "left arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2B05",
    char: "⬅",
    name: "left arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2196 FE0F",
    char: "↖️",
    name: "up-left arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2196",
    char: "↖",
    name: "up-left arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2195 FE0F",
    char: "↕️",
    name: "up-down arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2195",
    char: "↕",
    name: "up-down arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2194 FE0F",
    char: "↔️",
    name: "left-right arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2194",
    char: "↔",
    name: "left-right arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "21A9 FE0F",
    char: "↩️",
    name: "right arrow curving left",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "21A9",
    char: "↩",
    name: "right arrow curving left",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "21AA FE0F",
    char: "↪️",
    name: "left arrow curving right",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "21AA",
    char: "↪",
    name: "left arrow curving right",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2934 FE0F",
    char: "⤴️",
    name: "right arrow curving up",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2934",
    char: "⤴",
    name: "right arrow curving up",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2935 FE0F",
    char: "⤵️",
    name: "right arrow curving down",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "2935",
    char: "⤵",
    name: "right arrow curving down",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "1F503",
    char: "🔃",
    name: "clockwise vertical arrows",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "1F504",
    char: "🔄",
    name: "counterclockwise arrows button",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "1F519",
    char: "🔙",
    name: "BACK arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "1F51A",
    char: "🔚",
    name: "END arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "1F51B",
    char: "🔛",
    name: "ON! arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "1F51C",
    char: "🔜",
    name: "SOON arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "1F51D",
    char: "🔝",
    name: "TOP arrow",
    category: "Symbols (arrow)",
    group: "Symbols",
    subgroup: "arrow",
  },
  {
    codes: "1F6D0",
    char: "🛐",
    name: "place of worship",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "269B FE0F",
    char: "⚛️",
    name: "atom symbol",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "269B",
    char: "⚛",
    name: "atom symbol",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "1F549 FE0F",
    char: "🕉️",
    name: "om",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "1F549",
    char: "🕉",
    name: "om",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "2721 FE0F",
    char: "✡️",
    name: "star of David",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "2721",
    char: "✡",
    name: "star of David",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "2638 FE0F",
    char: "☸️",
    name: "wheel of dharma",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "2638",
    char: "☸",
    name: "wheel of dharma",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "262F FE0F",
    char: "☯️",
    name: "yin yang",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "262F",
    char: "☯",
    name: "yin yang",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "271D FE0F",
    char: "✝️",
    name: "latin cross",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "271D",
    char: "✝",
    name: "latin cross",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "2626 FE0F",
    char: "☦️",
    name: "orthodox cross",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "2626",
    char: "☦",
    name: "orthodox cross",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "262A FE0F",
    char: "☪️",
    name: "star and crescent",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "262A",
    char: "☪",
    name: "star and crescent",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "262E FE0F",
    char: "☮️",
    name: "peace symbol",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "262E",
    char: "☮",
    name: "peace symbol",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "1F54E",
    char: "🕎",
    name: "menorah",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "1F52F",
    char: "🔯",
    name: "dotted six-pointed star",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "1FAAF",
    char: "🪯",
    name: "khanda",
    category: "Symbols (religion)",
    group: "Symbols",
    subgroup: "religion",
  },
  {
    codes: "2648",
    char: "♈",
    name: "Aries",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "2649",
    char: "♉",
    name: "Taurus",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "264A",
    char: "♊",
    name: "Gemini",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "264B",
    char: "♋",
    name: "Cancer",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "264C",
    char: "♌",
    name: "Leo",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "264D",
    char: "♍",
    name: "Virgo",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "264E",
    char: "♎",
    name: "Libra",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "264F",
    char: "♏",
    name: "Scorpio",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "2650",
    char: "♐",
    name: "Sagittarius",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "2651",
    char: "♑",
    name: "Capricorn",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "2652",
    char: "♒",
    name: "Aquarius",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "2653",
    char: "♓",
    name: "Pisces",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "26CE",
    char: "⛎",
    name: "Ophiuchus",
    category: "Symbols (zodiac)",
    group: "Symbols",
    subgroup: "zodiac",
  },
  {
    codes: "1F500",
    char: "🔀",
    name: "shuffle tracks button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F501",
    char: "🔁",
    name: "repeat button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F502",
    char: "🔂",
    name: "repeat single button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "25B6 FE0F",
    char: "▶️",
    name: "play button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "25B6",
    char: "▶",
    name: "play button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23E9",
    char: "⏩",
    name: "fast-forward button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23ED FE0F",
    char: "⏭️",
    name: "next track button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23ED",
    char: "⏭",
    name: "next track button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23EF FE0F",
    char: "⏯️",
    name: "play or pause button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23EF",
    char: "⏯",
    name: "play or pause button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "25C0 FE0F",
    char: "◀️",
    name: "reverse button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "25C0",
    char: "◀",
    name: "reverse button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23EA",
    char: "⏪",
    name: "fast reverse button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23EE FE0F",
    char: "⏮️",
    name: "last track button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23EE",
    char: "⏮",
    name: "last track button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F53C",
    char: "🔼",
    name: "upwards button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23EB",
    char: "⏫",
    name: "fast up button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F53D",
    char: "🔽",
    name: "downwards button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23EC",
    char: "⏬",
    name: "fast down button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23F8 FE0F",
    char: "⏸️",
    name: "pause button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23F8",
    char: "⏸",
    name: "pause button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23F9 FE0F",
    char: "⏹️",
    name: "stop button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23F9",
    char: "⏹",
    name: "stop button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23FA FE0F",
    char: "⏺️",
    name: "record button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23FA",
    char: "⏺",
    name: "record button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23CF FE0F",
    char: "⏏️",
    name: "eject button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "23CF",
    char: "⏏",
    name: "eject button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F3A6",
    char: "🎦",
    name: "cinema",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F505",
    char: "🔅",
    name: "dim button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F506",
    char: "🔆",
    name: "bright button",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F4F6",
    char: "📶",
    name: "antenna bars",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F6DC",
    char: "🛜",
    name: "wireless",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F4F3",
    char: "📳",
    name: "vibration mode",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "1F4F4",
    char: "📴",
    name: "mobile phone off",
    category: "Symbols (av-symbol)",
    group: "Symbols",
    subgroup: "av-symbol",
  },
  {
    codes: "2640 FE0F",
    char: "♀️",
    name: "female sign",
    category: "Symbols (gender)",
    group: "Symbols",
    subgroup: "gender",
  },
  {
    codes: "2640",
    char: "♀",
    name: "female sign",
    category: "Symbols (gender)",
    group: "Symbols",
    subgroup: "gender",
  },
  {
    codes: "2642 FE0F",
    char: "♂️",
    name: "male sign",
    category: "Symbols (gender)",
    group: "Symbols",
    subgroup: "gender",
  },
  {
    codes: "2642",
    char: "♂",
    name: "male sign",
    category: "Symbols (gender)",
    group: "Symbols",
    subgroup: "gender",
  },
  {
    codes: "26A7 FE0F",
    char: "⚧️",
    name: "transgender symbol",
    category: "Symbols (gender)",
    group: "Symbols",
    subgroup: "gender",
  },
  {
    codes: "26A7",
    char: "⚧",
    name: "transgender symbol",
    category: "Symbols (gender)",
    group: "Symbols",
    subgroup: "gender",
  },
  {
    codes: "2716 FE0F",
    char: "✖️",
    name: "multiply",
    category: "Symbols (math)",
    group: "Symbols",
    subgroup: "math",
  },
  {
    codes: "2716",
    char: "✖",
    name: "multiply",
    category: "Symbols (math)",
    group: "Symbols",
    subgroup: "math",
  },
  {
    codes: "2795",
    char: "➕",
    name: "plus",
    category: "Symbols (math)",
    group: "Symbols",
    subgroup: "math",
  },
  {
    codes: "2796",
    char: "➖",
    name: "minus",
    category: "Symbols (math)",
    group: "Symbols",
    subgroup: "math",
  },
  {
    codes: "2797",
    char: "➗",
    name: "divide",
    category: "Symbols (math)",
    group: "Symbols",
    subgroup: "math",
  },
  {
    codes: "1F7F0",
    char: "🟰",
    name: "heavy equals sign",
    category: "Symbols (math)",
    group: "Symbols",
    subgroup: "math",
  },
  {
    codes: "267E FE0F",
    char: "♾️",
    name: "infinity",
    category: "Symbols (math)",
    group: "Symbols",
    subgroup: "math",
  },
  {
    codes: "267E",
    char: "♾",
    name: "infinity",
    category: "Symbols (math)",
    group: "Symbols",
    subgroup: "math",
  },
  {
    codes: "203C FE0F",
    char: "‼️",
    name: "double exclamation mark",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "203C",
    char: "‼",
    name: "double exclamation mark",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "2049 FE0F",
    char: "⁉️",
    name: "exclamation question mark",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "2049",
    char: "⁉",
    name: "exclamation question mark",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "2753",
    char: "❓",
    name: "red question mark",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "2754",
    char: "❔",
    name: "white question mark",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "2755",
    char: "❕",
    name: "white exclamation mark",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "2757",
    char: "❗",
    name: "red exclamation mark",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "3030 FE0F",
    char: "〰️",
    name: "wavy dash",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "3030",
    char: "〰",
    name: "wavy dash",
    category: "Symbols (punctuation)",
    group: "Symbols",
    subgroup: "punctuation",
  },
  {
    codes: "1F4B1",
    char: "💱",
    name: "currency exchange",
    category: "Symbols (currency)",
    group: "Symbols",
    subgroup: "currency",
  },
  {
    codes: "1F4B2",
    char: "💲",
    name: "heavy dollar sign",
    category: "Symbols (currency)",
    group: "Symbols",
    subgroup: "currency",
  },
  {
    codes: "2695 FE0F",
    char: "⚕️",
    name: "medical symbol",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2695",
    char: "⚕",
    name: "medical symbol",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "267B FE0F",
    char: "♻️",
    name: "recycling symbol",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "267B",
    char: "♻",
    name: "recycling symbol",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "269C FE0F",
    char: "⚜️",
    name: "fleur-de-lis",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "269C",
    char: "⚜",
    name: "fleur-de-lis",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "1F531",
    char: "🔱",
    name: "trident emblem",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "1F4DB",
    char: "📛",
    name: "name badge",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "1F530",
    char: "🔰",
    name: "Japanese symbol for beginner",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2B55",
    char: "⭕",
    name: "hollow red circle",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2705",
    char: "✅",
    name: "check mark button",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2611 FE0F",
    char: "☑️",
    name: "check box with check",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2611",
    char: "☑",
    name: "check box with check",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2714 FE0F",
    char: "✔️",
    name: "check mark",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2714",
    char: "✔",
    name: "check mark",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "274C",
    char: "❌",
    name: "cross mark",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "274E",
    char: "❎",
    name: "cross mark button",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "27B0",
    char: "➰",
    name: "curly loop",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "27BF",
    char: "➿",
    name: "double curly loop",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "303D FE0F",
    char: "〽️",
    name: "part alternation mark",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "303D",
    char: "〽",
    name: "part alternation mark",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2733 FE0F",
    char: "✳️",
    name: "eight-spoked asterisk",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2733",
    char: "✳",
    name: "eight-spoked asterisk",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2734 FE0F",
    char: "✴️",
    name: "eight-pointed star",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2734",
    char: "✴",
    name: "eight-pointed star",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2747 FE0F",
    char: "❇️",
    name: "sparkle",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2747",
    char: "❇",
    name: "sparkle",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "00A9 FE0F",
    char: "©️",
    name: "copyright",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "00A9",
    char: "©",
    name: "copyright",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "00AE FE0F",
    char: "®️",
    name: "registered",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "00AE",
    char: "®",
    name: "registered",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2122 FE0F",
    char: "™️",
    name: "trade mark",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "2122",
    char: "™",
    name: "trade mark",
    category: "Symbols (other-symbol)",
    group: "Symbols",
    subgroup: "other-symbol",
  },
  {
    codes: "0023 FE0F 20E3",
    char: "#️⃣",
    name: "keycap: #",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0023 20E3",
    char: "#⃣",
    name: "keycap: #",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "002A FE0F 20E3",
    char: "*️⃣",
    name: "keycap: *",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "002A 20E3",
    char: "*⃣",
    name: "keycap: *",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0030 FE0F 20E3",
    char: "0️⃣",
    name: "keycap: 0",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0030 20E3",
    char: "0⃣",
    name: "keycap: 0",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0031 FE0F 20E3",
    char: "1️⃣",
    name: "keycap: 1",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0031 20E3",
    char: "1⃣",
    name: "keycap: 1",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0032 FE0F 20E3",
    char: "2️⃣",
    name: "keycap: 2",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0032 20E3",
    char: "2⃣",
    name: "keycap: 2",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0033 FE0F 20E3",
    char: "3️⃣",
    name: "keycap: 3",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0033 20E3",
    char: "3⃣",
    name: "keycap: 3",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0034 FE0F 20E3",
    char: "4️⃣",
    name: "keycap: 4",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0034 20E3",
    char: "4⃣",
    name: "keycap: 4",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0035 FE0F 20E3",
    char: "5️⃣",
    name: "keycap: 5",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0035 20E3",
    char: "5⃣",
    name: "keycap: 5",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0036 FE0F 20E3",
    char: "6️⃣",
    name: "keycap: 6",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0036 20E3",
    char: "6⃣",
    name: "keycap: 6",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0037 FE0F 20E3",
    char: "7️⃣",
    name: "keycap: 7",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0037 20E3",
    char: "7⃣",
    name: "keycap: 7",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0038 FE0F 20E3",
    char: "8️⃣",
    name: "keycap: 8",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0038 20E3",
    char: "8⃣",
    name: "keycap: 8",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0039 FE0F 20E3",
    char: "9️⃣",
    name: "keycap: 9",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "0039 20E3",
    char: "9⃣",
    name: "keycap: 9",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "1F51F",
    char: "🔟",
    name: "keycap: 10",
    category: "Symbols (keycap)",
    group: "Symbols",
    subgroup: "keycap",
  },
  {
    codes: "1F520",
    char: "🔠",
    name: "input latin uppercase",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F521",
    char: "🔡",
    name: "input latin lowercase",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F522",
    char: "🔢",
    name: "input numbers",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F523",
    char: "🔣",
    name: "input symbols",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F524",
    char: "🔤",
    name: "input latin letters",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F170 FE0F",
    char: "🅰️",
    name: "A button (blood type)",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F170",
    char: "🅰",
    name: "A button (blood type)",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F18E",
    char: "🆎",
    name: "AB button (blood type)",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F171 FE0F",
    char: "🅱️",
    name: "B button (blood type)",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F171",
    char: "🅱",
    name: "B button (blood type)",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F191",
    char: "🆑",
    name: "CL button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F192",
    char: "🆒",
    name: "COOL button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F193",
    char: "🆓",
    name: "FREE button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "2139 FE0F",
    char: "ℹ️",
    name: "information",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "2139",
    char: "ℹ",
    name: "information",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F194",
    char: "🆔",
    name: "ID button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "24C2 FE0F",
    char: "Ⓜ️",
    name: "circled M",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "24C2",
    char: "Ⓜ",
    name: "circled M",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F195",
    char: "🆕",
    name: "NEW button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F196",
    char: "🆖",
    name: "NG button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F17E FE0F",
    char: "🅾️",
    name: "O button (blood type)",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F17E",
    char: "🅾",
    name: "O button (blood type)",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F197",
    char: "🆗",
    name: "OK button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F17F FE0F",
    char: "🅿️",
    name: "P button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F17F",
    char: "🅿",
    name: "P button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F198",
    char: "🆘",
    name: "SOS button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F199",
    char: "🆙",
    name: "UP! button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F19A",
    char: "🆚",
    name: "VS button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F201",
    char: "🈁",
    name: "Japanese “here” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F202 FE0F",
    char: "🈂️",
    name: "Japanese “service charge” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F202",
    char: "🈂",
    name: "Japanese “service charge” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F237 FE0F",
    char: "🈷️",
    name: "Japanese “monthly amount” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F237",
    char: "🈷",
    name: "Japanese “monthly amount” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F236",
    char: "🈶",
    name: "Japanese “not free of charge” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F22F",
    char: "🈯",
    name: "Japanese “reserved” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F250",
    char: "🉐",
    name: "Japanese “bargain” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F239",
    char: "🈹",
    name: "Japanese “discount” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F21A",
    char: "🈚",
    name: "Japanese “free of charge” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F232",
    char: "🈲",
    name: "Japanese “prohibited” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F251",
    char: "🉑",
    name: "Japanese “acceptable” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F238",
    char: "🈸",
    name: "Japanese “application” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F234",
    char: "🈴",
    name: "Japanese “passing grade” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F233",
    char: "🈳",
    name: "Japanese “vacancy” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "3297 FE0F",
    char: "㊗️",
    name: "Japanese “congratulations” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "3297",
    char: "㊗",
    name: "Japanese “congratulations” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "3299 FE0F",
    char: "㊙️",
    name: "Japanese “secret” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "3299",
    char: "㊙",
    name: "Japanese “secret” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F23A",
    char: "🈺",
    name: "Japanese “open for business” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F235",
    char: "🈵",
    name: "Japanese “no vacancy” button",
    category: "Symbols (alphanum)",
    group: "Symbols",
    subgroup: "alphanum",
  },
  {
    codes: "1F534",
    char: "🔴",
    name: "red circle",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E0",
    char: "🟠",
    name: "orange circle",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E1",
    char: "🟡",
    name: "yellow circle",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E2",
    char: "🟢",
    name: "green circle",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F535",
    char: "🔵",
    name: "blue circle",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E3",
    char: "🟣",
    name: "purple circle",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E4",
    char: "🟤",
    name: "brown circle",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "26AB",
    char: "⚫",
    name: "black circle",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "26AA",
    char: "⚪",
    name: "white circle",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E5",
    char: "🟥",
    name: "red square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E7",
    char: "🟧",
    name: "orange square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E8",
    char: "🟨",
    name: "yellow square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E9",
    char: "🟩",
    name: "green square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7E6",
    char: "🟦",
    name: "blue square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7EA",
    char: "🟪",
    name: "purple square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F7EB",
    char: "🟫",
    name: "brown square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "2B1B",
    char: "⬛",
    name: "black large square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "2B1C",
    char: "⬜",
    name: "white large square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25FC FE0F",
    char: "◼️",
    name: "black medium square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25FC",
    char: "◼",
    name: "black medium square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25FB FE0F",
    char: "◻️",
    name: "white medium square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25FB",
    char: "◻",
    name: "white medium square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25FE",
    char: "◾",
    name: "black medium-small square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25FD",
    char: "◽",
    name: "white medium-small square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25AA FE0F",
    char: "▪️",
    name: "black small square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25AA",
    char: "▪",
    name: "black small square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25AB FE0F",
    char: "▫️",
    name: "white small square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "25AB",
    char: "▫",
    name: "white small square",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F536",
    char: "🔶",
    name: "large orange diamond",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F537",
    char: "🔷",
    name: "large blue diamond",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F538",
    char: "🔸",
    name: "small orange diamond",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F539",
    char: "🔹",
    name: "small blue diamond",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F53A",
    char: "🔺",
    name: "red triangle pointed up",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F53B",
    char: "🔻",
    name: "red triangle pointed down",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F4A0",
    char: "💠",
    name: "diamond with a dot",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F518",
    char: "🔘",
    name: "radio button",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F533",
    char: "🔳",
    name: "white square button",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F532",
    char: "🔲",
    name: "black square button",
    category: "Symbols (geometric)",
    group: "Symbols",
    subgroup: "geometric",
  },
  {
    codes: "1F3C1",
    char: "🏁",
    name: "chequered flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F6A9",
    char: "🚩",
    name: "triangular flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F38C",
    char: "🎌",
    name: "crossed flags",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F4",
    char: "🏴",
    name: "black flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F3 FE0F",
    char: "🏳️",
    name: "white flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F3",
    char: "🏳",
    name: "white flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F3 FE0F 200D 1F308",
    char: "🏳️‍🌈",
    name: "rainbow flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F3 200D 1F308",
    char: "🏳‍🌈",
    name: "rainbow flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F3 FE0F 200D 26A7 FE0F",
    char: "🏳️‍⚧️",
    name: "transgender flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F3 200D 26A7 FE0F",
    char: "🏳‍⚧️",
    name: "transgender flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F3 FE0F 200D 26A7",
    char: "🏳️‍⚧",
    name: "transgender flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F3 200D 26A7",
    char: "🏳‍⚧",
    name: "transgender flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F4 200D 2620 FE0F",
    char: "🏴‍☠️",
    name: "pirate flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F3F4 200D 2620",
    char: "🏴‍☠",
    name: "pirate flag",
    category: "Flags (flag)",
    group: "Flags",
    subgroup: "flag",
  },
  {
    codes: "1F1E6 1F1E8",
    char: "🇦🇨",
    name: "flag: Ascension Island",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1E9",
    char: "🇦🇩",
    name: "flag: Andorra",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1EA",
    char: "🇦🇪",
    name: "flag: United Arab Emirates",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1EB",
    char: "🇦🇫",
    name: "flag: Afghanistan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1EC",
    char: "🇦🇬",
    name: "flag: Antigua & Barbuda",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1EE",
    char: "🇦🇮",
    name: "flag: Anguilla",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1F1",
    char: "🇦🇱",
    name: "flag: Albania",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1F2",
    char: "🇦🇲",
    name: "flag: Armenia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1F4",
    char: "🇦🇴",
    name: "flag: Angola",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1F6",
    char: "🇦🇶",
    name: "flag: Antarctica",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1F7",
    char: "🇦🇷",
    name: "flag: Argentina",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1F8",
    char: "🇦🇸",
    name: "flag: American Samoa",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1F9",
    char: "🇦🇹",
    name: "flag: Austria",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1FA",
    char: "🇦🇺",
    name: "flag: Australia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1FC",
    char: "🇦🇼",
    name: "flag: Aruba",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1FD",
    char: "🇦🇽",
    name: "flag: Åland Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E6 1F1FF",
    char: "🇦🇿",
    name: "flag: Azerbaijan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1E6",
    char: "🇧🇦",
    name: "flag: Bosnia & Herzegovina",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1E7",
    char: "🇧🇧",
    name: "flag: Barbados",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1E9",
    char: "🇧🇩",
    name: "flag: Bangladesh",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1EA",
    char: "🇧🇪",
    name: "flag: Belgium",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1EB",
    char: "🇧🇫",
    name: "flag: Burkina Faso",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1EC",
    char: "🇧🇬",
    name: "flag: Bulgaria",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1ED",
    char: "🇧🇭",
    name: "flag: Bahrain",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1EE",
    char: "🇧🇮",
    name: "flag: Burundi",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1EF",
    char: "🇧🇯",
    name: "flag: Benin",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1F1",
    char: "🇧🇱",
    name: "flag: St. Barthélemy",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1F2",
    char: "🇧🇲",
    name: "flag: Bermuda",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1F3",
    char: "🇧🇳",
    name: "flag: Brunei",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1F4",
    char: "🇧🇴",
    name: "flag: Bolivia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1F6",
    char: "🇧🇶",
    name: "flag: Caribbean Netherlands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1F7",
    char: "🇧🇷",
    name: "flag: Brazil",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1F8",
    char: "🇧🇸",
    name: "flag: Bahamas",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1F9",
    char: "🇧🇹",
    name: "flag: Bhutan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1FB",
    char: "🇧🇻",
    name: "flag: Bouvet Island",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1FC",
    char: "🇧🇼",
    name: "flag: Botswana",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1FE",
    char: "🇧🇾",
    name: "flag: Belarus",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E7 1F1FF",
    char: "🇧🇿",
    name: "flag: Belize",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1E6",
    char: "🇨🇦",
    name: "flag: Canada",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1E8",
    char: "🇨🇨",
    name: "flag: Cocos (Keeling) Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1E9",
    char: "🇨🇩",
    name: "flag: Congo - Kinshasa",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1EB",
    char: "🇨🇫",
    name: "flag: Central African Republic",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1EC",
    char: "🇨🇬",
    name: "flag: Congo - Brazzaville",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1ED",
    char: "🇨🇭",
    name: "flag: Switzerland",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1EE",
    char: "🇨🇮",
    name: "flag: Côte d’Ivoire",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1F0",
    char: "🇨🇰",
    name: "flag: Cook Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1F1",
    char: "🇨🇱",
    name: "flag: Chile",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1F2",
    char: "🇨🇲",
    name: "flag: Cameroon",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1F3",
    char: "🇨🇳",
    name: "flag: China",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1F4",
    char: "🇨🇴",
    name: "flag: Colombia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1F5",
    char: "🇨🇵",
    name: "flag: Clipperton Island",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1F7",
    char: "🇨🇷",
    name: "flag: Costa Rica",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1FA",
    char: "🇨🇺",
    name: "flag: Cuba",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1FB",
    char: "🇨🇻",
    name: "flag: Cape Verde",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1FC",
    char: "🇨🇼",
    name: "flag: Curaçao",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1FD",
    char: "🇨🇽",
    name: "flag: Christmas Island",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1FE",
    char: "🇨🇾",
    name: "flag: Cyprus",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E8 1F1FF",
    char: "🇨🇿",
    name: "flag: Czechia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E9 1F1EA",
    char: "🇩🇪",
    name: "flag: Germany",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E9 1F1EC",
    char: "🇩🇬",
    name: "flag: Diego Garcia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E9 1F1EF",
    char: "🇩🇯",
    name: "flag: Djibouti",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E9 1F1F0",
    char: "🇩🇰",
    name: "flag: Denmark",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E9 1F1F2",
    char: "🇩🇲",
    name: "flag: Dominica",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E9 1F1F4",
    char: "🇩🇴",
    name: "flag: Dominican Republic",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1E9 1F1FF",
    char: "🇩🇿",
    name: "flag: Algeria",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EA 1F1E6",
    char: "🇪🇦",
    name: "flag: Ceuta & Melilla",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EA 1F1E8",
    char: "🇪🇨",
    name: "flag: Ecuador",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EA 1F1EA",
    char: "🇪🇪",
    name: "flag: Estonia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EA 1F1EC",
    char: "🇪🇬",
    name: "flag: Egypt",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EA 1F1ED",
    char: "🇪🇭",
    name: "flag: Western Sahara",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EA 1F1F7",
    char: "🇪🇷",
    name: "flag: Eritrea",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EA 1F1F8",
    char: "🇪🇸",
    name: "flag: Spain",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EA 1F1F9",
    char: "🇪🇹",
    name: "flag: Ethiopia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EA 1F1FA",
    char: "🇪🇺",
    name: "flag: European Union",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EB 1F1EE",
    char: "🇫🇮",
    name: "flag: Finland",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EB 1F1EF",
    char: "🇫🇯",
    name: "flag: Fiji",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EB 1F1F0",
    char: "🇫🇰",
    name: "flag: Falkland Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EB 1F1F2",
    char: "🇫🇲",
    name: "flag: Micronesia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EB 1F1F4",
    char: "🇫🇴",
    name: "flag: Faroe Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EB 1F1F7",
    char: "🇫🇷",
    name: "flag: France",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1E6",
    char: "🇬🇦",
    name: "flag: Gabon",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1E7",
    char: "🇬🇧",
    name: "flag: United Kingdom",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1E9",
    char: "🇬🇩",
    name: "flag: Grenada",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1EA",
    char: "🇬🇪",
    name: "flag: Georgia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1EB",
    char: "🇬🇫",
    name: "flag: French Guiana",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1EC",
    char: "🇬🇬",
    name: "flag: Guernsey",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1ED",
    char: "🇬🇭",
    name: "flag: Ghana",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1EE",
    char: "🇬🇮",
    name: "flag: Gibraltar",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1F1",
    char: "🇬🇱",
    name: "flag: Greenland",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1F2",
    char: "🇬🇲",
    name: "flag: Gambia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1F3",
    char: "🇬🇳",
    name: "flag: Guinea",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1F5",
    char: "🇬🇵",
    name: "flag: Guadeloupe",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1F6",
    char: "🇬🇶",
    name: "flag: Equatorial Guinea",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1F7",
    char: "🇬🇷",
    name: "flag: Greece",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1F8",
    char: "🇬🇸",
    name: "flag: South Georgia & South Sandwich Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1F9",
    char: "🇬🇹",
    name: "flag: Guatemala",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1FA",
    char: "🇬🇺",
    name: "flag: Guam",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1FC",
    char: "🇬🇼",
    name: "flag: Guinea-Bissau",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EC 1F1FE",
    char: "🇬🇾",
    name: "flag: Guyana",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1ED 1F1F0",
    char: "🇭🇰",
    name: "flag: Hong Kong SAR China",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1ED 1F1F2",
    char: "🇭🇲",
    name: "flag: Heard & McDonald Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1ED 1F1F3",
    char: "🇭🇳",
    name: "flag: Honduras",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1ED 1F1F7",
    char: "🇭🇷",
    name: "flag: Croatia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1ED 1F1F9",
    char: "🇭🇹",
    name: "flag: Haiti",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1ED 1F1FA",
    char: "🇭🇺",
    name: "flag: Hungary",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1E8",
    char: "🇮🇨",
    name: "flag: Canary Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1E9",
    char: "🇮🇩",
    name: "flag: Indonesia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1EA",
    char: "🇮🇪",
    name: "flag: Ireland",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1F1",
    char: "🇮🇱",
    name: "flag: Israel",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1F2",
    char: "🇮🇲",
    name: "flag: Isle of Man",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1F3",
    char: "🇮🇳",
    name: "flag: India",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1F4",
    char: "🇮🇴",
    name: "flag: British Indian Ocean Territory",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1F6",
    char: "🇮🇶",
    name: "flag: Iraq",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1F7",
    char: "🇮🇷",
    name: "flag: Iran",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1F8",
    char: "🇮🇸",
    name: "flag: Iceland",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EE 1F1F9",
    char: "🇮🇹",
    name: "flag: Italy",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EF 1F1EA",
    char: "🇯🇪",
    name: "flag: Jersey",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EF 1F1F2",
    char: "🇯🇲",
    name: "flag: Jamaica",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EF 1F1F4",
    char: "🇯🇴",
    name: "flag: Jordan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1EF 1F1F5",
    char: "🇯🇵",
    name: "flag: Japan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1EA",
    char: "🇰🇪",
    name: "flag: Kenya",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1EC",
    char: "🇰🇬",
    name: "flag: Kyrgyzstan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1ED",
    char: "🇰🇭",
    name: "flag: Cambodia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1EE",
    char: "🇰🇮",
    name: "flag: Kiribati",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1F2",
    char: "🇰🇲",
    name: "flag: Comoros",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1F3",
    char: "🇰🇳",
    name: "flag: St. Kitts & Nevis",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1F5",
    char: "🇰🇵",
    name: "flag: North Korea",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1F7",
    char: "🇰🇷",
    name: "flag: South Korea",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1FC",
    char: "🇰🇼",
    name: "flag: Kuwait",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1FE",
    char: "🇰🇾",
    name: "flag: Cayman Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F0 1F1FF",
    char: "🇰🇿",
    name: "flag: Kazakhstan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1E6",
    char: "🇱🇦",
    name: "flag: Laos",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1E7",
    char: "🇱🇧",
    name: "flag: Lebanon",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1E8",
    char: "🇱🇨",
    name: "flag: St. Lucia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1EE",
    char: "🇱🇮",
    name: "flag: Liechtenstein",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1F0",
    char: "🇱🇰",
    name: "flag: Sri Lanka",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1F7",
    char: "🇱🇷",
    name: "flag: Liberia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1F8",
    char: "🇱🇸",
    name: "flag: Lesotho",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1F9",
    char: "🇱🇹",
    name: "flag: Lithuania",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1FA",
    char: "🇱🇺",
    name: "flag: Luxembourg",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1FB",
    char: "🇱🇻",
    name: "flag: Latvia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F1 1F1FE",
    char: "🇱🇾",
    name: "flag: Libya",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1E6",
    char: "🇲🇦",
    name: "flag: Morocco",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1E8",
    char: "🇲🇨",
    name: "flag: Monaco",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1E9",
    char: "🇲🇩",
    name: "flag: Moldova",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1EA",
    char: "🇲🇪",
    name: "flag: Montenegro",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1EB",
    char: "🇲🇫",
    name: "flag: St. Martin",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1EC",
    char: "🇲🇬",
    name: "flag: Madagascar",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1ED",
    char: "🇲🇭",
    name: "flag: Marshall Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F0",
    char: "🇲🇰",
    name: "flag: North Macedonia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F1",
    char: "🇲🇱",
    name: "flag: Mali",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F2",
    char: "🇲🇲",
    name: "flag: Myanmar (Burma)",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F3",
    char: "🇲🇳",
    name: "flag: Mongolia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F4",
    char: "🇲🇴",
    name: "flag: Macao SAR China",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F5",
    char: "🇲🇵",
    name: "flag: Northern Mariana Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F6",
    char: "🇲🇶",
    name: "flag: Martinique",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F7",
    char: "🇲🇷",
    name: "flag: Mauritania",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F8",
    char: "🇲🇸",
    name: "flag: Montserrat",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1F9",
    char: "🇲🇹",
    name: "flag: Malta",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1FA",
    char: "🇲🇺",
    name: "flag: Mauritius",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1FB",
    char: "🇲🇻",
    name: "flag: Maldives",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1FC",
    char: "🇲🇼",
    name: "flag: Malawi",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1FD",
    char: "🇲🇽",
    name: "flag: Mexico",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1FE",
    char: "🇲🇾",
    name: "flag: Malaysia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F2 1F1FF",
    char: "🇲🇿",
    name: "flag: Mozambique",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1E6",
    char: "🇳🇦",
    name: "flag: Namibia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1E8",
    char: "🇳🇨",
    name: "flag: New Caledonia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1EA",
    char: "🇳🇪",
    name: "flag: Niger",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1EB",
    char: "🇳🇫",
    name: "flag: Norfolk Island",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1EC",
    char: "🇳🇬",
    name: "flag: Nigeria",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1EE",
    char: "🇳🇮",
    name: "flag: Nicaragua",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1F1",
    char: "🇳🇱",
    name: "flag: Netherlands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1F4",
    char: "🇳🇴",
    name: "flag: Norway",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1F5",
    char: "🇳🇵",
    name: "flag: Nepal",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1F7",
    char: "🇳🇷",
    name: "flag: Nauru",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1FA",
    char: "🇳🇺",
    name: "flag: Niue",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F3 1F1FF",
    char: "🇳🇿",
    name: "flag: New Zealand",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F4 1F1F2",
    char: "🇴🇲",
    name: "flag: Oman",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1E6",
    char: "🇵🇦",
    name: "flag: Panama",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1EA",
    char: "🇵🇪",
    name: "flag: Peru",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1EB",
    char: "🇵🇫",
    name: "flag: French Polynesia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1EC",
    char: "🇵🇬",
    name: "flag: Papua New Guinea",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1ED",
    char: "🇵🇭",
    name: "flag: Philippines",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1F0",
    char: "🇵🇰",
    name: "flag: Pakistan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1F1",
    char: "🇵🇱",
    name: "flag: Poland",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1F2",
    char: "🇵🇲",
    name: "flag: St. Pierre & Miquelon",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1F3",
    char: "🇵🇳",
    name: "flag: Pitcairn Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1F7",
    char: "🇵🇷",
    name: "flag: Puerto Rico",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1F8",
    char: "🇵🇸",
    name: "flag: Palestinian Territories",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1F9",
    char: "🇵🇹",
    name: "flag: Portugal",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1FC",
    char: "🇵🇼",
    name: "flag: Palau",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F5 1F1FE",
    char: "🇵🇾",
    name: "flag: Paraguay",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F6 1F1E6",
    char: "🇶🇦",
    name: "flag: Qatar",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F7 1F1EA",
    char: "🇷🇪",
    name: "flag: Réunion",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F7 1F1F4",
    char: "🇷🇴",
    name: "flag: Romania",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F7 1F1F8",
    char: "🇷🇸",
    name: "flag: Serbia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F7 1F1FA",
    char: "🇷🇺",
    name: "flag: Russia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F7 1F1FC",
    char: "🇷🇼",
    name: "flag: Rwanda",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1E6",
    char: "🇸🇦",
    name: "flag: Saudi Arabia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1E7",
    char: "🇸🇧",
    name: "flag: Solomon Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1E8",
    char: "🇸🇨",
    name: "flag: Seychelles",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1E9",
    char: "🇸🇩",
    name: "flag: Sudan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1EA",
    char: "🇸🇪",
    name: "flag: Sweden",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1EC",
    char: "🇸🇬",
    name: "flag: Singapore",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1ED",
    char: "🇸🇭",
    name: "flag: St. Helena",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1EE",
    char: "🇸🇮",
    name: "flag: Slovenia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1EF",
    char: "🇸🇯",
    name: "flag: Svalbard & Jan Mayen",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1F0",
    char: "🇸🇰",
    name: "flag: Slovakia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1F1",
    char: "🇸🇱",
    name: "flag: Sierra Leone",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1F2",
    char: "🇸🇲",
    name: "flag: San Marino",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1F3",
    char: "🇸🇳",
    name: "flag: Senegal",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1F4",
    char: "🇸🇴",
    name: "flag: Somalia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1F7",
    char: "🇸🇷",
    name: "flag: Suriname",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1F8",
    char: "🇸🇸",
    name: "flag: South Sudan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1F9",
    char: "🇸🇹",
    name: "flag: São Tomé & Príncipe",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1FB",
    char: "🇸🇻",
    name: "flag: El Salvador",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1FD",
    char: "🇸🇽",
    name: "flag: Sint Maarten",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1FE",
    char: "🇸🇾",
    name: "flag: Syria",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F8 1F1FF",
    char: "🇸🇿",
    name: "flag: Eswatini",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1E6",
    char: "🇹🇦",
    name: "flag: Tristan da Cunha",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1E8",
    char: "🇹🇨",
    name: "flag: Turks & Caicos Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1E9",
    char: "🇹🇩",
    name: "flag: Chad",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1EB",
    char: "🇹🇫",
    name: "flag: French Southern Territories",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1EC",
    char: "🇹🇬",
    name: "flag: Togo",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1ED",
    char: "🇹🇭",
    name: "flag: Thailand",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1EF",
    char: "🇹🇯",
    name: "flag: Tajikistan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1F0",
    char: "🇹🇰",
    name: "flag: Tokelau",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1F1",
    char: "🇹🇱",
    name: "flag: Timor-Leste",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1F2",
    char: "🇹🇲",
    name: "flag: Turkmenistan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1F3",
    char: "🇹🇳",
    name: "flag: Tunisia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1F4",
    char: "🇹🇴",
    name: "flag: Tonga",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1F7",
    char: "🇹🇷",
    name: "flag: Türkiye",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1F9",
    char: "🇹🇹",
    name: "flag: Trinidad & Tobago",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1FB",
    char: "🇹🇻",
    name: "flag: Tuvalu",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1FC",
    char: "🇹🇼",
    name: "flag: Taiwan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1F9 1F1FF",
    char: "🇹🇿",
    name: "flag: Tanzania",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FA 1F1E6",
    char: "🇺🇦",
    name: "flag: Ukraine",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FA 1F1EC",
    char: "🇺🇬",
    name: "flag: Uganda",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FA 1F1F2",
    char: "🇺🇲",
    name: "flag: U.S. Outlying Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FA 1F1F3",
    char: "🇺🇳",
    name: "flag: United Nations",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FA 1F1F8",
    char: "🇺🇸",
    name: "flag: United States",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FA 1F1FE",
    char: "🇺🇾",
    name: "flag: Uruguay",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FA 1F1FF",
    char: "🇺🇿",
    name: "flag: Uzbekistan",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FB 1F1E6",
    char: "🇻🇦",
    name: "flag: Vatican City",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FB 1F1E8",
    char: "🇻🇨",
    name: "flag: St. Vincent & Grenadines",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FB 1F1EA",
    char: "🇻🇪",
    name: "flag: Venezuela",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FB 1F1EC",
    char: "🇻🇬",
    name: "flag: British Virgin Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FB 1F1EE",
    char: "🇻🇮",
    name: "flag: U.S. Virgin Islands",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FB 1F1F3",
    char: "🇻🇳",
    name: "flag: Vietnam",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FB 1F1FA",
    char: "🇻🇺",
    name: "flag: Vanuatu",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FC 1F1EB",
    char: "🇼🇫",
    name: "flag: Wallis & Futuna",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FC 1F1F8",
    char: "🇼🇸",
    name: "flag: Samoa",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FD 1F1F0",
    char: "🇽🇰",
    name: "flag: Kosovo",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FE 1F1EA",
    char: "🇾🇪",
    name: "flag: Yemen",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FE 1F1F9",
    char: "🇾🇹",
    name: "flag: Mayotte",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FF 1F1E6",
    char: "🇿🇦",
    name: "flag: South Africa",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FF 1F1F2",
    char: "🇿🇲",
    name: "flag: Zambia",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F1FF 1F1FC",
    char: "🇿🇼",
    name: "flag: Zimbabwe",
    category: "Flags (country-flag)",
    group: "Flags",
    subgroup: "country-flag",
  },
  {
    codes: "1F3F4 E0067 E0062 E0065 E006E E0067 E007F",
    char: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    name: "flag: England",
    category: "Flags (subdivision-flag)",
    group: "Flags",
    subgroup: "subdivision-flag",
  },
  {
    codes: "1F3F4 E0067 E0062 E0073 E0063 E0074 E007F",
    char: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    name: "flag: Scotland",
    category: "Flags (subdivision-flag)",
    group: "Flags",
    subgroup: "subdivision-flag",
  },
  {
    codes: "1F3F4 E0067 E0062 E0077 E006C E0073 E007F",
    char: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    name: "flag: Wales",
    category: "Flags (subdivision-flag)",
    group: "Flags",
    subgroup: "subdivision-flag",
  },
];

export default Emojies;
