import React, { useState, useEffect, useRef } from "react";
import ColorDropdown from "../../../Dropdown/ColorDropdown";

const CustomerNameViewNotes = ({
  setNotesSetting,
  notesSetting,
  handleNotesClick,
  textareaRef,
  visible,
  children,
  notesId,
  notesTitle,
  notesCategory,
  colourCodes,
  selectedFile,
  resetFile,
  customerTaskCategory,
  customerColor,
  handleSubmit,
  handleInputChange,
  formData,
  handleSetColour

}) => {
  const formRef = useRef(null);
  const handleInsertColur=(color)=>{
   
    handleSetColour(color)
   
  }
  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <div className="border  rounded-5px w-[598px] ">
        <div className="grid grid-cols-1">
          <div className={`flex flex-row relative  `}>
            <input
                type="text"
                placeholder="Note Title . . ."
                className="px-2 w-77per border mr-2  rounded-5px focus:ring-0 border-D2DAE1 focus:border-778FA5 h-[35px] mt-3 ml-2"
                onClick={() => handleNotesClick("addnotes")}
                onChange={handleInputChange}
                name="notes_title"
              
              />
              <select
                className="form-select w-[119px] rounded-5px h-[35px] mt-3 focus:ring-0 border-D2DAE1 focus:border-778FA5 mr-2 pt-1"
                onChange={handleInputChange}
                name="notes_category_id"
                defaultValue=""
              >
                <option value="" disabled>
                  Category
                </option>
                {customerTaskCategory?.data?.notes.map((cat) => (
                  <option value={cat.id} key={cat.id}>
                    {cat.value}
                  </option>
                ))}
              </select>
              <span className="mt-3">
                <ColorDropdown colors={customerColor} handleInsertColur={handleInsertColur}  onChange={handleInputChange} colourCodes={colourCodes}/>
              </span>
          </div>
          {children}
        </div>
      </div>

      
    </form>
  );
};


export default CustomerNameViewNotes;
