
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CUSTOMER_TASK_DATA_GET, CUSTOMERTABLE_VALUE_UPDATE,
    CUSTOMER_TABLEDROP_DATA,ADD_UPDATE_DROPDOWN,
    CUSTOMER_OTHERDETAIL_DYNAMIC_ADD_UPDATE,
     BASE_URL ,
     CUSTOMER_TASK_CATEGORY,
     CUSTOMER_CLOSED_TASK_GET,
     CUSTOMER_SOCIAL_DATA_GET,
     CUSTMOER_ARTIFACT_DATA_GET,
     CUSTOMER_REPORTING_DATA_GET,
     CUSTOMERTABLE_TASK_VALUE_UPDATE,
     CUSTOMER_OVERDUSE_TASK_GET,
     CUSTOMER_CONTACT_DATA_GET,
     CUSTOMER_SINGLE_UPDATE_CONTACT_PERSON,
     CUSTOMER_TASK_GET_TASKPOD,
     CUSTOMER_ACTIVITY_GET_EVENT,
     CUSTOMER_SERVICE_DATA_GET,
     CUSTOMER_SERVICE_SINGLE_UPDATE,
     CUSTOMER_ARTIFACT_SINGLE_UPDATE,
     GET_COMPANY_ADDRRESS,
     SINGLE_UPDATE_SOCIAL_LINK,
     SINGLE_UPDATE_REPORTING_TEAM,
     CUSTOMER_ADDRESS_DATA_GET,
     CUSTOMER_ALL_ADDRESS,
     CUSTOMER_TASK_HEADER,
     COMPANY_PROFILE_DETAIL,
     CUSTOMER_ADDRESS_SINGLE_GET,
     CUSTMOER_NOTES_DATA_GET,
     CUSTOMER_GET_ALL_NOTES_DATA_BASEDON_SCROLL,
     CUSTOMER_GET_ALL_ACTIVITY_DATA_BASED_ON_SCROLL,
     USER_LIST_DATA,
     ADD_UPDATE_USER_LIST,
     MANAGE_LIST_GET,
     DYNAMIC_LIST_DATA,
     DYNAMIC_HEADER_LIST,
     EDITE_DYNAMIC_VALUE,
     ADD_UPDATE_DYANMIC_TABLE,
     GET_ALL_DATA_RELETED_TO_DESIGNATION,
     GET_COMPANY_DELEGETION,
     TASK_GET_ORGACCORDING,
     GET_ALL_CUSTOMER_DELEGATION,
     GET_ALL_ADDRESS_COMPANY,
     EDIT_COMPANY_ADDRESS_BASED_ON_ID,
     GET_VENDOR_LIST,
     GET_SOCIAL_LINK_VENDOR,
     GET_VENDOR_CONTACT_DATA,
     SINGLE_VENDOR_ADD_UPDATE_CONTACT_DATA,
     GET_VENDOR_ALL_ADDRESS,
     EDIT_VENDOR_ADDRESS,
     GET_CUSTOMER_GROUP_DATA_ADD_UPDATE_TIME,
     ENABLE_USER,
     DELETE_TABLE_CATEGORIES_DATA,
     DELETE_EXTERNAL_USER,
     GET_ALL_CUSTOMER_DELEGATION_RIGHT
    } from '../constants';
import { fetchVendorSocail } from '../vendor/vendorSocialSlice';

export const customerTaskTableShowUpdate = createApi({
  reducerPath: 'customerTaskTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    // fetchCustomerTaskTableData: builder.query({
    //   query: ({ token, customerId, pagination, status,quiFilter }) => {
    //     let url = `${CUSTOMER_TASK_DATA_GET}${customerId}?status=${status}&quiFilter=${encodeURIComponent(quiFilter)}&${pagination}`;
    //     return {
    //       url,
    //       method: 'GET',
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         'Content-Type': 'application/json',
    //       },
    //     };
    //   },
    // }),
    
    fetchCustomerTaskTableData: builder.query({
      query: ({ token, customerId,owner,company_id, pagination, status, quiFilter }) => {
        
        const params = new URLSearchParams({
          status,
          quiFilter: encodeURIComponent(quiFilter),
          ...pagination,
        });
        
    
        const url = `${CUSTOMER_TASK_DATA_GET}${customerId}?${params.toString()}&owner=${owner}&company_id=${company_id}`;
        return {
          url,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
      },
    }),
    updateCustomerTaskTableData: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMERTABLE_TASK_VALUE_UPDATE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchCustomerTaskTableDataQuery,
  useUpdateCustomerTaskTableDataMutation,
} = customerTaskTableShowUpdate;


export const TaskGetOrgAccording = createApi({
  reducerPath: 'TaskGetOrgAccording',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchTaskGetOrgAccording: builder.query({
      query: ({ token, customerId,owner,company_id, pagination, status, quiFilter }) => { 
        const params = new URLSearchParams({
          status,
          quiFilter: encodeURIComponent(quiFilter),
          ...pagination,
        });
      
          const url = `${TASK_GET_ORGACCORDING}?customer_id=${customerId}?&owner=${owner}&company_id=${company_id}`;
        return {
          url,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
      },
    }),
  }),
});

export const {
  useFetchTaskGetOrgAccordingQuery,
} = TaskGetOrgAccording;

export const customerDocumentUpdate = createApi({
  reducerPath: 'customerDocumentUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerDocumentData: builder.query({
      //get document data
      query: ({ token, customerId, pagination }) => ({
        url: `${CUSTMOER_ARTIFACT_DATA_GET}${customerId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCustomerDocumentData: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMER_ARTIFACT_SINGLE_UPDATE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchCustomerDocumentDataQuery,
  useUpdateCustomerDocumentDataMutation,
} = customerDocumentUpdate;

export const customerReportingTeamUpdate = createApi({
  reducerPath: 'customerReportingTeamUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerReportingData: builder.query({
      query: ({ token, customerId, pagination }) => ({
        url: `${CUSTOMER_REPORTING_DATA_GET}${customerId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCustomerReportingData: builder.mutation({
      query: ({ token, data }) => ({
        url: SINGLE_UPDATE_REPORTING_TEAM,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchCustomerReportingDataQuery,
  useUpdateCustomerReportingDataMutation,
} = customerReportingTeamUpdate;

export const customerSocialShowUpdate = createApi({
  reducerPath: 'customerSocialShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerSocialData: builder.query({
      query: ({ token, customerId, pagination }) => ({
        url: `${CUSTOMER_SOCIAL_DATA_GET}${customerId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCustomerSocialData: builder.mutation({
      query: ({ token, data }) => ({
        url: SINGLE_UPDATE_SOCIAL_LINK,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchCustomerSocialDataQuery,
  useUpdateCustomerSocialDataMutation,
} = customerSocialShowUpdate;



export const customerTaskTableDropAdd = createApi({
    reducerPath: 'customerTaskTableDropAdd',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
      fetchCustomerTaskTable: builder.query({
        query: ({token}) => ({
          url: CUSTOMER_TASK_CATEGORY,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }),
      }),
      CustomerTaskTableDropAdd: builder.mutation({
        query: ({ token, data }) => ({
          url: ADD_UPDATE_DROPDOWN,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: data,
        }),
      }),
    }),
  });
export const {
  useLazyFetchCustomerTaskTableQuery,
  useCustomerTaskTableDropAddMutation,
} = customerTaskTableDropAdd;


export const custTableHeadFieldDynAddUp = createApi({
  reducerPath: 'custTableHeadFieldDynAddUp',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    CustTableHeadFieldDyn: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMER_OTHERDETAIL_DYNAMIC_ADD_UPDATE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    })
  }),
});

export const {
  useCustTableHeadFieldDynMutation,
} = custTableHeadFieldDynAddUp;

// export const customerTaskOverTableShowUpdate = createApi({
//   reducerPath: 'customerTaskOverTableShowUpdate',
//   baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
//   endpoints: (builder) => ({
//     fetchCustomerTaskOverTableData: builder.query({
//       query: ({ token, customerId, pagination }) => ({
//         url: `${CUSTOMER_TASK_Over_DATA_GET}${customerId}?${pagination}`,
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       }),
//     }),
//     updateCustomerTaskOverTableData: builder.mutation({
//       query: ({ token, data }) => ({
//         url: CUSTOMERTABLE_TASK_VALUE_UPDATE,
//         method: 'POST',
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//         body: data,
//       }),
//     }),
//   }),
// });

// export const {
//   useFetchCustomerTaskOverTableDataQuery,
//   useUpdateCustomerTaskOverTableDataMutation,
// } = customerTaskOverTableShowUpdate;

export const customerTaskDueTableShowUpdate = createApi({
  reducerPath: 'customerTaskDueTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerTaskDueTableData: builder.query({
      query: ({ token, customerId, pagination }) => ({
        url: `${CUSTOMER_OVERDUSE_TASK_GET}${customerId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCustomerTaskDueTableData: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMERTABLE_TASK_VALUE_UPDATE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchCustomerTaskDueTableDataQuery,
  useUpdateCustomerTaskDueTableDataMutation,
} = customerTaskDueTableShowUpdate;

export const customerTaskCloseTableShowUpdate = createApi({
  reducerPath: 'customerTaskCloseTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerTaskCloseTableData: builder.query({
      query: ({ token, customerId, pagination }) => ({
        url: `${CUSTOMER_CLOSED_TASK_GET}${customerId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
  useFetchCustomerTaskCloseTableDataQuery
} = customerTaskCloseTableShowUpdate;

export const customerContactTableShowUpdate = createApi({ 
  reducerPath: 'customerContactTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerContactTableData: builder.query({
      query: ({ token, customerId, pagination }) => ({
        url: `${CUSTOMER_CONTACT_DATA_GET}${customerId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCustomerContactTableData: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMER_SINGLE_UPDATE_CONTACT_PERSON,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchCustomerContactTableDataQuery,
  useUpdateCustomerContactTableDataMutation,
} = customerContactTableShowUpdate;

export const customerTaskPodTableShowUpdate = createApi({
  reducerPath: 'customerTaskPodTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerTaskPodTableData: builder.query({
      query: ({ token, customerId, pagination ,quiFilter }) => ({
        url: `${CUSTOMER_TASK_GET_TASKPOD}${customerId}?quiFilter=${encodeURIComponent(quiFilter)}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCustomerTaskPodTableData: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMERTABLE_TASK_VALUE_UPDATE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});


export const {
  useFetchCustomerTaskPodTableDataQuery,
  useUpdateCustomerTaskPodTableDataMutation,
} = customerTaskPodTableShowUpdate;

export const customerTaskActivityCount = createApi({
  reducerPath: 'customerTaskActivityCount',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerTaskActivityCount: builder.query({
      query: ({ token, taskId}) => ({
        url: `${CUSTOMER_ACTIVITY_GET_EVENT}?task_id=${encodeURIComponent(taskId)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
 useFetchCustomerTaskActivityCountQuery
} = customerTaskActivityCount;

export const customerServiceTableShowUpdate = createApi({
  reducerPath: 'customerServiceTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerServiceTableData: builder.query({
      query: ({ token, customerId, pagination }) => ({
        url: `${CUSTOMER_SERVICE_DATA_GET}${customerId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCustomerServiceTableData: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMER_SERVICE_SINGLE_UPDATE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchCustomerServiceTableDataQuery,
  useUpdateCustomerServiceTableDataMutation,
} = customerServiceTableShowUpdate;






export const companyTableShowUpdate = createApi({
  reducerPath: 'companyTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCompanyTableData: builder.query({
      query: ({ token, customerId, pagination }) => ({
        url: `${GET_COMPANY_ADDRRESS}${customerId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCompanyTableData: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMER_SERVICE_SINGLE_UPDATE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});


export const {
 useFetchCompanyTableDataQuery,
 useLazyFetchCompanyTableDataQuery
} = companyTableShowUpdate;




export const customerAddressTableShowUpdate = createApi({
  reducerPath: 'customerAddressTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerAddressTableData: builder.query({
      query: ({ token, customerId, pagination }) => ({
        url: `${CUSTOMER_ADDRESS_DATA_GET}${customerId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
  useFetchCustomerAddressTableDataQuery
} = customerAddressTableShowUpdate;





export const customerAllAddressTableShowUpdate = createApi({
  reducerPath: 'customerAllAddressTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerAllAddressTableData: builder.query({
      query: ({ token, customerId, pagination }) => ({
        url: `${CUSTOMER_ALL_ADDRESS}${customerId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCustomerAllAddressTableData: builder.mutation({
      query: ({ token, data }) => ({
        url: CUSTOMER_ALL_ADDRESS,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchCustomerAllAddressTableDataQuery,
  useUpdateCustomerAllAddressTableDataMutation,
} = customerAllAddressTableShowUpdate;



export const customerTaskHeaderApi = createApi({
  reducerPath: 'customerTaskHeaderApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerHeaderTask: builder.query({
      query: ({ token}) => ({
        url: `${CUSTOMER_TASK_HEADER}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
  useFetchCustomerHeaderTaskQuery
} = customerTaskHeaderApi;



export const customerCompanyDataApi = createApi({
  reducerPath: 'customerCompanyDataApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerCompanyData: builder.query({
      query: ({ token,company_profile_id}) => ({
        url: `${COMPANY_PROFILE_DETAIL}?profile_id=${encodeURIComponent(company_profile_id)}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
 useFetchCustomerCompanyDataQuery
} = customerCompanyDataApi;



export const customerAddressSingleDataApi = createApi({
  reducerPath: 'customerAddressSingleDataApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerAddressSingle: builder.query({
      query: ({ token,addressId}) => ({
        url: `${CUSTOMER_ADDRESS_SINGLE_GET}${addressId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
 useFetchCustomerAddressSingleQuery
} = customerAddressSingleDataApi;




export const customerNotesTableDataApi = createApi({
  reducerPath: 'customerNotesTableDataApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerNotesTableData: builder.query({
      query: ({ token,customerId}) => ({
        url: `${CUSTMOER_NOTES_DATA_GET}${customerId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
 useFetchCustomerNotesTableDataQuery,
 useLazyFetchCustomerNotesTableDataQuery

} = customerNotesTableDataApi;






export const customerNotesDataTableShowUpdate = createApi({
  reducerPath: 'customerNotesDataTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerNotesDataTableData: builder.query({
      query: ({ token, customerId, page ,quiFilter }) => ({
        url: `${CUSTOMER_GET_ALL_NOTES_DATA_BASEDON_SCROLL}${customerId}?page=${page}&quiFilter=${quiFilter}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});


export const {
  useFetchCustomerNotesDataTableDataQuery
} = customerNotesDataTableShowUpdate;



export const customerActivityDataTableShowUpdate = createApi({
  reducerPath: 'customerActivityDataTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerActivityDataTableData: builder.query({
      query: ({ token, taskid, page }) => ({
        url: `${CUSTOMER_GET_ALL_ACTIVITY_DATA_BASED_ON_SCROLL}${taskid}?page=${page}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});


export const {
  useFetchCustomerActivityDataTableDataQuery
} = customerActivityDataTableShowUpdate;



export const userDataTableShowUpdate = createApi({
  reducerPath: 'userDataTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchUserDataTableData: builder.query({
      query: ({ token, pagination,quiFilter }) => ({
        url: `${USER_LIST_DATA}${pagination}&${new URLSearchParams(quiFilter).toString()}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      providesTags:["GET"]
    }),
    AddUpdateUserList: builder.mutation({
      query: ({ token, data }) => ({
        url: ADD_UPDATE_USER_LIST,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
    UpdateUserActive: builder.mutation({
      query: ({ token, data }) => ({
        url: ENABLE_USER,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body:data,
      }),
      invalidatesTags:["GET"]
    }),
    DeleteExternalUser: builder.mutation({
      query: ({ token, data }) => ({
        url: DELETE_EXTERNAL_USER,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body:data,
      }),
      invalidatesTags:["GET"]
    }),
  }),
});


export const {
  useAddUpdateUserListMutation,
  useFetchUserDataTableDataQuery,
  useUpdateUserActiveMutation,
  useDeleteExternalUserMutation
  
} = userDataTableShowUpdate;




export const manageDataTableShowUpdate = createApi({
  reducerPath: 'manageDataTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchManageDataTableData: builder.query({
      query: ({ token, pagination }) => ({
        url: `${MANAGE_LIST_GET}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});


export const {
 useFetchManageDataTableDataQuery
} = manageDataTableShowUpdate;

export const dynamicDataTableShowUpdate = createApi({
  reducerPath: 'dynamicDataTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchDynamicDataTableData: builder.query({
      query: ({ token, dynamicId,pagination }) => ({
        url: `${DYNAMIC_LIST_DATA}${dynamicId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
      providesTags:["Gets"]
    }),
    updateDynamicDataTableData: builder.mutation({
      query: ({ token, data }) => ({
        url: `${DELETE_TABLE_CATEGORIES_DATA}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body:data
      }),
      invalidatesTags:["Gets"]
    }),
  }),
});


export const {
 useFetchDynamicDataTableDataQuery,useUpdateDynamicDataTableDataMutation
} = dynamicDataTableShowUpdate;

export const dynamicHeader = createApi({
  reducerPath: 'dynamicHeader',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchDynamicHeader: builder.query({
      query: ({ token, dynamicId }) => ({
        url: `${DYNAMIC_HEADER_LIST}${dynamicId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});




export const {
useFetchDynamicHeaderQuery
} = dynamicHeader;



export const dynamicEditValue = createApi({
  reducerPath: 'dynamicEditValue',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchDynamicEditValue: builder.query({
      query: ({ token, id,getId }) => ({
        url: `${EDITE_DYNAMIC_VALUE}${id}?getId=${getId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    AddUpdateDynamicList: builder.mutation({
      query: ({ token, data }) => ({
        url: ADD_UPDATE_DYANMIC_TABLE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});


export const {
  useAddUpdateDynamicListMutation,
 useFetchDynamicEditValueQuery
} = dynamicEditValue;


export const getAllData = createApi({
  reducerPath: 'getAllData',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchGetAllData: builder.query({
      query: ({ token }) => ({
        url: `${GET_ALL_DATA_RELETED_TO_DESIGNATION}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});


export const {
useFetchGetAllDataQuery
} = getAllData;




export const companyDelegation = createApi({
  reducerPath: 'companyDelegation',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCompanyDelegation: builder.query({
      query: ({token,pagination}) => ({
        url: `${GET_COMPANY_DELEGETION}${pagination}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCompanyDelegation: builder.mutation({
      query: ({ token, data }) => ({
        url: ADD_UPDATE_DROPDOWN,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});
export const {
useFetchCompanyDelegationQuery,
useUpdateCompanyDelegationMutation,
} = companyDelegation;



export const customerDelegation = createApi({
  reducerPath: 'customerDelegation',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerDelegation: builder.query({
      query: ({token,pagination}) => ({
        url: `${GET_COMPANY_DELEGETION}${pagination}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateCustomerDelegation: builder.mutation({
      query: ({ token, data }) => ({
        url: ADD_UPDATE_DROPDOWN,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});
export const {
useFetchCustomerDelegationQuery,
useLazyFetchCustomerDelegationQuery

} = customerDelegation;






export const customerTableDelegation = createApi({
  reducerPath: 'customerTableDelegation',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerTableDelegation: builder.query({
      query: ({token,pagination,customerId,rightTabFLag}) => ({
        url: rightTabFLag?GET_ALL_CUSTOMER_DELEGATION_RIGHT:`${GET_ALL_CUSTOMER_DELEGATION}${customerId}${pagination}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});
export const {
useFetchCustomerTableDelegationQuery,
useLazyFetchCustomerTableDelegationQuery
} = customerTableDelegation;




export const companyAddressSingleDataApi = createApi({
  reducerPath: 'companyAddressSingleDataApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCompanyAddressSingle: builder.query({
      query: ({ token,addressId}) => ({
        url: `${EDIT_COMPANY_ADDRESS_BASED_ON_ID}${addressId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
 useFetchCompanyAddressSingleQuery
} = companyAddressSingleDataApi;





export const companyAddressTableShowUpdate = createApi({
  reducerPath: 'companyAddressTableShowUpdate',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCompanyAddressTableData: builder.query({
      query: ({ token, companyId, pagination }) => ({
        url: `${GET_ALL_ADDRESS_COMPANY}${companyId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
 useFetchCompanyAddressTableDataQuery
} = companyAddressTableShowUpdate;






export const vendorList = createApi({
  reducerPath: 'vendorList',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchVendorList: builder.query({
      query: ({ token, companyId, pagination }) => ({
        url: `${GET_VENDOR_LIST}${companyId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateVendorSingle: builder.mutation({
      query: ({ token, data }) => ({
        url: SINGLE_UPDATE_REPORTING_TEAM,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchVendorListQuery,
  useUpdateVendorSingleMutation
} = vendorList;





export const vendorSocial = createApi({
  reducerPath: 'vendorSocial',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchVendorSocail: builder.query({
      query: ({ token, vendorId, pagination }) => ({
        url: `${GET_SOCIAL_LINK_VENDOR}${vendorId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateVendorSocail: builder.mutation({
      query: ({ token, data }) => ({
        url: SINGLE_UPDATE_SOCIAL_LINK,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
useFetchVendorSocailQuery,
useUpdateVendorSocailMutation
} = vendorSocial;




export const vendorContact = createApi({
  reducerPath: 'vendorContact',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchVendorContact: builder.query({
      query: ({ token, vendorId, pagination }) => ({
        url: `${GET_VENDOR_CONTACT_DATA}${vendorId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateVendorContact: builder.mutation({
      query: ({ token, data }) => ({
        url: SINGLE_VENDOR_ADD_UPDATE_CONTACT_DATA,
        method: 'POST',
        headers: {  
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: data,
      }),
    }),
  }),
});

export const {
useFetchVendorContactQuery,
useUpdateVendorContactMutation
} = vendorContact;




export const vendorAllAddress = createApi({
  reducerPath: 'vendorAllAddress',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchVendorAllAddress: builder.query({
      query: ({ token, vendorId, pagination }) => ({
        url: `${GET_VENDOR_ALL_ADDRESS}${vendorId}${pagination}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
 useFetchVendorAllAddressQuery
} = vendorAllAddress;




export const editVendorAddress = createApi({
  reducerPath: 'editVendorAddress',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchEditVendorAddress: builder.query({
      query: ({ token,addressId}) => ({
        url: `${EDIT_VENDOR_ADDRESS}${addressId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    })
  }),
});

export const {
 useFetchEditVendorAddressQuery
} = editVendorAddress;


export const customerGroup = createApi({
  reducerPath: 'customerGroup',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchCustomerGroup: builder.query({
      query: ({ token }) => ({
        url: `${GET_CUSTOMER_GROUP_DATA_ADD_UPDATE_TIME}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

// export const deleteTableCategoriesData = createApi({
//   reducerPath: 'customerGroup',
//   baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
//   endpoints: (builder) => ({
//     fetchCustomerGroup: builder.query({
//       query: ({ token, data }) => ({
//         url: `${DELETE_TABLE_CATEGORIES_DATA}`,
//         method: 'POST',
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       }),
//     }),
//   }),
// });

export const deleteTableCategoriesData = createApi({
  reducerPath: 'dynamicDataTableDeleteData',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    fetchDynamicDataTableData: builder.query({
      query: ({ token, data }) => ({
        url: `${DELETE_TABLE_CATEGORIES_DATA}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body:data
      }),
    })
  }),
});

export const {deleteCustomerGroup}=deleteTableCategoriesData

export const { useFetchCustomerGroupQuery } = customerGroup;









