
import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit';

import { saveData ,getCommonDataBasedOnId,deleteData,
  updateData

} from '../../components/store/customer/CustomerSocialAddUpdateFunction';
import { REPORTING_ADD_UPDATE ,CUSTOMER_REPORTING_DATA_GET,CUSTOMER_REPORTING_DELETE,
  CUSTOMER_REPORTING_STATUS
} from '../constants';

export const saveReportTeamMemberDataAsync = createAsyncThunk(
  'teamMembers/saveReportTeamMemberDataAsync',
  async ({ socialData, token }, thunkAPI) => {
    try {
         const requestData = [socialData] 
        const endpoint = (socialData.id === undefined || socialData.id=== '') ? 
          REPORTING_ADD_UPDATE : CUSTOMER_REPORTING_STATUS;
        const response = await ((socialData.id === undefined || socialData.id === '') ? 
          saveData : updateData)(
          endpoint,
          requestData,
          token
        );


        if(response?.status === true) {
          const url = `${CUSTOMER_REPORTING_DATA_GET}${socialData.customer_id}`;
          const getData = await getCommonDataBasedOnId(url, token);
          thunkAPI.dispatch(setReportingTeam(getData));
        }
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const fetchCustomerReportingTeam = createAsyncThunk(
  'customerReportingTeam/fetchCustomerReportingTeam',
  async ({ customerId, token },thunkAPI) => {
    try {
      const url = `${CUSTOMER_REPORTING_DATA_GET}${customerId}`;
      
      const getData = await getCommonDataBasedOnId(url, token);
      const socialData=getData?.data?.data;
      if (Array.isArray(getData) && getData.length > 0) {
        const processedData = getData.map(member => ({
          ...member,
          teamChecked: member.status === 1 ? true : false
        }));
      //  console.log("0000000000000000",processedData);
        thunkAPI.dispatch(setReportingTeam(processedData));
        return processedData;
      } else {
       
        return thunkAPI.rejectWithValue('Data is not an array or is empty');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteReportingTeamMemberAsync = createAsyncThunk(
  'customerReportingTeam/deleteReportingTeamMemberAsync',
  async ({ id, token }, thunkAPI) => {
   // console.log(token);return false;
    try {
      const url =`${CUSTOMER_REPORTING_DELETE}${id}`
      const response = await deleteData(url,token);
      return response;
     
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const reportingTeamSlice = createSlice({
  name: 'customerReportingTeam',
  initialState: {
    pointerStyle: 0,
    message: null,
    showMessage: false,
    teamMembers: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    
   
  
    setReportingTeam: (state, action) => {
     
      state.teamMembers =action.payload;
    },
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    removeReportingMemberAtIndex: (state, action) => {
      const indexToRemove = action.payload;
      state.teamMembers = state.teamMembers.filter((_, index) => index !== indexToRemove);
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
      state.filteredTeamMembers = state.teamMembers.filter(teamMember =>
        teamMember.reporting_person_email.toLowerCase().includes(state.searchQuery.toLowerCase()) ||
        teamMember.reporting_person_name.toLowerCase().includes(state.searchQuery.toLowerCase())
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveReportTeamMemberDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveReportTeamMemberDataAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message = action.payload.message;
        state.showMessage = true;
     
      })
      .addCase(saveReportTeamMemberDataAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      })
      .addCase(fetchCustomerReportingTeam.pending, (state) => {
        state.status = 'loading';
        
      })
      .addCase(fetchCustomerReportingTeam.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.teamMembers = action.payload;
       
      })
      .addCase(fetchCustomerReportingTeam.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteReportingTeamMemberAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteReportingTeamMemberAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
       
        state.message = action.payload.message;
        state.showMessage = false;
      })
      .addCase(deleteReportingTeamMemberAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      });
  },
});

export const { addTeamMember, inputChange, save, addTeamCheck,setReportingTeam ,hideMessage,showMessage,removeReportingMemberAtIndex,setSearchQuery} = reportingTeamSlice.actions;
export default reportingTeamSlice.reducer;
