import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import NotesEditor from "../TextEditor/NotesEditor";
import ViewNotesData from "./ViewNotesData";
import {
  deleteNotes,
  saveNotes,
} from "../../utils/customer/customerNotesSlice";
import { BASE_URL } from "../../utils/constants";

import {
  useFetchTaskDataQuery,
  useFetchCustomerNotesColorDataQuery,
} from "../../utils/customer/customerSelect";
import { CUSTMOER_NOTES_ATTACHMENT_DATA_GET } from "../../utils/constants";
import { deleteData } from "../store/customer/CustomerSocialAddUpdateFunction";
import { useFetchCustomerNotesDataTableDataQuery } from "../../utils/customer/customerTaskTable";

const AllNotesShow = ({
  filterData,
  disable,
  setDisable,
  refreshKey,
  containerRef,
  taskOpenTab
}) => {
  const { token } = useRequireAuth();
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const { id: customerId } = useParams();
  const [editNoteId, setEditNoteId] = useState(null);
  const [refreshKeys, setRefreshKeys] = useState(0);
  const [notesData, setNotesData] = useState([]);
  const prevScrollY = useRef(0);
  
  const [viewNotes, setViewNotes] = useState({
    allnotes: false,
    addnotes: false,
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [formData, setFormData] = useState({
    notes_title: "",
    notes_category_id: "",
    colour_code: "",
  });
  const [notesBody, setNotesBody] = useState(null);
  const notesDatasFormSubmit = useSelector((state) => state.notes);
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [visible, setVisible] = useState(true);
  const [color, setColor] = useState(null);
  const [page, setPage] = useState(10);

  const { data: customerTableALL, refetch: refetchCustomerData } = useFetchCustomerNotesDataTableDataQuery({
    token: token,
    customerId: customerId,
    page: `${page}`,
    quiFilter: filterData,
  }, {
    skip: taskOpenTab !== 2, // Skip the query if taskOpenTab is not 2
  });

  useEffect(() => {
    if (customerTableALL?.data && customerTableALL?.data?.notes?.length > 0 && taskOpenTab===2) {
      setNotesData(customerTableALL?.data);
    }
  }, [customerTableALL,taskOpenTab]);

  useEffect(() => {
    if(taskOpenTab===2){
      refetchCustomerData({
        token: token,
        customerId: customerId,
        page: `${page}`,
        quiFilter: filterData,
      });
    }
   
  }, [page, token,taskOpenTab,notesDatasFormSubmit.notes,refreshKey]);

 

  const handleNotesEdit = (id, notesbody, attachment, title) => {
    setFormData({
      notes_title: title,
    });
    setEditNoteId(id);
    setViewNotes({ allnotes: true, addnotes: true });
    setNotesBody(notesbody);
    setSelectedFiles(attachment);
    setVisible(false);
  };

  const handleDelete = async (id) => {
    try {
      const response = await dispatch(deleteNotes({ id, token }));
      const { payload } = response;
      if (payload?.status === true) {
        refetchCustomerData();
        if (notesData?.length > 0) {
          setNotesData((prevNotes) => {
            if (!Array.isArray(prevNotes)) {
              console.error("prevNotes is not an array", prevNotes);
              return prevNotes;
            }
            return prevNotes.filter((note) => note?.id !== id);
          });
        } else {
          setNotesData([]);
        }
      }
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  const handleNotesClick = (key) => {
    setViewNotes((prevState) => ({ ...prevState, [key]: true }));
  };

  const hndleCloseNotes = () => {
    textareaRef.current.value = "";
    setViewNotes({ allnotes: false, addnotes: false });
    setEditNoteId(null);
    setVisible(true);
  };

  const downloadFile = async (file) => {
    const url = `${BASE_URL}${file}`;

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSetColour = (colour) => setColor(colour);

  const handleSubmit = (e) => {
    e.preventDefault();
    const notesData = {
      notes_category_id: e.target.notes_category_id.value,
      colour_code: color,
      notes_title: e.target.notes_title.value,
      company_id: parsedCompanyData.company_id,
      customer_id: customerId,
      notes_body: textareaRef.current?.innerHTML || notesBody,
      rag_status: "wip",
      attachment: selectedFiles,
      id: editNoteId,
    };

    if (textareaRef.current?.innerHTML !== "") {
      dispatch(saveNotes({ notesData, token }));
      setFormData({ notes_title: "", notes_category_id: "", colour_code: "" });
      hndleCloseNotes();
      refetchCustomerData();
      setEditNoteId(null);
      setVisible(true);
      setSelectedFiles([]);
    }
  };

  const {
    data: customerTaskCategory,
    error,
    isLoading,
  } = useFetchTaskDataQuery({
    token,
    jsonData: {
      customer_id: customerId,
      company_id: parsedCompanyData.company_id,
    },
  }, {
    skip: taskOpenTab !== 2, // Skip the query if taskOpenTab is not 2
  });

  const {
    data: customerColor,
    error: customerCodeerror,
    isLoading: customerColorLoading,
  } = useFetchCustomerNotesColorDataQuery({ token }, {
    skip: taskOpenTab !== 2, // Skip the query if taskOpenTab is not 2
  });

  const resetFile = (newSelectedFiles = []) =>
    setSelectedFiles(newSelectedFiles);
  const handleDeleteFileServer = async (id, editNoteId) => {
    const url = `${CUSTMOER_NOTES_ATTACHMENT_DATA_GET}${id}`;
    try {
      const newSelectedFiles = selectedFiles.filter((f) => f.id !== id);
      resetFile(newSelectedFiles);
      const response = await deleteData(url, token);
      if (response.status) {
        setViewNotes({ allnotes: true, addnotes: true });
        setEditNoteId(editNoteId);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleClickDeleteFile = (index) => {
    if (index >= 0 && index < selectedFiles?.length) {
      resetFile(selectedFiles.filter((file, idx) => idx !== index));
    }
  };

  const handleFileUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.click();

    input.addEventListener("change", (event) => {
      const files = Array.from(event.target.files);
      let updatedFiles = selectedFiles.slice();

      if (selectedFiles && selectedFiles?.length > 0) {
        const lastFile = selectedFiles[selectedFiles?.length - 1];
        const lastId = lastFile.id;
        files.forEach((file, index) => {
          updatedFiles.push({
            id: lastId + index + 1,
            attachment: file.name,
            file: file,
          });
        });
      } else {
        files.forEach((file, index) => {
          updatedFiles.push({
            id: index + 1,
            attachment: file.name,
            file: file,
          });
        });
      }
      setSelectedFiles(updatedFiles);
    });

    return false;
  };
  const getFileLastName = (filePath) => {
    if (filePath && typeof filePath === "string" && filePath.includes("/")) {
      return filePath.split("/").pop();
    } else {
      return filePath;
    }
  };

  useEffect(() => {
    const handleScroll = async() => {
      const container = containerRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;

        if (
          scrollTop > prevScrollY.current &&
          page <= customerTableALL?.data?.total
        ) {
          setPage((prev) => prev + 1);
        } else if (page >= 10) {
          setPage((prev) => (prev > 1 ? prev - 1 : prev));
        }
        prevScrollY.current = scrollTop;
      }
    };
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [page, customerTableALL?.data, containerRef]);

  

  return (
    <React.Fragment>
      {notesData?.notes?.length > 0 &&
        notesData?.notes.map((note) => (
          <div className="py-1 border-t-2 border-1d446933" key={note.id}>
            <div className="flex text-sm">
              <i className={`${note.notesimg} text-556080 text-3xl me-4`}></i>
              <div className="w-full">
                {editNoteId === note.id && viewNotes.addnotes ? (
                  <div>
                    <ViewNotesData
                      setNotesSetting={setViewNotes}
                      notesSetting={viewNotes.addnotes}
                      visible={viewNotes.addnotes}
                      handleNotesClick={handleNotesClick}
                      textareaRef={textareaRef}
                      notesTitle={note.notes_title}
                      notesId={note.id}
                      notesCategory={note.category_name}
                      onDataFetch={setNotesData}
                      colourCodes={note.colour_code}
                      selectedFile={selectedFiles}
                      resetFile={resetFile}
                      customerTaskCategory={customerTaskCategory}
                      customerColor={customerColor}
                      handleSubmit={handleSubmit}
                      handleInputChange={handleInputChange}
                      formData={formData}
                      handleSetColour={handleSetColour}
                      editNoteId={editNoteId}
                    >
                      <div
                        className={`grid ${
                          viewNotes.addnotes ? "show" : "hidden"
                        }`}
                      >
                        <NotesEditor
                          notesBody={note.notes_body}
                          textareaRef={textareaRef}
                          initialContent={note.notes_body}
                          notesSetting={viewNotes.addnotes}
                          handleFileUpload={handleFileUpload}
                          selectedFile={selectedFiles}
                          downloadFiles={downloadFile}
                          handleDeleteFileServer={handleDeleteFileServer}
                          editNoteId={editNoteId}
                          handleClickDeleteFile={handleClickDeleteFile}
                          hndleCloseNotes={hndleCloseNotes}
                          key={selectedFiles?.length}
                          getFileLastName={getFileLastName}
                        />
                      </div>
                    </ViewNotesData>
                  </div>
                ) : (
                  <React.Fragment>
                    <div
                      className={`flex justify-between ${
                        editNoteId === note.id ? "hidden" : "visible"
                      }`}
                    >
                      <div className="flex flex-row mb-1 gap-x-3">
                        <div className="flex items-center">
                          <h1 className="text-red-500">
                            <i
                              className="bi bi-person-circle"
                              style={{ color: "rgb(41, 87, 165)" }}
                            ></i>
                          </h1>
                        </div>
                        <div>
                          <p className="text-2C495D font-600">
                            {note.notes_title}
                          </p>
                          <p className="text-F16136 text-xs">
                            {note.updated_by}{" "}
                            <span className="text-2999BC">
                              {" "}
                              | {note.created_at}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="border border-[rgba(44, 73, 93, 0.20)] rounded-md p-1 px-3 text-2C495D">
                          <span className={note.category_name ? "me-3" : ""}>
                            {note.category_name}
                          </span>
                          <i
                            className="bi bi-circle-fill"
                            style={{ color: note.colour_code || "#000" }}
                          ></i>
                        </div>
                        {disable === true && visible === true && (
                          <Fragment>
                            <button
                              type="button"
                              className="mx-4"
                              onClick={() =>
                                handleNotesEdit(
                                  note.id,
                                  note.notes_body,
                                  note.attach,
                                  note.notes_title
                                )
                              }
                            >
                              <i className="bi bi-pencil-square text-F16136"></i>
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDelete(note.id)}
                            >
                              <i className="bi bi-trash3 text-F16136"></i>
                            </button>
                          </Fragment>
                        )}
                      </div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: note.notes_body }}
                      className="py-1"
                    />
                  </React.Fragment>
                )}
                {!viewNotes.addnotes && (
                  <div className="flex flex-col pt-2">
                    <div className="text-xs flex flex-wrap space-x-2 m-2">
                      {note?.attach &&
                        note?.attach.map((attachment) => (
                          <div
                            key={attachment.id}
                            className="flex items-center space-x-2 border p-2 rounded  h-9"
                          >
                            <span className="truncate">
                              {getFileLastName(attachment.attachment)}
                            </span>
                            <button
                              className="text-blue-500 hover:text-blue-700"
                              onClick={() =>
                                downloadFile(attachment.attachment)
                              }
                            >
                              <i className="bi bi-eye text-14"></i>
                            </button>
                          </div>
                        ))}
                    </div>
                    <div className="text-xs flex flex-col pt-1">
                      <div className="flex items-center justify-end">
                        <span className="text-red-500">
                          Last Updated: {note.updated_by}{" "}
                          <span className="text-2999BC">
                            {" "}
                            | {note.updated_at}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </React.Fragment>
  );
};

export default AllNotesShow;
