import React, { Fragment,useState,useEffect } from 'react'
import useClickOutside from '../../hooks/Screen/useClickOutside';
import FirstMessage from '../GlobalFeature/MessageAlert/FirstMessage';
import useCustomFormValidation from '../../hooks/ErrorHandling/useCustomFormValidation';
import useRequireAuth from '../../utils/useRequireAuth';
import { useFetchSelectPermissionQuery } from '../../utils/users/permissions';
import { useFetchCompanyListQuery } from '../../utils/company/companySelector';
import { useOrgGroupAddUpdateMutation, useorgGroupMutation } from '../../utils/users/Organizations';
import OwnerDropDown from '../GlobalFeature/Fields/OwnerDropDown';
import { useLazyFetchPersonInTableQuery } from '../../utils/customer/customerTable';


const validationRules = {
 
  group_name: {
    required: true,
    errorMessage: 'Cannot be left blank',
  },
  // owner:{
  //   required: true,
  //   errorMessage: 'Cannot be left blank',
  // },
  permission_level_id: {
    required: true,
    errorMessage: 'Cannot be left blank',
  }

};



const AddPermission = ({refetchData,setIsPopupOpen,isPopupOpen}) => {
  const userdata = useRequireAuth();


  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });


  const initialFormData = {
    group_name: "",
    about_group: "",
    owner: "",
    permission_level_id: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (isPopupOpen) {
      setFormData({
        id: isPopupOpen?.customer_id?.value || "",
        group_name: isPopupOpen?.group_name?.value || "",
        about_group: isPopupOpen?.about_group?.value || "",
        permission_level_id: isPopupOpen?.permission_id?.value || "",
      });
    } else {
      setFormData(initialFormData);
    }
  }, [isPopupOpen]);

  const { values, errors, touched, handleChange, handleBlur, validateField, isFormValid } = useCustomFormValidation(
    formData,
    validationRules
  );

  const isPopupOpenclickoutside = useClickOutside(() => {
    setIsPopupOpen(false);
  });

  const {
    data: permissionDropData,
    error,
    permissionLoading,
} = useFetchSelectPermissionQuery(userdata?.token);


  const handleFieldChange = (e) => {
    handleChange(e);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [fetchPersonInTable, { data: personTableData }] =
  useLazyFetchPersonInTableQuery();

const fetchPersonData = (data) => {
  fetchPersonInTable({ token: userdata?.token, data: data });
};

  const [addUpdateGroup, { isLoading, isError, isSuccess }] = useOrgGroupAddUpdateMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formValid = isFormValid();

    if (!formValid) {
      return;
    }


    try {
      const response = await addUpdateGroup({ token: userdata?.token, data: formData });
     

      if (response) {
        setAlert({
          visible: true,
          message: "success updated",
          type: "success",
        });
        setFormData(initialFormData);
        setIsPopupOpen(false);
        refetchData();
      } else if (response?.error) {
        setAlert({
          visible: true,
          message: response?.error,
          type: "danger",
        });
      }
    } catch (err) {
      console.error("Error while adding/updating user:", err);
    }
  };

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };


  const tableDetail = {
    tableidclass: "usermodelpermission",
  };

  const handleInsert = (item) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      owner: item,
    }));
  };


  return (
    <Fragment>
      <FirstMessage
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onHide={hideAlert}
      />
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-gray-900 bg-opacity-50">
          <div
            className="relative mt-18 bg-white  font-popns drop-shadow-lg  rounded-md w-[520px] overflow-hidden no-scrollbar"
            ref={isPopupOpenclickoutside}
          >
            <div className="mx-6 flex items-center justify-between  md:py-3  border-b border-BECBD3 rounded-t">
              <h3 className="text-xl text-434852 font-medium">{formData?.group_name ? 'Edit' : 'Create'} Group</h3>
              <button
                onClick={() => {
                  setIsPopupOpen(false);
                }}
                type="button"
                className="text-454545 hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                data-modal-hide="assets-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <form
              className={`w-full lg:max-h-[85vh]  overflow-auto pt-3`}
              onSubmit={handleSubmit}
            >
            
              <div className="grid gap-1 px-6">
                <div>
                  <label htmlFor="group_name" className="block pb-1">
                    <span className="text-sm font-400  text-434852 ">
                      Name
                    </span>
                    <span className=" text-red-500"> *</span>                    
                  </label>
                  <input
                    type="text"
                    id="group_name"
                    name="group_name"
                    value={formData?.group_name}
                    onChange={handleFieldChange}
                    className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs block w-full h-[35px]"
                  />
                  {errors.group_name && (
                      <span className="text-red-500 text-xxs">{errors.group_name}</span>
                    )}
                </div>
                <div  className='pt-0'>
                  <label
                    htmlFor="about_group"
                    className="block text-lg text-434852"
                  >
                    <span className="text-sm font-400 text-434852">
                      About Me
                    </span>                  
                  </label>
                  <textarea 
                  className='w-full border-DFE2E5 text-12 rounded' 
                  rows={4}
                  id="about_group"
                  name="about_group"
                  onChange={handleFieldChange}
                  value={formData?.about_group}

                  >
                  </textarea>
                </div>
                <div className='mb-3 '>
                  <label htmlFor="permission_level_id" className="block pb-1">
                    <span className="font-400	text-sm text-434852">
                        Permission Level
                    </span>
                    <span className=" text-red-500"> *</span>                    
                  </label>
                
                  <select className='border-DFE2E5 py-0 form-select text-12 font-400 w-full h-[35px] '  id="permission_level_id" name="permission_level_id"  value={formData.permission_level_id} onChange={handleFieldChange}>
                       <option hidden selected >Select Permission Level</option>
                        {permissionLoading ? (
                            <option>Loading...</option>
                        ) : (
                          permissionDropData?.data?.map(permission => (
                                <option key={permission.id} value={permission.id} >
                                    {permission.permission_level}
                                </option>
                            ))
                        )}
                  </select>
                  {errors.permission_level_id && (
                      <span className="text-red-500 text-xxs">{errors.permission_level_id}</span>
                    )}
                </div>
              </div>
              
             

              <div className="sticky bottom-0 bg-white pb-3 pr-4 flex justify-end  text-md w-full rounded-none z-[9998]">
                <div className="px-2">
                  <button
                    onClick={() => {
                      setIsPopupOpen(false);
                    }}
                    type="button"
                    className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-1 text-center rounded-5px"
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold text-gray-900 border border-gray-200 py-1 px-5 ms-3 rounded-5px"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

    </Fragment>
  )
}

export default AddPermission