// import { useMemo } from 'react';

// const useGroupAndAggregateData = (data, groupByHeaders) => {
//   const groupedData = useMemo(() => {
//     if (groupByHeaders.length === 0) return data;

//     const groupDataRecursively = (items, groupByHeaders, level = 0) => {
//       if (level >= groupByHeaders.length) return items;

//       const groupedData = {};
//       for (const item of items) {
//         const groupKey = item[groupByHeaders[level].key];
//         if (!groupedData[groupKey]) groupedData[groupKey] = [];
//         groupedData[groupKey].push(item);
//       }

//       const nestedGroups = [];
//       for (const key in groupedData) {
//         nestedGroups.push({
//           groupValue: key,
//           items: groupDataRecursively(groupedData[key], groupByHeaders, level + 1)
//         });
//       }

//       return nestedGroups;
//     };

//     return groupDataRecursively(data, groupByHeaders);
//   }, [data, groupByHeaders]);

//   return groupedData;
// };

// export default useGroupAndAggregateData;






import { useMemo } from 'react';
import sha256 from 'crypto-js/sha256';

const useGroupAndAggregateData = (data, groupByHeaders, parentId = '') => {
  const groupedData = useMemo(() => {
    if (groupByHeaders.length === 0) return data;

    const groupDataRecursively = (items, groupByHeaders, level = 0, parentKey = '') => {
      if (level >= groupByHeaders.length) return items;

      const groupedData = {};
      for (const item of items) {
        const groupKey = item[groupByHeaders[level].key]?.value;

        if (!groupedData[groupKey]) groupedData[groupKey] = [];

        groupedData[groupKey].push(item);

       
      }

      const nestedGroups = [];
      let index = 0; // Unique index for each group
      for (const key in groupedData) {
        const uniqueKey = `${sha256(parentKey + parentId + level + index++)}`;
        nestedGroups.push({
          groupKey: uniqueKey,
          value: key,
          items: groupDataRecursively(groupedData[key], groupByHeaders, level + 1)
        });
      }

      return nestedGroups;
    };

    return groupDataRecursively(data, groupByHeaders);
  }, [data, groupByHeaders, parentId]);

  return groupedData;
};

export default useGroupAndAggregateData;
