// import { useEffect, useRef, useState } from 'react';

// const useColumnResizer = () => {
//   const [startX, setStartX] = useState(null); // Track starting X position of mouse
//   const [startWidth, setStartWidth] = useState(null); // Track starting width of column
//   const [pressed, setPressed] = useState(false); // Flag to track mouse press
//   const [resizingColumn, setResizingColumn] = useState(null); // Track currently resizing column
//   const theadRef = useRef(null); // Ref for thead element

//   const handleMouseDown = (event, column) => {
//     const resizeBar = event.target;
//     const th = resizeBar.closest('th');
//     setPressed(true);
//     setResizingColumn(th);
//     setStartX(event.pageX);
//     setStartWidth(th.offsetWidth);
//     document.addEventListener('mousemove', handleMouseMove);
//     document.addEventListener('mouseup', handleMouseUp);
//   };

//   const handleMouseMove = (event) => {
//     if (pressed && resizingColumn) {
//       const newWidth = startWidth + (event.pageX - startX);
//       if (newWidth > 0) {
//         resizingColumn.style.width = `${newWidth}px`;
//       }
//     }
//   };

//   const handleMouseUp = () => {
//     if (pressed) {
//       setPressed(false);
//       setResizingColumn(null);
//       document.removeEventListener('mousemove', handleMouseMove);
//       document.removeEventListener('mouseup', handleMouseUp);
//     }
//   };

//   const handleDoubleClick = (column) => {
//     if (column && theadRef.current) {
//       const thIndex = Array.from(theadRef.current.children).indexOf(column);
//       const cells = Array.from(document.querySelectorAll(`td:nth-child(${thIndex + 1})`));
//       const maxWidth = Math.max(
//         ...cells.map((cell) => cell.getBoundingClientRect().width)
//       );
//       column.style.width = `${maxWidth}px`;
//     }
//   };

//   useEffect(() => {
//     // Cleanup on unmount or when pressed/resizingColumn changes
//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//       document.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [pressed, resizingColumn]);

//   return { theadRef, handleMouseDown, handleDoubleClick };
// };

// export default useColumnResizer;










// import { useState, useRef, useCallback, useEffect } from 'react';

// const useResizableColumn = (initialWidths) => {
//   const [columnWidths, setColumnWidths] = useState(initialWidths);
//   const resizingColumnRef = useRef(null);
//   const startXRef = useRef(0);
//   const startWidthRef = useRef(0);

//   const onMouseMove = useCallback((e) => {
//     if (resizingColumnRef.current !== null) {
//       const newWidth = startWidthRef.current + (e.clientX - startXRef.current);
//       setColumnWidths((prevWidths) => ({
//         ...prevWidths,
//         [resizingColumnRef.current]: newWidth,
//       }));
//     }
//   }, []);

//   const onMouseUp = useCallback(() => {
//     resizingColumnRef.current = null;
//     document.removeEventListener('mousemove', onMouseMove);
//     document.removeEventListener('mouseup', onMouseUp);
//   }, [onMouseMove]);

//   const onMouseDown = useCallback((e, columnKey) => {
//     resizingColumnRef.current = columnKey;
//     startXRef.current = e.clientX;
//     startWidthRef.current = columnWidths[columnKey];
//     document.addEventListener('mousemove', onMouseMove);
//     document.addEventListener('mouseup', onMouseUp);
//   }, [columnWidths, onMouseMove, onMouseUp]);

//   useEffect(() => {
//     return () => {
//       document.removeEventListener('mousemove', onMouseMove);
//       document.removeEventListener('mouseup', onMouseUp);
//     };
//   }, [onMouseMove, onMouseUp]);

//   return { columnWidths, onMouseDown };
// };

// export default useResizableColumn;





import { useRef, useState, useEffect } from "react";

const useResizableColumn = () => {
  const tableRef = useRef(null);
  const [columnWidths, setColumnWidths] = useState([]);

  useEffect(() => {
    if (tableRef.current) {
      const columns = Array.from(tableRef.current.querySelectorAll("th"));
      const initialColumnWidths = columns.map((th, index) => {
        const cells = Array.from(
          tableRef.current.querySelectorAll(`td:nth-child(${index + 1})`)
        );
        const maxContentWidth = cells.reduce((maxWidth, cell) => {
          const cellWidth = cell.scrollWidth;
          return Math.max(maxWidth, cellWidth);
        }, th.scrollWidth);

        return maxContentWidth + 16; // Add some padding
      });

      setColumnWidths(initialColumnWidths);
    }
  }, [tableRef]);

  const handleMouseDown = (index, event) => {
    const startX = event.clientX;
    const startWidth = columnWidths[index];

    const handleMouseMove = (moveEvent) => {
      const newWidth = startWidth + moveEvent.clientX - startX;
      setColumnWidths((prevWidths) => {
        const newWidths = [...prevWidths];
        newWidths[index] = newWidth;
        return newWidths;
      });
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleDoubleClick = (index) => {
    if (tableRef.current) {
      const th = tableRef.current.querySelectorAll("th")[index];
      const cells = Array.from(
        tableRef.current.querySelectorAll(`td:nth-child(${index + 1})`)
      );
      const maxContentWidth = cells.reduce((maxWidth, cell) => {
        const cellWidth = cell.scrollWidth;
        return Math.max(maxWidth, cellWidth);
      }, th.scrollWidth);

      setColumnWidths((prevWidths) => {
        const newWidths = [...prevWidths];
        newWidths[index] = maxContentWidth + 16; // Add some padding
        return newWidths;
      });
    }
  };

  return { columnWidths, handleMouseDown, tableRef, handleDoubleClick };
};

export default useResizableColumn;
