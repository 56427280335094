
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
     BASE_URL, 
     ADDUPDATE_ORG, 
     ADDUPDATE_ORG_MEMBER

    } from '../constants';
    
export const orgGroupAddUpdate = createApi({
        reducerPath: 'orgGroupAddUpdate',
        baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
        endpoints: (builder) => ({
         
            orgGroupAddUpdate: builder.mutation({
            query: ({token,data}) => ({
              url : `${ADDUPDATE_ORG}`,
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
          }),
        }),
});
      
export const {
        useOrgGroupAddUpdateMutation,
} = orgGroupAddUpdate;




 
export const orgGroupAddUpdateMember = createApi({
    reducerPath: 'orgGroupAddUpdateMember',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
     
        orgGroupAddUpdateMember: builder.mutation({
        query: ({token,data}) => ({
          url : `${ADDUPDATE_ORG_MEMBER}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      }),
    }),
});
  
export const {
    useOrgGroupAddUpdateMemberMutation,
} = orgGroupAddUpdateMember;