import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import { saveData , getCommonDataBasedOnId,deleteData,updateData} from "../../components/store/customer/CustomerSocialAddUpdateFunction";
import { CUSTOMER_SOCIAL_ADD_UPDATE,CUSTOMER_SOCIAL_DATA_GET,CUSTOMER_SOCIAL_DATA_DELETE,CUSTOMER_SOCAIL_LINK_STATUS } from "../constants";

export const saveSocialLinkDataAsync = createAsyncThunk(
  'socialMembers/saveSocialLinkDataAsync',
  async ({ socialData, token }, thunkAPI) => {
    try {

      const requestData =[socialData];
      const endpoint = (socialData.id === undefined || socialData.id === '') ? 
      CUSTOMER_SOCIAL_ADD_UPDATE : CUSTOMER_SOCAIL_LINK_STATUS;
     
      const response = await ((socialData.id === undefined || socialData.id === '') ? 
          saveData : updateData)(
          endpoint,
          requestData,
          token
        );      
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const fetchSocialLinkData = createAsyncThunk(
  'customerSocialLink/fetchSocialLinkData',
  async ({ customerId, token },thunkAPI) => {
    try {
      const url = `${CUSTOMER_SOCIAL_DATA_GET}${customerId}`;
      const getData = await getCommonDataBasedOnId(url, token);
      const socialData=getData?.data?.data;
   
      if (Array.isArray(socialData) && socialData.length > 0) {
        const processedData = socialData.map(member => ({
          ...member,
          socialChecked: member.status === 1 ? true : false
        }));
        thunkAPI.dispatch(setSocialMembers(processedData));
        return processedData;
      } else {
       
        return thunkAPI.rejectWithValue('Data is not an array or is empty');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteSocialLinkDataAsync = createAsyncThunk(
  'customerSocialLink/deleteSocialLinkDataAsync',
  async ({ itemId, token }, { rejectWithValue }) => {
    try {
      const response = await deleteData(`${CUSTOMER_SOCIAL_DATA_DELETE}${itemId}`,token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const socialLinkSlice = createSlice({
  name: "customerSocialLink",
  initialState: {
    pointerStyle: 0,
    message: null,
    showMessage: false,
    socialMembers: [{ link_type: "", link: "", socialChecked: true }],
    status: 'idle',
    error: null,
  },
  reducers: {
    addSocialMember: (state) => {
      if (state.pointerStyle === 1) {
        state.socialMembers.unshift({
          link_type: "",
          link: "",
          socialChecked: true
        });
        state.pointerStyle = 0;
      }
    },
    inputChange: (state, action) => {
      const { index, name, value } = action.payload;
      state.socialMembers[index][name] = value;
    },
    save: (state, action) => {
      const { payload } = action;
      
      state.pointerStyle = 1;
    },
    addSocialCheck: (state, action) => {
     
      const { indexno, socialChecked } = action.payload;
      state.socialMembers[indexno].socialChecked = socialChecked;
    },
    setSocialMembers: (state, action) => {
      const processedData = action.payload.map((member) => ({
        ...member,
        socialChecked:member.status === 1 ? true : false
      }));
      state.socialMembers = processedData;
      state.pointerStyle = 1;
    },
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    removeSocialMemberAtIndex: (state, action) => {
      const indexToRemove = action.payload;
      state.socialMembers = state.socialMembers.filter((_, index) => index !== indexToRemove);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveSocialLinkDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveSocialLinkDataAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message = action.payload.message;
        state.showMessage = true;
        state.pointerStyle = 1;
      })
      .addCase(saveSocialLinkDataAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      })
      .addCase(fetchSocialLinkData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSocialLinkData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.teamMembers = action.payload;
        state.pointerStyle = 1;
      })
      .addCase(fetchSocialLinkData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteSocialLinkDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSocialLinkDataAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.socialMembers = state.socialMembers.filter(member => member.id !== action.payload.id);
        state.socialMembers = state.socialMembers.map((member, index) => ({
          ...member,
          index
        }));
        state.message = action.payload.message;
        state.showMessage = false;
      })
      .addCase(deleteSocialLinkDataAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      });
  },
});

export const { addSocialMember, inputChange, save, addSocialCheck, setSocialMembers,hideMessage,removeSocialMemberAtIndex} =
  socialLinkSlice.actions;
export default socialLinkSlice.reducer;
