import React, { useReducer, useEffect } from "react";
import { useState } from "react";
import SaveIcon from "../Icons/SaveIcon";
import { useSelector, useDispatch } from "react-redux";
import {
  hideMessage,
  saveContactPersonDataAsync,
  deleteContactPersonDataAsync
} from "../../utils/customer/contactPersonsSlice";
import { useParams } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import CustomerContactPersonModel from "./CustomerContactPersonModel";
import CustomerListTable from "./CustomerListTable";
import contactHeader from "./customercontactHeader";
import { useFetchCustomerContactTableDataQuery,useUpdateCustomerContactTableDataMutation } from "../../utils/customer/customerTaskTable";

import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";

const CustomerContactPerson = () => {
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const message = useSelector((state) => state.contactPersons.message);
  const showMessage = useSelector((state) => state.contactPersons.showMessage);
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [currentPage,setCurrentPage]=useState(1);
  const [alert ,setAlert]=useState(false);
  const [loading,setLoading]=useState(false);
  const dispatch = useDispatch();
  const [customer,setCustomer]=useState([]);
  const [initialFilter,setInitialFilter]=useState([]);
  const [customerTablePagination,setCustomerTablePagination] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { pointerStyle, teamMembers, messageData } = useSelector(
    (state) => state.contactPersons
  );

  const handleDelete = async(rowKey, item,itemId) => {
   const id=itemId?.id;
    try {
      await dispatch(deleteContactPersonDataAsync({ id, token }));
      refetchCustomerData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleStatusUpdate=async(rowKey, item,itemId,status)=>{
    const id=item.id.value;
    const socialData={
      id:id,
      status:status?1:0,
    }
    try {
      await dispatch(saveContactPersonDataAsync({ socialData, token }));
      refetchCustomerData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  }

  const handleSubmitData=async(customerId)=>{
    refetchCustomerData();
  }

  const { data: customerTableALL, refetch: refetchCustomerData } =
  useFetchCustomerContactTableDataQuery({
      token: token,
      customerId: customerId,
      pagination: `${customerTablePagination}`,

  });

  

  useEffect(() => {
    if (customerTableALL) {
      setCustomer(customerTableALL.data.data);
      const { data: omitData, ...restData } = customerTableALL.data;
      setInitialFilter(restData);
      setLoading(false);
    }
  }, [customerTableALL, customerTablePagination]);

  const [updateCustomerContactTableData, { CustomerDropLoad, CustomerDroperror, }] = useUpdateCustomerContactTableDataMutation();

  const customerTableFieldUpdate = async (field_name,value,id) => {
    const updateData = {
      field_name: value?.field_name,
      value: field_name?.value,
      id: value?.id
    }

    
    try {
      const result = await updateCustomerContactTableData({ token: token, data: updateData }).unwrap();
      if(result){
        refetchCustomerData()
        {result?.data?.original ?  setAlert({ visible: true, message: result?.data?.original?.error, type: 'danger' }) : setAlert({ visible: true, message: result?.message, type: 'success' })} 
      }
    } catch (error) {
      setAlert({ visible: true, message: 'Error updating data!', type: 'danger' });
    }
  }

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  setTimeout(() => {
    dispatch(hideMessage());
  }, 2000);

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  return (
    <>
      <div className="tablemaindiv">
        <div className={`${isFullscreen ? "bg-white h-full px-5 pt-2" : ""}`}>
          {showMessage && <p className="text-green-500 text-right">{message}</p>}
          <FirstMessage
            type={alert.type}
            message={alert.message}
            visible={alert.visible}
            onHide={hideAlert}
          />
            <CustomerListTable
              tableDetail={{
                tableidclass: "fullscreen-table",
                tableparentidclass: "tableparent",
                tablename: <div className="text-203C50 text-20 font-600 mt-2">Contact Person</div>,
                tablenameclass:
                  "font-medium text-16 text-203C50 capitalize",
                tableSearch: true,
                tableFilter: true,
                tableResizer: true,
                tableZoom: true,
                tableheader: false,
                dataactivity:true,
                datetedata:true,
                addbutton: (
                  <button 
                     onClick={openPopup}
                     type="button"
                     className="text-2541558A hover:text-254155"
                  >
                       <i className="bi bi-plus-lg"></i>
                  </button>
              )
              }}
              thead={contactHeader}
              tbody={customer}
              tablePaginationData={setCustomerTablePagination}
              TableFieldUpdate={customerTableFieldUpdate}
              initialFilter={initialFilter}
              handleDelete={handleDelete}
            />
            {isPopupOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-[102]">
                <CustomerContactPersonModel
                  isOpen={isPopupOpen}
                  onClose={closePopup}
                  handleSubmitData={handleSubmitData}
                />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default CustomerContactPerson;
