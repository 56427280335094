const documentHeader = [
    {
        "doc_type": "Doc Type",
        "doc_type_status": 1
    },
    // {
    //     "doc_name": "Doc-Name",
    //     "doc_name_status": 1
    // },
    {
        "file_name": "Document",
        "file_name_status": 1
    },
    {
        "descriptions": "Description",
        "description_status": 1
    },
    {
        "action": "Action",
        "action_status": 1
    },
]


export default documentHeader