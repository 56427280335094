import React, { useState, useRef, useEffect } from "react";
import { BASE_URL } from "../../../../utils/constants";

const NotesContent = ({ notesData }) => {
  const [loading, setLoading] = useState(false);
  const [contentCount, setContentCount] = useState(10);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [expandedNotes, setExpandedNotes] = useState({}); 
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const handleScroll = () => {
      if (
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 20 &&
        !loading &&
        contentCount < notesData?.length
      ) {
        setLoading(true);
        setTimeout(() => {
          setContentCount((prevCount) => {
            const newCount = Math.min(prevCount + 5, notesData?.length);
            return newCount;
          });
          setLoading(false);
        }, 1000);
      }
    };

    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [loading, contentCount, notesData?.length]);

  useEffect(() => {
    if (notesData?.length === 0) {
      setContentCount(0);
    }
  }, [notesData.length]);

  const getFileLastName = (filePath) => {
    if (filePath && typeof filePath === "string" && filePath.includes("/")) {
      return filePath.split("/").pop();
    } else {
      return filePath;
    }
  };

  const downloadFile = async (file) => {
    try {
      const url = `${BASE_URL}${file}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const urlObject = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlObject;
      if (typeof file === "string" && file.includes("/")) {
        const filename = file.split("/").pop();
        link.download = filename;
      } else {
        link.download = file;
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Error occurred while downloading the file:", error);
    }
  };

  const highlightTextInHtml = (html, highlight) => {
    if (!highlight.trim()) return html;

    const regex = new RegExp(`(${highlight})`, "gi");
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const walker = document.createTreeWalker(tempDiv, NodeFilter.SHOW_TEXT, null, false);
    let node;
    while ((node = walker.nextNode())) {
      const text = node.textContent;
      if (regex.test(text)) {
        const span = document.createElement("span");
        span.innerHTML = text.replace(regex, (match) => `<span class="bg-yellow-300">${match}</span>`);
        node.replaceWith(span);
      }
    }

    return tempDiv.innerHTML;
  };

  const toggleExpand = (id) => {
    setExpandedNotes((prevExpanded) => ({
      ...prevExpanded,
      [id]: true, 
    }));
  };

  const filteredNotes = notesData.filter((note) =>
    note.notes_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    note.notes_body.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isContentLong = (content) => {
    const testElement = document.createElement("div");
    testElement.style.position = "absolute";
    testElement.style.visibility = "hidden";
    testElement.style.height = "auto";
    testElement.style.width = "300px"; 
    testElement.style.lineHeight = "20px"; 
    testElement.innerText = content;
    document.body.appendChild(testElement);
    const isLong = testElement.clientHeight > 70; 
    document.body.removeChild(testElement);
    return isLong;
  };

  return (
    <>
      <div className="flex mt-[4px] items-center relative">
        <span className="mr-2 absolute left-3">
          <i className="bi bi-search text-gray-500"></i>
        </span>
        <div className="flex-grow">
          <input
            className="h-[35px] w-full px-8 border rounded focus:outline-none"
            type="text"
            placeholder="Search For Notes..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="flex-1 overflow-y-auto notesscrollbar mt-2 border-b">
        <div ref={containerRef}>
          {filteredNotes.slice(0, contentCount).map((note) => (
            <div className="mt-2 border-b-2" key={note.id}>
              <div>
                <div className="border-b-1 space-y-2">
                  <div className="flex">
                    <div className="flex justify-between w-full">
                      <div className="flex">
                        <div className="h-[40px] w-[40px] bg-gray-500 rounded-full text-center pt-[6px] text-20 text-white font-500">
                          kk
                        </div>
                        <div className="ml-5 pt-1">
                          <div
                            className="text-1D4469 text-[14px] font-semibold"
                            dangerouslySetInnerHTML={{
                              __html: highlightTextInHtml(note.notes_title, searchTerm)
                            }}
                          />
                          <div className="flex pt-2">
                            <div className="text-F16136 text-[12px] font-300 capitalize">
                              {note.updated_by}
                            </div>
                            <span className="pt-0 ml-1 mr-1">|</span>
                            <div className="text-F16136 text-[12px] font-300 capitalize">
                              {note.created_at}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mr-1">
                        <div className="flex border rounded m-1 h-[35px]">
                          <div className="pl-3 pt-2 text-[12px] font-400">
                            {note?.category_name}
                          </div>
                          <span className=" h-4 w-4 m-2 pt-[5px] mr-4 flex items-center justify-center">
                          <i
                            className="bi bi-circle-fill"
                            style={{ color: note.colour_code || "#000" }}
                          ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-[61px] capitalize text-535252 text-[13px] font-normal leading-[20px]">
                    {expandedNotes[note.id] ? (
                      <div  className="line-clamp-4 break-words">

                              <div    
                                dangerouslySetInnerHTML={{    
                                  __html: highlightTextInHtml(note.notes_body, searchTerm),   
                                }}    
                              />    

                      </div>
                     
                    ) : isContentLong(note.notes_body) ? (
                      <>
                        <div className="line-clamp-4 break-words">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: highlightTextInHtml(note.notes_body, searchTerm),
                            }}
                          />
                        </div>
                        <button
                          onClick={() => toggleExpand(note.id)}
                          className="capitalize text-2999BC text-[13px] font-normal"
                        >
                          Read More
                        </button>
                      </>
                    ) : (
                      <div className="line-clamp-4 break-words">

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: highlightTextInHtml(note.notes_body, searchTerm),
                                }}
                              />
                      </div>
                    )}
                  </div>
                  <div className="border-b-2"></div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {loading && (
          <div className="flex my-2 border-b border-[#203C501A]">
            <div className="mr-3">
              <div className="animate-pulse bg-gray-200 rounded-full h-[30px] w-[30px]"></div>
            </div>
            <div>
              <div className="animate-pulse bg-gray-200 h-[16px] w-[100px] mb-1"></div>
              <div className="animate-pulse bg-gray-200 h-[16px] w-[150px]"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NotesContent;
