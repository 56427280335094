import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ADD_COMPANY_GROUP, COMPANY_PROFILE } from "../../utils/constants";
import useRequireAuth from "../../utils/useRequireAuth";
import { useDispatch } from "react-redux";
import { setProfile } from "../../utils/companyProfileSlice";
import { useNavigate } from "react-router-dom";
import { COMPANY_DUPLICATE, COMPANY_DISPLAY, COMMON_URL } from "../../utils/constants";
import { Base64 } from "js-base64"; 
import { saveData, getData } from "../store/CompayProfileFuction/CompanyProfileAllFuction";

function CompanyProfile({ businessCategoryData, countryData, currencyData, initialCompanyGroup = [] }) {
  const user = useRequireAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState(null);
  const [companyDisplayName, setCompanyDisplayName] = useState(null);
  const [mobileNumber, setMobileNumber] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [companyGroup, setCompanyGroup] = useState(initialCompanyGroup);
  const [imageSrc, setImageSrc] = useState('');
  const [cusdipName, setCusdipName] = useState('');
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      setImageSrc(event.target.result);      
    };

    if (file) {      
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);    
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleKeyUp = async (e) => {
    const Authtoken = user.token;
    const searchedValue = e.target.value;
    if (searchedValue.length >= 4) {
      try {
        const url = `${COMPANY_DUPLICATE}?search_company=${encodeURIComponent(
          searchedValue
        )}`;

        const response = await getData(url, Authtoken);
        setResponseData(response);
        handleKeyUpDNAme(e);
        setCusdipName(searchedValue);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setResponseData(null);
    }
  };

  const handleKeyUpDNAme =  async(e) =>{
    const Authtoken = user.token;
    const searchedValue = e.target.value;
    if (searchedValue.length) {
      try {
        const url = `${COMPANY_DISPLAY}?search_company=${encodeURIComponent(
          searchedValue
        )}`;
        const response = await getData(url, Authtoken);
        setCompanyDisplayName(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setCompanyDisplayName(null);
    } 
  }

  const handleRadioButtonClick = (value, setFieldValue) => {
    setFieldValue("type", value);
  };

  const initialValues = {
    company_name: "",
    company_group: "",
    display_name: "",
    website: "",
    country_id: "",
    company_logo: null,
    type: "",
    business_category: "",
    legal_name: "",
    currency_id: "",
    company_phone_no: "",
    company_email: "",
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Company Name is required"),
    company_group: Yup.string().required("Company Group is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    const imgName = fileInputRef.current.files[0];
    try {
      
      const Authtoken = user.token;
      const formDataWithFile = new FormData();
      formDataWithFile.append("company_name", values.company_name);
      formDataWithFile.append("display_name", cusdipName);
      formDataWithFile.append("company_group", values.company_group);
      formDataWithFile.append("company_website", values.website);
      formDataWithFile.append("company_type", values.type);
      formDataWithFile.append("business_category", values.business_category);
      formDataWithFile.append("legal_name", values.legal_name);
      formDataWithFile.append("company_logo", imgName);
      formDataWithFile.append("country_id", values.country_id);
      formDataWithFile.append("currency_id", values.currency_id);
      formDataWithFile.append("company_phone_no", phoneNumber);
      formDataWithFile.append("company_email", values.company_email);   
      formDataWithFile.append("company_group", values.company_group);     
      const savedData = await saveData(COMPANY_PROFILE, formDataWithFile, Authtoken);
      dispatch(setProfile(savedData.data));
      const companyId = savedData.data.id;
      const companyData = {
        company_id: companyId,
        company_name: savedData.data.company_name,
        message: "Records Sucessfully created!",
      };
      localStorage.removeItem("company_data");
      localStorage.setItem("company_data", JSON.stringify(companyData));
      const encryptedCompanyId = Base64.encode(companyId);
      navigate(`/edit_company/${encryptedCompanyId}`);
      setSubmitting(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const formErrors = error.response.data.errors;
        Object.keys(formErrors).forEach((key) => {
          setFieldError(key, formErrors[key][0]);
        });
      } else {
        setFieldError("form", "An error occurred while submitting the form.");
      }
      setSubmitting(false);
    }
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    setPhoneNumber(numericValue);
  };

  const handleKeyUpEmailValidation = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setIsValidEmail(validateEmail(inputValue));
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleLabelClick = async (company_name, setFieldValue, setFieldError) => {
    const Authtoken = user.token; 

    const requestData = {
      org_id: 1, // Example org_id
      company_group: company_name
    };

    try {
    
      const response = await fetch(ADD_COMPANY_GROUP, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Authtoken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const newGroup = { id: company_name, company_group: company_name }; // Adjust according to your response structure
        setCompanyGroup([...companyGroup, newGroup]);
        setFieldValue("company_group", company_name);
      } else {
        const errorData = await response.json();
        if (errorData.errors && errorData.errors.company_group) {
          setFieldError("company_group", errorData.errors.company_group[0]);
        } else {
          throw new Error("Unknown error");
        }
      }
    } catch (error) {
      console.error("Error adding company group:", error);
      setFieldError("company_group", "An error occurred while adding the company group.");
    }
  };

  const handleChange = (e) => {   
    setCusdipName( e.target.value);
  }
  

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, setFieldError, values }) => (
          <Form id="" encType="multipart/form-data">
            <div className="flex flex-col md:flex-row px-8 pt-4">
              <div className="w-full md:w-4/5 lg:w-4/5 mt-4 md:mt-0 md:mr-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                  <div className="">
                    <Field name="company_name">
                      {({ field }) => (
                        <div className="mb-2">
                          <label htmlFor="company_name" className="mb-2 text-535252 text-14 font-500 flex justify-between">
                            <span>Name <span className=" text-red-600">*</span></span>
                            <span className="text-F16136 text-12 italic hover:text-green-400 cursor-pointer" onClick={() => handleLabelClick(values.company_name, setFieldValue, setFieldError)}>Set as Company Group</span>
                          </label>
                          <input
                            {...field}
                            className="border border-gray-300 text-xs block w-full p-2.5 rounded-5px"
                            type="text"
                            onKeyUp={handleKeyUp}
                          />
                          <ErrorMessage name="company_name" component="div" className="text-red-500 text-xxs" />
                          {responseData?.duplicate ? (
                            <div>
                              <span className="text-red-500 text-xxs">
                                {responseData?.message}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <span className="text-green-500 text-sm">
                                {responseData?.message}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="company_group"
                      className="block mb-2 text-535252 text-14 font-500"
                    >
                      Company Group <span className=" text-red-600">*</span>
                    </label>
                    <Field
                      as="select"
                      id="company_group"
                      name="company_group"
                      className="form-select border border-gray-300 text-gray-900 text-xs block w-full px-2.5 py-0.5"
                      value={values.company_group}
                      onChange={(e) => setFieldValue("company_group", e.target.value)}
                    >
                      <option value="">Select Company Group</option>
                      {companyGroup.map((companyGR) => (
                        <option key={companyGR.id} value={companyGR.company_group}>
                          {companyGR.company_group}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="company_group" component="div" className="text-red-500 text-xxs" />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                  <div className="">
                    <Field name="display_name">
                      {({ field }) => (
                        <div>
                          <label htmlFor="display_name" className="block mb-2 text-535252 text-14 font-500"> Cust. Display Name</label>
                          <input
                            {...field}
                            className="border border-gray-300 text-xs block w-full p-2.5 rounded-5px"
                            type="text"
                            value={cusdipName}
                            onKeyUp={handleKeyUpDNAme}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="display_name" component="div" className="text-red-500 text-xxs" />
                          {companyDisplayName?.duplicate ? (
                            <div>
                              <span className="text-red-500 text-xxs">
                                {companyDisplayName?.message}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <span className="text-green-500 text-sm">
                                {companyDisplayName?.message}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <Field name="type">
                    {({ field }) => (
                      <div className="mb-2">
                        <label htmlFor="types" className="block mb-2 text-535252 text-14 font-500 ">Type</label>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          <label>
                            <input
                              type="radio"
                              name="type"
                              value="business"
                              checked={values.type === "business"}
                              onChange={() => handleRadioButtonClick("business", setFieldValue)}
                              className="hidden"
                            />
                            <button
                              type="button"
                              className={`w-full px-2 py-1 text-535252 text-14 rounded-5px border cbtn-transition-bg hover:bg-gray-300 focus:outline-none option ${
                                values.type === "business" ? "bg-sky-900 text-white border-2C495D font-400" : "border font-400"}`}
                              onClick={() => handleRadioButtonClick("business", setFieldValue)}
                            >
                              Business
                            </button>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="type"
                              value="individual"
                              checked={values.type === "individual"}
                              onChange={() => handleRadioButtonClick("individual", setFieldValue)}
                              className="hidden"
                            />
                            <button
                              type="button"
                              className={`w-full px-2 py-1 text-535252 text-14 rounded-5px border cbtn-transition-bg hover:bg-gray-300 focus:outline-none option ${
                                values.type === "individual" ? "bg-sky-900 text-white border-2C495D font-400" : "border font-400"}`}
                              onClick={() => handleRadioButtonClick("individual", setFieldValue)}
                            >
                              Individual
                            </button>
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="type"
                              value="prospect"
                              checked={values.type === "prospect"}
                              onChange={() => handleRadioButtonClick("prospect", setFieldValue)}
                              className="hidden"
                            />
                            <button
                              type="button"
                              className={`w-full px-2 py-1 text-535252 text-14 rounded-5px border cbtn-transition-bg hover:bg-gray-300 focus:outline-none option ${
                                values.type === "prospect" ? "bg-sky-900 text-white border-2C495D font-400" : "border font-400"}`}
                              onClick={() => handleRadioButtonClick("prospect", setFieldValue)}
                            >
                              Prospect
                            </button>
                          </label>
                        </div>
                      </div>
                    )}
                  </Field>
                  <ErrorMessage name="type" component="div" className="text-red-500 text-xxs" />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                  <div className="">
                    <Field name="website">
                      {({ field }) => (
                        <div>
                          <label htmlFor="Website" className="block mb-2 text-535252 text-14 font-500"> Website </label>
                          <input
                            {...field}
                            className="border border-gray-300 text-xs block w-full p-2.5 rounded-5px"
                            type="text"
                          />
                          <ErrorMessage name="website" component="div" className="text-red-500 text-xxs" />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="">
                    <label htmlFor="legal_name" className="block mb-2 text-535252 text-14 font-500">Legal Name</label>
                    <Field
                      type="text"
                      id="legal_name"
                      name="legal_name"
                      className="border border-gray-300 text-xs block w-full p-2.5 rounded-5px"
                    />
                    <ErrorMessage name="legal_name" component="div" className="text-red-500 text-xxs" />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-3">
                  <div className="mb-2">
                    <label htmlFor="business_category" className="block mb-2 text-535252 text-14 font-500">Business Category</label>
                    <Field
                      as="select"
                      id="business_category"
                      name="business_category"
                      className="form-select border border-gray-300 text-gray-900 text-xs block w-full px-2.5 py-0.5"
                    >
                      <option value="">Select Category</option>
                      {!businessCategoryData ? null : (
                        businessCategoryData.map((CategoryData) => (
                          <option key={CategoryData.id} value={CategoryData.id}>
                            {CategoryData.business_category}
                          </option>
                        ))
                      )}
                    </Field>
                    <ErrorMessage name="business_category" component="div" className="text-red-500 text-xxs" />
                  </div>
                  <div className="">
                    <label htmlFor="currency_id" className="block mb-2 text-535252 text-14 font-500">Currency</label>
                    <Field
                      as="select"
                      id="currency_id"
                      name="currency_id"
                      className="form-select border border-gray-300 text-gray-900 text-xs block w-full px-2.5 py-0.5"
                    >
                      <option>Please Currency</option>
                      {!currencyData ? null : (
                        currencyData.map((CurrencyData) => (
                          <option key={CurrencyData.id} value={CurrencyData.id}>
                            {CurrencyData.currency_name_symbol}
                          </option>
                        ))
                      )}
                    </Field>
                    <ErrorMessage name="currency_id" component="div" className="text-red-500 text-xxs" />
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/5 lg:w-1/5">
                <Field name="company_logo">
                  {({ field, form }) => (
                    <div className="">
                      <div
                        htmlFor="dropzone-file"
                        className="w-40 p-1 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100"
                      >
                        <div
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          onClick={handleClick}
                          className="flex items-center h-36 w-36 text-center text-12 text-gray-500"
                        >
                          {imageSrc ? (
                            <div className="relative w-full h-full">
                              <img src={imageSrc} alt="Uploaded" className="w-full h-full object-cover rounded" />
                              <button className="upload-button absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-lg font-semibold opacity-0 transition-opacity duration-600 hover:opacity-75">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                </svg>
                              </button>
                            </div>
                          ) : (
                            'Drag & Drop or Click to Upload'
                          )}
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="flex font-poppins px-8 py-3 justify-end">
              <div className="flex gap-4">
                <button
                  type="submit"
                  className="px-6 py-1 text-base rounded font-normal bg-1D4469 text-white focus:outline-none cbtn-transition-bg"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'SAVING...' : 'SAVE'}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CompanyProfile;
