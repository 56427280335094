import { useState } from 'react';

const useFormValidation = () => {
  const [errorMessage, setErrorMessage] = useState({});

  const validateField = (fieldName, value) => {
    let validationError = '';

    switch (fieldName) {
      case 'customer_group':
        if (!value) {
          validationError = "Customer Group is required.";
        }
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          validationError = "Email is required.";
        } else if (!emailRegex.test(value)) {
          validationError = "Enter a valid email address.";
        }
        break;
      case 'customerName':
        if (!value) {
          validationError = "Customer name is required.";
        }
        break;
      case 'displayName':
        if (!value) {
          validationError = "Display name is required.";
        }
        break;
      case 'webSite':
        const domainExtensions = ['com', 'in', 'usa','uk']; 
        const regex = new RegExp(`\\.(?:${domainExtensions.join('|')})\\b`, 'i');

        if (!value) {
          validationError = "Website URL is required.";
        }
        else if (!regex.test(value)){
          validationError = "Provide Validate Url";
        }
        break;
      case 'type':
        if (!value) {
          validationError = "Type is required.";
        }
        break;
      case 'business_category':
        if (!value) {
          validationError = "Business category is required.";
        }
        break;
      case 'taxNo':
        if (!value) {
          validationError = "Tax no is required.";
        }
        break;
      case 'country_id':
        if (!value) {
          validationError = "Country is required.";
        }
        break;
      case 'phoneNumber':
        if (!value) {
          validationError = "Phone number is required.";
        }
        break;
      default:
        break;
    }

    setErrorMessage(prevState => ({
      ...prevState,
      [fieldName]: validationError
    }));

    return validationError === '';
  };

  const validateForm = (formData) => {
    const formErrors = {};

    for (const [field, value] of Object.entries(formData)) {
      
      const isValid = validateField(field, value);
      if (!isValid) {
        formErrors[field] = errorMessage[field];
      }
    }

    return {
      isValid: Object.keys(formErrors).length === 0,
      errors: formErrors,
    };
  };

  return {
    errorMessage,
    validateField,
    validateForm,
    setErrorMessage, // Adding setErrorMessage to be used outside the hook if necessary
  };
};

export default useFormValidation;
