import React, { Fragment,useRef } from "react";
import useDropdown from "../../../hooks/Dropdown/useDropDown1";
import SaveEditFirst from "../SaveEdit/SaveEditFirst";
import useDropdownPosition from "../../../hooks/Dropdown/useDropDownPosition";
import { parseJSONValue } from "../../../utils/json/parseJSONValue";
import useClickOutside from "../../../hooks/Screen/useClickOutside";
import {  Popper, Radio} from '@mui/material';
import {
  Autocomplete,
  FormControl,
  TextField,
  Box,
  Typography,
  Paper,
} from "@mui/material";

const DropDown1 = ({
  tableidclass,
  setDataInputRow,
  setEditModelStatus,
  dataInputRow,
  SpecificElement,
  initialIsOpen,
  DropData,
  UpdateTableField,
  FieldValue,
  Item,
  FirstField,
}) => {
  const parsedDropData = Array.isArray(parseJSONValue(DropData))
    ? parseJSONValue(DropData)
    : [];
  const {
    isOpen,
    setIsOpen,
    selectedOption,
    toggleDropdown,
    handleOptionChange,
  } = useDropdown(initialIsOpen, FieldValue);

  const { dropdownPosition, dropdownRef } = useDropdownPosition(
    isOpen,
    tableidclass
  );
  const SaveClickRef=useRef(null)
  const CloseClickRef=useRef(null)
  const initialState = { selectedOption: selectedOption };

  const handleClickAway = () => {
    setIsOpen(false); // Close the dropdown when clicking outside
  };

  const handleCloseClick = (e) => {
    e.stopPropagation();
    setDataInputRow(null);
    if (setEditModelStatus) {
      setEditModelStatus(null);
    }
    setIsOpen(false);
    handleOptionChange(initialState?.selectedOption);
  };

  const closeDropdownOutsideClick = useClickOutside(() => {
    setIsOpen(false);
    setDataInputRow(null);
  });

  const options = parsedDropData.map((item) => ({
    value: item[FirstField],
    color_code: item.color_code,
  }));

  const selectOnChange = (event, newValue) => {
    handleOptionChange(newValue);
  };
  // const CustomPopper = (props) => {
  //   return <Popper {...props} sx={{ '& .MuiAutocomplete-listbox': { fontSize: '16px' } }} />;
  // };

  const SaveData=()=>{
    if(SaveClickRef?.current){
      SaveClickRef.current.click()
    }
  }
  const CloseData=()=>{
    if(CloseClickRef?.current){
      CloseClickRef.current.click()
    }
  }


  // const closeoutsideclick = useClickOutside(
  //   ()=>{
  //     setTimeout(()=>{
  //     if( !Boolean(selectedOption?.value)){
  //       CloseData()
  //     }
  //     },[1000])
      
      
  //   }
  //   ,true
  // )
  
  return (
    <div >
      {/* Left side component */}
      {dataInputRow?.rowIndex === SpecificElement && (
        <SaveEditFirst
          UpdateTableField={(e) => UpdateTableField(e, selectedOption, Item)}
          handleCloseClick={handleCloseClick}
          saveClickRef={SaveClickRef}
          closeClickRef={CloseClickRef}
        />
      )}

      {/* Right side component */}
      <FormControl
        fullWidth
        variant="outlined"
        sx={{
         
          boxSizing: "border-box", // Include border in width/height calculation
          borderRadius: "4px", // Optional: Set border radius
          hover: "none",
         
        }}
      >
       <Autocomplete
       autoFocus
        options={options}
        getOptionLabel={(option) => option.value}
        value={selectedOption?.value?selectedOption:null}
        onChange={selectOnChange}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        isOptionEqualToValue={(option, value) => option.value === value?.[FirstField]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            autoFocus
            // placeholder="Select an option"
            sx={{
                  width: 150,// Adjust width
                  '& .MuiOutlinedInput-root': {
                    '& input': {
                      boxShadow: 'none', // Remove any inside shadow
                      border: 'none', // Remove any inner borders
                      outline: 'none',
                       // Remove focus outline
                    },
                  },
                  '& .MuiInputBase-root': {
                    fontSize: '11px', // Adjust font size
                    padding: '8px',
                    height: 30, // Adjust height
                  },
                }}
          />
        )}
        PaperComponent={(props) => (
          <Paper
            {...props}
            sx={{
            
              maxHeight: 183, // Adjust height as needed
              overflow: 'auto', // Ensure only one scrollbar appears
              '&::-webkit-scrollbar': {
                width: '0px', // Hide the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent', // Hide the scrollbar thumb
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.value} style={{ fontSize: '11px' }}>
            <Box display="flex" alignItems="center">
              {option.color_code && (
                <div
                  // checked={selectedOption?.value === option.value}
                  style={{ backgroundColor: option.color_code,minWidth: '20px', width: '20px', height: '20px',marginRight:"10px",borderRadius:"5px" }}
                  // sx={{
                  //   '& .MuiSvgIcon-root': {
                  //     fill: 'transparent',
                  //   },
                  // }}
                />
              )}
              <Typography variant="body2" sx={{fontSize: '11px' }}>
                {option.value}
              </Typography>
            </Box>
          </li>
        )}
  />

      </FormControl>
    </div>
  );
};








// import React, { Fragment, useState, useRef, useEffect } from 'react';
// import useDropdown from '../../../hooks/Dropdown/useDropDown1';
// import SaveEditFirst from '../SaveEdit/SaveEditFirst';
//  import useDropdownPosition from '../../../hooks/Dropdown/useDropDownPosition';
// import { parseJSONValue } from '../../../utils/json/parseJSONValue';
// import useClickOutside from '../../../hooks/Screen/useClickOutside';
// import { Popper, Button, Paper,Box, Typography } from '@mui/material';

// import { FormControl, InputLabel,TextField, Radio, Autocomplete } from '@mui/material';



const DropDown2 = ({ tableidclass, setDataInputRow, setEditModelStatus, dataInputRow, SpecificElement, initialIsOpen, DropData, UpdateTableField, FieldValue, Item, FirstField }) => {
  
  
  const parsedDropData = Array.isArray(parseJSONValue(DropData)) ? parseJSONValue(DropData) : [];
  const { isOpen, setIsOpen, selectedOption, toggleDropdown, handleOptionChange } = useDropdown(initialIsOpen, FieldValue);

  const { dropdownPosition, dropdownRef } = useDropdownPosition(isOpen, tableidclass);

  const initialState = { selectedOption: selectedOption };


  const handleCloseClick = (e) => {
    e.stopPropagation();
    setDataInputRow(null);
    if (setEditModelStatus) {
      setEditModelStatus(null);
    }
    setIsOpen(false);
    handleOptionChange(initialState?.selectedOption);
  };


  const closeDropdownOutsideClick = useClickOutside(() => {
    setIsOpen(false);
    setDataInputRow(null);
  });


  const options = parsedDropData.map((item) => ({
    value: item[FirstField],
    color_code: item.color_code,
  }));

  const selectOnChange = (e) => {
    const selectedValue = e.target.value;
    const selected = options.find(option => option[FirstField] === selectedValue);
    handleOptionChange(selected);
  }

  // const customStyles = {
  //   control: (base) => ({
  //     ...base,
  //     borderColor: '#535252',
  //     boxShadow: '0px 0px 4px 0px #535252',
  //     width: "200px",
  //     '&:hover': {
  //       borderColor: '#535252',
  //     },
  //   }),
  //   option: (base, { data, isSelected, isFocused }) => ({
  //     ...base,
  //     backgroundColor: isSelected ? data.color_code : isFocused ? '#f5f5f5' : data.color_code,
  //     color: 'black',
  //     '&:hover': {
  //       backgroundColor: data.color_code,
  //       color: 'white',
  //     },
  //   }),
  //   singleValue: (base, { data }) => ({
  //     ...base,
  //     color: 'black',
  //     backgroundColor: data.color_code,
  //     borderRadius: '1px',
  //     padding: '2px 8px',
  //     display: 'flex',
  //     alignItems: 'center',
  //   }),
  // };



  return (
    <Fragment>
      <div>

      <FormControl variant="outlined" sx={{ minWidth: 200 }}>
      <Autocomplete
        value={selectedOption}
        onChange={selectOnChange}
        options={options}
        getOptionLabel={(option) => option.value || ''}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        disablePortal
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            sx={{
              fontSize: '11px', // Font size for the input
              '& .MuiInputLabel-root': {
                fontSize: '11px', // Font size for the label
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '11px', // Font size for the input text
              },
            }}
          />
        )}
        PopperComponent={(props) => (
          <Popper {...props} style={{ width: 200, zIndex: 1300 }} />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.value} style={{ fontSize: '11px' }}>
            <Box display="flex" alignItems="center">
              {option.color_code && (
                <Radio
                  checked={selectedOption?.value === option.value}
                  style={{ backgroundColor: option.color_code, width: '20px', height: '20px' }}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fill: 'transparent',
                    },
                  }}
                />
              )}
              <Typography variant="body2" sx={{ ml: 2, fontSize: '11px' }}>
                {option.value}
              </Typography>
            </Box>
          </li>
        )}
      />
    </FormControl>


      {dataInputRow?.rowIndex === SpecificElement && (
        <SaveEditFirst UpdateTableField={(e) => { UpdateTableField(e, selectedOption, Item); }} handleCloseClick={handleCloseClick} />
      )}

     </div>

    </Fragment>
  );
};

export default DropDown1;