import React, { useState } from "react";
import ReactDOm from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SideBar from "./SideBar";
import Options from "./Options";

const CreateDashboard = () => {
  return (
    <>
      <div className="flex grid-rows-2 gap-10">
        <div className="w-1/5">
          <SideBar />
        </div>
        <Options />
      </div>
    </>
  );
};

export default CreateDashboard;
