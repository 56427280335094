import React, { useState } from 'react';

const MultiSelectDropDown = ({setExtraFieldData,extraFieldData,setInputValue,inputValue,handleAddOption,setInputVisible,InputVisible}) => {


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

 

  const handleDeleteOption = (index) => {
    const updatedOptions = extraFieldData.field_options.filter((_, i) => i !== index);
    setExtraFieldData(prevState => ({
      ...prevState,
      field_options: updatedOptions
    }));
  };

  const handleAddNewField = () => {
    setInputVisible(true);
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleAddOption();
    }
  };

  return (
    <div>
      <div className='border-t-4 bg-white rounded-lg p-1 border-b-4 border-gray-200 shadow-b-lg rounded-b-lg' id="newField">
        <div className='pl-1 pr-2 py-2 relative max-h-40 overflow-y-auto thinscrollbar'>
          {Array.isArray(extraFieldData?.field_options) && extraFieldData?.field_options?.map((option, index) => (
            <div key={index} className="mb-2 relative group flex pr-3">
              <i className="bi bi-grip-vertical mr-2 mt-2 text-CCD4DC hover:text-667279"></i>
              <button
                className='bg-gray-200 border rounded-md w-full px-4 py-1 relative flex items-left justify-left'
              >
                {option.value}

                {
                  option?.id &&

                  (   
                      <i
                        className="bi bi-trash text-F16136 cursor-pointer absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100"
                        onClick={() => handleDeleteOption(index)}
                      ></i>
                  )

                }
             
              </button>
            </div>
          ))}
          {InputVisible && (
            <div className="mb-2 relative flex pr-3">
              <i className="bi bi-grip-vertical mr-2 mt-2 text-667279"></i>
              <input className='w-full px-2 py-1 rounded' value={inputValue} onChange={handleInputChange}  onKeyUp={handleKeyUp}/>
              <div className="absolute right-0 top-0 bottom-0 flex items-center">
                <button onClick={handleAddOption}>
                  <i className="bi bi-arrow-right mr-5"></i>
                </button>
              </div>
            </div>
          )}
        </div>
        <button
          className='bg-white px-6 py-1 text-gray-500'
          onClick={handleAddNewField}
          style={{
            opacity: InputVisible ? 0.5 : 1,
            fontWeight: InputVisible ? '300' : '500',
            color: InputVisible ? 'gray' : '#626262',
          }}
        >
          Add option<i className="bi bi-plus-square ml-3"></i>
        </button>
      </div>
    </div>
  );
};

export default MultiSelectDropDown;
