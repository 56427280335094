 
 //export const BASE_URL = "https://api.vadsconsulting.com/";
// export const BASE_URL = "http://127.0.0.1:8000/";
//  export const BASE_URL = "https://uat.vadsconsulting.com/";
 export const BASE_URL = "https://zinancials.com/";


export const LOGIN_URL = `${BASE_URL}api/login`;
export const FORGET_PASS_URL = `${BASE_URL}api/forgot-password`;
export const VALID_TOKEN_URL = `${BASE_URL}api/custom-password-reset-url/`;
export const RESET_PASS_URL = `${BASE_URL}api/password/reset`;
export const LOGOUT_URL = `${BASE_URL}api/logout`;

export const ALL_USER = `${BASE_URL}api/users/get_all_users`;
export const COMPANY_PROFILE = `${BASE_URL}api/company/save_profile`;
export const EDIT_PROFILE = `${BASE_URL}api/company/edit_profile`;
export const SAVE_BILLING = `${BASE_URL}api/company/save_billing`;
export const SAVE_CONTACT = `${BASE_URL}api/company/save_contact_person`;
export const SAVE_ADDRESS = `${BASE_URL}api/company/store_address`;
export const CHARTS_OF_ACCOUNTS = `${BASE_URL}api/charts_of_accounts`;
export const COMPANY_DUPLICATE = `${BASE_URL}api/company/check_duplicate_company`;
export const COMPANY_DISPLAY = `${BASE_URL}api/company/check_display`;
export const CHARTS_OF_ACCOUNTS_DETAILS = `${BASE_URL}api/charts_of_accounts/account/details/`;
export const COMPANY_PROFILE_DETAIL = `${BASE_URL}api/company/get_company_details`;
export const COMPANY_BILLING_DETAIL = `${BASE_URL}api/company/get_billing_details`;
export const COMPANY_EDIT_ADDRESS = `${BASE_URL}api/company/get_edit_address_details`;
export const COMPANY_EDIT_CONTACT = `${BASE_URL}api/company/get_contact_details`;
export const DELETE_CONTACT_API = `${BASE_URL}api/company/delete_contact`;
export const SIGNUP_URL = `${BASE_URL}api/signup`;
export const COMMON_URL = `${BASE_URL}api/company/get_common`;
export const ADD_VENDOR = `${BASE_URL}api/vendors/add`;
export const ADD_UPDATE_CHART_OF_ACCOUNT_DETAILS = `${BASE_URL}api/charts_of_accounts/add_update_chart_of_account_details`;
export const COMPANY_PROFILE_BASIS_OF_USER_LOGIN = `${BASE_URL}api/company/get_company_profile`;
export const COMPANY_PROFILE_PIC = `${BASE_URL}api/company/company_profile_pic/`;
export const CUSTOMER_GET_DATA = `${BASE_URL}api/customers/get/`;
export const ADD_UPDATE_CUSTOMER = `${BASE_URL}api/customers/add_update`;
export const GET_CUST_COMMN = `${BASE_URL}api/customers/get_cust_commn`;
export const CUSTOMER_TABLEDROP_DATA = `${BASE_URL}api/customers/get_cust_commn_with_value`;
export const CUSTOMER_TABLEDYNAMIC_DROP = `${BASE_URL}api/customers/get_dynamic_field`;
export const CUSTOMER_PERSON_IN_TABLE = `${BASE_URL}api/get_persons`;
export const RANDOM_PERSON = `${BASE_URL}api/random_persons`;

export const REPORTING_ADD_UPDATE = `${BASE_URL}api/customers/reporting/add_update`;
export const GET_REPORTING_DATA = `${BASE_URL}api/customers/reporting/views/`;
export const UPDATE_SAVE_OTHERDETAIL_DATA = `${BASE_URL}api/customers/add_update_single`;
export const CUSTOMER_SOCIAL_ADD_UPDATE = `${BASE_URL}api/customers/socials/add_update`;
export const CUSTOMER_CONTACT_ADD_UPDATE = `${BASE_URL}api/customers/contacts/add_update`;
export const GET_CUSTOMERLIST_HEADERS = `${BASE_URL}api/headers/get_header_configration`;
export const CUSTOMERLIST_UPDATE_HEADERS = `${BASE_URL}api/headers/update_header_configration`;
export const CUSTOMERTABLE_VALUE_UPDATE = `${BASE_URL}api/customers/customer_list_single`;
export const CUSTOMERTABLE_ADDFIELD_HEADERS = `${BASE_URL}api/customers/add_optionla_field`;
export const CUSTOMERTABLE_UPDATEFIELD_HEADERS = `${BASE_URL}api/customers/update_optional_field`;
export const CUSTOMERTABLE_GETFIELD_HEADERS = `${BASE_URL}api/customers/get_additional_field`;
export const ADD_UPDATE_DROPDOWN = `${BASE_URL}api/customers/add_update_dropdown`;
export const CUSTOMER_SERVICE_ADD_UPDATE = `${BASE_URL}api/customers/services/add_update`;
export const CUSTMOER_ARTIFACT_ADD_UPDATE = `${BASE_URL}api/customers/artifacts/add_update`;
export const CUSTOMER_ADRESSS_ADD_UPDATE = `${BASE_URL}api/customers/address/add_update`;
export const CUSTOMER_DELETE_DATA = `${BASE_URL}api/customers/socials/delete_social/`;
export const CUSTOMER_ADDRESS_DATA_GET = `${BASE_URL}api/customers/address/get_all_address/`;
export const CUSTOMER_SOCIAL_DATA_GET = `${BASE_URL}api/customers/socials/get_all_links/`;
export const CUSTOMER_REPORTING_DATA_GET = `${BASE_URL}api/customers/reporting/views/`;
export const CUSTOMER_CONTACT_DATA_GET = `${BASE_URL}api/customers/contacts/get_all_contacts/`;
export const CUSTOMER_SERVICE_DATA_GET = `${BASE_URL}api/customers/services/get_all_services/`;
export const CUSTMOER_ARTIFACT_DATA_GET = `${BASE_URL}api/customers/artifacts/get_artifacts/`;
export const CUSTOMER_REPORTING_DELETE = `${BASE_URL}api/customers/reporting/delete_reporting/`;
export const CUSTOMER_SOCIAL_DATA_DELETE = `${BASE_URL}api/customers/socials/delete_social/`;
export const CUSTOMER_SERVICE_DATA_DELETE = `${BASE_URL}api/customers/services/delete_contact/`;
export const CUSTOMER_NOTES_ADD_UPDATE = `${BASE_URL}api/customers/notes/add_update`;
export const CUSTMOER_NOTES_DATA_GET = `${BASE_URL}api/customers/notes/fetch_notes/`;
export const CUSTMOER_NOTES_ATTACHMENT_DATA_GET = `${BASE_URL}api/customers/notes/delete_attachment/`;
export const CUSTMOER_NOTES_DATA_DELETE = `${BASE_URL}api/customers/notes/delete_notes/`;
export const CUSTOMER_ADDRESS_DATA_DELETE = `${BASE_URL}api/customers/address/delete_address/`;
export const CUSTOMER_DETAILS_DATA = `${BASE_URL}api/customers/customer_details/`;
export const CUSTOMER_OTHER_DETAILS = `${BASE_URL}api/customers/get_others_details/`;
export const CUSTOMER_ARTIFACT_DATA_DELETE = `${BASE_URL}api/customers/artifacts/delete_artifact/`;
export const CUSTOMER_CONTACT_DATA_DELETE = `${BASE_URL}api/customers/contacts/delete_contact/`;
export const CUSTOMER_OTHERDETAIL_DYNAMIC_ADD_UPDATE = `${BASE_URL}api/customers/add_others_lable`;
export const CUSTOMER_TASK_CATEGORY = `${BASE_URL}api/customers/tasks/get_common_cat`;
export const CUSTOMER_REPORTING_STATUS = `${BASE_URL}api/customers/reporting/status`;
export const CUSTOMER_SOCAIL_LINK_STATUS = `${BASE_URL}api/customers/socials/status`;
export const CUSTOMER_ARTIFACT_STATUS = `$get_task{BASE_URL}api/customers/artifacts/status`;
export const CUSTOMER_CONTACT_STATUS = `${BASE_URL}api/customers/contacts/status`;
export const CUSTOMER_SERVICE_STATUS = `${BASE_URL}api/customers/services/status`;
export const CUSTOMER_TASK_ADD_UPDATE = `${BASE_URL}api/customers/tasks/add_update`;
export const CUSTOMER_TASK_DATA_GET = `${BASE_URL}api/customers/tasks/get_task/`;
export const TASK_GET_ORGACCORDING = `${BASE_URL}api/customers/tasks/get_task_org`;

export const CUSTOMER_NOTES_COLOR_CODE = `${BASE_URL}api/customers/get_colours`;
export const CUSTOMER_MANAGER_LIST = `${BASE_URL}api/customers/reporting/manger`;
export const CUSTOMER_ARTIFACT_DATATYPE = `${BASE_URL}api/customers/artifacts/get_doc_type`;
export const CUSTOMER_GROUP_LIST = `${BASE_URL}api/customers/get_customer_group`;
export const CUSTOMER_DESINATION_LIST = `${BASE_URL}api/customers/reporting/designations`;
export const CUSTOMER_GET_USER_DATA = `${BASE_URL}api/get_users`;
export const CUSTOMER_CLOSED_TASK_GET = `${BASE_URL}api/customers/tasks/get_close_task/`;
export const CUSTOMER_OVERDUSE_TASK_GET = `${BASE_URL}api/customers/tasks/get_overdue_task/`;
export const CUSTOMER_ACTIVITY_ADD_UPDATE = `${BASE_URL}api/customers/tasks/add_update_task_activity`;
export const CUSTOMER_ACTIVITY_GET_EVENT = `${BASE_URL}api/customers/tasks/get_task_activity`;
export const CUSTOMER_TASK_POD_DATA = `${BASE_URL}api/customers/tasks/get_task_pod`;
export const TASKPOD_ADD_UPDATE = `${BASE_URL}api/customers/tasks/add_update_task_pod`;
export const TASK_VIEW_DATA = `${BASE_URL}api/customers/tasks/get_task_by/`;
export const CUSTOMER_SERVICE_CATEGORY = `${BASE_URL}api/customers/services/service_dropdown`;
export const CUSTOMER_SOCIAL_CATEGORY = `${BASE_URL}api/customers/socials/get_social_link_dp`;
export const CUSTOMERTABLE_TASK_VALUE_UPDATE = `${BASE_URL}api/customers/tasks/add_update_single_task`;
export const CUSTOMER_SINGLE_UPDATE_CONTACT_PERSON = `${BASE_URL}api/customers/contacts/add_update_single_contact`;
export const CUSTOMER_TASK_GET_TASKPOD = `${BASE_URL}api/customers/tasks/get_task_pod_details/`;
export const CUSTOMER_SERVICE_SINGLE_UPDATE = `${BASE_URL}api/customers/services/add_update_single_service`;
export const CUSTOMER_ARTIFACT_SINGLE_UPDATE = `${BASE_URL}api/customers/artifacts/add_update_single_artifact`;
export const GET_COMPANY_ADDRRESS = `${BASE_URL}api/company/get_edit_address_details/`;
export const SINGLE_UPDATE_SOCIAL_LINK = `${BASE_URL}api/customers/socials/add_update_single_social`;
export const SINGLE_UPDATE_REPORTING_TEAM = `${BASE_URL}api/customers/reporting/add_update_single_reporting`;
export const CUSTOMER_ALL_ADDRESS = `${BASE_URL}api/customers/address/get_all_customer_address/`;
export const CUSTOMER_TASK_HEADER=`${BASE_URL}api/customers/tasks/get_task_headers`;
export const CUSTOMER_ADDRESS_SINGLE_GET=`${BASE_URL}api/customers/address/get_edit_address/`;
export const CUSTOMER_All_NOTES_BASED_SCROLL=`${BASE_URL}api/customers/notes/notes/fetch_all_notes/`;
export const CUSTOMER_EDIT_TASK_DATA=`${BASE_URL}api/customers/tasks/get_task_edit/`;
export const CUSTOMER_GET_ALL_NOTES_DATA_BASEDON_SCROLL=`${BASE_URL}api/customers/notes/fetch_all_notes/`;
export const CUSTOMER_GET_ALL_ACTIVITY_DATA_BASED_ON_SCROLL=`${BASE_URL}api/customers/tasks/get_all_task_activity/`;
export const USER_LIST_DATA=`${BASE_URL}api/users/get_all_user`;
export const ADD_UPDATE_USER_LIST =`${BASE_URL}api/users/add_edit_user`;
export const MANAGE_LIST_GET=`${BASE_URL}api/users/get_manage_list`;
export const DYNAMIC_LIST_DATA=`${BASE_URL}api/users/get_dynamic_value_list/`;
export const DYNAMIC_HEADER_LIST=`${BASE_URL}api/users/get_dynamic_header_value/`;
export const EDITE_DYNAMIC_VALUE=`${BASE_URL}api/users/get_dynamic_table_edit_value/`;
export const ADD_UPDATE_DYANMIC_TABLE=`${BASE_URL}api/users/add_update_dynamic_table`;
export const GET_ALL_DATA_RELETED_TO_DESIGNATION=`${BASE_URL}api/users/all_data_related`;
export const GET_MANAGE_PERMISSION =`${BASE_URL}api/org/org_groups_get`;
export const GET_MANAGE_GROUPS =`${BASE_URL}api/org/org_groups_people`;
export const SELECT_PERMISSION_LEVEL =`${BASE_URL}api/permission/permission_list`;
export const PERMISSION_MODULES =`${BASE_URL}api/permission/permission_modules`;
export const PERMISSION_ADDUPDATE =`${BASE_URL}api/permission/addupdate_permission`; 
export const ADDUPDATE_ORG =`${BASE_URL}api/org/addupdate_org`;

export const ENUM_VALUE_GET = `${BASE_URL}api/field/enumvalueget`;

export const ADDUPDATE_ORG_MEMBER =`${BASE_URL}api/org/addupdate_org_member`;
export const EDIT_USER_LIST_BASED_ON_ID=`${BASE_URL}api/users/get_user_edit/`;
export const GET_COMPANY_DELEGETION=`${BASE_URL}api/company/get_company_delegation`;
export const UPDATE_COMPANY_ADDRESS=`${BASE_URL}api/company/update_address`;
export const ADD_COMPANY_GROUP=`${BASE_URL}api/company/add_company_group`;
export const ADD_UPDATE_DELEGATION=`${BASE_URL}api/manager_access/add_access`;
export const GET_DELEGATION_DATA_EDITE=`${BASE_URL}api/manager_access/get_delegation/`;
export const GET_ORGANIZATION_EDIT_DATA=`${BASE_URL}api/org/get_organization_edit/`;
export const DELETE_ORGANIZATION_DATA=`${BASE_URL}api/org/delete_organization/`;
export const GET_CUSTOMER_DELEGATION=`${BASE_URL}api/customers/get_customer_delegation/`;
export const CHECK_EMAIL_DUPLICATE=`${BASE_URL}api/check_duplicate_email`;
export const DELTE_COMPANY_DELEGATION = `${BASE_URL}api/company/delete_delegation/`;
export const ADD_UPDATE_CUSTOMER_DELEGATION=`${BASE_URL}api/customers/add_update_customer_delegation`;
export const ADD_CUSTOMER_DELEGATION=`${BASE_URL}api/customers/add_customer_delegation`;
export const DELETE_UPDATE_CUSTOMER_DELEGATION=`${BASE_URL}api/customers/delete_customer_delegation`;
export const GET_ALL_CUSTOMER_DELEGATION=`${BASE_URL}api/customers/get_all_customer_delegation/`;
export const GET_ALL_CUSTOMER_DELEGATION_RIGHT=`${BASE_URL}api/manager_access/get_customer_delegation_access`;
export const CUST_DELEGATION=`${BASE_URL}api/customers/get_delegation/`;
export const DELETE_CUSTOMER_DELEGATION=`${BASE_URL}api/customers/delete_delegation/`;
export const GET_COMPANY_DEFAULT_ADDRESS=`${BASE_URL}api/company/get_company_default_addrees/`;
export const ADD_UPDATE_COMPANY_ADDRESS=`${BASE_URL}api/company/add_Update_company_address`;
export const GET_ALL_ADDRESS_COMPANY=`${BASE_URL}api/company/get_all_company_address/`;
export const EDIT_COMPANY_ADDRESS_BASED_ON_ID=`${BASE_URL}api/company/get_edit_company_address/`;
export const ADD_UPDATE_VENDOR = `${BASE_URL}api/vendors/add_update`;
export const GET_VENDOR_LIST = `${BASE_URL}api/vendors/get_vendors_list/`;
export const EDIT_VENDOR_DATA=`${BASE_URL}api/vendors/edit_vendors/`;
export const ADD_VENDOR_SOCAIL_LINK=`${BASE_URL}api/vendors/links/add_socail_link`;
export const GET_SOCIAL_LINK_VENDOR=`${BASE_URL}api/vendors/links/get_socail_link/`;
export const DELETE_SOCAIL_Vendor_LINK=`${BASE_URL}api/vendors/links/delete_socail_link/`;
export const ADD_VENDOR_CONATACT_PERSON=`${BASE_URL}api/vendors/contact/add_update`;
export const DELETE_VENDOR_CONTACT_DATA=`${BASE_URL}api/vendors/contact/delete_contact/`;
export const GET_VENDOR_CONTACT_DATA=`${BASE_URL}api/vendors/contact/get_contact_list/`;
export const SINGLE_VENDOR_ADD_UPDATE_CONTACT_DATA=`${BASE_URL}api/vendors/contact/add_update_single_contact/`;
export const ADD_UPDATE_VENDOR_ADDRESS=`${BASE_URL}api/vendors/address/add_update`
export const GET_VENDOR_ALL_ADDRESS=`${BASE_URL}api/vendors/address/get_all_Address/`;
export const GET_VENDOR_DEFAULT_ADDRESS=`${BASE_URL}api/vendors/address/get_default_Address/`;
export const EDIT_VENDOR_ADDRESS=`${BASE_URL}api/vendors/address/get_edit_Address/`;
export const EDIT_VENDOR_DATA_LIST=`${BASE_URL}api/vendors/get_edit_vendor_data_list/`;
export const EDIT_VENDOR_SOCIAL_DATA=`${BASE_URL}api/vendors/links/get_socail_link_edit/`;
export const CUSTOMER_DEPARTMENT_LIST = `${BASE_URL}api/customers/reporting/departments`;
export const MANAGE_ACCESS_DELETEGATION = `${BASE_URL}api/manager_access/get_delegation_access`;
export const GET_CUSTOMER_ACCESS_AVAILABLE=`${BASE_URL}api/customers/get_customer_delegation_access/`;
export const CHECK_ORG_DUPLICATE=`${BASE_URL}api/check_duplicate_org`;
export const GET_USER_DETAILS_MORE_DETAILS=`${BASE_URL}api/users/get_manager_team/`;
export const GET_CUSTOMER_GROUP_DATA_ADD_UPDATE_TIME=`${BASE_URL}api/customers/get_customer_groups`;
export const ENABLE_USER=`${BASE_URL}api/users/status`;
export const DELETE_TABLE_CATEGORIES_DATA=`${BASE_URL}api/mange_list/delete`;
export const DELETE_EXTERNAL_USER=`${BASE_URL}api/external/delete`;
export const CHECK_DUPLICATE_CUSTOMER=`${BASE_URL}api/customers/check_duplicate_customer`;
export const CHECK_DUPLICATE_CUST_DISP_NAME=`${BASE_URL}api/customers/check_duplicate_customer_display_name`;
export const GET_GROUP_DATA=`${BASE_URL}api/users/get_org_group`;



 
 



