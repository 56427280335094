import React, { useState, Fragment, useRef, useEffect } from 'react';
import useKeyboardShortcuts from '../../../../hooks/KeysHandle/useKeyboardShortcuts';

const SearchBar = ({ data, event, SearchOpen = false, InputClass }) => {
  const [tableSearch, setTableSearch] = useState(SearchOpen);
  const inputRef = useRef(null);

  const TableSearchData = () => {
    setTableSearch(!tableSearch);
  };

  useKeyboardShortcuts({
    ToggleSearchBar: (event) => {
      event.preventDefault();
      TableSearchData();
    },
  });

  useEffect(() => {
    if (tableSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [tableSearch]);

  return (
    <Fragment>
     {tableSearch && (
      <div className="relative flex items-center">
          <input 
            ref={inputRef}
            className={`px-3 pl-9 ${InputClass} rounded border-[#1D4469] text-12 border w-full not-italic`} 
            type="text"
            value={data}
            onChange={(e) => {
              event(e?.target?.value);
            }}
            placeholder="Search"
          />
          
          <div className="absolute left-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 11 10" fill="none">
              <path d="M10.348 9.10842L7.88279 6.63308C8.51662 5.90563 8.86391 4.99035 8.86391 4.0375C8.86391 1.81126 6.98785 0 4.68195 0C2.37606 0 0.5 1.81126 0.5 4.0375C0.5 6.26374 2.37606 8.07499 4.68195 8.07499C5.54762 8.07499 6.37255 7.82291 7.07785 7.34438L9.56175 9.8385C9.66557 9.9426 9.80521 10 9.95485 10C10.0965 10 10.2309 9.94786 10.3329 9.85307C10.5496 9.65172 10.5565 9.31784 10.348 9.10842ZM4.68195 1.05326C6.38637 1.05326 7.77296 2.39195 7.77296 4.0375C7.77296 5.68304 6.38637 7.02173 4.68195 7.02173C2.97754 7.02173 1.59094 5.68304 1.59094 4.0375C1.59094 2.39195 2.97754 1.05326 4.68195 1.05326Z" fill="#848687" fill-opacity="0.6"/>
            </svg>
          </div>
        </div>

        )}

      <div>
        <button
          type="button"
          onClick={TableSearchData}
          className={`tooltip hover:text-254155 ${
            tableSearch
              ? 'text-254155 border border-253E50 px-1 rounded'
              : 'text-2541558A'
          }`}
        >
          <i className={`bi bi-search tableheaderfiltericon `}></i>
          <span className={`tooltiptext text-[10px] w-[60px] bottom-full left-1/2 ml-[-30px]`}>Search</span>  
        </button>
      </div>
    </Fragment>
  );
};

export default SearchBar;
