import React, { Fragment } from 'react'
import useDropdownPosition from '../../../hooks/Dropdown/useDropDownPosition';

const   TextHoverElement = ({ParentId,Data,HoverActiveClass,ClassName}) => {

  const { dropdownPosition, dropdownRef } = useDropdownPosition(true, ParentId?.tableidclass);

 if(Data?.value === null || Data?.value === ''){
     return null
 }

  return (
    <Fragment>
        <div className={`absolute ${dropdownPosition === 'bottom' ? 'top-full' : 'bottom-full'} ${HoverActiveClass} justify-start`} ref={dropdownRef}>

        
            <div className={` px-2 py-1.5 justify-center  rounded-5px    `}   >            
                        
                    <p className={`accounting-newlinewrap ${ClassName}`}>
                            {Data && Data['value']}         
                    </p>
                       

            </div>          
            <div className="owner-triangle">            
                <i className="bi bi-caret-down-fill text-white text-md"></i>          
            </div>          

    </div>


   </Fragment>
  )
}

export default TextHoverElement