const VendorHeader = [
    {
        "vendor_name": "Vendor Name",
        "vendor_name_status": 1
    },
    {
      "vendor_display_name": "Vendor Display Name",
      "vendor_display_name_status": 1
    },
    
    {
      "type": "Type",
      "type_status": 1
    },
    {
      "currency": "Currency",
      "currency_status": 1
    },
    
  
]


export default VendorHeader