import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  CUSTOMER_CONTACT_ADD_UPDATE,
  CUSTOMER_CONTACT_DATA_GET,
  CUSTOMER_CONTACT_DATA_DELETE,
  CUSTOMER_CONTACT_STATUS
} from "../constants";
import {
  saveData,
  getCommonDataBasedOnId,
  deleteData,
  updateData
} from "../../components/store/customer/CustomerSocialAddUpdateFunction";

export const saveContactPersonDataAsync = createAsyncThunk(
  "teamMembers/saveContactPersonDataAsync",
  async ({ socialData, token }, thunkAPI) => {
    try {
      const requestData = [socialData];
    
      const endpoint = (socialData.id === undefined || socialData.id === '') ? 
      CUSTOMER_CONTACT_ADD_UPDATE : CUSTOMER_CONTACT_STATUS;
      const responses = await ((socialData.id === undefined || socialData.id === '') ? 
          saveData : updateData)(
          endpoint,
          requestData,
          token
        );
      
      return responses;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const fetchContactPersonData = createAsyncThunk(
  "contactPersons/fetchContactPersonData",
  async ({ customerId, token }, thunkAPI) => {
    try {
      const url = `${CUSTOMER_CONTACT_DATA_GET}${customerId}`;
      const getData = await getCommonDataBasedOnId(url, token);
      const contactResponse=getData?.data?.data
      if (Array.isArray(contactResponse) && contactResponse.length > 0) {
          const processedData = contactResponse.map(member => ({
            ...member,
            isChecked: member.status === 1 ? true : false
          }));
          
          thunkAPI.dispatch(setContactPersons(processedData));
          return processedData;
        
      } else {
        return thunkAPI.rejectWithValue('Data is not an array or is empty');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteContactPersonDataAsync = createAsyncThunk(
  "contactPersons/deleteContactPersonDataAsync",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await deleteData(
        `${CUSTOMER_CONTACT_DATA_DELETE}${id}`,
        token
      );
     return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const contactPersonsSlice = createSlice({
  name: "contactPersons",

  initialState: {
    pointerStyle: 0,
    message: null,
    showMessage: false,
    teamMembers: [
      { name: "", contact_no: "", contact_email: "", isChecked: true },
    ],
    status: "idle",
    error: null,
  },
  reducers: {
    addTeamMember: (state) => {
      if (state.pointerStyle !== 0) {
        state.teamMembers.unshift({
          name: "",
          contact_no: "",
          contact_email: "",
          isChecked: true,
        });
        state.pointerStyle = 0;
      }
    },
    inputChange: (state, action) => {
      const { index, name, value } = action.payload;
      state.teamMembers[index][name] = value;
    },
    save: (state, action) => {
      state.pointerStyle = 1;
    },
    addTeamCheck: (state, action) => {
      const { indexno, isChecked } = action.payload;
      state.teamMembers[indexno].isChecked = isChecked;
    },
    setContactPersons: (state, action) => {
      const processedData = action.payload.map((member) => ({
        ...member,
        isChecked: member.status === 1 ? true : false
      }));
      state.teamMembers = processedData;
      state.pointerStyle = 1;
    },
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    removeContactMemberAtIndex: (state, action) => {
      const indexToRemove = action.payload;
      state.teamMembers = state.teamMembers.filter((_, index) => index !== indexToRemove);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveContactPersonDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveContactPersonDataAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.showMessage = true;
        state.pointerStyle = 1;
      })
      .addCase(saveContactPersonDataAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      })
      .addCase(fetchContactPersonData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContactPersonData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teamMembers = action.payload;
        state.pointerStyle=1;
      })
      .addCase(fetchContactPersonData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteContactPersonDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteContactPersonDataAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teamMembers = state.teamMembers.filter(
          (member) => member.id !== action.payload.id
        );
        state.message = action.payload.message;
        state.showMessage = true;
        state.pointerStyle = 1;
      })
      .addCase(deleteContactPersonDataAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      });
  },
});

export const {
  addTeamMember,
  inputChange,
  save,
  addTeamCheck,
  setContactPersons,
  hideMessage,
  removeContactMemberAtIndex
} = contactPersonsSlice.actions;

export default contactPersonsSlice.reducer;
