import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useFetchCustomerReportingDataQuery } from "../../utils/customer/customerTaskTable";
import { useParams } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import { useSelector } from "react-redux";
const ClientReportingTeam = ({ isOpen, onToggle,handleRouteSection }) => {
  const { id: customerId } = useParams();
  const { token } = useRequireAuth();
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const [customer, setCustomer] = useState([]);
  const message = useSelector((state) => state.customerReportingTeam.message);

  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchCustomerReportingDataQuery({
      customerId: customerId,
      pagination: customerTablePagination,
      token: token,
    },{ skip: isOpen !== true });

  useEffect(() => {
    if (customerTableALL && isOpen===true) {
      setCustomer(customerTableALL?.data?.data);
    }
  }, [customerTableALL, customerTablePagination,isOpen]);

  useEffect(()=>{
   if(isOpen===true){
    refetchCustomerData();
   }
    
  },[message])

 
  return (
    <div className="bg-1d44691a border border-1D446933 mb-3 rounded-5px">
      <div className="flex justify-between px-3 py-2">
        <h4 className="text-2C495D font-normal cursor-pointer" onClick={handleRouteSection}>Reporting Team</h4>
        <button
          onClick={(e) => { e.stopPropagation(); onToggle(); }}
          // className={`flex items-center justify-center  rounded 
          //   ${isOpen ? 'bg-white  border border-gray-500' : 'hover:bg-gray-300 hover:border-gray-400 '}`}
          className={`relative flex w-[20px] h-[20px] p-3 justify-center items-center flex-shrink-0 transition-all duration-200 ease-in-out hover:bg-white hover:border-white
            ${isOpen ? '' : ' border-none hover:bg-white hover:border-2 hover:border-white'}`}
        >
          {isOpen ? (
            <i className={`bi  bi-${isOpen ? "chevron-up" : "chevron-down" } `}></i>
          ) : (
            <i className={`bi bi-${isOpen ? "chevron-up" : "chevron-down" } `}></i>
          )}
        </button>
      </div>

      {isOpen && (
        <Fragment>
          {/* <hr className="border-b border-1D446933 my-3" /> */}

          <div className="p-3 bg-white rounded-b-lg" id="teamNo">
            <table className="tasklistpop min-w-full bg-white border-top border-gray-200 border-bottom border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 text-14  bg-gray-100 font-normal text-start">Department</th>
                  <th className="p-2 text-14  bg-gray-100 font-normal text-start">Name</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(customer) && customer.map((cust) => (
                      <tr className="border-b">
                        <td className="p-2 text-xs">{cust?.designation?.value}</td>
                        <td className="p-2 text-xs">
                          {cust?.reporting_person_name?.value}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ClientReportingTeam;
