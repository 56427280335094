import React, { useState, useEffect, useRef, Fragment } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { flushSync } from "react-dom";

import LayoutHOC from "../LayoutHOC";
import { Link } from "react-router-dom";
import CustomerOtherDetail from "./CustomerOtherDetail";
import CustomerReportingTeam from "./CustomerReportingTeam";
import CustomerSocialLink from "./CustomerSocialLink";
import CustomerAddress from "./CustomerAddress";
import CustomerContactPerson from "./CustomerContactPerson";
import CustomerArtifact from "./CustomerArtifact";
import CustomerServices from "./CustomerServices";
import AddIcon from "../Icons/AddIcon";
import AddTask from "./AddTask";
import AddOverDue from "./AddOverDue";
import AddNotes from "./AddNotes";
import AddUpcomingEvent from "./AddUpcomingEvent";
import LeftCard from "./LeftCard";
import AddTeam from "./AddTeam";
import { getDataIdBase } from "../store/CompayProfileFuction/CompanyProfileAllFuction";
import useRequireAuth from "../../utils/useRequireAuth";
import { useParams } from "react-router-dom";
import { CUSTMOER_NOTES_DATA_GET } from "../../utils/constants";
function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
 

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = date < 10 ? `0${date}` : date;

  return `${formattedMonth}/${formattedDate}/${year}`;
}

const AddCustomer1 = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const [taskOpenTab, setTaskOpenTab] = React.useState(1);

  const [isFixed, setIsFixed] = useState(false);
  const [currentDate, setCurrentDate] = useState(getDate());

  const [calendarDate, setCalendarDate] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const { token } = useRequireAuth();
  const {id:customerId}=useParams();

  const [taskExpand, setTaskExpand] = useState(false);

  const handleClick = (index) => (event) => {
    event.preventDefault();
    setOpenTab(index);

    if (index != 0) {
      const contentElement = document.getElementById(`link${index + 1}`);

      flushSync(() => {
        contentElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      });
    }
  };
  const handleTaskClick = (index) => (event) => {
    event.preventDefault();
    setTaskOpenTab(index);

    if (index != 0) {
      const contentElement = document.getElementById(`tasklink${index + 1}`);

      flushSync(() => {
        contentElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      });
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 120) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const myTabs = [
    { label: "Home", content: "Welcome to the home page!" },
    { label: "Other Detail", content: <CustomerOtherDetail /> },
    { label: "Reporting Team", content: <CustomerReportingTeam /> },
    { label: "Social Links", content: <CustomerSocialLink /> },
    { label: "Address", content: <CustomerAddress /> },
    { label: "Contact Person", content: <CustomerContactPerson /> },
    { label: "Artifact", content: <CustomerArtifact /> },
    { label: "Services", content: <CustomerServices /> },
  ];

  const taskTabs = [
    { label: "Home", content: "Hey" },
    { label: "Task", content: <AddTask /> },
    { label: "OverDue", content: <AddOverDue /> },
    { label: "Notes", content: <AddNotes /> },
    { label: "Team", content: <AddTeam  /> },
    { label: "Events", content: <AddUpcomingEvent /> },
    { label: "Artifact", content: "Artifact" },
    { label: "Other Detail", content: "Other Detail" },
  ];

  function TaskExpand() {
    setTaskExpand(!taskExpand);
    setCalendarDate(new Date());
  }

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );

    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }
  const goToPreviousDate = () => {
    const previousDate = new Date(calendarDate);
    previousDate.setDate(previousDate.getDate() - 1);
    setCalendarDate(previousDate);
  };
  const goToNextDate = () => {
    const nextDate = new Date(calendarDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setCalendarDate(nextDate);
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  const calendarDateChange = (e) => {
    setCalendarDate(e);
  };
  const calendarClick = (e) => {
    e.preventDefault();
    setCalendarOpen(!calendarOpen);
  };
  const [notesData, setNotesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const url =`${CUSTMOER_NOTES_DATA_GET}${customerId}`
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDataIdBase(url, token);
        setNotesData(response?.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, token, customerId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  let expandbtn = (
    <div className={`flex justify-end mb-4 `}>
      <button
        className="bg-white border border-E1E1E1 py-2 px-3"
        onClick={TaskExpand}
      >
        <i className="bi bi-arrows-fullscreen"></i>
      </button>
    </div>
  );

  return (
      <div className={`p-4`}>
        <div className={`flex flex-col p-2 mb-3`}>
          <div className={`flex justify-between items-center px-2`}>
            <h5 className={`text-203C50 font-Vietnam text-[24px] font-medium`}>
              New Customer
            </h5>
            <div className={`flex items-center`}>
              <label className={`inline-flex items-center cursor-pointer`}>
                <input
                  type={`checkbox`}
                  value={``}
                  className={`sr-only peer`}
                />
                <div
                  className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
                ></div>
              </label>
            </div>
          </div>
        </div>

        {!taskExpand && (
          <ul
            className={`flex w-full mb-0 list-none flex-wrap px-3 items-center flex-row bg-white border border-1d446933 ${
              isFixed ? "shadow fixed top-[60px] z-50 w-full" : ""
            }  `}
            role="tablist"
          >
            {myTabs.map((item, index) => (
              <li key={index} className="-mb-px mr-2 last:mr-0 text-center">
                <Link
                  className={
                    "p-3 block leading-normal " +
                    (openTab === index
                      ? "font-medium text-2C495D border-b-2 border-BFBFBF"
                      : "text-848687")
                  }
                  onClick={handleClick(index)}
                  role="tab"
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        )}

        <div className={`flex flex-wrap`}>
          <div className={` ${taskExpand ? "hidden" : "w-[70%]"}  mt-6`}>
            {myTabs.map((item, index) => (
              <div
                key={index}
                className={`relative flex flex-col mb-6 min-w-0 break-words bg-white shadow-2 rounded ${
                  openTab === index
                    ? "block"
                    : openTab === 0 || index === 0
                    ? "hidden"
                    : "block"
                }`}
                id={`link${index + 1}`}
              >
                <div className="px-4 py-5 flex-auto">
                  <div className="tab-content tab-space">{item.content}</div>
                </div>
              </div>
            ))}
          </div>

          <div className={` ${taskExpand ? "w-3/12" : "hidden"}`}>
            {taskExpand && expandbtn}

            <div className="no-scrollbar h-[500px] overflow-y-auto">
              <LeftCard />
            </div>
          </div>

          <div className={` ${taskExpand ? "w-[75%]" : "hidden"} ps-4`}>
            <div className="flex justify-between">
              {taskExpand && (
                <ul
                  className={`flex w-10/12 mb-3 list-none flex-wrap px-3 items-center flex-row bg-white border border-1d446933 ${
                    isFixed ? "shadow fixed top-[60px] z-50 w-full" : ""
                  }  `}
                  role="tablist"
                >
                  {taskTabs.map((item, index) => (
                    <li
                      key={index}
                      className="-mb-px mr-2 last:mr-0 text-center"
                    >
                      <Link
                        className={
                          "p-3 block leading-normal " +
                          (taskOpenTab === index
                            ? "font-medium text-2C495D border-b-2 border-BFBFBF"
                            : "text-848687")
                        }
                        onClick={handleTaskClick(index)}
                        role="tab"
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              <div>
                <button className="rounded-full bg-2999BC text-white border border-1d446933 py-2 px-6">
                  Timeline
                </button>
              </div>
            </div>

            <div className="no-scrollbar h-[500px] overflow-y-auto">
              {taskTabs.map((item, index) => (
                <div
                  key={index}
                  className={`relative flex flex-col mb-6 min-w-0 break-words bg-white shadow-2 rounded ${
                    taskOpenTab === index
                      ? "block"
                      : taskOpenTab === 0 || index === 0
                      ? "hidden"
                      : "block"
                  }`}
                  id={`tasklink${index + 1}`}
                >
                  <div className="px-4 py-5 flex-auto">
                    <div className="tab-content tab-space">{item.content}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={`${taskExpand ? "hidden" : "w-[30%]"} mt-6 ps-4`}>
            {!taskExpand && expandbtn}

            <div className={`bg-white border border-1d446933 px-4 mb-4`}>
              <div className="flex justify-between py-4">
                <Fragment>
                  <h3 className="text-2C495D text-base font-normal">Task</h3>
                  <AddIcon />
                </Fragment>
              </div>

              <div className={`py-4 h-48 border-y-2 border-1d446933`}>
                <div className={`grid grid-cols-2 mb-2`}>
                  <div className="rounded-md border border-1D446933 p-2">
                    <i className="bi bi-circle-fill text-D9D9D9"></i>
                    <span className="m-6">No Status</span>
                  </div>

                  <span className={`flex items-center justify-end`}>
                    {currentDate}
                  </span>
                  <div>
                    <button
                      type="button"
                      className={`bg-transparent text-2999BC text-sm`}
                    >
                      Design
                    </button>
                  </div>
                </div>
              </div>

              <p className={`p-3 text-2C495D text-sm `}>
                Hello World - Description test
              </p>
            </div>

            <div className="bg-white border border-1d446933 px-4 mb-4">
              <div className="flex justify-between py-4">
                <Fragment>
                  <h3 className="text-2C495D font-normal text-base">
                    Over Due
                  </h3>
                  <AddIcon />
                </Fragment>
              </div>

              <div className={`py-4 h-48 border-y-2 border-1d446933 mb-9`}>
                <div className={`grid grid-cols-2 mb-2`}>
                  <div className="rounded-md border border-1D446933 p-2">
                    <i className="bi bi-circle-fill text-D9D9D9"></i>
                    <span className="m-6">No Status</span>
                  </div>

                  <span className={`flex items-center justify-end `}>
                    {currentDate}
                  </span>
                  <div className={``}>
                    <button
                      type="button"
                      className={`bg-transparent text-2999BC text-sm`}
                    >
                      Design
                    </button>
                  </div>
                </div>

                <p className="pt-3 text-2C495D text-sm">
                  Hello World - Description test
                </p>
              </div>
            </div>
            {/* h-[600px] overflow-auto */}
            <div className="bg-white border border-1d446933 px-4 mb-4 max-h-[600px] overflow-auto">
              <div className="flex justify-between py-4">
                <Fragment>
                  <h3 className="text-2C495D font-normal text-base">Notes</h3>
                  <AddIcon />
                </Fragment>
              </div>

              <Fragment>
              
                {!notesData ? null : (
                  notesData.map((note) => (
                
                <div className="py-4 border-t-2 border-1d446933">
                  <div className="flex justify-between rounded-md border border-1D446933 p-2 px-4">
                    <span className="ms-14">Risk</span>
                    <i className="bi bi-circle-fill text-FBB244"></i>
                  </div>

                  <div className="font-medium text-sm text-2C495D mt-3">
                  {note.notes_title}
                  </div>

                  <p className="mt-3 text-2C495D text-sm">
                  {note.notes_body}
                  </p>
                </div>

              ))
              )}
              </Fragment>
            </div>

            <div className="bg-white border border-1d446933 px-4 mb-4">
              <div className="flex justify-between py-4">
                <Fragment>
                  <h3 className={`text-2C495D text-base font-normal`}>
                    Upcoming Event
                  </h3>
                  <AddIcon />
                </Fragment>
              </div>

              <div className={`py-2 border-t-2 border-1d446933`}>
                <div className="border border-1D446933 p-2 px-4">
                  <div className="flex flex-wrap">
                    <div className="">
                      <button
                        className="bg-F2F2F2 rounded-sm p-1"
                        onClick={goToPreviousDate}
                      >
                        <i className="bi bi-chevron-left"></i>
                      </button>
                      <span className="text-sm text-848687 px-12">
                        {formatDate(calendarDate)}
                      </span>
                      <button
                        className="bg-F2F2F2 rounded-sm p-1"
                        onClick={goToNextDate}
                      >
                        <i className="bi bi-chevron-right"></i>
                      </button>
                    </div>

                    <div className="h-full border-s border-1D446933 ms-3 relative">
                      <button
                        type="button"
                        className="text-2999BC hover:text-F16136 focus:outline-none ms-3"
                        onClick={calendarClick}
                      >
                        <span className="relative">
                          <i className="bi bi-calendar-event-fill text-2xl"></i>
                          {/* <i className="bi bi-calendar text-2xl"></i>
                          <div className="absolute right-0 top-0 left-0 text-xs ">
                            {formatDate(calendarDate).slice(3, 6)}
                          </div> */}
                        </span>
                      </button>
                      {calendarOpen && (
                        <Fragment>
                          <DatePicker
                            selected={calendarDate}
                            onChange={calendarDateChange}
                            holidays={[
                              {
                                date: "2024-01-26",
                                holidayName: "Republic Day",
                              },
                              {
                                date: "2024-08-15",
                                holidayName: "Independence Day",
                              },
                              {
                                date: "2024-10-02",
                                holidayName: "Mahatma Gandhi",
                              },
                              { date: "2024-10-31", holidayName: "Diwali" },
                              {
                                date: "2024-12-25",
                                holidayName: "Christmas Day",
                              },
                              {
                                date: "2024-12-31",
                                holidayName: "New Year Eve",
                              },
                            ]}
                            inline
                            filterDate={isWeekday}
                            dayClassName={(date) => {
                              const isWeekend =
                                date.getDay() === 0 || date.getDay() === 6; // Saturday or Sunday
                              return isWeekend ? "satsunweek" : "montofriweek";
                            }}
                            closeOnScroll={true}
                            onClickOutside={() => {
                              setCalendarOpen(false);
                            }}
                            calendarClassName="customercalendar top-full right-0"
                            calendarStartDay={1}
                          >
                            <div>
                              <button
                                className="bg-1D4469 text-white font-500 text-18 py-2 px-4 mx-5"
                                onClick={() => {
                                  setCalendarDate(new Date());
                                }}
                              >
                                Today
                              </button>
                              <button
                                className="bg-F4F4F4 text-535252 border border-EAE2E2 font-500 text-18 py-2 px-4 ms-6"
                                onClick={() => setCalendarOpen(false)}
                              >
                                Close
                              </button>
                            </div>
                          </DatePicker>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>

                <div className="border border-E8E9EA my-5 p-4 relative">
                  <i className="border-y-[25px] border-transparent border-r-[25px] border-r-2999BC w-0 h-0 rotate-45 absolute top-[-16px] left-[-4px] text-2999BC"></i>

                  <div className="flex justify-between mb-3">
                    <span>{formatDate(calendarDate).slice(0, 2)}</span>
                    <span>
                      <i className="bi bi-plus-circle text-606061"></i>
                    </span>
                  </div>
                  {/* <div > */}
                  {/* Design */}
                  <textarea
                    rows={6}
                    className="text-2C495D bg-F5F0E9 border border-2c495d33 text-sm w-full p-3"
                  ></textarea>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div>hfgjhkj</div> */}
        </div>
      </div>
  );
};

export default LayoutHOC(AddCustomer1);
