


// import React, { useState,useEffect,useRef, Fragment } from 'react';
// import useClickOutside from '../../../hooks/Screen/useClickOutside';
// import useKeyboardShortcuts from '../../../hooks/KeysHandle/useKeyboardShortcuts';

// const DropdownField = ({ setExtraFieldData, extraFieldData, setInputValue, inputValue, setShadowColor, shadowColor, handleAddOption, setInputVisible, InputVisible }) => {
//   const [addOptionEnabled, setAddOptionEnabled] = useState(true);
//   const [colorPatlate, setColorPatlate] = useState("");
//   const [editFieldOption, setEditFieldOption] = useState(null);
//   const [editValue, setEditValue] = useState({});
//   const inputRef = useRef(null);

//   const handleColorPatlate = (index) => {
//     setColorPatlate(`${index}`);
//   };

//   const colorcloseoutsideclick = useClickOutside(() => {
//     setColorPatlate(null);
//   });

//   const colors = ['#f0f0f0', '#a6b1e1', '#b8e2bb', '#ffd8bf', '#e6e6fa', '#fffdd0', '#00BFFF', '#f4cccc', '#87ceeb', '#f08080', '#98fb98', '#EE82EE'];

//   const handleInputChange = (e) => {
//     setInputValue(e.target.value);
//   };

//   const handleColorClick = (color) => {
//     setShadowColor(color);
//   }

//   const editClickOutside = useClickOutside(() => {
//     if (editFieldOption !== null) {
//       handleSaveEdit(editFieldOption);
//       setEditFieldOption(null);

//     }
//   });

//   const handleEditField = (index, Field) => {
// console.warn(Field,"Field")
//        setEditFieldOption(index);
//        setEditValue(Field);

//     // if (editFieldOption === index) {
//     //   setEditFieldOption(null);
//     // } else {
//     //   setEditFieldOption(index);
//     //   setEditValue(value);
//     // }

//   }

//   const handleSaveEdit = (index) => {
//     const updatedFields = extraFieldData?.field_options?.map((field, i) => {
//       if (i === index) {
//         return { ...field, value: editValue?.value };
//       }
//       return field;
//     });
//     setExtraFieldData(prevState => ({
//       ...prevState,
//       field_options: updatedFields
//     }));
//     setEditFieldOption(null);
//   }

//   const handleDeleteField = (index) => {
//     const newTextFields = extraFieldData?.field_options?.filter((_, i) => i !== index);
//     setExtraFieldData(prevState => ({
//       ...prevState,
//       field_options: newTextFields
//     }));
//   };

//   const handleAddNewField = () => {
//     setInputVisible(true);
//   };

//   const handleDragStart = (e, index) => {
//     e.dataTransfer.setData('index', index);
//   };

//   const handleDragOver = (e, index) => {
//     e.preventDefault();
//   };

//   const handleDrop = (e, dropIndex) => {
//     const dragIndex = e.dataTransfer.getData('index');
//     const updatedFields = [...extraFieldData?.field_options];
//     const draggedItem = updatedFields[dragIndex];
//     updatedFields.splice(dragIndex, 1);
//     updatedFields.splice(dropIndex, 0, draggedItem);
//     setExtraFieldData(prevState => ({
//       ...prevState,
//       field_options: updatedFields
//     }));
//   };



//   useKeyboardShortcuts({
//     Enter: (event) => {
//       event.preventDefault();
//       handleAddOption();

//     },
//   });


//   useEffect(() => {
//     if (inputRef.current && editFieldOption !== null) {
//       inputRef.current.focus();
//     }
//   }, [editFieldOption]);


//   return (
//     <div>
//       <div className='border-t-4 bg-white rounded-lg p-1 border-b-4 border-gray-200 shadow-b-lg rounded-b-lg' id="newField">
//         <div className='pl-1 pr-2 py-2 relative max-h-40 overflow-y-auto thinscrollbar'  ref={editClickOutside}>
//           {Array.isArray(extraFieldData?.field_options) && extraFieldData?.field_options?.map((field, index) => (
//             <div key={index} className="mb-2  flex me-3 group">
//               <button
//                 className='mr-2 mt-2'
//                 type='button'
//                 draggable
//                 onDragStart={(e) => handleDragStart(e, index)}
//                 onDragOver={(e) => handleDragOver(e, index)}
//                 onDrop={(e) => handleDrop(e, index)}
//               >
//                 <i className="bi bi-grip-vertical  text-CCD4DC hover:text-667279"></i>
//               </button>

        
//                  <div 
//                         className={`border flex-1 max-w-[300px] ${editFieldOption === index ? 'px-0 !border-t-0' : 'px-4 py-1'}  rounded-b-md relative`}
//                         style={{
//                           borderTop: `4px solid ${field.color_code}`,
//                           borderTopLeftRadius: '0.375rem',
//                           borderTopRightRadius: '0.375rem'
//                         }}
//                   >
//                           {editFieldOption === index ? (
//                             <Fragment>
//                             <input
//                               type="text"
//                               value={editValue?.value}
//                               onChange={(e) => setEditValue({value:e?.target?.value})}
//                               onKeyUp={(e) => {
//                                 if (e.key === 'Enter') handleSaveEdit(index);
//                               }}
//                               className={`w-full ps-2 pe-16 py-1  focus:ring-0 focus:shadow-none`}
//                               style={{ backgroundColor: field?.color_code }}
//                               ref={inputRef}
//                             />
                           

//                                 </Fragment>
//                           ) : (
//                             <Fragment>
//                                   <div className='max-w-full accounting-newlinewrap pe-14'>
//                                            {field?.value}
//                                   </div>
                                   
//                             </Fragment>
//                           )}


//                       <div className='absolute right-0 top-0 bottom-0 flex items-center me-3'>
//                           {editFieldOption === index ? (
//                                <Fragment>
//                             {/* <div className='relative'>
//                               <button className='' onClick={() => { handleColorPatlate(index) } }>
//                                 <i className={`bi bi-palette-fill cursor-pointer mr-2 w-[29px] h-[28px] text-[${shadowColor}]`}></i>
//                               </button>

//                               {colorPatlate &&
//                                 <div className='color-palette-panel grid grid-cols-4 bottom-[97px]' ref={colorcloseoutsideclick}>
//                                   {colors.map((color, index) => (
//                                     <div key={index} className="color-box flex justify-center items-center" style={{ backgroundColor: color }} onClick={() => handleColorClick(color)}></div>
//                                   ))}
//                                 </div>
//                               }
//                             </div> */}
                                     

//                                     <button onClick={() => {handleSaveEdit(index)} }>
//                                       <i className="bi bi-arrow-right "></i>
//                                     </button>


//                                </Fragment>


//                           ) :
//                           (    

//                             <Fragment>
//                                   <button className='me-2 opacity-0 group-hover:opacity-100' onClick={() => handleEditField(index, field)}>
//                                         <i
//                                           className={`bi bi-pencil text-[${field.color_code}] `}
//                                         ></i>
//                                   </button>

//                                     {field?.delete &&
//                                       (
//                                         <button className='opacity-0 group-hover:opacity-100' onClick={() => handleDeleteField(index)}>
//                                           <i
//                                             className="bi bi-trash text-F16136 "
//                                           ></i>
//                                         </button>
//                                       )
//                                     }
//                             </Fragment>

//                           )}
//                       </div>

//                 </div>

               

               


//             </div>
//           ))}
//           {InputVisible && (
//             <div className="mb-2  flex me-3">
//               <i className="bi bi-grip-vertical mr-2 mt-2 text-667279"></i>
//                     <div className='relative w-full'>
//                           <input className='w-full ps-2 pe-16 py-1 rounded shadow-8' value={inputValue} onChange={handleInputChange} />
//                           <div className="absolute right-0 top-0 bottom-0 flex items-center me-4">
//                             <div className='relative'>
//                               <button className='' onClick={() => { handleColorPatlate("code001") } }>
//                                 <i className={`bi bi-palette-fill cursor-pointer mr-2 w-[29px] h-[28px] text-[${shadowColor}]`}></i>
//                               </button>

//                               {colorPatlate &&
//                                 <div className='color-palette-panel grid grid-cols-4 bottom-[97px]' ref={colorcloseoutsideclick}>
//                                   {colors.map((color, index) => (
//                                     <div key={index} className="color-box flex justify-center items-center" style={{ backgroundColor: color }} onClick={() => handleColorClick(color)}></div>
//                                   ))}
//                                 </div>
//                               }
//                             </div>
//                             <button onClick={handleAddOption}>
//                               <i className="bi bi-arrow-right"></i>
//                             </button>
//                           </div>

//                     </div>
              
//             </div>
//           )}
//         </div>
//         {addOptionEnabled && (
//           <button
//             className='bg-white px-6 py-1 text-gray-500'
//             onClick={handleAddNewField}
//             style={{
//               opacity: InputVisible ? 0.5 : 1,
//               fontWeight: InputVisible ? '300' : '500',
//               color: InputVisible ? 'gray-500' : '[#626262]',
//             }}
//           >
//             Add option<i className="bi bi-plus-square ml-3"></i>
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default DropdownField;

















import React, { useState, useEffect, useRef, Fragment } from 'react';
import useClickOutside from '../../../hooks/Screen/useClickOutside';
import useKeyboardShortcuts from '../../../hooks/KeysHandle/useKeyboardShortcuts';

const DropdownField = ({ setExtraFieldData, extraFieldData, setInputValue, inputValue, setShadowColor, shadowColor, handleAddOption, setInputVisible, InputVisible }) => {
  const [addOptionEnabled, setAddOptionEnabled] = useState(true);
  const [colorPatlate, setColorPatlate] = useState(null);
  const [editFieldOption, setEditFieldOption] = useState(null);
  const [editValue, setEditValue] = useState({ value: '', color_code: '#87CEEB' });
  const inputRef = useRef(null);

  

  const handleColorPatlate = (index) => {
    setColorPatlate(index);
  };

  const colorcloseoutsideclick = useClickOutside(() => {
    setColorPatlate(null);
  });

  const colors = ['#6417D8', '#008937', '#9538C1', '#E33400', '#DA8B16', '#5F5E52', '#452D2D', '#1D4A6A', '#EF177F', '#17E7F4', '#386FC1', '#6A4FD7'];

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // const handleColorClick = (color) => {
  //   setShadowColor(color);
  // }

  const handleColorClick = (color) => {
    if (editFieldOption !== null) {
      setEditValue({ ...editValue, color_code: color });
    } else {
      setShadowColor(color);
    }
  };

  const editClickOutside = useClickOutside(() => {
    if (editFieldOption !== null) {
      handleSaveEdit(editFieldOption);
      setEditFieldOption(null);
    }
  });

  const handleEditField = (index, field) => {
    setEditFieldOption(index);
    setEditValue({ ...field });
    setColorPatlate(null); // Close color palette when editing starts
  };

  const handleSaveEdit = (index) => {
    const updatedFields = extraFieldData?.field_options?.map((field, i) => {
      if (i === index) {
        return {
          ...field,
          value: editValue.value,
          color_code: editValue.color_code
        };
      }
      return field;
    });
    setExtraFieldData(prevState => ({
      ...prevState,
      field_options: updatedFields
    }));
    setEditFieldOption(null);
  };

  const handleDeleteField = (index) => {
    const newTextFields = extraFieldData?.field_options?.filter((_, i) => i !== index);
    setExtraFieldData(prevState => ({
      ...prevState,
      field_options: newTextFields
    }));
  };

  const handleAddNewField = () => {
    setInputVisible(true);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('index', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    const dragIndex = e.dataTransfer.getData('index');
    const updatedFields = [...extraFieldData?.field_options];
    const draggedItem = updatedFields[dragIndex];
    updatedFields.splice(dragIndex, 1);
    updatedFields.splice(dropIndex, 0, draggedItem);
    setExtraFieldData(prevState => ({
      ...prevState,
      field_options: updatedFields
    }));
  };

  useKeyboardShortcuts({
    Enter: (event) => {
      event.preventDefault();
      handleAddOption();
    },
  });

  useEffect(() => {
    if (inputRef.current && editFieldOption !== null) {
      inputRef.current.focus();
    }
  }, [editFieldOption]);

  return (
    <div>
      <div className='border-t-4 bg-white rounded-lg p-1 border-b-4 border-gray-200 shadow-b-lg rounded-b-lg' id="newField">
        <div className='pl-1 pr-2 py-2 relative max-h-40 overflow-y-auto thinscrollbar' ref={editClickOutside}>
          {Array.isArray(extraFieldData?.field_options) && extraFieldData?.field_options?.map((field, index) => (
            <div key={index} className="mb-2  flex me-3 group">
              <button
                className='mr-2 mt-2'
                type='button'
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDrop={(e) => handleDrop(e, index)}
              >
                <i className="bi bi-grip-vertical  text-CCD4DC hover:text-667279"></i>
              </button>
              <div
                className={`border flex-1 max-w-[100%] ${editFieldOption === index ? 'px-0 !border-t-0' : 'px-4 py-1'}  rounded-b-md relative`}
                style={{
                  borderTop: `4px solid ${field.color_code}`,
                  borderTopLeftRadius: '0.375rem',
                  borderTopRightRadius: '0.375rem'
                }}
              >
                {editFieldOption === index ? (
                  <Fragment>
                    <input
                      type="text"
                      value={editValue.value}
                      onChange={(e) => setEditValue({ ...editValue, value: e.target.value })}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') handleSaveEdit(index);
                      }}
                      className={`w-full ps-2 pe-16 py-1  focus:ring-0 focus:shadow-none`}
                      style={{ backgroundColor: editValue.color_code }}
                      ref={inputRef}
                    />
                 
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className='max-w-full accounting-newlinewrap pe-14'>
                      {field?.value}
                    </div>
                  </Fragment>
                )}
                <div className='absolute right-0 top-0 bottom-0 flex items-center me-3'>
                  {editFieldOption === index ? (
                    <Fragment>

                        <div className='relative'>
                          <button className='' onClick={() => { handleColorPatlate(index) }}>
                            <i className={`bi bi-palette-fill cursor-pointer mr-2 w-[29px] h-[28px] text-[${editValue?.color_code}]`}></i>
                          </button>
                            {colorPatlate !== null && colorPatlate === index && (
                              <div className='absolute color-palette-panel grid grid-cols-4 ' ref={colorcloseoutsideclick}>
                                {colors.map((color, index) => (
                                  <div key={index} className="color-box flex justify-center items-center" style={{ backgroundColor: color }} onClick={() => handleColorClick(color)}></div>
                                ))}
                              </div>
                            )}
                        </div>

                      <button onClick={() => handleSaveEdit(index)}>
                        <i className="bi bi-arrow-right "></i>
                      </button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <button className='me-2 opacity-0 group-hover:opacity-100' onClick={() => handleEditField(index, field)}>
                        <i
                          className={`bi bi-pencil text-[${field.color_code}] `}
                        ></i>
                      </button>
                      {field?.delete &&
                        (
                          <button className='opacity-0 group-hover:opacity-100' onClick={() => handleDeleteField(index)}>
                            <i
                              className="bi bi-trash text-F16136 "
                            ></i>
                          </button>
                        )
                      }
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          ))}
          {InputVisible && (
            <div className="mb-2  flex me-3">
              <i className="bi bi-grip-vertical mr-2 mt-2 text-667279"></i>
              <div className='relative w-full'>
                <input className='w-full ps-2 pe-16 py-1 rounded shadow-8' value={inputValue} onChange={handleInputChange} />
                <div className="absolute right-0 top-0 bottom-0 flex items-center me-4">
                  <div className='relative'>
                    <button className='' onClick={() => { handleColorPatlate("code001") }}>
                      <i className={`bi bi-palette-fill cursor-pointer mr-2 w-[29px] h-[28px] text-[${shadowColor}]`}></i>
                    </button>
                    {colorPatlate !== null && colorPatlate === "code001" && 
                      <div className='absolute color-palette-panel grid grid-cols-4 ' ref={colorcloseoutsideclick}>
                        {colors.map((color, index) => (
                          <div key={index} className="color-box flex justify-center items-center" style={{ backgroundColor: color }} onClick={() => handleColorClick(color)}></div>
                        ))}
                      </div>
                    }
                  </div>
                  <button onClick={handleAddOption}>
                    <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {addOptionEnabled && (
          <button
            className='bg-white px-6 py-1 text-gray-500'
            onClick={handleAddNewField}
            style={{
              opacity: InputVisible ? 0.5 : 1,
              fontWeight: InputVisible ? '300' : '500',
              color: InputVisible ? 'gray-500' : '[#626262]',
            }}
          >
            Add option<i className="bi bi-plus-square ml-3"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default DropdownField;
