import React, { useState, useEffect, Fragment, useRef } from "react";
import LayoutHOC from "../LayoutHOC";
import { Link, useNavigate } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import {
  ADD_UPDATE_CUSTOMER,
  CUSTOMER_GROUP_LIST,
} from "../../utils/constants";
import { useFetchCompanyCommonDataQuery } from "../../utils/company/companySelector";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import useFormValidation from "../../hooks/ErrorHandling/useFormValidation";
import {
  useCustomerTableMutation,
  useFetchCustomerTableDataQuery,
} from "../../utils/customer/customerTable";
import { parseJSONValue } from "../../utils/json/parseJSONValue";
import useClickOutside from "../../hooks/Screen/useClickOutside";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";
import useFormDataAppend from "../../hooks/FormSubmit/useFormDataAppend";
import { useFetchCustomerGroupQuery } from "../../utils/customer/customerTaskTable";
import { CHECK_DUPLICATE_CUSTOMER } from "../../utils/constants";
import { CHECK_DUPLICATE_CUST_DISP_NAME } from "../../utils/constants";

const validationRules = {
  // customer_group : {
  //   required: true,
  //   errorMessage: 'Customer Group is required.',
  // },
  customer_name: {
    required: true,
    errorMessage: "Customer Name is required.",
  },

  phone: [
    {
      required: true,
      errorMessage: "Phone Number is required.",
    },
    
    {
      maxDigits: 15,
      errorMessage: "Must be between 1 to 15 digits.",
    },
  ],
  email: [
    {
      required: true,
      errorMessage: "Email is required.",
    },
    {
      email: true,
      errorMessage: "Provide valid email",
    },
  ],
  website: [
    {
      domain: true,
      errorMessage: "Provide valid website url.",
    },
  ],
};

const AddCustomer = () => {
  const { appendIfNotEmpty } = useFormDataAppend();
  const [custName, setCustName] = useState("");
  const [cusdipName, setCusdipName] = useState("");
  const [custgroupname, setCustgroupname] = useState("");
  const [customerData, setCustomerData] = useState({
    customer_group: "",
    customer_name: "",
    customer_display_name: "",
    website: "",
    type: "",
    customerFile: null,
    business_category: "",
    customer_code: "",
    country_id: "",
    phone: "",
    email: "",
    customerfile: "",
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateField,
    isFormValid,
  } = useCustomFormValidation(customerData, validationRules);

  const { errorMessage, validateForm } = useFormValidation();

  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });

  const navigate = useNavigate();
  const { token } = useRequireAuth();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const compId = (parsedCompanyData.company_id);
  const [isFormFrozen, setIsFormFrozen] = useState(false);
  const fileInputRef = useRef(null);
  const [typeData, setTypeData] = useState({});
  const parsedtypeData = Array.isArray(parseJSONValue(typeData))
    ? parseJSONValue(typeData)
    : [];

  const [CustomerTableDrop, { data: customerCommnData }] =
    useCustomerTableMutation();

  const parsedData = Array.isArray(parseJSONValue(customerCommnData?.data))
    ? parseJSONValue(customerCommnData?.data)
    : [];
  const [isOpen, setIsOpen] = useState(false);

  const dropclickoutside = useClickOutside(() => {
    setIsOpen(null);
  });

  const toggleDropdown = (event) => {
    const { id } = event.currentTarget;
    const newIsOpen = isOpen === id ? null : id;

    setIsOpen(newIsOpen);
    if (newIsOpen) {
      const completedata = {
        model_name: "customers",
        field_name: id,
      };
      CustomerTableDrop({ token: token, data: completedata });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const completedata = {
        model_name: "customers",
        field_name: "type",
      };
      const result = await CustomerTableDrop({
        token: token,
        data: completedata,
      });
      setTypeData(result?.data?.data);
    };

    fetchData();    
  }, []);

  const handleOptionChange = (event, item) => {
    const name = event.currentTarget.getAttribute("name");
    handleChange({
      target: {
        name,
        value: item?.value,
      },
    });
    setCustomerData({ ...customerData, [name]: item?.value });
    setIsOpen(null);
  };

  const fetchCustomerTable = (event) => {
    const { name } = event.target;
    const completedata = {
      model_name: "customers",
      field_name: name,
    };
    CustomerTableDrop({ token: token, data: completedata });
  };

  const handleDispName = (event) => {
    setCusdipName(event.target.value);
    checkCustName(event.target.value);
    checkCustDispName(event.target.value);
  };
  const handleInputChange = (event) => {
    handleChange(event);
    const { name, value } = event.target;
    setCustomerData({ ...customerData, [name]: value });
    setCustName(value);
  };
  
  const checkCustName = async (name) => {
    try {
      const response = await fetch(CHECK_DUPLICATE_CUSTOMER, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ 'search_customer':name, 'company_id':compId }),
      });
      const data = await response.json();
      if (data) {        
        console.log(data);
      } else {
        console.log("Error saving customer data:", await response?.text());
      }
    } catch (error) {
      console.log("Error submitting form:", error);
    }
  };

  const checkCustDispName = async (dispname) => {
    try {
      const response = await fetch(CHECK_DUPLICATE_CUST_DISP_NAME, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ 'search_customer_display_name':dispname, 'company_id':compId }),
      });
      const data = await response.json();
      if (data) {        
        console.log(data);
      } else {
        console.log("Error saving customer data:", await response?.text());
      }
    } catch (error) {
      console.log("Error submitting form:", error);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [groupList, setGroupList] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
    }
  };

  const { data: customerTableALL, refetch: refetchCustomerData } =
    useFetchCustomerTableDataQuery({
      companyId: parsedCompanyData.company_id,
      owner: parsedCompanyData.owner,
      pagination: `?page=1`,
      token: token,
    });

    
  const handleButtonClick = async (event) => {
    event.preventDefault();

    if (isFormFrozen !== true && event.target.customer_group_id.value === "") {
      validationRules.customer_group_id = {
        required: true,
        errorMessage: "Customer Group is required.",
      };
    }
    const formValid = isFormValid();
    if (!formValid) {
      return;
    }

    const imgName = fileInputRef.current.files[0];
    const customer_grp = isFormFrozen === true ? 1 : 0;

    const formDataWithFile = new FormData();
    formDataWithFile.append("company_id", parsedCompanyData.company_id);
   
    //formDataWithFile.append("customer_grp", customer_grp);
    formDataWithFile.append(
      "customer_group_id",
      event.target.customer_group_id.value
    );
    formDataWithFile.append(
      "customer_display_name",
      event.target.customer_display_name.value
    );
    
    

    Object.entries(customerData).forEach(([key, value]) => {
      appendIfNotEmpty(formDataWithFile, key, value);
    });

    formDataWithFile.append("profile_pic", imgName);

    try {
      const response = await fetch(ADD_UPDATE_CUSTOMER, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataWithFile,
      });

      const data = await response.json();
      if (data) {
        refetchCustomerData();
        
        localStorage.removeItem("customer_data");
      
        setAlert({
          visible: true,
          message: data?.message,
          type: "success",
        });
        await refetchCustomerData();
        setTimeout(() => {
          navigate(`/add_customer1/${data?.data?.id}`);
          const customer = {
            company_id: data?.data?.id,
            customer_name: customerData.customer_name,
          };
          localStorage.setItem("customer_data", JSON.stringify(customer));
        }, 2000);
      } else {
        console.log("Error saving customer data:", await response?.text());
      }
    } catch (error) {
      console.log("Error submitting form:", error);
    }
  };

  const {
    data: customerGroup,
    error,
    isLoading,
  } = useFetchCustomerGroupQuery({ token });

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };
  const handleclick = () => {
    setIsFormFrozen(!isFormFrozen);
    setCustgroupname(custName);
  };

  const handleChangeCustomerDisplay = (e) => {
    setCusdipName(e.target.value);
    /* checkCustDispName(e.target.value); */
  };

  return (
    <div className={`p-4`}>
      <FirstMessage
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onHide={hideAlert}
      />
      <div className={`flex flex-col p-2 pt-5`}>
        <div className={`flex justify-between items-center px-6`}>
          <h5 className={`text-203C50 font-Vietnam text-[24px] font-medium`}>
            New Customer
          </h5>
          <div className={`flex items-center`}>
            <label className={`inline-flex items-center cursor-pointer`}>
              <input type={`checkbox`} className={`sr-only peer`} />
              <div
                className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
              ></div>
            </label>
          </div>
        </div>
      </div>

      <div className={`rounded-md bg-white mt-4 p-4`}>
        <form onSubmit={handleButtonClick}>
          <div className={`flex gap-5 px-3 mb-4`}>
            <div className={`gap-y-5 gap-x-12 grid grid-cols-2`}>
              <div>
                <label
                  htmlFor={`customer_name`}
                  className={`block mb-2 text-535252 text-14 font-500`}
                >
                  Name <span className="text-red-700">* </span>
                  {errors.customer_name && (
                    <span className="text-red-700 text-xxs">{errors.customer_name}</span>
                  )}
                </label>
                <input
                  type={`text`}
                  id={`customer_name`}
                  name="customer_name"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onKeyUp={handleDispName}
                  className={`w-360px rounded-5px border-BFBFBF text-xs  focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                  placeholder={`Enter Name  . . . `}
                />
                <label
                  htmlFor={`customer_grp`}
                  className={`block mb-2 text-535252 text-14 font-500`}
                >
                  <input
                    type="checkbox"
                    id="customer_grp"
                    name="customer_grp"
                    value="customer_grp"
                    className="me-2"
                    onClick={handleclick}
                  ></input>
                  Set as Customer Group
                </label>
              </div>
              <div className="w-360px">
                <label
                  htmlFor={`customer_display_name`}
                  className={`block mb-2 text-535252 text-14 font-500`}
                >
                  Cust. Display Name
                  {errors && (
                    <span className="text-red-700">
                      {" "}
                      {errors.customer_display_name}
                    </span>
                  )}
                </label>
                <input
                  type={`text`}
                  value={cusdipName}
                  id={`customer_display_name`}
                  name="customer_display_name"
                  onChange={handleChangeCustomerDisplay}
                  onBlur={handleBlur}
                  className={`w-full rounded-5px border border-gray-300 text-gray-900 text-xs focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                  placeholder={`Enter Customer Display Name  . . . `}
                />
              </div>

              <div className={`w-360px ${isFormFrozen ? "pointer-events-none" : ""}`}>
                <label
                  htmlFor={`customer_group_id`}
                  className={`block mb-2 text-535252 text-14 font-500`}
                >
                  Customer Group
                  {errors && (
                    <span className="text-red-700">
                      {" "}
                      {errors.customer_group_id}
                    </span>
                  )}
                </label>

                <select
                  className={`w-full py-[0.25rem] px-[0.5rem] rounded-5px text-xs ${isFormFrozen ? "bg-gray-200" : ""}`}
                  name="customer_group_id"
                  id="customer_group_id"
                  onChange={handleInputChange}
                >
                  <option value={custgroupname ? custgroupname : ''}>{custgroupname ? custgroupname : 'Select Option'}</option>
                  {customerGroup &&
                    customerGroup?.map((item, index) => (
                      <option value={item?.customer_group}>{item?.customer_group}</option>
                    ))}
                </select>
              </div>
              <div className={isFormFrozen ? "pointer-events-none" : ""}>
                <label
                  htmlFor={`type`}
                  className={`block mb-2 text-535252 text-14 font-500`}
                >
                  Type
                  {errors && (
                    <span className="text-red-700"> {errors.type}</span>
                  )}
                </label>
                <div className="relative w-360px">
                    <button
                      id="type"
                      onClick={(event) => {
                        toggleDropdown(event);
                      }}
                      className={`w-full h-[30px] rounded-5px tracking-wide text-535252 border border-BFBFBF  px-2 leading-tight focus:border-gray-500 focus:ring-1 focus:outline-none focus:ring-grey-100 py-1 text-center inline-flex items-center justify-between ${isFormFrozen ? "bg-gray-200" : "bg-white"}`}
                      type="button"
                      style={{
                        borderRadius: "1px",
                        /* background: "#FFF", */
                      }}
                    >
                      <span
                        className={`tracking-wide py-1 text-xs rounded-full text-black capitalize`}
                        style={{
                          backgroundColor:
                            customerData?.type?.color_code,
                        }}
                      >
                        {customerData?.type || "Select an option"}
                      </span>

                      <span>
                        <i
                          className={`bi bi-chevron-${
                            isOpen === "type" ? "up" : "down"
                          }`}
                        ></i>
                      </span>
                    </button>

                    {isOpen === "type" && (
                      <div
                        ref={dropclickoutside}
                        id="dropdownRadioBgHover"
                        className={`w-full z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute `}
                      >
                        <ul
                          className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 h-[210px] overflow-auto"
                          aria-labelledby="status"
                          style={{
                            borderRadius: "1px",
                            border: "0.4px solid #535252",
                            background: "#FFF",
                            boxShadow: "0px 0px 4px 0px #535252",
                          }}
                        >
                          {parsedData &&
                            parsedData?.map((item, index) => (
                              <li
                                key={index}
                                className="border-b border-DADADA"
                                name="type"
                                onClick={(event) => {
                                  handleOptionChange(event, item);
                                }}
                              >
                                <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                  {item?.color_code && (
                                    <input
                                      id={`status-radio-${index}`}
                                      type="radio"
                                      value={item && item?.value}
                                      name="type"
                                      className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-grey-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                      checked={
                                        customerData?.type?.value ===
                                        item?.value
                                      }
                                      onChange={(event) => {
                                        handleOptionChange(event, item);
                                      }}
                                      style={{
                                        backgroundColor: item?.color_code,
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "5px",
                                        backgroundSize: "3rem",
                                      }}
                                    />
                                  )}

                                  <label
                                    htmlFor={`status-radio-${index}`}
                                    className="w-full ml-2 text-sm tracking-wide text-383838 rounded dark:text-gray-300"
                                  >
                                    {item && item?.value}
                                  </label>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
              </div>               
            </div>

            <div className="flex flex-1 justify-end min-h-[167px]">
              <div className="w-48 me-5">
                <div className="border-dashed border-2 border-BFBFBF h-full">
                  <div
                    id="image-preview"
                    className="max-w-sm rounded-lg flex items-center justify-center text-center cursor-pointer h-full "
                  >
                    <input
                      id="upload"
                      name="customerfile"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                    <label htmlFor="upload" className="cursor-pointer">
                      {selectedFile ? (
                        <Fragment>
                          <div className="relative">
                            <img
                              src={selectedFile}
                              alt="img preview"
                              className="object-cover"
                            />
                            {selectedFile ? (
                              <span className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center"></span>
                            ) : (
                              <span className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center">
                                <span className="relative">
                                  <i className="bi bi-circle-fill text-1E1E1E text-4xl"></i>
                                  <i className="bi bi-upload text-2xl text-white absolute top-0 left-0 right-0 bottom-0" />
                                </span>
                              </span>
                            )}
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <i className="bi bi-upload text-2xl" />
                          <p className="text-lg text-gray-500">Add Logo</p>
                        </Fragment>
                      )}

                      <span
                        id="filename"
                        className="text-gray-500 bg-gray-200 z-50"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`gap-12 flex px-3 mb-3`}>
            <div className="w-360px">
              <label
                htmlFor={`business_category`}
                className={`block mb-2 text-535252 text-14 font-500`}
              >
                Business Category
                {errors && (
                  <span className="text-red-700">
                    {" "}
                    {errors.business_category}
                  </span>
                )}
              </label>

              <div className="relative">
                <button
                  id="business_category"
                  onClick={(event) => {
                    toggleDropdown(event);
                  }}
                  className={`w-full h-[30px] rounded-5px tracking-wide bg-white text-535252 border border-BFBFBF  px-2 leading-tight focus:border-gray-500 focus:ring-1 focus:outline-none focus:ring-grey-100 py-1 text-center inline-flex items-center justify-between`}
                  type="button"
                  style={{
                    borderRadius: "1px",
                    background: "#FFF",
                  }}
                >
                  <span
                    className={`tracking-wide py-1 text-xs rounded-full text-black`}
                    style={{
                      backgroundColor:
                        customerData?.business_category?.color_code,
                    }}
                  >
                    {customerData?.business_category || "Select an option"}
                  </span>

                  <span>
                    <i
                      className={`bi bi-chevron-${
                        isOpen === "business_category" ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </button>

                {isOpen === "business_category" && (
                  <div
                    ref={dropclickoutside}
                    id="dropdownRadioBgHover"
                    className={`w-full z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute `}
                  >
                    <ul
                      className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 h-[210px] overflow-auto"
                      aria-labelledby="status"
                      style={{
                        borderRadius: "1px",
                        border: "0.4px solid #535252",
                        background: "#FFF",
                        boxShadow: "0px 0px 4px 0px #535252",
                      }}
                    >
                      {parsedData &&
                        parsedData?.map((item, index) => (
                          <li
                            key={index}
                            className="border-b border-DADADA"
                            name="business_category"
                            onClick={(event) => {
                              handleOptionChange(event, item);
                            }}
                          >
                            <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                              {item?.color_code && (
                                <input
                                  id={`status-radio-${index}`}
                                  type="radio"
                                  value={item && item?.value}
                                  name="business_category"
                                  className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-grey-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                  checked={
                                    customerData?.business_category?.value ===
                                    item?.value
                                  }
                                  onChange={(event) => {
                                    handleOptionChange(event, item);
                                  }}
                                  style={{
                                    backgroundColor: item?.color_code,
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "5px",
                                    backgroundSize: "3rem",
                                  }}
                                />
                              )}

                              <label
                                htmlFor={`status-radio-${index}`}
                                className="w-full ml-2 text-sm tracking-wide text-383838 rounded dark:text-gray-300"
                              >
                                {item && item?.value}
                              </label>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor={`customer_code`}
                className={`block mb-2 text-535252 text-14 font-500`}
              >
               Customer Code
               
              </label>
              <input
                type={`text`}
                id={`customer_code`}
                name="customer_code"
                onChange={handleInputChange}
                onBlur={handleBlur}
                className={`w-360px rounded-5px border-BFBFBF text-sm  focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                placeholder={`Enter Customer Code `}
              />
            </div>
            <div className="w-[355px]">
              <label
                htmlFor={`country_id`}
                className={`block mb-2 text-535252 text-14 font-500`}
              >
                Country
                {errors && (
                  <span className="text-red-700"> {errors.country_id}</span>
                )}
              </label>

              <div className="relative">
                <button
                  id="country_id"
                  onClick={(event) => {
                    toggleDropdown(event);
                  }}
                  className={`w-full h-[30px] rounded-5px bg-white text-535252 border border-BFBFBF  px-2 leading-tight focus:border-gray-500 focus:ring-1 focus:outline-none focus:ring-grey-100 py-1 text-center inline-flex items-center justify-between`}
                  type="button"
                  style={{
                    borderRadius: "1px",
                    background: "#FFF",
                  }}
                >
                  <span
                    className={`tracking-wide py-1 text-xs rounded-full text-black`}
                    style={{
                      backgroundColor: customerData?.country_id?.color_code,
                    }}
                  >
                    {customerData?.country_id || "Select an option"}
                  </span>

                  <span>
                    <i
                      className={`bi bi-chevron-${
                        isOpen === "country_id" ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </button>

                {isOpen === "country_id" && (
                  <div
                    ref={dropclickoutside}
                    id="dropdownRadioBgHover"
                    className={`w-full z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute `}
                  >
                    <ul
                      className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 h-[210px] overflow-auto"
                      aria-labelledby="status"
                      style={{
                        borderRadius: "1px",
                        border: "0.4px solid #535252",
                        background: "#FFF",
                        boxShadow: "0px 0px 4px 0px #535252",
                      }}
                    >
                      {parsedData &&
                        parsedData?.map((item, index) => (
                          <li
                            key={index}
                            className="border-b border-DADADA"
                            name="country_id"
                            onClick={(event) => {
                              handleOptionChange(event, item);
                            }}
                          >
                            <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                              {item?.color_code && (
                                <input
                                  id={`status-radio-${index}`}
                                  type="radio"
                                  value={item && item?.value}
                                  name="country_id"
                                  className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-grey-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                  checked={
                                    customerData?.country_id?.value ===
                                    item?.value
                                  }
                                  onChange={(event) => {
                                    handleOptionChange(event, item);
                                  }}
                                  style={{
                                    backgroundColor: item?.color_code,
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "5px",
                                    backgroundSize: "3rem",
                                  }}
                                />
                              )}

                              <label
                                htmlFor={`status-radio-${index}`}
                                className="w-full ml-2 text-sm tracking-wide text-383838 rounded dark:text-gray-300"
                              >
                                {item && item?.value}
                              </label>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={`gap-12 flex px-3 mb-4`}>
            <div>
              <label
                htmlFor={`phone`}
                className={`block mb-2 text-535252 text-14 font-500`}
              >
                Phone <span className="text-red-700">* </span>
                {errors && <span className="text-red-700">{errors.phone}</span>}
              </label>
              
              <input
                type={`text`}
                id={`phone`}
                name="phone"
                value={customerData?.phone}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className={`w-360px border-BFBFBF text-sm  focus:ring-1d4469 focus:border-1d4469 rounded-5px px-2.5 py-1`}
                placeholder={`Enter Phone No. `}
                maxLength="16"
              />
            </div>
            <div>
              <label
                htmlFor={`email`}
                className={`block mb-2 text-535252 text-14 font-500`}
              >
                Email <span className="text-red-700">* </span>
                {errors && (
                  <span className="text-red-700"> {errors.email}</span>
                )}
              </label>
              <input
                type={`text`}
                id={`email`}
                name="email"
                onChange={handleInputChange}
                className={`w-[360px] rounded-5px border border-gray-300 text-gray-900 text-sm  focus:ring-1d4469 focus:border-1d4469 px-2.5py-1`}
                placeholder={`Enter Email `}
              />
            </div>

            <div>
              <label
                htmlFor={`website`}
                className={`block mb-2 text-535252 text-14 font-500`}
              >
                Website
                {errors && (
                  <span className="text-red-700"> {errors.website}</span>
                )}
              </label>
              <input
                type={`text`}
                id={`website`}
                name="website"
                onChange={handleInputChange}
                className={`w-360px border border-gray-300 text-gray-900 text-sm  focus:ring-1d4469 focus:border-1d4469 rounded-5px px-2.5 py-1`}
                placeholder={`Enter Website`}
              />
            </div>
          </div>

          <div className={`flex justify-end pt-3`}>
            <div></div>
            <Link
              to="/customers"
              type={`button`}
              className={`text-535252 bg-F4F4F4 border border-EAE2E2 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2 me-5 mb-2 focus:outline-none`}
            >
              CANCEL
            </Link>
            <button
              type="submit"
              className={`py-2 px-5 me-2 mb-2 text-sm font-medium text-white bg-1D4469 focus:outline-none  focus:z-10 focus:ring-4 focus:ring-gray-100 
                     `}
            >
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LayoutHOC(AddCustomer);