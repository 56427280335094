import React from 'react'

const SubmitIcon = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
            <path d="M5.0488 16.5168L2.97866 17.717C2.67944 17.8982 2.33772 17.9958 1.98863 17.9999C1.63954 18.0039 1.29567 17.9143 0.992373 17.7401C0.689072 17.566 0.43728 17.3136 0.262878 17.009C0.0884757 16.7044 -0.00224455 16.3584 4.21847e-05 16.0068L1.42233 11.6712C1.53824 11.319 1.75769 11.0106 2.0516 10.7869C2.34551 10.5632 2.69998 10.4347 3.06802 10.4186L8.21357 10.2236C8.51232 10.1902 8.78834 10.047 8.98882 9.82139C9.1893 9.59581 9.30015 9.30367 9.30015 9.0009C9.30015 8.69813 9.1893 8.40599 8.98882 8.1804C8.78834 7.95482 8.51232 7.81163 8.21357 7.77825L3.06802 7.57572C2.69998 7.55959 2.34551 7.43115 2.0516 7.20743C1.75769 6.98372 1.53824 6.67529 1.42233 6.32306L0.00748889 2.00252C0.00489194 1.65051 0.0951455 1.30411 0.269022 0.998756C0.442899 0.693398 0.69416 0.44003 0.997117 0.264557C1.30007 0.089084 1.64386 -0.00219943 1.99332 4.02424e-05C2.34278 0.00227991 2.68539 0.0979626 2.9861 0.277305L9.00291 3.76524L15.0197 7.26818C15.318 7.44314 15.5655 7.69383 15.7375 7.99524C15.9095 8.29665 16 8.63823 16 8.9859C16 9.33356 15.9095 9.67515 15.7375 9.97655C15.5655 10.278 15.318 10.5287 15.0197 10.7036L9.00291 14.1916L8.01252 14.7691L5.0488 16.5168Z" fill="#1D4469"/>
      </svg>
  )
}

export default SubmitIcon