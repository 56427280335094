import React, { Fragment, useEffect } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import { useParams } from "react-router-dom";
import { useFetchCustomerServicesDataQuery } from "../../utils/customer/customerSelect";
import { parseJSONValue } from "../../utils/json/parseJSONValue";
import { useSelector } from "react-redux";
 
const ClientServiceCard = ({ isOpen, onToggle, handleRouteSection }) => {
  const message = useSelector(state => state.customerServices.message);
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const {
    data: customerServicesData,
    refetch: customerRefetch,
  } = useFetchCustomerServicesDataQuery(
    { token, customerId },
    { skip: isOpen !== true }
  );
  const parsedData = Array.isArray(parseJSONValue(customerServicesData?.data?.data)) ? parseJSONValue(customerServicesData?.data?.data) : [];
 
  useEffect(() => {
    if (isOpen) {
      customerRefetch();
    }
  }, [message, isOpen, customerRefetch]);
 
  return (
 
    <div className=  "relative bg-1d44691a border border-1D446933 mb-3 mt-3 cursor-pointer rounded" onClick={handleRouteSection}>
    {/* <div className="relative bg-1d44691a border border-1D446933 mb-3 mt-3 rounded-3px p-4"> */}
      <div className=" relative flex justify-between items-center px-3 py-2">
        <h4 className="text-2C495D font-normal cursor-pointer  h-[24] w-[267] gap-133">Services</h4>
        <button
          onClick={(e) => { e.stopPropagation(); onToggle(); }}
          // className={`flex items-center justify-center  rounded 
          //   ${isOpen ? 'bg-white  border border-gray-500' : 'hover:bg-gray-300 hover:border-gray-400 '}`}
          className={`relative flex w-[20px] h-[20px] p-3 justify-center items-center flex-shrink-0 transition-all duration-200 ease-in-out hover:bg-white hover:border-white
            ${isOpen ? '' : ' border-none hover:bg-white hover:border-2 hover:border-white'}`}
        >
          {isOpen ? (
            <i className={`bi  bi-${isOpen ? "chevron-up" : "chevron-down" } `}></i>
          ) : (
            <i className={`bi bi-${isOpen ? "chevron-up" : "chevron-down" } `}></i>
          )}
        </button>
      </div>
 
      {isOpen && (
        <Fragment>
          <div className="p-3 bg-white rounded-b-lg w-100px">
            <table className="tasklistpop min-w-full bg-white ">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 text-14 font-normal text-start ">Service Name</th>
                </tr>
              </thead>
              <tbody>
                {!parsedData ? null : parsedData.map((cust) => {
                  const service = typeof cust?.service_name?.value === 'string' ? JSON.parse(cust?.service_name?.value) : cust?.service_name?.value;
                  return (
                    <tr className="border-b" key={cust.id}>
                      <td className="p-2 text-xs">{service.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Fragment>
      )}
    </div>
  );
};
 
export default ClientServiceCard;