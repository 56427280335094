import { useState } from 'react';

const useFormDataAppend = () => {
  const appendIfNotEmpty = (formData, key, value) => {
    if (value && typeof value === 'object' && 'value' in value) {
      formData.append(key, JSON.stringify({ value: value.value }));
    } else if (value !== undefined && value !== '') {
      formData.append(key, value);
    }
  };

  return { appendIfNotEmpty };
};

export default useFormDataAppend;
