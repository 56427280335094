import { useState, useEffect, useRef, Fragment } from "react";
import { BASE_URL } from "../../utils/constants";
import CustomerSocialLink from "./CustomerSocialLink";
import CustomerAddress from "./CustomerAddress";
import CustomerContactPerson from "./CustomerContactPerson";
import { useFetchCustomerAddressDataBasedOnIdQuery } from "../../utils/customer/customerSelect";
import useRequireAuth from "../../utils/useRequireAuth";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import { ADD_UPDATE_CUSTOMER } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { parseJSONValue } from "../../utils/json/parseJSONValue";
import { parseJSONFields } from "../../utils/json/parseJSONFields";

import { useCustomerTableMutation } from "../../utils/customer/customerTable";
import useClickOutside from "../../hooks/Screen/useClickOutside";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";
import useFormDataAppend from "../../hooks/FormSubmit/useFormDataAppend";
import { useFetchCustomerGroupQuery } from "../../utils/customer/customerTaskTable";
//import TopTitle from "../GlobalFeature/TopTitle";

const validationRules = {
  customer_code: {
    required: true,
    errorMessage: "Tax No is required.",
  },
  phone: [
    {
      required: true,
      errorMessage: "Phone Number is required.",
    },
    {
      number: true,
      errorMessage: "Phone Number should contain only digits.",
    },
    {
      maxDigits: 15,
      errorMessage: "Must be between 1 to 15 digits.",
    },
  ],
  email: [
    {
      required: true,
      errorMessage: "Email is required.",
    },
    {
      email: true,
      errorMessage: "Provide valid email",
    },
  ],
};

const CustomerHome = ({ leftTab }) => {
  const { appendIfNotEmpty } = useFormDataAppend();
  
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });

  const [customerData, setCustomerData] = useState({

    customer_group_id: "",
    customer_name: "",
    customer_display_name: "",
    website: "",
    type: "",
    customerFile: null,
    business_category: "",
    customer_code: "",
    country_id: "",
    phone: "",
    email: "",
    customerfile: "",

  });
  const [validationErrors, setValidationErrors] = useState({});

  const [formData, setFormData] = useState({});

  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const navigate = useNavigate();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [customer, setCustomer] = useState([]);
  const [setImageSrc] = useState("");
  const fileInputRef = useRef(null);
  const [isSeleted, setIsSeleted] = useState(false);
  const [isFormFrozen, setIsFormFrozen] = useState(false);
  const [custName, setCustName] = useState("");
  const [cusdipName, setCusdipName] = useState("");
  const [custgroupname, setCustgroupname] = useState("");
  const [typeData, setTypeData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  

  const parsedtypeData = Array.isArray(parseJSONValue(typeData))
    ? parseJSONValue(typeData)
    : [];

  const { data: customerAddress, refetch: refetchCustomerData } =
    useFetchCustomerAddressDataBasedOnIdQuery(
      { token, customerId },
      {
        skip: leftTab !== 0,
      }
    );

  const [CustomerTableDrop, { data: customerCommnData }] =
    useCustomerTableMutation();

  const parsedData = Array.isArray(parseJSONValue(customerCommnData?.data))
    ? parseJSONValue(customerCommnData?.data)
    : [];

  const handleOptionChange = (item,name) => {
    let fieldValue;
    if(name==='customer_group_id'){
      fieldValue=item?.customer_group;
    }else{
      const itemvalue=parseJSONValue(item);
      fieldValue=itemvalue?.value;
      
    }
    setCustomerData(prev => ({
      ...prev,
      [name]: fieldValue
    }));
   

    setIsOpen(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeModalOutside = useClickOutside(() => {
    closeModal();
  });

  const dropclickoutside = useClickOutside(() => {
    setIsOpen(null);
  });

  const toggleDropdown = (event) => {
    const { id } = event.currentTarget;
    const newIsOpen = isOpen === id ? null : id;

    setIsOpen(newIsOpen);
    if (newIsOpen) {
      const completedata = {
        model_name: "customers",
        field_name: id,
      };

      CustomerTableDrop({ token: token, data: completedata });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleChange(event);
    const isCurrentValueObject =
      customerData[name] && typeof customerData[name] === "object";
    const newValue = isCurrentValueObject ? { value } : value;
    setCustomerData({ ...customerData, [name]: newValue });
    setCustName(value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
    }
  };

  const fetchData = async () => {
    const parsedResponse = parseJSONFields(customerAddress?.data[0]);
    setCustomerData(parsedResponse);
    setFormData(parsedResponse);
  };

  useEffect(() => {
    if (leftTab === 0) {
      fetchData();
    }
  }, [customerAddress, leftTab]);

  const openModal = async () => {
    setIsModalOpen(true);
    fetchData();
    const completedata = {
      model_name: "customers",
      field_name: "type",
    };

    const result = await CustomerTableDrop({
      token: token,
      data: completedata,
    });
    setTypeData(result?.data?.data);
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    let hasError = false;
    const errors = {};

    const fieldsToValidate = [
      {
        key: "customer_group_id",
        message: "Customer Group is required.",
        condition: !isFormFrozen && !customerData.customer_group_id,
      },
      
      {
        key: "phone",
        message: "Phone number can't be blank.",
        condition: !customerData.phone && !event.target.phone.value,
      },
      {
        key: "email",
        message: "Email can't be blank.",
        condition: !customerData.email && !event.target.email.value,
      },
      {
        key: "customer_name",
        message: "Customer name can't be blank.",
        condition:
          !customerData.customer_name && !event.target.customer_name.value,
      },
    ];
    fieldsToValidate.forEach(({ key, message, condition }) => {
      if (condition) {
        errors[key] = message;
        hasError = true;
      }
    });
    if (hasError) {
      setValidationErrors(errors);
      return false;
    }

    const imgName = fileInputRef.current.files[0];
    const formDataWithFile = new FormData();

    formDataWithFile.append("company_id", parsedCompanyData.company_id);
    const customer_grp = isFormFrozen === true ? 1 : 0;
    formDataWithFile.append("customer_grp", customer_grp);

    Object.entries(customerData).forEach(([key, value]) => {
      appendIfNotEmpty(formDataWithFile, key, value);
    });

    formDataWithFile.append("profile_pic", imgName);
    formDataWithFile.append("id", customerId);

    try {
      const response = await fetch(ADD_UPDATE_CUSTOMER, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataWithFile,
      });
      const data = await response.json();
      refetchCustomerData();
      setAlert({
        visible: true,
        message: data.message,
        type: "success",
      });
      closeModal();
    } catch (error) {
      setAlert({
        visible: true,
        message: error,
        type: "danger",
      });
      console.log("Error submitting form:", error);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateField,
    isFormValid,
  } = useCustomFormValidation(customerData, validationRules);

  useEffect(() => {
    const checkFileIsSelected = () => {
      if (customerData?.profile_pic) {
        setIsSeleted(true);
      } else if (selectedFile) {
        setIsSeleted(true);
      } else {
        setIsSeleted(false);
      }
    };

    checkFileIsSelected();
  }, [customerData?.profile_pic, selectedFile]);

  const handleSetFalse = () => {
    setIsSeleted(false);
    setSelectedFile(false);
  };

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  const handleclick = () => {
    setIsFormFrozen(!isFormFrozen);
    setCustgroupname(custName);
  };
  const handleDispName = (event) => {
    setCusdipName(event.target.value);
  };

  const {
    data: customerGroup,
    error,
    isLoading,
  } = useFetchCustomerGroupQuery({ token });

  useEffect(() => {
    const custgrb = customerData?.customer_grp ? true : false;
    setIsFormFrozen(custgrb);
  }, [customerData?.customer_grp]);

  setTimeout(() => {
    setValidationErrors("");
  }, 200);
  const ImageUpload = ({ BASE_URL, customerData }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(URL.createObjectURL(file));
      }
    };
  
    const isSelected = selectedFile || customerData?.profile_pic;
  

  return (
    <Fragment>
      <FirstMessage
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onHide={hideAlert}
      />
      <div className="flex flex-col">
        <div className="bg-white shadow-2 rounded p-5 mb-4">
          <div className="flex flex-row">
            <div
              htmlFor="dropzone-file"
              className="border-2 w-[121px] border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100 h-[121px]"
            >
              <div className="flex items-center h-full w-full text-center text-gray-500">
                {formData?.profile_pic && (
                  <img
                    src={
                      formData?.profile_pic
                        ? `${BASE_URL}${formData?.profile_pic}`
                        : "https://picsum.photos/200/300"
                    }
                    alt="Uploaded "
                    className="w-full h-full"
                  />
                )}
              </div>
            </div>

            <div className="ml-8 flex flex-col w-[81%]">
              <div className=" mb-4 flex justify-end">
                <button
                  className="py-2 px-6 border border-b-1 border-l-0 border-r-0 rounded-lg border-b-2C495D border-t-gray-300 text-2C495D text-sm font-500 hover:bg-2C495D hover:text-white hover:border-b-black hover:border-t-gray-300 transition"
                  onClick={openModal}
                >
                  Edit
                </button>
              </div>
              <div className="grid grid-cols-2 mt-8">
                <div class="">
                  <div className="text-20 text-203C50 font-500 pb-6 capitalize">
                    {formData?.customer_name}
                  </div>
                </div>
                <div class="">
                  <div className="flex space-x-1">
                    <p class="text-sm font-light text-848687">Phone</p>
                    <p class="text-sm font-normal text-2C495D capitalize">
                      {formData?.phone}
                    </p>
                  </div>
                  <div className="flex space-x-1 mt-1">
                    <p class="text-sm font-light text-848687 mr-1">Email</p>
                    <p class="text-sm font-normal text-2C495D capitalize">
                      {formData?.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-b border-F1F6F9 my-3 h-1.5 bg-F1F6F9"></hr>
          <div className="flex flex-row flex-wrap">
            <div class="basis-1/4 mt-4 mb-3">
              <p class="text-sm font-light text-848687">Cus. Display Name</p>
              <p class="text-sm font-normal text-2C495D mb-2 capitalize">
                {formData?.customer_display_name}
              </p>
            </div>

            <div class="basis-1/4 mt-2 mb-3">
              <div class="basis-1/4 mt-2">
                <p class="text-sm font-light text-848687">Customer Code</p>
                <p class="text-sm font-normal text-2C495D mb-0 capitalize">
                  {formData?.customer_code}
                </p>
              </div>
            </div>
            <div class="basis-1/4 mt-2 mb-3">
              <div class="basis-1/4 mt-2">
                <p class="text-sm font-light text-848687">Country</p>
                <p class="text-sm font-normal text-2C495D mb-0 capitalize">
                  {formData?.country_id !== "" && formData?.country_id}
                </p>
              </div>
            </div>
            <div class="basis-1/4 mt-4 mb-3">
              <p class="text-sm font-light text-848687">Business Category</p>
              <p class="text-sm font-normal text-2C495D mb-2 capitalize">
                {formData?.business_category !== "" &&
                  formData?.business_category}
              </p>
            </div>
          </div>
          <div className="flex flex-row flex-wrap">
            {!isFormFrozen && (
              <Fragment>
                <div class="basis-1/4 mt-2 mb-3">
                  <p class="text-sm font-light text-848687">Type</p>
                  <p class="text-sm font-normal text-2C495D mb-0 capitalize">
                    {formData?.type !== "" && formData?.type}
                  </p>
                </div>
                <div class="basis-1/4 mt-2 mb-3">
                  <p class="text-sm font-light text-848687">Customer Group</p>
                  <p class="text-sm font-normal text-2C495D mb-2 capitalize">
                    {formData?.customer_group !== "" &&
                      formData?.customer_group}
                  </p>
                </div>
              </Fragment>
            )}
            <div class="basis-1/4 mt-2">
              <p class="text-sm font-light text-848687">Website</p>
              <p class="text-sm font-normal text-2C495D mb-2 capitalize">
                {formData?.website}
              </p>
            </div>
            <div class="basis-1/4 mt-2"></div>
          </div>
        </div>
        {/* <TopTitle
          name={formData?.customer_name}
          type={formData?.type}
          pt="0px"
        /> */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-[10000]">
            <div className="bg-black bg-opacity-50 absolute inset-0  "></div>
            <div
              className="bg-white p-3 rounded shadow-lg relative z-10 justify-items-center"
              ref={closeModalOutside}
            >
              <div className="flex justify-between  mb-2">
                <div className="text-xl">Edit Customer</div>
                <div
                  className="group inline-block p-2 relative"
                  onClick={() => {
                    closeModal();
                    handleSetFalse();
                  }}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    ></path>
                  </svg>
                  <div className="absolute inset-0 rounded-full border-2 border-transparent group-hover:border-gray-500 group-hover:cursor-pointer transition-all duration-300 ease-in-out"></div>
                </div>
              </div>
              <form onSubmit={handleButtonClick}>
                <div className={`flex gap-3 px-3 gap-x-3`}>
                  <div className={` grid grid-cols-2 `}>
                    
                        <div className="mr-3 h-[65px]">
                          <label
                            htmlFor={`customer_name`}
                            className={`block mb-2 text-535252 text-14 font-500`}
                          >
                            Name <span className="text-red-700">* </span>
                            {validationErrors.customer_name && (
                              <span className="text-red-700">
                                {validationErrors.customer_name}
                              </span>
                            )}
                          </label>
                          <input
                            type={`text`}
                            id={`customer_name`}
                            name="customer_name"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            onKeyUp={handleDispName}
                            value={customerData?.customer_name}
                            className={`w-360px rounded-5px border-BFBFBF text-xs  focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                            placeholder={`Enter Name  . . . `}
                          />
                          <label
                            htmlFor={`customer_grp`}
                            className={`block mb-2 text-535252 text-14 font-400`}
                          >
                            <input
                              type="checkbox"
                              id="customer_grp"
                              name="customer_grp"
                              value="customer_grp"
                              className="me-2"
                              onClick={handleclick}
                              checked={!!isFormFrozen}
                            />
                            Set as Customer Group
                          </label>
                        </div>

                        <div>
                          <label
                            htmlFor={`customer_display_name`}
                            className={`block mb-2 text-535252 text-14 font-500`}
                          >
                            Cust. Display Name
                          </label>
                          <input
                            type={`text`}
                            id={`customer_display_name`}
                            name="customer_display_name"
                            value={customerData?.customer_display_name}
                            onChange={handleInputChange}
                            className={`w-360px rounded border-BFBFBF text-xs  focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                            placeholder={`Enter Customer Display Name  . . . `}
                          />
                        </div>
                   
                    <div className={`w-360px ${isFormFrozen ? "pointer-events-none" : ""}`}>
                        <label
                          htmlFor="customer_group_id"
                          className="block mb-2 text-535252 text-14 font-500"
                        >
                          Customer Group
                          {validationErrors && (
                            <span className="text-red-700">
                              {" "}{validationErrors.customer_group_id}
                            </span>
                          )}
                        </label>
                        <div className="relative w-[360px]">
                          <button
                            id="customer_group_id"
                            onClick={toggleDropdown}
                            className={`w-full h-[30px] rounded-5px tracking-wide text-535252 border border-BFBFBF px-2 leading-tight focus:border-gray-500 focus:ring-1 focus:outline-none focus:ring-grey-100 py-1 text-center inline-flex items-center justify-between ${isFormFrozen ? "bg-gray-200" : "bg-white"}`}
                            type="button"
                            style={{ borderRadius: "1px", /* background: "#FFF" */ }}
                          >
                            <span className="tracking-wide py-1 text-xs rounded-full text-black"> 
                            {customerData?.customer_group_id
                                ? customerGroup?.find(group => group.customer_group === customerData?.customer_group_id)?.customer_group
                                : "Select a group"}
                            </span>
                            <span>
                              <i className={`bi bi-chevron-${isOpen === "customer_group_id" ? "up" : "down"}`}></i>
                            </span>
                          </button>
                          {isOpen === "customer_group_id" && (
                            <div
                              ref={dropclickoutside}
                              id="dropdownRadioBgHover"
                              className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute"
                            >
                              <ul
                                className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 h-[210px] overflow-auto"
                                aria-labelledby="status"
                                style={{ borderRadius: "1px", border: "0.4px solid #535252", background: "#FFF", boxShadow: "0px 0px 4px 0px #535252" }}
                              >
                                {customerGroup?.map((item, index) => (
                                  <li
                                    key={index}
                                    className="border-b border-DADADA"
                                    onClick={() => handleOptionChange(item, 'customer_group_id')}
                                  >
                                    <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                      {item?.color_code && (
                                        <input
                                          id={`status-radio-${index}`}
                                          type="radio"
                                          value={item?.customer_group}
                                          name="customer_group_id"
                                          className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-grey-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                          checked={item?.customer_group === customerData?.customer_group_id}
                                          onChange={() => handleOptionChange(item, 'customer_group_id')}
                                        />
                                      )}
                                      <label
                                        htmlFor={`status-radio-${index}`}
                                        className="w-full ml-2 text-sm tracking-wide text-383838 rounded dark:text-gray-300"
                                      >
                                        {item?.customer_group}
                                      </label>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className={isFormFrozen ? "pointer-events-none" : ""}>
                      <label htmlFor="type" className="block mb-2 text-535252 text-14 font-500">
                        Type
                        {errors && <span className="text-red-700"> {errors.type}</span>}
                      </label>

                      <div className="relative w-[360px]">
                        <button
                          id="Type"
                          onClick={toggleDropdown}
                          className={`w-full h-[30px] rounded-5px tracking-wide text-535252 border border-BFBFBF px-2 leading-tight focus:border-gray-500 focus:ring-1 focus:outline-none focus:ring-grey-100 py-1 text-center inline-flex items-center justify-between ${isFormFrozen ? "bg-gray-200" : "bg-white"}`}
                          type="button"
                        >
                          <span className="tracking-wide py-1 text-xs rounded-full text-black capitalize">
                            {customerData?.type || "Select an option"}
                          </span>
                          <span>
                            <i className={`bi bi-chevron-${isOpen === "Type" ? "up" : "down"}`}></i>
                          </span>
                        </button>

                        {isOpen === "Type" && (
                          <div
                            ref={dropclickoutside}
                            id="dropdownRadioBgHover"
                            className="w-full z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute"
                          >
                            <ul
                              className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 h-[210px] overflow-auto"
                              aria-labelledby="status"
                              style={{
                                borderRadius: "1px",
                                border: "0.4px solid #535252",
                                background: "#FFF",
                                boxShadow: "0px 0px 4px 0px #535252",
                              }}
                            >
                              {parsedData && parsedData.map((item, index) => (
                                <li
                                  key={index}
                                  className="border-b border-DADADA"
                                  name="Type"
                                  onClick={() => handleOptionChange(item,'type')}
                                >
                                  <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                    {item?.color_code && (
                                      <input
                                        id={`status-radio-${index}`}
                                        type="radio"
                                        value={item.value}
                                        name="Type"
                                        className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-grey-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                        checked={item.value === customerData?.type}
                                        onChange={() => handleOptionChange(item,'type')}
                                        style={{
                                          backgroundColor: item.color_code,
                                          width: "25px",
                                          height: "25px",
                                          borderRadius: "5px",
                                          backgroundSize: "3rem",
                                        }}
                                      />
                                    )}
                                    <label
                                      htmlFor={`status-radio-${index}`}
                                      className="w-full ml-2 text-sm tracking-wide text-383838 rounded dark:text-gray-300"
                                    >
                                      {item.value}
                                    </label>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-1 justify-end min-h-[167px]">
                    <div className="w-48 me-5">
                      <div className="border-dashed border-2 border-BFBFBF h-full">
                        <div
                          id="image-preview"
                          className="max-w-sm rounded-lg flex items-center justify-center text-center cursor-pointer h-full "
                        >
                          <input
                            id="upload"
                            name="customerfile"
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                          />
                          <label htmlFor="upload" className="cursor-pointer">
                            
                            <Fragment>
                              <div className="relative">
                                <img
                                  src={
                                    selectedFile
                                      ? selectedFile
                                      : `${BASE_URL}${customerData?.profile_pic}`
                                  }
                                  className="object-cover"
                                /> 
                                <span><i class="bi bi-upload"></i></span>

                                {!isSeleted && (
                                   <span className="absolute inset-0 flex items-center justify-center bg-opacity-50 bg-black hover:bg-opacity-60 transition-all">
                                   <i className="bi bi-upload text-4xl text-white"></i>
                                 </span>
                                )}
                              </div>
                            </Fragment>

                            <span
                              id="filename"
                              className="text-gray-500 bg-gray-200 z-50"
                            />
                          </label>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`gap-3 flex px-3 mb-3`}>
                  <div>
                    <label
                      htmlFor={`business_category`}
                      className={`block mb-2 text-535252 text-14 font-500`}
                    >
                      Business Category
                    </label>

                    <div className="relative w-[360px]">
                      <button
                        id="business_category"
                        onClick={(event) => {
                          toggleDropdown(event);
                        }}
                        className={`w-full h-[30px] tracking-wide bg-white text-535252 border border-BFBFBF  px-2 leading-tight focus:border-gray-500 focus:ring-1 focus:outline-none focus:ring-grey-100 py-1 text-center inline-flex items-center justify-between`}
                        type="button"
                        style={{
                          borderRadius: "4px",
                          background: "#FFF",
                        }}
                      >
                        <span
                          className={`tracking-wide py-1 text-xs rounded-full text-black`}
                          style={{
                            backgroundColor:
                              customerData?.business_category?.color_code,
                          }}
                        >
                          {customerData?.business_category}
                        </span>

                        <span>
                          <i
                            className={`bi bi-chevron-${
                              isOpen === "business_category" ? "up" : "down"
                            }`}
                          ></i>
                        </span>
                      </button>

                      {isOpen === "business_category" && (
                        <div
                          ref={dropclickoutside}
                          id="dropdownRadioBgHover"
                          className={`w-full z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute `}
                        >
                          <ul
                            className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 h-[210px] overflow-auto"
                            aria-labelledby="status"
                            style={{
                              borderRadius: "1px",
                              border: "0.4px solid #535252",
                              background: "#FFF",
                              boxShadow: "0px 0px 4px 0px #535252",
                            }}
                          >
                            {parsedData &&
                              parsedData?.map((item, index) => (
                                <li
                                  key={index}
                                  className="border-b border-DADADA"
                                  name="business_category"
                                  onClick={() => {
                                    handleOptionChange(item,"business_category");
                                  }}
                                >
                                  <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                    {item?.color_code && (
                                      <input
                                        id={`status-radio-${index}`}
                                        type="radio"
                                        value={item && item?.value}
                                        name="business_category"
                                        className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-grey-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                        checked={
                                          customerData?.business_category ===
                                          item?.value
                                        }
                                        onChange={() => {
                                          handleOptionChange(item,'business_category');
                                        }}
                                        style={{
                                          backgroundColor: item?.color_code,
                                          width: "25px",
                                          height: "25px",
                                          borderRadius: "5px",
                                          backgroundSize: "3rem",
                                        }}
                                      />
                                    )}

                                    <label
                                      htmlFor={`status-radio-${index}`}
                                      className="w-full ml-2 text-sm tracking-wide text-383838 rounded dark:text-gray-300"
                                    >
                                      {item && item?.value}
                                    </label>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor={`customer_code`}
                      className={`block mb-2 text-535252 text-14 font-500`}
                    >
                     Customer Code
                    </label>
                    <input
                      type={`text`}
                      id={`customer_code`}
                      name="customer_code"
                      value={customerData?.customer_code || ""}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      className={`w-360px rounded border-BFBFBF text-xs focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                      placeholder={`Enter Customer Code`}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`country`}
                      className={`block mb-2 text-535252 text-14 font-500`}
                    >
                      Country
                    </label>

                    <div className="relative w-[360px]">
                      <button
                        id="country_id"
                        onClick={(event) => {
                          toggleDropdown(event);
                        }}
                        className={`w-full h-[30px] tracking-wide bg-white text-535252 border border-BFBFBF  px-2 leading-tight focus:border-gray-500 focus:ring-1 focus:outline-none focus:ring-grey-100 py-1 text-center inline-flex items-center justify-between`}
                        type="button"
                        style={{
                          borderRadius: "4px",
                          background: "#FFF",
                        }}
                      >
                        <span
                          className={`tracking-wide py-1 text-xs rounded-full text-black`}
                          style={{
                            backgroundColor:
                              customerData?.country_id?.color_code,
                          }}
                        >
                          {customerData?.country_id}
                        </span>

                        <span>
                          <i
                            className={`bi bi-chevron-${
                              isOpen === "country_id" ? "up" : "down"
                            }`}
                          ></i>
                        </span>
                      </button>

                      {isOpen === "country_id" && (
                        <div
                          ref={dropclickoutside}
                          id="dropdownRadioBgHover"
                          className={`w-full z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 absolute `}
                        >
                          <ul
                            className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 h-[210px] overflow-auto"
                            aria-labelledby="status"
                            style={{
                              borderRadius: "1px",
                              border: "0.4px solid #535252",
                              background: "#FFF",
                              boxShadow: "0px 0px 4px 0px #535252",
                            }}
                          >
                            {parsedData &&
                              parsedData?.map((item, index) => (
                                <li
                                  key={index}
                                  className="border-b border-DADADA"
                                  name="country_id"
                                  onClick={() => {
                                    handleOptionChange(item,'country_id');
                                  }}
                                >
                                  <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                    {item?.color_code && (
                                      <input
                                        id={`status-radio-${index}`}
                                        type="radio"
                                        value={item && item?.value}
                                        name="country_id"
                                        className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-grey-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                        checked={
                                          customerData?.country_id ===
                                          item?.value
                                        }
                                        onChange={() => {
                                          handleOptionChange(item,'country_id');
                                        }}
                                        style={{
                                          backgroundColor: item?.color_code,
                                          width: "25px",
                                          height: "25px",
                                          borderRadius: "5px",
                                          backgroundSize: "3rem",
                                        }}
                                      />
                                    )}

                                    <label
                                      htmlFor={`status-radio-${index}`}
                                      className="w-full ml-2 text-sm tracking-wide text-383838 rounded dark:text-gray-300"
                                    >
                                      {item && item?.value}
                                    </label>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={`gap-3 flex px-3 mb-3`}>
                  <div>
                    <label
                      htmlFor={`phone`}
                      className={`block mb-2 text-535252 text-14 font-500`}
                    >
                      Phone <span className="text-red-700">* </span>
                      {validationErrors && (
                        <span className="text-red-700">
                          {" "}
                          {validationErrors.phone}
                        </span>
                      )}
                    </label>
                    <input
                      type={`text`}
                      id={`phone`}
                      name="phone"
                      value={customerData?.phone || ""}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      className={`w-360px rounded border-BFBFBF text-xs focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                      placeholder={`Enter Phone No. `}
                      maxLength="16"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`email`}
                      className={`block mb-2 text-535252 text-14 font-500`}
                    >
                      Email <span className="text-red-700">* </span>
                      {validationErrors && (
                        <span className="text-red-700">
                          {" "}
                          {validationErrors.email}
                        </span>
                      )}
                    </label>
                    <input
                      type={`text`}
                      id={`email`}
                      name="email"
                      value={customerData?.email}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      className={`w-360px rounded border border-gray-300 text-gray-900 text-xs focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                      placeholder={`Enter Email `}
                    />
                    <div
                      id="email-error"
                      className="text-red-500 mt-1"
                      style={{ display: "none" }}
                    ></div>
                  </div>
                  <div>
                    <label
                      htmlFor={`website`}
                      className={`block mb-2 text-535252 text-14 font-500`}
                    >
                      Website
                    </label>
                    <input
                      type={`text`}
                      id={`website`}
                      name="website"
                      value={customerData?.website || ""}
                      onChange={handleInputChange}
                      className={`w-360px rounded-5px border-BFBFBF text-xs  focus:ring-1d4469 focus:border-1d4469 px-2.5 py-1`}
                      placeholder={`Enter Website`}
                    />
                  </div>                  
                </div>

                <div className={`flex justify-end pt-3 pe-2`}>
                  <Link
                    onClick={closeModal}
                    type={`button`}
                    className={`px-6 py-1 text-16  rounded-5px font-500 bg-F4F4F4 focus:outline-none me-2`}
                  >
                    CANCEL
                  </Link>
                  <button
                    type="submit"
                    className="px-6 py-1 text-16 rounded-5px font-500 bg-1D4469 text-white focus:outline-none cbtn-transition-bg cbtn-transition-bg"
                  >
                    UPDATE
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        <div class="rounded mb-3">
          <CustomerSocialLink leftTab={leftTab} />
        </div>
        <div class=" rounded mb-3">
          <CustomerAddress leftTab={leftTab} />
        </div>
        <div class="rounded">
          <CustomerContactPerson leftTab={leftTab} />
        </div>
      </div>
    </Fragment>
  );}
};

export default CustomerHome;
