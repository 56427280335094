import React, { useRef, Fragment, useEffect, useState } from "react";
import SubmitIcon from "../Icons/SubmitIcon";
import {saveActivity} from "../../utils/customer/customerTaskEventSlice";
import { useDispatch,useSelector } from "react-redux";
import useRequireAuth from "../../utils/useRequireAuth";
import { CUSTOMER_ACTIVITY_GET_EVENT } from "../../utils/constants";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import InputWithSubmit from "../GlobalFeature/Fields/InputWithSubmit";
const formatchangeDateformate = (dateString) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const parts = dateString.split("-");
  const day = parseInt(parts[0], 10);
  const monthIndex = parseInt(parts[1], 10) - 1;
  const year = parts[2].split(" ");
  const formattedDate = `${day} ${monthNames[monthIndex]} ${year[0].slice(
    -2
  )}  ${year[1]}`;
  return formattedDate;
};

const CustomerTaskActivity = ({ onFocus,taskid, taskCategory ,activity,onBlur,closeActivityModal,name}) => {
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const handleInputChange = (event) => {
    const { value } = event.target;
  };
  const [taskEvent,setTaskEvent]=useState([]);
  const activitys = useSelector(state => state.activity);
  const dispatch=useDispatch();
  const { token } = useRequireAuth();
  const url = `${CUSTOMER_ACTIVITY_GET_EVENT}?task_id=${taskid}`;
 useEffect(() => {
  const fetchData = async () => {
    try {

      if (activitys?.activity?.length === 0 || activitys?.activity?.data === '') {
        const data = await getCommonDataBasedOnId(url, token);
        setTaskEvent(data?.data);
      } else {
        setTaskEvent(activitys?.activity?.data);
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
    }
  };

  fetchData();
}, [url, token, taskid, activitys?.activity?.data]);

  const handleSaveTaskEvent = (event) => {
    event.preventDefault();
    const taskEvent = {
      description: event.target.description.value,
      task_id: taskid,
    };
    
    dispatch(saveActivity({ taskEvent, token }));
  };
  const[isActivityModalOpen, setActivityModalOpen] = useState(true);


  const closeActivityModals = () => {
    setActivityModalOpen(false)
    closeActivityModal();
  }
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Fragment>
   
      <div className="absolute bg-white top-[223px] right-[282px] rounded-10px px-4 py-3 shadow-2">
        <div className="flex justify-between">
          <div><h3>{name}</h3></div>
          <div className="">
          <button
            onClick={closeActivityModals}
            type="button"
            className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          </div>
        </div>
        
        <div className="flex flex-col max-h-[200px] overflow-y-scroll w-full">
          <p className="mb-3">{taskCategory}</p>
          {!taskEvent
            ? null
            : taskEvent.map((CategoryData) => (
                <div className="px-2 py-2 bg-F1F6F9 rounded-5px mb-3">
                  <div className="px-2 flex items-center gap-x-2">
                    <div>
                      <img className="w-8 h-8 me-2 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="user photo" /> 
                    </div>
                    <div className="flex flex-col">
                      <p className="text-2999BC font-medium text-xs">
                        {CategoryData.created_by}
                      </p>
                      <p className="text-F16136 font-medium text-xxs">
                        {formatchangeDateformate(CategoryData.created_at)}
                      </p>
                    </div>
                  </div>
                  <p className="text-xs text-justify text-535252 m-3">
                    {CategoryData.description}
                  </p>
                </div>
              ))}
        </div>
        <InputWithSubmit handleSaveEvent={handleSaveTaskEvent}  />
      </div>
   
    </Fragment>
  );
};
export default CustomerTaskActivity;