import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";

import { useFetchCompanyCommonDataQuery } from "../../utils/company/companySelector";
import FullScreenMode from "../GlobalFeature/Table/HeadTop/FullScreenMode";

import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import CustomerListTable from "../customer/CustomerListTable";
import CompanyAddressHeader from "./CompanyAddressHeader";
import AddUpdateCompanyAddress from "./AddUpdateCompanyAddress";

import { useUpdateCustomerAllAddressTableDataMutation } from "../../utils/customer/customerTaskTable";
import { useCustomerTableMutation } from "../../utils/customer/customerTable";
import { useFetchCompanyAddressDataQuery } from "../../utils/customer/customerSelect";
import { useFetchCompanyAddressTableDataQuery } from "../../utils/customer/customerTaskTable";


const EditCompanyAddress = () => {
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const { token } = useRequireAuth();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [refreshKey, setRefreshKey] = useState(0);
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const [customer, setCustomer] = useState([]);
  const [initialFilter, setInitialFilter] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [addressData, setAddressData] = useState();
  const [isOpenPoup,setIsOpenPoup]=useState(false);
  
  const { data: customerAddressData, refetch } = useFetchCompanyAddressDataQuery({ token, companyId:parsedCompanyData?.company_id});
  console.log("9999999999999999",customerAddressData);
  const { data: customerTableALL, refetch: refetchCustomerData } =
  useFetchCompanyAddressTableDataQuery({
      companyId:parsedCompanyData?.company_id,
      pagination: `${customerTablePagination}`,
      token: token,
    });


  const {
    data: companyCommnData,
    error,
    isLoading,
  } = useFetchCompanyCommonDataQuery(token);

  const openPopup = () => {
    setIsOpenPoup(true)
  };
  const closePopup = () => {
    setAddressData('');
    setIsOpenPoup(false)
    refetch();
  };

  useEffect(() => {
    if (customerTableALL && customerTableALL[0]?.id) {
      setIsSubmitting(false);
    }
  }, [customerTableALL, refetchCustomerData]);



  useEffect(() => {
    if (customerTableALL) {
      setCustomer(customerTableALL.data.data ?? "");
      const { data: omitData, ...restData } = customerTableALL.data;
      setInitialFilter(restData ?? "");
    }
  }, [customerTableALL, customerTablePagination]);

  const [
    updateCustomerAllAddressTableData,
    { CustomerDropLoad, CustomerDroperror },
  ] = useUpdateCustomerAllAddressTableDataMutation();

  const customerTableFieldUpdate = async (field_name, value, id) => {
    const updateData = {
      field_name: value?.field_name,
      value: field_name?.value,
      id: value?.id,
    };

    try {
      const result = await updateCustomerAllAddressTableData({
        token: token,
        data: updateData,
      }).unwrap();
      if (result) {
       // refetchCustomerAddress();
        {
          result?.data?.original
            ? setAlert({
                visible: true,
                message: result?.data?.original?.error,
                type: "danger",
              })
            : setAlert({
                visible: true,
                message: result?.message,
                type: "success",
              });
        }
      }
    } catch (error) {
      setAlert({
        visible: true,
        message: "Error updating data!",
        type: "danger",
      });
    }
  };

  const handelSaveData = () => {
    refetch();
   // refetchCustomerAddress();
  };

  const [
    CustomerTableDrop,
    { data: customerCommnData, refetch: refetchCustomerCommnData },
  ] = useCustomerTableMutation();

  const fetchCustomerTable = (data) => {
    const completedata = {
      model_name: "customer_teams",
      field_name: data,
    };
    CustomerTableDrop({ token: token, data: completedata });
  };
  const handleNavigate = (taskid) => {
    if (taskid) {
      setAddressData(taskid);
    }
  };

  

  return (
    <>
      <div className="tablemaindiv" key={refreshKey}>
        <div className={` h-full ${isFullscreen ? "mt-[-16px]" : "pt-2"} `}>
          {addressData && (
            <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
              <AddUpdateCompanyAddress
                isOpen={""}
                closePopup={closePopup}
                addressid={addressData}
                AddressData={customerAddressData}
                companyId={ parsedCompanyData?.company_id}
                refetchCustomerData={refetchCustomerData}
              />
            </div>
          )}
          {isOpenPoup && (
            <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
              <AddUpdateCompanyAddress
                isOpen={""}
                closePopup={closePopup}
                AddressData={customerAddressData}
                companyId={ parsedCompanyData?.company_id}
                refetchCustomerData={refetchCustomerData}
              />
            </div>
          )}
          {isFullscreen ? (
            <Fragment>
              <div className="flex justify-end mb-2">
                <FullScreenMode event={(e) => toggleFullscreen(e)}>
                  {isFullscreen ? (
                    <i className="bi bi-fullscreen-exit"></i>
                  ) : (
                    <i className="bi bi-fullscreen text-xl text-284356"></i>
                  )}
                </FullScreenMode>
              </div>
              <CustomerListTable
                tableDetail={{
                  tableidclass: "fullscreen-table",
                  tableparentidclass: "tableparent",
                  tablename: "Address",
                  tablenameclass: "font-medium text-16 text-203C50 capitalize",
                  tableSearch: true,
                  tableFilter: true,
                  tableResizer: true,
                  tableheader: false,
                  dataactivity: true,
                  datetedata: true,
                  forcefullyfreeze: true,
                  addbutton: (
                    <button
                      onClick={openPopup}
                      type="button"
                      className="text-2541558A hover:text-254155"
                    >
                      <i className="bi bi-plus-lg"></i>
                    </button>
                  ),
                }}
                thead={CompanyAddressHeader}
                tbody={customer}
                fetchtableBodyData={fetchCustomerTable}
                customerCommnData={customerCommnData}
                tablePaginationData={setCustomerTablePagination}
                TableFieldUpdate={customerTableFieldUpdate}
                initialFilter={initialFilter}
                navigateNameCondition={["address_type"]}
                handleNavigate={handleNavigate}
              />
            </Fragment>
          ) : (
            <Fragment>
              <div className="flex justify-between ">
                <h2 className="font-Vietnam text-20 font-600  text-203C50">
                  Address
                </h2>
                <div className="flex gap-8">
                  <div className="flex items-center gap-2">
                    <button
                      type="button"
                      className="text-2541558A hover:text-254155"
                      onClick={openPopup}
                    >
                      <i className="bi bi-plus-lg"></i>
                    </button>

                    <FullScreenMode event={(e) => toggleFullscreen(e)}>
                      {isFullscreen ? (
                        <i className="bi bi-fullscreen-exit"></i>
                      ) : (
                        <i className="bi bi-fullscreen text-xl text-284356"></i>
                      )}
                    </FullScreenMode>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          <div className="bg-white p-2">
            {customerAddressData?.length > 0 ? (
              customerAddressData.map((address, index) => (
                <div className={`grid grid-cols-4 gap-x-3 p-5  `}>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Address Type</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address?.address_type}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">Address</p>
                    <p className="text-sm font-normal text-2C495D">
                      {address.address}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">City</p>
                    <p className="text-sm font-normal text-2C495D">
                      {address.city}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">State</p>
                    <p className="text-sm font-normal text-2C495D">
                      {address.state}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Country</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.country_id}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Zip Code</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.zip_code}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">
                      Point of Contact
                    </p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.point_of_contact}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Primary Email</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.primary_email}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">
                      Secondary Email
                    </p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.secondry_email}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Phone1</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.phone1}
                    </p>
                  </div>

                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Phone2</p>
                    <p class="text-sm font-normal text-2C495D">
                      {address.phone2}
                    </p>
                  </div>

                </div>
              ))
            ) : (
              <Fragment>
                <div className={`grid grid-cols-3 gap-x-3  `}>
                <div className="mb-3">
                    <p class="text-sm font-light text-848687">Address Type</p>
                    <p class="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">Address</p>
                    <p className="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">City</p>
                    <p className="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-sm font-light text-848687">State</p>
                    <p className="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Country</p>
                    <p class="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Zip Code</p>
                    <p class="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">
                      Point of Contact
                    </p>
                    <p class="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Primary Email</p>
                    <p class="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">
                      Secondary Email
                    </p>
                    <p class="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Phone1</p>
                    <p class="text-sm font-normal text-2C495D">
                    </p>
                  </div>

                  <div className="mb-3">
                    <p class="text-sm font-light text-848687">Phone2</p>
                    <p class="text-sm font-normal text-2C495D">
                    </p>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCompanyAddress;
