import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  saveData,
  FileSaveData,
  getCommonDataBasedOnId,
  deleteData,
  updateArtifact
} from "../../components/store/customer/CustomerSocialAddUpdateFunction";
import {
  CUSTMOER_ARTIFACT_ADD_UPDATE,
  CUSTMOER_ARTIFACT_DATA_GET,
  CUSTOMER_ARTIFACT_DATA_DELETE,
  CUSTOMER_ARTIFACT_STATUS
} from "../constants";


export const saveTeamMemberDataAsync = createAsyncThunk(
  "teamMembers/saveTeamMemberData",
  async ({ socialData, token }, thunkAPI) => {
    try {
     
      const requestData =  [socialData] ;    
      const endpoint = (socialData.id === undefined || socialData.id === '') ? 
      CUSTMOER_ARTIFACT_ADD_UPDATE : CUSTOMER_ARTIFACT_STATUS;    
      const response = await ((socialData.id === undefined || socialData.id === '') ? 
      FileSaveData : updateArtifact)(
        endpoint,
        requestData,
        token
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const fetchCustomerArtifactData = createAsyncThunk(
  "customerArtifact/fetchCustomerArtifactData",
  async ({ customerId, token }, thunkAPI) => {
    try {
      const url = `${CUSTMOER_ARTIFACT_DATA_GET}${customerId}`;
      const getData = await getCommonDataBasedOnId(url, token);

      const contactResponse = getData?.data?.data;
      
      if (Array.isArray(contactResponse) && contactResponse.length > 0) {
      
        const processedData = contactResponse.map((member) => ({
          ...member,
          isChecked: member.status? true : false
        }));
        thunkAPI.dispatch(setCustomerArtifact(processedData));
        return processedData;
      }else {
       
        return thunkAPI.rejectWithValue('Data is not an array or is empty');
      }
      
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteTeamMemberAsync = createAsyncThunk(
  "customerArtifact/deleteTeamMember",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const url =`${CUSTOMER_ARTIFACT_DATA_DELETE}${id}`;
      const response = await deleteData(url,token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const artifactSlice = createSlice({
  name: "customerArtifact",
  initialState: {
    pointerStyle: 0,
    message: null,
    showMessage: false,
    teamMembers: [
      {
        docType: "",
        description: "",
        docFile: "",
        isChecked: true,
      },
    ],
    textareaIndex: null,
    textareaHeight: "auto",
    status: "idle",
    error: null,
  },
  reducers: {
    addTeamMember: (state) => {
      if (state.pointerStyle !== 0) {
        state.teamMembers.unshift({
          docType: "",
          description: "",
          docFile: "",
          isChecked: true,

        });
        state.pointerStyle = 0;
      }
    },
    inputChange: (state, action) => {
      const { index, name, value } = action.payload;
      state.teamMembers[index][name] = value;
    },
    save: (state) => {
      state.pointerStyle = 1;
    },
    addTeamCheck: (state, action) => {
      const { indexno, isChecked } = action.payload;
      state.teamMembers[indexno].isChecked = isChecked;
    },
    setTextAreaIndex: (state, action) => {
      state.textareaIndex = action.payload;
    },
    setTextAreaHeight: (state, action) => {
      state.textareaHeight = action.payload;
    },
    setCustomerArtifact: (state, action) => {
      const processedData = action.payload.map((member) => ({
        ...member,
        isChecked: member.status === "1" ? true : false
      }));
      state.teamMembers = processedData;
      state.pointerStyle = 1;
    },
   
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    deleteTeamMember: (state, action) => {
      state.teamMembers.splice(action.payload, 1);
    },
    removeArtifactAtIndex: (state, action) => {
      const indexToRemove = action.payload;
      state.teamMembers = state.teamMembers.filter(
        (_, index) => index !== indexToRemove
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveTeamMemberDataAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveTeamMemberDataAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.showMessage = true;
        state.pointerStyle=1;
      })
      .addCase(saveTeamMemberDataAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      })
      .addCase(fetchCustomerArtifactData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCustomerArtifactData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teamMembers = action.payload;
        state.pointerStyle = 1;
      })
      .addCase(fetchCustomerArtifactData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteTeamMemberAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTeamMemberAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teamMembers = state.teamMembers.filter(
          (member) => member.id !== action.payload.id
        );
        state.message = action.payload.message;
        state.showMessage = false;
      })
      .addCase(deleteTeamMemberAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = true;
      });
  },
});

export const {
  removeArtifactAtIndex,
  addTeamMember,
  inputChange,
  save,
  addTeamCheck,
  setTextAreaIndex,
  setTextAreaHeight,
  setCustomerArtifact,
  showMessage,
  hideMessage,
  deleteTeamMember,
} = artifactSlice.actions;

export default artifactSlice.reducer;
