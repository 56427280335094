import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CreatePortalHOC from '../Fields/CreatePortalHOC';

const MuiLoader = ({isOpen}) => {
  return (
    <CreatePortalHOC isOpen={isOpen} dialog>
        <Box sx={{ display: 'flex',width:"100%",justifyContent:"center",alignItems:"center" }}>
            <CircularProgress />
        </Box>
    </CreatePortalHOC>
  )
}

export default MuiLoader