import React from 'react'

const FilterSearchTh = ({tableThSearch,setTableThSearch,tableSearchInput,searchTerms}) => {

   const headerterm = Object.keys(searchTerms).length > 0;

    const filterSearchTh = () => {
        setTableThSearch(!tableThSearch);
      };
  return (
    <div>
        <button type="button" onClick={filterSearchTh} className={`tooltip relative hover:text-254155  ${tableThSearch ? 'text-254155 border border-253E50 px-1 rounded' : 'text-2541558A'} `}>
           <i className="bi bi-funnel tableheaderfiltericon  "></i>
           {(headerterm || tableSearchInput) && <i className="bi bi-x absolute bottom-0 left-[13px] text-DF4321 text-[10px]"></i>}
           <span className={`tooltiptext text-[10px] w-[50px] bottom-full left-1/2 ml-[-25px]`}>Filter</span>

        </button>
    </div>
  )
}

export default FilterSearchTh