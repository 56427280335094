import React, { useState, useEffect } from "react";
import LayoutHOC from "../LayoutHOC";
import { Link, useLocation } from "react-router-dom";
import VendorHome from "./VendorHome";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchVendorEditData } from "../../utils/vendor/vendorTeamSlice";
import useRequireAuth from "../../utils/useRequireAuth";

const VendorDashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id: vendorId } = useParams();
  const { token } = useRequireAuth();
  const queryParams = new URLSearchParams(location.search);
  const lefttabParam = parseInt(queryParams.get("LeftTab")) || 0;
  const companyLocalData = localStorage.getItem("company_data");
  let parsedCompanyData = [];

  try {
    parsedCompanyData = JSON.parse(companyLocalData) || [];
  } catch (error) {
    console.error("Error parsing company data:", error);
  }

  const [state, setState] = useState({
    openTab: lefttabParam,
    isFixed: false,
    vendorData: parsedCompanyData,
  });

  const handleScroll = () => {
    setState((prevState) => ({
      ...prevState,
      isFixed: window.scrollY > 120,
    }));
  };
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
   
    const fetchData = async () => {
      try {
        const resultAction = await dispatch(
          fetchVendorEditData({ vendorId, token })
        );
       
        const { payload } = resultAction;
      
        setState((prevState) => ({
          ...prevState,
          vendorData: payload?.data,
        }));
      } catch (error) {
        console.error("Error fetching vendor edit data:", error);
      }
    };

    fetchData();
  }, [vendorId, dispatch, token]);

  const { openTab, isFixed, vendorData } = state;

 
  

  return (
    <div className={`p-4 pb-0`}>
      <div className={`flex flex-col p-2`}>
        <div className={`flex justify-between items-center px-2`}>
          <div className="flex">
            <h5
              className={`text-203C50 font-Vietnam text-[24px] font-medium capitalize`}
            >
             {vendorData?.vendor_name}
            </h5>
          </div>

          <div className={`flex items-center`}>
            <label className={`inline-flex items-center cursor-pointer`}>
              <input type={`checkbox`} value={``} className={`sr-only peer`} />
              <div
                className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
              ></div>
            </label>
          </div>
        </div>
      </div>
      <VendorHome vendorData={vendorData}/>
    </div>
  );
};

export default LayoutHOC(VendorDashboard);
