import React, { } from 'react';
const CustomerTaskHoverDescription = ({description}) => {

      if(description === null || description === ''){
             return null;
      }
      return (
            <div className="absolute  bg-white shadow-9 rounded-10px px-3 py-4 w-full" id="thd" style={{ width: '125px', zIndex: 1 }}>
                  <div className="flex flex-col">
                        <p className='text-sm text-2C495D mb-2'>Description</p>
                        <div className="px-3 py-2 bg-F1F6F9 rounded-5px">
                              <p className="text-xs text-justify text-535252">{description??''}</p>
                        </div>
                  </div>
            </div>
      );
};
export default CustomerTaskHoverDescription;
