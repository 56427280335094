import React, { Fragment,useState,useEffect } from 'react'
import { useFetchPermissionModuleQuery, useFetchSelectPermissionQuery, useLazyFetchPermissionModuleQuery, usePermissionsAddUpdateMutation } from '../../utils/users/permissions';
import useRequireAuth from '../../utils/useRequireAuth';
import Skeleton from 'react-loading-skeleton';
import useCustomFormValidation from '../../hooks/ErrorHandling/useCustomFormValidation';
import PermissionToggleButton from './PermissionToggleButton';
import FirstMessage from '../GlobalFeature/MessageAlert/FirstMessage';



const validationRules = {
  name: {
    required: true,
    errorMessage: 'required',
  },

};


const PermissionLevel = ({TableNav,ButtonEvent,PermissionLevelId}) => {



    const {token}=useRequireAuth();
    const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  


  const [isYes, setIsYes] = useState([]);

    const [activeTab, setActiveTab] = useState(PermissionLevelId?.permission?.value || ''); // State to manage the active tab


    const [formData,setFormData] = useState({
       id:  PermissionLevelId?.permission_id?.value,
       name: PermissionLevelId?.permission?.value,
       description: PermissionLevelId?.permission_description?.value,
    })
    const [issue, setIssue] = useState("");


  //   const {
  //     data: permissionModule,
  //     refetch: refetchPermissionModule,
  //     PermisisonModulError,
  //     PermisisonModuLoading,
  // } = useFetchPermissionModuleQuery({token,id:formData?.id}, { skip: !formData.id });

  const [fetchPermissionModule, { 
    data: permissionModule, 
    isError: permissionModuleError, 
    isLoading: permissionModuleLoading 
  }] = useLazyFetchPermissionModuleQuery();

  useEffect(() => {
    if (formData.id) {
      fetchPermissionModule({ token, id: formData.id });
    }
  }, [formData.id, fetchPermissionModule, token]);


  //   useEffect(() => {
  //     if (permissionModule?.data) {
  //       setIsYes(permissionModule.data.map(() => ({ isModuleYes: false, subModules: [] })));
  //     }
  // }, [permissionModule]);
  useEffect(() => {
    if (permissionModule?.data) {
      setIsYes(permissionModule?.data?.map(module => ({
        isModuleYes: false,
        subModules: module?.sub_modules?.map((subModule) => subModule?.checked),
      })));
    }
  }, [permissionModule]);
  


    const { values, errors, touched, handleChange, handleBlur, validateField, isFormValid } = useCustomFormValidation(
      formData,
      validationRules
    );

    const {
        data: permissionDropData,
        refetch: refetchPermissionDropData,
        error,
        permissionLoading,
    } = useFetchSelectPermissionQuery(token);





    const handleTabChange = (tab) => {
        setActiveTab(tab?.permission_level);
        setFormData({
          id: tab?.id || '',
          name: tab?.permission_level || '',
          description: tab?.description || ''
        });
        // if (tab?.id) {
        //   refetchPermissionModule();
        // }
    };


    const toggleState = (moduleIndex, subModuleIndex, value) => {
      setIsYes(prevState => {
        const newState = [...prevState];
        if (subModuleIndex === null) {
          newState[moduleIndex].isModuleYes = value;
        } else {
          newState[moduleIndex].subModules[subModuleIndex] = value;
        }
        return newState;
      });
    };
  

    const FieldChange = (e) => {
      handleChange(e);
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }

    const AddPermission = () => {
      setActiveTab('')
      setFormData({
        id: '',
        name: '',
        description: ''
      })
        fetchPermissionModule({token});

    }


    const [permissionAddUpdate, {permissionAddUpdatLoad, permissionAddUpdaterror }] =
    usePermissionsAddUpdateMutation();

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const formValid = isFormValid();
  
      if (!formValid) {
        return;
      }
      const permissionData = [];

      isYes.forEach((module, moduleIndex) => {
    
        module.subModules.forEach((subModuleYes, subModuleIndex) => {
          permissionData.push({
            id: permissionModule.data[moduleIndex].sub_modules[subModuleIndex].id,
            checkbox: subModuleYes ? 1 : 0
          });
        });
      });
      const dataToSend = {
        ...formData,
        permissions: permissionData
      };
  
      try {

        const response = await permissionAddUpdate({  token, data: dataToSend });
       
      
        if (response?.data?.status) {
          setAlert({
            visible: true,
            message: response?.data?.message,
            type: "success",
          });
          setIssue("")
               fetchPermissionModule({ token, id: formData.id });

             refetchPermissionDropData();
        } 
        else{
          setAlert({
            visible: true,
            message: response?.error?.data?.message,
            type: "danger",
          });
          setIssue({name: response?.error?.data?.message})
        }
      } catch (err) {
        console.error("Error while adding/updating:", err);
      }
    };



    
    const hideAlert = () => {
      setAlert((prevState)=>({ ...prevState,visible: false }));
    };

    
  return (
    <Fragment>
      <FirstMessage
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onHide={hideAlert}
      />
    {TableNav()}
      <form onSubmit={handleSubmit}>                                  
        <div className='flex justify-between'>
          <button
              type='button'
              className='text-1D4469 font-medium text-16 mb-1 hover:text-0891b2'
              onClick={ButtonEvent}
          >
              <i className="bi bi-arrow-left"></i> Permission Level
          </button>

          <div className='mb-1'>
              <button type='button' onClick={ButtonEvent} className='border border-EAE2E2 bg-F4F4F4 text-535252 font-500 rounded-[5px] px-5 py-1 mr-3 hover:border-123251'>
                      CANCEL
              </button>
              <button type='submit' className='border border-123251 bg-1D4469 text-white font-500 rounded-[5px] px-5 py-1'>
                      {formData?.id ? 'Update'  : 'SAVE' }
              </button>
          </div>                                        
      </div>
      <div className='flex h-[580px]'>
          <div className='bg-white p-3 w-[222px]'>
            <button type='button' className='w-[198px] bg-DDF7FF text-2999BC rounded py-2 flex ps-4 text-14 font-600' onClick={ () => { AddPermission() } } >+ Add Permission</button>
            <div>
                  {permissionLoading ? (
                          <Skeleton count={5} height={35} />
                  ) : (
                    permissionDropData?.data?.map(permission => (
                          // <div key={permission.id} className='mt-2 ps-4 text-2C495D text-14'>
                          //     {permission.permission_level}
                          // </div>
                          <button
                          type='button'
                          key={permission.id}
                          onClick={() => handleTabChange(permission)}
                          className={`w-full flex mt-2 py-2 px-4 text-sm font-medium transition-colors duration-200 ease-in-out ${
                            activeTab === permission.permission_level
                              ? 'rounded bg-F3F3F3'
                              : 'text-2C495D hover:bg-F3F3F3'
                          }`}
                        >
                          {permission.permission_level}
                        </button>
                      ))
                  )}
              </div>
            </div>
            <div className='bg-white w-full ms-4'>
              <div className='grid gap-5 p-6 pb-3 border-b border-E1E6EC'>
              
              {issue.name && <span className="text-red-700 text-12">{issue.name}</span>}

                <div>
                  <label htmlFor='name' className='w-[15%] inline-block text-434852 text-14 font-500'>
                  Name
                        <span className=" text-red-500"> *</span>
                      
                        {errors.name && <span className="text-red-700 text-12">{errors.name}</span>}
                  </label>
                  <input id="name" name='name' value={formData?.name} disabled={formData?.id} onChange={FieldChange} type='text' className='bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs w-[430px] h-[35px] disabled:opacity-80'/>
                </div>
                <div>
                  <label htmlFor='description' className='w-[15%] inline-block text-434852 text-14 font-500'>Description</label>
                  <input id="description" name='description' value={formData?.description} onChange={FieldChange}   type='text' className='bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-900 text-xs w-[430px] h-[35px]'/>
                </div>
              </div>




                      {/* <div className='p-6 pt-3'>
                              <div className='flex justify-between'>
                                    <span>Menu</span>
                                      <div className={`flex w-[140px] ${isYes ? 'bg-white border border-D6D6D6 rounded-[50px]' : 'bg-F0F0F0 border border-D6D6D6 rounded-[50px]' }`}>
                                          <button
                                              type="button"
                                              className={`w-[73px] text-12 font-500 transition-colors duration-300 ${isYes ? 'bg-2999BC border border-4CC1E5 text-white rounded-[50px]' : 'text-383838'}`}
                                              onClick={() => setIsYes(true)}
                                          >
                                              Yes
                                          </button>
                                          <button
                                              type="button"
                                              className={`w-[73px] text-12 font-500 transition-colors duration-300 ${isYes ? 'text-383838 ' : 'bg-444 border border-565656 text-white rounded-[50px]'}`}
                                              onClick={() => setIsYes(false)}
                                          >
                                              No
                                          </button>
                                      </div>
                              </div>

                            
                      </div> */}
                                <div className='p-6 pt-3 h-[445px] overflow-auto'>
                                  <div className='grid gap-3'>

                                       {permissionModule && permissionModule?.data && permissionModule?.data?.map((module, moduleIndex) => (

                                          <div key={moduleIndex} className=''>
                                                <div className='flex justify-between mb-2'>
                                                      <span className='text-14 font-500 text-383838'>{module?.module}</span>
                                                      {/* <PermissionToggleButton isYes={isYes[moduleIndex]?.isModuleYes} toggleState={(value) => toggleState(moduleIndex, null, value)} /> */}
                                                      
                                                    
                                                </div>
                                                <div className='bg-F5F5F5 pt-[20px] pb-[11px] pl-[40px]'>
                                                      {module?.sub_modules?.map((subModule, subModuleIndex) => (
                                                                  <div key={subModuleIndex}  className={`flex justify-between mb-2 ${subModuleIndex !== module?.sub_modules?.length - 1 ? 'border-b border-E1E6EC pb-2' : ''}`}>
                                                                        <span className='text-13 font-500 text-1D4469'>{subModule?.sub_module}</span>
                                                                        <PermissionToggleButton isYes={isYes[moduleIndex]?.subModules[subModuleIndex]} toggleState={(value) => toggleState(moduleIndex, subModuleIndex, value)} />

                                                                  </div>
                                                      ))} 
                                                </div>
                                                      
                                          </div>
                                               
       
                                       ))}
                                  </div>

                                   
                             </div>

                             
            </div>




        </div>


    </form>

    </Fragment>
  )
}

export default PermissionLevel