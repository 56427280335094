import ReportingTeamModal from "./ReportingTeamModal";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteReportingTeamMemberAsync,
  hideMessage,
} from "../../utils/customer/reportingTeamSlice";
import useRequireAuth from "../../utils/useRequireAuth";
import { useParams } from "react-router-dom";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import CustomerListTable from "./CustomerListTable";
import AddTeamTableHead from "./AddTeamTableHead";
import { saveReportTeamMemberDataAsync } from "../../utils/customer/reportingTeamSlice";
import { useFetchCustomerReportingDataQuery } from "../../utils/customer/customerTaskTable";
import { useUpdateCustomerReportingDataMutation } from "../../utils/customer/customerTaskTable";
import { useCustomerTableMutation } from "../../utils/customer/customerTable";
import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";

const CustomerReportingTeam = ({leftTab}) => {
 
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.customerReportingTeam.message);
  const [initialFilter, setInitialFilter] = useState([]);
  const [customer, setCustomer] = useState([]);

  const [customerTablePagination, setCustomerTablePagination] = useState("");

  const [updateCustomerReportingData, { CustomerDropLoad, CustomerDroperror }] =
    useUpdateCustomerReportingDataMutation();
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });

  const showMessage = useSelector(
    (state) => state.customerReportingTeam.showMessage
  );
  const [loading, setLoading] = useState(false);
  const handleStatusUpdate = async (rowKey, item, itemId, status) => {
    const socialData = {
      id: itemId,
      status: status ? 1 : 0,
      customer_id: customerId,
    };
    try {
      await dispatch(saveReportTeamMemberDataAsync({ socialData, token }));
      refetchCustomerData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSubmitData = async (customerId) => {
    refetchCustomerData();
  };

  const [
    CustomerTableDrop,
    { data: customerCommnData, refetch: refetchCustomerCommnData },
  ] = useCustomerTableMutation();

  const fetchCustomerTable = (data) => {
    const completedata = {
      model_name: "customer_teams",
      field_name: data,
    };
    CustomerTableDrop({ token: token, data: completedata });
  };

  const { data: customerTableALL, refetch: refetchCustomerData } = useFetchCustomerReportingDataQuery(
    {
      customerId,
      pagination: customerTablePagination,
      token,
    },
    {
      skip: leftTab !== 4,
    }
  );


  useEffect(() => {
    if (customerTableALL && leftTab===4) {
      setCustomer(customerTableALL?.data?.data);
      const { data: omitData, ...restData } = customerTableALL?.data;

      setInitialFilter(restData);
      setLoading(false);
    }
  }, [customerTableALL, customerTablePagination]);

  const [fetchPersonInTable, { data: personTableData }] =
    useLazyFetchPersonInTableQuery();

  const fetchPersonData = (data) => {
    fetchPersonInTable({ token: token, data: data });
  };

  const customerTableFieldUpdate = async (field_name, value, id) => {
    let fieldValue;
    if (value.field_name === "reporting_person_email") {
      fieldValue = field_name.email;
    } else if (value.field_name === "reporting_person_name") {
      fieldValue = field_name.name;
    } else {
      fieldValue = field_name.value;
    }

    const updateData = {
      field_name: value.field_name,
      value: fieldValue,
      id: value.id,
    };

    try {
      const result = await updateCustomerReportingData({
        token: token,
        data: updateData,
      }).unwrap();
      if (result) {
        refetchCustomerData();
        {
          result?.data?.original
            ? setAlert({
                visible: true,
                message: result?.data?.original?.error,
                type: "danger",
              })
            : setAlert({
                visible: true,
                message: result?.message,
                type: "success",
              });
        }
      }
    } catch (error) {
      setAlert({
        visible: true,
        message: "Error updating data!",
        type: "danger",
      });
    }
  };

  const handleDelete = async (rowKey, item) => {
    const id = item?.id?.value;
    try {
      await dispatch(deleteReportingTeamMemberAsync({ id, token }));
      refetchCustomerData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  setTimeout(() => {
    dispatch(hideMessage());
  }, 3000);

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  return (
    <div className="tablemaindiv">
      <div className={` ${isFullscreen ? "bg-white h-full" : ""}`}>
        {/* <div className="flex items-center gap-2">
          <div className="tablemaindiv customerTaskTable py-1">
            {isPopupOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-[102]">
                <ReportingTeamModal
                  isOpen={isPopupOpen}
                  onClose={closePopup}
                  handleSubmitData={handleSubmitData}
                />
              </div>
            )}
          </div>
        </div> */}

        {showMessage && <p className="text-green-500 text-right	">{message}</p>}

        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />

        <CustomerListTable
          tableDetail={{
            tableidclass: "fullscreen-table",
            tableparentidclass: "tableparent",
            tablename: "Reporting Team",
            tablenameclass: "font-medium text-537596 capitalize",
            tableSearch: true,
            tableFilter: true,
            tableResizer: true,
            tableZoom: true,
            tableheader: false,
            statusUpdate: true,
            datetedata: true,
            downloaddata: false,
            addbutton: (
              <button
                onClick={openPopup}
                type="button"
                className="text-2541558A hover:text-254155"
              >
                <i className="bi bi-plus-lg"></i>
              </button>
            ),
          }}
          thead={AddTeamTableHead}
          tbody={customer}
          tablePaginationData={setCustomerTablePagination}
          fetchtableBodyData={fetchCustomerTable}
          customerCommnData={customerCommnData || personTableData}
          fetchPersonInTable={fetchPersonData}
          initialFilter={initialFilter}
          TableFieldUpdate={customerTableFieldUpdate}
          handleDelete={handleDelete}
          handleStatusUpdate={handleStatusUpdate}
          navigateNameCondition={["file_name"]}
        />
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-[102]">
            <ReportingTeamModal
              isOpen={isPopupOpen}
              onClose={closePopup}
              handleSubmitData={handleSubmitData}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default CustomerReportingTeam;
