import React,{useState} from "react"
import ReactDOm from "react-dom"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Tabs from "./Tabs"
import CustomerTable from "./CustomerTable";


const SubNav = () => {
    return (
        <div>
        
               <Tabs/>
               <CustomerTable/>
                            </div>
    )
}


export default SubNav






// App.js
// import React from 'react';
// import Header from './Header';
// import Leftmenu from './Leftmenu';
// import Footer from './Footer';
// import Tabs from './Tabs';
// import CustomerTable from './CustomerTable';

// const App = () => {
//   return (
//     <div>
//       <div>
//         <Leftmenu />
//         <Tabs />
//         <CustomerTable />
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default App;