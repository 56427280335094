import { useMemo } from 'react';

function useDateWithDay(date) {
  return useMemo(() => {
    if (!(date instanceof Date)) {
      return 'Invalid date';
    }

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const weekday = days[date.getDay()];

    return `${weekday}, ${day} ${month}, ${year}`;
  }, [date]);
}

export default useDateWithDay;
