

import { useState,useEffect } from 'react';

const useCustomFormValidation = (initialValues, validationRules) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Determine if the new value needs to be wrapped in an object
    const isCurrentValueObject = values? typeof values[name] === 'object':'';
    const newValue = isCurrentValueObject ? { value } : value;
    
    setValues({ ...values, [name]: newValue });
    setTouched({ ...touched, [name]: true });
    validateField(name, newValue);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    
    const isCurrentValueObject = typeof values[name] === 'object';
    const newValue = isCurrentValueObject ? { value } : value;

    setTouched({ ...touched, [name]: true });
    validateField(name, newValue);
  };

  const validateField = (name, value) => {
    const rule = validationRules[name];
   
    const actualValue = typeof value === 'object' && value !== null ? value.value : value;

    if (rule) {
      let fieldErrors = [];
      if (Array.isArray(rule)) {
        fieldErrors = rule.map((ruleItem) => validateRule(ruleItem, actualValue));
      } else {
        fieldErrors.push(validateRule(rule, actualValue));
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors.find((error) => error !== undefined) || undefined,
      }));
    }
  };


  const validateRule = (rule, value) => {
    
    const actualValue = typeof value === 'object' && value !== null ? value.value : value;

   
    if (rule.required && (actualValue?.trim() === '' || actualValue?.trim() === null)) {
      return rule.errorMessage || 'This field is required.';
    }

    if (rule.email && actualValue && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(actualValue)) {
      return rule.errorMessage || 'Invalid email format.';
    }
    if (rule.number && !/^\d+$/.test(actualValue)) {
      return rule.errorMessage || 'Must be a number.';
    }
    
    if (rule.domain) {
        const domainRegex = /\.(com|net|org|edu|gov|biz|info|co|io|us|uk|in|au|ca|de|fr|jp|kr|ru|cn|br|mx|es|nl|ch|se|no|fi|dk|it|be|at)$/i;
        if (actualValue && !domainRegex?.test(actualValue)) {
          return rule.errorMessage || 'Provide valid website URL.';
        }
    }
    
    if (rule.maxDigits && actualValue?.length > rule?.maxDigits) {
        return rule.errorMessage || `Must be between 1 to ${rule?.maxDigits} digits.`;
    }
  
    if (rule.minDigits && actualValue?.length < rule?.minDigits) {
        return rule.errorMessage || `Must be at least ${rule?.minDigits} digits.`;
    }

    if (rule.validationFn && !rule.validationFn(actualValue)) {
      return rule.errorMessage || 'Invalid value.';
    }

    return undefined; 
  };

   const validateForm = () => {
    const newErrors = {};
    Object.keys(validationRules).forEach((name) => {
      const rule = validationRules[name];
      const value = values[name];

      let fieldErrors = [];

      const actualValue = typeof value === 'object' && value !== null ? value?.value : value;

      if (Array.isArray(rule)) {
        fieldErrors = rule.map((ruleItem) => validateRule(ruleItem, actualValue));
      } else {
        fieldErrors.push(validateRule(rule, actualValue));
      }

      newErrors[name] = fieldErrors.find((error) => error !== undefined) || undefined;
    });

    setErrors(newErrors);
    return newErrors;
  };

  const isFormValid = () => {
    const formValidation = validateForm();
    return Object.keys(formValidation).every((key) => formValidation[key] === undefined);
  };

  return {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    validateField,
    isFormValid,
  };
};

export default useCustomFormValidation;
