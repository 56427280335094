import React, { Fragment,useState } from 'react'
import ClientInfoCard from './ClientInfoCard'
import ClientServiceCard from './ClientServiceCard'
import { ClientBillingCard } from './ClientBillingCard'
import ClientDocument from './ClientDocument'
import ClientReportingTeam from './ClientReportingTeam'


const LeftCard = ({handleTaskExpand,handleClickTab}) => {

    const [isOpen, setIsOpen] = useState(true);


    const handleToggle = () => { setIsOpen(!isOpen); };
    const [leftDrop, setLeftDrop] = useState([]);

    const handleLeftDrop = (index) => {
      if (leftDrop.includes(index)) {
        setLeftDrop(leftDrop.filter((i) => i !== index));
      } else {
        setLeftDrop([...leftDrop, index]);
      }
    };
    const handleRouteSection=async (index)=>{
      await handleTaskExpand()
      await handleClickTab(index);
    }

  return (
    <Fragment>
        <ClientInfoCard isOpen={leftDrop.includes(0)} onToggle={() => handleLeftDrop(0)} handleRouteSection={()=>handleRouteSection(0)}/>
        <ClientServiceCard isOpen={leftDrop.includes(1)} onToggle={() => handleLeftDrop(1)} handleRouteSection={()=>handleRouteSection(1)}/>
        <ClientBillingCard isOpen={leftDrop.includes(2)} onToggle={() => handleLeftDrop(2)} handleRouteSection={()=>handleRouteSection(2)}/>
        <ClientDocument isOpen={leftDrop.includes(3)} onToggle={() => handleLeftDrop(3)} handleRouteSection={()=>handleRouteSection(3)}/>
        <ClientReportingTeam isOpen={leftDrop.includes(4)} onToggle={() => handleLeftDrop(4)} handleRouteSection={()=>handleRouteSection(4)}/>
    </Fragment>
 
  )
}

export default LeftCard