import React, { useState, useRef, useEffect ,Fragment } from "react";
import ViewNotesData from "./ViewNotesData";
import NotesEditor from "../TextEditor/NotesEditor";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";

import AllNotesShow from "./AllNotesShow";
import {
  useFetchTaskDataQuery,
  useFetchCustomerNotesColorDataQuery,
} from "../../utils/customer/customerSelect";
import { useParams } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import { saveNotes } from "../../utils/customer/customerNotesSlice";
import { useDispatch } from "react-redux";
import { CUSTMOER_NOTES_ATTACHMENT_DATA_GET } from "../../utils/constants";
import { deleteData } from "../store/customer/CustomerSocialAddUpdateFunction";



const AddNotes = ({taskOpenTab}) => {
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });

  const [refreshKey, setRefreshKey] = useState(0);
  const [viewNotes, setViewNotes] = useState({
    allnotes: false,
    addnotes: false,
  });
  const companyLocalData = JSON.parse(localStorage.getItem("company_data"));
  const [filterData, setFilterData] = useState(null);
  const [notetborder, setNoteTborder] = useState(false);
  const [formData, setFormData] = useState({
    notes_title: "",
    notes_category_id: "",
    colour_code: "",
  });
  const [color, setColor] = useState(null);
  const [disable, setDisable] = useState(true);
  const containerRef = useRef(null);
  const [page, setPage] = useState(0);

  const handleNotesClick = () => {
    setViewNotes({ allnotes: true, addnotes: true });
    setDisable(false);
  };

  const hndleCloseNotes = () => {
    setViewNotes({ allnotes: false, addnotes: false });
    setRefreshKey((prevKey) => prevKey + 1);
    setDisable(true);
    setSelectedFiles([]);
  };

  const handleFileUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.click();
    input.addEventListener("change", (event) => {
      const files = Array.from(event.target.files);
      let updatedFiles = selectedFiles.slice();

      if (selectedFiles && selectedFiles.length > 0) {
        const lastFile = selectedFiles[selectedFiles.length - 1];
        const lastId = lastFile.id;
        files.forEach((file, index) => {
          updatedFiles.push({
            id: lastId + index + 1,
            attachment: file.name,
            file: file,
          });
        });
      } else {
        files.forEach((file, index) => {
          updatedFiles.push({
            id: index + 1,
            attachment: file.name,
            file: file,
          });
        });
      }

      setSelectedFiles(updatedFiles);
    });

    return false;
  };

  const getFileLastName = (filePath) => {
    if (typeof filePath === "string" && filePath.includes("/")) {
      return filePath.split("/").pop();
    } else {
      return filePath;
    }
  };

  const { data: customerTaskCategory } = useFetchTaskDataQuery({
    token,
    jsonData: {
      customer_id: customerId,
      company_id: companyLocalData.company_id,
    },
  });

  const { data: customerColor } = useFetchCustomerNotesColorDataQuery({
    token,
  });

  const handleInputChange = ({ target: { name, value } }) =>
    setFormData({ ...formData, [name]: value });
  const handleSetColour = (colour) => setColor(colour);

  const handleSubmit = (e) => {
    e.preventDefault();
    const notesData = {
      notes_category_id: formData.notes_category_id || 1,
      colour_code: color ?? "#D9D9D9",
      notes_title: formData.notes_title,
      company_id: companyLocalData.company_id,
      customer_id: customerId,
      notes_body: textareaRef.current.innerHTML,
      rag_status: "wip",
      attachment: selectedFiles,
    };

    if (textareaRef.current.innerHTML !== "" && formData.notes_title!=='') {
      dispatch(saveNotes({ notesData, token }));
      setViewNotes({ allnotes: false, addnotes: false });
      setFormData({ notes_title: "", notes_category_id: "", colour_code: "" });
      setDisable(true);
      setRefreshKey((prevKey) => prevKey + 1);
      setSelectedFiles([]);
    }else{
      setAlert({
        visible: true,
        message: "Notes title and notes body cannot be blank.",
        type: "danger",
      })
      setNoteTborder(true)
    }
  };

  const handleFilterData = (value) => setFilterData(value);

  const resetFile = (newSelectedFiles) =>
    setSelectedFiles(newSelectedFiles ?? []);

  const handleDeleteFileServer = async (id, editNoteId) => {
    const url = `${CUSTMOER_NOTES_ATTACHMENT_DATA_GET}${id}`;
    try {
      const newSelectedFiles = selectedFiles.filter((f) => f.id !== id);
      resetFile(newSelectedFiles);
      const response = await deleteData(url, token);
      if (response.status) {
        setViewNotes({ allnotes: true, addnotes: true });
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleClickDeleteFile = (id) => {
    if (id >= 0 && id < selectedFiles.length) {
      resetFile(selectedFiles.filter((file, idx) => idx !== id));
    }
  };

  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };
  

  return (
    <Fragment>
    <FirstMessage
    type={alert.type}
    message={alert.message}
    visible={alert.visible}
    onHide={hideAlert}
  />
    <div className="bg-white px-4">
      <div className="flex items-center justify-between py-1">
        <h4 className="font-medium text-537596 capitalize">Notes</h4>
        <div className="flex justify-between gap-x-3.5">
          {/* <NotesDropdown handleFilterData={handleFilterData} /> */}
          <button
            className="py-2 px-3 border border-E1E1E1 text-2999BC text-sm font-500 rounded-5px"
            onClick={handleNotesClick}
          >
            + Add Notes
          </button>
        </div>
      </div>

      <div
        className={`notes ${
          Object.values(viewNotes).some((value) => value) ? "show" : ""
        }`}
      >
        <ViewNotesData
          key={refreshKey}
          setNotesSetting={() => {}}
          notesSetting={false}
          handleNotesClick={handleNotesClick}
          textareaRef={textareaRef}
          visible={viewNotes.addnotes}
          selectedFile={selectedFiles}
          resetFile={resetFile}
          notetborder={notetborder}
          customerTaskCategory={customerTaskCategory}
          customerColor={customerColor}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          formData={formData}
          handleSetColour={handleSetColour}
        >
          <div className={`grid ${viewNotes.addnotes ? "show" : "hidden"}`}>
            <NotesEditor
              textareaRef={textareaRef}
              initialContent={""}
              setInitialContent={() => {}}
              notesSetting={false}
              handleFileUpload={handleFileUpload}
              selectedFile={selectedFiles}
              hndleCloseNotes={hndleCloseNotes}
              handleClickDeleteFile={handleClickDeleteFile}
              getFileLastName={getFileLastName}
              handleDeleteFileServer={handleDeleteFileServer}
            />
          </div>
        </ViewNotesData>
      </div>
      <div className="h-full max-h-[75vh] overflow-auto" ref={containerRef}>
        <AllNotesShow
          filterData={filterData}
          disable={disable}
          setDisable={setDisable}
          refreshKey={refreshKey}
          key={refreshKey}
          containerRef={containerRef}
          taskOpenTab={taskOpenTab}
        />
      </div>
    </div>
    </Fragment>
  );
};

export default AddNotes;
