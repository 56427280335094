import { useState, useEffect } from 'react';
import useRequireAuth from '../../utils/useRequireAuth';

const usePermissions = () => {
    const {token} = useRequireAuth();

    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        if (token) {
            const storedPermissions = JSON.parse(localStorage?.getItem('myUserData')) || [];

            setPermissions(storedPermissions);
        }
    }, [token]);

    const hasPermission = (module, subModule) => {
        return permissions?.permissions?.some(p => p.module === module && p.sub_module === subModule && parseInt(p.permission) === 1);
    };

    return { permissions, hasPermission };
};

export default usePermissions;
