import React from 'react'
import LayoutHOC from '../LayoutHOC'

const ModalCompany = () => {
  return (
    <>
      <div className='p-5 mt-4'> 
            <div className="flex flex-col">
                <div className="flex justify-between items-center">
                    <h5 className="text-203C50 font-Vietnam text-24 font-500">
                        Add Customer
                    </h5>
                    <div className="checkbox-wrapper-20">
                        <div className="switch">
                            <input id="2" className="input" type="checkbox" />
                            <label htmlFor="2" className="slider"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-6 flex justify-between items-center'>
              <div>
                <h3 className='text-20 text-203C50 font-600'>Name and contact</h3>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 27" fill="none">
                  <rect x="0.485951" y="0.485951" width="26.0281" height="26.0281" rx="6.85486" stroke="#1D4469" stroke-width="0.971903"/>
                  <path d="M13.7012 21.265C13.1757 21.265 12.75 20.8229 12.75 20.2772V7.71434C12.75 7.16869 13.1757 6.72656 13.7012 6.72656C14.2266 6.72656 14.6524 7.16869 14.6524 7.71434V20.2772C14.6524 20.8229 14.2266 21.265 13.7012 21.265Z" fill="#284356"/>
                  <path d="M19.75 14.9836H7.65236C7.12692 14.9836 6.70117 14.5414 6.70117 13.9958C6.70117 13.4501 7.12692 13.008 7.65236 13.008H19.75C20.2754 13.008 20.7012 13.4501 20.7012 13.9958C20.7012 14.5414 20.2754 14.9836 19.75 14.9836Z" fill="#284356"/>
              </svg>
              </div>
            </div>
            <section className="address bg-white mt-2 pt-1 py-8">
              <div className="font-poppins grid grid-cols-3 px-8 py-3 pt-5 gap-6">
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">First Name</label>
                      <input type="text"  className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Middle Name</label>
                      <input type="text"  className="border border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Last  Name</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Company  Name</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label htmlFor="State" className="block mb-1 text-535252 text-14 font-400">Client Display name*</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Email</label>
                      <input type="email" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Phone Number</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label htmlFor="address" className="block mb-1 text-535252 text-14 font-400">Mobile Number</label>
                      <input type="text" name="address[16]" className="border border-gray-300 text-gray-900 text-xs block w-full p-2.5" />
                  </div>
                  <div>
                      <label htmlFor="phone no1" className="block mb-1 text-535252 text-14 font-400">Fax</label>
                      <input type="text" name="phone1[16]" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label htmlFor="phone2[16]" className="block mb-1 text-535252 text-14 font-400">Website</label>
                      <input type="text" name="phone2[16]" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label htmlFor="Primary Email" className="block mb-1 text-535252 text-14 font-400">Other</label>
                      <input type="text" name="primary_email[16]" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
              </div>
              <div className='font-poppins w-[68%] px-8 py-3'>
                      <label htmlFor="Primary Email" className="block mb-1 text-535252 text-14 font-400">Other</label>
                      <input type="text" name="primary_email[16]" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                      <div className="flex items-center mt-3">
                          <input
                            type="checkbox"
                            value=""
                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:outline-none focus:ring-0 checked:bg-537596 input:border-none"
                          />
                          <label
                            htmlFor="link-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            is a sub client
                        </label>
                      </div>
                </div>
              <div className="flex px-10 font-poppins pt-5 justify-end">
                  <div className="flex gap-4">
                      <div className="text-center">
                          <span className="p-1 text-green-400"></span>
                      </div>
                      <button className="px-6 py-2 text-base rounded font-normal bg-F4F4F4 focus:outline-none" type="button" fdprocessedid="og01k">CANCEL</button>
                      <button type="submit" className="px-6 py-2 text-base rounded font-normal bg-1D4469 text-white focus:outline-none" fdprocessedid="tqv38">SAVE</button>
                  </div>
              </div>
        </section>
            <div className='mt-6 flex justify-between items-center'>
              <div>
                <h3 className='text-20 text-203C50 font-600'>Addresses</h3>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 27" fill="none">
                  <rect x="0.485951" y="0.485951" width="26.0281" height="26.0281" rx="6.85486" stroke="#1D4469" stroke-width="0.971903"/>
                  <path d="M13.7012 21.265C13.1757 21.265 12.75 20.8229 12.75 20.2772V7.71434C12.75 7.16869 13.1757 6.72656 13.7012 6.72656C14.2266 6.72656 14.6524 7.16869 14.6524 7.71434V20.2772C14.6524 20.8229 14.2266 21.265 13.7012 21.265Z" fill="#284356"/>
                  <path d="M19.75 14.9836H7.65236C7.12692 14.9836 6.70117 14.5414 6.70117 13.9958C6.70117 13.4501 7.12692 13.008 7.65236 13.008H19.75C20.2754 13.008 20.7012 13.4501 20.7012 13.9958C20.7012 14.5414 20.2754 14.9836 19.75 14.9836Z" fill="#284356"/>
              </svg>
              </div>
            </div>
            <section className="address bg-white mt-2 pt-1 py-8">
              <h3 className='px-8 pt-5 text-2C495D text-16 font-600'>Billing Address</h3>
              <div className="font-poppins grid grid-cols-3 px-8 py-3 pt-5 gap-6">
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Street Address 1</label>
                      <input type="text"  className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Street Address 2</label>
                      <input type="text"  className="border border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">City</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">State</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label htmlFor="State" className="block mb-1 text-535252 text-14 font-400">ZIP Code</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Country</label>
                      <input type="email" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
              </div>
              <div className='font-poppins px-8 py-3'>
                        <span className='text-2C495D text-16 font-600'>Shipping Address</span>
                      <div className="flex items-center mt-3">
                          <input
                            type="checkbox"
                            value=""
                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:outline-none focus:ring-0 checked:bg-537596 input:border-none"
                          />
                          <label
                            htmlFor="link-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            is a sub client
                        </label>
                      </div>
                </div>
              <div className="flex px-10 font-poppins pt-5 justify-end">
                  <div className="flex gap-4">
                      <div className="text-center">
                          <span className="p-1 text-green-400"></span>
                      </div>
                      <button className="px-6 py-2 text-base rounded font-normal bg-F4F4F4 focus:outline-none" type="button" fdprocessedid="og01k">CANCEL</button>
                      <button type="submit" className="px-6 py-2 text-base rounded font-normal bg-1D4469 text-white focus:outline-none" fdprocessedid="tqv38">SAVE</button>
                  </div>
              </div>
        </section>
            <div className='mt-6 flex justify-between items-center'>
              <div>
                <h3 className='text-20 text-203C50 font-600'>Notes and attachments</h3>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 27" fill="none">
                  <rect x="0.485951" y="0.485951" width="26.0281" height="26.0281" rx="6.85486" stroke="#1D4469" stroke-width="0.971903"/>
                  <path d="M13.7012 21.265C13.1757 21.265 12.75 20.8229 12.75 20.2772V7.71434C12.75 7.16869 13.1757 6.72656 13.7012 6.72656C14.2266 6.72656 14.6524 7.16869 14.6524 7.71434V20.2772C14.6524 20.8229 14.2266 21.265 13.7012 21.265Z" fill="#284356"/>
                  <path d="M19.75 14.9836H7.65236C7.12692 14.9836 6.70117 14.5414 6.70117 13.9958C6.70117 13.4501 7.12692 13.008 7.65236 13.008H19.75C20.2754 13.008 20.7012 13.4501 20.7012 13.9958C20.7012 14.5414 20.2754 14.9836 19.75 14.9836Z" fill="#284356"/>
              </svg>
              </div>
            </div>
            <section className="address bg-white mt-2 pt-1 py-8">
              <h3 className='px-8 pt-5 text-2C495D text-16 font-600'>Billing Address</h3>
              <div className="flex justify-between px-5 pt-5 space-x-10">
                <div className="w-1/2">
                    <label className="block mb-1">Notes</label>
                    <textarea className="w-full border border-gray-300 rounded p-2"></textarea>
                </div>
                <div className="w-1/2">
                    <label className="block mb-1">Attachments</label>
                    <textarea className="w-full border border-gray-300 rounded p-2"></textarea>
                </div>
            </div>

              <div className="flex px-10 font-poppins pt-5 justify-end">
                  <div className="flex gap-4">
                      <div className="text-center">
                          <span className="p-1 text-green-400"></span>
                      </div>
                      <button className="px-6 py-2 text-base rounded font-normal bg-F4F4F4 focus:outline-none" type="button" fdprocessedid="og01k">CANCEL</button>
                      <button type="submit" className="px-6 py-2 text-base rounded font-normal bg-1D4469 text-white focus:outline-none" fdprocessedid="tqv38">SAVE</button>
                  </div>
              </div>
        </section>
        <div className='mt-6 flex justify-between items-center'>
              <div>
                <h3 className='text-20 text-203C50 font-600'>Payments</h3>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 27" fill="none">
                  <rect x="0.485951" y="0.485951" width="26.0281" height="26.0281" rx="6.85486" stroke="#1D4469" stroke-width="0.971903"/>
                  <path d="M13.7012 21.265C13.1757 21.265 12.75 20.8229 12.75 20.2772V7.71434C12.75 7.16869 13.1757 6.72656 13.7012 6.72656C14.2266 6.72656 14.6524 7.16869 14.6524 7.71434V20.2772C14.6524 20.8229 14.2266 21.265 13.7012 21.265Z" fill="#284356"/>
                  <path d="M19.75 14.9836H7.65236C7.12692 14.9836 6.70117 14.5414 6.70117 13.9958C6.70117 13.4501 7.12692 13.008 7.65236 13.008H19.75C20.2754 13.008 20.7012 13.4501 20.7012 13.9958C20.7012 14.5414 20.2754 14.9836 19.75 14.9836Z" fill="#284356"/>
              </svg>
              </div>
            </div>
            <section className="address bg-white mt-2 pt-1 py-8">
              <div className="font-poppins grid grid-cols-3 px-8 py-3 pt-5 gap-6">
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Primary Payment Method</label>
                      <input type="text"  className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Terms</label>
                      <input type="text"  className="border border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Sales from delivery options</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label className="block mb-1 text-535252 text-14 font-400">Language to use  when you send invoices</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
                  <div>
                      <label htmlFor="State" className="block mb-1 text-535252 text-14 font-400">Credit Limit</label>
                      <input type="text" className="border rounded border-gray-300 text-gray-900 text-xs block w-full p-2.5"/>
                  </div>
              </div>
              <div className="flex px-10 font-poppins pt-5 justify-end">
                  <div className="flex gap-4">
                      <div className="text-center">
                          <span className="p-1 text-green-400"></span>
                      </div>
                      <button className="px-6 py-2 text-base rounded font-normal bg-F4F4F4 focus:outline-none" type="button" fdprocessedid="og01k">CANCEL</button>
                      <button type="submit" className="px-6 py-2 text-base rounded font-normal bg-1D4469 text-white focus:outline-none" fdprocessedid="tqv38">SAVE</button>
                  </div>
              </div>
        </section><div className='mt-6 flex justify-between items-center'>
              <div>
                <h3 className='text-20 text-203C50 font-600'>Additional Info</h3>
                <p className='text-16 font-400 text-203C50'>Add client details. Sort, filter and track. Create reports your business need.</p>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 27" fill="none">
                  <rect x="0.485951" y="0.485951" width="26.0281" height="26.0281" rx="6.85486" stroke="#1D4469" stroke-width="0.971903"/>
                  <path d="M13.7012 21.265C13.1757 21.265 12.75 20.8229 12.75 20.2772V7.71434C12.75 7.16869 13.1757 6.72656 13.7012 6.72656C14.2266 6.72656 14.6524 7.16869 14.6524 7.71434V20.2772C14.6524 20.8229 14.2266 21.265 13.7012 21.265Z" fill="#284356"/>
                  <path d="M19.75 14.9836H7.65236C7.12692 14.9836 6.70117 14.5414 6.70117 13.9958C6.70117 13.4501 7.12692 13.008 7.65236 13.008H19.75C20.2754 13.008 20.7012 13.4501 20.7012 13.9958C20.7012 14.5414 20.2754 14.9836 19.75 14.9836Z" fill="#284356"/>
              </svg>
              </div>
            </div>
            <section className=" bg-white mt-2">
              <div className="font-poppins grid grid-cols-3 py-3">
                  <button className='text-left ms-5 item-center border'> + Add Custom Field</button>
              </div>
        </section>
      </div>



      
    </>
  )
}

export default LayoutHOC(ModalCompany);