import { useState, useEffect } from 'react';

const getData = async (url, Authtoken , options = {}) => {
  try {
    const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Authtoken}`,
          "Content-Type": "application/json",
        },
      });
    if (!response.status) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }
    return await response.json();
   //console.log("test",data);return false;
 
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; 
  }
};

const getDataIdBase = async (url, Authtoken ,CompanyId, options = {}) => {
    const companyLocalData = localStorage.getItem("company_data");
    const parsedCompanyData = JSON.parse(companyLocalData);
    const companyId=parseInt(parsedCompanyData.company_id);
    try {
      const response = await fetch(`${url} ${companyId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Authtoken}`,
            "Content-Type": "application/json",
          },
        });
      if (!response.status) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      return await response.json();
     //console.log("test",data);return false;
   
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; 
    }
  };

const saveData = async (url, data, token,options = {}) => {
  try {
   
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });
    if (!response.status) {
      throw new Error(`Error saving data: ${response.status}`);
    }
  
    return await response.json();
  } catch (error) {
    console.error('Error saving data:', error);
    throw error; 
  }
};

const saveAddressData = async (url, data, token, options = {}) => {
  try {
     
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      ...options,
    });
 
    if (!response.ok) {
      throw new Error(`Error saving address data: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error saving address data:', error);
    throw error;
  }
};

// const saveAddressData = async (url, data, token,options = {}) => {
//   try {
 
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       body:JSON.stringify(data),
//     });
//     if (!response.status) {
//       throw new Error(`Error saving data: ${response.status}`);
//     }
  
//     return await response.json();
//   } catch (error) {
//     console.error('Error saving data:', error);
//     throw error; 
//   }
// };


const updateData = async (url, id, data, options = {}) => {
  try {
    const response = await fetch(`${url}/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' }, 
      ...options,
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`Error updating data: ${response.statusText}`);
    }
    return await response.json(); 
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};

export { getData, saveData, updateData,getDataIdBase,saveAddressData};