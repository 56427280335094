import React,{useEffect} from 'react'
import useKeyboardShortcuts from '../../../hooks/KeysHandle/useKeyboardShortcuts';

const SaveEditFirst = ({UpdateTableField,handleCloseClick}) => {

  useKeyboardShortcuts({
    Enter: (event) => {
      event.preventDefault();
      UpdateTableField(event);
    },
    Escape: (event) => {
      event.preventDefault();
      handleCloseClick(event);
    }
  });


  return (
        <div className="absolute bottom-[0px] left-[-91px] pl-6 z-[99999999] h-full flex items-center savecancelpopuptable">
          <div className="flex items-center">
          <span className='bg-white dropshadow1 rounded-md  transform -rotate-90 divide-y-2'>
              <button type='button' className='px-2 py-2  transform rotate-90' onClick={UpdateTableField}>
                <i className="bi bi-check-circle  hover:text-1D4469"></i>
              </button>
              <button 
                type='button' 
                onClick={handleCloseClick} 
                className='relative px-2 py-2 flex items-center justify-center'
              >
                
                <span className='absolute top-[22px] '>
                  <i className="bi bi-caret-down-fill text-white text-2xl"></i>
                </span>

                
                <span>
                  <i className="bi bi-x-circle hover:text-FBB244"></i>
                </span>
              </button>
            </span>



            {/* <span className='absolute top-[22px] left-1'>
              <i className="bi bi-caret-down-fill text-white text-2xl"></i>
            </span> */}
          </div>
        </div>  
  )
}

export default SaveEditFirst