import React, { Fragment } from 'react'
import InputField from '../Fields/InputField';
import DropDown1 from '../Fields/DropDown1';
import MultipleSelectDropdown from '../Fields/MultipleDropDown';
import SearchDropDown from '../Fields/SearchDropDown';
import { parseJSONValue } from '../../../utils/json/parseJSONValue';
import PersonDropDown from '../Fields/PersonDropDown';
import CombinedDate from '../Fields/CombinedDate';
import MultiPerson from '../Fields/MultiPerson';
const RenderElements = ({tableDetail,fetchPersonInTable,Item, Header,RowKey,CommonData,CellChange,CellInput,setDataRow,DataRow,UpdateField,clientCoord,refetchCustomerData,multiData}) => {

    let parsedValue;
      try {
        const value = Item[Header.key]?.value;
        const parsed = JSON.parse(value);
        
        parsedValue = typeof parsed === "object" && parsed !== null ? parseJSONValue(Item[Header.key]) :  Item[Header.key];
        console.log(typeof parsed === "object" && parsed !== null,"try hit")
        console.log((parsedValue),typeof(parsedValue),"parsed")
      } catch (error) {
        console.log("catch hit")
        parsedValue = Item[Header.key];
      }
      let type;
      
      
      if(Header.key==="Delegation Control" || Header.key==="delegation_control")
         type = Item && Item[Header.key]?.type || 'delegation_multiple';
      else
         type = Item && Item[Header.key]?.type || 'text';

   
    // const FieldData = Item && Item[Header.key]?.field_name; 

    switch (type) {
      case 'text':
        return (
            <Fragment>
                <InputField  tableidclass={tableDetail.tableidclass} FieldType={type} FieldValue={parsedValue?.value} FieldChange={CellChange} setDataInputRow={setDataRow} UpdateTableField={UpdateField} Item={Item[Header?.key]} Header={Header} clientCoord={clientCoord}/>   
            </Fragment>

        );
      case 'number':
        return (
          
          <InputField  tableidclass={tableDetail.tableidclass} FieldType={type} FieldValue={parsedValue?.value} FieldChange={CellChange} setDataInputRow={setDataRow} UpdateTableField={UpdateField} Item={Item[Header.key]}/>    
                  
        );
      case 'date':
        return (
          <Fragment>

            {/* <InputField value={value} CellChange={CellChange} customerTableData={customerTableData}/> */}
            <InputField  tableidclass={tableDetail.tableidclass} FieldType={type} FieldValue={parsedValue?.value} FieldChange={CellChange} setDataInputRow={setDataRow} UpdateTableField={UpdateField} Item={Item[Header.key]}/>    
             
                
          </Fragment>
         

          
        );
      case 'combine_date':
        return (
          <Fragment>

            {/* <InputField value={value} CellChange={CellChange} customerTableData={customerTableData}/> */}
            {/* <InputField  tableidclass={tableDetail.tableidclass} FieldType={type} FieldValue={parsedValue?.value} FieldChange={CellChange} setDataInputRow={setDataRow} UpdateTableField={UpdateField} Item={Item[Header.key]}/>     */}
         
          
             <CombinedDate tableidclass={tableDetail.tableidclass} FieldType={type} FieldValue={parsedValue?.value} FieldChange={CellChange} setDataInputRow={setDataRow} UpdateTableField={UpdateField} Item={Item[Header.key]} ItemsValue={Item}/>
                
          </Fragment>
         

          
        );
      case 'dropdown':
        return (
          // <select
          //   defaultValue={value}
          //   onChange={CellChange}
          //   className="p-3 w-full"
          // >
          //   <option selected>g</option>
          //   </select>
          <Fragment>
            
                  <DropDown1 tableidclass={tableDetail.tableidclass} setDataInputRow={setDataRow} dataInputRow={DataRow} SpecificElement={RowKey} initialIsOpen={true} DropData={CommonData?.data}  UpdateTableField={UpdateField} FieldValue={parsedValue} Item={Item[Header.key]} FirstField="value"/>
                
          </Fragment>
          
        );

      case 'multiple':
        return (
          // <select
          //   defaultValue={value}
          //   onChange={CellChange}
          //   className="p-3 w-full"
          // >
          //   <option selected>g</option>
          //   </select>
          <Fragment>
            
                  {/* <DropDown1 tableidclass={tableDetail.tableidclass} setDataRow={setDataRow} DataRow={DataRow} SpecificElement={RowKey} initialIsOpen={true} DropData={CommonData?.data[Item[Header.key].table_name]}  UpdateTableField={UpdateField} FieldName={Item[Header.key].table_name} FieldValue={Item[Header.key]} FirstField="value"/> */}
                  <MultipleSelectDropdown tableidclass={tableDetail.tableidclass} setDataInputRow={setDataRow}  DropData={CommonData?.data} initialIsOpen={true}  UpdateTableField={UpdateField} Item={Item[Header.key]}/>
          </Fragment>
          
        );

      case 'Listbox':
        return (
          <Fragment>
                  <SearchDropDown  tableidclass={tableDetail.tableidclass}  setDataInputRow={setDataRow}  dataInputRow={DataRow} SpecificElement={RowKey}  DropData={CommonData?.data} UpdateTableField={UpdateField} FieldValue={parsedValue} Item={Item[Header.key]}/>
                  {/* <DropDown1 tableidclass={tableDetail.tableidclass} setDataRow={setDataRow} DataRow={DataRow} SpecificElement={RowKey} initialIsOpen={true} DropData={CommonData?.data[Item[Header.key].table_name]}  UpdateTableField={UpdateField} FieldName={Item[Header.key].table_name} FieldValue={Item[Header.key]} FirstField="value"/> */}
                  {/* <MultipleSelectDropdown  setDataRow={setDataRow}  options={Item[Header.key]?.value} onChange={EventFire} initialIsOpen={true}  UpdateTableField={UpdateField} FieldName={Item[Header.key].table_name} FieldValue={Item[Header.key]}/> */}
          </Fragment>
          
        );

      case 'person':
        return (
          <Fragment>
                  <PersonDropDown  tableidclass={tableDetail.tableidclass} fetchPersonInTable={fetchPersonInTable}  setDataInputRow={setDataRow}  dataInputRow={DataRow} SpecificElement={RowKey}  DropData={CommonData} UpdateTableField={UpdateField} FieldValue={parsedValue} Item={Item[Header.key]}/>
                  {/* <DropDown1 tableidclass={tableDetail.tableidclass} setDataRow={setDataRow} DataRow={DataRow} SpecificElement={RowKey} initialIsOpen={true} DropData={CommonData?.data[Item[Header.key].table_name]}  UpdateTableField={UpdateField} FieldName={Item[Header.key].table_name} FieldValue={Item[Header.key]} FirstField="value"/> */}
                  {/* <MultipleSelectDropdown  setDataRow={setDataRow}  options={Item[Header.key]?.value} onChange={EventFire} initialIsOpen={true}  UpdateTableField={UpdateField} FieldName={Item[Header.key].table_name} FieldValue={Item[Header.key]}/> */}
          </Fragment>
          
        );
        case 'delegation':
          return(
            <Fragment>

              <p>delegation</p>
            </Fragment>
          )
        case "multi_person":
          return(
            <Fragment>
                <MultiPerson  tableidclass={tableDetail.tableidclass} FieldType={type} FieldValue={parsedValue} FieldChange={CellChange} setDataInputRow={setDataRow} UpdateTableField={UpdateField} Item={Item[Header?.key]} Header={Header} clientCoord={clientCoord} DataRow={DataRow} CommonData={CommonData} ItemComplete={Item} refetchCustomerData={refetchCustomerData} multiData={multiData}/>   
            </Fragment>
          )

      default:
        return (
          <InputField  tableidclass={tableDetail.tableidclass} FieldType={type} FieldValue={parsedValue?.value} FieldChange={CellChange} setDataInputRow={setDataRow} UpdateTableField={UpdateField} Item={Item[Header?.key]} Header={Header} clientCoord={clientCoord}/>     
             
        );
    }


}

export default RenderElements