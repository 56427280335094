// import React from 'react'
// import SubmitIcon from '../../Icons/SubmitIcon'

// const TextareaSubmit = ({handleSaveEvent}) => {
//   return (
//     <form onSubmit={handleSaveEvent}>
//         {/* <div className="relative flex flex-wrap"> */}
//         <div className='border-2 border-lightBlue rounded-5px focus:outline-hidden focus:ring-0 focus:border-1 focus:border-lightBlue focus:shadow-11 flex flex-col'>

//             <textarea
//                 name="description"
//                 rows={3}
//                 className="h-full w-full  shadow-10 pr-8"
//                 placeholder="Type Something..."
//             ></textarea>
//             <div>

//             <button
//                 className="bg-white absolute right-1 top-1 z-10 py-2 px-4"
//                 type="submit"
//                 data-ripple-light="true"
//             >
//                 <SubmitIcon />
//             </button>

//             </div>


//         </div>
          
//         {/* </div> */}
//     </form>
//   )
// }

// export default TextareaSubmit








// import React, { useState, useRef, useEffect } from 'react';
// import SubmitIcon from '../../Icons/SubmitIcon';

// const TextareaSubmit = ({ defaultValue,onChange,handleSaveEvent }) => {
//   const textareaRef = useRef(null);

//   const handleInput = () => {
//     textareaRef.current.style.height = 'auto';
//     textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
//   };

//   useEffect(() => {
//     handleInput(); // Adjust the height initially in case there's already text in the textarea
//   }, []);

//   return (
//     <form onSubmit={handleSaveEvent} className="relative">
//       <div className="border-2 border-lightBlue rounded-5px focus:outline-hidden focus:ring-0 focus:border-1 focus:border-lightBlue focus:shadow-11 flex flex-col relative">
//         <textarea
//           name="description"
//           rows={3}
//           className="h-full w-full shadow-10 pr-14 resize-none overflow-hidden p-2 rounded-t-5px"
//           placeholder="Type Something..."
//           onInput={handleInput}
//           value={defaultValue}
//           onChange={onChange}
//           ref={textareaRef}
//         ></textarea>
//         <div className="absolute right-1 bottom-1">
//           <button
//             className="bg-white py-2 px-4 rounded-md shadow"
//             type="submit"
//             data-ripple-light="true"
//           >
//             <SubmitIcon />
//           </button>
//         </div>
//       </div>
//     </form>
//   );
// };

// export default TextareaSubmit;













import React, { useState, useRef, useEffect } from 'react';
import SubmitIcon from '../../Icons/SubmitIcon';
import CreatePortalHOC from './CreatePortalHOC';

const TextareaSubmit = ({ Value, onChange, handleSaveEvent }) => {
  const textareaRef = useRef(null);

  const handleInput = () => {
    textareaRef.current.style.height = '80px';  
    textareaRef.current.style.minHeight = '60px';  
    textareaRef.current.style.maxHeight = '80px';  
    // textareaRef.current.style.height = `${Math.min(Math.max(textareaRef.current.scrollHeight, 10), 80)}px`; 
      
      
    textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
  };

 


  useEffect(() => {
    handleInput(); 
  }, []);

  


 

  return (
      <form className="relative">
        <div className=" h-[55px] flex flex-col ">
         
          <textarea
            name="description"
            id="poparea"
            rows={8}
            className=" h-[55px]  resize-none text-12 overflow-hidden rounded-[5px] "
            // placeholder="Type Something..."
            onInput={handleInput}
            value={Value}
            onChange={onChange}
            ref={textareaRef}
          ></textarea>
         
          <div className="absolute right-1 bottom-1">
            {/* <button
              className="py-2 px-4 rounded-md "
              type="button"
              data-ripple-light="true"
              onClick={handleSaveEvent}
            >
              <SubmitIcon />
            </button> */}
          </div>
        </div>
      </form>
  );
};

export default TextareaSubmit;
