import React, { Fragment, useState, useRef } from 'react';
import LayoutHOC from '../LayoutHOC';
import CustomerListTable from './CustomerListTable';
import InvoiceBody from './InvoiceBody.js';
import invoiceHeader from './invoiceHeader';
import iPay from '../../assets/images/iPay.webp';
import VISA from '../../assets/images/VISA.webp';
import PayPal from '../../assets/images/PayPal.webp';
import InvoiceEditCompanyModal from '../company/InvoiceEditCompanyModal';
import ModalCompany from '../company/ModalCompany';
import useClickOutside from '../../hooks/Screen/useClickOutside';
import InvoiceView from './InvoiceView';

const Invoice = () => {
    const [imageSrc, setImageSrc] = useState(null);
    const fileInputRef = useRef(null);
    const [attachmentName, setAttachmentName] = useState('');
    const attachmentInputRef = useRef(null);
    const [isSetOpenMyCompanyModal, setOpenMyCompanyModal] = useState(false);
    const [isSetTaskOpenMyCompanyModal, setTaskOpenMyCompanyModal] = useState(false);
    const [isModalCompanyOpen,setModalCompany] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    

    const handleOptionChange = () => {
        setIsModalOpen(true); 
    };
    

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
      };
    

    const handleAttachmentInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setAttachmentName(file.name);
        }
    };

    const handleAttachmentClick = () => {
        attachmentInputRef.current.click();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFileUpload(file);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileUpload = (file) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            setImageSrc(event.target.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleInputChange = (event) => {
        const file = event.target.files[0];
        handleFileUpload(file);
    };

    const handleOpenMyCompanyModal = () => {
        setOpenMyCompanyModal(true);
    };

    const openMyCompanyModal = () => {
        setTaskOpenMyCompanyModal(true);
    };

    const closeMyCompanyModal = () => {
        setTaskOpenMyCompanyModal(false);
    };


    const openInvoiceView = () =>{
        setIsModalOpen(true);
     }
     const closeInvoiceView = () =>{
        setIsModalOpen(false);
     }

    const isOpen = () => {};

    const closeMycompanyoutsideclick = useClickOutside(() => { 
        setTaskOpenMyCompanyModal(false); 
    });
    const closeclick = useClickOutside(() => { setIsModalOpen(false); });

    return (
        <Fragment>
            <div className="m-5 mt-10">
                <div className="border bg-white mt-5 h-10">
                    <ul className="flex justify-start ms-4 space-x-8 text-center pt-2 text-848687">
                        <li className="relative hover:text-2C495D hover:font-500 cursor-pointer hover:after:content-[''] hover:after:absolute hover:after:left-0 hover:after:right-0 hover:after:bottom-0 hover:after:h-0.5 hover:after:bg-2C495D hover:pb-1">
                            Edit
                        </li>
                        <li className="relative hover:text-2C495D hover:font-500 cursor-pointer hover:after:content-[''] hover:after:absolute hover:after:left-0 hover:after:right-0 hover:after:bottom-0 hover:after:h-0.5 hover:after:bg-2C495D hover:pb-1">
                            Email View
                        </li>
                        <li className="relative hover:text-2C495D hover:font-500 cursor-pointer hover:after:content-[''] hover:after:absolute hover:after:left-0 hover:after:right-0 hover:after:bottom-0 hover:after:h-0.5 hover:after:bg-2C495D hover:pb-1">
                            PDF View
                        </li>
                        <li className="relative hover:text-2C495D hover:font-500 cursor-pointer hover:after:content-[''] hover:after:absolute hover:after:left-0 hover:after:right-0 hover:after:bottom-0 hover:after:h-0.5 hover:after:bg-2C495D hover:pb-1">
                            Payor View
                        </li>
                    </ul>
                </div>

                <div className="bg-white mt-3 rounded-lg pb-4">
                    <div className="flex justify-between items-center p-5">
                        <div>
                            <h3 className="m-4 text-24 font-600 text-2C495D">Invoices</h3>
                            <div className="mt-4 ml-4 flex space-x-8">
                                <h4 className="text-18 font-600 text-2C495D">GFPO USA</h4>
                                <h5 className="text-15 font-500 text-2C495D">gfpo.us@globalfpo.com</h5>
                            </div>
                            <h6 className="ml-4 mt-1 text-15 font-400 text-2C495D">28228</h6>
                            <button 
                                className="border rounded border-gray-300 text-848687 text-12 font-normal ml-4 mt-2 p-2 hover:bg-white"
                                onClick={openMyCompanyModal}
                                type='button'
                            >
                                Edit <i className="bi bi-pencil-fill ml-2 text-gray"></i>
                            </button>
                            {isSetTaskOpenMyCompanyModal && (
                                <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                                    <div className="fixed inset-0 flex justify-end z-[9999] bg-gray-900 bg-opacity-50">
                                        <div ref={closeMycompanyoutsideclick} className="relative mt-18 bg-white  font-popns drop-shadow-lg  rounded-md max-w-[824px] overflow-hidden no-scrollbar">
                                                <div className="px-4">
                                                    <div className="flex justify-between items-center py-2 ">
                                                        <div className="text-xl font-medium text-203C50">My Company</div>
                                                        <div className="rounded-[5px] bg-F1F5F8 w-[30px] h-[30px] flex justify-center items-center" onClick={closeMyCompanyModal} ><i class="bi bi-x-lg"></i></div>
                                                    </div>
                                                    <InvoiceEditCompanyModal
                                                        isOpen={isOpen}
                                                        closeMyCompanyModal={closeMyCompanyModal}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                                </div>
                             )}
                            
                        </div>
                        <div className="mt-8 pe-8 flex justify-end w-[34%]">
                            <div className="w-72 h-32 p-1 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100 flex items-center justify-center">
                                <div
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    onClick={handleClick}
                                    className="flex items-center justify-center w-64 h-28 text-center text-gray-500"
                                >
                                    {imageSrc ? (
                                        <img src={imageSrc} alt="Uploaded" className="w-full h-full object-cover" />
                                    ) : (
                                        <div className="flex flex-col items-center justify-center text-center">
                                            <button className="mb-1 text-1 font-400 text-2999BC">Add Logo</button>
                                            <small className="text-14 font-400 text-B3B3B3">Max size: 1 MB</small>
                                        </div>
                                    )}
                                </div>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-F5F5F5 mt-4">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4 p-4 relative">
                                <button 
                                        onClick={toggleDropdown}
                                        className="form-select text-xs block w-4/5 mb-2 px-1 py-2 rounded-md border border-gray-300 bg-white focus:outline-none"
                                        fdprocessedid="ues0i"
                                    >
                                        ADD CUSTOMER - SEARCH BY NAME - DROPDOWN
                                    </button>
                                    <div>
                                        {dropdownOpen && (
                                            <ul className="text-xs block w-4/5 px-2.5 space-y-2 py-2 rounded-md border border-gray-300 bg-white focus:outline-none" fdprocessedid="ues0i">
                                                <li className='text-2999BC text-12 font-500'><a href='modal_company'>+ Add New</a> </li>
                                                <li className=''>A Good Painter, LLC </li>
                                                <li className=''>ABC, LLC </li>
                                                <li className=''>ABC Ltd. </li>
                                            </ul>
                                        )}
                                    </div>
                                    <div
                                        className="absolute inset-y-4 right-12 w-0.5 py-5 bg-gray-300"
                                        style={{ background: 'rgba(29, 68, 105, 0.20)' }}
                                    ></div>
                            </div>

                            <div className="col-span-8 p-4 space-y-2">
                                <div className="flex items-center">
                                    <label className="w-36 text-5E5E5E text-sm font-400">Invoice Number</label>
                                    <input className="px-2 w-[191px] rounded h-8 border border-gray-300"></input>
                                </div>
                                <div className="flex items-center">
                                    <label className="w-36 text-5E5E5E text-sm font-400">Terms</label>
                                    <select className="w-[192px] rounded py-1 border border-gray-300 form-select">
                                        <option>Net 30</option>
                                        <option>1</option>
                                        <option value="1">2</option>
                                        <option value="2">3</option>
                                    </select>
                                </div>
                                <div className="flex items-center">
                                    <label className="w-36 text-5E5E5E text-sm font-400">Invoice Date</label>
                                    <input className="w-[192px] rounded border border-gray-300 h-8 text-14" type="date"></input>
                                </div>
                                <div className="flex items-center">
                                    <label className="w-36 text-5E5E5E text-sm font-400">Due Date</label>
                                    <input className="w-[192px] rounded border border-gray-300 h-8 text-14" type="date"></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white m-4 mt-4">
                        <div>
                            <div className="flex items-center justify-between">
                                <label className="text-14 text-434852 font-500">Tags (Hidden):</label>
                                <button className="text-12 text-2999BC font-400 pe-2">Manage Tags</button>
                            </div>
                            <input
                                className="w-full rounded mt-1 px-3"
                                placeholder="Start Typing To Add a Tag"
                            ></input>
                        </div>

                        <div className="mt-6">
                            <div className="flex justify-between">
                                <div>
                                    <h3 className="text-16 text-2C495D font-600">Product or Service</h3>
                                </div>
                                <div>
                                    <button className="text-1D4469 text-20 pe-2">
                                        <i class="bi bi-plus-square"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="py-20 text-center">Dynamic Table Data Here</div>
                        </div>
                    </div>
                    <div className="m-4 flex pb-10">
                        <div className=" w-[57%]">
                            <div className="flex space-x-3">
                                <h4 className="text-16 text-2C495D font-600">Customer Payment Option</h4>
                                <button className="text-2999BC text-14 font-500 ">Edit</button>
                            </div>
                            <div className="flex space-x-4 mt-2">
                                <button>
                                    <img src={iPay} alt="iPay"></img>
                                </button>
                                <button>
                                    <img src={VISA} alt="VISA"></img>
                                </button>
                                <button>
                                    <img src={PayPal} alt="PayPal"></img>
                                </button>
                            </div>
                            <div className="mt-4">
                                <h5 className="text-434852 text-14 font-500">
                                    Activate online card or bank transfer payments for your clients
                                </h5>
                                <textarea
                                    className="mt-1 w-3/5 h-28 px-2 border-DFE2E5 rounded-md placeholder:text-12 placeholder:font-400 placeholder:text-434852"
                                    placeholder="Tell your customer how you want to get paid"
                                ></textarea>
                            </div>
                            <div className="mt-3">
                                <h5 className="text-434852 text-14 font-500">Note to Customer</h5>
                                <textarea
                                    className="mt-1 w-3/5 h-28 px-2 border-DFE2E5 rounded-md placeholder:text-12 placeholder:font-400 placeholder:text-434852"
                                    placeholder="Tell your customer how you want to get paid"
                                ></textarea>
                            </div>
                            <div className="mt-3">
                                <h5 className="text-434852 text-14 font-500">Internal Customer Notes(Hidden)</h5>
                                <textarea
                                    className="mt-1 w-3/5 h-28 px-2 border-DFE2E5 rounded-md placeholder:text-12 placeholder:font-400 placeholder:text-434852"
                                    placeholder="Tell your customer how you want to get paid"
                                ></textarea>
                            </div>

                            <div className="mt-3">
                                <h5 className="text-2C495D text-16 font-600">Attachments</h5>
                                <div className="w-3/5 h-32 p-1 border-2 mt-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100" onClick={handleAttachmentClick}>
                                    <div className="flex flex-col items-center justify-center h-full w-full text-center text-gray-500">
                                        <div className="flex items-center justify-center w-64 h-28 text-center text-gray-500">
                                            <div className="flex flex-col items-center justify-center text-center">
                                                {attachmentName ? (
                                                    <p className="text-2999BC text-14 font-400">{attachmentName}</p>
                                                ) : (
                                                    <Fragment>
                                                        <button className="text-2999BC text-14 font-400">Add attachments</button>
                                                        <small className="text-B3B3B3 text-14 font-400">Max size: 20 MB</small>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        type="file"
                                        ref={attachmentInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleAttachmentInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-2/5">
                            <div className="bg-EBF4FA h-40 rounded-lg">
                                <div className="px-6 pt-4 pb-2">
                                    <div className="flex justify-between">
                                        <p className="text-5E5E5E text-14 font-400">Subtotal</p>
                                        <p className="text-2C495D text-14 font-400">$ 0.00</p>
                                    </div>
                                    <div className="flex justify-between mt-2">
                                        <p className="text-5E5E5E text-14 font-400">Sales Tax</p>
                                        <button className="text-2999BC text-14 font-400">Set Up</button>
                                    </div>
                                </div>
                                <hr className="border-gray-300" />
                                <div className="px-5 pt-2">
                                    <div className="flex justify-between">
                                        <p className="text-5E5E5E text-14 font-600">Invoice Total</p>
                                        <p className="text-2C495D text-14 font-600">$ 0.00</p>
                                    </div>
                                    <div className="flex justify-end mt-2">
                                        <button className="text-2999BC text-14 font-400"
                                            onClick={openInvoiceView}
                                            type='button'>Edit Totals
                                         </button>
                                                {isModalOpen && (
                                                        <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                                                            <div className="fixed inset-0 flex justify-end z-[9999] bg-gray-900 bg-opacity-50">
                                                                <div ref={closeclick} className="relative mt-18 bg-white  font-Poppins drop-shadow-lg  rounded-md w-[652px]">
                                                                        <div className="px-4">
                                                                            <div className="flex justify-between items-center py-2">
                                                                                <div className="text-xl font-medium text-203C50">Invoice 1001</div>
                                                                                <div className="rounded-[5px] bg-F1F5F8 w-[30px] h-[30px] flex justify-center items-center" onClick={closeInvoiceView} ><i class="bi bi-x-lg"></i></div>
                                                                            </div>
                                                                            <InvoiceView
                                                                                isOpen={isOpen}
                                                                                closeMyCompanyModal={closeInvoiceView}
                                                                            />
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end space-x-2 pe-4">
                        <div className=" ">
                        <button
                            type="submit"
                            className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold  border border-gray-200 py-2 px-5 ms-3 rounded-sm"
                        >
                            SAVE
                        </button>
                        </div>
                        <div className="">
                           
                            <button
                            type="submit"
                            className="w-48 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold  border border-gray-200 py-2 px-5 ms-3 rounded-sm"
                        >
                            Review and Send
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LayoutHOC(Invoice);
