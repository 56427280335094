const handlePostApi = async ({ AddField, Data,LiveLoad, AlertMessage,Loader,Error }) => {
    try {
        Loader(true);
        Error(null);
        const response = await AddField(Data).unwrap();
        
        await Promise.all(LiveLoad.map(refetch => refetch()));

        AlertMessage({ visible: true, message: response?.message, type: 'success' });
        
      } catch (err) {
        AlertMessage({ visible: true, message: err?.data?.error, type: 'danger' });
      } finally {
        Loader(false);
      }
};

export default handlePostApi;
