import { CUSTOMER_ACTIVITY_GET_EVENT } from "../../../utils/constants";

const getData = async (url, Authtoken , options = {}) => {
  console.log(url, 1111);
  try {
    const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Authtoken}`,
          "Content-Type": "application/json",
        },
      });
    if (!response.status) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }
    return await response.json();
 
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; 
  }
};

const getDataIdBase = async (url, Authtoken ,CompanyId, options = {}) => {
    const companyLocalData = localStorage.getItem("company_data");
    const parsedCompanyData = JSON.parse(companyLocalData);
    try {
      const response = await fetch(`${url} ${parsedCompanyData.company_id.trim()}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Authtoken}`,
            "Content-Type": "application/json",
          },
        });
      if (!response.status) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      return await response.json();
     //console.log("test",data);return false;
   
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; 
    }
  };

const saveData = async (url, data, Authtoken,options = {}) => {
  try {
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${Authtoken}`,
        'Content-Type': 'application/json', // Specify JSON content type
      },
      body: JSON.stringify(data[0]),
    });
    if (!response.status) {
      throw new Error(`Error saving data: ${response.status}`);
    }
   
    return await response.json();
   
  } catch (error) {
    console.error('Error saving data:', error);
    throw error; 
  }
};

const FileSaveData = async (url, data, Authtoken, options = {}) => {
  try {
   /// console.log("342345234subash",data);return false;
    const { file_name, customer_id, descriptions, doc_type,company_id ,status} = data[0];
    const formData = new FormData();
    formData.append('customer_id', customer_id);
    formData.append('descriptions', descriptions);
    formData.append('doc_type', doc_type);
    formData.append('file_name', file_name);
    formData.append('company_id', company_id);
    formData.append('status', status);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${Authtoken}`,
      },
      body: formData,
    });
    if (!response.status) {
      throw new Error(`Error saving data: ${response.status}`);
    }
    return await response.json();
     

  } catch (error) {
    console.error('Error saving data:', error);
    throw error;
  }
};



const saveDataAll = async (url, data, Authtoken, options = {}) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${Authtoken}`,
      },
      body: data,
    });
    if (!response.status) {
      throw new Error(`Error saving data: ${response.status}`);
    }
    return await response.json();
     

  } catch (error) {
    console.error('Error saving data:', error);
    throw error;
  }
};


const updateArtifact = async (url, data, Authtoken, options = {}) => {
  try {
    const {id,status} = data[0];
   // console.log(id,"44444444444444",status);return false;
    const formData = new FormData();
    formData.append('id', id);
    formData.append('status', status);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${Authtoken}`,
      },
      body: formData,
    });
    if (!response.status) {
      throw new Error(`Error saving data: ${response.status}`);
    }
    return await response.json();
     

  } catch (error) {
    console.error('Error saving data:', error);
    throw error;
  }
};
const updateData = async (url, data, Authtoken, options = {}) => {
  try {
   
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${Authtoken}`,
        'Content-Type': 'application/json', // Specify JSON content type
      },
      body: JSON.stringify(data[0]),
    });
    if (!response.ok) {
      throw new Error(`Error updating data: ${response.statusText}`);
    }
   // console.log(response.json());return false;
    return await response.json(); 
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};

const deleteData = async (url, Authtoken , options = {}) => {
  try {
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Authtoken}`,
          "Content-Type": "application/json",
        },
      });
    if (!response.status) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }
    return await response.json();
   //console.log("test",data);return false;
 
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; 
  }
};

const getCommonDataBasedOnId = async (url, Authtoken , options = {}) => {
  try {
    const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Authtoken}`,
          "Content-Type": "application/json",
        },
      });
    if (!response.status) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    return await response.json();
   //console.log("test",data);return false;
 
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; 
  }
};




const getAddDataBasedOnId = (url, Authtoken , options = {}) => {
  try {
    const response =  fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Authtoken}`,
          "Content-Type": "application/json",
        },
      });
    if (!response.status) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }
    return  response.json();
   //console.log("test",data);return false;
 
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; 
  }
};

// const getAdrressCommonDataBasedOnId = async (url, Authtoken, options = {}) => {
//   try {
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${Authtoken}`,
//         "Content-Type": "application/json",
//       },
//     });
//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     const responseData = await response.json();
//     if (!responseData) {
//       throw new Error("Empty response!");
//     }
//     console.log("Response Data:", responseData);
//     return responseData;
//   } catch (error) {
//     if (error instanceof SyntaxError) {
//       console.error("Error parsing JSON:", error);
//     } else {
//       console.error("Error fetching data:", error);
//     }
//     throw error;
//   }
// };

const saveNotesData = async (url, data, Authtoken,options = {}) => {
  try {
 
    const { company_id, customer_id, notes_category_id, notes_title ,notes_body ,rag_status,id,colour_code,attachment } = data[0];
   
    
    const formData = new FormData();
    formData.append('company_id', company_id);
    formData.append('customer_id', customer_id);
    formData.append('notes_category_id', notes_category_id);
    formData.append('notes_title', notes_title);
    formData.append('notes_body', notes_body);
    formData.append('rag_status', rag_status);
    formData.append('id', id);
    formData.append('colour_code', colour_code);
    formData.append('attachment',attachment);
    attachment.forEach((fileObj, index) => {
      formData.append(`attachment[${index}]`, fileObj.file);
    });
    // if (Array.isArray(attachment) && attachment.length > 0) {
    //   attachment.forEach((file, index) => {
    //     if (file instanceof File) {
    //       formData.append(`attachment[${index}]`, file);
    //     }
    //   });
    // }
    // for (let [key, value] of formData.entries()) {
    //   console.log("888888888888888888",key, value);
    // }
    // console.log(formData.entries(),"attachment",attachment);return false;
  
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${Authtoken}`,
      },
      body: formData,
    });
    if (!response.status) {
      throw new Error(`Error saving data: ${response.status}`);
    }
    return await response.json();
     
   
  } catch (error) {
    console.error('Error saving data:', error);
    throw error; 
  }
};



const saveTasksData = async (url, data, Authtoken,options = {}) => {
  try {
   
   
    const {start_date,end_date} = data[0];

    
    // const formData = new FormData();
    // formData.append('category_id', category_id);
    // formData.append('company_id', company_id);
    // formData.append('contact_name', contact_name);
    // formData.append('customer_id', customer_id);
    // formData.append('description', description);
    // formData.append('owner', owner);
    // formData.append('priority', priority);
    // formData.append("due_date",due_date);
    // formData.append('subject', subject);
    // formData.append('task_pod', task_pod);
    // formData.append('id', id);
    // formData.append('closing_remarks', closing_remarks);
    // formData.append('status', status);
    // formData.append('is_recurrence', is_recurrence);

    
    const formData = new FormData();

    
    // Assuming data is an array and you want to handle the first item
    const taskData = data[0];

    // Append each field from taskData to FormData
    // Object.keys(taskData).forEach((key) => {
    //   formData.append(key, taskData[key]);
    // });

    Object.keys(taskData).forEach((key) => {
      if (Array.isArray(taskData[key])) {
        // Convert array to JSON string and append it
        formData.append(key, JSON.stringify(taskData[key]));
      } else { 
        formData.append(key, taskData[key]);
      }
    });
    
    // if (start_date) {
    //   formData.append('start_date', start_date?.toLocaleString('en-GB', { hour12: false }));
    // }

    // if (end_date) {
    //   formData.append('end_date', end_date?.toLocaleString('en-GB', { hour12: false }));
    // }

  
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${Authtoken}`,
      },
      body: formData,
    });
    
    if (!response.status) {
      throw new Error(`Error saving data: ${response.status}`);
    }
    return await response.json();
     
   
  } catch (error) {
    console.error('Error saving data:', error);
    throw error; 
  }
};

const saveDataAddress = async (url, data, Authtoken,options = {}) => {
  try {
   
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${Authtoken}`,
        'Content-Type': 'application/json', // Specify JSON content type
      },
      body: JSON.stringify(data),
    });
    if (!response.status) {
      throw new Error(`Error saving data: ${response.status}`);
    }
   
    return await response.json();
   
  } catch (error) {
    console.error('Error saving data:', error);
    throw error; 
  }
};

const getCountDataBasedOnId = async (id,Authtoken, options = {}) => {
  try {
    const url = `${CUSTOMER_ACTIVITY_GET_EVENT}?task_id=${id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Authtoken}`,
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();
    return responseData?.data.length;
  } catch (error) {
    throw error;
  }
};

export { getData, saveData,saveDataAddress, updateData,getDataIdBase,deleteData,getCommonDataBasedOnId,FileSaveData,saveNotesData,updateArtifact,saveTasksData,getCountDataBasedOnId,getAddDataBasedOnId,saveDataAll};