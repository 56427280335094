import React, { useState, useEffect, useRef, Fragment } from "react";
import LayoutHOC from "../LayoutHOC";
import CustomerListTable from "../customer/CustomerListTable";
import taskPodHead from "../customer/TaskPodHead";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import EditTaskModel from "../customer/EditTaskModel";
import AddTaskModal from "../customer/AddTaskModal";
import useRequireAuth from "../../utils/useRequireAuth";
import { useParams } from "react-router-dom";

import GentChart from "../GlobalFeature/Chart/GentChart";
import NavBarTabs from "../GlobalFeature/Others/NavBarTabs";
import { useFetchCustomerTaskPodTableDataQuery,
  useUpdateCustomerTaskPodTableDataMutation
 } from "../../utils/customer/customerTaskTable";
 import { useLazyFetchCustomerTableQuery ,useLazyFetchPersonInTableQuery} from "../../utils/customer/customerTable";
 import { useCustomerTableMutation } from "../../utils/customer/customerTable";
import useFirstLetters from "../../hooks/LetterShow/useFirstLetters";
import FirstLetters from "../GlobalFeature/Letters/FirstLetters";
import QuickFilter from "../GlobalFeature/Table/HeadTop/QuickFilter";
import { getUserTokeData } from "../../utils/userSlice";
import { useLocation } from "react-router-dom";

 const stringData = (dateString) => {
  if (!dateString || dateString.trim() === "") return "";
  const data = dateString?.split("-");
  return `${data[2]}-${data[1]}-${data[0]}`;
};

const TaskPod = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location=useLocation();
  const [quiFilter, setQuiFilter] = useState("All");
  const [initialFilter, setInitialFilter] = useState([]);
  const [customer, setCustomer] = useState([]);
  const { token } = useRequireAuth();
  const [customerTablePagination, setCustomerTablePagination] = useState("");
  const { name: taskPod } = useParams();
  const [refreshKey, setRefreshKey] = useState(0);
  const showMessage = useSelector((state) => state.tasks.showMessage);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [editData, setEditData] = useState();
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const taskFormSubmit = useSelector((state) => state.tasks);
  
  const { data: customerTableALL, refetch: refetchCustomerData } =
  useFetchCustomerTaskPodTableDataQuery({
      customerId: encodeURIComponent(taskPod),
      pagination: `${customerTablePagination}`,
      quiFilter:quiFilter,
      token: token,
      
    });

    useEffect(() => {
      if (customerTableALL) {
        setCustomer(customerTableALL?.data?.data);
        const { data: omitData, ...restData } = customerTableALL?.data;
   
        setInitialFilter(restData);
        setLoading(false);
      }
    }, [customerTableALL, customerTablePagination]);

    useEffect(()=>{
      if(taskFormSubmit?.tasks?.data?.data){
        refetchCustomerData(); 
      }
  
    },[taskFormSubmit?.tasks?.data?.data])

   

    const fetchPersonData = (data) => fetchPersonInTable({ token, data });
    const [fetchPersonInTable, { data: personTableData }] =
      useLazyFetchPersonInTableQuery();
  
      
  const [updateCustomerTaskPodTableData, { CustomerDropLoad, CustomerDroperror }] =
  useUpdateCustomerTaskPodTableDataMutation();

  const handleNavigate = (customer_id) => {
    setIsEditPopupOpen(true);
    setEditData(customer_id);
  };

  useEffect(()=>{
    dispatch(getUserTokeData())
  },[location])

  const openPopup = () => {
    setIsPopupOpen(true);
    dispatch(getUserTokeData())
  };

  const closePopup = () => {
    setIsEditPopupOpen(false);
    setIsPopupOpen(false);
    setEditData();
  };

  const [
    CustomerTableDrop,
    { data: customerCommnData, refetch: refetchCustomerCommnData },
  ] = useCustomerTableMutation();

  const fetchCustomerTable = (data) => {
    const completedata = {
      "model_name": "task",
      "field_name": data,
    };

    
    CustomerTableDrop({token:token,data:completedata});
  }
 

  const customerTableFieldUpdate = async (field_name, value) => {
    let datevalue;
    if (
      value?.field_name === "start_date" ||
      value?.field_name === "end_date"
    ) {
      datevalue = stringData(field_name.value);
    } else {
      datevalue =
        value?.field_name === "owner" ? field_name.name : field_name.value;
    }

    const updateData = {
      field_name: value?.field_name,
      value: datevalue,
      id: value?.id,
    };
    

    try {
      const result = await updateCustomerTaskPodTableData({
        token: token,
        data: updateData,
      }).unwrap();
      refetchCustomerData();
      setAlert({
        visible: true,
        message: result?.data?.original?.error || result?.message,
        type: result?.data?.original?.error ? "danger" : "success",
      });
    } catch (error) {
      setAlert({
        visible: true,
        message: "Error updating data!",
        type: "danger",
      });
    }
  };
  
  const [taskExpand, setTaskExpand] = useState(false);
  const TaskExpand=()=> setTaskExpand(!taskExpand);
  const isOpen = () => {};
  const TaskNavbarQuickFilter = (option) => {
    setQuiFilter(option);
    setRefreshKey(prevKey => prevKey + 1);
 };

 useEffect(()=>{
  if(refreshKey){
    refetchCustomerData();
  }
 
},[refreshKey])

const activityClosed=(val)=>{
  refetchCustomerData();
  
}

  let expandbtn = (
    <div className={`flex justify-end items-center mb-1`}>
      <div className="me-3">
        <NavBarTabs
          tabs={["Days", "Weeks", "Months"]}
          initialTab="Days"
          TabButtonClass="rounded px-3 py-1 cursor-pointer text-xs font-medium"
          activeStyle="bg-254155  text-white"
          inactiveStyle="bg-f1f6f9 text-434852"
          // onTabChange
        />
      </div>

      <button
        className="bg-white border border-E1E1E1 py-1 px-3"
        onClick={TaskExpand}
      >
        <i className="bi bi-arrows-fullscreen"></i>
      </button>
    </div>
  );

  return (
    <div className={`p-4`}>
      <div className={`flex flex-wrap`}>
        <div className={` ${taskExpand ? "hidden" : "w-[70%]"}  mt-3`}>
          <nav className="px-4">
            <div className="container ">
              <div className="flex justify-between ">
                <div className="tablemaindiv customerTaskTable">
                 
                  {isPopupOpen && (
                    <div className="absolute  top-0 left-0 w-full flex justify-center items-center"  >
                      <AddTaskModal
                        isOpen={isOpen}
                        closePopup={closePopup}
                        taskid={editData}
                        taskPod={taskPod}
                        key={refreshKey}
                       
                      />
                    </div>
                  )}
                  {editData && (
                    <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                      <EditTaskModel
                        isOpen={isOpen}
                        closePopup={closePopup}
                        taskid={editData}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="md:hidden">
                <button className="text-white focus:outline-none">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>


              <div>

               
                <CustomerListTable
                 key={refreshKey}
                  tableDetail={{
                    tableidclass: "fullscreen-table",
                    tableparentidclass: "tableparent",
                    tablename: (
                      <div>
                        {" "}
                        <span className="text-xl font-medium">
                          TaskPod 
                        </span>{" "}
                        <span className="font-medium">-</span>{" "}
                        <span className="text-sm">{taskPod}</span>{" "}
                      </div>
                    ),
                    tablenameclass: "font-medium text-16 text-203C50 capitalize",
                    tableSearch: true,
                    tableFilter: true,
                    tablequickfilter: (
                      <QuickFilter
                          options={[
                            "All",
                            "Due Today",
                            "Due This Week",
                            "Due Next Week",
                            "Created by me",
                            "Assigned To me",
                          ]}
                          placeholder={<span className="text-848687">Quick Filter</span>}
                          onSelect={TaskNavbarQuickFilter}
                       />
                    ),
                    tableResizer: true,
                    tableZoom: true,
                    tableheader: true,
                    dataactivity: true,
                    headeraddfield: false,
                    addbutton: (
                      <button
                        onClick={openPopup}
                        type="button"
                        className="text-2541558A hover:text-254155"
                      >
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    ),
                  }}
                  thead={taskPodHead}
                  tbody={customer}
                  tablePaginationData={setCustomerTablePagination}
                  fetchtableBodyData={fetchCustomerTable}
                  customerCommnData={customerCommnData || personTableData}
                  fetchPersonInTable={fetchPersonData}
                  TableFieldUpdate={customerTableFieldUpdate}
                  initialFilter={initialFilter}
                  handleNavigate={handleNavigate}
                  navigateNameCondition={["subject"]}
                  TextFieldElement={{
                    dropdown : (DataText,tableDetail) => {
                     
                      return(
                        <FirstLetters text={DataText} />
                      )
                    }
                  }}
                  activityClosed={activityClosed}
                />
              </div>
      
      
          </nav>
        </div>
        <div className={` ${taskExpand ? "w-[3%]" : "hidden"}`}>
          {taskExpand && expandbtn}
          <div className="no-scrollbar h-[500px] overflow-y-auto">
            {/* <LeftCard /> */}
          </div>
        </div>
        <div className={` ${taskExpand ? "w-[97%]" : "hidden"} ps-4`}>
          <div className="flex justify-between"></div>
          <div className="no-scrollbar h-[500px] overflow-y-auto">
            <div className="px-4 py-5 flex-auto bg-white">
              <p>Gantt Chart</p>
            </div>
          </div>
        </div>

        <div className={`${taskExpand ? "hidden" : "w-[30%]"} mt-2 ps-4`}>
          {!taskExpand && expandbtn}

          <GentChart className="w-100" />
        </div>
      </div>
    </div>
  );
};

export default LayoutHOC(TaskPod);
