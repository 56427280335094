import React, { useState , useEffect, Fragment} from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import { useDispatch } from "react-redux";
import OwnerDropDown from "../GlobalFeature/Fields/OwnerDropDown";
import { getCommonDataBasedOnId, saveData } from "../store/customer/CustomerSocialAddUpdateFunction";
import { CUST_DELEGATION } from "../../utils/constants";
import { ADD_UPDATE_CUSTOMER_DELEGATION } from "../../utils/constants";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import { useLazyFetchPersonInTableQuery } from "../../utils/customer/customerTable";
import { useParams } from "react-router-dom";

const AddUpdateDelegation = ({closePopup, editData ,handleSubmitData}) => {
  const { token } = useRequireAuth();

  const dispatch = useDispatch();
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const [errors, setErrors] = useState({});
  const { id: customerId } = useParams();
  const [show,setShow]=useState('');

  const [formData, setFormData] = useState({
    email: "",
    owner: 2,
  });

 const fetchTaskEditData = async (editData) => {
    const url = `${CUST_DELEGATION}${editData}`;
    const getData = await getCommonDataBasedOnId(url, token);
    if(getData?.data && getData?.data?.length>0){
      setFormData(getData?.data[0]);
    }
  };

  useEffect(() => {
    fetchTaskEditData(editData);
  }, [editData]);


  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validate = () => {
    let tempErrors = {};

    if (!formData?.email) {
      tempErrors.email = 'Email is required';
    }
    if (!formData?.owner) {
      tempErrors.owner = 'access is required';
    } 

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

 const handleSave=async(e)=>{
  e.preventDefault();
  if(!validate()){
    return false;
  }
  const customerData={
    ...formData,
    customer_id:customerId,
    company_id:parsedCompanyData.company_id,
    id:editData
  }

  const response=await saveData(ADD_UPDATE_CUSTOMER_DELEGATION,[customerData],token);

  if(response?.success===true){

    setAlert({
      visible: true,
      message: response?.message,
      type: "success",
    });
    handleSubmitData();
    closePopup()
  }else{
    setShow(response?.message);
    
  }
 }
 const hideAlert = () => {
  setAlert((prevState)=>({ ...prevState,visible: false }));
};

const handleInsert = (item) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    email: item,
  }));
};
 
const [fetchPersonInTable, { data: personTableData }] =
useLazyFetchPersonInTableQuery();

const fetchPersonData = (data) => {
fetchPersonInTable({ token:token, data: data });
};
const tableDetail = {
  tableidclass: "usermodelmember",
};
setTimeout(()=>{
  setShow('');
},4000)

  return (
    <Fragment>
    <FirstMessage
    type={alert.type}
    message={alert.message}
    visible={alert.visible}
    onHide={hideAlert}
  />
    <div className="fixed inset-y-0 z-[999] grid h-screen place-items-center justify-start">
      <div className="relative  py-3 mt-18 bg-white border border-E5ECF0 font-popns drop-shadow-lg  rounded-md w-500px overflow-hidden no-scrollbar ">
        <div className=" flex items-center justify-between p-4 md:p-3  border-b border-BECBD3 rounded-t">
          <div className="px-2"></div>
          <br />
          <h3 className="text-2xl text-2C495D">Customer Delegation</h3>

          <button
            onClick={closePopup}
            type="button"
            className="text-454545 bg-transparent hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <form
          onSubmit={handleSave}
          className="w-full lg:max-h-[70vh]  overflow-x-hidden  no-scrollbar"
        >
          <div className="basis-5/5 mb-3 px-6 pt-3">
            <label htmlFor="first_name" className="block text-lg">
              <span className="text-sm font-medium text-434852">
              Email
              </span>
              <span className=" text-red-500"> *</span>
              {errors.email && (
                      <span className="text-red-700">{errors.email}</span>
              )}
            </label>
            <OwnerDropDown
                tableidclass={tableDetail.tableidclass}
                fetchPersonInTable={fetchPersonData}
                DropData={personTableData}
                handleInsert={handleInsert}
                owner={formData?.email}
                fieldname={"owner"}
              />
              {show && (<span className="text-red-700">{show}</span>)}
          </div>
          <div className="basis-5/5 mb-6 px-6 pt-2 ">
            <label
              htmlFor="Designation"
              className="block mb-1 text-sm  text-434852"
            >
              Access <span className=" text-red-500 "> *</span>
              {errors.owner && (
                      <span className="text-red-700">{errors.owner}</span>
              )}
            </label>
            <select
              id={`owner`}
              name="owner"
              onChange={handleFieldChange}
              className={`form-select  border border-BFBFBF text-383838 text-md  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1`}
            >
              
              <option value="1" selected={formData?.owner === 1}>
                Owner
              </option>
              <option value="2" selected={formData?.owner === 2}>
                Contributor
              </option>
            </select>
          </div>
          <div className="flex justify-end py-4  modal-footer text-md w-full">
            <div className="px-6">
              <button
                onClick={closePopup}
                type="button"
                className="w-28 text-535252 bg-F4F4F4 hover:bg-eeeeee cancel-btn focus:ring-4 focus:ring-blue-300 font-semibold px-5 py-2 text-center rounded-sm"
              >
                CANCEL
              </button>
              {editData ? (
                   <button
                   type="submit"
                   className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold text-gray-900 border border-gray-200 py-2 px-5 ms-3 rounded-sm"
                 >
                   UPDATE
                 </button>
              ):( 
              <button
                type="submit"
                className="w-28 text-white bg-1D4469 hover:bg-20486F save-btn font-semibold text-gray-900 border border-gray-200 py-2 px-5 ms-3 rounded-sm"
              >
                SAVE
              </button>
            )}
             
            </div>
          </div>
        </form>
      </div>
    </div>
    </Fragment>
  );
};

export default AddUpdateDelegation;
