import React from 'react'
import useTabs from '../../../hooks/Others/useTabs';

const Tabs = ({ tabs, initialTab,TabButtonClass,activeStyle,inactiveStyle }) => {
    const [activeTab, handleTabClick] = useTabs(initialTab);

    

  return (
    tabs.map((tab) => (
        <button
          key={tab}
          className={` ${TabButtonClass} ${activeTab === tab ? activeStyle : inactiveStyle}`}
          onClick={() => handleTabClick(tab)}
        >
          {tab}
        </button>
    ))
  )
}

export default Tabs