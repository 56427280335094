const contacsocailtHeader = [
    {
        "social_link_id": "Social Type",
        "social_link_id_status": 1
    },
    {
        "social_link": "Link",
        "social_link_status": 1
    },
    
    {
        "action": "Action",
        "action_status": 1
    },
]


export default contacsocailtHeader