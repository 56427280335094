import React, { Fragment, useEffect, useState } from 'react'
import useDropdownPosition from '../../../hooks/Dropdown/useDropDownPosition';
import CreatePortalHOC from '../Fields/CreatePortalHOC';

const EmailHoverElement = ({ ParentId, Data, HoverActiveClass }) => {
    const [isOpenHover, setIsOpenHover] = useState(false)
    const { dropdownPosition, dropdownRef } = useDropdownPosition(true, ParentId?.tableidclass);
    const [dropdownCoord, setDropdownCoord] = useState(null)

    useEffect(() => {
        if (dropdownCoord !== null && !isOpenHover) {
            setIsOpenHover(true);
        }
    }, [dropdownCoord]);

    if (Data?.value === null || Data?.value === '') {
        return null
    }
    const getCreatePortalPosition = () => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect(); // Get the bounding rectangle
            return ({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            });
        }
    }
    const handleOpen = (event) => {
        event.stopPropagation()
        setDropdownCoord((prevState) => { return getCreatePortalPosition() })
    }

    const handleClose = () => {
        setDropdownCoord(null)
        setIsOpenHover(false)
    }
    //  ${dropdownPosition === 'bottom' ? 'top-full' : 'bottom-full'}
    return (
        <>
            <div className={`w-full absolute top-0 h-full ${HoverActiveClass}`} ref={dropdownRef} onMouseOver={handleOpen} onMouseLeave={handleClose}>
            </div>

            <CreatePortalHOC isOpen={isOpenHover} onClose={handleClose} position={dropdownCoord}  verticalDist={8}>
                <div className={`bg-white px-2 py-1.5 justify-center shadow-12 rounded-5px    inline-block `}   >
                    <div className="flex flex-row gap-x-2.5">
                        <div className="">
                            <h4>
                                <i className="bi bi-person-circle"></i>
                            </h4>
                        </div>
                        <div className="flex items-center">
                            <p className="text-2999BC text-xs font-medium ">
                                {Data && Data['name']}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-2.5">
                        <div className="">
                            <h4>
                                <i className="bi bi-envelope"></i>
                            </h4>
                        </div>
                        <div className="flex items-center">
                            <p className="text-F16136 text-xs font-medium">
                                {Data && Data['value']}

                            </p>
                        </div>
                    </div>
                </div>
                <div className="owner-triangle">
                    <i className="bi bi-caret-down-fill text-white text-md"></i>
                </div>
            </CreatePortalHOC>
        </>

    )
}

export default EmailHoverElement