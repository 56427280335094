import React from 'react'

const ToggleDensityMode = ({event,children}) => {
  return (
         <div>
            <button type='button' onClick={event} className='tooltip'>
                {children}

                <span className={`tooltiptext text-[10px] w-[85px] bottom-full left-1/2 ml-[-40px]`}>Density Mode</span>

            </button>
          </div>
  )
}

export default ToggleDensityMode