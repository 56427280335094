import React, { useRef, useEffect , useState } from 'react'
import useCustomFormValidation from "../hooks/ErrorHandling/useCustomFormValidation"; 
import { RESET_PASS_URL } from '../utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { VALID_TOKEN_URL } from "../utils/constants";
import FooterFixed from './FooterFixed';
import { useParams } from "react-router-dom";

const validationRules = {  
  password: {
    required: true,
    errorMessage: "Cannot be left blank",
  },
  password_confirmation: {
    required: true,
    errorMessage: "Cannot be left blank",
  }
};

const ResetPassword = () => { 
    const Navigate = useNavigate(); 
    const [error,setErrors]=useState(false);   
    const [nperror,setNperrors]=useState(false);
    const [succesmsg,setSuccErrors]=useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    
    const location = useLocation();
    const password_confirmd = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null); 
    const [matchPassword,setMatchPassword]=useState('');
    const { token, email } = useParams();   

    const [formData, setFormData] = useState({
      password: "",
      password_confirmation: ""
    });
  
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      validateField,
      isFormValid,
    } = useCustomFormValidation(formData, validationRules);    
    
    const handleInputChange = (event) => {
      handleChange(event);
      const { name, value } = event.target;
      if(name==='password_confirmation'){
        confirmPasswordMatch(value)
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  
    const confirmPasswordMatch = (value) => {
      if(formData.password !== value){
        setMatchPassword(formData.password !== value);
      }else{
        setMatchPassword('');
        //setIsButtonDisabled(false);
      }    
    };
      

    const handleButtomClick = async (event) => {      
      event.preventDefault();
      
      const formValid = isFormValid();      
      if(formValid===true && formData!==''){
        try {
          const response = await fetch(`${VALID_TOKEN_URL}${token}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          const data = await response.json();
          //console.log(data, 3333);return false;
          if(data.status =='error'){
            setSuccErrors(data.message);
          }else{
            try {
              const response = await fetch(RESET_PASS_URL, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...formData, token, email }),
              });
              const data = await response.json();
              if(data.status=='error') {
                setErrors(data.message);
              }
              if(data.status=='sucess') {
                setSuccErrors(data.message);
                const timer = setTimeout(() => {
                  Navigate('/'); 
                }, 1000);        
                return () => clearTimeout(timer);
              }
              if(data.message) {
                setNperrors(data.message);
              }
              //console.warn(data,"data here now")            
            } catch (error) {
              console.error('Error:', error);
              setErrorMessage('An error occurred. Please try again.');
            }
          }  
        }catch (error) {
          console.error("Error checking token:", error);
        }
      }    
    }; 


  return (
      <div className="bg-white">
        <div className='flex items-center justify-center min-h-screen'>
            <div className="px-2 py-8 border border-EDEDED rounded shadow-md mx-28 w-1/2 max-w-md"> 
              <div className="text-1D5D9B text-24 font-700 pb-5 px-5 text-center">Reset Password</div>
                <div className="space-y-5">
                  <form onSubmit={(e) => e.preventDefault()} className="flex flex-col py-2 px-5" id="ResetPassForm">
                    <label htmlFor="password" className="text-545B61 text-14 font-500">New Password</label>
                    <input 
                      className="border border-E4EDF3 rounded-md focus:outline-none focus:border-sky-700 w-full px-1" 
                      id="password"
                      name="password" 
                      type="password" 
                      placeholder=""
                      onChange={handleInputChange} 
                    />
                    <div 
                      className='h-6'
                    >
                      {(errors.password && !formData.password) && (
                        <span className="text-red-500 font-normal text-12">{errors.password}</span>
                      )}
                    </div>
                    <label htmlFor="password_confirmation" className="text-545B61 text-14 font-500">Confirm Password</label>
                    <input 
                      className="border border-E4EDF3 rounded-md focus:outline-none focus:border-sky-700 w-full px-1"
                      name="password_confirmation"
                      id="password_confirmation" 
                      type="password" 
                      placeholder=""
                      onChange={handleInputChange} 
                    />
                    <div 
                      className='h-6'
                    >
                      {(errors.password_confirmation && !formData.password_confirmation) ? (
                        <span className="text-red-500 font-normal text-12">{errors.password_confirmation}</span>
                      ) : matchPassword ? (
                        <span className="text-red-500 font-normal text-12">Passwords do not match.</span>
                      ) : null}
                    </div>
                    <button 
                      onClick={handleButtomClick} 
                      type="submit" 
                      className="bg-sky-700 w-full text-white font-bold py-1 rounded-md mb-3 hover:bg-sky-600"
                    >
                      Submit
                    </button>
                    <div 
                      className='h-6'
                    >
                      {succesmsg && (
                        <span className="mt-1 text-red-500 font-normal text-xs">{succesmsg}</span>
                      )}
                      {nperror && (
                        <span className="text-red-500 font-normal text-xs">{nperror}</span>
                      )}
                    </div>  
                  </form>
                  <div className="flex items-center">
                    <hr className="flex-grow border-t-2 border-gray-200"/>
                  </div>
                  <div className="flex items-center justify-between text-14 px-5">
                    <a href="/" className="font-bold text-red-500">Login</a>
                    <a href="/signup" className="font-bold text-red-500">Sign Up</a>
                  </div>
                </div>
            </div>  
      <div><FooterFixed /></div>
      </div>        
    </div>                
  );
}

export default ResetPassword