import React from 'react'

const SortingFilter = ({sortKey,sortOrder,header}) => {
  return (
    <button className="btn ">
    {sortKey === header.key ?
        (sortOrder === 'asc' ? 
                    <svg className='opacity-55' xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                <path d="M9.87932 5.80364C9.75974 5.75037 9.63029 5.72285 9.49938 5.72285C9.36847 5.72285 9.23903 5.75037 9.11945 5.80364L5.39295 7.4635C4.50105 7.92009 3.98811 6.97449 4.43684 6.3967C5.26954 5.32451 7.77559 2.60792 8.91913 1.37787C8.99327 1.2981 9.08306 1.23447 9.1829 1.19097C9.28274 1.14746 9.39048 1.125 9.49938 1.125C9.60829 1.125 9.71603 1.14746 9.81586 1.19097C9.9157 1.23447 10.0055 1.2981 10.0796 1.37787C11.2232 2.60792 13.7292 5.32451 14.5619 6.3967C15.0218 6.9888 14.4811 7.91157 13.6058 7.4635L9.87932 5.80364Z" fill="#B4C0C8"/>
                    </svg> 
                    : 
                    (sortOrder === 'desc' ?  
                                <svg className='opacity-55' xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                                        <path d="M9.12178 12.1946C9.24136 12.2478 9.37081 12.2754 9.50172 12.2754C9.63262 12.2754 9.76207 12.2478 9.88165 12.1946L13.6081 10.5347C14.5 10.0781 15.013 11.0237 14.5643 11.6015C13.7316 12.6737 11.2255 15.3903 10.082 16.6203C10.0078 16.7001 9.91804 16.7637 9.8182 16.8072C9.71836 16.8508 9.61062 16.8732 9.50172 16.8732C9.39281 16.8732 9.28507 16.8508 9.18523 16.8072C9.0854 16.7637 8.9956 16.7001 8.92146 16.6203C7.77792 15.3903 5.27187 12.6737 4.43917 11.6015C3.97933 11.0094 4.52002 10.0866 5.39529 10.5347L9.12178 12.1946Z" fill="#B4C0C8"/>
                                </svg> 
                            : 
                                                    <svg className='opacity-55' xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                                        <path d="M9.87932 5.80364C9.75974 5.75037 9.63029 5.72285 9.49938 5.72285C9.36847 5.72285 9.23903 5.75037 9.11945 5.80364L5.39295 7.4635C4.50105 7.92009 3.98811 6.97449 4.43684 6.3967C5.26954 5.32451 7.77559 2.60792 8.91913 1.37787C8.99327 1.2981 9.08306 1.23447 9.1829 1.19097C9.28274 1.14746 9.39048 1.125 9.49938 1.125C9.60829 1.125 9.71603 1.14746 9.81586 1.19097C9.9157 1.23447 10.0055 1.2981 10.0796 1.37787C11.2232 2.60792 13.7292 5.32451 14.5619 6.3967C15.0218 6.9888 14.4811 7.91157 13.6058 7.4635L9.87932 5.80364Z" fill="#B4C0C8"/>
                                                        <path d="M9.12178 12.1946C9.24136 12.2478 9.37081 12.2754 9.50172 12.2754C9.63262 12.2754 9.76207 12.2478 9.88165 12.1946L13.6081 10.5347C14.5 10.0781 15.013 11.0237 14.5643 11.6015C13.7316 12.6737 11.2255 15.3903 10.082 16.6203C10.0078 16.7001 9.91804 16.7637 9.8182 16.8072C9.71836 16.8508 9.61062 16.8732 9.50172 16.8732C9.39281 16.8732 9.28507 16.8508 9.18523 16.8072C9.0854 16.7637 8.9956 16.7001 8.92146 16.6203C7.77792 15.3903 5.27187 12.6737 4.43917 11.6015C3.97933 11.0094 4.52002 10.0866 5.39529 10.5347L9.12178 12.1946Z" fill="#B4C0C8"/>
                                                    </svg>
                    )
        )
        : 
                <svg className='opacity-55' xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                    <path d="M9.87932 5.80364C9.75974 5.75037 9.63029 5.72285 9.49938 5.72285C9.36847 5.72285 9.23903 5.75037 9.11945 5.80364L5.39295 7.4635C4.50105 7.92009 3.98811 6.97449 4.43684 6.3967C5.26954 5.32451 7.77559 2.60792 8.91913 1.37787C8.99327 1.2981 9.08306 1.23447 9.1829 1.19097C9.28274 1.14746 9.39048 1.125 9.49938 1.125C9.60829 1.125 9.71603 1.14746 9.81586 1.19097C9.9157 1.23447 10.0055 1.2981 10.0796 1.37787C11.2232 2.60792 13.7292 5.32451 14.5619 6.3967C15.0218 6.9888 14.4811 7.91157 13.6058 7.4635L9.87932 5.80364Z" fill="#B4C0C8"/>
                    <path d="M9.12178 12.1946C9.24136 12.2478 9.37081 12.2754 9.50172 12.2754C9.63262 12.2754 9.76207 12.2478 9.88165 12.1946L13.6081 10.5347C14.5 10.0781 15.013 11.0237 14.5643 11.6015C13.7316 12.6737 11.2255 15.3903 10.082 16.6203C10.0078 16.7001 9.91804 16.7637 9.8182 16.8072C9.71836 16.8508 9.61062 16.8732 9.50172 16.8732C9.39281 16.8732 9.28507 16.8508 9.18523 16.8072C9.0854 16.7637 8.9956 16.7001 8.92146 16.6203C7.77792 15.3903 5.27187 12.6737 4.43917 11.6015C3.97933 11.0094 4.52002 10.0866 5.39529 10.5347L9.12178 12.1946Z" fill="#B4C0C8"/>
                </svg>
    }
  </button>
  )
}

export default SortingFilter