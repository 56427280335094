import React, { Fragment } from 'react';
import useFirstLetters from '../../../hooks/LetterShow/useFirstLetters';

const FirstLetters = ({ text }) => {
  const firstLetters = useFirstLetters(text);
  
  return <Fragment>{firstLetters && <span className='uppercase px-2 py-1 border w-[40px] flex justify-center'>{firstLetters}</span> } </Fragment>;
};

export default FirstLetters;
