import React, { useRef, useState } from 'react'
import { checkValidateData } from '../utils/validate'; 
import { LOGIN_URL } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { addUser,updateUser } from '../utils/userSlice';
import { useDispatch } from 'react-redux';  
import useRedirectIfLoggedIn from '../utils/useRedirectIfLoggedIn';
import userImage from '../assets/images/user.webp';
import checkWeb from '../assets/images/check.webp';
import logo from '../assets/images/logo.webp';
import glogo from '../assets/images/glogo.webp'
import FooterFixed from './FooterFixed';

const Login = () => {
 
   // useRedirectIfLoggedIn();
    const dispatch = useDispatch();  
    const Navigate = useNavigate();     
    const email = useRef(null);
    const password = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null); 

  const handleButtomClick = async () => {
    const message = checkValidateData(
      email.current.value,
      password.current.value
    );

    setErrorMessage(message);
    if (message) return;  

    const formData = {
      email: email.current.value,
      password: password.current.value,
    };

    try {
      const response = await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.error) {
        let loginerr = document.getElementById("loginerror");
        loginerr.innerText = data.error;
        return; 
      }
      
      dispatch(addUser(data?.data));
      const companyValue = data?.data?.company_set;
      if (companyValue === 1) {
        dispatch(updateUser(data?.data));
        Navigate("/dashboardMain");
      } else {
        Navigate("/dashboardMain");
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again.');
    }
  }; 


  return (
      <div className="bg-white">
        <div className='flex items-center justify-center min-h-screen'>
          <div className="px-2 py-8 border border-EDEDED rounded shadow-md mx-28 w-1/2 max-w-md">                  
                  {/* <div className="flex justify-center py-5">
                  <div className="text-1D5D9B text-3xl font-bold pb-2 px-5">Zinancial</div>
                  </div> */}
                <div className="text-1D5D9B text-24 font-700 pb-5 px-5 text-center">Sign In</div>
                <div id="loginerror" className='text-center text-red-500 text-xxs'></div>

                <div className="space-y-5">
                    <form onSubmit={(e) => e.preventDefault()} className="flex flex-col space-y-3 py-2 px-5" id="loginForm">
                    <label htmlFor="email" className="text-545B61 text-14 font-500">Email</label>
                        <input  ref={email} className="border-1 border-E4EDF3 rounded-md focus:outline outline-1D5D9B focus: border-1 focus:border-sky-700 w-full px-1" type="email"
                            id="email" placeholder="" />
                       <label htmlFor="password" className="text-545B61 text-14 font-500">Password</label>
                        <input ref={password} className=" border-1 border-E4EDF3 rounded-md focus:outline outline-1D5D9B focus:border-sky-700 w-full px-1" id="password"
                            name="password" type="password" placeholder="" />
                        <a href="/forget_password" className="text-F16136 text-12 hover:cursor-pointer">Forgot Password ?</a>
                        {errorMessage && <p className="text-red-red text-xxs">{errorMessage}</p>}
                        <button onClick={handleButtomClick} type="submit" className="bg-sky-700 w-full text-white font-bold py-1 rounded-md mb-3 hover:bg-sky-600">Sign
                            In</button>
                    </form> 
                    <div className="flex items-center">
                        <hr className="flex-grow border-t-2 border-gray-200"/>
                    </div>
                    {/* <button className="flex px-2 py-1 bg-blue-400 w-full text-white font-bold rounded-md ">
                      <img
                            src={glogo} alt="google"/>
                        <div className="text-center w-full py-1">Sign In With Google</div>
                    </button> */}
                    <div className="text-center text-14">Don't have an account yet? <a href="/signup" className="font-700 text-red-500">Sign Up Now.</a></div>
                    <div className="text-center text-14">By continuing, you are indicating that you have read and agree to the
                        <a className="text-sky-700 text-14 font-bold"> Terms of Use</a> and <a className="text-sky-700 font-bold">Privacy
                            Policy.</a>
                    </div>
                    
                </div>
            </div>
            <div><FooterFixed /></div>
        </div>        
      </div>                
  );
}

export default Login