


import { useState, useEffect, useCallback } from 'react';

const useSorting = (initialData, headers, initialOrder) => {
  const [sortedData, setSortedData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [combinedFilteredData, setCombinedFilteredData] = useState(initialData);
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(initialOrder);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchHeaderKey, setSearchHeaderKey] = useState(null);
  const [columnSearchTerms, setColumnSearchTerms] = useState({});
  const [isSorting, setIsSorting] = useState(false);

  useEffect(() => {
    setSortedData(initialData);
    setFilteredData(initialData);
    setCombinedFilteredData(initialData);
  }, [initialData]);


  // const { data: customerTableALL, refetch: refetchCustomerData } =
  // useFetchCustomerTableDataQuery({
  //   companyId: parsedCompanyData.company_id,
  //   owner:  parsedCompanyData.owner,
  //   pagination: `${1}`,
  //   token: token,
  // });

  // useEffect(() => {
  //   refetchCustomerData()
  // },[headers,initialOrder])

  // useEffect(() => {
  //   if (customerTableALL) {
  //     setCustomer(customerTableALL?.data?.data);
  //     const { data: omitData, ...restData } = customerTableALL?.data;
  //     setInitialFilter(restData);
  //   }
  // }, [customerTableALL, customerTablePagination]);

  const sortData = useCallback((key) => {
    setIsSorting(true);

    let newSortOrder;
    if (sortOrder === 'asc') {
      newSortOrder = 'desc';
    } else {
      newSortOrder = 'asc';
    }

    let sorted;
    // if (newSortOrder === 'initial') {
    //   sorted = combinedFilteredData; // Use combined filtered data
    // } else {
    //   sorted = [...combinedFilteredData].sort((a, b) => {
    //     const valueA = a[key]?.value ?? '';
    //     const valueB = b[key]?.value ?? '';

    //     if (typeof valueA === 'string' && typeof valueB === 'string') {
    //       return valueA.localeCompare(valueB);
    //     } else {
    //       return valueA - valueB;
    //     }
    //   });

    //   if (newSortOrder === 'desc') {
    //     sorted.reverse();
    //   }
    // }
    sorted = combinedFilteredData;

    setSortedData(sorted);
    setSortKey(key);
    setSortOrder(newSortOrder);
    setIsSorting(false);
  }, [combinedFilteredData, sortKey, sortOrder]);

  const handleSearch = useCallback((term, headerKey = null) => {
    setSearchTerm(term);
    setSearchHeaderKey(headerKey);
    setSortOrder('asc');
  }, []);


  const handleColumnSearch = useCallback((term, key) => {
    setColumnSearchTerms((prev) => ({
      ...prev,
      [key]: term,
    }));
  }, []);

  const clearAllFilters = useCallback(() => {
    setSearchTerm('');
    setSearchHeaderKey(null);
    setColumnSearchTerms({});
  }, []);

  useEffect(() => {
    let filtered = initialData;

    if (searchTerm.trim() !== '') {
      filtered = filtered.filter(item => {
        if (searchHeaderKey) {
          return item[searchHeaderKey]?.value?.toLowerCase().includes(searchTerm?.toLowerCase());
        } else {
          return Object.values(item)?.some(value => {
            return typeof value?.value === 'string' && value?.value?.toLowerCase().includes(searchTerm?.toLowerCase());
          });
        }
      });
    }

    Object.keys(columnSearchTerms).forEach(key => {
      const term = columnSearchTerms[key];
      if (term.trim() !== '') {
        filtered = filtered.filter(item => item[key]?.value?.toLowerCase().includes(term.toLowerCase()));
      }
    });

    setFilteredData(filtered);
    setCombinedFilteredData(filtered);
    setSortedData(filtered);

  }, [initialData, searchTerm, searchHeaderKey, columnSearchTerms]);






  return {
    sortedData,
    sortData,
    sortKey,
    sortOrder,
    handleSearch,
    handleColumnSearch,
    clearAllFilters,
    headers,
  };
};

export default useSorting;
