const contactHeader = [
    {
        "name": "Name",
        "name_status": 1
    },
    
    {
        "contact_email": "Email",
        "email_status": 1
    },
    {
        "contact_no": "Contact Number",
        "contact_no_status": 1
    },
   
    {
        "action": "Action",
        "action_status": 1
    },
]


export default contactHeader