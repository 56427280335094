import { createSlice } from "@reduxjs/toolkit";

const initialUserData = JSON.parse(localStorage.getItem("myUserData"));
const initialState = initialUserData ? initialUserData : null; 

const userSlice = createSlice({
  name: "user",
  initialState: initialState,

  reducers: {
    addUser: (state, action) => {
      const addUserData = action.payload;

      localStorage.setItem("myUserData", JSON.stringify(addUserData));
      return addUserData;
    },
    removeUser: (state, action) => {
      localStorage.removeItem("myUserData");
      return null;
    }, 
    updateUser: (state, action) => {
      const addUserData = action.payload;
      localStorage.setItem("myUserData", JSON.stringify(addUserData));
      return addUserData;
    },
    addParticularCompany: (state, action) => {
      const addCompanyData = action.payload;
      localStorage.setItem("company_data", JSON.stringify(addCompanyData));
      return addCompanyData;
    },
    addNotesData:(state,action)=>{
      const addCompanyData = action.payload;
     
      localStorage.setItem("notes_data", JSON.stringify(addCompanyData));
      return addCompanyData;
    },
    getUserTokeData:(state,action)=>{
      const addCompanyData = action.payload;
      const getuserToken=JSON.parse(localStorage.getItem("myUserData"))
      const userToken= getuserToken ? getuserToken : null; 
      return userToken;
    }
  
  },
});

export const { addUser, removeUser, resetUserData ,updateUser,addParticularCompany,getParticularCompany,addNotesData,getUserTokeData} = userSlice.actions;

export default userSlice.reducer;
