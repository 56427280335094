
import { createSlice , createAsyncThunk} from '@reduxjs/toolkit';
import { saveData ,deleteData} from '../../components/store/customer/CustomerSocialAddUpdateFunction';
import { CUSTOMER_ADRESSS_ADD_UPDATE ,CUSTOMER_ADDRESS_DATA_DELETE} from '../constants';

export const saveCustomerAddress = createAsyncThunk(
  "customerAddresses/saveCustomerAddress",
  async ({ updatedAddresses, token }, thunkAPI) => {
    
    try {
      const response = await saveData(
        CUSTOMER_ADRESSS_ADD_UPDATE,
        updatedAddresses,
        token
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);
export const deleteAddresssAsync = createAsyncThunk(
  "customerAddresses/deleteAddresssAsync",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const url =`${CUSTOMER_ADDRESS_DATA_DELETE}${id}`;
      const response = await deleteData(url,token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addressSlice = createSlice({
  name: 'customerAddresses',
  initialState: {
    addresses: [
      {
       
        address_type: '',
        city: '',
        phone1: '',
        point_of_contact: '',
        state: '',
        phone2: '',
        address: '',
        zip_code: '',
        primary_email: '',
        country_id: '',
        secondry_email: '',
      },
    ],
    loading: false,
    error: null,
    showMessage: false,
    message: null,
  },
  reducers: {
    addAddress: (state) => {
      state.addresses.unshift({ ...state.addresses });
    },
    deleteAddress: (state, action) => {
      state.addresses.splice(action.payload, 1);
    },
    updateAddress: (state, action) => {
      const { index, name, value } = action.payload;
      state.addresses[index][name] = value;
    },
    setAddresses: (state, action) => {
      state.addresses = action.payload;
    },
    removeAddressAtIndex: (state, action) => {
      const indexToRemove = action.payload;
      state.addresses = state.addresses.filter(
        (_, index) => index !== indexToRemove
      );
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCustomerAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.showMessage = false;
        state.message = null;
      })
      .addCase(saveCustomerAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(saveCustomerAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        state.showMessage = false;
        state.message = action.payload || action.error.message;
      })
      .addCase(deleteAddresssAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAddresssAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.showMessage = true;
      })
      .addCase(deleteAddresssAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        state.message = action.payload || action.error.message;
        state.showMessage = false;
      });
  },
});

export const { addAddress, deleteAddress, updateAddress ,setAddresses ,removeAddressAtIndex ,hideMessage } = addressSlice.actions;

export default addressSlice.reducer;
