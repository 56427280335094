import React, { Fragment, useState } from 'react';
import LayoutHOC from "../LayoutHOC";
import { Link } from 'react-router-dom';

const Importcha = () => {
    const [activeTab, setActiveTab] = useState('Chartered Accountants');
    const [isOpen, setIsOpen] = useState(true);

    const tabs = [
        'Bank Transaction',
        'App Transaction',
        'Receipts',
        'Reconcil',
        'Rules',
        'Chart of Accountants'
    ];

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        // if (tab === 'Chartered Accountants') {
        //     setIsOpen(true);
        // } else {
        //     setIsOpen(false);
        // }
    };

    return (
        <Fragment>
            <div className="flex-1 mt-7">
                <h5 className="font-Vietnam text-2xl font-medium px-4 pb-3">Chart Of Accountants</h5>
                <div className="px-4 font-poppins text-base ">
                    <div className="flex py-1 px-7 gap-x-10 bg-white ">
                        {tabs.map((tab) => (
                            <div
                                key={tab}
                                onClick={() => handleTabClick(tab)}
                                className={`cursor-pointer hover:border-b-2 border-2C495D pb-1 ${
                                    activeTab === tab ? 'border-b-2 border-2C495D font-500 text-2C495D' : 'text-848687 font-normal'
                                }`}
                            >
                                {tab}
                            </div>
                        ))}
                    </div>
                </div>

                {isOpen && (
                    <div className="w-[-webkit-fill-available] fixed inset-y-0 inset-x-150 z-[999] grid h-screen place-items-center bg-opacity-0 transition-opacity duration-300">
                        <div className="relative   font-poppins leading-relaxed antialiased shadow-1 mt-40">
                            
                            <div className='bg-white border border-E5ECF0 p-6 w-[886px]'>

                                            <div className=" mb-2 bg-E6ECF1">           
                                                <div className="flex justify-between py-2 px-2">            
                                                    <div className="font-500 text-2C495D ">Import Chart of Account</div>            
                                                    <div className="text-F16136 font-500 border-l-2 border-CFCFCF">         
                                                        <button className="mx-5">Set Default</button>           
                                                    </div>          
                                                </div>          
                                            </div>          

                                            <form className="py-4 space-x-6">           
                                                <div className="flex justify-between">          
                                                    <label className="block">           
                                                        <input type="file" className="block w-full text-md text-slate-500 border border-BFBFBF          
                                                            file:mr-4 file:py-2 file:px-4           
                                                            file:border-0           
                                                            file:text-sm            
                                                            file:bg-EFEFEF file:text-535252         
                                                            hover:file:bg-violet-100            
                                                        " />            
                                                    </label>            
                                                    <button className="bg-1D4469 text-white hover:bg-1D4469 hover:text-F16136 text-1D4469 border-2 border-DCDCDC text-xl py-1 px-8">            
                                                        Import          
                                                    </button>           
                                                </div>          
                                            </form>         
                                            <div className="text-xl py-3 border-t border-t-E6EFF3">Select Industry</div>                            
                                            <div className="pb-4 flex flex-wrap gap-6 justify-center text-center text-xl text-1D4469">          
                                                <Link to="/asset_chart_of_account" className="bg-E6ECF1 h-[74px] w-[249px] flex justify-center items-center text-lg cursor-pointer hover:cursor-wai">Real Estate</Link>          
                                                <Link to="/asset_chart_of_account"  className="bg-E6ECF1 h-[74px] w-[249px] flex justify-center items-center text-lg cursor-pointer hover:cursor-wai">Manufacturing</Link>           
                                                <Link to="/asset_chart_of_account"  className="bg-E6ECF1 h-[74px] w-[249px] flex justify-center items-center text-lg cursor-pointer hover:cursor-wai">Restaurants</Link>         
                                                <Link to="/asset_chart_of_account"  className="bg-E6ECF1 h-[74px] w-[249px] flex justify-center items-center text-lg cursor-pointer hover:cursor-wai">Construction</Link>            
                                                <Link to="/asset_chart_of_account"  className="bg-E6ECF1 h-[74px] w-[249px] flex justify-center items-center text-lg cursor-pointer hover:cursor-wai">Healthcare and Medical</Link>          
                                                <Link to="/asset_chart_of_account"  className="bg-E6ECF1 h-[74px] w-[249px] flex justify-center items-center text-lg cursor-pointer hover:cursor-wai">E commerce</Link>          
                                                <Link to="/asset_chart_of_account"  className="bg-E6ECF1 h-[74px] w-[249px] flex justify-center items-center text-lg cursor-pointer hover:cursor-wai">Legal and Law</Link>           
                                                <Link to="/asset_chart_of_account"  className="bg-E6ECF1 h-[74px] w-[249px] flex justify-center items-center text-lg cursor-pointer hover:cursor-wai">Non-Profit Organizations</Link>            
                                            </div>          

                            </div>

                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default LayoutHOC(Importcha);
