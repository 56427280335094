import React, { useState, useEffect, Fragment } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import { useSelector } from "react-redux";
import { DELETE_CONTACT_API, SAVE_CONTACT,COMPANY_EDIT_CONTACT } from "../../utils/constants";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Shimmer from "../Shimmer";
import { getCommonDataBasedOnId } from "../store/customer/CustomerSocialAddUpdateFunction";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";

const validationSchema = Yup.object({
  contacts: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      contactNo: '',
      designation: '',
    })
  ),
});

const initialValues = {
  contacts: [
    { name: '', email: '', contactNo: '', designation: '' },
  ],
};
const EditContact = () => {
  const disabledColumnStyle = {
    pointerEvents: 'none',
    opacity: 0.5, 
    backgroundColor: '#f0f0f0',
  };
  const {token} = useRequireAuth();
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [contSubmitted, setConSubmitted] = useState(false);
  const [apiContacts, setApiContacts] = useState([]);
  const [success,setSuccess]=useState('');
  const [refreshKey, setRefreshKey] = useState(0);

  const fetchData=async(id)=>{
    try {
      const url = `${COMPANY_EDIT_CONTACT}?profile_id=${id}`;
      const response=await getCommonDataBasedOnId(url,token);
      
      setApiContacts(response)
     
    } catch (error) {
      
    }
  }

  useEffect(()=>{fetchData(parsedCompanyData.company_id)},[parsedCompanyData.company_id])
  const [contacts, setContacts] = useState([
    {
      id: "",
      name: "",
      designation: "",
      contactNo: "",
      email: "",
      isRemovable: false,
    },
  ]);

  const handleHardRemoveContact = async (id) => {
    try {
      const response = await fetch(`${DELETE_CONTACT_API}/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (!response.ok) {
        throw new Error("Failed to delete contact");
      }else{
        setAlert({
          visible: true,
          message: "Contact person deleted successfully.",
          type: "success",
        });
      }
      setApiContacts((prevContacts) =>
        prevContacts.filter((contact) => contact.id !== id)
      );
    } catch (error) {
      console.error("Error deleting contact:", error.message);
    }
  };

  const handleAddMoreInputChanged = (e,rowIndex, fieldName) => {
    const { value } = e.target;
    setApiContacts(prevFormData => {
      const updatedFormData = [...prevFormData];
      updatedFormData[rowIndex][fieldName] = value;
      return updatedFormData;
    });
  };

  
  const handleSubmit = async (values, { setSubmitting }) => {
 
    try {
     
      const response = await fetch(SAVE_CONTACT, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company_profile_id: parsedCompanyData.company_id,
          contacts: values,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if(data?.status===true){
        fetchData( parsedCompanyData.company_id);
        setAlert({
          visible: true,
          message: response?.message || "Contact person added successfully.",
          type: "success",
        });
        setRefreshKey(prevKey => prevKey + 1);
      }
    
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };
  

  return (
    <>
      <div className="text-slate-500 text-20 font-normal font-['Be Vietnam']capitalize py-3">Contact Person</div>    
      <div className="Contact-Person bg-white pt-5  py-8">
        <FirstMessage
          type={alert.type}
          message={alert.message}
          visible={alert.visible}
          onHide={hideAlert}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          key={refreshKey}
        >
          {(formik) => (
            <Form>
              

              <div className="relative  px-4 py-3 pt-1">
                <table className="w-full  text-left rtl:text-right  font-poppins  text-neutral-600 text-lg font-normal">
                  <thead className="text-neutral-600  font-normal ">
                    <tr className="bg-white ">
                      <th
                        scope="col"
                        className="px-6 py-4 pb-0 mb-0  text-535252 text-14 font-500"
                      >
                        Name*
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 pb-0 mb-0  text-535252 text-14  font-500"
                      >
                        Email Address*
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 pb-0 mb-0 text-535252 text-14  font-500"
                      >
                        Contact No.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 pb-0 mb-0  text-535252 text-14  font-500"
                      >
                        Designation
                        
                      
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 pb-0 mb-0  text-535252 text-14  font-500"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
        
                  <tbody>
                  
                    {Array.isArray(apiContacts) &&
                      apiContacts.map((contact, index) => (
                        <tr key={index} className="bg-white" >
                          <td className="px-6 py-2" style={disabledColumnStyle}>
                            <Field
                              type="text"
                              onChange={(e) =>
                                handleAddMoreInputChanged(e, index,'name')
                              }
                              name={`contacts[${contact.name}]`}
                              value={contact.name||''}
                              className="border border-gray-300 text-gray-900 text-sm block w-full p-2.5 rounded-5px"
                            />
                          
                          </td>
                          <td className="px-6 py-2" style={disabledColumnStyle}>
                            <Field
                              type="text"
                              name={`contacts[${contact.email_address}]`}
                              onChange={(e) =>
                                handleAddMoreInputChanged(e, index,'email_address')
                              }
                              value={contact.email_address||''}
                              className="border border-gray-300 text-gray-900 text-sm block w-full p-2.5 rounded-5px"
                            />
                          
                          
                          </td>
                          <td className="px-6 py-2" style={disabledColumnStyle}>
                            <Field
                              type="text"
                              name={`contacts[${contact.contact_no}]`}
                              value={contact.contact_no||''}
                              onChange={(e) =>
                                handleAddMoreInputChanged(e, index,'contact_no')
                              }
                              className="border border-gray-300 text-gray-900 text-sm block w-full p-2.5 rounded-5px"
                            />
                          
                          </td>
                          <td className="px-6 py-2" style={disabledColumnStyle}>
                            <Field
                              type="text"
                              name={`contacts[${contact.designation}]`}
                              value={contact.designation||''}
                              onChange={(e) =>
                                handleAddMoreInputChanged(e, index,'designation')
                              }
                              className="border border-gray-300 text-gray-900 text-sm block w-full p-2.5 rounded-5px"
                            />
                          
                          </td>
                          <td className="py-2">
                            <button
                              onClick={() => handleHardRemoveContact(contact.id)}
                              type="button"
                              class="text-red-500 font-semibold"
                              fdprocessedid="nv0vc"
                            >
                              <i className="bi bi-trash3 text-18 text-F84545 font-600 ms-4"></i>                            
                            </button>                        
                          </td>
                        </tr>
                      ))}
            
                    {!apiContacts && <Shimmer width="100%" height="100px" />}
                    {contacts.map((contact, index) => (
                      <Fragment>
                      <tr key={index} className="bg-white ">
                        <td className="px-6 pt-2">
                          <Field
                            type="text"
                            name={`contacts[${index}].name`}
                            className=" border border-gray-300 text-gray-900 text-sm  block w-full p-2.5 rounded-5px"
                          />
                         
                        </td>
                        <td className="px-6 pt-2">
                          <Field
                            type="text"
                            name={`contacts[${index}].email`}
                            className=" border border-gray-300 text-gray-900 text-sm  block w-full p-2.5 rounded-5px"
                          />
                          
                        </td>
                        <td className="px-6 pt-2">
                          <Field
                            type="text"
                            name={`contacts[${index}].contactNo`}
                            className=" border border-gray-300 text-gray-900 text-sm  block w-full p-2.5 rounded-5px"
                          />                        
                        </td>

                        <td className="px-6 pt-2">
                          <Field
                            type="text"
                            name={`contacts[${index}].designation`}
                            className=" border border-gray-300 text-gray-900 text-sm  block w-full p-2.5 rounded-5px"
                          />                        
                        </td>
                        <td className="flex items-center pt-2">  
                          <button
                            type="submit"
                            className="px-6 py-1 text-base rounded font-normal bg-1D4469 text-white focus:outline-none me-2"
                            disabled={formik.isSubmitting}
                          >
                            {formik.isSubmitting ? "Submitting..." : "Save"}
                          </button>
                          
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6">
                          <ErrorMessage
                            name={`contacts[${index}].name`}
                            component="div"
                            className="text-red-500 text-xxs italic"
                          />
                        </td>
                        <td className="px-6">
                          <ErrorMessage
                            name={`contacts[${index}].email`}
                            component="div"
                            className="text-red-500 text-xxs italic"
                          />
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
          </Form>
        )}
      </Formik>
      </div>
    </>
  );
};

export default EditContact;
