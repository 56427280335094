const taskPodHeader = [
    {
        "subject": "Task Name",
        "subject_status" : "1"
    },
   
    {
        "due_date":"Due Date",
        "due_date_status":1
     },
    {
        "owner": "Assignee",
        "assignee_status" : "1"
    },
   
    {
        "status" : "Status",
        "status_status" : "1"
    },
    {
        "priority": "Priority",
        "priority_status" : "1"
    },
   
    {
        "activity": "Activity",
        "activity_status": 1,
    },
    {
        "description": "Description",
        "description_status": 1,
    }

    
    
]
export default taskPodHeader;