import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  getDataIdBase,
  getCommonDataBasedOnId,
  saveTasksData,
} from "../../components/store/customer/CustomerSocialAddUpdateFunction";
import {
  CUSTOMER_TASK_ADD_UPDATE,
  CUSTOMER_NOTES_ADD_UPDATE,
  CUSTMOER_NOTES_DATA_DELETE,
  CUSTOMER_TASK_DATA_GET,
} from "../constants";

export const saveTasks = createAsyncThunk(
  "tasks/saveTasks",
  async ({ taskDatas, token }, thunkAPI) => {
    try {
      const response = await saveTasksData(
        CUSTOMER_TASK_ADD_UPDATE,
        [taskDatas],
        token
      );
      if (response.status === true) {
        const url = `${CUSTOMER_TASK_DATA_GET}${taskDatas.customer_id}`;
        const responseData = await getCommonDataBasedOnId(url, token);

        thunkAPI.dispatch(setCustomerTask(responseData));
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);

export const fetchTask = createAsyncThunk(
  "tasks/fetchTask",
  async ({ customerid, token }, thunkAPI) => {
    try {
      const response = await getDataIdBase(
        `${CUSTOMER_TASK_ADD_UPDATE}${customerid}`,
        token
      );
      if (response?.status === true) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);

export const deleteTask = createAsyncThunk(
  "tasks/deleteTask",
  async ({ id, token }, thunkAPI) => {
    try {
      const response = await deleteData(
        `${CUSTMOER_NOTES_DATA_DELETE}${id}`,
        token
      );

      if (response?.status === true) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue("Data is not an array or is empty");
    }
  }
);

const customerTasksSlice = createSlice({
  name: "tasks",
  initialState: {
    loading: false,
    tasks: [],
    error: null,
    showMessage: false,
    message: null,
  },
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    setCustomerTask: (state, action) => {
      state.tasks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTask.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(fetchTask.fulfilled, (state, action) => {
        state.loading = false;
        state.tasks = action.payload;
        state.error = "";
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(fetchTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveTasks.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(saveTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.tasks = action.payload;
        state.error = "";
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(saveTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteTask.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.showMessage = true;
        state.message = action.payload.message;
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { showMessage, hideMessage, setCustomerTask } =
  customerTasksSlice.actions;
export default customerTasksSlice.reducer;
