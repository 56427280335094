import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const CreatePortalHOC = ({ isOpen, onClose, children,dialog, position={}, noBackground , noBoxShadow,verticalDist=0 }) => {
  
  //console.log(position, 3000)
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (!event.target.closest('.dropdown-content')) {
  //       // onClose();
  //     }
  //   };
 
  //   document.addEventListener('click', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [onClose]);
  if (dialog && isOpen) {
    return ReactDOM.createPortal(
      <div
        className="dropdown-content"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          zIndex: 9999,
          padding: '10px',
          display: 'flex',
          width:"100vw",
          height:"100vh",
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
          zIndex: 9998, // Slightly less than the dialog zIndex
          transition: 'opacity 0.3s ease-in-out',
          opacity: dialog ? 1 : 0, // Fade effect
        }}
      >
        {children}
      </div>,
      document.body
    );
  }


  if (!isOpen || position===null) 
    
    return null;
  
    // if (padding && isOpen) {
    //   return ReactDOM.createPortal(
    //     <div
    //      className="dropdown-content"
    //   style={{
    //     position: 'absolute',
    //     top: `${position.top+8}px`,
    //     left: `${position.left}px`,
    //     borderRadius: `${position.borderRadius}px`,
    //     backgroundColor: 'white',
    //     boxShadow: '0px 4px 10px 5px rgba(0, 0, 0, 0.1)',
    //     zIndex: 9999,
    //     display:"flex"
    //     }}
    //     >
    //       {children}
    //     </div>,
    //     document.body
    //   );
    // }
    
  return ReactDOM.createPortal(
    <div
      className="dropdown-content"
      style={{
        position: 'absolute',
        top: `${position.top+(verticalDist)}px`,
        left: `${position.left}px`,
        borderRadius: `${position.borderRadius}px`,
        backgroundColor: !noBackground? 'white':"transparent",
        boxShadow: !noBoxShadow? '0px 4px 10px 5px rgba(0, 0, 0, 0.1)':"none",
        zIndex: 9999,
        display:"flex",
      }}
    >
      {children}
    </div>,
    document.body
  );
};
 
export default CreatePortalHOC;