import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteData, getDataIdBase, saveData,getCommonDataBasedOnId } from '../../components/store/customer/CustomerSocialAddUpdateFunction';
import { CUSTOMER_ACTIVITY_ADD_UPDATE,CUSTOMER_ACTIVITY_GET_EVENT} from '../constants';

export const saveActivity = createAsyncThunk('activity/saveActivity', async ({taskEvent ,token}, thunkAPI) => {
  try {
     const response=await saveData(CUSTOMER_ACTIVITY_ADD_UPDATE,[taskEvent],token);
     if(response){
      const url = `${CUSTOMER_ACTIVITY_GET_EVENT}?task_id=${taskEvent.task_id}`;
      const responseData=await getCommonDataBasedOnId(url,token);
      thunkAPI.dispatch(setCustomerActivity(responseData));
     }
     return response;
  } catch (error) {
    return thunkAPI.rejectWithValue('Data is not an array or is empty');
  }
});

export const fetchTaskEvent=createAsyncThunk('activity/fetchTaskEvent',async({id,token},thunkAPI)=>{
  try {
    const url = `${CUSTOMER_ACTIVITY_GET_EVENT}?task_id=${id}`;
    const response=await getCommonDataBasedOnId(url,token);
   return response;
    
  }catch(error){
    return thunkAPI.rejectWithValue('Data is not an array or is empty');
  }
});

const customerActivitySlice = createSlice({
  name: 'activity',
  initialState: {
    loading: false,
    activity: [],
    error: null,
    showMessage: false,
    message: null,
  },
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
    },
    hideMessage: (state) => {
      state.showMessage = false;
    },
    setCustomerActivity:(state, action) => {
     
        state.activity = action.payload;
       
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskEvent.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(fetchTaskEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.activity = action.payload;
        state.error = '';
        state.showMessage = true;
      
      })
      .addCase(fetchTaskEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveActivity.pending, (state) => {
        state.loading = true;
        state.showMessage = false;
      })
      .addCase(saveActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.tasks=action.payload;
        state.error = '';
        state.showMessage = true;
        state.message = action.payload.message;
       
      })
      .addCase(saveActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { showMessage, hideMessage ,setCustomerActivity } = customerActivitySlice.actions;
export default customerActivitySlice.reducer;
