import React from 'react';
import Skeleton from 'react-loading-skeleton';

const KeyValueSkeleton = () => {
  return (
    <div className="w-[47%] h-[40px] mb-6 md:mb-0 relative flex items-center">
      <div className="w-[40%] tracking-wide text-535252 text-[13px]">
        <Skeleton width={150} height={20} />
      </div>
      <div className="xl:w-[60%] 2xl:w-[77%]">
        <Skeleton width={150} height={20} />
      </div>
    </div>
  );
};

export default KeyValueSkeleton;
