import React, { Fragment, useEffect, useState } from "react";
import { useFetchCompanyCommonDataQuery } from "../../utils/company/companySelector";
import useRequireAuth from "../../utils/useRequireAuth";
import useCustomerFormValidation from "../../hooks/ErrorHandling/useCustomerFormValidation";
import { useFetchCompanyAddressSingleQuery } from "../../utils/customer/customerTaskTable";
import useCustomFormValidation from "../../hooks/ErrorHandling/useCustomFormValidation";
import { ADD_UPDATE_COMPANY_ADDRESS } from "../../utils/constants";
import { saveData } from "../store/customer/CustomerSocialAddUpdateFunction";


const validationRules = {
    address_type : {
    required: true,
    errorMessage: 'required',
  },
  point_of_contact: {
    required: true,
    errorMessage: 'required',
  },
  address: {
    required: true,
    errorMessage: 'required',
  },
  country_id: {
    required: true,
    errorMessage: 'required',
  },
  primary_email: [
   {
      required: true,
      errorMessage: 'Email is required.',
   },
   {
     email: true,
     errorMessage: 'Provide valid email',
   },
  ],
  secondry_email: [
    {
      email: true,
      errorMessage: 'Provide valid email',
    },
  ],
  phone1: {
    required: true,
    errorMessage: 'required',
  },
};




const AddUpdateCompanyAddress = ({ isOpen, closePopup ,refetchCustomerData,addressid,AddressData,companyId }) => {
  const { token } = useRequireAuth();
  const { data: customerAddress, refetch: refetchCustomerAddress } =
  useFetchCompanyAddressSingleQuery({
      token: token,
      addressId: addressid
  });


  const { errorMessage, validateForm } = useCustomerFormValidation();
  const [addresses,setAddresses]=useState([]);

  const [formData, setFormData] = useState({
    address_type: '',
    point_of_contact: '',
    address: '',
    country_id: '',
    primary_email: '',
    secondry_email: '',
    phone1: '',
    primary_email:"",
    secondry_email:""
  });

  const { values, errors, touched, handleChange, handleBlur, validateField, isFormValid } = useCustomFormValidation(
    formData,
    validationRules
  );

  useEffect(() => {
    if (customerAddress && customerAddress?.length>0) {
      setFormData(customerAddress[0]);
    }
  }, [customerAddress,addressid]);


  const handleFieldChange = (e) => {
      handleChange(e);
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (event) => {
    event.preventDefault();
  
    const formValid = isFormValid();
    if(!formValid){
      return;
    }

    const updatedAddresses=[{
      ...formData,
      company_profile_id:companyId,
      id:addressid
    }];
  
   const response=await saveData(ADD_UPDATE_COMPANY_ADDRESS,updatedAddresses,token);
   if(response){
    closePopup();
    refetchCustomerData()
   }
   
  };
  
  const {
    data: companyCommnData,
    error,
    isLoading,
  } = useFetchCompanyCommonDataQuery(token);

  function convertToLower(str) {
    if (typeof str === 'string') {
      return str.toLowerCase();
    }
    return '';
  }


  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-gray-900 bg-opacity-50">
      <div className="relative mt-18 bg-white  font-popns drop-shadow-lg  rounded-md w-[1074px] overflow-hidden no-scrollbar">
        <div className=" flex items-center justify-between p-4 md:p-3  border-b  border-E5EAED rounded-t">
          <h2 class="font-Vietnam text-20 font-normal text-537596">
            
            { customerAddress?.id ? 'Update Address' : <div className="text-434852 text-20 font-500">Add Address</div> }

          </h2>
          <button
            onClick={closePopup}
            type="button"
            className="text-454545 bg-F1F5F8 hover:bg-F1F5F8 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="assets-modal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <form
            onSubmit={handleSave}
            className="w-full overflow-hidden no-scrollbar p-5"
          >
          <div className={`grid grid-cols-3 gap-x-8`}>
            <div>
              <label
                className="block font-500 text-434852 text-14 mb-1"
                htmlFor={`address_type`}
              >
                Address Type <span className="text-red-700">*</span>
                {errors && (
                    <span className="text-red-700">
                      {errors.address_type}
                    </span>
                  )}
              </label>
              <select
                className="py-0 h-[30px] form-select rounded block appearance-none w-full border border-DFE2E5 text-xs text-434852 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id={`address_type`}
                name="address_type"
                onChange={handleFieldChange}
              >
                <option hidden selected>Select Address Type</option>
                {(AddressData?.length === 0 || convertToLower(formData?.address_type)==='default') &&  <option value="Default" selected={convertToLower(formData?.address_type)==='default'}>Default</option>   }
                <option value="Primary" selected={convertToLower(formData?.address_type)==='primary'}>Primary</option>
                <option value="Legal Address" selected={convertToLower(formData?.address_type)==='legal address'}>Legal Address</option>
              </select>
              
            </div>
        

            <div>
              <label
                className="block font-500 text-434852 text-14 mb-1"
                htmlFor={`address`}
              >
                Address <span className="text-red-700">*</span>
                {errors && (
                    <span className="text-red-700">
                      {errors.address}
                    </span>
                  )}
              </label>
              <input
                style={{ height: '30px' }}
                className="resize-y border border-DFE2E5 rounded text-xs w-full text-383838 p-1 px-3"
                id={`address`}
                name="address"
                onChange={handleFieldChange}
                value={formData?.address}
                
              ></input>
            </div>
            <div>
              <label
                className="block font-500 text-434852 text-14 mb-1"
                htmlFor={`city`}
              >
                City
              </label>
              <input
                className="rounded border-DFE2E5 block w-full py-3 px-4 mb-3 text-383838 border text-xs"
                type="text"
                id={`city`}
                name="city"
                onChange={handleFieldChange}
                value={formData?.city}
                
              />
            </div>
            <div>
              <label
                className="block font-500 text-434852 text-14 mb-1"
                htmlFor={`state`}
              >
                State
              </label>
              <input
                className=" block h-[30px ] w-full py-3 px-4 mb-3 text-383838 border rounded border-DFE2E5 text-xs"
                type="text"
                id={`state`}
                name="state"
                onChange={handleFieldChange}
                value={formData?.state}
                
              />
            </div>
           
            <div>
              <label
                htmlFor={`country_id`}
                className={`block mb-1 text-434852 text-14 font-500`}
              >
                Country     <span className="text-red-700">*</span>
                {errors && (
                    <span className="text-red-700">
                      {errors.country_id}
                    </span>
                  )}
              </label>
              <select
                id={`country_id`}
                name="country_id"
                onChange={handleFieldChange}
                
                className="form-select rounded block appearance-none py-0 h-[30px] w-full border border-DFE2E5 text-xs text-535252 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option selected hidden>Select Country</option>
                {companyCommnData?.data?.country?.map((CountryData) => (
                  <option key={CountryData.id} value={CountryData.country_name} selected={formData?.country_id===CountryData.country_name}>
                    {CountryData.country_name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                className="block font-500 text-434852 text-14 mb-1"
                htmlFor={`zip_code`}
              >
                Zip Code
              </label>
              <input
                className=" block h-[30px] w-full py-3 px-4 mb-3 text-383838 border border-DFE2E5 rounded text-xs"
                id={`zip_code`}
                type="text"
                name="zip_code"
                onChange={handleFieldChange}
                value={formData?.zip_code}
              />
            </div>

            <div>
              <label
                className="block font-500 text-434852 text-14  mb-1"
                htmlFor={`point_of_contact`}
              >
                Point of Contact <span className="text-red-700">*</span>
              
                   {errors && (
                    <span className="text-red-700">
                      {errors.point_of_contact}
                    </span>
                  )}
              </label>
              <input
                className=" block w-full py-3 px-4 mb-3 text-383838 border rounded border-DFE2E5 text-xs"
                type="text"
                id={`point_of_contact`}
                name="point_of_contact"
                onChange={handleFieldChange}
                onBlur={handleBlur}
                value={formData?.point_of_contact}
                
              />
          
            </div>


            <div>
                <label
                  className="block font-500 text-434852 text-14 mb-1"
                  htmlFor={`primary_email`}
                >
                  Primary Email  <span className="text-red-700">*</span>
               
                   {errors && (
                    <span className="text-red-700">
                      {errors.primary_email}
                    </span>
                  )}
                </label>
                
                <input
                  className="block w-full py-3 px-4 mb-3 text-383838 border rounded border-DFE2E5 text-xs"
                  id={`primary_email`}
                  type="text"
                  name="primary_email"
                  onChange={handleFieldChange}
                  value={formData?.primary_email}
                 
                />
                
               
              </div>
              <div>
                <label
                  className="block font-500 text-434852 text-14 mb-1"
                  htmlFor={`secondry_email`}
                >
                  Secondary Email  
                 
                   {errors && (
                    <span className="text-red-700 ms-1">
                      {errors.secondry_email}
                    </span>
                   )}

                </label>
                <input
                  className="block w-full py-3 px-4 mb-3 text-383838 border rounded border-DFE2E5 text-xs"
                  id={`secondry_email`}
                  type="text"
                  name="secondry_email"
                  value={formData?.secondry_email}
                  onChange={handleFieldChange}
                
                 
                />
              
              </div>

            <div>
              <label
                className="block font-500 text-434852 text-14 mb-2"
                htmlFor={`phone1`}
              >
                Phone 1 
                <span className="text-red-700">*</span>
              
                   {errors && (
                    <span className="text-red-700 ms-1">
                      {errors.phone1}
                    </span>
                   )}
              </label>
              <input
                className="block w-full py-3 px-4 mb-3 text-383838 border rounded border-DFE2E5 text-xs"
                type="text"
                name="phone1"
                id={`phone1`}
                onChange={handleFieldChange}
                value={formData?.phone1}
                onKeyDown={(e) => {
                  if (
                    !/^\d+$/.test(e.key) &&
                    ![
                      "Backspace",
                      "Delete",
                      "ArrowLeft",
                      "ArrowRight",
                      "Home",
                      "End",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
               
              />            
             
            </div>
            <div>
              <label
                className="block font-500 text-434852 text-14 mb-2"
                htmlFor={`phone2`}
              >
                Phone 2
              </label>
              <input
                className=" block w-full py-3 px-4 mb-3 text-383838 border rounded border-DFE2E5 text-xs"
                type="text"
                id={`phone2`}
                name="phone2"
                onChange={handleFieldChange}
                value={formData?.phone2}
                onKeyDown={(e) => {
                  if (
                    !/^\d+$/.test(e.key) &&
                    ![
                      "Backspace",
                      "Delete",
                      "ArrowLeft",
                      "ArrowRight",
                      "Home",
                      "End",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                
              />
             
            </div>
          </div>
          <div className="sticky bottom-0 bg-white pt-4 flex justify-end text-md w-full rounded-none z-[9998]">
            <div className="">
              <button
                onClick={closePopup} 
                type="button"
                className="px-6 py-1 text-16  rounded font-500 bg-F4F4F4 focus:outline-none me-2"
              >
                CANCEL
              </button>
              <button
                type="submit"
                className={`px-6 py-1 text-16 rounded font-500 bg-1D4469 text-white focus:outline-none cbtn-transition-bg cbtn-transition-bg`}
                     
              >
                { customerAddress?.id ? 'Update' : 'SAVE' }
              </button>
            </div>
          </div>
          
        </form>
      </div>
    </div>
  );
};

export default AddUpdateCompanyAddress;