import React, { useState, useEffect, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddCombineDate = ({ FieldValue, FieldChange, UpdateTableField, Item, isOpens }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
 
  useEffect(() => {
    if (FieldValue) {
     
      if(isOpens){
        const [startStr, endStr] = FieldValue.split(' - ');
        setStartDate(startStr ? parseDateString(startStr) : null);
        setEndDate(endStr ? parseDateString(endStr) : null);
      }else{
        setStartDate(FieldValue ? destructuerDate(FieldValue) : null);
      }
    } 
  }, [FieldValue]);

  const destructuerDate = (dateStart) => {
    const dates = dateStart.split('-');
    return `${dates[2]}-${dates[1]}-${dates[0]}`;
  };

  const parseDateString = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return new Date(`${year}-${month}-${day}`);
  };

  

  const formatDateForSave = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const onChange = (dates) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      const formattedStartDate = start ? formatDateForSave(start) : '';
      const formattedEndDate = end ? formatDateForSave(end) : '';
      FieldChange({ due_date: `${formattedStartDate} - ${formattedEndDate}` });
    } else {
      setStartDate(dates);
      setEndDate(null);
      const formattedStartDate = dates ? formatDateForSave(dates) : '';
      FieldChange({ due_date: `${formattedStartDate}` });
    }
  };
  

  const placeholder = "Select Date Range";
  const displayFormat = "dd-MM-yyyy";

  

  return (
    <Fragment>
      {isOpens ? (
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          placeholderText={placeholder}
          dateFormat={displayFormat}
          className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-800 text-sm block w-full h-10"
        />
      ) : (
        <DatePicker
          selected={startDate}
          onChange={onChange}
          placeholderText={placeholder}
          dateFormat={displayFormat}
          className="bg-white rounded border border-DFE2E5 focus:outline-hidden focus:ring-0 focus:border focus:border-C4D3E2 focus:shadow-10 active:border active:border-DFE2E5 text-gray-800 text-sm block w-full h-10"
        />
      )}
    </Fragment>
  );
};

export default AddCombineDate;