import ServicesModal from "./ServicesModal";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  hideMessage,
  saveServicesDataAsync,
  deleteServicesDataAsync
} from "../../utils/customer/servicesSlice";
import { useNavigate, useParams } from "react-router-dom";
import useRequireAuth from "../../utils/useRequireAuth";
import useFullscreen from "../../hooks/Screen/FullCloseScreen";
import CustomerListTable from "./CustomerListTable";
import serviceHeader from "./cusromerServiceData";
import { useCustomerTableMutation } from "../../utils/customer/customerTable";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";
import { useFetchCustomerServiceTableDataQuery } from "../../utils/customer/customerTaskTable";
import { useUpdateCustomerServiceTableDataMutation } from "../../utils/customer/customerTaskTable";

const CustomerServices = ({leftTab}) => {
 
  const { isFullscreen, toggleFullscreen } = useFullscreen();
  const dispatch = useDispatch();
  const { token } = useRequireAuth();
  const { id: customerId } = useParams();
  const [customer,setCustomer]=useState([]);
  const [initialFilter,setInitialFilter]=useState([]);
  const message = useSelector((state) => state.customerServices.message);
  const showMessage = useSelector(
    (state) => state.customerServices.showMessage
  );
  const companyLocalData = localStorage.getItem("company_data");
  const parsedCompanyData = JSON.parse(companyLocalData);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [alert,setAlert]=useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading,setLoading]=useState(false)
  const [customerTablePagination,setCustomerTablePagination] = useState('');

  const handleDelete = async(rowKey, item,itemid) => {   
   const id=item?.customer_id?.value;
    try {
      await dispatch(deleteServicesDataAsync({ id, token }));
      refetchCustomerData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleStatusUpdate=async(rowKey, item,itemId,status)=>{
    const socialData={
      id:itemId,
      status:status?"1":"0",
    }
    try {
      await dispatch(saveServicesDataAsync({ socialData, token }));
      refetchCustomerData();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  }

 
 
  const { data: customerTableALL, refetch: refetchCustomerData } = useFetchCustomerServiceTableDataQuery({
    token: token,
    customerId: customerId,
    pagination: `${customerTablePagination}`,
  }, {
    skip: leftTab !== 1, // Skip the query if leftTab is not 1
  });

  useEffect(() => {
    if (customerTableALL && leftTab===1) {
        setCustomer(customerTableALL?.data?.data);
        const { data: omitData, ...restData } = customerTableALL?.data;
     
        setInitialFilter(restData);
      setLoading(false);
    }
  }, [customerTableALL, customerTablePagination, leftTab]);

 



  const [updateCustomerServiceTableData, { CustomerDropLoad, CustomerDroperror, }] = useUpdateCustomerServiceTableDataMutation();
  const customerTableFieldUpdate = async (field_name,value) => {
    
    let values, data;

    if (Array.isArray(field_name)) {
   
      values = field_name.map((it) => it.value).join("; ");
      data = {
        value: values,
      };
    } else {
      // Handle single item
      values = field_name?.value || "";
      if (values) {
        data = {
          value: values,
          color_code: field_name?.color_code,
        };
      }
    }
    const updateData = {
      field_name: value.field_name,
      value: data,
      id: value.id
    }
   /// console.log("updateData",updateData);return false;
    try {
      const result = await updateCustomerServiceTableData({ token: token, data: updateData }).unwrap();
      if(result){
        refetchCustomerData()
        {result?.data?.original ?  setAlert({ visible: true, message: result?.data?.original?.error, type: 'danger' }) : setAlert({ visible: true, message: result?.message, type: 'success' })} 
      }
    } catch (error) {
      setAlert({ visible: true, message: 'Error updating data!', type: 'danger' });
    }
  
  }
  
  const [
    CustomerTableDrop,
    { data: customerCommnData, refetch: refetchCustomerCommnData },
  ] = useCustomerTableMutation();

  const fetchCustomerTable = (data) => {
    const completedata = {
      "model_name": "customer_services",
      "field_name": data,
    };
    CustomerTableDrop({token:token,data:completedata});
  }
  


  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
 setTimeout(()=>{dispatch(hideMessage())},2000);

  
  const navigate = useNavigate();
  const handleNavigate = (customer_id = null) => {
    navigate(`/customer`);
  };
  const hideAlert = () => {
    setAlert((prevState)=>({ ...prevState,visible: false }));
  };

  return (
    <>
      <div className="tablemaindiv">
        <div className={`${isFullscreen ? "bg-white h-full px-5 pt-2" : ""}`}>
          {showMessage && (
            <p className="text-green-500 text-right">{message}</p>
          )}
          <FirstMessage
            type={alert.type}
            message={alert.message}
            visible={alert.visible}
            onHide={hideAlert}
          />
          
          <CustomerListTable
            tableDetail={
              {
                  tableidclass:"fullscreen-table",
                  tablename:'Services',
                  tablenameclass: 'font-medium text-537596 capitalize',
                  tableSearch:true,
                  tableFilter:true,
                  tableResizer:true,
                  tableZoom:true,
                  statusUpdate: false,
                  datetedata:true,
                  downloaddata:false,
                  addbutton: (
                      <button 
                         onClick={openPopup}
                         type="button"
                         className="text-2541558A hover:text-254155"
                      >
                           <i className="bi bi-plus-lg"></i>
                      </button>
                  )
              }
            }
            thead={serviceHeader}
            tbody={customer}
            tablePaginationData={setCustomerTablePagination}
            fetchtableBodyData={fetchCustomerTable}
            customerCommnData={customerCommnData}
            initialFilter={initialFilter}
            handleNavigate={handleNavigate}
            TableFieldUpdate={customerTableFieldUpdate}
            handleStatusUpdate={handleStatusUpdate}
            handleDelete={handleDelete}
            navigateNameCondition={["subject"]}
          />
          
            {isPopupOpen && (
              <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                <ServicesModal isOpen={isPopupOpen} onClose={closePopup}  refetchCustomerData={refetchCustomerData}/>
              </div>
            )}

        </div>
      </div>
    </>
  );
};

export default CustomerServices;
