import React, { useState, useEffect, Fragment } from "react";
import useRequireAuth from "../../utils/useRequireAuth";
import CustomerListTable from "../customer/CustomerListTable";
import GroupsHeader from "./GroupsHeader";
import { useFetchGroupsTableDataQuery } from "../../utils/users/groups";
import AddGroups from "./AddGroups";
import { deleteData } from "../store/customer/CustomerSocialAddUpdateFunction";
import { DELETE_ORGANIZATION_DATA } from "../../utils/constants";
import FirstMessage from "../GlobalFeature/MessageAlert/FirstMessage";

const GroupsTable = ({ handleNavigates,TableNav, groupid}) => {
  const [groupsTablePagination, setGroupsTablePagination] = useState("");
  const [groups, setGroups] = useState([]);
  
  const [alert, setAlert] = useState({ visible: false, message: "", type: "" });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editId,setEditId]=useState();

  const [initialFilter, setInitialFilter] = useState([]);
  const { token } = useRequireAuth();
 console.log("99999999999999999",groupid?.group_name?.id);
  const { data: groupsTableALL, refetch: refetchGroupsData } =
    useFetchGroupsTableDataQuery({
      pagination: `${groupsTablePagination}`,
      orggrp_id: groupid?.group_name?.id,
      token: token,
    });

  
  useEffect(() => {
    if (groupsTableALL) {
      const { data, ...restData } = groupsTableALL?.data;
      setGroups(data);
      setInitialFilter(restData);
    }
  }, [groupsTableALL, groupsTablePagination, refetchGroupsData]);

  const handleActionDelete = (data) => {
    const firstkey = Object.keys(data)[0];
    
  };


 const openPopup=()=>{
  
  setIsPopupOpen(true);
 }

 const closePopup=()=>{
  setEditId();
  setIsPopupOpen(false);
 }

const handleNavigate=(data, items, header)=>{
  handleNavigates(data, items, header);
  setEditId(data)
}
const handleDelete=async(rowKey, item)=>{
 const id=item?.customer_id?.value;
 const url=`${DELETE_ORGANIZATION_DATA}${id}`
 const response=deleteData(url,token);
 if(response){
  refetchGroupsData();
  setAlert({
    visible: true,
    message: "data deleted successfully",
    type: "success",
  });
 }

}
const hideAlert = () => {
  setAlert((prevState)=>({ ...prevState,visible: false }));
};
  
  return (
    <Fragment>
       <FirstMessage
        type={alert.type}
        message={alert.message}
        visible={alert.visible}
        onHide={hideAlert}
      />
      <nav className="">
        <div className="container ">
          <div class="flex justify-between ">
            <div class="flex justify-between ">
              <div class="tablemaindiv customerTaskTable">
                 {isPopupOpen===true && (
                  <div className="absolute top-0 left-0 w-full flex justify-center items-center">
                  <AddGroups                  
                    refetchData={refetchGroupsData}
                    groupid={groupid}
                    closePopup={closePopup}
                    isPopupOpen={isPopupOpen}
                    action='Add'                    
                  />
                </div>
                )}

                 {editId && (
                  <div className="absolute  top-0 left-0 w-full flex justify-center items-center">
                  <AddGroups                  
                    refetchData={refetchGroupsData}
                    groupid={groupid}
                    closePopup={closePopup}
                    isPopupOpen={isPopupOpen}
                    editId={editId}
                    action='Edit'                     
                  />
                </div>
                )}                
              </div>
            </div>
          </div>
          <div className="md:hidden">
            <button className="text-white focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>
    <CustomerListTable
      tableDetail={{
        tableidclass: "fullscreen-table",
        tableparentidclass: "tableparent",
        tablename:TableNav(),
        tablenameclass: "font-medium text-16 text-203C50 capitalize",
        tableSearch: true,
        tableFilter: true,
        forcefullyfreeze: true,
        tableResizer: true,
        tableZoom: true,
        tableheader: false,
        dataactivity: true,
        headeraddfield: false,
        datetedata:true,
        addbutton: (
          <button
            onClick={openPopup}
            type="button"
            className="text-2541558A hover:text-254155"
          >
            <i className="bi bi-plus-lg"></i>
          </button>
        ),
      }}
      thead={GroupsHeader}
      tbody={groups}
      tablePaginationData={setGroupsTablePagination}
      initialFilter={initialFilter}
      navigateNameCondition={["name","action"]}
      handleNavigate={handleNavigate}
      handleDelete={handleDelete}
    />
    </Fragment>
  );
};

export default GroupsTable;
